import { Context, createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { initialize, LDClient, LDContext, LDOptions } from 'launchdarkly-js-client-sdk';

const ldEnvKey = process.env.REACT_APP_LD_ENV_KEY as string;
const ldOptions: LDOptions = {
  privateAttributes: ['email'],
};
const defaultContext = {
  kind: 'user',
  anonymous: true,
};

const LDClientContext: Context<LDClient | undefined> = createContext<LDClient | undefined>(
  undefined
);

const useLDClientContext = () => useContext(LDClientContext);

const LDProvider = ({ context, children }: { context?: LDContext; children?: ReactNode }) => {
  const [client, setClient] = useState<LDClient | undefined>(undefined);
  const initClient = async () => {
    const ldContext = context || defaultContext;
    const newLdClient = initialize(ldEnvKey ?? '', ldContext, ldOptions);

    await newLdClient.waitForInitialization();

    setClient(newLdClient);
  };

  useEffect(() => {
    if (!client) {
      initClient();
    }

    return () => {
      client?.close();
    };
  });

  return <LDClientContext.Provider value={client}>{!!client && children}</LDClientContext.Provider>;
};

export { LDProvider, useLDClientContext, defaultContext, ldOptions };
