import { ButtonThemes, SpinnerSizes } from '../../../lib/constants/components';
import { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BannerTheme } from './ClimateFieldViewBanner.types';
import cfDriveImg from '../../../assets/images/bayer-value/climate-fieldview-drive.png';
import { ClimateFieldViewIcon } from '../../../assets/icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import { fullMonthDay } from '../../../lib/constants/date-formats';
import { GET_FARM_CLIMATE_FIELD_VIEW } from '../../../lib/graphql/FarmInformation.gql';
import { getRegionSpecificLink } from '../../../lib/utils/utils';
import Link from '../../_shared/Link';
import LinkButton from '../../_shared/LinkButton';
import { LinkUrls } from '../../../lib/constants/react-router';
import Spinner from '../../_shared/Spinner';
import styles from './ClimateFieldViewBanner.module.scss';
import { useBreakpoint } from '../../../lib/hooks';
import { useQuery } from '@apollo/client';

export interface Props {
  bannerTheme: BannerTheme;
}

const ClimateFieldViewBanner: FC<Props> = ({ bannerTheme }) => {
  const { isXs } = useBreakpoint();
  const { t } = useTranslation();
  const { data: farmData, loading: isLoading } = useQuery(GET_FARM_CLIMATE_FIELD_VIEW);

  const renderCta: ReactNode = (
    <LinkButton
      className={cx(styles['cfb__button'])}
      theme={bannerTheme === BannerTheme.DASHBOARD ? ButtonThemes.SECONDARY : ButtonThemes.PRIMARY}
      href={getRegionSpecificLink(farmData?.farm?.farmInfo?.address?.province, {
        east: LinkUrls.CLIMATE_FIELD_VIEW_EAST,
        west: LinkUrls.CLIMATE_FIELD_VIEW_WEST,
      })}
      target="_blank"
      altText={t('bayer-value.climate-fieldview.cta-alt')}
      rel="noopener noreferrer"
    >
      {t('bayer-value.climate-fieldview.cta')}
    </LinkButton>
  );

  const renderFooter = (): ReactNode => {
    if (
      farmData?.farm.farmInfo.isEnrolledClimateFieldview &&
      farmData?.farm.farmInfo.climateFieldviewRenewalDate
    ) {
      return (
        <p className={cx(styles['cfb__description'])}>
          {t('bayer-value.climate-fieldview.renewal', {
            date: dayjs(farmData?.farm.farmInfo.climateFieldviewRenewalDate).format(fullMonthDay),
          })}
        </p>
      );
    }
    return renderCta;
  };

  const renderBanner = (): ReactNode => (
    <div
      className={cx(styles['cfb'], {
        [styles['cfb-dashboard']]: bannerTheme === BannerTheme.DASHBOARD,
        [styles['cfb-rewards']]: bannerTheme === BannerTheme.REWARDS_LANDING,
      })}
    >
      <div className={cx(styles['cfb__text-wrapper'])}>
        <div className={cx(styles['cfb__icon-wrapper'])}>
          <ClimateFieldViewIcon
            className={cx(styles['cfb__icon'])}
            aria-label={t('bayer-value.climate-fieldview.logo-alt')}
          />
        </div>
        <p className={cx(styles['cfb__description'])}>
          {farmData?.farm.farmInfo.isEnrolledClimateFieldview ? (
            <Trans
              i18nKey="bayer-value.climate-fieldview.description"
              components={{
                sup: <sup />,
                url: <Link href={LinkUrls.GROWER_PROGRAMS} target="_blank" />,
              }}
            />
          ) : (
            t('bayer-value.climate-fieldview.sign-up-description')
          )}
        </p>
        <p className={cx(styles['cfb__price-wrapper'])}></p>
        {!isXs && renderFooter()}
      </div>
      <div
        className={cx(styles['cfb__image-wrapper'], {
          [styles['cfb-dashboard__image-wrapper']]: bannerTheme === BannerTheme.DASHBOARD,
          [styles['cfb-rewards__image-wrapper']]: bannerTheme === BannerTheme.REWARDS_LANDING,
        })}
      >
        <img
          className={cx(styles['cfb__image'])}
          src={cfDriveImg}
          alt={t('bayer-value.climate-fieldview.image-alt')}
        />
      </div>
      {isXs && <div className={cx(styles['cfb__button-wrapper'])}>{renderFooter()}</div>}
    </div>
  );

  return (
    <div className={cx({ [styles['cfb__loading']]: isLoading })}>
      {isLoading ? <Spinner size={SpinnerSizes.MD} /> : renderBanner()}
    </div>
  );
};

ClimateFieldViewBanner.displayName = 'ClimateFieldViewBanner';

export default ClimateFieldViewBanner;
