import { OnboardingStep, Step } from './stepper';
import CropInformation from '../../pages/Onboarding/Setup/steps/CropInformation';
import FarmInformation from '../../pages/Onboarding/Setup/steps/FarmInformation';
import PartnerPermissions from '../../pages/Onboarding/Setup/steps/PartnerPermissions';
import PreferredRetailers from '../../pages/Onboarding/Setup/steps/PreferredRetailers/PreferredRetailers';
import ProfileDetails from '../../pages/Onboarding/Setup/steps/ProfileDetails';
import Summary from '../../pages/Onboarding/Setup/steps/Summary';
import { UserOnboardingDataQuery } from '../../__generated__/graphql';
import UserPermissions from '../../pages/Onboarding/Setup/steps/UserPermissions';

export const isAdmin = (onboardingData: UserOnboardingDataQuery | null) =>
  !!onboardingData?.user?.accountInfo?.isAdmin;

export const checkHasPartners = (onboardingData: UserOnboardingDataQuery | null) =>
  (onboardingData?.farm?.partners?.filter((partner) => !partner.isParent)?.length || 0) > 0;

export const ONBOARDING_STEPS: Step[] = [
  {
    id: OnboardingStep.MY_ACCOUNT,
    labelKey: 'onboarding.steps.my-account.name',
    children: [
      {
        id: OnboardingStep.PROFILE_DETAILS,
        labelKey: 'onboarding.steps.profile-details.name',
        Component: ProfileDetails,
      },
    ],
    index: 1,
  },
  {
    id: OnboardingStep.MY_OPERATION,
    labelKey: 'onboarding.steps.my-operation.name',
    children: [
      {
        id: OnboardingStep.FARM_INFORMATION,
        labelKey: 'onboarding.steps.farm-information.name',
        shouldBeDisplayed: isAdmin,
        Component: FarmInformation,
      },
      {
        id: OnboardingStep.CROP_INFORMATION,
        labelKey: 'onboarding.steps.crop-information.name',
        shouldBeDisplayed: isAdmin,
        Component: CropInformation,
      },
      {
        id: OnboardingStep.PREFERRED_RETAILERS,
        labelKey: 'onboarding.steps.preferred-retailers.name',
        Component: PreferredRetailers,
      },
      {
        id: OnboardingStep.USER_INVITATIONS,
        labelKey: 'onboarding.steps.user-invitations.name',
        shouldBeDisplayed: isAdmin,
        children: [
          {
            id: OnboardingStep.PARTNER_PERMISSIONS,
            labelKey: 'onboarding.steps.partner-permissions.name',
            Component: PartnerPermissions,
            shouldBeDisplayed: checkHasPartners,
          },
          {
            id: OnboardingStep.USER_PERMISSIONS,
            labelKey: 'onboarding.steps.user-permissions.name',
            Component: UserPermissions,
          },
        ],
      },
    ],
    index: 2,
  },
  {
    id: OnboardingStep.SUMMARY,
    labelKey: 'onboarding.steps.summary.name',
    index: 3,
    Component: Summary,
  },
];
