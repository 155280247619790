import { ReactNode } from 'react';

export interface TooltipProps {
  /**
   * Class name for the tooltip component.
   */
  className?: string;
  /**
   * Class name for the tooltip content.
   */
  innerClassName?: string;
  /**
   * ID for the `div` that contains the tooltip
   */
  id?: string;
  /**
   *  Tooltip position
   */
  position?: TooltipPosition;
  /**
   * Heading of the tooltip
   */
  heading?: string;
  /**
   * Content to be displayed inside the tooltip
   */
  text?: string;
  /**
   * Flag to force the tooltip to show
   */
  forceShow?: boolean;
  /**
   * Flag to show the tooltip on hover
   */
  showOnHover?: boolean;
  /**
   * Flag to show the tooltip on focus
   */
  showOnFocus?: boolean;
  children: ReactNode;

  theme?: TooltipTheme;
}

export enum TooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOP = 'top',
}

export enum TooltipTheme {
  LIGHT = 'light',
  DARK = 'dark',
}
