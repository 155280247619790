import { Step } from '../constants/stepper';
import { UserOnboardingDataQuery } from '../../__generated__/graphql';

interface FlatStep extends Step {
  parentStepId?: string;
}

/**
 * Filters a given array of steps based on the `shouldBeDisplayed` function
 * @param steps
 * @param user
 * @returns
 */
export const filterSteps = (steps: Step[], onboardingData: UserOnboardingDataQuery | null) =>
  steps.filter((s) => {
    if (s.children?.length) {
      // eslint-disable-next-line no-param-reassign
      s.children = filterSteps(s.children, onboardingData);
    }
    if (s.shouldBeDisplayed) {
      return s.shouldBeDisplayed?.(onboardingData);
    }
    return true;
  });

/**
 * Flattens a given array of steps
 * @param steps
 * @returns
 */
export const flattenSteps = (steps: Step[], parentStepId?: string): FlatStep[] => {
  const flatSteps: FlatStep[] = [];
  steps.forEach((s) => {
    flatSteps.push({ ...s, parentStepId });
    if (s.children?.length) {
      flatSteps.push(...flattenSteps(s.children, s.id));
    }
  });

  return flatSteps;
};

/**
 * Gets the steps for a user
 */
export const getSteps = (steps: Step[], onboardingData: UserOnboardingDataQuery | null) =>
  filterSteps(
    steps.map((step) => ({ ...step })),
    onboardingData
  );

/**
 * Get a Step by ID. If the Step found doesn't have a Component associated to it, it returns the next step that has a Component
 * @param steps
 * @param stepId
 * @returns
 */
export const getStepById = (steps: Step[], stepId: string): Step | null => {
  let foundStep = false;
  const flatSteps = flattenSteps(steps);
  const step = flatSteps.find((s) => {
    if (s.id === stepId) {
      foundStep = true;
    }

    return foundStep && s.Component;
  });

  return step || null;
};

/**
 * Gets the first step with a Component
 * @param steps
 */
export const getFirstStep = (steps: Step[]): Step | null => {
  const flatSteps = flattenSteps(steps);
  const step = flatSteps.find((s) => s.Component);

  return step || null;
};

/**
 * Gets the next an previous step of the provided step
 * @param steps
 * @param stepId
 */
export const getAdjacentSteps = (
  steps: Step[],
  stepId: string
): { previous: Step | null; next: Step | null } => {
  const flatSteps = flattenSteps(steps);
  let previousStep: Step | null = null;
  let foundStep: boolean = false;
  let nextStep: Step | null = null;

  flatSteps.forEach((step) => {
    if (step.id === stepId) {
      foundStep = true;
    } else if (step.Component) {
      if (foundStep && !nextStep) {
        nextStep = step;
      } else if (!foundStep) {
        previousStep = step;
      }
    }
  });

  return { previous: previousStep, next: nextStep };
};

/**
 * Returns an array with the active steps and their ancestors
 * @param steps
 * @param stepId
 * @returns
 */
export const getActiveSteps = (steps: Step[], stepId: string): Step[] => {
  const activeSteps: Step[] = [];
  const flatSteps = flattenSteps(steps);

  const step = flatSteps.find((s) => s.id === stepId);

  if (step) {
    activeSteps.push(step);
  }

  if (step?.parentStepId) {
    activeSteps.push(...getActiveSteps(steps, step.parentStepId));
  }

  return activeSteps;
};

/**
 * Updates the `isCompleted` and `isInProgress` status of the steps
 * @param steps
 * @param prevStepId
 * @param nextStepId
 * @returns
 */
export const updateStepStatus = (steps: Step[], prevStepId?: string, nextStepId?: string): Step[] =>
  steps.map((s) => {
    const updatedStep: Step = { ...s };

    if (!updatedStep.isCompleted) {
      if (updatedStep.id === prevStepId) {
        updatedStep.isCompleted = true;
      } else if (updatedStep.id === nextStepId) {
        updatedStep.isInProgress = true;
      }
    }

    if (s.children?.length) {
      updatedStep.children = updateStepStatus(s.children, prevStepId, nextStepId);
      updatedStep.isCompleted = updatedStep.children.every((child) => child.isCompleted);
      updatedStep.isInProgress = updatedStep.children.some((child) => child.isInProgress);
    }

    return updatedStep;
  });

/**
 * Calculates the completion percentage of the steps
 * @param steps
 * @returns
 */
export const getStepsCompletionPercentage = (steps: Step[]): number => {
  const flatSteps = flattenSteps(steps).filter((s) => s.Component);
  const progressPercentage =
    (100 / flatSteps.length) * flatSteps.filter((s) => s.isCompleted).length;

  return progressPercentage;
};
