import { Language } from '../constants/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useDateFormat = (formatEn: string, formatFr: string): string => {
  const {
    i18n: { language },
  } = useTranslation();

  const dateFormat = useMemo(
    () => (language === Language.En ? formatEn : formatFr),
    [formatEn, formatFr, language]
  );

  return dateFormat;
};
