import { DisplayTheme, IconPosition } from '../../../../lib/constants/components';
import { FC, useEffect, useState } from 'react';
import { GET_FARM_PROMOTIONS, UPDATE_PROMOTION } from '../../../../lib/graphql/Promotions.gql';
import { useMutation, useQuery } from '@apollo/client';

import cx from 'classnames';
import { DashboardContextActionTypes } from '../../../../lib/contexts/dashboard/DashboardContext.types';
import { GET_USER_IDENTITY } from '../../../../lib/graphql/UserInformation.gql';
import Promotion from '../../../../components/PromotionBanner';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SpinnerIcon } from '../../../../assets/icons';
import styles from './ProgramPromotionSection.module.scss';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  theme?: DisplayTheme;
}

const ProgramPromotionSection: FC<Props> = ({ theme = DisplayTheme.WIDGET }) => {
  const { t } = useTranslation();
  const [promotionUpdate, setPromotionUpdate] = useState<string | null>();
  const { data: promotionsData, loading: loadingPromotionData } = useQuery(GET_FARM_PROMOTIONS);
  const { data: userData, loading: loadingUser } = useQuery(GET_USER_IDENTITY);
  const [updateProgramPromotion, { loading: loadingUpdatePromotion }] = useMutation(
    UPDATE_PROMOTION,
    {
      refetchQueries: [{ query: GET_FARM_PROMOTIONS }],
    }
  );
  const [, dispatchSnackbar] = useSnackbarContext();
  const [, dispatchDashboard] = useDashboardContext();

  useEffect(() => {
    if (promotionsData && !loadingPromotionData && promotionsData.farm.activePromotions.length) {
      dispatchDashboard({
        type: DashboardContextActionTypes.UpdateDisplayProgramPromotions,
        payload: true,
      });
    }
  }, [dispatchDashboard, loadingPromotionData, promotionsData]);

  const onUpdatePromotion = (promotionId: string) => {
    if (userData && !loadingUser) {
      setPromotionUpdate(promotionId);
      const input = {
        promotionToFarmId: promotionId,
        enrolledById: userData.user.accountInfo.id,
        isRedeemed: true,
      };
      updateProgramPromotion({ variables: { input } })
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: 'Successfully enrolled',
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          setPromotionUpdate(null);
        });
    }
  };

  return promotionsData?.farm.activePromotions.length ? (
    <>
      {theme === DisplayTheme.BANNER && (
        <hr className={cx(styles['program-promotion-section__divider'])} />
      )}
      <div
        className={cx(styles['program-promotion-section'], {
          [styles['program-promotion-section--banner']]: theme === DisplayTheme.BANNER,
          [styles['program-promotion-section--widget']]: theme === DisplayTheme.WIDGET,
        })}
      >
        {promotionsData.farm.activePromotions.map((programPromotion) => (
          <Promotion
            key={programPromotion.id}
            image={programPromotion.promotion.imageUrl}
            title={
              programPromotion.isRedeemed
                ? t(`program-offer.offers.${programPromotion.promotion.key}.title-enrolled`)
                : t(`program-offer.offers.${programPromotion.promotion.key}.title`)
            }
            badge={t('program-offer.badge')}
            description={
              programPromotion.isRedeemed
                ? t(`program-offer.offers.${programPromotion.promotion.key}.description-enrolled`)
                : t(`program-offer.offers.${programPromotion.promotion.key}.description`)
            }
            cta={
              !programPromotion.isRedeemed
                ? {
                    label: t(`program-offer.offers.${programPromotion.promotion.key}.button`),
                    action: () => {
                      onUpdatePromotion(programPromotion.id);
                    },
                    buttonIcon:
                      loadingUpdatePromotion && promotionUpdate === programPromotion.id
                        ? SpinnerIcon
                        : undefined,
                    buttonIconPosition: IconPosition.LEFT,
                    buttonIconClassName: cx({
                      [styles['program-promotion-section__button-loading']]:
                        loadingUpdatePromotion && promotionUpdate === programPromotion.id,
                    }),
                  }
                : null
            }
            theme={theme}
          />
        ))}
      </div>
    </>
  ) : null;
};

ProgramPromotionSection.displayName = 'ProgramPromotionSection';

export default ProgramPromotionSection;
