import { createContext, FC, useState } from 'react';
import { SignUpContextProps, SignUpContextType, SignUpInformation } from './SignUpContext.types';
import { VerificationMethod } from '../../../__generated__/graphql';

const initialState: SignUpInformation = {
  bayerValueConsent: false,
  email: '',
  emailExists: false,
  farmId: '',
  isAdmin: false,
  phone: '',
  uid: '',
  verificationId: '',
  verificationMethod: VerificationMethod.Sms,
};

const SignUpContext = createContext<SignUpContextType>([initialState, () => {}]);

export const SignUpProvider: FC<SignUpContextProps> = ({ children }) => {
  const value = useState<SignUpInformation>(initialState);

  return <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>;
};

export default SignUpContext;
