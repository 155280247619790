import { ButtonThemes, LinkThemes } from '../../lib/constants/components';
import Button from '../_shared/Button';
import cx from 'classnames';
import { FC } from 'react';
import Link from '../_shared/Link';
import { LinkUrls } from '../../lib/constants/react-router';
import styles from './PreAuthFooter.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
}

const PreAuthFooter: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer className={cx(className, styles['preauth-footer'])}>
      <div className={cx(styles['preauth-footer__inner'])}>
        <div className={cx(styles['preauth-footer__links-wrapper'])}>
          <nav className={cx(styles['preauth-footer__nav'])} aria-label={t('footer.nav.copyright')}>
            <ul className={cx(styles['preauth-footer__list'])}>
              <li className={cx(styles['preauth-footer__nav-item'])}>
                <Link
                  href={LinkUrls.COOKIE_SETTINGS}
                  target="_blank"
                  className={cx(styles['preauth-footer__link'])}
                  theme={LinkThemes.TEXT_LINK_DARK}
                >
                  {t('footer.links.cookie-info')}
                </Link>
              </li>
              <li className={cx(styles['preauth-footer__nav-item'])}>
                <Button
                  id="ot-sdk-btn"
                  className={cx('ot-sdk-show-settings',styles['preauth-footer__link'])}
                  theme={ButtonThemes.TEXT_LINK_DARK}
                >
                  {t('footer.links.cookie-settings')}
                </Button>
              </li>
            </ul>
          </nav>
        </div>
        <div className={cx(styles['preauth-footer__copyright-wrapper'])}>
          <p className={cx(styles['preauth-footer__copyright'])}>
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </p>
        </div>
      </div>
    </footer>
  );
};

PreAuthFooter.displayName = 'PreAuthFooter';

export default PreAuthFooter;
