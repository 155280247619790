import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import { timezonEst } from '../constants/date-formats';

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
export default dayjs;

/**
 * Function that gets the current Bayer Value reward year
 * @returns Current reward year
 */
export const getCurrentProgramYear = (): number => {
  const today = dayjs();
  const currentYear = today.year();
  const programStartDate = dayjs(`${currentYear}-10-01`);

  if (today.isBefore(programStartDate)) {
    return currentYear;
  }
  return currentYear + 1;
};

/**
 * Function that gets the current Bayer Value reward earning period
 * @returns object that contains the start and end year of the current reward earning period
 */
export const getCurrentRewardEarningPeriod = () => {
  const today = dayjs();
  const currentYear = dayjs().year();
  const periodEnd = `09/30/${currentYear}`; // September 30th
  let currentPeriodStart = currentYear;

  if (today.isBefore(periodEnd)) {
    currentPeriodStart -= 1;
  }

  return { currentPeriodStart, currentPeriodEnd: currentPeriodStart + 1 };
};

/**
 * Gets the Bayer Value reward earning period in date format
 * @returns
 */
export const getRewardEarningPeriodDate = (
  year: number = getCurrentRewardEarningPeriod().currentPeriodEnd
) => ({
  start: `10/01/${year - 1}`,
  end: `09/30/${year}`,
});

/**
 * Generates a list of the past three rewards years
 * @param limit Minimum year to show
 * @returns Array containing the list of years
 */
export const getLastThreeRewardYears = (limit: number = 2022): number[] => {
  const years: number[] = [];
  const today = dayjs();
  const currentYear: number = today.year();
  const isAfterMarch = today.month() >= 2;

  if (isAfterMarch) {
    years.push(currentYear);
  }

  years.push(currentYear - 1, currentYear - 2, currentYear - 3);

  return years.filter((year) => year >= limit).slice(0, 3);
};

export const getRewardsPeriodYear = (date: string): number => {
  const purchaseDate = dayjs(date);
  const startDate = dayjs(`10/01/${purchaseDate.year() - 1}`);
  const endDate = dayjs(`9/30/${purchaseDate.year()}`);

  if (purchaseDate < startDate) {
    return startDate.year();
  } else if (purchaseDate > startDate && purchaseDate < endDate) {
    return endDate.year();
  }
  return purchaseDate.year() + 1;
};

/**
 * Checks if the date of a purchase is from a previous year
 * @param date Date fo the purchase
 * @returns Whether the date belongs to the previous earning year
 */
export const isPurchaseOneYearOld = (date: string, limit: number = 2022) => {
  const periodYear = getRewardsPeriodYear(date);
  const currentYear = dayjs().year();

  if (
    periodYear < limit ||
    currentYear - periodYear > 2 ||
    (periodYear > currentYear && dayjs(date) > dayjs(`10/01/${currentYear}`))
  ) {
    return false;
  }

  return true;
};

/**
 * Returns the current date and time in the EST timezone as a `Date` object.
 *
 * @returns {Date} - The current EST time.
 */
export const newEstDate = (): Date => {
  const newDate = dayjs().tz(timezonEst);

  return new Date(
    newDate.year(),
    newDate.month(),
    newDate.date(),
    newDate.hour(),
    newDate.minute(),
    newDate.second(),
    newDate.millisecond()
  );
};
