import cx from 'classnames';
import { FC } from 'react';
import RewardsYearPicker from './RewardsYearPicker';
import { StickyRewardsYearPickerProps } from './RewardsYearPicker.types';
import styles from './RewardsYearPicker.module.scss';

const StickyRewardsYearPicker: FC<StickyRewardsYearPickerProps> = ({
  selectedYear,
  onSelect,
  title,
}) => (
  <div className={cx(styles['sticky-picker'])}>
    <div className={cx(styles['sticky-picker__container'])}>
      <div>
        <p className={cx(styles['sticky-picker__title'])}>{title}</p>
      </div>
      <div>
        <RewardsYearPicker selectedYear={selectedYear} onSelect={onSelect} />
      </div>
    </div>
  </div>
);

export default StickyRewardsYearPicker;
