import { gql } from '../../__generated__';

export const GET_SHOPPING_CARTS_QUERY = gql(`
query ShoppingCarts {
  shoppingCarts {
    shoppingCarts {
      id
      retailer {
        id
        name
        alias
        address {
          city
          province
        }
      }
      cartProducts {
        id
        quantity
        product {
          sku
          name
          acresPerPackage
          productSize
          trait {
            id
            imageUrl
          }
          seedTreatment {
            id
            name
          }
        }
      }
    }
  }
}`);

export const ADD_TO_CART_MUTATION = gql(`
mutation AddToCart($input: AddToCartInput!) {
  addToCart(input: $input) {
    cartProductId
  }
}
`);

export const DELETE_FROM_CART_MUTATION = gql(`
mutation DeleteCartProduct($input: DeleteCartProductInput!) {
  deleteCartProduct(input: $input)
}
`);

export const UPDATE_CART_ITEM_MUTATION = gql(`
mutation UpdateCartProduct($input: UpdateCartProductInput!) {
  updateCartProduct(input: $input) {
    cartProductId
  }
}
`);

export const SUBMIT_CART_MUTATION = gql(`
mutation SubmitCart($input: SubmitCartInput!) {
  submitCart(input: $input)
}
`);
