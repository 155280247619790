import { AirplaneIcon } from '../../assets/icons';
import cx from 'classnames';
import { FC } from 'react';
import styles from './RewardsProgressBar.module.scss';

export interface Props {
  currentValue: number;
  totalValue: number;
  ariaDescribedById: string;
}

const RewardsProgressBar: FC<Props> = ({ currentValue, totalValue, ariaDescribedById }) => {
  const progressPercentage = totalValue ? (100 / totalValue) * currentValue : 100;
  const isComplete = progressPercentage === 100;

  return (
    <div className={cx(styles['rewards-progress-bar'])}>
      <progress
        aria-describedby={ariaDescribedById}
        className={cx(styles['rewards-progress-bar__bar'])}
        max={100}
        value={progressPercentage}
      />
      <div
        className={cx(styles['rewards-progress-bar__deco'], {
          [styles['rewards-progress-bar__deco--complete']]: isComplete,
        })}
      >
        <AirplaneIcon
          className={cx(styles['rewards-progress-bar__airplane'], {
            [styles['rewards-progress-bar__airplane--complete']]: isComplete,
          })}
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

RewardsProgressBar.displayName = 'RewardsProgressBar';

export default RewardsProgressBar;
