import { Axis } from './LinearChart.types';
import { Color } from '../../lib/constants/charts';
import { Language } from '../../lib/constants/i18n';

/**
 * Configures chart margins based on screen size.
 *
 * @param {boolean} isXs - Indicates if the screen size is extra small.
 * @returns {Object} Margin settings for the chart.
 */
export const chartConfig = (isXs: boolean) => {
  if (isXs) {
    return { margin: { right: 16, left: 16, top: 24 } };
  }
  return { margin: { right: 16, left: 16, top: 48 } };
};

/**
 * Configuration settings for chart tick.
 *
 * @property {number} fontSize - The font size of the axis labels.
 * @property {Object} tick - The settings for the tick marks on the axis.
 * @property {string} tick.fill - The color of the tick marks.
 */
export const tickConfig = {
  fontSize: 16,
  tick: {
    fill: Color.SECONDARY_COLOR_100,
  },
};

/**
 * Configuration settings for chart Axis.
 *
 * @param {boolean} isXs - Indicates if the screen size is extra small.
 * @property {number} tickMargin - The margin between the axis and the ticks.
 */
export const axisConfig = (axis: Axis, isXs?: boolean) => {
  if (isXs || axis === Axis.X_AXIS) {
    return {
      ...tickConfig,
      tickMargin: 24,
    };
  }

  return {
    ...tickConfig,
  };
};

/**
 * Configuration settings for axis labels.
 *
 * @property {number} angle - The rotation angle of the axis labels.
 * @property {number} offset - The offset of the axis labels from the axis.
 */
export const axisLabelConfig = {
  angle: -90,
};

/**
 * Configuration settings for the cursor.
 *
 * @property {string} strokeDasharray - The pattern of dashes and gaps used to stroke the cursor line.
 * @property {string} stroke - The color of the cursor line.
 * @property {number} strokeOpacity - The opacity of the cursor line.
 */
export const cursorConfig = {
  strokeDasharray: '2 2',
  stroke: Color.DARK_BLUE,
  strokeOpacity: 0.73,
};

/**
 * Configuration settings for lines on the chart.
 *
 * @property {number} strokeWidth - The width of the lines.
 * @property {Object} activeDot - The settings for the active dot on the line.
 * @property {number} activeDot.r - The radius of the active dot.
 * @property {number} activeDot.strokeWidth - The width of the outline of the active dot.
 */
export const lineConfig = {
  strokeWidth: 1.5,
  activeDot: { r: 5, strokeWidth: 2 },
};

/**
 * Configuration settings for grid lines.
 *
 * @property {string} strokeDasharray - The pattern of dashes and gaps used to stroke the grid lines.
 */
export const gridConfig = {
  strokeDasharray: '2 1',
};

/**
 * Formats numerical values for display.
 *
 * @param {number} value - The value to be formatted.
 * @returns {string} The formatted value.
 */
export const valueFormatter = (value: number, language: string) =>
  new Intl.NumberFormat(language === Language.En ? 'en-US' : 'fr-CA', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
