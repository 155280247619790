import { FC, useEffect, useState } from 'react';
import {
  ProgramRewardMetrics,
  ProgramRewardMetricsCategory,
} from '../../../../__generated__/graphql';
import BayerValueEastTable from './segments/BayerValueEastTable/BayerValueEastTable';
import BayerValueHotPotatoesTable from './segments/BayerValueHotPotatoesTable';
import BayerValueWestTable from './segments/BayerValueWestTable';
import cx from 'classnames';
import { ReceiptIcon } from '../../../../assets/icons';
import Spinner from '../../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../../lib/constants/components';
import styles from '../BayerValueProgram.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState/TableEmptyState';
import { useTranslation } from 'react-i18next';

export interface Props {
  loading: boolean;
  programRewardMetrics: ProgramRewardMetrics[] | undefined;
}

const SegmentsSection: FC<Props> = ({ loading, programRewardMetrics }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState<ProgramRewardMetricsCategory>();

  useEffect(() => {
    if (programRewardMetrics && !!programRewardMetrics.length) {
      setCategory(programRewardMetrics[0].category);
    }
  }, [programRewardMetrics]);

  const renderSegmentsTable = () => {
    switch (category) {
      case ProgramRewardMetricsCategory.Bvw:
        return <BayerValueWestTable programRewardMetrics={programRewardMetrics} />;
      case ProgramRewardMetricsCategory.Bve:
        return <BayerValueEastTable programRewardMetrics={programRewardMetrics} />;
      case ProgramRewardMetricsCategory.Hp:
        return <BayerValueHotPotatoesTable programRewardMetrics={programRewardMetrics} />;
      default:
        return null;
    }
  };

  return (
    <section className={cx(styles['segments'])}>
      <div className={cx(styles['segments__wrapper'])}>
        {loading && (
          <>
            <div className={cx(styles['segments__header-skeleton'])} />
            <div className={cx(styles['spinner-wrapper'])}>
              <Spinner size={SpinnerSizes.LG} />
            </div>
          </>
        )}
        {!loading && !!programRewardMetrics?.length && (
          <>
            <div className={cx(styles['segments__header'])}>
              <h2 className={cx(styles['segments__title'])}>
                {(category === ProgramRewardMetricsCategory.Bvw &&
                  t('bayer-value.segments.west.title')) ||
                  (category === ProgramRewardMetricsCategory.Bve &&
                    t('bayer-value.segments.east.title')) ||
                  (category === ProgramRewardMetricsCategory.Hp &&
                    t('bayer-value.segments.hot-potatoes.title'))}
              </h2>
            </div>
            {renderSegmentsTable()}
          </>
        )}
        {!loading && !programRewardMetrics?.length && (
          <>
            <div className={cx(styles['segments__header'])}>
              <h2 className={cx(styles['segments__title'])}>
                {t('bayer-value.segments.west.title')}
              </h2>
            </div>
            <div className={cx(styles['segments__empty-state'])}>
              <TableEmptyState
                Icon={ReceiptIcon}
                title={t('bayer-value.segments.empty-state')}
                description={t('bayer-value.segments.empty-state-description')}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

SegmentsSection.displayName = 'SegmentsSection';

export default SegmentsSection;
