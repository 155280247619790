import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { FC, useEffect, useState } from 'react';
import {
  formatRetailerName,
  formatRetailerPhoneNumbers,
  getPrimaryContact,
} from '../../../lib/utils/retailers';
import { MinusCircleIcon, PlusCircleIcon, SpinnerIcon } from '../../../assets/icons';
import { Retailer, RetailerContact } from '../../../__generated__/graphql';
import { TFunction, useTranslation } from 'react-i18next';
import Button from '../../_shared/Button';
import cx from 'classnames';
import styles from './AddRetailerCard.module.scss';

export interface Props {
  className?: string;
  isLoading?: boolean;
  isSelected?: boolean;
  onButtonClick?(retailer: Retailer): void;
  retailer: Retailer;
  isCompact?: boolean;
  isPreferred?: boolean;
}

const getCardIcon = (isSelected: boolean, t: TFunction) => {
  if (isSelected) {
    return (
      <>
        <MinusCircleIcon className={cx(styles['arc__icon'])} aria-hidden="true" />
        <span className={cx('sr-only')}>{t('retailers.remove')}</span>
      </>
    );
  }
  return (
    <>
      <PlusCircleIcon className={cx(styles['arc__icon'])} aria-hidden="true" />
      <span className={cx('sr-only')}>{t('retailers.select')}</span>
    </>
  );
};

const AddRetailerCard: FC<Props> = ({
  className,
  retailer,
  isLoading = false,
  isSelected = false,
  onButtonClick,
  isCompact = false,
  isPreferred = false,
}) => {
  const { t } = useTranslation();
  const [primaryContact, setPrimaryContact] = useState<RetailerContact>();

  useEffect(() => {
    setPrimaryContact(getPrimaryContact(retailer));
  }, [retailer]);

  return (
    <div
      className={cx(styles['arc'], className, {
        [styles['arc--selected']]: isSelected && !onButtonClick,
        [styles['arc--w-button']]: onButtonClick,
      })}
    >
      <div className={cx(styles['arc__top-wrapper'])}>
        <div className={cx(styles['arc__address-wrapper'])}>
          {isCompact && isPreferred && (
            <p className={cx(styles['arc__eyebrow'])}>
              {t('account-settings.my-preferred-retailer.preferred-retailer')}
            </p>
          )}
          <p className={cx(styles['arc__ret-name'])}>{formatRetailerName(t, retailer)}</p>
          {!isCompact && primaryContact && (
            <p
              className={cx(styles['arc__ret-contact'])}
            >{`${primaryContact?.firstName} ${primaryContact?.lastName}`}</p>
          )}
          <p
            className={cx(styles['arc__ret-information'], {
              [styles['arc__ret-address--selected']]: isSelected && !onButtonClick,
            })}
          >
            {retailer.address &&
              t(isCompact ? 'format.city-province' : 'format.full-address', {
                address: retailer.address?.address,
                city: retailer.address?.city,
                province: retailer.address?.province,
                postalCode: retailer.address?.postalCode,
              })}
          </p>
          {isPreferred && (retailer.phone || retailer.mobilePhone) && (
            <p className={cx(styles['arc__ret-information'])}>
              {formatRetailerPhoneNumbers(t, retailer.phone, retailer.mobilePhone)}
            </p>
          )}
        </div>
      </div>
      <div className={cx(styles['arc__icon-wrapper'])}>
        {onButtonClick && (
          <Button
            className={cx(styles['arc__button'])}
            onClick={() => {
              onButtonClick?.(retailer);
            }}
            type="button"
            theme={ButtonThemes.SECONDARY}
            icon={isLoading ? SpinnerIcon : undefined}
            iconPosition={IconPosition.LEFT}
            iconClassName={cx(styles['arc__loading-icon'])}
            disabled={isLoading}
          >
            {isLoading ? t('general.removing') : t('general.remove')}
          </Button>
        )}
        {!onButtonClick && getCardIcon(isSelected, t)}
      </div>
    </div>
  );
};

AddRetailerCard.displayName = 'AddRetailerCard';

export default AddRetailerCard;
