import dayjs, { isPurchaseOneYearOld } from '../../../../lib/utils/dates';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';

import { ButtonThemes } from '../../../../lib/constants/components';
import { Cell } from '../../../_shared/TableAccordion/TableAccordion';
import CropProtectionSection from './CropProtectionSection';
import cx from 'classnames';
import { FC } from 'react';
import { formatRetailerName } from '../../../../lib/utils/retailers';
import InvoiceSection from './InvoiceSection';
import LinkButton from '../../../_shared/LinkButton';
import { PreOrderProduct } from '../../../../__generated__/graphql';
import { Link as RouterLink } from 'react-router-dom';
import { slashSeparatedYearFirst } from '../../../../lib/constants/date-formats';
import styles from './TableRow.module.scss';
import TableAccordion from '../../../_shared/TableAccordion';
import { useTranslation } from 'react-i18next';

export interface Props {
  item: PreOrderProduct;
  className?: string;
  showBreakdownCta?: boolean;
}

const PreOrderRow: FC<Props> = ({ item, className, showBreakdownCta = false }) => {
  const { t } = useTranslation();

  const getDesktopRows = (): Cell[] => {
    const cells: Cell[] = [
      {
        content: item.product.name,
        align: 'left',
        key: 'name',
      },
      {
        content: item.quantity,
        align: 'right',
        key: 'quantity',
      },
      {
        content: dayjs(item.preOrder.date).format(slashSeparatedYearFirst),
        align: 'center',
        key: 'date',
      },
    ];

    cells.push({
      content: item.preOrder.retailer.address
        ? t('purchases.details.retailer', {
            name: formatRetailerName(t, item.preOrder.retailer),
            city: item.preOrder.retailer?.address?.city || '',
            province: item.preOrder.retailer?.address?.province || '',
          })
        : formatRetailerName(t, item.preOrder.retailer),
      key: 'retailer',
    });

    return cells;
  };

  return (
    <TableAccordion
      key={item.id}
      className={className}
      cells={getDesktopRows()}
      mobileCells={[
        {
          key: 'data',
          content: (
            <div className={cx(styles['mobile-cell'])}>
              <div className={cx(styles['mobile-cell__inner'])}>
                <span className={cx(styles['mobile-cell__title'])}>{item.product.name}</span>
                <div className={cx(styles['mobile-cell__date-programs-wrap'])}>
                  <span className={cx(styles['mobile-cell__date'])}>
                    {dayjs(item.preOrder.date).format(slashSeparatedYearFirst)}
                  </span>
                </div>
                <span className={cx(styles['mobile-cell__retailer'])}>
                  {t('purchases.details.retailer', {
                    name: formatRetailerName(t, item.preOrder.retailer),
                    city: item.preOrder?.retailer?.address?.city || '',
                    province: item.preOrder?.retailer?.address?.province || '',
                  })}
                </span>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className={cx(styles['table-row-content'])}>
        <InvoiceSection
          date={item.preOrder.date}
          itemId={item.preOrder.id}
          itemType="pre-order"
          products={item.preOrder.preOrderProducts}
        />
        <div className={cx(styles['rewards-earned'])}>
          <CropProtectionSection />
          <div className={cx(styles['rewards-program__button-wrapper'])}>
            <LinkButton
              href={LinkUrls.CATALOG_URL}
              target="_blank"
              className={cx(styles['rewards-program__button'])}
              theme={ButtonThemes.PRIMARY}
            >
              {t('purchases.details.view-catalog')}
            </LinkButton>
            {isPurchaseOneYearOld(item.preOrder.date) && showBreakdownCta && (
              <LinkButton
                as={RouterLink}
                to={`/${PageRoutes.REWARDS}`}
                className={cx(styles['rewards-program__button'])}
                theme={ButtonThemes.SECONDARY}
              >
                {t('purchases.details.view-breakdown')}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </TableAccordion>
  );
};

export default PreOrderRow;
