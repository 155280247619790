import { DropdownOption } from './react-select';
import { Language as GQLLanguage } from '../../__generated__/graphql';
import { TFunction } from 'i18next';

export const LANGUAGE_KEY = 'language';

export enum Language {
  En = 'en',
  Fr = 'fr',
}

export const languageOptions = (t: TFunction): DropdownOption[] => [
  { value: GQLLanguage.En, label: t('header.languages.en') },
  { value: GQLLanguage.Fr, label: t('header.languages.fr') },
];
