import { createContext, FC, useState } from 'react';
import { PaginationContextProps, PaginationContextType } from './PaginationContext.types';

const PaginationContext = createContext<PaginationContextType>([0, () => {}]);

export const PaginationProvider: FC<PaginationContextProps> = ({ children }) => {
  const value = useState<number>(0);

  return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>;
};

export default PaginationContext;
