import { Axis, LinearChartData } from './LinearChart.types';
import {
  axisConfig,
  axisLabelConfig,
  chartConfig,
  cursorConfig,
  gridConfig,
  lineConfig,
  valueFormatter,
} from './LinearChart.config';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import cx from 'classnames';
import { FC } from 'react';
import LinearChartLegends from './subcomponents/LinearChartLegends/LinearChartLegends';
import LinearChartTooltip from './subcomponents/LinearChartTooltip';
import styles from './LinearChart.module.scss';
import { useBreakpoint } from '../../lib/hooks';
import { useTranslation } from 'react-i18next';

export interface Props {
  data: LinearChartData;
  yAxisLabel: string;
  xAxisKey: string;
}

const LinearChart: FC<Props> = ({ data, xAxisKey, yAxisLabel }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isXs } = useBreakpoint();

  return (
    <div className={cx(styles['linear-chart'])}>
      <ResponsiveContainer>
        <LineChart
          data={data.dataset}
          {...chartConfig(isXs)}
          className={cx(styles['linear-chart__wrapper'])}
        >
          <CartesianGrid vertical={false} {...gridConfig} />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => valueFormatter(value, language)}
            {...axisConfig(Axis.Y_AXIS, isXs)}
          >
            {!isXs && (
              <Label
                className={cx(styles['linear-chart__label'])}
                value={yAxisLabel}
                position="left"
                {...axisLabelConfig}
              />
            )}
          </YAxis>
          <XAxis
            dataKey={xAxisKey}
            axisLine={false}
            tickLine={false}
            {...axisConfig(Axis.X_AXIS)}
          />
          <Tooltip
            content={<LinearChartTooltip language={language} />}
            cursor={{ ...cursorConfig }}
          />
          <Legend content={<LinearChartLegends />} />
          {data.lines.map((line) => (
            <Line
              type="linear"
              dot={false}
              key={line.key}
              dataKey={line.key}
              stroke={line.color}
              name={t(line.label)}
              {...lineConfig}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

LinearChart.displayName = 'LinearChart';

export default LinearChart;
