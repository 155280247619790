import { FC, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Disclaimer.module.scss';

export interface Props {
  title: string;
  content?: string | ReactElement;
  className?: string;
  children?: ReactNode;
}

const Disclaimer: FC<Props> = ({ title, content, className, children }) => (
  <div className={cx(className, styles['disclaimer'])}>
    <p className={cx(className, styles['disclaimer__title'])}>{title}</p>
    <p className={cx(className, styles['disclaimer__content'])}>{content}</p>
    {children}
  </div>
);

Disclaimer.displayName = 'Disclaimer';

export default Disclaimer;
