import { Farm, FarmUpdateInput, PaymentPreferenceOptions } from '../../../../__generated__/graphql';
import { FarmInfoExtended, PaymentPreferenceExtended } from '../../../../lib/models/Farm.model';
import { FC, useEffect, useRef, useState } from 'react';
import {
  GET_FARM_INFORMATION_QUERY,
  UPDATE_FARM_INFORMATION_MUTATION,
} from '../../../../lib/graphql/FarmInformation.gql';
import { maskPostalCode, unmaskPostalCode } from '../../../../lib/utils/form-validations';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import ChildUserFarmInformation from './subcomponents/ChildUserFarmInformation';
import ChildUserPaymentPreferences from './subcomponents/ChildUserPaymentPreferences';
import ChildUserTransferOwnership from './subcomponents/ChildUserTransferOwnership';
import cx from 'classnames';
import FarmInformation from './subcomponents/FarmInformation';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import ImageClient from '../../../../lib/clients/image-client';
import PaymentPreferences from '../../../Onboarding/Setup/steps/FarmInformation/PaymentPreferences';
import SettingsWrapper from '../../components/SettingsWrapper';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SourceImageType } from '../../../../lib/constants/images';
import styles from './MyOperation.module.scss';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { usePlacesApi } from '../../../../lib/hooks/usePlacesApi';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { v4 as uuid } from 'uuid';

const MyOperation: FC = () => {
  const { t } = useTranslation();
  const {
    data: farmData,
    loading: loadingFarmData,
    error: errorFarmData,
    refetch,
  } = useQuery(GET_FARM_INFORMATION_QUERY);
  const {
    data: userData,
    loading: loadingUserData,
    error: errorUserData,
  } = useQuery(GET_USER_IS_ADMIN_QUERY);
  const [updateFarmInfo] = useMutation(UPDATE_FARM_INFORMATION_MUTATION, {
    refetchQueries: [{ query: GET_FARM_INFORMATION_QUERY }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();
  const [photo, setPhoto] = useState<File | null>(null);
  const [parentFarm, setParentFarm] = useState<Partial<Farm>>();
  const [deletePhoto, setDeletePhoto] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const placesApiLoaded = usePlacesApi();
  const formId = useRef(uuid());

  const {
    reset,
    watch,
    control,
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    trigger,
  } = useForm<FarmInfoExtended>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  useFormErrors(trigger, dirtyFields);

  const preferredOption = watch('paymentPreference.preferredOption');
  const emailAddress = watch('paymentPreference.directDepositEmail');

  /**
   * Check if there is a change in the directDepositEmail or if the Mail Cheque option was selected.
   * If either condition is met, the consent to receive EFT  will marked as unselected.
   */
  useEffect(() => {
    if (
      (preferredOption === PaymentPreferenceOptions.Mail ||
        emailAddress !== farmData?.farm.farmInfo.paymentPreference.directDepositEmail) &&
      !parentFarm
    ) {
      setValue('paymentPreference.emailAccepted', false);
    }
  }, [
    emailAddress,
    farmData?.farm.farmInfo.paymentPreference.directDepositEmail,
    parentFarm,
    preferredOption,
    setValue,
  ]);

  /**
   * Set the initial state of the form based on whether the current farm is the parent farm.
   * If the current farm is the parent farm, it will use its own payment preference information.
   * If the current farm is not the parent farm, it will inherit the payment preference information from the parent farm.
   */
  useEffect(() => {
    const paymentPreference =
      parentFarm && parentFarm.farmInfo
        ? {
            ...parentFarm.farmInfo.paymentPreference,
            mailingAddress: {
              address: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.address || '',
              address2: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.address2 || '',
              city: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.city || '',
              province: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.province,
              placeId: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.placeId || '',
              postalCode: maskPostalCode(
                parentFarm.farmInfo?.paymentPreference?.mailingAddress?.postalCode || ''
              ),
            },
            emailAccepted: !!parentFarm.farmInfo.paymentPreference.eftConsentDate,
          }
        : {
            ...farmData?.farm.farmInfo.paymentPreference,
            mailingAddress: {
              address: farmData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.address || '',
              address2: farmData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.address2 || '',
              city: farmData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.city || '',
              province: farmData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.province,
              placeId: farmData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.placeId || '',
              postalCode: maskPostalCode(
                farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.postalCode || ''
              ),
            },
            exchangeAccepted:
              farmData?.farm.farmInfo.paymentPreference.preferredOption ===
              PaymentPreferenceOptions.Exchange,
            emailAccepted: !!farmData?.farm.farmInfo.paymentPreference.eftConsentDate,
          };

    reset({
      name: farmData?.farm.farmInfo.name,
      address: {
        address: farmData?.farm?.farmInfo?.address?.address || '',
        address2: farmData?.farm?.farmInfo?.address?.address2 || '',
        city: farmData?.farm?.farmInfo?.address?.city || '',
        province: farmData?.farm?.farmInfo?.address?.province,
        placeId: farmData?.farm?.farmInfo.address?.placeId || '',
        postalCode: maskPostalCode(farmData?.farm?.farmInfo?.address?.postalCode || ''),
      },
      paymentPreference: {
        ...paymentPreference,
      },
    });
  }, [farmData, parentFarm, reset]);

  /** Determine if the current farm is the parent of a child farm. */
  useEffect(() => {
    if (farmData?.farm.partners) {
      farmData?.farm.partners.forEach((partner) => {
        if (partner.isParent) {
          setParentFarm(partner.partner as Farm);
        }
      });
    }
  }, [farmData?.farm.partners]);

  /** Catches errors that may occur during GraphQL queries. */
  useEffect(() => {
    if (errorFarmData || errorUserData) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, errorFarmData, errorUserData, t]);

  const getPaymentPreferences = (
    paymentPreference: PaymentPreferenceExtended,
    consentDate: Date
  ) => {
    switch (paymentPreference.preferredOption) {
      case PaymentPreferenceOptions.Eft:
        return {
          chequePayeeName: null,
          directDepositEmail: paymentPreference.directDepositEmail,
          eftConsentDate: dirtyFields.paymentPreference?.emailAccepted ? new Date() : consentDate,
          mailingAddress: {
            address: null,
            address2: null,
            city: null,
            placeId: null,
            postalCode: null,
            province: null,
          },
        };
      case PaymentPreferenceOptions.Mail:
        return {
          chequePayeeName: paymentPreference.chequePayeeName,
          directDepositEmail: null,
          eftConsentDate: null,
          mailingAddress: {
            ...paymentPreference.mailingAddress,
            postalCode:
              unmaskPostalCode(paymentPreference.mailingAddress?.postalCode || '') || null,
          },
        };
      case PaymentPreferenceOptions.Exchange:
      default:
        return {
          chequePayeeName: null,
          directDepositEmail: null,
          eftConsentDate: null,
          mailingAddress: {
            address: null,
            address2: null,
            city: null,
            placeId: null,
            postalCode: null,
            province: null,
          },
        };
    }
  };

  const onSubmit = (formData: FarmInfoExtended) => {
    if (farmData?.farm?.farmInfo?.id && (isDirty || photo || deletePhoto || !!parentFarm)) {
      const consentDate = parentFarm
        ? parentFarm.farmInfo?.paymentPreference.eftConsentDate
        : farmData.farm.farmInfo.paymentPreference.eftConsentDate;

      const paymentPreferences = getPaymentPreferences(formData.paymentPreference, consentDate);

      const farmDetails: FarmUpdateInput = {
        ...paymentPreferences,
        farmId: farmData?.farm?.farmInfo?.id,
        name: formData.name,
        address: {
          ...formData.address,
          postalCode: unmaskPostalCode(formData.address?.postalCode || ''),
        },
        preferredOption: formData.paymentPreference.preferredOption,
      };

      const uploadImage = photo
        ? ImageClient.uploadImage(photo, SourceImageType.FARM, farmData.farm.farmInfo.id).then(
            () => {
              refetch();
              setPhoto(null);
            }
          )
        : null;

      const deleteImage = deletePhoto
        ? ImageClient.deleteImage(deletePhoto).then(() => {
            refetch();
            setDeletePhoto(null);
          })
        : null;

      const updateFarm =
        isDirty || !!parentFarm
          ? updateFarmInfo({
              variables: {
                input: farmDetails,
              },
            })
          : null;

      setIsSaving(true);

      Promise.all([uploadImage, updateFarm, deleteImage])
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('alert.updated-successfully'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <SettingsWrapper
      title={t('account-settings.nav.my-operation')}
      buttonType="submit"
      buttonForm={formId.current}
      disabled={!userData?.user?.accountInfo?.isAdmin || (!isDirty && !photo && !deletePhoto)}
      isLoadingData={loadingFarmData || loadingUserData}
      isUpdating={isSaving}
      onClick={handleSubmit(onSubmit)}
      hideSaveButton={!userData?.user?.accountInfo?.isAdmin}
    >
      {!userData?.user?.accountInfo?.isAdmin && <ChildUserTransferOwnership />}
      <form className={cx(styles['my-operation'])} id={formId.current}>
        {userData?.user?.accountInfo?.isAdmin ? (
          <FarmInformation
            register={register}
            control={control}
            errors={errors}
            farmData={farmData}
            photo={photo}
            placesApiLoaded={placesApiLoaded}
            setPhoto={setPhoto}
            setDeletePhoto={setDeletePhoto}
            setValue={setValue}
            watch={watch}
          />
        ) : (
          <ChildUserFarmInformation />
        )}
        <hr className={cx(styles['form-divider'])} />
        {userData?.user?.accountInfo?.isAdmin ? (
          <>
            <div className={cx(styles['my-operation__text-wrapper'])}>
              <h2 className={cx(styles['my-operation__heading'])}>
                {t('account-settings.my-operation.payment-preferences.heading')}
              </h2>
              <p className={cx(styles['my-operation__subtitle'])}>
                <Trans
                  i18nKey="account-settings.my-operation.payment-preferences.subtitle"
                  components={[<sup />]}
                />
              </p>
            </div>
            <PaymentPreferences
              register={register}
              unregister={unregister}
              setValue={setValue}
              control={control}
              errors={errors}
              watch={watch}
              placesApiLoaded={placesApiLoaded}
              isParentFarm={!parentFarm}
              isColony={!!farmData?.farm.farmInfo.isColony}
            />
          </>
        ) : (
          <ChildUserPaymentPreferences />
        )}
      </form>
    </SettingsWrapper>
  );
};

MyOperation.displayName = 'MyOperation';

export default MyOperation;
