import { createContext, FC, useReducer } from 'react';
import {
  OrderChartContextProps,
  OrderChartContextState,
  OrderChartContextType,
} from './OrderCharContext.types';

import OrderChartContextReducer from './reducer';
import { PaginationProvider } from '../pagination/PaginationContext';
import { PurchaseChartType } from '../../constants/purchases';

const initialState: OrderChartContextState = {
  filters: [],
  options: [],
  type: PurchaseChartType.ACRE,
};

const OrderChartContext = createContext<OrderChartContextType>([initialState, () => {}]);

export const OrderChartProvider: FC<OrderChartContextProps> = ({ children }) => {
  const value = useReducer(OrderChartContextReducer, initialState);

  return (
    <OrderChartContext.Provider value={value}>
      <PaginationProvider>{children} </PaginationProvider>
    </OrderChartContext.Provider>
  );
};

export default OrderChartContext;
