import { AppMocks } from './lib/mocks/app.mock';
import cx from 'classnames';
import { FC } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import MockProvider from './lib/contexts/MockProvider';
import { Outlet } from 'react-router-dom';
import RootProvider from './lib/contexts/RootProvider';
import SnackBarContainer from './components/SnackbarContainer';
import styles from './App.module.scss';

const App: FC = () => {
  const Provider = process.env.REACT_APP_USE_MOCKS ? MockProvider : RootProvider;

  return (
    <Provider mocks={process.env.REACT_APP_USE_MOCKS ? AppMocks : undefined}>
      <div className={cx(styles['app'])}>
        <Header className={cx(styles['app__header'])} />
        <main id="main" className={cx(styles['app__main'])}>
          <Outlet />
        </main>
        <SnackBarContainer className={cx(styles['app__snack'])} />
        <Footer className={cx(styles['app__footer'])} />
      </div>
    </Provider>
  );
};

export default App;
