import cx from 'classnames';
import { FC } from 'react';
import { InitialsTheme } from '../../../lib/constants/components';
import styles from './Avatar.module.scss';

export interface Props {
  profilePictureUrl?: string | null | undefined;
  firstName: string;
  lastName: string;
  className?: string;
  initialsTheme?: InitialsTheme;
}

const Avatar: FC<Props> = ({
  profilePictureUrl,
  firstName = '',
  lastName = '',
  className,
  initialsTheme = InitialsTheme.PRIMARY,
}) => {
  const initials: string = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return (
    <div
      className={cx(
        styles['avatar'],
        {
          [styles['avatar--loading']]: !profilePictureUrl && !initials,
          [styles['avatar__initials-wrapper']]: !profilePictureUrl,
          [styles[`avatar__initials-wrapper--${initialsTheme}`]]: !profilePictureUrl,
        },
        className
      )}
      style={profilePictureUrl ? { backgroundImage: `url('${profilePictureUrl}')` } : {}}
    >
      {!profilePictureUrl && (
        <span className={cx(styles['avatar__initials'])} aria-hidden>
          {initials}
        </span>
      )}
    </div>
  );
};

Avatar.displayName = 'Avatar';

export default Avatar;
