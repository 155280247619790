import { gql } from '../../__generated__';

export const GET_PURCHASES_PRE_ORDER_TABLES = gql(`
query OrderHistory($offset: Int, $orderBy: PurchasePreOrderSortOrder, $filterBy: PurchasePreOrderFilters, $limit: Int) {
  purchaseProducts(offset: $offset, orderBy: $orderBy, filterBy: $filterBy, limit: $limit) {
    purchaseProducts {
      id
      quantity
      product {
        sku
        name
      }
      purchase {
        purchaser
        invoiceDate
        invoiceId
        id
        retailer {
          id
          name
          alias
          address {
            city
            province
          }
        }
        purchaseProducts {
          quantity
          product {
            sku
            name
            preAuthUrl
          }
        }
        rewardPrograms {
          id
          name
          year
          type
        }
      }
      rewardPrograms {
        id
        name
        year
        type
      }
    }
    totalCount
  }
  preOrderProducts(offset: $offset, orderBy: $orderBy, filterBy: $filterBy, limit: $limit) {
    preOrderProducts {
      id
      quantity
      product {
        sku
        name
      }
      preOrder {
        id
        date
        retailer {
          id
          name
          alias
          address {
            city
            province
          }
        }
        preOrderProducts {
          quantity
          product {
            sku
            name
            preAuthUrl
          }
        }
      }
      
    }
    totalCount
  }
}`);

export const GET_ORDER_HISTORY_FILTERS_QUERY = gql(`
query PurchasePreOrderFilterOptions {
  purchaseFilterOptions {
    productFilterFields {
      sku
      name
    }
    programFilterFields {
      id
      name
    }
    retailerFilterFields {
      id
      name
    }
    purchaserFilterFields {
      id
      name
    }
  }
  preOrderFilterOptions {
    productFilterFields {
      sku
      name
    }
    retailerFilterFields {
      id
      name
    }
  }
}`);
