import { FC, SVGProps } from 'react';
import cx from 'classnames';
import styles from './BannerBadge.module.scss';

export interface Props {
  title: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

const BannerBadge: FC<Props> = ({ title, Icon }) => (
  <div className={cx(styles['banner-badge'])}>
    <Icon aria-hidden="true" className={cx(styles['banner-badge__icon'])} />
    <p className={cx(styles['banner-badge__text'])}>{title}</p>
  </div>
);

BannerBadge.displayName = 'BannerBadge';

export default BannerBadge;
