import { collapseElement, expandElement } from '../../../lib/utils/animations';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import cx from 'classnames';
import styles from './TableAccordion.module.scss';
import { useBreakpoint } from '../../../lib/hooks';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export type CellAlign = 'left' | 'center' | 'right';

export interface ToggleProps {
  onClick: () => void;
  controlId: string;
  isOpen?: boolean;
}

export interface Cell {
  content: ReactNode;
  align?: CellAlign;
  className?: string;
  key: string;
}

export interface Props {
  id?: string;
  cells: Cell[];
  mobileCells?: Cell[];
  children?: ReactNode;
  className?: string;
  defaultOpen?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}

const Toggle: FC<ToggleProps> = ({ onClick, controlId, isOpen }) => {
  const { t } = useTranslation();

  return (
    <button
      className={cx(styles['cell__toggle'], {
        [styles['cell__toggle--opened']]: isOpen,
      })}
      type="button"
      onClick={onClick}
      aria-expanded={isOpen}
      aria-controls={`accordion-panel-${controlId}`}
      aria-label={t(`purchases.table-toggle.${isOpen ? 'collapse' : 'expand'}`)}
    >
      <ChevronDownIcon className={cx(styles['cell__icon'])} aria-hidden="true" />
    </button>
  );
};

const TableAccordion: FC<Props> = ({
  id,
  cells = [],
  mobileCells = [],
  children,
  className,
  defaultOpen = false,
  onExpand,
  onCollapse,
}) => {
  const { isMd } = useBreakpoint();
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const contentRef = useRef<HTMLDivElement>(null);
  const accordionId = useRef<string>(id || uuid());

  useEffect(() => {
    setInitialRender(false);
    if (!defaultOpen) {
      (contentRef.current as HTMLElement).style.display = 'none';
    }
  }, []);

  const toggleItem = () => {
    const isCurrentlyOpen = !isOpen;
    setIsOpen(isCurrentlyOpen);

    if (isCurrentlyOpen) {
      expandElement(contentRef.current as HTMLElement, () => {
        onExpand?.();
      });
    } else {
      collapseElement(contentRef.current as HTMLElement, () => {
        onCollapse?.();
      });
    }
  };

  return (
    <>
      {!isMd && (
        <tr className={cx(className, styles['row'], { [styles['row--expanded']]: isOpen })}>
          <td className={cx(styles['cell'])} aria-labelledby={accordionId.current}>
            <Toggle onClick={toggleItem} controlId={accordionId.current} isOpen={isOpen} />
          </td>
          {cells.map((cell) => (
            <td
              className={cx(
                styles['cell'],
                styles[`cell--text-${cell.align || 'left'}`],
                cell.className
              )}
              key={cell.key}
            >
              {cell.content}
            </td>
          ))}
        </tr>
      )}
      {isMd && (
        <tr
          className={cx(styles['row-mobile'], {
            [styles['row-mobile--opened']]: isOpen,
          })}
        >
          {mobileCells.map((mobileCell) => (
            <td key={mobileCell.key} className={cx(styles['cell'])}>
              {mobileCell.content}
            </td>
          ))}
          <td className={cx(styles['cell'], styles['mobile-toggle-wrapper'])} aria-labelledby={accordionId.current}>
            <Toggle onClick={toggleItem} controlId={accordionId.current} isOpen={isOpen} />
          </td>
        </tr>
      )}
      <tr className={cx(styles['content-row'], { [styles['content-row--expanded']]: isOpen })}>
        <td colSpan={cells.length + 1} className={cx(styles['content-cell'])}>
          <div
            aria-hidden={!isOpen}
            id={`accordion-panel-${accordionId.current}`}
            ref={contentRef}
            className={cx(styles['content-cell__wrapper'], {
              [styles['content-cell__wrapper--initial']]: initialRender && !defaultOpen,
            })}
          >
            {children}
          </div>
        </td>
      </tr>
    </>
  );
};

TableAccordion.displayName = 'TableAccordion';

export default TableAccordion;
