import { gql } from '../../../../__generated__';

export const GET_RECENT_PURCHASES_FOR_DASHBOARD = gql(`
query PurchaseProducts($orderBy: PurchasePreOrderSortOrder, $limit: Int) {
  purchaseProducts(orderBy: $orderBy, limit: $limit) {
    purchaseProducts {
      id
      product {
        sku
        name
      }
      rewardPrograms {
        id
        name
        type
      }
    }
  }
}
`);
