import { SignUpStep, Step } from './stepper';
import CompleteAccount from '../../pages/AccountCreation/subpages/CompleteAccount/CompleteAccount';
import PhoneVerification from '../../pages/AccountCreation/subpages/PhoneVerification';
import PrimaryOwner from '../../pages/AccountCreation/subpages/PrimaryOwner';
import SignupCompleted from '../../pages/AccountCreation/subpages/SignupCompleted/SignupCompleted';
import VerificationMethod from '../../pages/AccountCreation/subpages/VerificationMethod';

export const SIGN_UP_STEPS: Step[] = [
  {
    id: SignUpStep.PRIMARY_OWNER,
    labelKey: 'signup.steps.primary-owner',
    index: 1,
    Component: PrimaryOwner,
  },
  {
    id: SignUpStep.VERIFICATION_METHOD,
    labelKey: 'signup.steps.verify-method',
    index: 2,
    Component: VerificationMethod,
  },
  {
    id: SignUpStep.PHONE_VERIFICATION,
    labelKey: 'signup.steps.verify-numer',
    index: 3,
    Component: PhoneVerification,
  },
  {
    id: SignUpStep.COMPLETE_ACCOUNT,
    labelKey: 'signup.steps.complete-account',
    index: 4,
    Component: CompleteAccount,
  },
  {
    id: SignUpStep.SIGN_UP_COMPLETE,
    labelKey: 'signup.steps.sign-up-complete',
    index: 5,
    Component: SignupCompleted,
  },
];
