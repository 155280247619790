import OrderHistoryContext from './RetailerContext';
import { RetailerContextType } from './RetailerContext.types';
import { useContext } from 'react';

const useRetailerContext = (): RetailerContextType => {
  const context = useContext(OrderHistoryContext);

  if (context === undefined) {
    throw new Error('useRetailerContext must be used within a RetailerProvider');
  }

  return context;
};

export default useRetailerContext;
