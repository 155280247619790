import { FC, SVGProps } from 'react';
import cx from 'classnames';
import styles from './ValidationCriteria.module.scss';
import { useTranslation } from 'react-i18next';
import { ValidationStateColors } from '../../../lib/constants/components';

export interface Props {
  label: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  validationStateColor?: ValidationStateColors;
  className?: string;
}

const ValidationCriteria: FC<Props> = ({
  label,
  icon,
  validationStateColor = ValidationStateColors.NOT_VALID,
  className,
}) => {
  const Icon = icon;
  const { t } = useTranslation();

  return (
    <div className={cx(styles['validation-criteria'], className)}>
      {Icon && (
        <span className={cx(styles['validation-criteria__icon-wrapper'])}>
          <Icon
            className={cx(
              styles['validation-criteria__icon'],
              styles[`validation-criteria__icon--${validationStateColor}`]
            )}
            aria-hidden="true"
          />
        </span>
      )}
      <p
        className={cx(
          styles['validation-criteria__label'],
          styles[`validation-criteria__label--${validationStateColor}`]
        )}
      >
        <span className={cx('sr-only')}>
          {t(
            `onboarding.password-criteria-${
              validationStateColor === ValidationStateColors.VALID ? 'passed' : 'failed'
            }`
          )}
        </span>
        {label}
      </p>
    </div>
  );
};

ValidationCriteria.displayName = 'ValidationCriteria';

export default ValidationCriteria;
