import { ComponentProps, Dispatch, FC, SetStateAction, useState } from 'react';
import { Responsibility, UserAccountInfo } from '../../../../__generated__/graphql';
import Avatar from '../../../../components/_shared/Avatar';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { ListType } from '../../../../lib/constants/users';
import { OptionsFooter } from '../../../../lib/constants/react-select';
import styles from './UserPermissions.module.scss';
import { TrashCanIcon } from '../../../../assets/icons';
import { useTranslation } from 'react-i18next';

const OptionsFooterComponent: FC<ComponentProps<OptionsFooter['element']>> = ({
  className,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <button className={cx(styles['options-footer'])} type="button" onClick={onSelect}>
      <div className={cx(styles['options-footer__separator'])}></div>
      <div className={cx(className, styles['options-footer__content'])}>
        <span className={cx(styles['options-footer__label'])}>
          {t('onboarding.user-permissions.dropdown-options-footer')}
        </span>
        <TrashCanIcon className={cx(styles['options-footer__icon'])} aria-hidden="true" />
      </div>
    </button>
  );
};

export interface Props {
  user: UserAccountInfo;
  renderDropdown: (
    onSubmit: (data: any, setLoadingUsers: Dispatch<SetStateAction<string[]>>) => void,
    partner: UserAccountInfo,
    isLoading: boolean,
    optionsFooter: undefined | OptionsFooter,
    setLoadingUsers: Dispatch<SetStateAction<string[]>>
  ) => JSX.Element;
  updateUsers: (data: any, setLoadingUsers: Dispatch<SetStateAction<string[]>>) => void;
  listType: ListType;
  loadingUsers: string[];
  setLoadingUsers: Dispatch<SetStateAction<string[]>>;
  setRecommendedUser: (user: UserAccountInfo) => void;
  setRecommendedUserModalVisible: (isVisible: boolean) => void;
  setRemoveUser: (userId: string) => void;
}

const User: FC<Props> = ({
  user,
  renderDropdown,
  updateUsers,
  listType,
  loadingUsers,
  setLoadingUsers,
  setRecommendedUser,
  setRecommendedUserModalVisible,
  setRemoveUser,
}) => {
  const { t } = useTranslation();
  const [responsibilitiesOpen, setResponsibilitiesOpen] = useState<boolean>(false);
  const { email, crmEmail, firstName, lastName, profilePictureUrl } = user;
  const optionsFooter: OptionsFooter = {
    element: OptionsFooterComponent,
    onSelect: () => setRemoveUser(user.id),
  };

  const renderResponsibilities = (responsibilities: Responsibility[]): string =>
    responsibilities
      .map((responsibility) => t(`responsibilities.${responsibility.key}`))
      .join(', ');

  return (
    <div className={cx(styles['user'])}>
      {listType === ListType.ACTIVE && (
        <Avatar firstName={firstName} lastName={lastName} profilePictureUrl={profilePictureUrl} />
      )}
      <div className={cx(styles['user__text-wrapper'])}>
        {user.role && <p className={cx(styles['user__role'])}>{t(`roles.${user.role.key}`)}</p>}
        <p className={cx(styles['user__name'])}>{`${firstName} ${lastName}`}</p>
        <p className={cx(styles['user__email'])}>{email || crmEmail}</p>
        {!!user.responsibilities && user.responsibilities.length > 0 && (
          <>
            <p
              className={cx(styles['user__responsibilities'], {
                [styles['user__responsibilities--open']]: responsibilitiesOpen,
              })}
            >
              {renderResponsibilities(user.responsibilities)}
            </p>
            {user.responsibilities.length > 1 && (
              <div>
                <Button
                  theme={ButtonThemes.TEXT_LINK_SECONDARY}
                  type="button"
                  onClick={() => setResponsibilitiesOpen(!responsibilitiesOpen)}
                >
                  {responsibilitiesOpen
                    ? t('account-settings.user-permissions.view-less')
                    : t('account-settings.user-permissions.view-more')}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div className={cx(styles['user__action-wrapper'])}>
        {listType === ListType.ACTIVE || listType === ListType.INVITED ? (
          renderDropdown(
            updateUsers,
            user,
            loadingUsers.includes(user.id),
            optionsFooter,
            setLoadingUsers
          )
        ) : (
          <Button
            className={cx(styles['user__invite-button'])}
            type="button"
            onClick={() => {
              setRecommendedUser(user);
              setRecommendedUserModalVisible(true);
            }}
          >
            {t('general.invite')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default User;
