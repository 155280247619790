import {
  DateRangeOptions,
  DEBOUNCE_DELAY,
  FilterForm,
  Period,
  QueryFilterCategory,
  QueryForm,
} from '../../../lib/constants/forms';
import { FC, memo, useEffect, useRef, useState } from 'react';
import {
  formatOrderHistoryFilterOptions,
  getFilterDateRange,
  getFilterDropdownOptions,
  getFilterFormInitialData,
} from '../../../lib/utils/order-purchases';
import { useBreakpoint, useDebounce, useDeepCompare } from '../../../lib/hooks';

import { AllFiltersOptions } from './FilterSection.const';
import cx from 'classnames';
import FilterSectionDesktop from './FilterSectionDesktop';
import FilterSectionMobile from './FilterSectionMobile';
import { PurchaseHistoryFilters } from '../../../lib/models/Purchase.model';
import { PurchasePreOrderFilterOptionsQuery } from '../../../__generated__/graphql';
import SearchBar from '../../../components/_shared/SearchBar';
import styles from './FilterSection.module.scss';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  isLoading: boolean;
  filters: PurchasePreOrderFilterOptionsQuery | undefined;
  onFiltersChange: (
    appliedFilters: PurchaseHistoryFilters,
    query: string,
    selectedDateRange: DateRangeOptions,
    customDate?: Period,
    limit?: number,
    offset?: number
  ) => void;
}

const FilterSection: FC<Props> = ({ isLoading = false, filters, onFiltersChange }) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint();
  const formId = useRef(uuid());
  const [dropdownOptions, setDropdownOptions] = useState<AllFiltersOptions>();
  const [formState, setFormState] = useState<FilterForm>(getFilterFormInitialData());
  const debouncedFilterState = useDebounce<FilterForm>(formState, DEBOUNCE_DELAY);
  const [formToSubmit, setFormToSubmit] = useState<FilterForm>(formState);
  const deepComparedValue = useDeepCompare(formToSubmit);
  const [searchParamsFiltered, setSearchParamsFiltered] = useState<boolean>(false);

  const { handleSubmit, register, watch, reset } = useForm<QueryForm>({
    defaultValues: {
      query: getFilterFormInitialData().query,
    },
  });

  useEffect(() => {
    if (filters) {
      const formattedFilters = formatOrderHistoryFilterOptions(filters);
      const options = {
        products: getFilterDropdownOptions(formattedFilters.productFilters, t),
        programs: getFilterDropdownOptions(formattedFilters.programFilters, t),
        purchaser: getFilterDropdownOptions(formattedFilters.purchaserFilters, t),
        retailers: getFilterDropdownOptions(formattedFilters.retailerFilters, t),
      };

      setDropdownOptions(options);
      const filterFormState = (currentState: FilterForm): FilterForm => ({
        ...currentState,
        product: currentState.product.filter((productId) =>
          formattedFilters.productFilters.find((filterOption) => filterOption.id === productId)
        ),
        retailer: currentState.retailer.filter((productId) =>
          formattedFilters.retailerFilters.find((filterOption) => filterOption.id === productId)
        ),
        program: currentState.program.filter((productId) =>
          formattedFilters.programFilters.find((filterOption) => filterOption.id === productId)
        ),
        purchaser: currentState.purchaser.filter((purchaserId) =>
          formattedFilters.purchaserFilters.find((filterOption) => filterOption.id === purchaserId)
        ),
      });

      setFormState(filterFormState);
      setFormToSubmit(filterFormState);

      setSearchParamsFiltered(true);
    }
  }, [filters, t]);

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data: QueryForm) => {
        setFormState((currentState) => ({ ...currentState, query: data.query }));
      })()
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  useEffect(() => {
    if (debouncedFilterState) {
      setFormToSubmit(debouncedFilterState);
    }
  }, [debouncedFilterState]);

  useEffect(() => {
    if (deepComparedValue) {
      const appliedFilters: PurchaseHistoryFilters = {
        productSkus: deepComparedValue?.product,
        programIds: deepComparedValue?.program,
        farmIds: deepComparedValue?.purchaser,
        retailerIds: deepComparedValue?.retailer,
      };

      const customDate: Period = getFilterDateRange(
        deepComparedValue?.dateRange,
        deepComparedValue?.customDateRangeStart,
        deepComparedValue?.customDateRangeEnd
      );

      onFiltersChange(
        appliedFilters,
        deepComparedValue?.query,
        deepComparedValue?.dateRange,
        customDate
      );
    }
  }, [deepComparedValue, onFiltersChange]);

  const renderFilters = () => {
    if (isLoading || !searchParamsFiltered) {
      return <div className={cx({ [styles['filter-section__loading']]: isLoading })} />;
    } else if (isMd) {
      return (
        <FilterSectionMobile
          dropdownOptions={dropdownOptions}
          resetParentForm={reset}
          formState={formToSubmit}
          setParentFormState={setFormState}
        />
      );
    }

    return (
      <FilterSectionDesktop
        dropdownOptions={dropdownOptions}
        resetParentForm={reset}
        formState={formToSubmit}
        setParentFormState={setFormState}
      />
    );
  };

  return (
    <section className={cx(styles['filter-section'])}>
      <form
        className={cx(styles['filter-section__form'])}
        id={formId.current}
        onSubmit={handleSubmit(() => {})}
      >
        <SearchBar
          className={cx(styles['filter-section__search'])}
          {...register(QueryFilterCategory.QUERY)}
        />
      </form>
      {renderFilters()}
    </section>
  );
};

FilterSection.displayName = 'FilterSection';

export default memo(FilterSection);
