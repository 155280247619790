import {
  Address,
  Farm,
  FarmUpdateInput,
  PaymentPreferenceOptions,
} from '../../../../../__generated__/graphql';
import { Controller, useForm } from 'react-hook-form';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  GET_FARM_INFORMATION_QUERY,
  UPDATE_FARM_INFORMATION_MUTATION,
} from '../../../../../lib/graphql/FarmInformation.gql';
import {
  maskPostalCode,
  unmaskPostalCode,
  Validations,
} from '../../../../../lib/utils/form-validations';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import AutocompleteWrapper from '../../../../../components/AutocompleteWrapper';
import { BellIcon } from '../../../../../assets/icons';
import { CharacterLimit } from '../../../../../lib/constants/character-limit';
import { checkImageSize } from '../../../../../lib/utils/images';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../../lib/constants/react-select';
import { FarmInfoExtended } from '../../../../../lib/models/Farm.model';
import { getProvinceDropdownOptions } from '../../../../../lib/utils/onboarding';
import { handleEnterKey } from '../../../../../lib/constants/google-maps';
import ImageClient from '../../../../../lib/clients/image-client';
import InfoBanner from '../../../../../components/_shared/InfoBanner';
import PaymentPreferences from './PaymentPreferences';
import { ProfilePhotoSize } from '../../../../../lib/constants/components';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SourceImageType } from '../../../../../lib/constants/images';
import { StepComponentProps } from '../../../../../lib/constants/stepper';
import StepsWrapper from '../../../StepsWrapper';
import styles from './FarmInformation.module.scss';
import TextField from '../../../../../components/_shared/TextField';
import UploadPhoto from '../../_shared/UploadPhoto/UploadPhoto';
import { useFormErrors } from '../../../../../lib/hooks/useFormErrors';
import { usePlacesApi } from '../../../../../lib/hooks/usePlacesApi';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { v4 as uuid } from 'uuid';

const FarmInformation: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const {
    data: farmData,
    error: farmError,
    loading: farmLoading,
    refetch,
  } = useQuery(GET_FARM_INFORMATION_QUERY);
  const [updateFarmInfo] = useMutation(UPDATE_FARM_INFORMATION_MUTATION, {
    refetchQueries: [{ query: GET_FARM_INFORMATION_QUERY }],
  });
  const [, dispatchSnackBar] = useSnackbarContext();
  const [photo, setPhoto] = useState<File | null>(null);
  const [photoUrl, setPhotoUrl] = useState('');
  const [deletePhoto, setDeletePhoto] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [parentFarm, setParentFarm] = useState<Partial<Farm>>();
  const inputPhoto = useRef<HTMLInputElement | null>(null);
  const formId = useRef<string>(uuid());
  const [provinces, setProvinces] = useState<DropdownOption[]>(getProvinceDropdownOptions(t));
  const placesApiLoaded = usePlacesApi();

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
    unregister,
    watch,
    reset,
    setValue,
    trigger,
  } = useForm<FarmInfoExtended>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  useFormErrors(trigger, dirtyFields);

  const preferredOption = watch('paymentPreference.preferredOption');
  const emailAddress = watch('paymentPreference.directDepositEmail');
  const placeId = watch('address.placeId');

  const shouldUpdateAddress = useMemo(
    () =>
      farmData?.farm.farmInfo.address &&
      !farmData?.farm.farmInfo.address.placeId &&
      placesApiLoaded &&
      !placeId,
    [farmData?.farm.farmInfo.address, placeId, placesApiLoaded]
  );

  /** Set the options for the province dropdown in the selected language */
  useEffect(() => {
    setProvinces(getProvinceDropdownOptions(t));
  }, [t]);

  /**
   * Check if there is a change in the directDepositEmail or if the Mail Cheque option was selected.
   * If either condition is met, the consent to receive EFT  will marked as unselected.
   */
  useEffect(() => {
    if (
      (preferredOption === PaymentPreferenceOptions.Mail ||
        emailAddress !== farmData?.farm.farmInfo.paymentPreference.directDepositEmail) &&
      !parentFarm
    ) {
      setValue('paymentPreference.emailAccepted', false);
    }
  }, [
    emailAddress,
    farmData?.farm.farmInfo.paymentPreference.directDepositEmail,
    parentFarm,
    preferredOption,
    setValue,
  ]);

  /**
   * Set the initial state of the form based on whether the current farm is the parent farm.
   * If the current farm is the parent farm, it will use its own payment preference information.
   * If the current farm is not the parent farm, it will inherit the payment preference information from the parent farm.
   */
  useEffect(() => {
    const paymentPreference =
      parentFarm && parentFarm.farmInfo
        ? {
            ...parentFarm.farmInfo.paymentPreference,
            mailingAddress: {
              address: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.address,
              address2: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.address2,
              city: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.city,
              province: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.province,
              placeId: parentFarm.farmInfo?.paymentPreference?.mailingAddress?.placeId,
              postalCode: maskPostalCode(
                parentFarm.farmInfo?.paymentPreference?.mailingAddress?.postalCode || ''
              ),
            },
            emailAccepted: !!parentFarm.farmInfo.paymentPreference.eftConsentDate,
          }
        : {
            ...farmData?.farm.farmInfo.paymentPreference,
            mailingAddress: {
              address: farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.address,
              address2: farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.address2,
              city: farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.city,
              province: farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.province,
              placeId: farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.placeId,
              postalCode: maskPostalCode(
                farmData?.farm.farmInfo?.paymentPreference?.mailingAddress?.postalCode || ''
              ),
            },
            emailAccepted: !!farmData?.farm.farmInfo.paymentPreference.eftConsentDate,
          };

    reset({
      name: farmData?.farm?.farmInfo?.name,
      address: {
        address: farmData?.farm?.farmInfo?.address?.address,
        address2: farmData?.farm?.farmInfo?.address?.address2 || '',
        city: farmData?.farm?.farmInfo?.address?.city,
        province: farmData?.farm?.farmInfo?.address?.province,
        placeId: farmData?.farm?.farmInfo?.address?.placeId,
        postalCode: maskPostalCode(farmData?.farm?.farmInfo?.address?.postalCode || ''),
      },
      paymentPreference: {
        ...paymentPreference,
      },
    });
    setPhotoUrl(farmData?.farm.farmInfo.farmLogoUrl || '');
  }, [farmData, parentFarm, reset]);

  /** Generate an image URL for user-submitted images. */
  useEffect(() => {
    if (photo) {
      const imageUrl = URL.createObjectURL(photo);
      setPhotoUrl(imageUrl);
    }
  }, [photo]);

  /** Determine if the current farm is the parent of a child farm. */
  useEffect(() => {
    if (farmData?.farm.partners) {
      farmData?.farm.partners.forEach((partner) => {
        if (partner.isParent) {
          setParentFarm(partner.partner as Farm);
        }
      });
    }
  }, [farmData?.farm.partners]);

  /** Catches errors that may occur during GraphQL queries. */
  useEffect(() => {
    if (farmError) {
      dispatchSnackBar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackBar, farmError, t]);

  const onSubmit = (formData: FarmInfoExtended) => {
    const paymentPreferenceDisabled =
      (parentFarm && parentFarm.farmInfo) || farmData?.farm.farmInfo?.isColony;

    if (farmData?.farm?.farmInfo?.id && (isDirty || photo || deletePhoto || !!parentFarm)) {
      const consentDate = parentFarm
        ? parentFarm.farmInfo?.paymentPreference.eftConsentDate
        : farmData.farm.farmInfo.paymentPreference.eftConsentDate;

      const paymentPreferences =
        formData.paymentPreference.preferredOption === PaymentPreferenceOptions.Eft
          ? {
              chequePayeeName: null,
              directDepositEmail: formData.paymentPreference.directDepositEmail,
              eftConsentDate: dirtyFields.paymentPreference?.emailAccepted
                ? new Date()
                : consentDate,
              mailingAddress: {
                address: null,
                address2: null,
                city: null,
                placeId: null,
                postalCode: null,
                province: null,
              },
            }
          : {
              chequePayeeName: formData.paymentPreference.chequePayeeName,
              directDepositEmail: null,
              eftConsentDate: null,
              mailingAddress: {
                ...formData.paymentPreference.mailingAddress,
                postalCode:
                  unmaskPostalCode(formData.paymentPreference.mailingAddress?.postalCode || '') ||
                  null,
              },
            };

      const farmDetails: FarmUpdateInput = {
        ...(paymentPreferenceDisabled ? {} : paymentPreferences),
        farmId: farmData?.farm?.farmInfo?.id,
        name: formData.name,
        address: {
          ...formData.address,
          postalCode: unmaskPostalCode(formData.address?.postalCode || ''),
        },
        ...(paymentPreferenceDisabled
          ? {}
          : { preferredOption: formData.paymentPreference.preferredOption }),
      };

      const uploadImage = photo
        ? ImageClient.uploadImage(photo, SourceImageType.FARM, farmData.farm.farmInfo.id).then(
            () => {
              refetch();
              setPhoto(null);
            }
          )
        : null;

      const deleteImage = deletePhoto
        ? ImageClient.deleteImage(deletePhoto).then(() => {
            refetch();
            setDeletePhoto(null);
          })
        : null;

      const updateFarm =
        isDirty || !!parentFarm
          ? updateFarmInfo({
              variables: {
                input: farmDetails,
              },
            })
          : null;

      setIsSaving(true);

      Promise.all([updateFarm, uploadImage, deleteImage])
        .then(() => {
          next?.();
        })
        .catch(() => {
          dispatchSnackBar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      next?.();
    }
  };

  const onPhotoChange = (e: any) => {
    setDeletePhoto(null);
    const newPhoto = e.target.files[0];
    if (newPhoto) {
      if (checkImageSize(newPhoto)) {
        setPhoto(newPhoto);
      } else {
        dispatchSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.size-limit'),
            state: SnackbarStates.WARNING,
          },
        });
      }
    }
  };

  const onBack = () => {
    back?.();
  };

  const handleAutocompleteFields = (address: Address) => {
    setValue('address', address, { shouldDirty: true, shouldValidate: true });
  };

  const handlePhotoUpload = () => {
    if (inputPhoto.current) {
      inputPhoto.current?.click();
    }
  };

  const handlePhotoDelete = () => {
    setPhoto(null);
    setPhotoUrl('');
    if (farmData?.farm.farmInfo.farmLogoId) {
      setDeletePhoto(farmData?.farm.farmInfo.farmLogoId);
    }
    if (inputPhoto.current) {
      inputPhoto.current.value = '';
    }
  };

  const renderAddressField = (): ReactNode => (
    <TextField
      className={cx(styles['form-input'], {
        [styles['form-input--error']]: !!errors.address?.address,
      })}
      label={t('form.labels.address')}
      {...register('address.address', {
        required: t('form.errors.required'),
        ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
      })}
      hasError={!!errors.address?.address}
      helperText={errors.address?.address?.message}
      onKeyDown={handleEnterKey}
    />
  );

  return (
    <StepsWrapper
      title={t('onboarding.farm-information.title')}
      subTitle={t('onboarding.farm-information.subtitle')}
      submit={{
        text: t('general.next'),
        onClick: () => {},
        buttonType: 'submit',
        buttonFormId: formId.current,
        disabled: farmLoading || isSaving,
        isSaving,
      }}
      back={{ text: t('general.back'), onClick: onBack }}
      loadingQuery={farmLoading}
    >
      <div className={cx(styles['farm-information'])}>
        <form onSubmit={handleSubmit(onSubmit)} id={formId.current}>
          <div className={cx(styles['form-header'])}>
            <p className={cx(styles['form-name'])}>{t('onboarding.farm-information.name')}</p>
            <UploadPhoto
              photoUrl={photoUrl}
              handlePhotoUpload={handlePhotoUpload}
              handlePhotoDelete={handlePhotoDelete}
              onPhotoChange={onPhotoChange}
              inputPhoto={inputPhoto}
              photoSize={ProfilePhotoSize.LG}
            />
          </div>
          <div className={cx(styles['form-group'])}>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.name,
                })}
                label={t('form.labels.farm-name')}
                {...register('name', {
                  required: t('form.errors.required'),
                  ...Validations.characterLimit(t, CharacterLimit.NAME),
                })}
                hasError={!!errors.name}
                helperText={errors.name?.message}
              />
            </div>
          </div>
          <hr className={cx(styles['form-divider'], styles['form-divider--invisible'])} />
          {shouldUpdateAddress && (
            <InfoBanner
              title={t('onboarding.address-banner')}
              className={cx(styles['farm-information__banner'])}
              closeButton={false}
              icon={BellIcon}
            />
          )}
          <div className={cx(styles['form-group'])}>
            <div className={cx(styles['form-row'])}>
              {placesApiLoaded ? (
                <AutocompleteWrapper handleAutocompleteFields={handleAutocompleteFields}>
                  {renderAddressField()}
                </AutocompleteWrapper>
              ) : (
                renderAddressField()
              )}
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.address?.address2,
                })}
                label={t('form.labels.address2')}
                {...register('address.address2', {
                  ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
                })}
                hasError={!!errors.address?.address2}
                helperText={errors.address?.address2?.message}
              />
            </div>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.address?.city,
                })}
                label={t('form.labels.city')}
                {...register('address.city', {
                  required: t('form.errors.required'),
                  ...Validations.characterLimit(t, CharacterLimit.CITY),
                })}
                hasError={!!errors.address?.city}
                helperText={errors.address?.city?.message}
              />
              <div className={cx(styles['form-nested-row'])}>
                <Controller
                  control={control}
                  name="address.province"
                  rules={{ required: t('form.errors.required') }}
                  render={({ field: { onChange, value, name } }) => (
                    <Dropdown
                      className={cx(styles['form-input'], {
                        [styles['form-input--dropdown']]: !!errors.address?.city,
                      })}
                      name={name}
                      value={provinces.find((p) => p.value === value)}
                      onChange={onChange}
                      label={t('form.labels.province')}
                      options={provinces}
                      hasError={!!errors.address?.province}
                      helperText={errors.address?.province?.message}
                    />
                  )}
                />
                <TextField
                  className={cx(styles['form-input'], {
                    [styles['form-input--error']]: !!errors.address?.postalCode,
                  })}
                  label={t('form.labels.postal-code')}
                  {...register('address.postalCode', {
                    required: t('form.errors.required'),
                    ...Validations.postalCode(t('form.labels.postal-code'), t),
                    onChange: ({ currentTarget }) => {
                      const { value } = currentTarget;
                      // eslint-disable-next-line no-param-reassign
                      currentTarget.value = maskPostalCode(value);
                    },
                  })}
                  hasError={!!errors.address?.postalCode}
                  helperText={errors.address?.postalCode?.message}
                />
              </div>
            </div>
          </div>

          <hr className={cx(styles['form-divider'])} />

          <div className={cx(styles['form-header'], styles['farm-information__header'])}>
            <p className={cx(styles['form-name'], styles['farm-information__heading'])}>
              {t('onboarding.payment-preferences.name')}
            </p>
            <p className={cx(styles['farm-information__subtitle'])}>
              <Trans i18nKey="onboarding.payment-preferences.subtitle" components={[<sup />]} />
            </p>
          </div>

          <PaymentPreferences
            register={register}
            unregister={unregister}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            placesApiLoaded={placesApiLoaded}
            isParentFarm={!parentFarm}
            isColony={!!farmData?.farm.farmInfo.isColony}
          />
        </form>
      </div>
    </StepsWrapper>
  );
};

FarmInformation.displayName = 'FarmInformation';

export default FarmInformation;
