import { components, OptionProps } from 'react-select';
import { DropdownOption, OptionsFooter } from '../../../../lib/constants/react-select';
import { CheckmarkIcon } from '../../../../assets/icons';
import cx from 'classnames';
import styles from '../Dropdown.module.scss';

const CustomOption = (props: OptionProps) => {
  const { data, isMulti } = props;
  const option = data as DropdownOption | OptionsFooter;

  return 'element' in option ? (
    <components.Option {...props}>
      <option.element
        className={cx(styles['option'], { [styles['option__focus']]: props.isFocused })}
        onSelect={option.onSelect}
      />
    </components.Option>
  ) : (
    <components.Option {...props}>
      <div className={cx(styles['option'], { [styles['option__focus']]: props.isFocused })}>
        <div className={cx(styles['option-layout'])}>
          <div className={cx(styles['option-content'])}>
            <div className={cx(styles['option-label-wrapper'])}>
              {option.iconLeft && (
                <span className={cx(styles['option-label-icon'])}>{option.iconLeft}</span>
              )}
              <span className={cx(styles['option-label'])}>{option.labelToDisplay}</span>
            </div>
            {option.description && (
              <span className={cx(styles['option-description'])}>{option.description}</span>
            )}
          </div>
          <span
            className={cx(styles['option-radio'], {
              [styles['option-radio--selected']]: props.isSelected || option.disabled,
              [styles['option-radio--disabled']]: option.disabled,
              [styles['option-radio--multiple']]: isMulti,
            })}
          >
            {isMulti && <CheckmarkIcon aria-hidden="true" />}
          </span>
        </div>
      </div>
    </components.Option>
  );
};

export default CustomOption;
