import { ChequeStatus } from '../../__generated__/graphql';
import { TFunction } from 'i18next';

export const mapChequeStatus = (status: ChequeStatus | null, t: TFunction) => {
  switch (status) {
    case ChequeStatus.Created:
      return t('bayer-value.rebates.status.created');
    case ChequeStatus.Issued:
      return t('bayer-value.rebates.status.issued');
    case ChequeStatus.Reconciled:
      return t('bayer-value.rebates.status.reconciled');
    case ChequeStatus.Returned:
      return t('bayer-value.rebates.status.returned');
    case ChequeStatus.Submitted:
      return t('bayer-value.rebates.status.submitted');
    case ChequeStatus.Void:
      return t('bayer-value.rebates.status.void');
    case ChequeStatus.PendingVoid:
      return t('bayer-value.rebates.status.void');
    default:
      return '';
  }
};
