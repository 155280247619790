import { FC, ReactNode } from 'react';
import cx from 'classnames';
import PreAuthFooter from '../PreAuthFooter';
import styles from './PreAuthContainer.module.scss';

export interface Props {
  children: ReactNode;
}

const PreAuthContainer: FC<Props> = ({ children }) => (
  <div className={cx(styles['pre-auth-container'])}>
    <div className={cx(styles['pre-auth-container__content'])}>{children}</div>
    <PreAuthFooter />
  </div>
);

PreAuthContainer.displayName = 'PreAuthContainer';

export default PreAuthContainer;
