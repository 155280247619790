import { FilterDropdownOption } from '../../../lib/utils/order-purchases';
import { Matcher } from 'react-day-picker';

export interface AllFiltersOptions {
  products: FilterDropdownOption[];
  programs: FilterDropdownOption[];
  purchaser: FilterDropdownOption[];
  retailers: FilterDropdownOption[];
}

export enum DateFilterView {
  OPTIONS = 'options',
  CUSTOM = 'custom',
}

export enum FilterModalView {
  ALL = 'all',
  DATE = 'date',
}

export type CustomDateRange = {
  startDate: undefined | Matcher | Matcher[];
  endDate: undefined | Matcher | Matcher[];
};
