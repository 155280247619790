import Pill, { PillColors, PillSizes } from '../../../../../components/_shared/Pill';
import cx from 'classnames';
import { FC } from 'react';
import { ProgramRewardMetrics } from '../../../../../__generated__/graphql';
import styles from '../../BayerValueProgram.module.scss';
import useBreakpoint from '../../../../../lib/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

export interface Props {
  programRewardMetrics: ProgramRewardMetrics[] | undefined;
}

const BayerValueWestTable: FC<Props> = ({ programRewardMetrics }) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();

  const getStatusText = (segment: ProgramRewardMetrics) =>
    segment.qualified
      ? t('bayer-value.segments.table-row-qualified')
      : t('bayer-value.segments.table-row-not-qualified');

  return (
    <table className={cx(styles['table'])}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.segments.west.table-header-segment')}
          </th>
          <th className={cx(styles['table__header'], styles[`table__header--right`])}>
            {t('bayer-value.segments.west.table-header-acre')}
          </th>
          <th className={cx(styles['table__header'], styles[`table__header--right`])}>
            {t('bayer-value.segments.west.table-header-status')}
          </th>
        </tr>
      </thead>
      <tbody className={cx(styles['table__tbody'])}>
        {programRewardMetrics?.map((segment) => (
          <tr key={segment.id} className={cx(styles['table__data-row'])}>
            <td
              className={cx(styles['table__data-cell'])}
              data-label={t('bayer-value.segments.west.table-header-segment')}
            >
              {segment.segment}
            </td>
            <td
              className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
              data-label={t('bayer-value.segments.west.table-header-acre')}
            >
              {segment.acres}
            </td>
            <td
              className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
              data-label={t('bayer-value.segments.west.table-header-status')}
            >
              <Pill
                text={getStatusText(segment)}
                aria-label={getStatusText(segment)}
                color={segment.qualified ? PillColors.GREEN : PillColors.RED}
                size={PillSizes.SM}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

BayerValueWestTable.displayName = 'BayerValueWestTable';

export default BayerValueWestTable;
