import { FC, memo } from 'react';
import { ChevronDownIcon } from '../../../../assets/icons';
import cx from 'classnames';
import styles from './TableSkeleton.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';

export interface Props {
  numberOfRows: number;
  numberOfCells: number;
}

const TableSkeleton: FC<Props> = ({ numberOfRows, numberOfCells }) => {
  const { isMd } = useBreakpoint();

  return isMd ? (
    <>
      {Array.from(Array(numberOfRows).keys()).map((el) => (
        <tr key={el} className={cx(styles['skeleton__row'])}>
          <td className={cx(styles['skeleton__cell'])} colSpan={numberOfCells - 1} aria-hidden>
            <div className={cx(styles['skeleton'])}></div>
            <div className={cx(styles['skeleton'])}></div>
            <div className={cx(styles['skeleton'])}></div>
          </td>
          <td className={cx(styles['skeleton__cell'], styles['skeleton__cell--top'])} aria-hidden>
            <div className={cx(styles['skeleton__icon-wrapper'])}>
              <ChevronDownIcon className={cx(styles['skeleton__icon'])} aria-hidden="true" />
            </div>
          </td>
        </tr>
      ))}
    </>
  ) : (
    <>
      {Array.from(Array(numberOfRows).keys()).map((el) => (
        <tr key={el} className={cx(styles['skeleton__row'])}>
          <td className={cx(styles['skeleton__cell'])} colSpan={numberOfCells} aria-hidden="true">
            <div className={cx(styles['skeleton'])}></div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default memo(TableSkeleton);
