import {
  AllFiltersForm,
  DateRangeFiltersForm,
  DateRangeOptions,
} from '../../../../../lib/constants/forms';
import { Control, UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FC, useState } from 'react';

import CustomDateRangeFilter from '../CustomDateRangeFilter/CustomDateRangeFilter';
import cx from 'classnames';
import { DateFilterView } from '../../FilterSection.const';
import DateRangeFilterOptions from '../DateRangeFilterOptions/DateRangeFilterOptions';
import styles from './DateRangeFilter.module.scss';

export interface Props {
  formControl: Control<DateRangeFiltersForm, object>;
  registerRadioButtons: UseFormRegisterReturn;
  setValue: UseFormSetValue<AllFiltersForm>;
  watch: UseFormWatch<DateRangeFiltersForm>;
  selectedDateRange: DateRangeOptions;
}

const DateRangeFilterDesktop: FC<Props> = ({
  formControl,
  registerRadioButtons,
  selectedDateRange,
  setValue,
  watch,
}) => {
  const { startDate, endDate } = watch();
  const [view, setView] = useState<DateFilterView>(
    selectedDateRange === DateRangeOptions.CUSTOM ? DateFilterView.CUSTOM : DateFilterView.OPTIONS
  );

  const renderView = () => {
    if (view === DateFilterView.CUSTOM) {
      return (
        <CustomDateRangeFilter
          formControl={formControl}
          watch={watch}
          setView={setView}
          setValue={setValue}
          initialValues={{
            startDate: selectedDateRange === DateRangeOptions.CUSTOM ? startDate : undefined,
            endDate: selectedDateRange === DateRangeOptions.CUSTOM ? endDate : undefined,
          }}
        />
      );
    }

    return (
      <DateRangeFilterOptions
        onCustomClick={() => {
          setView(DateFilterView.CUSTOM);
        }}
        registerRadioButtons={registerRadioButtons}
      />
    );
  };

  return <div className={cx(styles['date-range-filter'])}>{renderView()}</div>;
};

export default DateRangeFilterDesktop;
