import { FC, ReactNode, useEffect, useState } from 'react';
import {
  getActiveSteps,
  getAdjacentSteps,
  getStepById,
  getStepsCompletionPercentage,
} from '../../lib/utils/stepper';
import { ChevronDownIcon } from '../../assets/icons';
import cx from 'classnames';
import Step from './subcomponents/Step';
import { Step as StepType } from '../../lib/constants/stepper';
import styles from './Stepper.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  steps: StepType[];
  onChange: (newStepId: string, nextStep?: StepType, backStep?: StepType) => void;
  className?: string;
  currentStepId?: string | null;
  footer?: ReactNode;
}

const Stepper: FC<Props> = ({ steps, onChange, className, currentStepId, footer }) => {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuCurrentStep, setMenuCurrentStep] = useState<StepType | undefined>();
  const [activeStepsIds, setActiveStepsIds] = useState<string[]>([]);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  useEffect(() => {
    if (currentStepId) {
      const currentStep = getStepById(steps, currentStepId);
      if (currentStep) {
        const activeSteps = getActiveSteps(steps, currentStepId);
        const { next, previous } = getAdjacentSteps(steps, currentStepId);
        onChange(currentStepId, next || undefined, previous || undefined);
        setActiveStepsIds(activeSteps.map((s) => s.id));

        if (activeSteps.length) {
          setMenuCurrentStep(activeSteps[activeSteps.length - 1]); // top level step is always the last one
        }
      }
    }
  }, [currentStepId]);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  useEffect(() => {
    setProgressPercentage(getStepsCompletionPercentage(steps));
  }, [steps]);

  const onStepChange = (step: StepType) => {
    const activeSteps = getActiveSteps(steps, step.id);
    const { next, previous } = getAdjacentSteps(steps, step.id);

    if (activeSteps.length) {
      setMenuCurrentStep(activeSteps[activeSteps.length - 1]); // top level step is always the last one
    }

    if (step.Component) {
      setMenuOpen(false);
    }

    setActiveStepsIds(activeSteps.map((s) => s.id));
    onChange(step.id, next || undefined, previous || undefined);
  };

  const onMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={cx(styles['stepper'], className)}>
      <progress max="100" value={progressPercentage || 0} className={cx(styles['progress-bar'])}>
        {t('stepper.progressPercentage', {
          percentage: progressPercentage.toFixed(2),
        })}
      </progress>
      <div className={cx(styles['mobile-menu'])}>
        <div className={cx(styles['step'], styles['step--active'], styles['step--on-menu'])}>
          <div className={cx(styles['step__number'])}>{menuCurrentStep?.index}</div>
          <span className={cx(styles['step__label'])}>{t(menuCurrentStep?.labelKey || '')}</span>
          <button
            type="button"
            aria-label={
              menuOpen ? t('stepper.step-toggle.collapse') : t('stepper.step-toggle.expand')
            }
            onClick={onMenuToggle}
            className={cx(styles['mobile-menu__toggle'], {
              [styles['mobile-menu__toggle--menu-open']]: menuOpen,
            })}
          >
            <ChevronDownIcon className={cx(styles['mobile-menu__icon'])} aria-hidden="true" />
          </button>
        </div>
      </div>
      <div
        className={cx(styles['steps-container'], {
          [styles['steps-container--menu-open']]: menuOpen,
        })}
      >
        <ol className={cx(styles['steps-container__list'])}>
          {steps.map((step) => (
            <Step
              step={step}
              onStepChange={onStepChange}
              activeStepsIds={activeStepsIds}
              key={step.id}
            />
          ))}
        </ol>
        {footer && (
          <div
            className={cx(styles['stepper-footer'], {
              [styles['stepper-footer--menu-open']]: menuOpen,
            })}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

Stepper.displayName = 'Stepper';

export default Stepper;
