import { FC, ReactElement } from 'react';

import cx from 'classnames';
import { DisplayTheme } from '../../../../../lib/constants/components';
import styles from './RetreatSkeleton.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  isEnrolled: boolean;
  theme: DisplayTheme;
}

const RetreatSkeleton: FC<Props> = ({ isEnrolled, theme }) => {
  const { t } = useTranslation();

  const renderSkeleton = (): ReactElement => {
    if (theme === DisplayTheme.BANNER) {
      return (
        <div className={cx(styles['banner-skeleton'])}>
          <div className={cx(styles['banner-skeleton__content-wrapper'])}>
            <div
              className={cx(
                styles['banner-skeleton__top-block'],
                styles['banner-skeleton__top-block--heading']
              )}
            ></div>
            <div
              className={cx(
                styles['banner-skeleton__top-block'],
                styles['banner-skeleton__top-block--subtitle']
              )}
            ></div>
            <hr className={cx(styles['banner-skeleton__divider'])} />
            {isEnrolled ? (
              <>
                <div
                  className={cx(
                    styles['banner-skeleton__bottom-block'],
                    styles['banner-skeleton__bottom-block--single-row']
                  )}
                ></div>
                <div
                  className={cx(
                    styles['banner-skeleton__bottom-block'],
                    styles['banner-skeleton__bottom-block--single-row']
                  )}
                ></div>
                <div
                  className={cx(
                    styles['banner-skeleton__bottom-block'],
                    styles['banner-skeleton__bottom-block--short-row']
                  )}
                ></div>
              </>
            ) : (
              <div
                className={cx(
                  styles['banner-skeleton__bottom-block'],
                  styles['banner-skeleton__bottom-block--block']
                )}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={cx(styles['widget-skeleton'])}>
        <div className={cx(styles['widget-skeleton__inner'])}>
          <div className={cx(styles['widget-skeleton__rewards-heading'])}>
            <p className={cx(styles['widget-skeleton__rewards-title'])}>
              {t('bayer-rewards-widget.retreat-progress')}
            </p>
            <p className={cx(styles['widget-skeleton__rewards-subtitle'])}></p>
          </div>
          <div className={cx(styles['widget-skeleton__progress-bar-wrapper'])}>
            <div className={cx(styles['widget-skeleton__progress-bar'])}></div>
          </div>
        </div>
        <div className={cx(styles['widget-skeleton__link-button'])}></div>
      </div>
    );
  };

  return renderSkeleton();
};

RetreatSkeleton.displayName = 'RetreatSkeleton';

export default RetreatSkeleton;
