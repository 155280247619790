import { FC, ImgHTMLAttributes, ReactNode } from 'react';
import { AccentStrokeColors } from '../../lib/constants/components';
import cx from 'classnames';
import { DiagonalLine } from '../../assets/decorations';
import styles from './ImageWithContent.module.scss';

export interface Props {
  imageSrc: ImgHTMLAttributes<HTMLImageElement>['src'];
  imageAlt: string;
  accentStrokeColor?: string;
  children?: ReactNode;
  className?: string;
}

const ImageWithContent: FC<Props> = ({
  children,
  accentStrokeColor = AccentStrokeColors.ANCILIARY_100,
  imageSrc,
  imageAlt,
  className,
}) => (
  <div className={cx(styles['image-content'], className)}>
    <div className={cx(styles['image-content__children'])}>{children}</div>
    <div className={cx(styles['art-wrapper'])}>
      <div className={cx(styles['art-wrapper__shape-mould'])}>
        <img className={cx(styles['art-wrapper__image'])} alt={imageAlt} src={imageSrc} />
      </div>
      <div className={cx(styles['art-wrapper__deco'])}>
        <DiagonalLine
          className={cx(
            styles['art-wrapper__line'],
            styles[`art-wrapper__line--${accentStrokeColor}`]
          )}
        />
      </div>
      <span
        className={cx(
          styles['art-wrapper__triangle'],
          styles[`art-wrapper__triangle--${accentStrokeColor}`]
        )}
      ></span>
    </div>
  </div>
);
ImageWithContent.displayName = 'ImageWithContent';

export default ImageWithContent;
