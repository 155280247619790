import { FC, ImgHTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import { Position } from '../../lib/constants/components';
import styles from './MediaWithContent.module.scss';
import { useBreakpoint } from '../../lib/hooks';

export interface Props {
  imageDesktop: ImgHTMLAttributes<HTMLImageElement>['src'];
  imageMobile: ImgHTMLAttributes<HTMLImageElement>['src'];
  imageAlt: string;
  children: ReactNode;
  childrenPosition?: Position;
}

const MediaWithContent: FC<Props> = ({
  imageDesktop,
  imageMobile,
  imageAlt,
  children,
  childrenPosition = Position.LEFT,
}) => {
  const { isXs } = useBreakpoint();
  const image = isXs ? imageMobile : imageDesktop;

  return (
    <div className={cx(styles['media-with-content'])}>
      <div className={cx(styles['media-with-content__content-wrapper'])}>
        <img src={image} alt={imageAlt} className={cx(styles['media-with-content__image'])} />
        <div
          className={cx(
            styles['media-with-content__children-wrapper'],
            styles[`media-with-content__children-wrapper--${childrenPosition}`]
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

MediaWithContent.displayName = 'MediaWithContent';

export default MediaWithContent;
