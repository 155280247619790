import { gql } from '../../__generated__';

export const GET_PRODUCTS_QUERY = gql(`
query PreOrderProduct($input: ProductForPreOrderInput!) {
  productForPreOrder(input: $input) {
    details {
      name
      cropType
      preAuthUrl
    }
    products {
      sku
      name
      cropType
      preAuthUrl
      acresPerPackage
      productSize
      seedTreatment {
        id
        name
      }
    }
  }
}`);
