import { ButtonThemes, InitialsTheme } from '../../../../lib/constants/components';
import dayjs, {
  getCurrentRewardEarningPeriod,
  getRewardsPeriodYear,
  isPurchaseOneYearOld,
} from '../../../../lib/utils/dates';
import { FC, useEffect, useMemo, useState } from 'react';
import { getDynamicProgramColorMap, getProgramsWithOther } from '../../../../lib/utils/programs';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';
import Avatar from '../../../_shared/Avatar';
import { Cell } from '../../../_shared/TableAccordion/TableAccordion';
import cx from 'classnames';

import { formatRetailerName } from '../../../../lib/utils/retailers';
import { GET_BAYER_VALUE_PROGRAM_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import InvoiceSection from './InvoiceSection';
import LinkButton from '../../../_shared/LinkButton';
import ProgramBadge from '../../../_shared/ProgramBadge';
import { ProgramBadgeSize } from '../../../../lib/constants/programs';
import { PurchaseProduct } from '../../../../__generated__/graphql';
import RewardsSection from './RewardsSection';
import { Link as RouterLink } from 'react-router-dom';
import { slashSeparatedYearFirst } from '../../../../lib/constants/date-formats';
import styles from './TableRow.module.scss';
import TableAccordion from '../../../_shared/TableAccordion';
import Tooltip from '../../../_shared/Tooltip';
import { TooltipPosition } from '../../../_shared/Tooltip/Tooltip.types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface Props {
  item: PurchaseProduct;
  className?: string;
  showBreakdownCta?: boolean;
  hasPartners: boolean;
  parentFarmName?: string;
}

const PurchaseRow: FC<Props> = ({
  item,
  className,
  showBreakdownCta,
  hasPartners,
  parentFarmName,
}) => {
  const { t } = useTranslation();
  const [cells, setCells] = useState<Cell[]>([]);
  const currentRewardEndYear = getCurrentRewardEarningPeriod().currentPeriodEnd;
  const { data: bayerValueProgramData } = useQuery(GET_BAYER_VALUE_PROGRAM_QUERY, {
    variables: { input: { year: currentRewardEndYear } },
  });

  const purchaserName = useMemo(
    () => item.purchase.purchaser?.split(' ') || item.purchase.purchaser || '',
    [item.purchase.purchaser]
  );
  const programs = useMemo(
    () => getProgramsWithOther(item.rewardPrograms, t),
    [item.rewardPrograms, t]
  );

  useEffect(() => {
    const cellList: Cell[] = [
      {
        content: item.product.name,
        align: 'left',
        key: 'name',
      },
      {
        content: item.quantity,
        align: 'right',
        key: 'quantity',
      },
      {
        content: dayjs(item.purchase.invoiceDate).format(slashSeparatedYearFirst),
        align: 'center',
        key: 'date',
      },
    ];

    cellList.push({
      content: (
        <div className={cx(styles['table-row__cell-wrap'])}>
          {getProgramsWithOther(item.rewardPrograms, t).map((program) => (
            <Tooltip
              text={program.name}
              position={TooltipPosition.TOP}
              innerClassName={cx(styles['purchaser-section__tooltip'])}
              key={program.id}
            >
              <ProgramBadge
                className={cx(styles['table-row__program-icon'])}
                program={getDynamicProgramColorMap(program)}
                size={ProgramBadgeSize.SM}
              />
            </Tooltip>
          ))}
        </div>
      ),
      key: 'programs',
    });

    cellList.push({
      content: item.purchase.retailer.address
        ? t('purchases.details.retailer', {
            name: formatRetailerName(t, item.purchase.retailer),
            city: item.purchase.retailer?.address?.city || '',
            province: item.purchase.retailer?.address?.province || '',
          })
        : formatRetailerName(t, item.purchase.retailer),
      key: 'retailer',
    });

    if (item.purchase.purchaser && purchaserName) {
      cellList.push({
        content: (
          <Tooltip
            text={item.purchase.purchaser}
            position={TooltipPosition.TOP}
            innerClassName={cx(styles['purchaser-section__tooltip'])}
          >
            <Avatar
              firstName={purchaserName[0]}
              lastName={purchaserName.length > 1 ? purchaserName[1] : ''}
              initialsTheme={
                parentFarmName === item.purchase.purchaser
                  ? InitialsTheme.SECONDARY
                  : InitialsTheme.PRIMARY
              }
            />
          </Tooltip>
        ),
        className: cx(styles['purchaser-section__cell']),
        key: 'purchaser',
      });
    }

    setCells([...cellList]);
  }, [parentFarmName, item, t, purchaserName]);

  return (
    <TableAccordion
      key={item.id}
      className={className}
      cells={cells}
      mobileCells={[
        {
          key: 'data',
          content: (
            <div className={cx(styles['mobile-cell'])}>
              <Avatar
                firstName={purchaserName[0]}
                lastName={purchaserName.length > 1 ? purchaserName[1] : ''}
                initialsTheme={
                  parentFarmName === item.purchase.purchaser
                    ? InitialsTheme.SECONDARY
                    : InitialsTheme.PRIMARY
                }
              />
              <div className={cx(styles['mobile-cell__inner'])}>
                <span className={cx(styles['mobile-cell__title'])}>{item.product.name}</span>
                <div className={cx(styles['mobile-cell__date-programs-wrap'])}>
                  <span className={cx(styles['mobile-cell__date'])}>
                    {dayjs(item.purchase.invoiceDate).format(slashSeparatedYearFirst)}
                  </span>
                  {!!programs.length && (
                    <span className={cx(styles['mobile-cell__middle-dot'])}>&#8226;</span>
                  )}
                  <div className={cx(styles['table-row__cell-wrap'])}>
                    {programs.map((program) => (
                      <ProgramBadge
                        className={cx(styles['table-row__program-icon'])}
                        key={program.id}
                        program={getDynamicProgramColorMap(program)}
                        size={ProgramBadgeSize.SM}
                      />
                    ))}
                  </div>
                </div>
                <span className={cx(styles['mobile-cell__retailer'])}>
                  {t('purchases.details.retailer', {
                    name: formatRetailerName(t, item.purchase.retailer),
                    city: item.purchase?.retailer?.address?.city || '',
                    province: item.purchase?.retailer?.address?.province || '',
                  })}
                </span>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className={cx(styles['table-row-content'])}>
        <InvoiceSection
          date={item.purchase.invoiceDate}
          itemId={item.purchase.invoiceId || ''}
          itemType="purchase"
          products={item.purchase.purchaseProducts}
          purchaser={item.purchase.purchaser || ''}
          hasPartners={hasPartners}
        />
        <div className={cx(styles['rewards-earned'])}>
          <RewardsSection item={item.purchase} bayerValueProgramData={bayerValueProgramData} />
          <div className={cx(styles['rewards-program__button-wrapper'])}>
            <LinkButton
              href={LinkUrls.CATALOG_URL}
              className={cx(styles['rewards-program__button'])}
              theme={ButtonThemes.PRIMARY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('purchases.details.view-catalog')}
            </LinkButton>
            {isPurchaseOneYearOld(item.purchase.invoiceDate) && showBreakdownCta && (
              <LinkButton
                as={RouterLink}
                to={`/${PageRoutes.REWARDS}?year=${getRewardsPeriodYear(
                  item.purchase.invoiceDate
                )}`}
                className={cx(styles['rewards-program__button'])}
                theme={ButtonThemes.SECONDARY}
              >
                {t('purchases.details.view-breakdown')}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </TableAccordion>
  );
};

export default PurchaseRow;
