import { FC, ReactNode } from 'react';
import { ButtonThemes } from '../../lib/constants/components';
import cx from 'classnames';
import image from '../../assets/images/login-landscape.jpg';
import ImageWithContent from '../ImageWithContent';
import LinkButton from '../_shared/LinkButton';
import { Link as RouterLink } from 'react-router-dom';
import styles from './SuccessCTA.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  buttonTheme: ButtonThemes;
  heading: string;
  subHeading: string | ReactNode;
  buttonLinkText: string;
  buttonRedirectLink: string;
  children?: ReactNode;
}

const SuccessCTA: FC<Props> = ({
  buttonTheme,
  heading,
  subHeading,
  buttonLinkText,
  buttonRedirectLink,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['success-cta'])}>
      <ImageWithContent imageSrc={image} imageAlt={t('images.farmers')}>
        <div className={cx(styles['success-cta__wrapper'])}>
          <div className={cx(styles['success-cta__inner'])}>
            <h1 className={cx(styles['success-cta__header'])}>{heading}</h1>
            <p className={cx(styles['success-cta__details'])}>{subHeading}</p>
            <LinkButton
              className={cx(styles['button'])}
              theme={buttonTheme}
              as={RouterLink}
              to={buttonRedirectLink}
            >
              {buttonLinkText}
            </LinkButton>
            {children}
          </div>
        </div>
      </ImageWithContent>
    </div>
  );
};

SuccessCTA.displayName = 'SuccessCTA';

export default SuccessCTA;
