import cx from 'classnames';
import { FC } from 'react';
import styles from './CardsSkeleton.module.scss';

export interface Props {
  className?: string;
}

const SKELETON_ROWS = 3;

const CardsSkeleton: FC<Props> = ({ className }) => (
  <>
    {Array.from(Array(SKELETON_ROWS).keys()).map((el) => (
      <div key={el} className={cx(styles['cards-skeleton'], className)}>
        <div className={cx(styles['cards-skeleton__text-wrapper'])}>
          <div
            className={cx(styles['cards-skeleton__text'], styles['cards-skeleton__text--small'])}
          ></div>
          <div
            className={cx(styles['cards-skeleton__text'], styles['cards-skeleton__text--medium'])}
          ></div>
        </div>
        <div className={cx(styles['cards-skeleton__button'])}>
          <div className={cx(styles['cards-skeleton__inner-button'])}></div>
        </div>
      </div>
    ))}
  </>
);

CardsSkeleton.displayName = 'CardsSkeleton';

export default CardsSkeleton;
