import { gql } from "@apollo/client";

export const GET_EVENT_PASS_INFORMATION = gql(`
query EventPassInformation {
    farm {
        farmInfo {
            id
            name
            address {
                province
            }
        }
    }
    user {
        accountInfo {
            id
            firstName
            lastName
        }
    }
}`);
