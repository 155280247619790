import { ProgramBadgeSize, ProgramColorIcon } from '../../../lib/constants/programs';
import cx from 'classnames';
import { FC } from 'react';
import styles from './ProgramBadge.module.scss';

export interface Props {
  program: ProgramColorIcon;
  size: ProgramBadgeSize;
  className?: string;
}

const ProgramBadge: FC<Props> = ({ program, size = ProgramBadgeSize.SM, className }) => (
  <div
    className={cx(className, styles['program-badge'], styles[`program-badge--${size}`])}
    style={{ backgroundColor: program.color }}
  >
    <program.Icon
      className={cx(
        styles[`program-badge__icon`],
        styles[`program-badge__icon--${program.theme}`],
        styles[`program-badge__icon--${size}`]
      )}
      aria-hidden="true"
    />
  </div>
);

ProgramBadge.displayName = 'ProgramBadge';

export default ProgramBadge;
