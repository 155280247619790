import { ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons';
import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Pagination.module.scss';
import usePaginationContext from '../../../lib/contexts/pagination/usePaginationContext';
import { useTranslation } from 'react-i18next';

const INDEX_OFFSET = 1;
const NUM_PAGE_LABELS = 5;

export interface Props {
  itemsPerPage: number;
  totalNumberItems: number;
  disablePages?: number[];
  onPageChange: (pageNum: number) => void;
}

const Pagination: FC<Props> = ({ itemsPerPage, totalNumberItems, onPageChange, disablePages }) => {
  const { t } = useTranslation();
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(true);
  const [currentPageNumber, setCurrentPageNumber] = usePaginationContext();
  const [numPages, setNumPages] = useState<number>(Math.ceil(totalNumberItems / itemsPerPage));
  const [pageLabels, setPageLabels] = useState<number[]>([]);

  useEffect(() => {
    const totalPages = Math.ceil(totalNumberItems / itemsPerPage);
    setNumPages(totalPages);

    if (totalPages === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
  }, [totalNumberItems, itemsPerPage]);

  useEffect(() => {
    setDisableNext(currentPageNumber >= numPages - INDEX_OFFSET);
    setDisableBack(currentPageNumber <= 0);
    onPageChange(currentPageNumber);

    let pagesRemaining = NUM_PAGE_LABELS;
    const pageNums = [];

    // Always show page 1 if there are any pages
    if (numPages > 0) {
      pageNums.push(0);
      pagesRemaining -= 1;
    }

    // Account for the last page being shown
    if (numPages > 1) {
      pagesRemaining -= 1;
    }

    // Chooses the second page number so that curPage is centered in the remaining pages to show.
    let secondPage = Math.ceil(currentPageNumber - pagesRemaining / 2);
    // Makes sure that there is room for the remaining pages to come after the second page
    secondPage = Math.min(secondPage, numPages - pagesRemaining - INDEX_OFFSET);
    // Make sure the second page comes after the first page
    secondPage = Math.max(secondPage, 1);

    // Add pages until maxLabels have been accounted for or until the last page is reached.
    for (
      let pageNum = secondPage;
      pageNum < numPages - INDEX_OFFSET && pagesRemaining > 0;
      pageNum += 1
    ) {
      pageNums.push(pageNum);
      pagesRemaining -= 1;
    }

    // Add the last page
    if (numPages > 1) {
      pageNums.push(numPages - INDEX_OFFSET);
    }

    setPageLabels(pageNums);
  }, [currentPageNumber, numPages]);

  return (
    <div className={cx(styles['pagination'])}>
      <button
        className={cx(styles['pagination__arrow'], styles['pagination__arrow--left'])}
        type="button"
        aria-label={t('pagination.previous-page')}
        onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
        disabled={disableBack}
        tabIndex={0}
      >
        <ChevronLeftIcon
          className={cx(styles['pagination__icon'], {
            [styles['pagination__icon--disabled']]: disableBack,
          })}
          aria-hidden="true"
        />
      </button>
      <div className={cx(styles['pagination__buttons-wrapper'])}>
        {pageLabels.map((num, index) => (
          <React.Fragment key={num}>
            {index > 0 && num - 1 !== pageLabels[index - 1] && (
              <div className={cx(styles['pagination__dots'])}>...</div>
            )}
            <button
              type="button"
              aria-label={
                currentPageNumber === num
                  ? t('pagination.current-page', { pageNum: num, totalPageNum: numPages })
                  : t('pagination.page', { pageNum: num, totalPageNum: numPages })
              }
              className={cx(styles['pagination__button'], {
                [styles['pagination__button--current']]: currentPageNumber === num,
              })}
              onClick={() => setCurrentPageNumber(num)}
              disabled={disablePages?.some((val) => val === num)}
            >
              {num + 1}
            </button>
          </React.Fragment>
        ))}
      </div>
      <button
        className={cx(styles['pagination__arrow'], styles['pagination__arrow--right'])}
        aria-label={t('pagination.next-page')}
        type="button"
        onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
        disabled={disableNext}
      >
        <ChevronRightIcon
          className={cx(styles['pagination__icon'], {
            [styles['pagination__icon--disabled']]: disableNext,
          })}
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
