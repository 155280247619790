import { gql } from '../../../../../__generated__/gql';

export const GET_CROP_INFORMATION_QUERY = gql(`
  query CropInformation {
    farm {
      farmInfo {
        id
        cropInformation {
          acrage
          crops {
            id
            name
          }
        }
      }
    }
  }
`);

export const UPDATE_CROP_INFORMATION_MUTATION = gql(`
mutation UpdateFarmCrops($input: FarmCropUpdateInput!) {
  farmCropUpdate(input: $input) {
    cropInformation {
      acrage
      crops {
        id
        name
      }
    }
  }
}
`);
