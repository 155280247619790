import {
  OrderHistoryContextAction,
  OrderHistoryContextActionTypes,
  OrderHistoryContextState,
} from './OrderHistoryContext.types';

const OrderHistoryContextReducer = (
  state: OrderHistoryContextState,
  action: OrderHistoryContextAction
): OrderHistoryContextState => {
  switch (action.type) {
    case OrderHistoryContextActionTypes.UpdateFiltersQuery:
      return {
        ...state,
        filters: action.payload.filters,
        query: action.payload.query,
        offset: 0,
      };
    case OrderHistoryContextActionTypes.UpdateOffset:
      return { ...state, offset: action.payload };
    case OrderHistoryContextActionTypes.UpdateSortBy:
      return { ...state, sortBy: action.payload, offset: 0 };
    case OrderHistoryContextActionTypes.UpdateSortOrder:
      return { ...state, sortOrder: action.payload, offset: 0 };
    default:
      return state;
  }
};

export default OrderHistoryContextReducer;
