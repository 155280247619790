import { FC, ReactNode } from 'react';
import { MockedProvider } from '@apollo/client/testing';
import { SnackbarProvider } from './snackbar/SnackbarContext';

export interface Props {
  children: ReactNode;
  mocks?: any;
}

const MockProvider: FC<Props> = ({ children, mocks }) => (
  <MockedProvider
    mocks={mocks}
    addTypename={false}
    defaultOptions={{
      watchQuery: { fetchPolicy: 'no-cache' },
      query: { fetchPolicy: 'no-cache' },
    }}
  >
    <SnackbarProvider>{children}</SnackbarProvider>
  </MockedProvider>
);

export default MockProvider;
