import {
  CalculatorContextProps,
  CalculatorContextState,
  CalculatorContextType,
} from './CalculatorContext.types';
import { createContext, FC, useReducer } from 'react';
import CalculatorContextReducer from './reducer';
import { PreFillOption } from '../../types/calculator';

const initialState: CalculatorContextState = {
  preFill: PreFillOption.NO,
  selectedCrops: [],
  selectedProducts: []
};
const CalculatorContext = createContext<CalculatorContextType>([initialState, () => {}]);

export const CalculatorProvider: FC<CalculatorContextProps> = ({ children }) => {
  const value = useReducer(CalculatorContextReducer, initialState);

  return <CalculatorContext.Provider value={value}>{children}</CalculatorContext.Provider>;
};

export default CalculatorContext;
