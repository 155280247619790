import {
  FC,
  ReactElement,
  ReactNode,
  SVGProps,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useBreakpoint, useEventListener } from '../../../lib/hooks';
import { ArrowDropDownIcon } from '../../../assets/icons';
import cx from 'classnames';
import { IconPosition } from '../../../lib/constants/components';
import Modal from '../Modal';
import { ModalProps } from '../Modal/Modal.types';
import styles from './Filter.module.scss';
import useFilterContext from '../../../lib/contexts/accessibility/useFilterContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const PAGE_PADDING_RIGHT = 56;
export interface Props {
  label: string | ReactElement;
  children?: ReactNode;
  dropdownIsVisible?: boolean;
  className?: string;
  isDisabled?: boolean;
  icon?: FC<SVGProps<SVGSVGElement>>;
  iconPosition?: IconPosition;
  numDropdownOptionsSelected?: number;
  modalProps?: Partial<ModalProps>;
  autoHeight?: boolean;
  onModalOpen?(): void;
  onModalClose?(): void;
}

const Filter: FC<Props> = ({
  label,
  children,
  dropdownIsVisible = false,
  isDisabled = false,
  className,
  numDropdownOptionsSelected = 0,
  icon: Icon = ArrowDropDownIcon,
  iconPosition = IconPosition.RIGHT,
  modalProps,
  autoHeight = false,
  onModalOpen,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint();
  const [currentFilterId, setCurrentFilterId] = useFilterContext();

  const [optionsSelectionExpression, setOptionsSelectionExpression] = useState<string>('');
  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const dropdownWrapperRef = useRef<HTMLDivElement | null>(null);
  const dropdownContentRef = useRef<HTMLDivElement | null>(null);

  const filterId = useRef(uuid());
  const filterButtonId = useRef(uuid());

  const closeModal = () => {
    onModalClose?.();
    setCurrentFilterId(undefined);
  };

  /**
   * Closes the filter if user click outside of it
   */
  useEventListener('mousedown', (e: any) => {
    if (currentFilterId === filterId.current && !isMd) {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setCurrentFilterId(undefined);
      }
    }
    return undefined;
  });

  useEffect(() => {
    if (dropdownIsVisible) {
      setCurrentFilterId(filterId.current);
    }
  }, [dropdownIsVisible, setCurrentFilterId]);

  useEffect(() => {
    if (numDropdownOptionsSelected > 0) {
      setOptionsSelectionExpression(`(${numDropdownOptionsSelected})`);
    } else {
      setOptionsSelectionExpression('');
    }
  }, [numDropdownOptionsSelected]);

  useLayoutEffect(() => {
    const dropdown = dropdownWrapperRef.current;
    const showDropdown = currentFilterId === filterId.current;

    if (dropdown && showDropdown && !isMd) {
      const rect = dropdown.getBoundingClientRect();
      if (rect.right > window.innerWidth - PAGE_PADDING_RIGHT) {
        const offset = rect.right - (window.innerWidth - PAGE_PADDING_RIGHT);
        dropdown.style.left = `-${offset}px`;
      }
    } else if (showDropdown && isMd) {
      onModalOpen?.();
    }
  }, [currentFilterId, isMd]);

  return (
    <div className={cx(styles['filter'], className)} id={filterId.current} ref={filterRef}>
      <button
        type="button"
        id={filterButtonId.current}
        aria-label={`${label} ${t('filter.filter')}`}
        className={cx(styles['filter__button'], {
          [styles[`filter__button--${iconPosition}`]]: iconPosition,
          [styles['filter__button--active']]: currentFilterId === filterId.current,
        })}
        onClick={() => {
          setCurrentFilterId(currentFilterId === filterId.current ? undefined : filterId.current);
        }}
        disabled={isDisabled}
        ref={filterButtonRef}
      >
        <span>
          {label}{` ${optionsSelectionExpression}`}
        </span>
        <Icon
          className={cx(styles['filter__icon'], {
            [styles['filter__icon--active']]:
              currentFilterId === filterId.current && Icon === ArrowDropDownIcon,
            [styles['filter__icon--disabled']]: isDisabled,
          })}
          aria-hidden="true"
        />
      </button>
      {currentFilterId === filterId.current && isMd && (
        <Modal
          classNames={{
            modalWrapper: cx(styles['modal']),
            footer: cx(styles['modal-footer']),
            modalTitle: cx(styles['modal__title']),
            closeButton: cx(styles['modal__close-button']),
            secondaryCta: cx(styles['modal-footer__text-link']),
          }}
          hide={closeModal}
          title={modalProps?.title ?? label}
          isVisible={currentFilterId === filterId.current}
          primaryCta={
            modalProps?.primaryCta
              ? {
                  ...modalProps.primaryCta,
                  action: () => {
                    modalProps.primaryCta?.action?.();
                    closeModal();
                  },
                }
              : undefined
          }
          secondaryCta={modalProps?.secondaryCta}
          headerContent={modalProps?.headerContent}
        >
          {children}
        </Modal>
      )}
      {currentFilterId === filterId.current && !isMd && (
        <div
          className={cx(styles['filter__content-wrapper'], {
            [styles['filter__content-wrapper--auto-height']]: autoHeight,
          })}
          ref={dropdownWrapperRef}
        >
          <div className={cx(styles['filter__content'])} ref={dropdownContentRef}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

Filter.displayName = 'Filter';

export default Filter;
