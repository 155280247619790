import {
  AccessLevel,
  Crop,
  Province,
  Responsibility,
  Role,
  User,
} from '../../__generated__/graphql';
import { AcresEast, AcresToNumMap, AcresWest, BayerValueWestProvinces } from '../constants/forms';
import { CropList, CropType, TypeOfTitle } from '../types/onboarding';
import { DropdownOption } from '../constants/react-select';
import { TFunction } from 'i18next';

export const getUserAccessLevels = (t: TFunction): DropdownOption[] => [
  {
    value: AccessLevel.NoAccess,
    label: t('onboarding.user-no-access'),
    description: t('onboarding.user-no-access-description'),
  },
  {
    value: AccessLevel.FullAccess,
    label: t('onboarding.user-full-access'),
    description: t('onboarding.user-full-access-description'),
  },
];

export const getProvinceDropdownOptions = (t: TFunction): DropdownOption[] =>
  Object.values(Province).map((p) => ({
    value: p,
    label: t(`provinces.${p}`),
  }));

export const titleToOption = (
  t: TFunction,
  title: Role | Responsibility,
  type: TypeOfTitle
): DropdownOption => ({
  value: title.id,
  labelKey: title.key,
  label: t(`${type}.${title.key}`),
});

/**
 * Sorts an array of crops by the translated name
 * @param crops
 * @param t
 * @returns
 */
export const sortCropsByName = (t: TFunction, crops: Partial<Crop>[] = []): Partial<Crop>[] => {
  const newCrops = [...(crops || [])];

  return newCrops.sort((a, b) => {
    const nameA = t(`crops.${a.name}`);
    const nameB = t(`crops.${b.name}`);
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export const sortCropsByType = (cropList: Partial<Crop>[]): CropList => {
  const crops: Partial<Crop>[] = [];
  const fruits: Partial<Crop>[] = [];
  const vegetables: Partial<Crop>[] = [];

  cropList.forEach((crop) => {
    if (crop.isActive) {
      switch (crop.type) {
        case CropType.FRUIT:
          fruits.push(crop);
          break;
        case CropType.VEGETABLE:
          vegetables.push(crop);
          break;
        default:
          crops.push(crop);
          break;
      }
    }
  });

  return { crops, fruits, vegetables };
};

export const getAcreOptions = (t: TFunction, province: Province): DropdownOption[] => {
  const options = BayerValueWestProvinces.includes(province) ? AcresWest : AcresEast;
  const program = BayerValueWestProvinces.includes(province) ? 'west' : 'east';

  return Object.values(options).map((c, index) => ({
    value: c,
    label: t(`onboarding.crop-information.acre-options-${program}.option-${index + 1}`),
  }));
};

/**
 * Gets the acreage range for a farm based on the province
 * @param province
 * @param acreage
 * @returns
 */
export const getAcreageFromProvince = (
  province: Province | undefined,
  acreage: number | undefined
) => {
  if (!province) {
    return acreage;
  }

  const options = BayerValueWestProvinces.includes(province) ? AcresWest : AcresEast;
  const entry = Object.entries(AcresToNumMap).find(
    ([range, value]) => value === acreage && Object.values(options).includes(range)
  );

  return entry?.[0] || acreage;
};

export const getPartialUserOptions = (t: TFunction, childUsers: Partial<User>[]) =>
  childUsers.map((user) => ({
    value: user?.accountInfo?.id,
    label: t('contact-us.full-name', {
      firstName: user?.accountInfo?.firstName,
      lastName: user.accountInfo?.lastName,
    }),
    imgSrc: user.accountInfo?.profilePictureUrl,
    firstName: user.accountInfo?.firstName,
    lastName: user.accountInfo?.lastName,
  }));

export const getFarmAcresOption = (
  acrage: number | undefined,
  province: Province
): string | undefined => {
  const entry = Object.entries(AcresToNumMap).find(([, value]) => value === acrage);
  const options = BayerValueWestProvinces.includes(province) ? AcresWest : AcresEast;

  if (entry && Object.values(options).includes(entry[0])) {
    return entry[0];
  } else if (typeof acrage === 'number') {
    return AcresWest.OPTION_6;
  }

  return undefined;
};

export const getFarmAcresFromOption = (acrageOption: string): number => {
  const entry = Object.entries(AcresToNumMap).find(([k]) => k === acrageOption);

  if (entry) {
    return entry[1];
  }

  return 0;
};
