import { gql } from '../../__generated__';

export const GET_CROPS_QUERY = gql(`
query Crops {
  crops {
    id
    name
    type
    isActive
  }
}
`);
