import { DateRangeOptions, FilterForm, Period } from '../constants/forms';
import { Filter, OrderHistoryFilterOptions } from '../models/Purchase.model';
import {
  OrderDirection,
  PurchasePreOrderFilterOptionsQuery,
  PurchasePreOrderSortField,
} from '../../__generated__/graphql';
import { OrderHistorySearchParams, PURCHASES_PER_PAGE } from '../constants/purchases';

import dayjs from './dates';
import { OrderHistoryContextState } from '../contexts/order-history/OrderHistoryContext.types';
import { TFunction } from 'i18next';

export type FilterDropdownOption = {
  label: string;
  value: string;
};

/**
 * Formats the graph response for filter options into OrderHistoryFilterOptions
 * @param data
 * @returns
 */
export const formatOrderHistoryFilterOptions = (
  data: PurchasePreOrderFilterOptionsQuery
): OrderHistoryFilterOptions => {
  const productFilters = new Map<string, Filter>();
  const programFilters = new Map<string, Filter>();
  const purchaserFilters = new Map<string, Filter>();
  const retailerFilters = new Map<string, Filter>();

  data.purchaseFilterOptions.productFilterFields.forEach((item) => {
    productFilters.set(item.sku, { id: item.sku, label: item.name });
  });
  data.purchaseFilterOptions.retailerFilterFields.forEach((item) => {
    retailerFilters.set(item.id, { id: item.id, label: item.name });
  });
  data.purchaseFilterOptions.programFilterFields.forEach((item) => {
    programFilters.set(item.id, { id: item.id, label: item.name });
  });
  data.purchaseFilterOptions.purchaserFilterFields.forEach((item) => {
    purchaserFilters.set(item.id, { id: item.id, label: item.name });
  });
  data.preOrderFilterOptions.productFilterFields.forEach((item) => {
    productFilters.set(item.sku, { id: item.sku, label: item.name });
  });
  data.preOrderFilterOptions.retailerFilterFields.forEach((item) => {
    retailerFilters.set(item.id, { id: item.id, label: item.name });
  });

  const sortFiltersAlphabetically = (a: Filter, b: Filter): number => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    } else if (labelA > labelB) {
      return 1;
    }

    return 0;
  };

  return {
    productFilters: Array.from(productFilters, ([, value]) => value).sort(
      sortFiltersAlphabetically
    ),
    programFilters: Array.from(programFilters, ([, value]) => value).sort(
      sortFiltersAlphabetically
    ),
    retailerFilters: Array.from(retailerFilters, ([, value]) => value).sort(
      sortFiltersAlphabetically
    ),
    purchaserFilters: Array.from(purchaserFilters, ([, value]) => value).sort(
      sortFiltersAlphabetically
    ),
  };
};

/**
 * Formats an array of Filter into an array of DropdownOptions
 * @param filters
 * @param t
 * @returns
 */
export const getFilterDropdownOptions = (filters: Filter[], t: TFunction): FilterDropdownOption[] =>
  filters.map((p) => ({
    value: p.id,
    label: t(`filter.dropdown-option`, { value: p.label, interpolation: { escapeValue: false } }),
  }));

/**
 * Gets exact dates for filtering based on date range
 * @param dateRange
 * @param customDateRangeStart
 * @param customDateRangeEnd
 * @returns
 */
export const getFilterDateRange = (
  dateRange: DateRangeOptions,
  customDateRangeStart?: string,
  customDateRangeEnd?: string
): Period => {
  switch (dateRange) {
    case DateRangeOptions.CUSTOM:
      return {
        startDate: customDateRangeStart,
        endDate: customDateRangeEnd,
      };
    case DateRangeOptions.LAST_THIRTY_DAYS:
      return {
        startDate: dayjs().subtract(30, 'day').toISOString(),
        endDate: dayjs().toISOString(),
      };
    case DateRangeOptions.LAST_SIX_MONTHS:
      return {
        startDate: dayjs().subtract(6, 'month').toISOString(),
        endDate: dayjs().toISOString(),
      };
    case DateRangeOptions.CURRENT_YEAR:
      return {
        startDate: dayjs().startOf('year').toISOString(),
        endDate: dayjs().endOf('year').toISOString(),
      };
    case DateRangeOptions.PREVIOUS_YEAR:
      return {
        startDate: dayjs().subtract(1, 'year').startOf('year').toISOString(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toISOString(),
      };
    case DateRangeOptions.ALL:
    default:
      return {
        startDate: undefined,
        endDate: undefined,
      };
  }
};

/**
 * Sets or deletes a query parameter in the `searchParams` based on the value
 * @param searchParams
 * @param key
 * @param value
 */
const setOrDeleteStringQueryParam = (
  searchParams: URLSearchParams,
  key: OrderHistorySearchParams,
  value?: string
) => {
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }
};

/**
 * Updates the search params to include the current filter options
 * @param orderHistoryData
 */
export const getUpdatedOrderHistorySearchParams = (
  orderHistoryData: OrderHistoryContextState
): URLSearchParams => {
  const searchParams = new URLSearchParams(window.location.search);

  setOrDeleteStringQueryParam(searchParams, OrderHistorySearchParams.QUERY, orderHistoryData.query);
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.SORT_ORDER,
    orderHistoryData.sortOrder
  );
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.SORT_BY,
    orderHistoryData.sortBy
  );
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.PRODUCT_SKU,
    orderHistoryData.filters.productSkus?.join(',')
  );
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.PURCHASER,
    orderHistoryData.filters.farmIds?.join(',')
  );
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.RETAILER_ID,
    orderHistoryData.filters.retailerIds?.join(',')
  );
  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.PROGRAM_ID,
    orderHistoryData.filters.programIds?.join(',')
  );

  setOrDeleteStringQueryParam(
    searchParams,
    OrderHistorySearchParams.DATE_RANGE,
    orderHistoryData.filters.dateRange?.option
  );

  if (orderHistoryData.filters.dateRange?.option === DateRangeOptions.CUSTOM) {
    setOrDeleteStringQueryParam(
      searchParams,
      OrderHistorySearchParams.START_DATE,
      orderHistoryData.filters.dateRange?.startDate
    );
    setOrDeleteStringQueryParam(
      searchParams,
      OrderHistorySearchParams.END_DATE,
      orderHistoryData.filters.dateRange?.endDate
    );
  } else {
    searchParams.delete(OrderHistorySearchParams.START_DATE);
    searchParams.delete(OrderHistorySearchParams.END_DATE);
  }

  return searchParams;
};

/**
 * Updates the search params to include the current filter options
 * @param orderHistoryData
 */
export const getOrderHistoryFiltersFromSearchParams = (): OrderHistoryContextState => {
  const searchParams = new URLSearchParams(window.location.search);
  let dateRangeOption: DateRangeOptions = DateRangeOptions.ALL;
  const searchParamDateRange = searchParams.get(OrderHistorySearchParams.DATE_RANGE);
  let sortOrderOption: OrderDirection = OrderDirection.Desc;
  const searchParamSortOrder = searchParams.get(OrderHistorySearchParams.SORT_ORDER);
  let sortByOption: PurchasePreOrderSortField = PurchasePreOrderSortField.Date;
  const searchParamSortBy = searchParams.get(OrderHistorySearchParams.SORT_BY);

  if (
    searchParamDateRange &&
    Object.values<string>(DateRangeOptions).includes(searchParamDateRange)
  ) {
    dateRangeOption = searchParamDateRange as DateRangeOptions;
  }

  if (
    searchParamSortOrder &&
    Object.values<string>(OrderDirection).includes(searchParamSortOrder)
  ) {
    sortOrderOption = searchParamSortOrder as OrderDirection;
  }

  if (
    searchParamSortBy &&
    Object.values<string>(PurchasePreOrderSortField).includes(searchParamSortBy)
  ) {
    sortByOption = searchParamSortBy as PurchasePreOrderSortField;
  }

  return {
    query: searchParams.get(OrderHistorySearchParams.QUERY) || '',
    filters: {
      productSkus: searchParams.get(OrderHistorySearchParams.PRODUCT_SKU)?.split(',') || [],
      programIds: searchParams.get(OrderHistorySearchParams.PROGRAM_ID)?.split(',') || [],
      farmIds: searchParams.get(OrderHistorySearchParams.PURCHASER)?.split(',') || [],
      retailerIds: searchParams.get(OrderHistorySearchParams.RETAILER_ID)?.split(',') || [],
      dateRange: {
        startDate: searchParams.get(OrderHistorySearchParams.START_DATE) || undefined,
        endDate: searchParams.get(OrderHistorySearchParams.END_DATE) || undefined,
        option: dateRangeOption,
      },
    },
    limit: PURCHASES_PER_PAGE,
    offset: 0,
    sortBy: sortByOption,
    sortOrder: sortOrderOption,
  };
};

/**
 * Gets the initial data for the FilterForm
 */
export const getFilterFormInitialData = (): FilterForm => {
  const orderHistoryContext: OrderHistoryContextState = getOrderHistoryFiltersFromSearchParams();

  return {
    purchaser: orderHistoryContext.filters.farmIds,
    product: orderHistoryContext.filters.productSkus,
    retailer: orderHistoryContext.filters.retailerIds,
    program: orderHistoryContext.filters.programIds,
    dateRange: orderHistoryContext.filters.dateRange?.option || DateRangeOptions.ALL,
    customDateRangeStart: orderHistoryContext.filters.dateRange?.startDate,
    customDateRangeEnd: orderHistoryContext.filters.dateRange?.endDate,
    query: orderHistoryContext.query || '',
  };
};
