import cx from 'classnames';
import { FC } from 'react';
import { SpinnerIcon } from '../../../assets/icons';
import { SpinnerSizes } from '../../../lib/constants/components';
import styles from './Spinner.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
  showText?: boolean;
  size?: SpinnerSizes;
  text?: string;
}

const Spinner: FC<Props> = ({ className, showText = true, size = SpinnerSizes.MD, text }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['spinner'], className)}>
      <SpinnerIcon
        className={cx(styles['spinner__icon'], styles[`spinner__icon--${size}`])}
        aria-hidden="true"
      />
      {showText && (
        <p className={cx(styles['spinner__text'])}>{text || t('general.loading-data')}</p>
      )}
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
