import { Crop, UserAccountInfo } from '../../__generated__/graphql';
import { PasswordValidationErrorState } from '../constants/forms';

export type JwtPayload = {
  accountCode: string;
  email?: string;
  isAdmin?: boolean;
};

export type CreateAccount = {
  email: string;
  password: string;
  confirmPassword: string;
  bayerValueConsent: string;
  marketingEmailConsent: string;
};

export type CreatePasswordErrorState = {
  [PasswordValidationErrorState.CHAR_SENSITIVE]?: undefined | boolean;
  [PasswordValidationErrorState.CONTAINS_NUMBER]?: undefined | boolean;
  [PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]: undefined | boolean;
  [PasswordValidationErrorState.CHAR_LENGTH]: undefined | boolean;
  [PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]?: undefined | boolean;
  [PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]?: undefined | boolean;
};

export type InviteUserRequest = Pick<
  UserAccountInfo,
  'firstName' | 'lastName' | 'email' | 'accessLevel'
>;

export enum CropType {
  FRUIT = 'FRUIT',
  VEGETABLE = 'VEGETABLE',
}

export type CropList = {
  crops: Partial<Crop>[];
  fruits: Partial<Crop>[];
  vegetables: Partial<Crop>[];
};

export type GetRecommendedUsers = Pick<UserAccountInfo, 'id' | 'firstName' | 'lastName' | 'email'>;

export enum TypeOfTitle {
  RESPONSIBILITIES = 'responsibilities',
  ROLES = 'roles',
}
