import { PreFillOption, ProductBreakdown } from '../../types/calculator';
import { ReactNode } from 'react';

export interface CalculatorContextState {
  preFill: PreFillOption;
  selectedCrops: string[];
  selectedProducts: ProductBreakdown[];
}

export interface CalculatorContextProps {
  children: ReactNode;
}

export enum CalculatorContextActionTypes {
  UpdatePreferences = 'UpdatePreferences',
  UpdateSelectedProducts = 'UpdateSelectedProducts',
}

export type CalculatorContextAction =
  | {
      type: CalculatorContextActionTypes.UpdatePreferences;
      payload: {
        prefill: PreFillOption;
        selectedCrops: string[];
      };
    }
  | {
      type: CalculatorContextActionTypes.UpdateSelectedProducts;
      payload: {
        selectedProducts: ProductBreakdown[];
      };
    };

export type CalculatorContextDispatch = (action: CalculatorContextAction) => void;

export type CalculatorContextType = [CalculatorContextState, CalculatorContextDispatch];
