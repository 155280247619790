import { Address, UpdateUserInput, UserAccountInfo } from '../../../../__generated__/graphql';
import { BellIcon, GarbageCanIcon } from '../../../../assets/icons';
import { Controller, useForm } from 'react-hook-form';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  GET_ROLES_RESPONSIBILITIES,
  GET_USER_ACCOUNT_INFO,
  UPDATE_USER_ACCOUNT_INFO,
} from '../../../../lib/graphql/UserInformation.gql';
import { getProvinceDropdownOptions, titleToOption } from '../../../../lib/utils/onboarding';
import {
  maskPhone,
  maskPostalCode,
  unMaskPhone,
  unmaskPostalCode,
  Validations,
} from '../../../../lib/utils/form-validations';
import { useMutation, useQuery } from '@apollo/client';
import AutocompleteWrapper from '../../../../components/AutocompleteWrapper/AutocompleteWrapper';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import { CharacterLimit } from '../../../../lib/constants/character-limit';
import { checkImageSize } from '../../../../lib/utils/images';
import cx from 'classnames';
import DeactivateAccountModal from './DeactivateAccountModal';
import Dropdown from '../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../lib/constants/react-select';
import { handleEnterKey } from '../../../../lib/constants/google-maps';
import ImageClient from '../../../../lib/clients/image-client';
import InfoBanner from '../../../../components/_shared/InfoBanner';
import { languageOptions } from '../../../../lib/constants/i18n';
import SettingsWrapper from '../../components/SettingsWrapper';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SourceImageType } from '../../../../lib/constants/images';
import styles from './Profile.module.scss';
import TextField from '../../../../components/_shared/TextField';
import { TypeOfTitle } from '../../../../lib/types/onboarding';
import UploadPhoto from '../../../Onboarding/Setup/_shared/UploadPhoto/UploadPhoto';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { usePlacesApi } from '../../../../lib/hooks/usePlacesApi';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const Profile: FC = () => {
  const { t } = useTranslation();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch,
  } = useQuery(GET_USER_ACCOUNT_INFO);
  const {
    data: roleResponsibilitiesData,
    loading: loadingRoleResponsibilities,
    error: roleResponsibilitiesError,
  } = useQuery(GET_ROLES_RESPONSIBILITIES);
  const [updateAccountInfo] = useMutation(UPDATE_USER_ACCOUNT_INFO, {
    refetchQueries: [{ query: GET_USER_ACCOUNT_INFO }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();
  const [photo, setPhoto] = useState<File | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const inputPhoto = useRef<HTMLInputElement | null>(null);
  const [deletePhoto, setDeletePhoto] = useState<string | null>(null);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<DropdownOption[]>(getProvinceDropdownOptions(t));
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const placesApiLoaded = usePlacesApi();
  const formId = useRef<string>(uuid());

  const {
    control,
    formState: { errors, dirtyFields, isDirty },
    handleSubmit,
    register,
    reset,
    trigger,
    setValue,
    watch,
  } = useForm<UserAccountInfo>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  const placeId = watch('address.placeId');
  useFormErrors(trigger, dirtyFields);

  const shouldUpdateAddress = useMemo(
    () =>
      userData?.user.accountInfo.address &&
      !userData?.user.accountInfo.address?.placeId &&
      placesApiLoaded &&
      !placeId,
    [userData?.user.accountInfo.address, placesApiLoaded, placeId]
  );

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  useEffect(() => {
    setProvinces(getProvinceDropdownOptions(t));
  }, [t]);

  useEffect(() => {
    reset({
      firstName: userData?.user?.accountInfo?.firstName,
      lastName: userData?.user?.accountInfo?.lastName,
      language: userData?.user.accountInfo.language,
      businessPhone: maskPhone(userData?.user?.accountInfo?.businessPhone || ''),
      mobilePhone: maskPhone(userData?.user?.accountInfo?.mobilePhone || ''),
      email: userData?.user.accountInfo.email,
      address: {
        address: userData?.user?.accountInfo?.address?.address,
        address2: userData?.user?.accountInfo?.address?.address2,
        postalCode: maskPostalCode(userData?.user?.accountInfo?.address?.postalCode || ''),
        city: userData?.user?.accountInfo?.address?.city,
        province: userData?.user?.accountInfo?.address?.province,
      },
      role: {
        id: userData?.user.accountInfo.role?.id,
      },
      responsibilities: userData?.user.accountInfo.responsibilities,
    });
    setPhotoUrl(userData?.user?.accountInfo.profilePictureUrl || '');
  }, [userData, reset]);

  useEffect(() => {
    if (userError || roleResponsibilitiesError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, userError, roleResponsibilitiesError, t]);

  useEffect(() => {
    if (photo) {
      const imageUrl = URL.createObjectURL(photo);
      setPhotoUrl(imageUrl);
    }
  }, [photo]);

  const onSubmit = (formData: UserAccountInfo) => {
    if (isDirty || photo || deletePhoto) {
      const { businessPhone, mobilePhone, email, role, responsibilities, ...data } = formData;

      const profileDetails: UpdateUserInput = {
        ...data,
        address: {
          ...formData.address,
          postalCode: unmaskPostalCode(formData.address?.postalCode || ''),
        },
        businessPhone: unMaskPhone(businessPhone || ''),
        mobilePhone: unMaskPhone(mobilePhone || ''),
        roleId: role?.id,
        responsibilities: roleResponsibilitiesData?.responsibilities
          .filter((responsibility) =>
            responsibilities
              .map((formResponsibility) => formResponsibility.key)
              .includes(responsibility.key)
          )
          .map((responsibility) => responsibility.id),
      };

      const uploadImage = photo
        ? ImageClient.uploadImage(photo, SourceImageType.USER).then(() => {
            setPhoto(null);
            refetch();
          })
        : null;

      const deleteImage = deletePhoto
        ? ImageClient.deleteImage(deletePhoto).then(() => {
            setDeletePhoto(null);
            refetch();
          })
        : null;

      const updateProfile = isDirty
        ? updateAccountInfo({ variables: { input: profileDetails } })
        : null;

      setIsSaving(true);

      Promise.all([updateProfile, uploadImage, deleteImage])
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('alert.updated-successfully'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const onPhotoChange = (e: any) => {
    setDeletePhoto(null);
    const newPhoto = e.target.files[0];
    if (newPhoto) {
      if (checkImageSize(newPhoto)) {
        setPhoto(newPhoto);
      } else {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.size-limit'),
            state: SnackbarStates.WARNING,
          },
        });
      }
    }
  };

  const handlePhotoUpload = () => {
    if (inputPhoto.current) {
      inputPhoto.current?.click();
    }
  };

  const handlePhotoDelete = () => {
    setPhoto(null);
    setPhotoUrl('');
    if (userData?.user.accountInfo.profilePictureId) {
      setDeletePhoto(userData?.user.accountInfo.profilePictureId);
    }
    if (inputPhoto.current) {
      inputPhoto.current.value = '';
    }
  };

  const handleAutocompleteFields = (address: Address) => {
    setValue('address', address, { shouldDirty: true, shouldValidate: true });
  };

  const renderAddressField = (): ReactNode => (
    <TextField
      className={cx(styles['form-input'], {
        [styles['form-input--error']]: !!errors.address?.address,
      })}
      label={t('form.labels.address')}
      {...register('address.address', {
        required: t('form.errors.required'),
        ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
      })}
      hasError={!!errors.address?.address}
      helperText={errors.address?.address?.message}
      onKeyDown={handleEnterKey}
    />
  );

  const roles = useMemo(
    () =>
      roleResponsibilitiesData?.roles
        ? roleResponsibilitiesData?.roles
            .map((role) => titleToOption(t, role, TypeOfTitle.ROLES))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [roleResponsibilitiesData, t]
  );

  const responsibilities = useMemo(
    () =>
      roleResponsibilitiesData?.responsibilities
        ? roleResponsibilitiesData.responsibilities
            .map((responsibility) => titleToOption(t, responsibility, TypeOfTitle.RESPONSIBILITIES))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [roleResponsibilitiesData, t]
  );

  return (
    <SettingsWrapper
      title={t('account-settings.nav.profile')}
      buttonType="submit"
      buttonForm={formId.current}
      onClick={handleSubmit(onSubmit)}
      disabled={!Object.keys(dirtyFields).length && !photo && !deletePhoto}
      isLoadingData={userLoading || loadingRoleResponsibilities}
      isUpdating={isSaving}
    >
      <div className={cx(styles['profile'])}>
        <form id={formId.current}>
          <div className={cx(styles['profile__form-header'])}>
            <legend className={cx(styles['profile__form-title-wrapper'])}>
              <p className={cx(styles['profile__form-title'])}>
                {t('account-settings.profile.personal-information')}
              </p>
              <p className={cx(styles['profile__form-subtitle'])}>
                {t('account-settings.profile.information-subtitle')}
              </p>
            </legend>
            <UploadPhoto
              photoUrl={photoUrl || ''}
              handlePhotoUpload={handlePhotoUpload}
              handlePhotoDelete={handlePhotoDelete}
              onPhotoChange={onPhotoChange}
              inputPhoto={inputPhoto}
              firstName={userData?.user?.accountInfo?.firstName}
              lastName={userData?.user?.accountInfo?.lastName}
            />
          </div>
          <hr className={cx(styles['form-divider'])} />
          <div className={cx(styles['form-group'])}>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.firstName,
                })}
                label={t('form.labels.first-name')}
                {...register('firstName', {
                  required: t('form.errors.required'),
                  ...Validations.characterLimit(t, CharacterLimit.SHORT_NAME),
                })}
                hasError={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.lastName,
                })}
                label={t('form.labels.last-name')}
                {...register('lastName', {
                  required: t('form.errors.required'),
                  ...Validations.characterLimit(t, CharacterLimit.SHORT_NAME),
                })}
                hasError={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            </div>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.email,
                })}
                label={t('form.labels.email')}
                {...register('email', {
                  required: t('form.errors.required'),
                })}
                hasError={!!errors.email}
                helperText={errors.email?.message}
                disabled
              />
            </div>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.mobilePhone,
                })}
                label={t('form.labels.mobile-number')}
                inputType="tel"
                {...register('mobilePhone', {
                  required: t('form.errors.required'),
                  ...Validations.phone(t('form.labels.phone-number'), t),
                  onChange: ({ currentTarget }) => {
                    const { value } = currentTarget;
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.value = maskPhone(value);
                  },
                })}
                hasError={!!errors.mobilePhone}
                helperText={errors.mobilePhone?.message}
              />
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.businessPhone,
                })}
                label={t('form.labels.business-number')}
                inputType="tel"
                {...register('businessPhone', {
                  ...Validations.phone(t('form.labels.phone-number'), t),
                  onChange: ({ currentTarget }) => {
                    const { value } = currentTarget;
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.value = maskPhone(value);
                  },
                })}
                hasError={!!errors.businessPhone}
                helperText={errors.businessPhone?.message}
              />
            </div>
            <div className={cx(styles['form-row'])}>
              <Controller
                control={control}
                name="language"
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    className={cx(styles['form-input'], {
                      [styles['form-input--error']]: !!errors.language,
                    })}
                    name={name}
                    value={languageOptions(t).find((language) => language.value === value)}
                    label={t('form.labels.language')}
                    options={languageOptions(t)}
                    onChange={onChange}
                    hasError={!!errors.language}
                    helperText={errors.language?.message}
                    searchable={false}
                  />
                )}
              />
            </div>
          </div>
          <hr className={cx(styles['form-divider'])} />
          <fieldset className={cx(styles['form-group'])}>
            <legend className={cx(styles['form-group__title'])}>
              <p className={cx(styles['profile__form-title'])}>{t('form.labels.role')}</p>
              <p className={cx(styles['profile__form-subtitle'])}>
                {t('account-settings.profile.roles-subtitle')}
              </p>
            </legend>
            <div className={cx(styles['form-row'])}>
              <Controller
                control={control}
                name="role.id"
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    className={cx(styles['form-input'], {
                      [styles['form-input--error']]: !!errors.role,
                    })}
                    name={name}
                    value={roles.find((role) => role.value === value)}
                    label={t('form.labels.role')}
                    options={roles}
                    onChange={onChange}
                    hasError={!!errors.role}
                    helperText={errors.role?.message}
                    searchable={false}
                  />
                )}
              />
              <Controller
                control={control}
                name="responsibilities"
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    className={cx(styles['form-input'], {
                      [styles['form-input--error']]: !!errors.responsibilities,
                    })}
                    isMulti
                    name={name}
                    value={value?.map((responsibility) =>
                      titleToOption(t, responsibility, TypeOfTitle.RESPONSIBILITIES)
                    )}
                    label={t('form.labels.responsibilities')}
                    options={responsibilities}
                    onChange={(newValue) =>
                      onChange(
                        newValue.target.value.map((singleValue: DropdownOption) => ({
                          id: singleValue.value,
                          key: singleValue.labelKey,
                        }))
                      )
                    }
                    hasError={!!errors.responsibilities}
                    helperText={errors.responsibilities?.message}
                    searchable={false}
                  />
                )}
              />
            </div>
          </fieldset>
          <hr className={cx(styles['form-divider'])} />
          <fieldset className={cx(styles['form-group'])}>
            <legend className={cx(styles['profile__form-group__title'])}>
              <p className={cx(styles['profile__form-title'])}>
                {t('account-settings.profile.mailing-address')}
              </p>
              {shouldUpdateAddress ? (
                <InfoBanner
                  title={t('onboarding.address-banner')}
                  className={cx(styles['farm-information__address-banner'])}
                  closeButton={false}
                  icon={BellIcon}
                />
              ) : (
                <p className={cx(styles['profile__form-subtitle'])}>
                  {t('account-settings.profile.address-subtitle')}
                </p>
              )}
            </legend>
            <div className={cx(styles['form-row'])}>
              {placesApiLoaded ? (
                <AutocompleteWrapper handleAutocompleteFields={handleAutocompleteFields}>
                  {renderAddressField()}
                </AutocompleteWrapper>
              ) : (
                renderAddressField()
              )}
              <TextField
                label={t('form.labels.address2')}
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.address?.address2,
                })}
                {...register('address.address2', {
                  ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
                })}
                hasError={!!errors.address?.address2}
                helperText={errors.address?.address2?.message}
              />
            </div>
            <div className={cx(styles['form-row'])}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.address?.city,
                })}
                label={t('form.labels.city')}
                {...register('address.city', {
                  required: t('form.errors.required'),
                  ...Validations.characterLimit(t, CharacterLimit.CITY),
                })}
                hasError={!!errors.address?.city}
                helperText={errors.address?.city?.message}
              />
              <div className={cx(styles['form-nested-row'])}>
                <Controller
                  control={control}
                  name="address.province"
                  rules={{ required: t('form.errors.required') }}
                  render={({ field: { onChange, value, name } }) => (
                    <Dropdown
                      name={name}
                      value={provinces.find((p) => p.value === value)}
                      label={t('form.labels.province')}
                      options={provinces}
                      onChange={onChange}
                      hasError={!!errors.address?.province}
                      helperText={errors.address?.province?.message}
                    />
                  )}
                />
                <TextField
                  className={cx(styles['form-input'], {
                    [styles['form-input--error']]: !!errors.address?.postalCode,
                  })}
                  label={t('form.labels.postal-code')}
                  {...register('address.postalCode', {
                    required: t('form.errors.required'),
                    ...Validations.postalCode(t('form.labels.postal-code'), t),
                    onChange: ({ currentTarget }) => {
                      const { value } = currentTarget;
                      // eslint-disable-next-line no-param-reassign
                      currentTarget.value = maskPostalCode(value);
                    },
                    setValueAs: (v) => unmaskPostalCode(maskPostalCode(v)),
                  })}
                  hasError={!!errors.address?.postalCode}
                  helperText={errors.address?.postalCode?.message}
                />
              </div>
            </div>
          </fieldset>
        </form>
        <hr className={cx(styles['form-divider'])} />
        <div className={cx(styles['profile__deactivate-account'])}>
          <div className={cx(styles['profile__row'])}>
            <div className={cx(styles['profile__icon-wrapper'])}>
              <GarbageCanIcon className={cx(styles['profile__icon'])} aria-hidden="true" />
            </div>
            <div className={cx(styles['profile__heading'])}>
              <p className={cx(styles['profile__heading-title'])}>
                {t('account-settings.profile.deactivate-account.heading')}
              </p>
              <p className={cx(styles['profile__heading-subtitle'])}>
                {t('account-settings.profile.deactivate-account.description')}
              </p>

              <Button
                className={cx(styles['profile__button'])}
                onClick={openAddModal}
                type="button"
                theme={ButtonThemes.TEXT_LINK}
              >
                {t('account-settings.profile.deactivate-account.cta')}
              </Button>
            </div>
          </div>
        </div>
        <DeactivateAccountModal
          hide={closeAddModal}
          open={addModalOpen}
          user={userData?.user.accountInfo}
        />
      </div>
    </SettingsWrapper>
  );
};

Profile.displayName = 'Profile';

export default Profile;
