import { FC, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { formatContacts } from '../../../../lib/utils/contacts';
import { GET_SUPPORT_CONTACTS } from '../../../../lib/graphql/SupportContact.gql';
import { LocalTeamMember } from '../../../../lib/models/LocalTeam.model';
import { maskPhone } from '../../../../lib/utils/form-validations';
import Skeleton from './Skeleton/Skeleton';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './LocalTeamWidget.module.scss';
import { useQuery } from '@apollo/client';
import UserCard from '../../../../components/_shared/UserCard';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {}

const LocalTeamWidget: FC<Props> = () => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();

  const {
    loading: loadingSupportContacts,
    data: supportContacts,
    error: supportContactError,
  } = useQuery(GET_SUPPORT_CONTACTS);

  useEffect(() => {
    if (supportContactError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, supportContactError, t]);

  const localTeamMembers: LocalTeamMember[] = useMemo(
    () => formatContacts(supportContacts, t),
    [supportContacts, t]
  );

  return (
    <div className={cx(styles['local-team-widget'])}>
      <div className={cx(styles['local-team-widget__text-wrapper'])}>
        <p className={cx(styles['local-team-widget__title'])}>{t('local-team-widget.title')}</p>
      </div>
      <ul className={cx(styles['local-team-widget__list'])}>
        {!loadingSupportContacts ? (
          localTeamMembers?.map((member) => (
            <li key={member.id} className={cx(styles['local-team-widget__list-item'])}>
              <UserCard
                fullName={member.contactFullName}
                description={member.email}
                phone={maskPhone(member.phone || '')}
                title={member.role}
                retailerGroupName={member.retailerName}
                hidePhoto
              />
            </li>
          ))
        ) : (
          <Skeleton />
        )}
      </ul>
    </div>
  );
};

LocalTeamWidget.displayName = 'LocalTeamWidget';

export default LocalTeamWidget;
