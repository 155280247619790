import Accordion from '../../../components/_shared/Accordion';
import { ChevronDownIcon } from '../../../assets/icons';
import cx from 'classnames';
import { FC } from 'react';
import { Product } from '../../../lib/types/calculator';
import styles from './TotalAmountBreakdown.module.scss';
import { useTranslation } from 'react-i18next';

interface TotalAmountBreakdownProps {
  estimatedRewards: number;
  products: Product[];
}

const TotalAmountBreakdown: FC<TotalAmountBreakdownProps> = ({ estimatedRewards, products }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['total-amount-breakdown']}>
      <div className={styles['total-amount-breakdown__running-total-wrapper']}>
        <span className={styles['total-amount-breakdown__running-total-title']}>
          {t('bayer-rewards-calculator.total-amount-breakdown.estimated-rewards')}
        </span>
        <span className={styles['total-amount-breakdown__running-total-amount']}>
          {t('format.price', { value: estimatedRewards })}
        </span>
      </div>
      <Accordion
        title={t('bayer-rewards-calculator.total-amount-breakdown.title')}
        Icon={ChevronDownIcon}
        classNames={{
          container: styles['total-amount-breakdown__accordion-container'],
          button: styles['total-amount-breakdown__accordion-button'],
        }}
      >
        <dl className={styles['total-amount-breakdown__product-list']}>
          {products.map((product) => (
            <>
              <dt className={styles['total-amount-breakdown__product-title']}>{product.label}</dt>
              <dd className={styles['total-amount-breakdown__product-amount']}>
                {t('format.price', { value: product.amount })}
              </dd>
            </>
          ))}
          <dt
            className={cx(
              styles['total-amount-breakdown__product-title'],
              styles['total-amount-breakdown__product-title--total']
            )}
          >
            {t('bayer-rewards-calculator.total-amount-breakdown.running-total')}
          </dt>
          <dd
            className={cx(
              styles['total-amount-breakdown__product-amount'],
              styles['total-amount-breakdown__product-amount--total']
            )}
          >
            {t('format.price', {
              value: products
                .map((product) => product.amount)
                .reduce((acc, current) => acc + current, 0),
            })}
          </dd>
        </dl>
      </Accordion>
    </div>
  );
};

export default TotalAmountBreakdown;
