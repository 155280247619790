import ActiveOrdersTable from '../ActiveOrdersTable/ActiveOrdersTable';
import cx from 'classnames';
import { FC } from 'react';
import { OrderHistoryContextActionTypes } from '../../../lib/contexts/order-history/OrderHistoryContext.types';
import { OrderHistoryResponse } from '../../../lib/models/Purchase.model';
import PurchaseTable from '../PurchaseTable/PurchaseTable';
import styles from './TabsSection.module.scss';
import Tabs from '../../../components/_shared/Tabs';
import useOrderHistoryContext from '../../../lib/contexts/order-history/useOrderHistoryContext';
import usePaginationContext from '../../../lib/contexts/pagination/usePaginationContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  results: OrderHistoryResponse;
  isLoading: boolean;
}

const TabsSection: FC<Props> = ({ results, isLoading }) => {
  const { t } = useTranslation();
  const [, setCurrentPage] = usePaginationContext();
  const [, dispatchOrderHistory] = useOrderHistoryContext();

  return (
    <section className={cx(styles['tabs-section'])}>
      <Tabs
        tabs={[
          {
            name: t('order-history.purchases', { quantity: results.purchases.count }),
            id: 'purchases',
            component: (
              <PurchaseTable
                purchases={results.purchases.data}
                isLoading={isLoading}
                totalPurchases={results.purchases.count}
              />
            ),
          },
          {
            name: t('order-history.active-orders', { quantity: results.orders.count }),
            id: 'orders',
            component: (
              <ActiveOrdersTable
                orders={results.orders.data}
                isLoading={isLoading}
                totalPurchases={results.orders.count}
              />
            ),
          },
        ]}
        onTabChange={() => {
          setCurrentPage(0);
          dispatchOrderHistory({ type: OrderHistoryContextActionTypes.UpdateOffset, payload: 0 });
        }}
      />
    </section>
  );
};

export default TabsSection;
