import {
  ProgramCardBadgeAlignment,
  ProgramCardLayout,
  ProgramCardSize,
  Props,
} from './ProgramCard.types';

import cx from 'classnames';
import { FC } from 'react';
import ProgramBadge from '../ProgramBadge';
import { ProgramBadgeSize } from '../../../lib/constants/programs';
import styles from './ProgramCard.module.scss';
import { useBreakpoint } from '../../../lib/hooks';

const ProgramCard: FC<Props> = ({
  badgeAlignment = ProgramCardBadgeAlignment.TOP,
  description,
  layout = ProgramCardLayout.HORIZONTAL,
  program,
  size = ProgramCardSize.MD,
}) => {
  const { isXs } = useBreakpoint();

  return (
    <div
      className={cx(styles['program-card'], {
        [styles[`program-card--${badgeAlignment}`]]: badgeAlignment,
        [styles[`program-card--${layout}`]]: layout,
      })}
    >
      <div className={cx(styles['program-card__badge'])}>
        <ProgramBadge
          size={isXs ? ProgramBadgeSize.MD : ProgramBadgeSize.LG}
          program={program.colorIconData}
        />
      </div>
      <div className={cx(styles['program-card__text-wrapper'])}>
        <p
          className={cx(styles['program-card__heading'], {
            [styles[`program-card__heading--${size}`]]: size,
          })}
        >
          {program.program.name}
        </p>
        {description && (
          <p
            className={cx(styles['program-card__description'], {
              [styles[`program-card__description--${size}`]]: size,
            })}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

ProgramCard.displayName = 'ProgramCard';

export default ProgramCard;
