import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { Controller, useForm } from 'react-hook-form';
import { FC, ReactNode, useRef } from 'react';
import { GetRetailersInput, Retailer } from '../../../__generated__/graphql';
import { HidePasswordIcon, ShowPasswordIcon } from '../../../assets/icons';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../_shared/Button';
import cx from 'classnames';
import Dropdown from '../../_shared/Dropdown';
import { formatRetailerName } from '../../../lib/utils/retailers';
import { getProvinceDropdownOptions } from '../../../lib/utils/onboarding';
import LinkButton from '../../_shared/LinkButton/LinkButton';
import styles from './AddPreferredRetailerModal.module.scss';
import TextField from '../../_shared/TextField';

interface Props {
  fetching: boolean;
  legendId: string;
  onSubmit(data: GetRetailersInput): void;
  resultsNumber: number;
  selectedRetailers: Retailer[];
  showSelected: Boolean;
  setShowSelected(): void;
}

const ModalHeader: FC<Props> = ({
  fetching,
  legendId,
  onSubmit,
  resultsNumber,
  selectedRetailers,
  setShowSelected,
  showSelected,
}) => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, reset } = useForm<GetRetailersInput>({
    defaultValues: {
      name: '',
      city: '',
      province: undefined,
    },
  });
  const provinces = useRef(getProvinceDropdownOptions(t));

  const handlShowSelected = () => {
    reset();
    setShowSelected();
  };

  const renderResultsLegend = (): string => {
    if (fetching && resultsNumber === 0) {
      return t('form.results.showing-results-loading');
    } else if (resultsNumber === 0) {
      return t('form.results.no-results');
    }

    return t('form.results.showing-results', { number: resultsNumber });
  };

  const renderSelectedLegend = (): ReactNode => {
    if (selectedRetailers.length) {
      const retailerList = selectedRetailers
        .map((retailer) => formatRetailerName(t, retailer))
        .join(', ');
      return (
        <span className={cx(styles['modal-header__selected'])}>
          <Trans
            i18nKey="account-settings.my-preferred-retailer.selected"
            values={{
              quantity: selectedRetailers.length,
              count: selectedRetailers.length,
              retailerList,
            }}
            components={[
              <LinkButton
                onClick={handlShowSelected}
                icon={showSelected ? ShowPasswordIcon : HidePasswordIcon}
                className={cx(styles['modal-header__show-selected'])}
                iconPosition={IconPosition.LEFT}
                iconClassName={cx(styles['modal-header__show-selected-icon'])}
              />,
            ]}
          />
        </span>
      );
    }
    return null;
  };

  return (
    <form className={cx(styles['modal-header'])} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register('name')}
        className={cx(styles['modal-header__field'])}
        label={t('form.labels.retailer-name')}
      />
      <div className={cx(styles['modal-header__grid-2'])}>
        <TextField
          {...register('city')}
          className={cx(styles['modal-header__field'])}
          label={t('form.labels.city')}
          name="city"
        />
        <Controller
          control={control}
          name="province"
          render={({ field: { onChange, value, name } }) => (
            <Dropdown
              label={t('form.labels.province')}
              className={cx(styles['modal-header__field'])}
              onChange={onChange}
              value={provinces.current?.find((option) => option.value === value)}
              name={name}
              options={provinces.current}
              clearable
            />
          )}
        />
      </div>
      <Button
        type="submit"
        theme={ButtonThemes.SECONDARY}
        className={cx(styles['modal-header__button'])}
      >
        {t('general.search')}
      </Button>
      <legend id={legendId} className={cx(styles['modal-header__legend'])}>
        {!showSelected && renderResultsLegend()}
        {renderSelectedLegend()}
      </legend>
    </form>
  );
};

export default ModalHeader;
