import { FC, ReactNode, SVGProps } from 'react';

import cx from 'classnames';
import { EmptyStateTheme } from '../../lib/constants/components';
import styles from './TableEmptyState.module.scss';

export interface Props {
  className?: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string | ReactNode;
  theme?: EmptyStateTheme;
  id?: string;
}

const TableEmptyState: FC<Props> = ({
  Icon,
  description,
  title,
  className,
  theme = EmptyStateTheme.PRIMARY,
  id,
}) => (
  <div className={cx(styles['empty-state-message'], className)} id={id}>
    <div
      className={cx(
        styles['empty-state-message__icon-wrapper'],
        styles[`empty-state-message__icon-wrapper--${theme}`]
      )}
    >
      <Icon className={cx(styles['empty-state-message__icon'])} />
    </div>
    <h2 className={cx(styles['empty-state-message__title'])}>{title}</h2>
    <p className={cx(styles['empty-state-message__subtitle'])}>{description}</p>
  </div>
);

TableEmptyState.displayName = 'TableEmptyState';

export default TableEmptyState;
