import { addressFields, addressOptions } from '../../lib/constants/google-maps';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Address } from '../../__generated__/graphql';
import { Autocomplete } from '@react-google-maps/api';
import { buildAddress } from '../../lib/utils/google-maps';

export interface Props {
  children: ReactNode;
  handleAutocompleteFields: (address: Address) => void;
}

const AutocompleteWrapper: FC<Props> = ({ children, handleAutocompleteFields }) => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.Autocomplete>();
  const [placeResult, setPlaceResult] = useState<google.maps.places.PlaceResult>();

  useEffect(() => {
    if (placeResult) {
      handleAutocompleteFields(buildAddress(placeResult));
    }
  }, [placeResult]);

  const onPlaceChanged = () => {
    setPlaceResult(selectedPlace?.getPlace());
  };

  return (
    <Autocomplete
      options={addressOptions}
      fields={addressFields}
      onPlaceChanged={onPlaceChanged}
      onLoad={(autocompleteInstance) => {
        setSelectedPlace(autocompleteInstance);
      }}
    >
      <div>{children}</div>
    </Autocomplete>
  );
};

AutocompleteWrapper.displayName = 'AutocompleteWrapper';

export default AutocompleteWrapper;
