export enum ArcWidth {
  MD = '83%',
  LG = '80%',
  XL = '70%',
}

export interface ChartEntry {
  color: string;
  amount: number;
  programName: string;
}
