import {
  BAYER_VALUE_PROGRAM_ID,
  HOT_POTATOES,
  MAIN_PROGRAMS,
  ProgramColorIcon,
  ProgramColorMap,
  ProgramColorMapFallback,
  ProgramType,
} from '../constants/programs';
import dayjs, { getLastThreeRewardYears } from './dates';
import { FarmBayerValueProgramQuery, ProgramRewardsQuery } from '../../__generated__/graphql';
import {
  PartialProgram,
  PartialProgramWithColorIcon,
  ProgramBreakdown,
  ProgramBreakdownWithColorIcon,
} from '../models/Program.model';
import { ChartEntry } from '../../components/_shared/ProgramDoughnutChart/ProgramDoughnutChart.types';
import { LinkUrls } from '../constants/react-router';
import { TFunction } from 'react-i18next';

const dynamicProgramColorMap: { [key: string]: ProgramColorIcon } = { ...ProgramColorMap };
let dynamicNextFallbackIndex: number = 0;

/**
 * Gets the program color icon entity for a program ID.
 * @param programId
 * @returns
 */
export const getDynamicProgramColorMap = (
  program: Pick<PartialProgram, 'id' | 'type'>
): ProgramColorIcon => {
  if (program.type === ProgramType.BAYER_VALUE) {
    dynamicProgramColorMap[program.id] = ProgramColorMap[BAYER_VALUE_PROGRAM_ID];
  } else if (!dynamicProgramColorMap[program.id]) {
    dynamicProgramColorMap[program.id] = ProgramColorMapFallback[dynamicNextFallbackIndex];
    dynamicNextFallbackIndex = (dynamicNextFallbackIndex + 1) % ProgramColorMapFallback.length;
  }

  return dynamicProgramColorMap[program.id];
};

/**
 * Groups the program breakdown into the "Other" category
 * @param breakdown Breakdown of the programs
 * @param t Function to translate strings
 * @param excludeIds Ids of the programs to exclude from the "Other" category
 */
export const groupProgramBreakdownAsOther = (
  breakdown: ProgramBreakdown[],
  t: TFunction
): ProgramBreakdownWithColorIcon[] => {
  let otherRewardsSum: number = 0;
  let earliestOtherProgramDate: string | undefined;
  const result: ProgramBreakdownWithColorIcon[] = [];

  breakdown.forEach((programBreakdown) => {
    if (programBreakdown.program.type === ProgramType.BAYER_VALUE) {
      result.push({
        ...programBreakdown,
        signUpDate: (programBreakdown.signUpDate || '').replace(/-/g, '/').replace(/T.+/, ''),
        colorIconData: getDynamicProgramColorMap(programBreakdown.program),
      });
    } else {
      otherRewardsSum += programBreakdown.rewardAmountInDollars;

      if (
        !earliestOtherProgramDate ||
        dayjs(earliestOtherProgramDate).isAfter(programBreakdown.signUpDate)
      ) {
        earliestOtherProgramDate = programBreakdown.signUpDate;
      }
    }
  });

  if (result.length < breakdown.length) {
    result.push({
      program: {
        id: 'other',
        name: t('program.name.other'),
      },
      signUpDate: (earliestOtherProgramDate || '').replace(/-/g, '/').replace(/T.+/, ''),
      rewardAmountInDollars: otherRewardsSum,
      colorIconData: ProgramColorMap['other'],
    });
  }

  return result;
};

/**
 * Adds color icon to an array of product breakdown
 * @param breakdown
 * @param bayerValueProgramId
 * @returns
 */
export const mapBreakdownToColorIcon = (
  breakdown: ProgramBreakdown[]
): ProgramBreakdownWithColorIcon[] =>
  breakdown.map((programBreakdown) => ({
    ...programBreakdown,
    colorIconData: getDynamicProgramColorMap(programBreakdown.program),
  }));

/**
 * Maps program breakdowns with color data into a Chart Entry item
 * @param breakdown
 */
export const mapProgramBreakdownToChartEntries = (
  breakdown: ProgramBreakdownWithColorIcon[]
): ChartEntry[] =>
  breakdown.map((programBreakdown) => ({
    programName: programBreakdown.program.name,
    color: programBreakdown.colorIconData.color,
    amount: programBreakdown.rewardAmountInDollars,
  }));

/**
 * Retrieves the Bayer Value program from GraphQL rewards item
 * @param rewardsData
 * @returns
 */
export const getBayerValueProgram = (
  rewardsData: ProgramRewardsQuery | FarmBayerValueProgramQuery | undefined
): PartialProgram | undefined => rewardsData?.enrolledProgramsInfo?.bayerValueProgramInfo?.program;

/**
 * Returns an array with main bayer value program alongside with "other" program
 * @param programs Programs to group
 * @param t Translate function
 * @param bayerValueProgramId Id of the user's main bayer value program
 * @returns
 */
export const getProgramsWithOther = (
  programs: PartialProgram[],
  t: TFunction
): PartialProgram[] => {
  const otherProgram: PartialProgram = {
    id: 'other',
    name: t('program.name.other'),
  };

  const programsLength = programs.length;
  const filteredPrograms = programs.filter((program) => program.type === ProgramType.BAYER_VALUE);

  if (programsLength > filteredPrograms.length) {
    filteredPrograms.push(otherProgram);
  }

  return filteredPrograms;
};

/**
 * Gets the reward program year from the URL
 * @returns {number} Reward year of the page
 */
export const getProgramYearFromUrl = () => {
  const yearOptions = getLastThreeRewardYears();
  const params = new URLSearchParams(window.location.search);
  const urlYear = parseInt(params.get('year') || '', 10);

  if (yearOptions.includes(urlYear)) {
    return urlYear;
  }

  return yearOptions[0];
};

/**
 * Returns an array with only Bayer Value programs
 * @param {PartialProgram[]} programs
 * @returns {PartialProgram[]}
 */
export const filterBayerValuePrograms = (programs: PartialProgram[]): PartialProgram[] =>
  programs.filter((program) => program.type === ProgramType.BAYER_VALUE);

/**
 * Returns an array with non Bayer Value programs
 * @param {PartialProgram[]} programs
 * @returns {PartialProgram[]}
 */
export const filterOtherPrograms = (programs: PartialProgram[]): PartialProgram[] =>
  programs.filter((program) => program.type !== ProgramType.BAYER_VALUE);

/**
 * Returns an array of ProgramBreakdown with only non Bayer Value programs
 * @param {ProgramBreakdown[]} breakdown
 * @returns {ProgramBreakdown[]}
 */
export const filterOtherProgramsBreakdown = (breakdown: ProgramBreakdown[]): ProgramBreakdown[] =>
  breakdown.filter((bd) => bd.program.type !== ProgramType.BAYER_VALUE);

/**
 * Maps an array of Program into an array of PartialProgramWithColorIcon
 * @param {PartialProgram[]} programs
 * @returns {PartialProgramWithColorIcon[]}
 */
export const mapProgramsToPartialWithColorIcon = (
  programs: PartialProgram[]
): PartialProgramWithColorIcon[] =>
  programs.map((program) => ({ program, colorIconData: getDynamicProgramColorMap(program) }));

/** Maps a program with it's corresponding marketing url (This is an static mapping,
 * program's name may change year by year), if there is no match with any program it will redirect to
 * the program catalog page.
 * @param programName Program name
 * @returns Marketing url
 */
export const getMarketingUrl = (programName: string | undefined) => {
  let marketingUrl = LinkUrls.BAYER_VALUE_PROGRAMS;
  MAIN_PROGRAMS.forEach((program) => {
    if (programName?.includes(program.name)) {
      marketingUrl = program.marketingUrl;
    }
  });
  return marketingUrl;
};

/** Maps a program name and year with it's corresponding calculator url (This is an static mapping,
 * program's name may change year by year)
 * @param programName Program name
 * @param year        Rewards year
 * @returns Marketing url
 */
export const getCalculatorUrl = (programName: string | undefined, year: number) => {
  let calculatorUrl;
  MAIN_PROGRAMS.forEach((program) => {
    if (programName?.includes(program.name) && year in program.calculatorUrl) {
      calculatorUrl = program.calculatorUrl[year];
    }
  });
  return calculatorUrl;
};

/**
 * Wheter the program name contains Hot Potatoes or not
 * @param programName
 * @returns
 */
export const isHotPotatoes = (programName: string) =>
  programName.toLowerCase().includes(HOT_POTATOES.name.toLowerCase());
