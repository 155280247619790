import { Address, Province } from '../../__generated__/graphql';
import { Libraries } from '@react-google-maps/api';

// Google's Places Library
export const libraries: Libraries = ['places'];

// Fields to be included for the Place in the details response
export const addressFields = [
  'address_components',
  'name',
  'types',
  'formatted_address',
  'place_id',
];

// Restrict predictions to only Canada
export const addressOptions = { componentRestrictions: { country: 'ca' } };
export const country = 'Canada';

export const defaultAddress: Address = {
  address: '',
  address2: '',
  city: '',
  postalCode: '',
  province: '' as Province,
};

// Represents a component of an address
export enum AddressComponent {
  PROVINCE = 'administrative_area_level_1',
  CITY = 'locality',
  POSTAL_CODE = 'postal_code',
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  NEIGHBORHOOD = 'neighborhood',
  FLOOR = 'floor',
  POST_BOX = 'post_box',
}

// Place types
export enum AddressType {
  INTEREST = 'point_of_interest',
  ESTABLISHMENT = 'establishment',
  LANDMARK = 'landmark',
}

export const AddressTypeList = [
  AddressType.ESTABLISHMENT,
  AddressType.INTEREST,
  AddressType.LANDMARK,
];

// Address components used in Address line 1
export const AddressLineList = [
  AddressComponent.STREET_NUMBER,
  AddressComponent.ROUTE,
  AddressComponent.NEIGHBORHOOD,
];

// Address components used in Address line 2
export const AddressLine2List = [AddressComponent.FLOOR, AddressComponent.POST_BOX];

// Prevents the form from being submitted when selecting an autocomplete option
export const handleEnterKey = (event: any) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

// Type used to detect the Province a user is in using Geolocation API
export const administrativeAreaLevel = 'administrative_area_level_1';

// Quebec abbreviation
export const qcShortName = 'QC';
