import Button from '../Button';
import cx from 'classnames';
import { FC } from 'react';
import styles from './SettingsSkeleton.module.scss';
import { useBreakpoint } from '../../../lib/hooks';
import { useTranslation } from 'react-i18next';

const SettingsSkeleton: FC = () => {
  const { isMd } = useBreakpoint();
  const { t } = useTranslation();

  return (
    <div className={cx(styles['skeleton'])}>
      <div className={cx(styles['skeleton__section'])}></div>
      <hr className={cx(styles['skeleton__hr'])} />
      <div className={cx(styles['skeleton__section'])}></div>
      <div className={cx(styles['skeleton__text'])}></div>
      <div className={cx(styles['skeleton__section'])}></div>
      <div className={cx(styles['skeleton__text'])}></div>
      <div className={cx(styles['skeleton__section'])}></div>
      <div className={cx(styles['skeleton__text'])}></div>
      <div className={cx(styles['skeleton__section'])}></div>
      <div className={cx(styles['skeleton__text'])}></div>
      {isMd && (
        <div className={cx(styles['skeleton__footer'])}>
          <Button
            className={cx(styles['skeleton__button'])}
            disabled
            onClick={() => {}}
            type="button"
          >
            {t('general.save')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SettingsSkeleton;
