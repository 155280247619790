import {
  SnackbarContextAction,
  SnackbarContextActionTypes,
  SnackbarContextState,
} from './SnackbarContext.types';
import { v4 as uuid } from 'uuid';

const snackbarContextReducer = (
  state: SnackbarContextState,
  action: SnackbarContextAction
): SnackbarContextState => {
  switch (action.type) {
    case SnackbarContextActionTypes.AddToQueue:
      const newSnackbar = {
        ...action.payload,
        id: uuid(),
      };

      return [...state, newSnackbar];
    case SnackbarContextActionTypes.RemoveFromQueue:
      return state.filter((snackbar) => snackbar.id !== action.id);
    default:
      return state;
  }
};

export default snackbarContextReducer;
