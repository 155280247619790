import {
  CalculatorContextAction,
  CalculatorContextActionTypes,
  CalculatorContextState,
} from './CalculatorContext.types';

const CalculatorContextReducer = (
  state: CalculatorContextState,
  action: CalculatorContextAction
): CalculatorContextState => {
  switch (action.type) {
    case CalculatorContextActionTypes.UpdatePreferences:
      return {
        ...state,
        preFill: action.payload.prefill,
        selectedCrops: action.payload.selectedCrops,
      };
    case CalculatorContextActionTypes.UpdateSelectedProducts:
      return {
        ...state,
        selectedProducts: action.payload.selectedProducts,
      };
    default:
      return state;
  }
};

export default CalculatorContextReducer;
