import { FC, useState } from 'react';
import { OrderHistoryRowType, PURCHASES_PER_PAGE } from '../../../lib/constants/purchases';

import Button from '../../../components/_shared/Button';
import { ButtonThemes } from '../../../lib/constants/components';
import cx from 'classnames';
import DownloadListModal from '../DownloadListModal';
import { ExportType } from '../../../lib/constants/exports';
import { FileDownloadIcon } from '../../../assets/icons';
import { OrderHistoryContextActionTypes } from '../../../lib/contexts/order-history/OrderHistoryContext.types';
import Pagination from '../../../components/_shared/Pagination';
import { PreOrderProduct } from '../../../__generated__/graphql';
import RecentPurchasesTable from '../../../components/BayerValue/RecentPurchasesTable';
import styles from './ActiveOrdersTable.module.scss';
import useOrderHistoryContext from '../../../lib/contexts/order-history/useOrderHistoryContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  totalPurchases: number;
  orders: PreOrderProduct[];
  isLoading: boolean;
}

const ActiveOrdersTable: FC<Props> = ({ orders, isLoading, totalPurchases }) => {
  const { t } = useTranslation();
  const [{ sortOrder, sortBy }, dispatch] = useOrderHistoryContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <section className={cx(styles['active-orders'])}>
      <DownloadListModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        exportType={ExportType.PreOrder}
      />

      <div className={cx(styles['active-orders__header-wrapper'])}>
        <div className={cx(styles['active-orders__text-wrapper'])}>
          <p className={cx(styles['active-orders__text'])}> {t('order-history.pre-orders-key')}</p>
        </div>
        <div className={cx(styles['active-orders__download-wrapper'])}>
          <p className={cx(styles['active-orders__download-title'])}>
            {t('order-history.download-list-header')}
          </p>
          <Button
            type="button"
            className={cx(styles['active-orders__download-button'])}
            onClick={() => setIsModalOpen(true)}
            theme={ButtonThemes.TEXT_LINK}
            icon={FileDownloadIcon}
            disabled={orders.length === 0}
          >
            {t('order-history.download-list')}
          </Button>
        </div>
      </div>

      <div className={cx(styles['active-orders__table-wrapper'])}>
        <RecentPurchasesTable
          recentPurchases={orders}
          orderField={sortBy}
          setOrderField={(sort) => {
            dispatch({
              type: OrderHistoryContextActionTypes.UpdateSortBy,
              payload: sort,
            });
          }}
          orderDirection={sortOrder}
          setOrderDirection={(order) => {
            dispatch({
              type: OrderHistoryContextActionTypes.UpdateSortOrder,
              payload: order,
            });
          }}
          isLoading={isLoading}
          loadingRowsNumber={PURCHASES_PER_PAGE}
          type={OrderHistoryRowType.PRE_ORDER}
        />
      </div>
      {orders.length > 0 && (
        <div className={cx(styles['active-orders__pagination-wrapper'])}>
          <Pagination
            itemsPerPage={PURCHASES_PER_PAGE}
            onPageChange={(pageNum) => {
              dispatch({
                type: OrderHistoryContextActionTypes.UpdateOffset,
                payload: pageNum * PURCHASES_PER_PAGE,
              });
            }}
            totalNumberItems={totalPurchases}
          />
        </div>
      )}
    </section>
  );
};

export default ActiveOrdersTable;
