import { cacheFirstRoutes, PageRoutes } from '../constants/react-router';
import { Language, LANGUAGE_KEY } from '../constants/i18n';
import { useEffect, useState } from 'react';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { fromGQLLanguage } from '../utils/i18n';
import { GET_USER_LANGUAGE } from '../graphql/UserInformation.gql';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const usePreferredLanguage = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>('cache-only');
  const { data: languageData } = useQuery(GET_USER_LANGUAGE, { fetchPolicy });

  useEffect(() => {
    if (cacheFirstRoutes.some((path) => pathname.includes(path)) || pathname === PageRoutes.HOME) {
      setFetchPolicy('cache-first');
    } else {
      setFetchPolicy('cache-only');
    }
  }, [pathname]);

  useEffect(() => {
    if (languageData && languageData?.user.accountInfo.language) {
      i18n.changeLanguage(fromGQLLanguage(languageData?.user.accountInfo.language));
      localStorage.setItem(LANGUAGE_KEY, fromGQLLanguage(languageData?.user.accountInfo.language));
    } else {
      i18n.changeLanguage(localStorage.getItem(LANGUAGE_KEY) || Language.En);
    }
  }, [i18n, languageData]);

  return languageData;
};
