import { ButtonThemes, SpinnerSizes } from '../../lib/constants/components';
import { FC, useMemo, useState } from 'react';
import { PageRoutes, QueryParams } from '../../lib/constants/react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useAlertLogin, useGigya } from '../../lib/hooks';

import { BellIcon } from '../../assets/icons';
import Button from '../../components/_shared/Button';
import cx from 'classnames';
import image from '../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../components/ImageWithContent';
import InfoBanner from '../../components/_shared/InfoBanner';
import Link from '../../components/_shared/Link';
import LinkButton from '../../components/_shared/LinkButton';
import { loadGigyaScreenSet } from '../../lib/utils/gigya';
import { LoginAlert } from '../../lib/constants/login-alerts';
import { Mode } from '../../lib/constants/gigya';
import PreAuthContainer from '../../components/PreAuthContainer';
import Spinner from '../../components/_shared/Spinner';
import styles from './Login.module.scss';
import { useTranslation } from 'react-i18next';
import { useUrlLanguage } from '../../lib/hooks/useUrlLanguage';

const Login: FC = () => {
  useUrlLanguage();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { alertId, alertDate } = useAlertLogin(language);
  const gigyaLoaded = useGigya();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(QueryParams.GIGYA_MODE);
  const [isLoading, setIsLoading] = useState(false);

  const showSpinner = useMemo<boolean>(
    () => isLoading || mode === Mode.AFTER_LOGIN || mode === Mode.LOGIN,
    [mode, isLoading]
  );

  const renderGigyaLoginRedirect = () => {
    setIsLoading(true);
    loadGigyaScreenSet();
  };

  const displayAlert = useMemo(
    () =>
      (alertId && alertId !== LoginAlert.HOLIDAY) ||
      (alertId && alertId === LoginAlert.HOLIDAY && alertDate),
    [alertId, alertDate]
  );

  return (
    <PreAuthContainer>
      <ImageWithContent
        imageSrc={image}
        imageAlt={t('images.farmers')}
        className={cx(styles['content'])}
      >
        <div
          className={cx(styles['login-container'], {
            [styles['login-container--spinner']]: showSpinner,
          })}
        >
          {showSpinner ? (
            <Spinner text={t('general.loading')} size={SpinnerSizes.LG} />
          ) : (
            (gigyaLoaded || process.env.REACT_APP_USE_MOCKS) && (
              <div className={cx(styles['login-container__inner'])}>
                {displayAlert && (
                  <div className={cx(styles['login-container__alert'])}>
                    <InfoBanner
                      title={t(`login.alert.${alertId}.title`)}
                      content={t(`login.alert.${alertId}.description`, {
                        date: alertDate,
                      })}
                      closeButton={false}
                      icon={BellIcon}
                    />
                  </div>
                )}
                <h1 className={cx(styles['login-container__header'])}>
                  {t('login.account-login')}
                </h1>
                <p className={cx(styles['login-container__subtitle'])}>
                  {t('login.login-subtitle')}
                </p>
                {process.env.REACT_APP_USE_MOCKS ? (
                  <LinkButton
                    as={RouterLink}
                    altText={t('login.login')}
                    to={PageRoutes.HOME}
                    className={cx(styles['login-container__item'])}
                    theme={ButtonThemes.PRIMARY}
                  >
                    {t('login.login')}
                  </LinkButton>
                ) : (
                  <Button
                    altText={t('login.login')}
                    type="button"
                    onClick={renderGigyaLoginRedirect}
                    className={cx(styles['login-container__item'])}
                  >
                    {t('login.login')}
                  </Button>
                )}
                <span className={cx(styles['login-container__sign-up'])}>
                  {`${t('login.no-account')} `}
                  <Link
                    as={RouterLink}
                    altText={t('onboarding.sign-up')}
                    to={`/${PageRoutes.SIGNUP}`}
                  >
                    {t('onboarding.sign-up')}
                  </Link>
                </span>
              </div>
            )
          )}
        </div>
      </ImageWithContent>
    </PreAuthContainer>
  );
};

Login.displayName = 'Login';

export default Login;
