import { ElementType, FC, ReactNode, SVGProps } from 'react';
import { ChevronRightIcon } from '../../../assets/icons';
import cx from 'classnames';
import { PolymorphicComponent } from '../../../lib/models/GenericComponent.model';
import styles from './CardLink.module.scss';

export interface Props {
  altText?: string;
  children?: ReactNode;
  className?: string;
  icon?: FC<SVGProps<SVGSVGElement>> | null;
  isHeaderLink?: boolean;
  linkText?: string;
}

const DEFAULT_ELEMENT = 'a';

const CardLink = <C extends ElementType = typeof DEFAULT_ELEMENT>({
  altText,
  as,
  children,
  className,
  linkText,
  icon: Icon = null,
  isHeaderLink = false,
  ...props
}: PolymorphicComponent<C, Props>) => {
  const TagName = as || DEFAULT_ELEMENT;

  return (
    <TagName
      {...props}
      className={cx(className, styles['card-link'], {
        [styles['card-link--is-header']]: isHeaderLink,
      })}
      aria-label={altText}
    >
      <div className={cx(styles['card-link__inner'])}>
        <div className={cx(styles['card-link__left'])}>
          {Icon && <Icon className={cx(styles['card-link__card-icon'])} aria-hidden="true" />}
          <div className={cx(styles['card-link__text-wrapper'])}>
            <p
              className={cx(styles['card-link__heading'], {
                [styles['card-link__heading--bold']]: isHeaderLink,
              })}
            >
              {children}
            </p>
          </div>
        </div>
        <div
          className={cx(styles['card-link__right'], {
            [styles['card-link__right--with-text']]: linkText,
          })}
        >
          {linkText && <p className={cx(styles['card-link__link-text'])}>{linkText}</p>}
          <div className={cx(styles['card-link__icon-wrapper'])}>
            <ChevronRightIcon className={cx(styles['card-link__icon'])} />
          </div>
        </div>
      </div>
    </TagName>
  );
};

CardLink.displayName = 'CardLink';

export default CardLink;
