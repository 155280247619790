import {
  CheckmarkIcon,
  CrossIcon,
  HidePasswordIcon,
  ShowPasswordIcon,
} from '../../../../assets/icons';
import { confirmPasswordMatch, isValidPassword } from '../../../../lib/utils/form-validations';
import { FC, useEffect, useRef, useState } from 'react';
import {
  PasswordState,
  PasswordValidationErrorState,
  SecurityForm,
} from '../../../../lib/constants/forms';
import { CreatePasswordErrorState } from '../../../../lib/types/onboarding';
import cx from 'classnames';
import { LogoutUser } from '../../../../lib/clients/gigya-client';
import { PageRoutes } from '../../../../lib/constants/react-router';
import { RESET_USER_PASSWORD } from '../../../../lib/graphql/UserInformation.gql';
import SettingsWrapper from '../../components/SettingsWrapper';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './Security.module.scss';
import TextField from '../../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import ValidationCriteria from '../../../../components/_shared/ValidationCriteria';
import { ValidationStateColors } from '../../../../lib/constants/components';

const Security: FC = () => {
  const { t } = useTranslation();
  const [, dispatch] = useSnackbarContext();
  const [showPasswordText, setShowPasswordText] = useState<boolean>(false);
  const [showConfirmPasswordText, setShowConfirmPasswordText] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<CreatePasswordErrorState>({
    [PasswordValidationErrorState.CHAR_SENSITIVE]: true,
    [PasswordValidationErrorState.CONTAINS_NUMBER]: true,
    [PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]: true,
    [PasswordValidationErrorState.CHAR_LENGTH]: true,
  });
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    watch,
    handleSubmit,
    reset,
    trigger,
  } = useForm<SecurityForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  useFormErrors(trigger, dirtyFields);

  const [resetPassword, { loading: isSaving }] = useMutation(RESET_USER_PASSWORD);
  const formId = useRef<string>(uuid());
  const currentPassword = watch('newPassword');
  const confirmPassword = watch('confirmNewPassword');

  useEffect(() => {
    if (currentPassword && confirmPassword) {
      trigger('confirmNewPassword');
    }
  }, [currentPassword]);

  const handleShowPassword = (e: Event) => {
    if (e.target) {
      setShowPasswordText(!showPasswordText);
    }
  };

  const handleConfirmShowPassword = (e: Event) => {
    if (e.target) {
      setShowConfirmPasswordText(!showConfirmPasswordText);
    }
  };

  const onSubmitNewPassword = (data: SecurityForm) => {
    const { newPassword } = data;
    reset();

    resetPassword({
      variables: {
        input: {
          password: newPassword,
        },
      },
    })
      .then(() => {
        dispatch({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.updated-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
        LogoutUser(false, `/${PageRoutes.RESET_PASSWORD_CONFIRMATION}`);
      })
      .catch(() => {
        dispatch({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  return (
    <SettingsWrapper
      title={t('account-settings.nav.profile')}
      buttonType="submit"
      buttonForm={formId.current}
      disabled={!isValid}
      isUpdating={isSaving}
    >
      <div className={cx(styles['security'])}>
        <div className={cx(styles['security__text-wrapper'])}>
          <p className={cx(styles['security__heading'])}>
            {t('account-settings.security.password-reset')}
          </p>
          <p className={cx(styles['security__subtitle'])}>
            {t('account-settings.security.subtitle')}
          </p>

          <form id={formId.current} onSubmit={handleSubmit(onSubmitNewPassword)}>
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error-required']]: errors.newPassword?.type === 'required',
              })}
              {...register('newPassword', {
                required: t('form.errors.required'),
                validate: {
                  gigyaRegexValidation: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.GIGYA_REGEX_VALIDATION,
                      setPasswordError
                    ),
                  gigyaRepeatedCharacters: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS,
                      setPasswordError
                    ),
                  containsSpecialCharacter: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR,
                      setPasswordError
                    ),
                  charLength: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.CHAR_LENGTH,
                      setPasswordError
                    ),
                },
              })}
              hasError={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              inputType={showPasswordText ? PasswordState.SHOW : PasswordState.HIDE}
              label={t('account-settings.security.new-password')}
              icon={showPasswordText ? HidePasswordIcon : ShowPasswordIcon}
              iconAsButton
              iconOnClick={handleShowPassword}
              iconAriaLabel={showPasswordText ? t('icons.hide-password') : t('icons.show-password')}
            />
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.confirmNewPassword,
              })}
              {...register('confirmNewPassword', {
                required: t('form.errors.required'),
                validate: (val) =>
                  !confirmPasswordMatch(currentPassword, val)
                    ? t('form.errors.password-match')
                    : true,
              })}
              inputType={showConfirmPasswordText ? PasswordState.SHOW : PasswordState.HIDE}
              label={t('account-settings.security.confirm-new-password')}
              hasError={!!errors.confirmNewPassword}
              helperText={errors.confirmNewPassword?.message}
              icon={
                // eslint-disable-next-line no-nested-ternary
                currentPassword
                  ? showConfirmPasswordText
                    ? HidePasswordIcon
                    : ShowPasswordIcon
                  : undefined
              }
              iconAsButton
              iconOnClick={handleConfirmShowPassword}
              iconAriaLabel={
                showConfirmPasswordText ? t('icons.hide-password') : t('icons.show-password')
              }
            />
            {currentPassword && (
              <div className={cx(styles['security__item'])}>
                <ValidationCriteria
                  label={t('onboarding.password-criteria-special-char')}
                  icon={
                    passwordError[PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-length')}
                  icon={
                    passwordError[PasswordValidationErrorState.CHAR_LENGTH]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.CHAR_LENGTH]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-gigya-repetition')}
                  icon={
                    passwordError[PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-gigya-validation')}
                  icon={
                    passwordError[PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </SettingsWrapper>
  );
};

Security.displayName = 'Security';

export default Security;
