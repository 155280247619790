import { ArrowDropDownIcon, LogoutIcon } from '../../../../assets/icons';
import { FC, useMemo, useRef, useState } from 'react';
import { Province, UserAccountInfo } from '../../../../__generated__/graphql';

import Avatar from '../../../_shared/Avatar';
import cx from 'classnames';
import { getRegion } from '../../../../lib/utils/utils';
import { Link } from 'react-router-dom';
import { LogoutUser } from '../../../../lib/clients/gigya-client';
import { PageRoutes } from '../../../../lib/constants/react-router';
import { Region } from '../../../../lib/constants/pre-orders';
import styles from './MyAccount.module.scss';
import { useEventListener } from '../../../../lib/hooks';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  user: Partial<UserAccountInfo> | undefined;
  farmProvince: Province | undefined;
}

const MyAccount: FC<Props> = ({ user, farmProvince }) => {
  const myAccountRef = useRef<HTMLDivElement>(null);
  const buttonId = useRef<string>(uuid());
  const menuId = useRef<string>(uuid());
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEventListener('mousedown', (e: any) => {
    if (myAccountRef.current && !myAccountRef.current.contains(e.target)) {
      setMenuOpen(false);
    }
  });

  const displayPreOrder = useMemo(
    () =>
      process.env.REACT_APP_SHOW_PRE_ORDERS &&
      farmProvince &&
      getRegion(farmProvince) === Region.WEST,
    [farmProvince]
  );

  const handleToggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div ref={myAccountRef} className={cx(styles['my-account'])}>
      <button
        className={cx(styles['my-account__toggle'], {
          [styles['my-account__toggle--open']]: menuOpen,
        })}
        type="button"
        aria-controls={menuId.current}
        aria-expanded={menuOpen}
        id={buttonId.current}
        onClick={handleToggleDropdown}
      >
        <div className={cx(styles['my-account__avatar-wrapper'])}>
          <Avatar
            profilePictureUrl={user?.profilePictureUrl}
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
          />
        </div>
        <p className={cx(styles['my-account__text'])}>{t('header.my-account')}</p>
        <ArrowDropDownIcon
          className={cx(styles['my-account__icon'], {
            [styles['my-account__icon--open']]: menuOpen,
          })}
          aria-hidden="true"
        />
      </button>
      <div
        className={cx(styles['my-account__dropdown'], {
          [styles['my-account__dropdown--hidden']]: !menuOpen,
        })}
        aria-hidden={!menuOpen}
        aria-labelledby={buttonId.current}
        id={menuId.current}
      >
        {displayPreOrder && (
          <Link
            to={PageRoutes.PRE_ORDERS}
            className={cx(styles['my-account__link'])}
            onClick={handleToggleDropdown}
          >
            {t('header.links.pre-orders')}
          </Link>
        )}

        {user?.onboardingComplete && (
          <Link
            className={cx(styles['my-account__link'])}
            to={PageRoutes.ACCOUNT_SETTINGS}
            onClick={handleToggleDropdown}
          >
            {t('header.links.settings')}
          </Link>
        )}

        <button
          className={cx(styles['my-account__link'])}
          onClick={() => {
            LogoutUser();
          }}
          type="button"
        >
          <span className={cx(styles['my-account__bold'])}>{t('header.links.logout')}</span>
          <LogoutIcon className={cx(styles['my-account__logout-icon'])} aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

MyAccount.displayName = 'MyAccount';

export default MyAccount;
