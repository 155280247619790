import { createContext, FC, useReducer } from 'react';
import {
  OrderHistoryContextProps,
  OrderHistoryContextState,
  OrderHistoryContextType,
} from './OrderHistoryContext.types';

import { getOrderHistoryFiltersFromSearchParams } from '../../utils/order-purchases';
import OrderHistoryContextReducer from './reducer';
import { PaginationProvider } from '../pagination/PaginationContext';

const initialState: OrderHistoryContextState = getOrderHistoryFiltersFromSearchParams();
const OrderHistoryContext = createContext<OrderHistoryContextType>([initialState, () => {}]);

export const OrderHistoryProvider: FC<OrderHistoryContextProps> = ({ children }) => {
  const value = useReducer(OrderHistoryContextReducer, initialState);

  return (
    <OrderHistoryContext.Provider value={value}>
      <PaginationProvider>{children} </PaginationProvider>
    </OrderHistoryContext.Provider>
  );
};

export default OrderHistoryContext;
