import { CheckmarkIcon } from '../../../assets/icons';
import cx from 'classnames';
import { Step as StepType } from '../../../lib/constants/stepper';
import styles from '../Stepper.module.scss';
import SubSteps from './SubSteps';
import { useTranslation } from 'react-i18next';

export interface StepProps {
  step: StepType;
  onStepChange: (step: StepType) => void;
  activeStepsIds: string[];
}

const Step = ({ step, onStepChange, activeStepsIds }: StepProps) => {
  const { t } = useTranslation();
  const isActive = activeStepsIds.includes(step.id);

  return (
    <li className={cx(styles['step-wrapper'])}>
      <button
        type="button"
        onClick={() => onStepChange(step)}
        className={cx(styles['step'], {
          [styles['step--active']]: isActive,
          [styles['step--completed']]: step.isCompleted,
          [styles['step--upcoming']]: !step.isCompleted && !step.isInProgress,
          [styles['step--parent']]: !step.Component,
        })}
        disabled={!step.isCompleted && !step.isInProgress}
      >
        <div className={cx(styles['step__number'])}>{step.index}</div>
        <div className={cx(styles['step__label'])}>
          {step.isCompleted && <span className="sr-only">{`${t('stepper.completed')}:`} </span>}
          {isActive && <span className="sr-only">{`${t('stepper.current')}:`} </span>}
          {t(step?.labelKey || '')}
        </div>
        {step.isCompleted && (
          <div className={cx(styles['step__icon'])}>
            <CheckmarkIcon aria-hidden="true" />
          </div>
        )}
      </button>
      {!!step.children?.length && (
        <SubSteps step={step} onSubStepChange={onStepChange} activeStepsIds={activeStepsIds} />
      )}
    </li>
  );
};

export default Step;
