import { OrderDirection, PurchasePreOrderSortField } from '../../../__generated__/graphql';
import { PurchaseHistoryFilters } from '../../models/Purchase.model';
import { ReactNode } from 'react';

export interface OrderHistoryContextState {
  query: string | undefined;
  filters: PurchaseHistoryFilters;
  limit: number;
  offset: number;
  sortBy: PurchasePreOrderSortField;
  sortOrder: OrderDirection;
}

export interface OrderHistoryContextProps {
  children: ReactNode;
}

export enum OrderHistoryContextActionTypes {
  UpdateFiltersQuery = 'UpdateFiltersQuery',
  UpdateOffset = 'UpdateOffset',
  UpdateSortBy = 'UpdateSortBy',
  UpdateSortOrder = 'UpdateSortOrder',
}

export type OrderHistoryContextAction =
  | {
      type: OrderHistoryContextActionTypes.UpdateFiltersQuery;
      payload: {
        query: string | undefined;
        filters: PurchaseHistoryFilters;
      };
    }
  | {
      type: OrderHistoryContextActionTypes.UpdateOffset;
      payload: number;
    }
  | { type: OrderHistoryContextActionTypes.UpdateSortBy; payload: PurchasePreOrderSortField }
  | { type: OrderHistoryContextActionTypes.UpdateSortOrder; payload: OrderDirection };

export type OrderHistoryContextDispatch = (action: OrderHistoryContextAction) => void;

export type OrderHistoryContextType = [OrderHistoryContextState, OrderHistoryContextDispatch];
