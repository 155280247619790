import { AccessLevel, FarmPartner } from '../../../../../__generated__/graphql';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CardsSkeleton from '../../_shared/CardsSkeleton/CardsSkeleton';
import cx from 'classnames';
import Disclaimer from '../../../../../components/_shared/Disclaimer';
import { GET_PARTNERS_PERMISSIONS } from '../../../../../lib/graphql/PartnerPermissions.gql';
import { InfoOutlineIcon } from '../../../../../assets/icons';
import Partner from './Partner/Partner';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../../lib/constants/stepper';
import StepsWrapper from '../../../StepsWrapper';
import styles from './PartnerPermissions.module.scss';
import Tooltip from '../../../../../components/_shared/Tooltip/Tooltip';
import { TooltipPosition } from '../../../../../components/_shared/Tooltip/Tooltip.types';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';

const PartnerPermissions: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_PARTNERS_PERMISSIONS);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [potentialPartnersList, setPotentialPartnersList] = useState<FarmPartner[]>();

  useEffect(() => {
    if (data?.farm?.partners) {
      const partners = [...data.farm.partners.filter((partner) => !partner.isParent)];
      partners.sort((partnerA, partnerB) => {
        if (
          partnerA.hasAccess === AccessLevel.FullAccess &&
          partnerB.hasAccess === AccessLevel.FullAccess
        )
          return 0;
        return partnerB.hasAccess === AccessLevel.FullAccess ? 1 : -1;
      });
      setPotentialPartnersList(partners as FarmPartner[]);
    }
  }, [data?.farm.partners]);

  useEffect(() => {
    if (error) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, error, t]);

  const renderPotentialPartners = () =>
    !loading ? (
      potentialPartnersList?.map((partner) => (
        <Partner partner={partner} key={partner.partner.farmInfo.id} />
      ))
    ) : (
      <CardsSkeleton />
    );

  const onBack = () => {
    back?.();
  };

  const onNext = () => {
    next?.();
  };

  return (
    <StepsWrapper
      title={t('onboarding.partner-permissions.heading')}
      subTitle={t('onboarding.partner-permissions.description')}
      submit={{ text: t('general.next'), onClick: onNext, disabled: loading }}
      back={{ text: t('general.back'), onClick: onBack }}
    >
      <div className={cx(styles['partner-permissions'])}>
        <h2 className={cx(styles['partner-permissions__subheading'])}>
          {t('onboarding.partner-permissions.potential-partners')}
          <Tooltip
            text={t(`onboarding.partner-permissions.partner-tooltip`)}
            position={TooltipPosition.TOP}
            className={cx(styles['partner-permissions__tooltip'])}
          >
            <InfoOutlineIcon
              className={cx(styles['partner-permissions__icon'])}
              aria-hidden="true"
            />
          </Tooltip>
        </h2>
        <p className={cx(styles['partner-permissions__user-description'])}>
          {t('onboarding.partner-permissions.potential-partners-description')}
        </p>
        <p className={cx(styles['partner-permissions__user-no-partners'])}>
          {t('onboarding.partner-permissions.no-partners')}
        </p>
        <Disclaimer
          title={t('punctuation.colon', { value: t('general.disclaimer') })}
          content={
            <Trans
              i18nKey="onboarding.partner-permissions.disclaimer-content"
              components={[<sup />]}
            />
          }
        />
        <div className={cx(styles['partner-permissions__user-wrapper'])}>
          {renderPotentialPartners()}
        </div>
      </div>
    </StepsWrapper>
  );
};

PartnerPermissions.displayName = 'PartnerPermissions';

export default PartnerPermissions;
