import { FC, SVGProps } from 'react';

export enum PillColors {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  WHITE = 'white',
}

export enum PillSizes {
  SM = 'sm',
  MD = 'md',
}

export interface Props {
  className?: string;
  color: PillColors;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  size?: PillSizes;
  text: string;
}
