import { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Skeleton.module.scss';

export interface Props {
  children?: ReactNode;
}

const Skeleton: FC<Props> = ({ children }) => (
  <div className={cx(styles['skeleton'])}>
    <div className={cx(styles['skeleton__header'])}>
      <div className={cx(styles['skeleton__retailer'])}>
        <div className={cx(styles['skeleton__retailer-avatar'])} />
        <div className={cx(styles['skeleton__retailer-info'])}>
          <div className={cx(styles['skeleton__retailer-name'])} />
          <div className={cx(styles['skeleton__retailer-location'])} />
        </div>
      </div>
    </div>
    <div className={cx(styles['skeleton__preorders'])}>{children}</div>
  </div>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
