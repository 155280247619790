import {
  GET_ALL_RETAILERS_WITH_PREFERRED,
  GET_PREFERRED_RETAILERS,
  GET_RETAILERS,
} from '../graphql/PreferredRetailers.gql';
import {
  GET_BAYER_VALUE_PROGRAM_QUERY,
  GET_BAYER_VALUE_RETREAT_INFO,
  GET_BAYER_VALUE_SEGMENTS_QUERY,
  GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
  GET_TOTAL_PURCHASES_REWARDS,
  GET_TOTAL_REWARDS_EARNED_QUERY,
} from '../graphql/ProgramRewards.gql';
import {
  GET_CROP_INFORMATION_QUERY,
  UPDATE_CROP_INFORMATION_MUTATION,
} from '../../pages/Onboarding/Setup/steps/CropInformation/CropInformation.gql';
import {
  GET_FARM_ADMIN_QUERY,
  GET_FARM_INFORMATION_QUERY,
  GET_FARM_MEMBERS_QUERY,
  GET_USER_AND_FARM_QUERY,
  UPDATE_FARM_INFORMATION_MUTATION,
} from '../graphql/FarmInformation.gql';
import { GET_FARM_CHILD_USERS, REMOVE_USER_FROM_FARM } from '../graphql/UserPermissions.gql';
import {
  GET_ORDER_HISTORY_FILTERS_QUERY,
  GET_PURCHASES_PRE_ORDER_TABLES,
} from '../../pages/OrderHistory/OrderHistory.gql';
import {
  GET_TRANSFER_OWNERSHIP,
  UPDATE_TRANSFER_OWNERSHIP,
} from '../graphql/TransfersOwnership.gql';
import {
  GET_USER_ACCOUNT_INFO,
  GET_USER_IS_ADMIN_QUERY,
  GET_USER_ONBOARDING_COMPLETE,
  GET_USER_REWARDS_ACCESS_QUERY,
  UPDATE_USER_ACCOUNT_INFO,
} from '../graphql/UserInformation.gql';
import { getCurrentRewardEarningPeriod, getRewardEarningPeriodDate } from '../utils/dates';
import {
  OrderDirection,
  PurchasePreOrderSortField,
  TransferOwnershipRequestStatus,
  VerificationMethod,
} from '../../__generated__/graphql';
import { GET_CROPS_QUERY } from '../graphql/Crops.gql';
import { GET_PARTNERS_PERMISSIONS } from '../graphql/PartnerPermissions.gql';
import { GET_PRODUCTS_QUERY } from '../graphql/Products.gql';
import { GET_PURCHASES_METRICS } from '../graphql/PurchaseMetrics.gql';
import { GET_PURCHASES_TABLE } from '../graphql/Purchases.gql';
import { GET_RECENT_PURCHASES_FOR_DASHBOARD } from '../../pages/Dashboard/components/PurchasesWidget/PurchaseWidget.gql';
import { GET_SHOPPING_CARTS_QUERY } from '../graphql/ShoppingCart.gql';
import { GET_SUMMARY_DETAILS_QUERY } from '../../pages/Onboarding/Setup/steps/Summary/Summary.gql';
import { GET_SUPPORT_CONTACTS } from '../graphql/SupportContact.gql';
import { GET_USER_INFO_NAV } from '../graphql/Navigation.gql';
import mockBayerValueProgram from './queries/bayer-value-program.mock.json';
import mockBayerValueRetreat from './queries/get-bayer-value-retreat-info.mock.json';
import mockChildUsers from './queries/child-users.mock.json';
import mockCrops from './queries/crops.mock.json';
import mockDashboardPurchases from './queries/dashboard-purchases.json';
import mockDeletedUser from './mutations/delete-user-from-farm.mock.json';
import mockFarm from './queries/farm.mock.json';
import mockFarmAdmin from './queries/farm-admin.mock.json';
import mockFarmCropInformation from './queries/farm-crop-information.mock.json';
import mockFarmMembers from './queries/farm-members.mock.json';
import mockFindRetailers from './queries/find-retailer.json';
import mockOrderHistoryFilters from './queries/order-history-filters.mock.json';
import mockOrderHistoryResults from './queries/order-history-table.mock.json';
import mockPartners from './queries/partners.mock.json';
import mockProductsByName from './queries/get-product.mock.json';
import mockPurchaseMetrics from './queries/purchas-metrics.mock.json';
import mockPurchasesTable from './queries/purchases-table.mock.json';
import mockRetailersWithPreferred from './queries/all-retailers-with-preferred.mock.json';
import mockRewardsAccessLevel from './queries/get-user-rewards-access.mock.json';
import mockRewardsBreakdown from './queries/get-program-rewards-breakdown.mock.json';
import mockRewardsTotal from './queries/rewards-total.mock.json';
import mockSegments from './queries/segments.mock.json';
import mockShoppingCart from './queries/shopping-cart.mock.json';
import mockSummary from './queries/summary.mock.json';
import mockSupportContacts from './queries/support-contacts.mock.json';
import mockTotalPurchasesRewards from './queries/total-purchases-rewards.json';
import mockTransferOwnership from './queries/transfer-ownership.json';
import mockTransferOwnershipCancel from './mutations/cancel-transfer-ownership.mock.json';
import mockUpdateCropsMutation from './mutations/update-crop-information.mock.json';
import mockUpdateFarmMutation from './mutations/update-farm-information.mock.json';
import mockUpdateProfileMutation from './mutations/update-user-information.mock.json';
import mockUser from './queries/user.mock.json';
import mockUserAndFarm from './queries/user-and-farm.mock.json';
import mockUserHeader from './queries/user-info-header.mock.json';
import mockUserIsAdmin from './queries/user-is-admin.mock.json';
import mockUserOnboardingComplete from './queries/user-onboarding-complete.mock.json';
import mockVerificationStatus from './queries/verification-status.mock.json';
import mockVerifyAdmin from './queries/verify-farm-admin.mock.json';
import { VERIFY_ADMIN_STATUS } from '../../pages/AccountCreation/subpages/PrimaryOwner/PrimaryOwner.gql';
import { VERIFY_FARM_ADMIN } from '../../pages/AccountCreation/subpages/VerificationMethod/VerificationMethod.gql';

export const AppMocks = [
  {
    request: {
      query: GET_USER_ACCOUNT_INFO,
    },
    result: { data: mockUser },
  },
  {
    request: {
      query: GET_CROP_INFORMATION_QUERY,
    },
    result: { data: mockFarmCropInformation },
  },
  {
    request: {
      query: GET_FARM_INFORMATION_QUERY,
    },
    result: { data: mockFarm },
  },
  {
    delay: 1000,
    request: {
      query: GET_FARM_CHILD_USERS,
    },
    result: { data: mockChildUsers },
  },
  {
    delay: 1000,
    request: {
      query: GET_PREFERRED_RETAILERS,
      variables: {},
    },
    result: {
      data: {
        user: {
          accountInfo: { id: mockUser.user.accountInfo.id },
          retailers: mockUser.user.retailers,
        },
      },
    },
  },
  {
    request: {
      query: GET_TRANSFER_OWNERSHIP,
    },
    result: { data: mockTransferOwnership },
  },
  {
    request: {
      query: UPDATE_TRANSFER_OWNERSHIP,
      variables: {
        input: {
          id: mockTransferOwnership.activeTransferOwnershipRequest.transferOwnershipRequest
            .transferOwnershipInformation.id,
          status: TransferOwnershipRequestStatus.Cancelled,
        },
      },
    },

    result: { data: mockTransferOwnershipCancel },
  },
  {
    request: {
      query: REMOVE_USER_FROM_FARM,
      variables: { userId: '200' },
    },
    result: { data: mockDeletedUser },
  },
  {
    request: {
      query: UPDATE_USER_ACCOUNT_INFO,
      variables: {
        input: {
          onboardingComplete: true,
        },
      },
    },
    result: { data: mockUpdateProfileMutation },
  },
  {
    request: {
      query: UPDATE_USER_ACCOUNT_INFO,
      variables: {
        input: {
          address: {
            address: mockUser.user.accountInfo.address.address,
            address2: mockUser.user.accountInfo.address.address2,
            city: mockUser.user.accountInfo.address.city,
            postalCode: mockUser.user.accountInfo.address.postalCode,
            province: mockUser.user.accountInfo.address.province,
          },
          businessPhone: mockUser.user.accountInfo.businessPhone,
          email: mockUser.user.accountInfo.email,
          firstName: mockUser.user.accountInfo.firstName,
          lastName: mockUser.user.accountInfo.lastName,
          mobilePhone: mockUser.user.accountInfo.mobilePhone,
        },
      },
    },
    result: { data: mockUpdateProfileMutation },
  },
  {
    request: {
      query: UPDATE_CROP_INFORMATION_MUTATION,
      variables: {
        input: {
          acrage: mockFarm.farm.farmInfo.cropInformation.acrage,
          cropIds: mockFarm.farm.farmInfo.cropInformation.crops.map((crop) => crop.id),
        },
      },
    },
    result: { data: mockUpdateCropsMutation },
  },
  {
    request: {
      query: UPDATE_FARM_INFORMATION_MUTATION,
      variables: {
        input: {
          farmId: mockFarm.farm.farmInfo.id,
          address: {
            address: mockFarm.farm.farmInfo.address.address,
            address2: mockFarm.farm.farmInfo.address.address2,
            city: mockFarm.farm.farmInfo.address.city,
            postalCode: mockFarm.farm.farmInfo.address.postalCode,
            province: mockFarm.farm.farmInfo.address.province,
          },
          preferredOption: mockFarm.farm.farmInfo.paymentPreference.preferredOption,
          name: mockFarm.farm.farmInfo.name,
          mailingAddress: {
            address: mockFarm.farm.farmInfo.paymentPreference.mailingAddress.address,
            address2: mockFarm.farm.farmInfo.paymentPreference.mailingAddress.address2,
            city: mockFarm.farm.farmInfo.paymentPreference.mailingAddress.city,
            postalCode: mockFarm.farm.farmInfo.paymentPreference.mailingAddress.postalCode,
            province: mockFarm.farm.farmInfo.paymentPreference.mailingAddress.province,
          },
          directDepositEmail: mockFarm.farm.farmInfo.paymentPreference.directDepositEmail,
          chequePayeeName: mockFarm.farm.farmInfo.paymentPreference.chequePayeeName,
        },
      },
    },
    result: { data: mockUpdateFarmMutation },
  },
  {
    request: {
      query: GET_RETAILERS,
      variables: { input: { city: undefined, name: undefined, province: undefined } },
    },
    result: { data: mockFindRetailers },
  },
  {
    request: {
      query: GET_PARTNERS_PERMISSIONS,
    },
    result: { data: mockPartners },
  },
  {
    request: {
      query: GET_FARM_CHILD_USERS,
    },
    result: { data: mockChildUsers },
  },
  {
    request: {
      query: GET_USER_REWARDS_ACCESS_QUERY,
    },
    result: { data: mockRewardsAccessLevel },
  },
  {
    delay: 1500,
    request: {
      query: GET_RECENT_PURCHASES_FOR_DASHBOARD,
      variables: {
        orderBy: {
          orderDirection: OrderDirection.Desc,
          orderField: PurchasePreOrderSortField.Date,
        },
        limit: 5,
      },
    },
    result: { data: mockDashboardPurchases },
  },
  {
    delay: 1500,
    request: {
      query: GET_PURCHASES_TABLE,
      variables: {
        orderBy: {
          orderDirection: OrderDirection.Desc,
          orderField: PurchasePreOrderSortField.Date,
        },
        limit: 5,
        filterBy: {
          dateRange: {
            startDate: getRewardEarningPeriodDate().start,
            endDate: getRewardEarningPeriodDate().end,
          },
        },
      },
    },
    result: { data: mockPurchasesTable },
  },
  {
    delay: 1500,
    request: {
      query: GET_PURCHASES_TABLE,
      variables: {
        orderBy: {
          orderDirection: OrderDirection.Desc,
          orderField: PurchasePreOrderSortField.Date,
        },
        limit: 5,
        filterBy: {
          programIds: [mockBayerValueProgram.enrolledProgramsInfo.bayerValueProgramInfo.program.id],
          dateRange: {
            startDate: getRewardEarningPeriodDate().start,
            endDate: getRewardEarningPeriodDate().end,
          },
        },
      },
    },
    result: { data: mockPurchasesTable },
  },
  {
    delay: 1500,
    request: {
      query: GET_PURCHASES_TABLE,
      variables: {
        orderBy: {
          orderDirection: OrderDirection.Desc,
          orderField: PurchasePreOrderSortField.Date,
        },
        limit: 5,
        filterBy: {
          programIds: mockRewardsBreakdown.enrolledProgramsInfo.otherPrograms.map(
            (item) => item.program.id
          ),
          dateRange: {
            startDate: getRewardEarningPeriodDate().start,
            endDate: getRewardEarningPeriodDate().end,
          },
        },
      },
    },
    result: { data: mockPurchasesTable },
  },
  {
    request: {
      query: GET_SUMMARY_DETAILS_QUERY,
      variables: { skipFarm: true },
    },
    result: { data: mockSummary },
  },
  {
    request: {
      query: GET_USER_INFO_NAV,
    },
    result: { data: mockUserHeader },
  },
  {
    request: {
      query: GET_USER_INFO_NAV,
    },
    result: { data: mockUserHeader },
  },
  {
    request: {
      query: GET_USER_ONBOARDING_COMPLETE,
    },
    result: { data: mockUserOnboardingComplete },
  },
  {
    delay: 1500,
    request: {
      query: GET_ORDER_HISTORY_FILTERS_QUERY,
    },
    result: { data: mockOrderHistoryFilters },
  },
  {
    delay: 1500,
    request: {
      query: GET_PURCHASES_PRE_ORDER_TABLES,
      variables: {
        orderBy: {
          orderDirection: OrderDirection.Desc,
          orderField: PurchasePreOrderSortField.Date,
        },
        limit: 16,
        offset: 0,
        filterBy: {
          query: '',
          programIds: [],
          productSkus: [],
          retailerIds: [],
        },
      },
    },
    result: { data: mockOrderHistoryResults },
  },
  {
    request: {
      query: GET_SUPPORT_CONTACTS,
    },
    result: { data: mockSupportContacts },
  },
  {
    request: {
      query: GET_USER_AND_FARM_QUERY,
    },
    result: { data: mockUserAndFarm },
  },
  {
    delay: 2000,
    request: {
      query: GET_FARM_MEMBERS_QUERY,
    },
    result: { data: mockFarmMembers },
  },
  {
    delay: 2000,
    request: {
      query: GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
      variables: {
        input: {
          year: getCurrentRewardEarningPeriod().currentPeriodEnd,
        },
      },
    },
    result: { data: mockRewardsBreakdown },
  },
  {
    delay: 2000,
    request: {
      query: GET_TOTAL_REWARDS_EARNED_QUERY,
      variables: {
        input: {
          year: getCurrentRewardEarningPeriod().currentPeriodEnd,
        },
      },
    },
    result: { data: mockRewardsTotal },
  },
  {
    delay: 2000,
    request: {
      query: GET_FARM_ADMIN_QUERY,
    },
    result: { data: mockFarmAdmin },
  },
  {
    delay: 1000,
    request: {
      query: GET_USER_IS_ADMIN_QUERY,
    },
    result: { data: mockUserIsAdmin },
  },
  {
    delay: 2000,
    request: {
      query: GET_TOTAL_PURCHASES_REWARDS,
      variables: {
        input: {
          year: getCurrentRewardEarningPeriod().currentPeriodEnd,
        },
        input2: {
          year: getCurrentRewardEarningPeriod().currentPeriodEnd,
        },
      },
    },
    result: { data: mockTotalPurchasesRewards },
  },
  {
    request: {
      query: GET_BAYER_VALUE_PROGRAM_QUERY,
    },
    result: { data: mockBayerValueProgram },
  },
  {
    request: {
      query: GET_CROPS_QUERY,
    },
    result: { data: mockCrops },
  },
  {
    request: {
      query: VERIFY_ADMIN_STATUS,
      variables: {
        input: {
          farmId: mockUser.user.accountInfo.farmId,
          phone: mockUser.user.accountInfo.mobilePhone,
          email: null,
        },
      },
    },
    result: { data: mockVerificationStatus },
  },
  {
    request: {
      query: VERIFY_FARM_ADMIN,
      variables: {
        input: {
          farmId: mockUser.user.accountInfo.farmId,
          phone: mockUser.user.accountInfo.mobilePhone,
          verificationMethod: VerificationMethod.Sms,
        },
      },
    },
    result: { data: mockVerifyAdmin },
  },
  {
    delay: 2000,
    request: {
      query: GET_SHOPPING_CARTS_QUERY,
    },
    result: { data: mockShoppingCart },
  },
  {
    delay: 2000,
    request: {
      query: GET_ALL_RETAILERS_WITH_PREFERRED,
      variables: {
        input: {},
      },
    },
    result: { data: mockRetailersWithPreferred },
  },
  {
    delay: 2000,
    request: {
      query: GET_PRODUCTS_QUERY,
      variables: {
        input: { name: mockProductsByName.products.products[0].name },
      },
    },
    result: { data: mockProductsByName },
  },
  {
    delay: 2000,
    request: {
      query: GET_BAYER_VALUE_SEGMENTS_QUERY,
      variables: {
        input: { year: 2023 },
      },
    },
    result: { data: mockSegments },
  },
  {
    request: {
      query: GET_BAYER_VALUE_RETREAT_INFO,
      variables: {
        input: { year: getCurrentRewardEarningPeriod().currentPeriodEnd },
      },
    },
    result: { data: mockBayerValueRetreat },
  },
  {
    request: {
      query: GET_PURCHASES_METRICS,
    },
    result: { data: mockPurchaseMetrics },
  },
].map((mock: any) => ({
  ...mock,
  newData: () => ({ ...(mock?.result || {}) }),
}));
