import dayjs from 'dayjs';
import { Province } from '../../__generated__/graphql';

export interface Promotion {
  provinces?: Province[];
  endDate: dayjs.Dayjs;
}

export const SummerSizzlerPromotion = {
  provinces: [Province.Ab, Province.Mb, Province.Sk],
};

export const DelkabPromotion = {
  provinces: [Province.Ab, Province.Mb, Province.Sk],
  endDate: dayjs('12/13/2024'),
};
