import Button from '../../components/_shared/Button';
import { ButtonThemes } from '../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import image from '../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../components/ImageWithContent';
import { loadGigyaScreenSet } from '../../lib/utils/gigya';
import PreAuthContainer from '../../components/PreAuthContainer';
import styles from './EmailVerified.module.scss';
import { useTranslation } from 'react-i18next';
import { useUrlLanguage } from '../../lib/hooks/useUrlLanguage';

const EmailVerified: FC = () => {
  useUrlLanguage();
  const errorCode = Number(new URLSearchParams(location.search).get('errorCode'));
  const { t } = useTranslation();

  return (
    <PreAuthContainer>
      <ImageWithContent imageSrc={image} imageAlt={t('images.farmers')}>
        <div className={cx(styles['email-verified-container'])}>
          <div className={cx(styles['email-verified-container__inner'])}>
            <h1 className={cx(styles['email-verified-container__header'])}>
              {t(`email-verified.${errorCode !== 0 ? 'error.' : ''}heading`)}
            </h1>
            <p className={cx(styles['email-verified-container__subtitle'])}>
              {t(`email-verified.${errorCode !== 0 ? 'error.' : ''}subheading`)}
            </p>
            <Button
              altText={t('email-verified.button')}
              type="button"
              onClick={() => loadGigyaScreenSet()}
              className={cx(styles['email-verified-container__item'])}
              theme={ButtonThemes.PRIMARY}
            >
              {t('email-verified.button')}
            </Button>
          </div>
        </div>
      </ImageWithContent>
    </PreAuthContainer>
  );
};

EmailVerified.displayName = 'EmailVerified';

export default EmailVerified;
