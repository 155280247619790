import cx from 'classnames';
import { FC } from 'react';
import Spinner from '../../../../../components/_shared/Spinner';
import styles from './Skeleton.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
}

const Skeleton: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['skeleton'])}>
      <div className={cx(styles['skeleton__metrics'])}>
        <div className={cx(styles['skeleton__metrics-inner'])}>
          <div className={cx(styles['skeleton__donut-wrapper'])}>
            <Spinner />
          </div>
          <div className={cx(styles['skeleton__information'])}>
            <div className={cx(styles['skeleton__metrics-heading'])}>
              <p className={cx(styles['skeleton__metrics-title'])}>
                {t('bayer-rewards-widget.metrics-title.rewards-earned')}
              </p>
              <div className={cx(styles['skeleton__metrics-subtitle'])}></div>
            </div>
            <div className={cx(styles['skeleton__cards-wrapper'])}>
              <p className={cx(styles['skeleton__metrics-title'])}>
                {t('bayer-rewards-widget.metrics-title.program-breakdown')}
              </p>
              <div className={cx(styles['skeleton__card'])}></div>
              <div className={cx(styles['skeleton__card'])}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Skeleton.displayName = 'Skeleton';

export default Skeleton;
