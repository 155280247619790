import {
  AccessLevel,
  FarmPartner,
  UpdateUserAccessLevelInput,
  UserAccountInfo,
  UserStatus,
} from '../../../../__generated__/graphql';
import { Controller, useForm } from 'react-hook-form';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DropdownOption, OptionsFooter } from '../../../../lib/constants/react-select';
import {
  GET_FARM_CHILD_USERS,
  UPDATE_USER_ACCESS_LEVEL,
} from '../../../../lib/graphql/UserPermissions.gql';
import { useMutation, useQuery } from '@apollo/client';
import { ArrowDropDownIcon } from '../../../../assets/icons';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import Dropdown from '../../../../components/_shared/Dropdown';
import { GET_PARTNERS_PERMISSIONS } from '../../../../lib/graphql/PartnerPermissions.gql';
import { GET_TRANSFER_OWNERSHIP } from '../../../../lib/graphql/TransfersOwnership.gql';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { getUserAccessLevels } from '../../../../lib/utils/onboarding';
import InviteUserModal from '../../../../components/InviteUserModal';
import { ListType } from '../../../../lib/constants/users';
import Partners from './Partners';
import SettingsWrapper from '../../components/SettingsWrapper';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './UserPermissions.module.scss';
import TransferOwnership from './TransferOwnership';
import UserList from './UserList';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const UserPermissions: FC = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useQuery(GET_USER_IS_ADMIN_QUERY);
  const { data: partners, loading: loadingPartners } = useQuery(GET_PARTNERS_PERMISSIONS);
  const { data: childUsers, loading: loadingChildUsers } = useQuery(GET_FARM_CHILD_USERS);
  const { loading: loadingTransfers } = useQuery(GET_TRANSFER_OWNERSHIP);
  const [updateUserAccessLevel] = useMutation(UPDATE_USER_ACCESS_LEVEL, {
    refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();
  const [accessLevels, setAccessLevels] = useState<DropdownOption[]>(getUserAccessLevels(t));
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activeUsers, setActiveUsers] = useState<UserAccountInfo[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<UserAccountInfo[]>([]);
  const [recommendedUsers, setRecommendedUsers] = useState<UserAccountInfo[]>([]);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setAccessLevels(getUserAccessLevels(t));
  }, [t]);

  useEffect(() => {
    const active: UserAccountInfo[] = [];
    const invited: UserAccountInfo[] = [];
    const recommended: UserAccountInfo[] = [];

    childUsers?.farm.users.forEach((user) => {
      if (user.accountInfo && user.accountInfo.id !== currentUser?.user.accountInfo.id) {
        switch (user.accountInfo.status) {
          case UserStatus.Active:
            active.push(user.accountInfo as UserAccountInfo);
            break;
          case UserStatus.PendingEmailConfirmation:
          case UserStatus.ExternalInvitedPendingSignup:
          case UserStatus.CrmInvitedPendingSignup:
            invited.push(user.accountInfo as UserAccountInfo);
            break;
          case UserStatus.CrmInitialized:
            recommended.push(user.accountInfo as UserAccountInfo);
            break;
          default:
            break;
        }
      }
    });

    setActiveUsers(active);
    setInvitedUsers(invited);
    setRecommendedUsers(recommended);
  }, [childUsers, currentUser?.user.accountInfo.id]);

  const handleUpdateUserPermission = (
    data: any,
    setLoadingUsers: Dispatch<SetStateAction<string[]>>
  ) => {
    const input: UpdateUserAccessLevelInput = {
      accessLevel: data.target.value as AccessLevel,
      userId: data.id,
    };
    setLoadingUsers((prevUsers) => [...prevUsers, data.id]);
    updateUserAccessLevel({ variables: { input } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.updated-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setLoadingUsers((prevUsers) => prevUsers.filter((id) => id !== data.id));
      });
  };

  const renderDropdown = (
    onSubmit: (data: any, setLoadingUser: any) => void,
    userOrPartner: UserAccountInfo | FarmPartner,
    isLoading: boolean = false,
    optionsFooter: undefined | OptionsFooter = undefined,
    setLoadingUsers: Dispatch<SetStateAction<string[]>> | undefined = undefined
  ) => {
    let accessLevel: string;
    let id: string;
    if ((userOrPartner as UserAccountInfo).accessLevel) {
      accessLevel = (userOrPartner as UserAccountInfo).accessLevel || AccessLevel.NoAccess;
      id = (userOrPartner as UserAccountInfo).id;
    } else {
      accessLevel = (userOrPartner as FarmPartner).hasAccess;
      id = (userOrPartner as FarmPartner).partner.farmInfo.id;
    }

    const currentVal = accessLevels.find((option) => option.value === accessLevel);
    const nameId = `accesslevel-${id}`;

    const dropdownForm = (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name={nameId}
          render={({ field: { onChange, name } }) => (
            <Dropdown
              name={name}
              value={currentVal}
              label={t('form.labels.select-user-permissions')}
              className={cx(styles['form-dropdown__partner'])}
              options={accessLevels}
              searchable={false}
              onChange={(e) => {
                onChange(e);
                onSubmit({ ...e, id }, setLoadingUsers);
              }}
              DropdownIndicatorIcon={ArrowDropDownIcon}
              defaultValue={currentVal}
              optionsFooter={optionsFooter}
              isLoading={isLoading}
            />
          )}
        />
      </form>
    );

    return dropdownForm;
  };

  return (
    <SettingsWrapper
      title={t('account-settings.nav.user-permissions')}
      buttonType="button"
      hideSaveButton
      isLoadingData={loadingPartners || loadingChildUsers || loadingTransfers}
    >
      <div className={cx(styles['user-permissions'])}>
        {partners && !!partners.farm.partners?.length && (
          <>
            <div className={cx(styles['user-permissions__top-row'])}>
              <div className={cx(styles['user-permissions__text-wrapper'])}>
                <h2 className={cx(styles['user-permissions__heading'])}>
                  {t('account-settings.user-permissions.farm-partner')}
                </h2>
                <p className={cx(styles['user-permissions__subtitle'])}>
                  {t('account-settings.user-permissions.farm-partner-subtitle')}
                </p>
              </div>
            </div>
            <Partners
              renderDropdown={renderDropdown}
              partners={partners.farm.partners as FarmPartner[]}
            />
            <div className={cx(styles['user-permissions__divider'])} />
          </>
        )}
        <div className={cx(styles['user-permissions__top-row'])}>
          <div className={cx(styles['user-permissions__text-wrapper'])}>
            <h2 className={cx(styles['user-permissions__heading'])}>
              {t('account-settings.user-permissions.heading')}
            </h2>
            <p className={cx(styles['user-permissions__subtitle'])}>
              {t('account-settings.user-permissions.subtitle')}
            </p>
          </div>
          <Button
            type="button"
            theme={ButtonThemes.TEXT_LINK}
            className={cx(styles['user-permissions__button'])}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            {t('account-settings.user-permissions.invite-user')}
          </Button>
          <InviteUserModal
            isVisible={modalVisible}
            hide={() => {
              setModalVisible(false);
            }}
            accessLevels={accessLevels}
          />
        </div>
        <UserList
          users={activeUsers}
          renderDropdown={renderDropdown}
          updateUsers={handleUpdateUserPermission}
          listType={ListType.ACTIVE}
        />

        {!!invitedUsers.length && (
          <>
            <div className={cx(styles['user-permissions__divider'])} />
            <div className={cx(styles['user-permissions__top-row'])}>
              <div className={cx(styles['user-permissions__text-wrapper'])}>
                <h2 className={cx(styles['user-permissions__heading'])}>
                  {t('account-settings.user-permissions.invited-users')}
                </h2>
              </div>
            </div>
            <UserList
              users={invitedUsers}
              renderDropdown={renderDropdown}
              updateUsers={handleUpdateUserPermission}
              listType={ListType.INVITED}
            />
          </>
        )}
        {!!recommendedUsers.length && (
          <>
            <div className={cx(styles['user-permissions__divider'])} />
            <div className={cx(styles['user-permissions__top-row'])}>
              <div className={cx(styles['user-permissions__text-wrapper'])}>
                <h2 className={cx(styles['user-permissions__heading'])}>
                  {t('account-settings.user-permissions.user-suggestions')}
                </h2>
              </div>
            </div>
            <UserList
              users={recommendedUsers}
              renderDropdown={renderDropdown}
              updateUsers={handleUpdateUserPermission}
              listType={ListType.SUGGESTED}
            />
          </>
        )}
        <div className={cx(styles['user-permissions__divider'])} />
        <TransferOwnership />
      </div>
    </SettingsWrapper>
  );
};

UserPermissions.displayName = 'UserPermissions';

export default UserPermissions;
