import {
  AccessLevel,
  OrderDirection,
  PurchasePreOrderSortField,
} from '../../../../__generated__/graphql';
import { FC, ReactNode, useRef } from 'react';
import { getDynamicProgramColorMap, getProgramsWithOther } from '../../../../lib/utils/programs';
import { Trans, useTranslation } from 'react-i18next';

import { CartEmptyIcon } from '../../../../assets/icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import { GET_RECENT_PURCHASES_FOR_DASHBOARD } from './PurchaseWidget.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import Link from '../../../../components/_shared/Link';
import { LinkUrls } from '../../../../lib/constants/react-router';
import ProgramBadge from '../../../../components/_shared/ProgramBadge';
import { ProgramBadgeSize } from '../../../../lib/constants/programs';
import Skeleton from './Skeleton/Skeleton';
import { slashSeparatedDate } from '../../../../lib/constants/date-formats';
import styles from './PurchasesWidget.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState';
import { useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';

const PurchasesTable: FC = () => {
  const { t } = useTranslation();
  const { data: rewardsAccessData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const { data: purchasesData, loading } = useQuery(GET_RECENT_PURCHASES_FOR_DASHBOARD, {
    variables: {
      orderBy: {
        orderDirection: OrderDirection.Desc,
        orderField: PurchasePreOrderSortField.Date,
      },
      limit: 5,
    },
    skip: rewardsAccessData?.user.accountInfo.accessLevel === AccessLevel.NoAccess,
  });
  const emptyTableStateId = useRef<string>(uuid());

  const renderTableBody = (): ReactNode => {
    if (purchasesData?.purchaseProducts.purchaseProducts.length === 0) {
      return (
        <td colSpan={10} aria-labelledby={emptyTableStateId.current}>
          <TableEmptyState
            Icon={CartEmptyIcon}
            title={t(`purchases.table-empty.title-purchase`)}
            id={emptyTableStateId.current}
            description={
              <Trans
                i18nKey="purchases.table-empty.description-purchase"
                values={{
                  date: dayjs().format(slashSeparatedDate),
                }}
                components={[<br />, <Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />]}
              />
            }
          />
        </td>
      );
    }
    return (purchasesData?.purchaseProducts.purchaseProducts || []).map((purchase) => (
      <tr className={cx(styles['purchases-widget__row'])} key={purchase.id}>
        <td
          className={cx(styles['purchases-widget__cell'], styles['purchases-widget__product-name'])}
        >
          {purchase.product.name}
        </td>
        <td className={cx(styles['purchases-widget__cell'])}>
          <div className={cx(styles['purchases-widget__badges-wrapper'])}>
            {getProgramsWithOther(purchase.rewardPrograms, t).map((program) => (
              <ProgramBadge
                key={program.id}
                className={cx(styles['purchases-widget__badge'])}
                program={getDynamicProgramColorMap(program)}
                size={ProgramBadgeSize.SM}
              />
            ))}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <table className={cx(styles['purchases-widget__table'])}>
        <thead>
          <tr
            className={cx(styles['purchases-widget__row'], {
              [styles['purchases-widget__row--loading']]: loading,
            })}
          >
            <th
              className={cx(
                styles['purchases-widget__cell'],
                styles['purchases-widget__table-heading'],
                styles['purchases-widget__table-heading--greedy'],
                {
                  [styles['purchases-widget__table-heading--loading']]: loading,
                }
              )}
            >
              {t('purchases-widget.product')}
            </th>
            <th
              className={cx(
                styles['purchases-widget__cell'],
                styles['purchases-widget__table-heading'],
                {
                  [styles['purchases-widget__table-heading--loading']]: loading,
                }
              )}
            >
              {t('purchases-widget.programs')}
            </th>
          </tr>
        </thead>
        <tbody>{loading ? <Skeleton /> : renderTableBody()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
