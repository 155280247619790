export type LocalTeamMember = {
  id: string;
  role: string;
  contactFullName: string;
  email: string;
  phone: string;
  websiteUrl?: string;
  retailerName?: string;
};

export enum LocalTeamMemberType {
  RETAILER = 'retailer',
  SUPPORT = 'support-contact',
}
