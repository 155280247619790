import { BannerTheme } from '../../../../components/BayerValue/ClimateFieldViewBanner/ClimateFieldViewBanner.types';
import CalculatorBanner from '../../../../components/BayerValue/CalculatorBanner';
import ClimateFieldViewBanner from '../../../../components/BayerValue/ClimateFieldViewBanner/ClimateFieldViewBanner';
import cx from 'classnames';
import { FC } from 'react';
import { FlagKeys } from '../../../../lib/constants/launch-darkly';
import LaunchDarklyWrapper from '../../../../components/_shared/LaunchDarklyWrapper';
import { LDIdentityType } from '../../../../components/_shared/LaunchDarklyWrapper/LaunchDarklyWrapper.types';
import styles from './BannersSection.module.scss';

const BannersSection: FC = () => (
  <section className={cx(styles['banners-section'])}>
    <div className={cx(styles['banners-section__climate-field-view-wrapper'])}>
      <ClimateFieldViewBanner bannerTheme={BannerTheme.REWARDS_LANDING} />
    </div>
    <LaunchDarklyWrapper flagKey={FlagKeys.CALCULATOR_AD} identityType={LDIdentityType.ID}>
      <div className={cx(styles['banners-section__calculator-wrapper'])}>
        <CalculatorBanner />
      </div>
    </LaunchDarklyWrapper>
  </section>
);

BannersSection.displayName = 'BannersSection';

export default BannersSection;
