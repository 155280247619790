import {
  AccountTransferIcon,
  ArrowDropDownIcon,
  HourglassIcon,
  SpinnerIcon,
} from '../../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { Controller, useForm } from 'react-hook-form';
import {
  FarmTransferOwnershipInput,
  TransferOwnershipRequestStatus,
  UpdateFarmTransferOwnershipInput,
  User,
  UserStatus,
} from '../../../../__generated__/graphql';
import { FC, useRef, useState } from 'react';
import {
  GET_TRANSFER_OWNERSHIP,
  START_TRANSFER_OWNERSHIP,
  UPDATE_TRANSFER_OWNERSHIP,
} from '../../../../lib/graphql/TransfersOwnership.gql';
import { useMutation, useQuery } from '@apollo/client';
import Avatar from '../../../../components/_shared/Avatar';
import Button from '../../../../components/_shared/Button';
import cx from 'classnames';
import Dropdown from '../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../lib/constants/react-select';
import { GET_FARM_CHILD_USERS } from '../../../../lib/graphql/UserPermissions.gql';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { getPartialUserOptions } from '../../../../lib/utils/onboarding';
import Modal from '../../../../components/_shared/Modal';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './UserPermissions.module.scss';
import { toGQLLanguage } from '../../../../lib/utils/i18n';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const TransferOwnership: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { data: transferData, loading: transferLoading } = useQuery(GET_TRANSFER_OWNERSHIP);
  const { data: childUsersData } = useQuery(GET_FARM_CHILD_USERS);
  const { data: adminData } = useQuery(GET_USER_IS_ADMIN_QUERY);
  const [startTransferOwnership, { loading: startTransferLoading }] = useMutation(
    START_TRANSFER_OWNERSHIP,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_TRANSFER_OWNERSHIP }],
    }
  );
  const [updateTransferRequest, { loading: updateTransferLoading }] = useMutation(
    UPDATE_TRANSFER_OWNERSHIP,
    {
      refetchQueries: [{ query: GET_TRANSFER_OWNERSHIP }],
    }
  );
  const [, dispatchSnackbar] = useSnackbarContext();
  const transferOwnershipFormId = useRef<string>(uuid());
  const [transferModalVisible, setTransferModalVisible] = useState<boolean>(false);
  const [statusModalVisible, setStatusModalVisible] = useState<boolean>(false);
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);

  const { handleSubmit, reset, control } = useForm();

  const createUserOptions = () => {
    const users = getPartialUserOptions(
      t,
      (childUsersData?.farm?.users || []).filter(
        (user) =>
          user.accountInfo.id !== adminData?.user.accountInfo.id &&
          user.accountInfo.status === UserStatus.Active
      ) as Partial<User>[]
    );
    return users.map((childUser) => {
      const avatar = (
        <Avatar
          firstName={childUser.firstName || ''}
          lastName={childUser.lastName || ''}
          profilePictureUrl={childUser.imgSrc || ''}
          className={cx(styles['form-dropdown__avatar'])}
        />
      );
      return {
        ...childUser,
        iconLeft: avatar,
      };
    });
  };

  const userOptions = createUserOptions();

  const onSubmit = (formData: any) => {
    const input: FarmTransferOwnershipInput = {
      newAdminUserId: formData.user,
      language: toGQLLanguage(language),
    };
    startTransferOwnership({ variables: { input } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('account-settings.my-operation.transfer-ownership.success-sent'),
            state: SnackbarStates.SUCCESS,
          },
        });
        setStatusModalVisible(true);
        setTransferModalVisible(false);
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  const onCancelTransferOwnership = () => {
    if (
      transferData?.activeTransferOwnershipRequest.transferOwnershipRequest
        ?.transferOwnershipInformation.id
    ) {
      const input: UpdateFarmTransferOwnershipInput = {
        id: transferData?.activeTransferOwnershipRequest.transferOwnershipRequest
          ?.transferOwnershipInformation.id,
        status: TransferOwnershipRequestStatus.Cancelled,
      };

      updateTransferRequest({
        variables: {
          input,
        },
      })
        .then(() => {
          setCancelModalVisible(false);
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('account-settings.my-operation.transfer-ownership.success-cancel'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const renderTransferStatus = () => {
    const { firstName, lastName } = transferData?.activeTransferOwnershipRequest
      .transferOwnershipRequest?.newAdminUser.accountInfo || { firstName: '', lastName: '' };

    return (
      <div className={cx(styles['user-permissions__text-wrapper'])}>
        <div className={cx(styles['user-permissions__icon-wrapper'])}>
          <HourglassIcon
            className={cx(
              styles['user-permissions__icon--transfer'],
              styles['user-permissions__icon']
            )}
            aria-hidden="true"
          />
          <h3 className={cx(styles['user-permissions__sub-heading'])}>
            {t('account-settings.user-permissions.transfer-ownership-status', {
              firstName,
              lastName,
            })}
          </h3>
        </div>
        <p className={cx(styles['user-permissions__subtitle'])}>
          {t('account-settings.user-permissions.transfer-ownership-status-description', {
            firstName,
            lastName,
          })}
        </p>
        {!statusModalVisible && (
          <Button
            type="button"
            theme={ButtonThemes.TEXT_LINK}
            className={cx(styles['user-permissions__button'])}
            onClick={() => {
              setCancelModalVisible(true);
            }}
          >
            {t('account-settings.user-permissions.cancel-transfer-ownership')}
          </Button>
        )}
      </div>
    );
  };

  const renderTransferOwnership = () => (
    <div className={cx(styles['user-permissions__text-wrapper'])}>
      <div className={cx(styles['user-permissions__icon-wrapper'])}>
        <AccountTransferIcon className={cx(styles['user-permissions__icon'])} aria-hidden="true" />
        <h3 className={cx(styles['user-permissions__sub-heading'])}>
          {t('account-settings.user-permissions.transfer-ownership')}
        </h3>
      </div>
      <p className={cx(styles['user-permissions__subtitle'])}>
        {t('account-settings.user-permissions.transfer-ownership-subtitle')}
      </p>
      <Button
        type="button"
        theme={ButtonThemes.TEXT_LINK}
        className={cx(styles['user-permissions__button'])}
        onClick={() => {
          setTransferModalVisible(true);
        }}
      >
        {t('account-settings.user-permissions.transfer-ownership-button')}
      </Button>

      <Modal
        classNames={{
          modalWrapper: cx(styles['modal']),
          modalContent: cx(styles['modal']),
        }}
        isVisible={transferModalVisible}
        hide={() => {
          reset();
          setTransferModalVisible(false);
        }}
        title={t('account-settings.user-permissions.transfer-ownership')}
        primaryCta={{
          label: t('account-settings.user-permissions.transfer-ownership'),
          action: () => {},
          buttonType: 'submit',
          buttonFormId: transferOwnershipFormId.current,
          disabled: startTransferLoading,
          buttonIcon: startTransferLoading ? SpinnerIcon : undefined,
          buttonIconClassName: cx({
            [styles['user-permissions__spinner']]: startTransferLoading,
          }),
          buttonIconPosition: IconPosition.LEFT,
        }}
        secondaryCta={{
          label: t('general.cancel'),
          disabled: startTransferLoading,
          action: () => {
            reset();
            setTransferModalVisible(false);
          },
        }}
      >
        <div className={cx(styles['user-permissions__text-wrapper'])}>
          <p
            className={cx(
              styles['user-permissions__sub-heading'],
              styles['user-permissions__sub-heading--modal']
            )}
          >
            {t('account-settings.user-permissions.transfer-ownership-modal-subtitle')}
          </p>
          <p
            className={cx(
              styles['user-permissions__subtitle'],
              styles['user-permissions__subtitle--modal']
            )}
          >
            {t('account-settings.user-permissions.transfer-ownership-modal-description')}
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={transferOwnershipFormId.current}
          className={cx(styles['form'])}
        >
          <Controller
            control={control}
            name="user"
            rules={{
              required: t('account-settings.user-permissions.transfer-ownership-error'),
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <Dropdown
                name={name}
                className={cx(styles['form-dropdown'])}
                value={userOptions?.find((user) => user.value === value) as DropdownOption}
                label={t('form.labels.select-user')}
                options={userOptions as DropdownOption[]}
                searchable={false}
                onChange={onChange}
                DropdownIndicatorIcon={ArrowDropDownIcon}
                hasError={!!error}
                helperText={error?.message}
              />
            )}
          />
        </form>
      </Modal>
    </div>
  );

  return (
    <div className={cx(styles['transfer-ownership'])}>
      {!transferLoading &&
      transferData &&
      transferData.activeTransferOwnershipRequest.transferOwnershipRequest
        ?.transferOwnershipInformation.status === TransferOwnershipRequestStatus.Pending
        ? renderTransferStatus()
        : renderTransferOwnership()}

      <Modal
        isVisible={statusModalVisible && !startTransferLoading}
        hide={() => {
          setStatusModalVisible(false);
        }}
        title={t('account-settings.user-permissions.transfer-ownership')}
        primaryCta={{
          label: t('general.close'),
          action: () => {
            setStatusModalVisible(false);

            reset();
          },
          buttonType: 'button',
        }}
      >
        {renderTransferStatus()}
      </Modal>

      <Modal
        isVisible={cancelModalVisible}
        hide={() => {
          setCancelModalVisible(false);
        }}
        title={t('account-settings.user-permissions.cancel-transfer-ownership')}
        primaryCta={{
          label: t('account-settings.user-permissions.cancel-transfer-ownership'),
          action: () => {
            onCancelTransferOwnership();
          },
          buttonType: 'submit',
          buttonFormId: transferOwnershipFormId.current,
          disabled: updateTransferLoading,
          buttonIcon: updateTransferLoading ? SpinnerIcon : undefined,
          buttonIconClassName: cx({
            [styles['user-permissions__spinner']]: updateTransferLoading,
          }),
          buttonIconPosition: IconPosition.LEFT,
        }}
        secondaryCta={{
          label: t('general.close'),
          disabled: updateTransferLoading,
          action: () => {
            reset();
            setCancelModalVisible(false);
          },
        }}
      >
        <div className={cx(styles['user-permissions__text-wrapper'])}>
          <h3 className={cx(styles['user-permissions__sub-heading'])}>
            {t('account-settings.user-permissions.cancel-transfer-modal-subtitle')}
          </h3>
          <p className={cx(styles['user-permissions__subtitle'])}>
            {t('account-settings.user-permissions.cancel-transfer-modal-description')}
          </p>
        </div>
      </Modal>
    </div>
  );
};

TransferOwnership.displayName = 'TransferOwnership';

export default TransferOwnership;
