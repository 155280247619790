import cx from 'classnames';
import { FC } from 'react';
import styles from './ContactUsSkeleton.module.scss';

const ContactUsSkeleton: FC = () => (
  <>
    <div className={cx(styles['contact-us-skeleton'])}>
      <div className={cx(styles['contact-us-skeleton__user'])}>
        <div className={cx(styles['contact-us-skeleton__profile-picture'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-titles'])}>
          <div className={cx(styles['contact-us-skeleton__user-title'])}></div>
          <div className={cx(styles['contact-us-skeleton__user-subtitle'])}></div>
        </div>
      </div>
      <hr className={cx(styles['contact-us-skeleton__support-line'])}></hr>
      <div className={cx(styles['contact-us-skeleton__user-details'])}>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
      </div>
    </div>
    <div className={cx(styles['contact-us-skeleton'])}>
      <div className={cx(styles['contact-us-skeleton__user'])}>
        <div className={cx(styles['contact-us-skeleton__profile-picture'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-titles'])}>
          <div className={cx(styles['contact-us-skeleton__user-title'])}></div>
          <div className={cx(styles['contact-us-skeleton__user-subtitle'])}></div>
        </div>
      </div>
      <hr className={cx(styles['contact-us-skeleton__support-line'])}></hr>
      <div className={cx(styles['contact-us-skeleton__user-details'])}>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
      </div>
    </div>
    <div className={cx(styles['contact-us-skeleton'])}>
      <div className={cx(styles['contact-us-skeleton__user'])}>
        <div className={cx(styles['contact-us-skeleton__profile-picture'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-titles'])}>
          <div className={cx(styles['contact-us-skeleton__user-title'])}></div>
          <div className={cx(styles['contact-us-skeleton__user-subtitle'])}></div>
        </div>
      </div>
      <hr className={cx(styles['contact-us-skeleton__support-line'])}></hr>
      <div className={cx(styles['contact-us-skeleton__user-details'])}>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
        <div className={cx(styles['contact-us-skeleton__user-detail'])}></div>
      </div>
    </div>
  </>
);

ContactUsSkeleton.displayName = 'ContactUsSkeleton';

export default ContactUsSkeleton;
