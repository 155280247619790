import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getProductSizeOptions,
  getQuantityCopy,
  getSeedTreatmentOptions,
} from '../../../../../lib/utils/pre-order';
import { PreOrderDetailsProduct, PreOrderDetailsRequest } from '../../../../../lib/types/pre-order';
import { Product, Retailer } from '../../../../../__generated__/graphql';

import AddRetailerCard from '../../../../../components/PreferredRetailer/AddRetailerCard';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../../lib/constants/react-select';
import { formatRetailerName } from '../../../../../lib/utils/retailers';
import { InfoOutlineIcon } from '../../../../../assets/icons';
import Pagination from '../../../../../components/_shared/Pagination';
import { ProductCategory } from '../../../../../lib/models/Product.model';
import { RETAILERS_PER_PAGE_ORDERS } from '../../../../../lib/constants/retailers';
import styles from '../PreOrderModal.module.scss';
import TextField from '../../../../../components/_shared/TextField/TextField';
import usePaginationContext from '../../../../../lib/contexts/pagination/usePaginationContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  handleSubmit: () => void;
  formID: MutableRefObject<string>;
  products: Product[];
  productDetails: PreOrderDetailsProduct;
  retailers: Retailer[];
  preferredRetailers: Retailer[];
  control: Control<PreOrderDetailsRequest, object>;
  register: UseFormRegister<PreOrderDetailsRequest>;
  errors: FieldErrors<PreOrderDetailsRequest>;
  watch: UseFormWatch<PreOrderDetailsRequest>;
  selectedRetailer: Retailer | undefined;
  setSelectedRetailer: Dispatch<SetStateAction<Retailer | undefined>>;
  setValue: UseFormSetValue<PreOrderDetailsRequest>;
  totalRetailers: number;
}

// Decides which fields should render according to product category
const FieldsToDisplay = {
  quantity: [ProductCategory.CANOLA_HYBRID, ProductCategory.SOYBEAN, ProductCategory.CORN],
  productSize: [ProductCategory.CANOLA_HYBRID],
  seedTreatment: [ProductCategory.CANOLA_HYBRID],
};

const PreOrderDetailsForm: FC<Props> = ({
  handleSubmit,
  formID,
  products,
  productDetails,
  retailers,
  control,
  register,
  errors,
  watch,
  selectedRetailer,
  setSelectedRetailer,
  setValue,
  preferredRetailers,
  totalRetailers,
}) => {
  const { t } = useTranslation();
  const quantityCopy = getQuantityCopy(productDetails.cropType);
  const { retailerId, seedTreatmentId, quantity, productSku } = watch();
  const seedTreatmentOptions = useRef<DropdownOption[]>(getSeedTreatmentOptions(products));
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [, setPageNum] = usePaginationContext();

  const sizeProductOptions: DropdownOption[] = useMemo(
    () => getProductSizeOptions(products, seedTreatmentId),
    [products, seedTreatmentId]
  );

  useEffect(() => {
    if (seedTreatmentId && selectedProduct) {
      setValue('productSku', '');
      setSelectedProduct(undefined);
    }
  }, [seedTreatmentId]);

  useEffect(() => {
    if (productSku) {
      setSelectedProduct(products.find((product) => product.sku === productSku));
    }
  }, [productSku]);

  return (
    <>
      <p className={cx(styles['pre-order-details-modal__subheading'])}>
        {t('order-placement.pre-order-modal.subheading', { productName: productDetails.name })}
      </p>
      <form id={formID.current} onSubmit={handleSubmit}>
        <div className={cx(styles['pre-order-details-modal__form-inner'])}>
          {FieldsToDisplay['quantity'].includes(productDetails.cropType) && (
            <>
              <p className={cx(styles['pre-order-details-modal__field-label'])}>{quantityCopy}</p>
              <TextField
                {...register('quantity', {
                  required: t('form.errors.invalid-field', {
                    fieldName: quantityCopy.toLowerCase(),
                  }),
                })}
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.quantity,
                })}
                label={quantityCopy}
                hasError={!!errors?.quantity}
                helperText={errors.quantity?.message}
                inputType="number"
                min={1}
                hideLabel
              />
            </>
          )}

          {FieldsToDisplay['seedTreatment'].includes(productDetails.cropType) && (
            <>
              <p className={cx(styles['pre-order-details-modal__field-label'])}>
                {t('form.labels.seed-treatment')}
              </p>
              <Controller
                control={control}
                name="seedTreatmentId"
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    name={name}
                    className={cx(styles['form-input'], {
                      [styles['form-input--error']]: !!errors.seedTreatmentId,
                    })}
                    value={seedTreatmentOptions.current.find(
                      (option) => option.value === (value && value.toString())
                    )}
                    label={t('form.labels.seed-treatment')}
                    options={seedTreatmentOptions.current}
                    onChange={onChange}
                    searchable={false}
                    hasError={!!errors?.seedTreatmentId}
                    helperText={errors.seedTreatmentId?.message}
                    hideLabel
                  />
                )}
              />
            </>
          )}

          {FieldsToDisplay['productSize'].includes(productDetails.cropType) && (
            <>
              <p className={cx(styles['pre-order-details-modal__field-label'])}>
                {t('form.labels.product-size')}
              </p>
              <Controller
                control={control}
                name="productSku"
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    name={name}
                    className={cx(styles['form-input'], {
                      [styles['form-input--error']]: !!errors.productSku,
                    })}
                    value={sizeProductOptions.find(
                      (option) => option.value === (value && value.toString())
                    )}
                    isDisabled={!seedTreatmentId}
                    label={t('form.labels.product-size')}
                    options={sizeProductOptions}
                    onChange={onChange}
                    searchable={false}
                    hasError={!!errors?.productSku}
                    helperText={errors.productSku?.message}
                    hideLabel
                  />
                )}
              />
              {selectedProduct?.acresPerPackage && (
                <>
                  <div className={cx(styles['acreage__space'])}></div>
                  <div className={cx(styles['acreage'])}>
                    <div className={cx(styles['acreage__icon-wrapper'])}>
                      <InfoOutlineIcon className={cx(styles['acreage__icon'])} />
                    </div>
                    <div>
                      <p className={cx(styles['acreage__heading'])}>
                        {t('order-placement.pre-order-modal.total-acre-quantity', {
                          acres: selectedProduct.acresPerPackage * (parseInt(quantity, 10) || 0),
                        })}
                      </p>
                      <p className={cx(styles['acreage__description'])}>
                        {t('order-placement.pre-order-modal.acres-description')}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <hr
          className={cx(
            styles['pre-order-details-modal__hr'],
            styles['pre-order-details-modal__hr--show-mobile']
          )}
        />
        <div>
          <p className={cx(styles['pre-order-details-modal__field-label'])}>
            {t('form.labels.retailer-options')}
          </p>
          {selectedRetailer && (
            <p className={cx(styles['pre-order-details-modal__selected-retailer'])}>
              {t('order-placement.pre-order-modal.selected-retailer', {
                retailerName: formatRetailerName(t, selectedRetailer),
              })}
            </p>
          )}

          <ul className={cx(styles['pre-order-details-modal__list'])}>
            {retailers.map((retailer) => {
              const elementId = `retailer-option-${retailer.id}`;

              return (
                <li className={cx(styles['pre-order-details-modal__list-item'])} key={retailer.id}>
                  <label
                    className={cx(styles['pre-order-details-modal__label'])}
                    htmlFor={elementId}
                  >
                    <input
                      {...register('retailerId')}
                      className={cx(styles['pre-order-details-modal__checkbox'], 'sr-only')}
                      type="radio"
                      id={elementId}
                      value={retailer.id}
                      onClick={() => setSelectedRetailer(retailer)}
                    />
                    <AddRetailerCard
                      retailer={retailer}
                      isSelected={retailerId === retailer.id}
                      isCompact
                      isPreferred={!!preferredRetailers.filter((r) => r.id === retailer.id).length}
                    />
                  </label>
                </li>
              );
            })}
          </ul>

          <div className={cx(styles['pre-order-details-modal__pagination'])}>
            <Pagination
              itemsPerPage={RETAILERS_PER_PAGE_ORDERS}
              totalNumberItems={totalRetailers}
              onPageChange={(pageNum) => {
                setPageNum(pageNum);
              }}
            />
          </div>

          {errors?.retailerId?.message && (
            <span className={cx(styles['helper-text'], styles['helper-text--errors'])}>
              {errors?.retailerId?.message}
            </span>
          )}
        </div>
      </form>
    </>
  );
};

PreOrderDetailsForm.displayName = 'PreOrderDetailsForm';

export default PreOrderDetailsForm;
