import DashboardContext from './DashboardContext';
import { DashboardContextType } from './DashboardContext.types';
import { useContext } from 'react';

const useDashboardContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }

  return context;
};

export default useDashboardContext;
