export enum Axis {
  Y_AXIS = 'y',
  X_AXIS = 'x',
}

export interface LinearChartLegendPayload {
  value: string;
  color: string;
}

export interface LinearChartTooltipPayload {
  value: number;
  color: string;
  name: string;
}

export interface LinearChartDataset {
  year: number;
  [key: string]: number;
}

export interface LinearChartLine {
  color: string;
  label: string;
  key: string;
}

export interface LinearChartData {
  dataset: LinearChartDataset[];
  lines: LinearChartLine[];
}
