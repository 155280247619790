import { ArrowDownwardIcon, CartEmptyIcon } from '../../../assets/icons';
import { FC, memo, useEffect, useState } from 'react';
import {
  OrderDirection,
  PreOrderProduct,
  PurchasePreOrderSortField,
  PurchaseProduct,
} from '../../../__generated__/graphql';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';

import dayjs from '../../../lib/utils/dates';
import { GET_PARTNERS_NAME } from '../../../lib/graphql/PartnerPermissions.gql';
import Link from '../../_shared/Link/Link';
import { LinkUrls } from '../../../lib/constants/react-router';
import { OrderHistoryRowType } from '../../../lib/constants/purchases';
import { slashSeparatedDate } from '../../../lib/constants/date-formats';
import styles from './RecentPurchasesTable.module.scss';
import TableEmptyState from '../../TableEmptyState';
import TableRow from './TableRow/TableRow';
import TableSkeleton from './TableSkeleton/TableSkeleton';
import { useQuery } from '@apollo/client';

const SKELETON_CELLS = 7;
const EMPTY_STATE_COLSPAN = 10;

export interface Props {
  type?: OrderHistoryRowType;
  isLoading?: boolean;
  loadingRowsNumber?: number;
  orderField: PurchasePreOrderSortField;
  setOrderField(sort: PurchasePreOrderSortField): void;
  orderDirection: OrderDirection;
  recentPurchases: PurchaseProduct[] | PreOrderProduct[];
  setOrderDirection(order: OrderDirection): void;
  showBreakdownCta?: boolean;
}

const RecentPurchasesTable: FC<Props> = ({
  type = OrderHistoryRowType.PURCHASE,
  isLoading = false,
  loadingRowsNumber = 5,
  orderField,
  setOrderField,
  orderDirection,
  recentPurchases = [],
  setOrderDirection,
  showBreakdownCta = false,
}) => {
  const { t } = useTranslation();
  const { data: partnerData, loading: partnerInfoLoading } = useQuery(GET_PARTNERS_NAME, {
    skip: type === OrderHistoryRowType.PRE_ORDER,
  });
  const [parentFarm, setParentFarm] = useState<string>();

  useEffect(() => {
    if (type === OrderHistoryRowType.PURCHASE) {
      let parentFarmName;

      partnerData?.farm.partners.forEach((partner) => {
        if (partner.isParent) {
          parentFarmName = partner.partner.farmInfo.name;
        }
      });

      if (!parentFarmName) {
        parentFarmName = partnerData?.farm.farmInfo.name;
      }

      setParentFarm(parentFarmName);
    }
  }, [partnerData, type]);

  const handleSortBy = (sortBy: PurchasePreOrderSortField) => {
    if (sortBy === orderField) {
      if (orderDirection === OrderDirection.Asc) {
        setOrderDirection(OrderDirection.Desc);
      } else {
        setOrderDirection(OrderDirection.Asc);
      }
    } else if (orderDirection === OrderDirection.Asc) {
      setOrderField(sortBy);
      setOrderDirection(OrderDirection.Desc);
    } else {
      setOrderField(sortBy);
    }
  };

  const renderTableBody = () => {
    if (isLoading || partnerInfoLoading) {
      return <TableSkeleton numberOfRows={loadingRowsNumber} numberOfCells={SKELETON_CELLS} />;
    } else if (recentPurchases.length === 0) {
      return (
        <tr>
          <td colSpan={EMPTY_STATE_COLSPAN} aria-label={t(`purchases.table-empty.title-${type}`)}>
            <TableEmptyState
              Icon={CartEmptyIcon}
              title={t(`purchases.table-empty.title-${type}`)}
              description={
                <Trans
                  i18nKey={`purchases.table-empty.description-${type}`}
                  values={{
                    date: dayjs().format(slashSeparatedDate),
                  }}
                  components={[<br />, <Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />]}
                />
              }
            />
          </td>
        </tr>
      );
    }

    if (type === OrderHistoryRowType.PURCHASE) {
      return recentPurchases.map((purchase, index) => (
        <TableRow
          key={purchase.id}
          item={purchase}
          className={cx({ [styles['table-row--with-bg']]: index % 2 === 0 })}
          showBreakdownCta={showBreakdownCta}
          hasPartners={!!partnerData?.farm.partners.length}
          parentFarmName={parentFarm}
        />
      ));
    }

    return recentPurchases.map((purchase, index) => (
      <TableRow
        key={purchase.id}
        item={purchase}
        className={cx({ [styles['table-row--with-bg']]: index % 2 === 0 })}
        showBreakdownCta={showBreakdownCta}
      />
    ));
  };

  return (
    <table
      className={cx(styles['recent-purchases-table'], {
        orders: type === OrderHistoryRowType.PRE_ORDER,
      })}
    >
      <thead className={cx(styles['table-header'])}>
        <tr>
          <th className={cx(styles['table-header__cell'], styles['table-header__cell--accordion'])}>
            <span className="sr-only">{t('purchases.table-headings.expand-collapse')}</span>
          </th>
          <th className={cx(styles['table-header__cell'], styles['table-header__cell--product'])}>
            <div className={cx(styles['table-header__cell-wrap'])}>
              {t('purchases.table-headings.product')}
              <button
                type="button"
                onClick={() => handleSortBy(PurchasePreOrderSortField.ProductName)}
                className={cx(styles['table-header__button'])}
                aria-label={t(
                  `purchases.table-sort.${
                    orderField === PurchasePreOrderSortField.ProductName
                      ? orderDirection
                      : OrderDirection.Desc
                  }`
                )}
              >
                <ArrowDownwardIcon
                  className={cx(styles['table-header__icon'], {
                    [styles['table-header__icon--ascending']]:
                      orderField === PurchasePreOrderSortField.ProductName &&
                      orderDirection === OrderDirection.Asc,
                  })}
                  aria-hidden="true"
                />
              </button>
            </div>
          </th>
          <th
            className={cx(
              styles['table-header__cell'],
              styles['table-header__cell--quantity'],
              styles['table-header__cell--text-right']
            )}
          >
            {t('purchases.table-headings.quantity')}
          </th>
          <th
            className={cx(
              styles['table-header__cell'],
              styles['table-header__cell--date'],
              styles['table-header__cell--text-center']
            )}
          >
            <div
              className={cx(
                styles['table-header__cell-wrap'],
                styles['table-header__cell-wrap--center']
              )}
            >
              {t('purchases.table-headings.date')}
              <button
                type="button"
                onClick={() => handleSortBy(PurchasePreOrderSortField.Date)}
                className={cx(styles['table-header__button'])}
                aria-label={t(
                  `purchases.table-sort.${
                    orderField === PurchasePreOrderSortField.Date
                      ? orderDirection
                      : OrderDirection.Desc
                  }`
                )}
              >
                <ArrowDownwardIcon
                  className={cx(styles['table-header__icon'], {
                    [styles['table-header__icon--ascending']]:
                      orderField === PurchasePreOrderSortField.Date &&
                      orderDirection === OrderDirection.Asc,
                  })}
                  aria-hidden="true"
                />
              </button>
            </div>
          </th>
          {type === OrderHistoryRowType.PURCHASE && (
            <th
              className={cx(styles['table-header__cell'], styles['table-header__cell--program'])}
              aria-label={t('purchases.table-headings.reward-program')}
            >
              <Trans i18nKey="purchases.table-headings.reward-program" components={[<br />]} />
            </th>
          )}
          <th className={cx(styles['table-header__cell'], styles['table-header__cell--retailer'])}>
            <div className={cx(styles['table-header__cell-wrap'])}>
              {t('purchases.table-headings.retailer')}
              <button
                type="button"
                onClick={() => handleSortBy(PurchasePreOrderSortField.RetailerName)}
                className={cx(styles['table-header__button'])}
                aria-label={t(
                  `purchases.table-sort.${
                    orderField === PurchasePreOrderSortField.RetailerName
                      ? orderDirection
                      : OrderDirection.Desc
                  }`
                )}
              >
                <ArrowDownwardIcon
                  className={cx(styles['table-header__icon'], {
                    [styles['table-header__icon--ascending']]:
                      orderField === PurchasePreOrderSortField.RetailerName &&
                      orderDirection === OrderDirection.Asc,
                  })}
                  aria-hidden="true"
                />
              </button>
            </div>
          </th>
          {type === OrderHistoryRowType.PURCHASE && (
            <th
              className={cx(styles['table-header__cell'], styles['table-header__cell--purchaser'])}
            >
              {t('purchases.table-headings.purchaser')}
            </th>
          )}
        </tr>
      </thead>
      <tbody className={cx(styles['table-row'])}>{renderTableBody()}</tbody>
    </table>
  );
};

RecentPurchasesTable.displayName = 'RecentPurchasesTable';

export default memo(RecentPurchasesTable);
