import PaginationContext from './PaginationContext';
import { PaginationContextType } from './PaginationContext.types';
import { useContext } from 'react';

const usePaginationContext = (): PaginationContextType => {
  const context = useContext(PaginationContext);

  if (context === undefined) {
    throw new Error('usePaginationContext must be used within a PaginationProvider');
  }

  return context;
};

export default usePaginationContext;
