import { Address, Province } from '../../__generated__/graphql';
import {
  AddressComponent,
  AddressLine2List,
  AddressLineList,
  AddressTypeList,
  administrativeAreaLevel,
  country,
  defaultAddress,
  qcShortName,
} from '../constants/google-maps';

export const buildAddress = (placeResult: google.maps.places.PlaceResult): Address => {
  const address: Address = defaultAddress;
  const addressLine: string[] = [];
  const addressLine2: string[] = [];
  const addressComponents: string[] = [country];

  address.placeId = placeResult.place_id;

  /**
   * Includes the name of the place in the Address 2 line if it is
   * a landmark, place of interest, or establishment.
   */
  if (
    placeResult.name &&
    AddressTypeList.some((addressType) => placeResult.types?.includes(addressType))
  ) {
    addressLine2.push(placeResult.name);
  }

  /**
   * Loops through the address components obtained from the Google Maps API
   * and assigns their respective values to Province, City, and Postal Code.
   * For address lines, it populates Address with Street number and Route,
   * and Address 2 with Floor and Post Box.
   */
  placeResult.address_components?.forEach((component) => {
    const { types } = component;
    if (types.includes(AddressComponent.PROVINCE)) {
      address.province = component.short_name as Province;
      addressComponents.push(component.short_name);
    } else if (types.includes(AddressComponent.CITY)) {
      address.city = component.long_name;
      addressComponents.push(component.long_name);
    } else if (types.includes(AddressComponent.POSTAL_CODE)) {
      address.postalCode = component.long_name;
      addressComponents.push(component.long_name);
    } else if (AddressLineList.some((comp) => types.includes(comp))) {
      addressLine.push(component.long_name);
    } else if (AddressLine2List.some((comp) => types.includes(comp))) {
      addressLine2.push(component.long_name);
    }
  });

  /**
   * If the Places API provides a formatted address, it will be used.
   * This address will exclude components already used in other fields
   * (country, postal code, province, and city). If a formatted address
   * is not provided, the previously extracted address components will be used.
   */
  if (placeResult.formatted_address) {
    const formatedAddress = placeResult.formatted_address.split(', ');
    if (address.postalCode && address.province) {
      addressComponents.push(`${address.province} ${address.postalCode}`);
    }
    const removeComponents = formatedAddress.filter(
      (component) => !addressComponents.includes(component)
    );
    address.address = removeComponents.join(', ');
  } else {
    address.address = addressLine.join(', ');
  }

  if (addressLine2.length) {
    address.address2 = addressLine2.join(', ');
  } else {
    address.address2 = '';
  }

  return address;
};

/**
 * Gets coordinates for the current user
 * @returns
 */
const getCoordinates = async () => {
  try {
    const position: GeolocationPosition = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
      error: false,
    };
  } catch (exception) {
    console.error(exception);
    return { error: true };
  }
};

/**
 * Checks if the user is in QC
 * @returns
 */
export const checkUserIsInQc = async (): Promise<boolean> => {
  const location = await getCoordinates();
  const apiKey = process.env.REACT_APP_MAPS_API_KEY;
  const geocoderUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=${apiKey}`;

  if (location.error) {
    return false;
  }

  const results = fetch(geocoderUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        const addressComponents = data.results[0].address_components;
        let isInQc = false;
        addressComponents.forEach((component: { types: string | string[]; short_name: string }) => {
          if (
            component.types.includes(administrativeAreaLevel) &&
            component.short_name === qcShortName
          ) {
            isInQc = true;
          }
        });
        return isInQc;
      }
      console.error('Geocoding API error:', data.error);
      return false;
    })
    .catch((error) => {
      console.error('Error fetching location data:', error);
      return false;
    });

  return results;
};
