import { ButtonThemes, DisplayTheme } from '../../lib/constants/components';

import { FC, SVGProps } from 'react';
import { ActionCta } from '../../lib/types/button';
import BannerBadge from '../_shared/BannerBadge';
import Button from '../_shared/Button';
import cx from 'classnames';
import { StarCircleIcon } from '../../assets/icons';
import styles from './PromotionBanner.module.scss';

export interface Props {
  image: string;
  imageClass?: string;
  title: string;
  description: string;
  theme: DisplayTheme;
  badge?: string;
  badgeIcon?: FC<SVGProps<SVGSVGElement>>;
  cta?: ActionCta | null;
}

const PromotionBanner: FC<Props> = ({
  image,
  imageClass,
  title,
  description,
  cta,
  theme,
  badge,
  badgeIcon = StarCircleIcon,
}) => (
  <div
    className={cx(styles['promotion-banner'], {
      [styles['promotion-banner--widget']]: theme === DisplayTheme.WIDGET,
      [styles['promotion-banner--banner']]: theme === DisplayTheme.BANNER,
    })}
  >
    <img
      className={cx(
        styles['promotion-banner__image'],
        {
          [styles['promotion-banner__image--banner']]: theme === DisplayTheme.BANNER,
        },
        imageClass
      )}
      src={image}
      alt=""
      aria-hidden
    />
    <div className={cx(styles['promotion-banner__container'])}>
      {badge && <BannerBadge title={badge} Icon={badgeIcon} />}
      <h1 className={cx(styles['promotion-banner__title'])}>{title}</h1>
      <p className={cx(styles['promotion-banner__description'])}> {description}</p>
      {cta && (
        <Button
          id={cta.id}
          type={cta.buttonType || 'button'}
          onClick={cta.action}
          theme={cta.buttonTheme || ButtonThemes.PRIMARY}
          className={cx(styles['promotion-banner__button'])}
          disabled={cta.disabled}
          icon={cta.buttonIcon}
          iconPosition={cta.buttonIconPosition}
          iconClassName={cta.buttonIconClassName}
        >
          {cta.label}
        </Button>
      )}
    </div>
  </div>
);

PromotionBanner.displayName = 'PromotionBanner';

export default PromotionBanner;
