import { DeactivateUserInput, UserAccountInfo } from '../../../../../__generated__/graphql';
import { GarbageCanIcon, SpinnerIcon } from '../../../../../assets/icons';

import cx from 'classnames';
import { DEACTIVATE_ACCOUNT } from './DeactivateAccount.queries.gql';
import { FC } from 'react';
import { IconPosition } from '../../../../../lib/constants/components';
import { LogoutUser } from '../../../../../lib/clients/gigya-client';
import Modal from '../../../../../components/_shared/Modal';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './DeactivateAccountModal.module.scss';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  hide(): void;
  user: Partial<UserAccountInfo> | undefined;
}

const DeactivateAccountModal: FC<Props> = ({ open, hide, user }) => {
  const { t } = useTranslation();
  const [deactivateUser, { loading: loadingDeactivate }] = useMutation(DEACTIVATE_ACCOUNT);
  const [, dispatchSnackbar] = useSnackbarContext();

  const handleDeactivatedUser = () => {
    if (user?.id && user?.gigyaId) {
      const input: DeactivateUserInput = {
        userId: user?.id,
        gigyaId: user?.gigyaId,
      };
      deactivateUser({ variables: { input } })
        .then(() => {
          LogoutUser();
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          hide();
        });
    }
  };

  if (user?.isAdmin) {
    return (
      <Modal
        classNames={{ modalWrapper: cx(styles['dam']) }}
        isVisible={open}
        hide={hide}
        title={t('account-settings.profile.deactivate-account.heading')}
        primaryCta={{
          label: t('general.close'),
          action: hide,
        }}
      >
        <p className={cx(styles['dam__title'])}>
          {t('account-settings.profile.deactivate-account.transfer-ownership')}
        </p>
        <p className={cx(styles['dam__text-body'])}>
          {t('account-settings.profile.deactivate-account.transfer-ownership-description')}
        </p>
      </Modal>
    );
  }

  return (
    <Modal
      classNames={{ modalWrapper: cx(styles['dam']) }}
      isVisible={open}
      hide={hide}
      title={t('account-settings.profile.deactivate-account.heading')}
      primaryCta={{
        label: t('account-settings.profile.deactivate-account.primary-cta'),
        action: handleDeactivatedUser,
        buttonIcon: loadingDeactivate ? SpinnerIcon : GarbageCanIcon,
        buttonIconPosition: IconPosition.LEFT,
        buttonIconClassName: cx({ [styles['dam__button--loading']]: loadingDeactivate }),
        disabled: loadingDeactivate,
      }}
      secondaryCta={{
        disabled: loadingDeactivate,
        label: t('general.cancel'),
        action: hide,
      }}
    >
      <p className={cx(styles['dam__title'])}>
        {t('account-settings.profile.deactivate-account.confirmation')}
      </p>
      <p className={cx(styles['dam__text-body'])}>
        {t('account-settings.profile.deactivate-account.confirmation-description')}
      </p>
    </Modal>
  );
};

DeactivateAccountModal.displayName = 'DeactivateAccountModal';

export default DeactivateAccountModal;
