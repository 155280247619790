import 'react-day-picker/dist/style.css';
import '../DateRange/day-picker.scss';

import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { DateRangeFiltersForm, YEAR_END, YEAR_START } from '../../../../lib/constants/forms';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { enUS, frCA } from 'date-fns/locale';

import { CalendarTodayIcon } from '../../../../assets/icons';
import { CustomCaption } from '../DateRange/CustomCaption/CustomCaption';
import cx from 'classnames';
import dayjs from '../../../../lib/utils/dates';
import { DayPicker } from 'react-day-picker';
import { fullWeekday } from '../../../../lib/constants/date-formats';
import styles from './DateRangeFilterMobileView.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import { useTranslation } from 'react-i18next';

export interface Props {
  formControl: Control<DateRangeFiltersForm, object>;
  watch: UseFormWatch<DateRangeFiltersForm>;
  formId: string;
  setError: Dispatch<SetStateAction<boolean>>;
}

const DateRangeFilterModalView: FC<Props> = ({ formControl, watch, formId, setError }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const calendarLanguage = language === 'fr' ? frCA : enUS;
  const { isXs, isMd } = useBreakpoint();
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);

  const { startDate, endDate } = watch();

  useEffect(() => {
    setError(startDateError || endDateError);
  }, [startDateError, endDateError, setError]);

  const renderInput = (
    onClick: () => void,
    placeholder: string,
    errorState: boolean,
    date?: string
  ) => {
    const content = (
      <>
        <CalendarTodayIcon
          className={cx(styles['date-range-mobile__calendar-icon'], {
            [styles['date-range-mobile__calendar-icon--error']]: errorState,
          })}
          aria-hidden="true"
        />
        <p
          className={cx(styles['date-range-mobile__date'], {
            [styles['date-range-mobile__date--error']]: errorState,
          })}
        >
          {dayjs(date).locale(language).format(fullWeekday) ||
            t('filter.calendar.end-date-placeholder') ||
            placeholder}
        </p>
      </>
    );

    if (isXs) {
      return (
        <button
          className={cx(styles['date-range-mobile__input-wrapper'], {
            [styles['date-range-mobile__input-wrapper--error']]: errorState,
          })}
          type="button"
          onClick={() => onClick()}
        >
          {content}
        </button>
      );
    }

    return (
      <div
        className={cx(styles['date-range-mobile__input-wrapper'], {
          [styles['date-range-mobile__input-wrapper--error']]: errorState,
        })}
      >
        {content}
      </div>
    );
  };

  const onStartDateChange = (selectedStartDate: string) => {
    if (endDate && dayjs(selectedStartDate).isAfter(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const onEndDateChange = (selectedEndDate: string) => {
    if (startDate && dayjs(selectedEndDate).isBefore(startDate)) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
      setStartDateError(false);
    }
  };

  const onClickStartCalendar = () => {
    setShowStartCalendar(!showStartCalendar);
    if (showEndCalendar) {
      setShowEndCalendar(!showEndCalendar);
    }
  };

  const onClickEndCalendar = () => {
    setShowEndCalendar(!showEndCalendar);
    if (showStartCalendar) {
      setShowStartCalendar(!showStartCalendar);
    }
  };

  return (
    <form className={cx(styles['date-range-mobile'])} id={formId}>
      <p className={cx(styles['date-range-mobile__subtitle'])}>{t('filter.calendar.subtitle')}</p>
      <div className={cx(styles['date-range-mobile__calendar-section-wrapper'])}>
        <div className={cx(styles['date-range-mobile__calendar-wrapper'])}>
          {renderInput(
            onClickStartCalendar,
            isXs
              ? t('filter.calendar.add-start-date-placeholder')
              : t('filter.calendar.start-date-placeholder'),
            startDateError,
            startDate
          )}
          {(showStartCalendar || !isXs) && (
            <Controller
              control={formControl}
              name="startDate"
              render={({ field: { onChange } }) => (
                <DayPicker
                  mode="single"
                  selected={new Date(startDate || '')}
                  onSelect={(e: any) => {
                    onStartDateChange(e);
                    onChange(e);
                  }}
                  fromYear={YEAR_START}
                  toYear={YEAR_END}
                  locale={calendarLanguage}
                  components={{
                    Caption: CustomCaption,
                  }}
                />
              )}
            />
          )}
          {startDateError && isXs && (
            <p className={cx(styles['date-range-mobile__error'])}>
              {t('filter.calendar.error-message')}
            </p>
          )}
        </div>
        {isMd && <p className={cx(styles['date-range-mobile__dash'])}>-</p>}
        <div className={cx(styles['date-range-mobile__calendar-wrapper'])}>
          {renderInput(
            onClickEndCalendar,
            isXs
              ? t('filter.calendar.add-end-date-placeholder')
              : t('filter.calendar.end-date-placeholder'),
            endDateError,
            endDate
          )}
          {(showEndCalendar || !isXs) && (
            <Controller
              control={formControl}
              name="endDate"
              render={({ field: { onChange } }) => (
                <DayPicker
                  mode="single"
                  selected={new Date(endDate || '')}
                  onSelect={(e: any) => {
                    onEndDateChange(e);
                    onChange(e);
                  }}
                  fromYear={YEAR_START}
                  toYear={YEAR_END}
                  locale={calendarLanguage}
                  components={{
                    Caption: CustomCaption,
                  }}
                />
              )}
            />
          )}
          {endDateError && isXs && (
            <p className={cx(styles['date-range-mobile__error'])}>
              {t('filter.calendar.error-message')}
            </p>
          )}
        </div>
      </div>
      {(startDateError || endDateError) && !isXs && (
        <p className={cx(styles['date-range-mobile__error'])}>
          {t('filter.calendar.error-message')}
        </p>
      )}
    </form>
  );
};

export default DateRangeFilterModalView;
