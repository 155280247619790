import { FAQ, FAQLink } from '../../../lib/models/FAQ';
import { Trans, useTranslation } from 'react-i18next';

import Accordion from '../../../components/_shared/Accordion';
import cx from 'classnames';
import { FC } from 'react';
import { GET_FARM_PROVINCE } from '../../../lib/graphql/FarmInformation.gql';
import { getRegionSpecificLink } from '../../../lib/utils/utils';
import Link from '../../../components/_shared/Link';
import styles from './QuestionsTable.module.scss';
import { useQuery } from '@apollo/client';

interface Props {
  questions: FAQ[];
  title: string;
}

const QuestionsTable: FC<Props> = ({ questions, title }) => {
  const { t } = useTranslation();
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE, {
    skip: questions.every(
      (question) =>
        !question?.links?.length || question.links.every((link) => typeof link.url === 'string')
    ),
  });

  const getLinkUrl = (link: FAQLink): string => {
    if (typeof link.url === 'string') {
      return link.url;
    }

    return getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, link.url);
  };

  return (
    <section className={cx(styles['questions-table'])}>
      <h2 className={cx(styles['questions-table__heading'])}>{t(title)}</h2>
      {questions.map((question) => (
        <Accordion key={question.title} title={t(question.title)}>
          {question.links ? (
            <p className={cx(styles['questions-table__content'])}>
              <Trans
                i18nKey={question.answer}
                values={question.links}
                components={question.links.map((link) => (
                  <Link href={getLinkUrl(link)} target={link.target} />
                ))}
              />
            </p>
          ) : (
            <p className={cx(styles['questions-table__content'])}>{t(question.answer)}</p>
          )}
        </Accordion>
      ))}
    </section>
  );
};

QuestionsTable.displayName = 'QuestionsTable';

export default QuestionsTable;
