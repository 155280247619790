import { ComponentType, ReactElement } from 'react';
import { CSSObjectWithLabel } from 'react-select';

export type DropdownOption = {
  value: string | number;
  label: string;
  labelKey?: string;
  description?: string | ReactElement;
  disabled?: boolean;
  iconLeft?: JSX.Element;
  labelToDisplay?: string;
};

export type OptionsFooter = {
  element: ComponentType<{ className: string; onSelect?: OptionsFooter['onSelect'] }>;
  onSelect: () => void;
};

export const DropdownColors = {
  disabled: '#9E9E9E',
  disabledStroke: '#e0e0e0',
  active: '#10384F',
  secondary: '#00bcff',
  errors: '#B85302',
  inactive: '#587484',
  inactiveLow: '#a4b3bc',
  transparent: 'transparent',
  variant: '#F5F5F5',
  white: '#ffffff',
};

export const DropdownDefaultStyles = {
  clearIndicator: (): CSSObjectWithLabel => ({
    height: '1.5rem',
    margin: '0 1.5rem 0 0',
    padding: 0,
    zIndex: 2,
  }),
  container: (): CSSObjectWithLabel => ({}),
  control: (currentState: any): CSSObjectWithLabel => {
    let borderColor = DropdownColors['inactiveLow'];
    if (currentState.isFocused) {
      borderColor = DropdownColors['active'];
    }
    if (currentState.isDisabled) {
      borderColor = DropdownColors['disabledStroke'];
    }
    if (currentState?.selectProps?.hasError) {
      borderColor = DropdownColors['errors'];
    }

    return {
      position: 'relative',
      borderRadius: 0,
      padding: 0,
      backgroundColor: DropdownColors['white'],
      borderColor,
      boxShadow: currentState.isFocused ? 'none' : '',
      '&:hover': {
        borderColor: DropdownColors['active'],
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -5,
        right: -5,
        bottom: -5,
        left: -5,
        border: '4px solid transparent',
        borderColor:
          currentState.isFocused && !currentState?.selectProps?.hasError
            ? DropdownColors['secondary']
            : DropdownColors['transparent'],
      },
      height: '3.5rem',
    };
  },
  dropdownIndicator: (currentState: any): CSSObjectWithLabel => ({
    height: '1.5rem',
    margin: '0 1.5rem 0 0',
    padding: 0,
    transform: currentState?.selectProps?.menuIsOpen ? 'rotate(180deg)' : '',
    svg: {
      width: '1.5rem',
      fill: currentState?.selectProps?.hasError ? DropdownColors['errors'] : '',
    },
  }),
  indicatorsContainer: (): CSSObjectWithLabel => ({}),
  indicatorSeparator: (): CSSObjectWithLabel => ({ display: 'none' }),
  input: (): CSSObjectWithLabel => ({ overflow: 'hidden' }),
  menu: (): CSSObjectWithLabel => ({
    marginTop: 0,
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    zIndex: 10,
  }),
  menuList: (): CSSObjectWithLabel => ({
    padding: 0,
  }),
  option: (currentState: any): CSSObjectWithLabel => ({
    padding: 0,
    backgroundColor: DropdownColors['white'],
    '&:last-child': {
      marginBottom: 0,
    },
    pointerEvents: currentState.data.disabled ? 'none' : 'auto',
  }),
  placeholder: (currentState: any): CSSObjectWithLabel => {
    const isActive =
      currentState?.selectProps?.menuIsOpen ||
      currentState.hasValue ||
      currentState?.selectProps?.inputValue;
    let color = isActive ? DropdownColors['active'] : DropdownColors['inactive'];

    if (currentState.isDisabled) {
      color = currentState.hasValue ? DropdownColors['inactive'] : DropdownColors['disabled'];
    }

    // @ts-ignore
    if (currentState?.selectProps?.hasError) {
      color = DropdownColors['errors'];
    }

    return {
      color,
      position: 'absolute',
      left: '1rem',
      zIndex: 5,
      transition: 'transform 150ms ease-out, font-size 150ms ease-out',
      transform: isActive ? 'translateY(-185%)' : '',
      fontSize: isActive ? '0.75rem' : '1rem',
      backgroundColor: '#fff',
      padding: isActive ? '0 4px' : '0',
    };
  },
  singleValue: (currentState: any): CSSObjectWithLabel => {
    let color = currentState.isDisabled ? DropdownColors['disabled'] : DropdownColors['active'];
    // @ts-ignore
    if (currentState?.selectProps?.hasError) {
      color = DropdownColors['errors'];
    }

    return {
      color,
    };
  },
  valueContainer: (): CSSObjectWithLabel => ({
    padding: '0.75rem 0 0.75rem 0.875rem',
  }),
  multiValue: (currentState: any): CSSObjectWithLabel => {
    let color = currentState.isDisabled ? DropdownColors['disabled'] : DropdownColors['active'];
    // @ts-ignore
    if (currentState?.selectProps?.hasError) {
      color = DropdownColors['errors'];
    }

    return {
      color,
      borderRadius: 0,
      backgroundColor: DropdownColors['white'],
      padding: 0,
      paddingLeft: 0,
      fontSize: '1rem',
    };
  },
  multiValueRemove: (): CSSObjectWithLabel => ({
    display: 'none',
  }),
};

export const LanguageDropdownOverrides = {
  container: (): CSSObjectWithLabel => ({
    height: '100%',
    color: DropdownColors.active,
  }),
  control: (currentState: any): CSSObjectWithLabel => ({
    border: 'none',
    height: '100%',
    padding: '1rem 1rem 0.75rem',
    backgroundColor: currentState?.isFocused ? DropdownColors.variant : DropdownColors.white,
    cursor: 'pointer',
    borderBottomWidth: '0.25rem',
    borderBottomStyle: 'solid',
    borderBottomColor: DropdownColors.transparent,
    transition: 'background-color 300ms ease-in-out, border 300ms ease-in-out',
    '@media only screen and (min-width: 1440px)': {
      padding: '1rem',
    },
    ':hover': {
      backgroundColor: DropdownColors.variant,
      borderBottomColor: DropdownColors.transparent,
    },
    ':focus-within': {
      borderBottomColor: DropdownColors.active,
    },
  }),
  indicatorsContainer: (): CSSObjectWithLabel => ({
    ' div': {
      margin: '0',
    },
  }),
  option: (): CSSObjectWithLabel => ({
    padding: '1rem',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    letterSpacing: '0.25px',
    color: DropdownColors.active,
    cursor: 'pointer',
    backgroundColor: DropdownColors.white,
    transition: 'background-color 300ms ease-in-out',
    '@media only screen and (min-width: 1440px)': {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.15px',
    },
    ':hover': {
      backgroundColor: DropdownColors.variant,
    },
  }),
  singleValue: (): CSSObjectWithLabel => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    letterSpacing: '0.25px',
    color: DropdownColors.active,
    '@media only screen and (min-width: 1440px)': {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.15px',
    },
  }),
  valueContainer: (): CSSObjectWithLabel => ({
    height: '100%',
    padding: '0',
    marginRight: '0.5rem',
  }),
};
