import { FC, useState } from 'react';
import { LinkUrls, PageRoutes } from '../../../lib/constants/react-router';
import { Navigate, useLocation } from 'react-router-dom';
import { AccessLevel } from '../../../__generated__/graphql';
import { GET_FARM_INFO_NAV } from '../../../lib/graphql/Navigation.gql';
import { GET_USER_ONBOARDING_COMPLETE } from '../../../lib/graphql/UserInformation.gql';
import { getRegion } from '../../../lib/utils/utils';
import { REDIRECT_URL_SS_KEY } from '../../../lib/constants/gigya';
import { Region } from '../../../lib/constants/pre-orders';
import { useQuery } from '@apollo/client';

export interface Props {
  redirectToPage?: PageRoutes | undefined;
  children: JSX.Element;
  checkIsAdmin?: boolean;
  checkHasAccess?: boolean;
  checkRegion?: Region;
}
const ProtectedRoute: FC<Props> = ({
  children,
  checkRegion,
  redirectToPage = undefined,
  checkIsAdmin = false,
  checkHasAccess = false,
}) => {
  const [hasFetchedUser, setHasFetchedUser] = useState<Boolean>();
  const { data: userData, error: userError } = useQuery(GET_USER_ONBOARDING_COMPLETE, {
    onCompleted: () => {
      setHasFetchedUser(true);
    },
  });
  const { data: farmData, error: farmError } = useQuery(GET_FARM_INFO_NAV);
  const { pathname, search, hash } = useLocation();

  if (!hasFetchedUser && (!userError || !farmError)) {
    return null;
  } else if (userError || farmError || !userData) {
    sessionStorage.setItem(REDIRECT_URL_SS_KEY, `${pathname}${search}${hash}`);

    return (
      <Navigate
        to={redirectToPage ? `${LinkUrls.PREPEND_SLASH}${redirectToPage}` : PageRoutes.HOME}
        replace
      />
    );
  } else if (
    (checkIsAdmin && !userData?.user.accountInfo.isAdmin) ||
    (checkHasAccess && userData?.user.hasAccess === AccessLevel.NoAccess) ||
    (!!checkRegion &&
      farmData?.farm.farmInfo.address?.province &&
      getRegion(farmData?.farm.farmInfo.address?.province) !== checkRegion)
  ) {
    return <Navigate to={PageRoutes.HOME} replace />;
  } else if (
    !userData?.user.accountInfo.onboardingComplete &&
    !pathname.startsWith(`/${PageRoutes.ONBOARDING}`) &&
    !pathname.startsWith(`/${PageRoutes.SIGNUP}`)
  ) {
    return <Navigate to={`/${PageRoutes.ONBOARDING}`} replace />;
  }

  return children;
};

ProtectedRoute.displayName = 'ProtectedRoute';

export default ProtectedRoute;
