import {
  abbrevMonthOrdinalDayYearEn,
  abbrevMonthOrdinalDayYearFr,
  slashSeparatedDate,
} from '../../../lib/constants/date-formats';
import { ChevronLeftIcon, ChevronRightIcon, DollarIcon } from '../../../assets/icons';
import { FC, ReactElement } from 'react';
import { IconPosition, LinkThemes } from '../../../lib/constants/components';
import { LinkUrls, PageRoutes } from '../../../lib/constants/react-router';
import { ProgramBadgeSize, ProgramColorIcon } from '../../../lib/constants/programs';
import cx from 'classnames';
import dayjs from 'dayjs';
import HeroSkeleton from '../LandingHero/Skeleton/HeroSkeleton';
import Link from '../../_shared/Link';
import ProgramBadge from '../../_shared/ProgramBadge';
import { Link as RouterLink } from 'react-router-dom';
import styles from './ProgramHero.module.scss';
import { useDateFormat } from '../../../lib/hooks/useDateFormat';
import { useTranslation } from 'react-i18next';

export interface Props {
  programBadgeProps: ProgramColorIcon;
  programName: string | ReactElement;
  programRewardsEarned: number;
  programYear: number;
  totalRewardsEarned: number;
  isLoading: boolean;
  linkText?: string;
  linkTo?: LinkUrls;
  programPeriod?: { start: string; end: Date };
}

const ProgramHero: FC<Props> = ({
  programBadgeProps,
  programRewardsEarned,
  programYear,
  totalRewardsEarned,
  isLoading = false,
  programName,
  linkText,
  linkTo,
  programPeriod,
}) => {
  const { t } = useTranslation();
  const dateFormat = useDateFormat(abbrevMonthOrdinalDayYearEn, abbrevMonthOrdinalDayYearFr);

  return (
    <div className={cx(styles['program-hero'])}>
      <div className={cx(styles['program-hero__wrapper'])}>
        <div className={cx(styles['program-hero__row-wrapper'])}>
          <Link
            as={RouterLink}
            to={`/${PageRoutes.REWARDS}?year=${programYear}`}
            icon={ChevronLeftIcon}
            iconPosition={IconPosition.LEFT}
            theme={LinkThemes.TEXT_LINK_DARK}
          >
            {t('general.back')}
          </Link>
        </div>
        {isLoading ? (
          <HeroSkeleton />
        ) : (
          <div className={cx(styles['program-hero__main-wrapper'])}>
            <div className={cx(styles['program-hero__left-wrapper'])}>
              <div className={cx(styles['program-hero__icon'])}>
                <ProgramBadge size={ProgramBadgeSize.LG} program={programBadgeProps} />
              </div>
              <div className={cx(styles['program-hero__left-text-wrapper'])}>
                <h1
                  className={cx(
                    styles['program-hero__text'],
                    styles['program-hero__text--heading']
                  )}
                >
                  {programName}
                </h1>
                {linkText && (
                  <Link
                    href={linkTo}
                    icon={ChevronRightIcon}
                    iconPosition={IconPosition.RIGHT}
                    theme={LinkThemes.TEXT_LINK_DARK}
                    target="_blank"
                  >
                    {linkText}
                  </Link>
                )}
              </div>
            </div>
            <hr className={cx(styles['program-hero__line'])}></hr>
            <div className={cx(styles['program-hero__right-wrapper'])}>
              <p
                className={cx(styles['program-hero__text'], styles['program-hero__text--overline'])}
              >
                {t('bayer-value.programs.program-rewards-earned', {
                  date: dayjs(programPeriod?.start).format(dateFormat),
                })}
              </p>
              <p
                className={cx(styles['program-hero__text'], styles['program-hero__text--heading'])}
              >
                {t('format.price', { value: programRewardsEarned })}
              </p>
              <div
                className={cx(
                  styles['program-hero__text-row-wrapper'],
                  styles['program-hero__text'],
                  styles['program-hero__text--subtitle']
                )}
              >
                <DollarIcon className={cx(styles['program-hero__dollar'])} aria-hidden="true" />
                <p>
                  <span className={cx(styles['program-hero__text--subtitle'])}>
                    {t('bayer-value.programs.total-rewards-available', {
                      date: dayjs(programPeriod?.end).format(slashSeparatedDate),
                      interpolation: { escapeValue: false },
                    })}
                  </span>
                  <span className={cx(styles['program-hero__text--subtitle--bold'])}>
                    {t('format.price', { value: totalRewardsEarned })}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProgramHero.displayName = 'ProgramHero';

export default ProgramHero;
