import { FC, useEffect, useState } from 'react';
import {
  ProgramBreakdown,
  ProgramBreakdownWithColorIcon,
} from '../../../../../lib/models/Program.model';

import cx from 'classnames';
import { DisplayTheme } from '../../../../../lib/constants/components';
import { GET_FARM_PROVINCE } from '../../../../../lib/graphql/FarmInformation.gql';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../../lib/graphql/ProgramRewards.gql';
import { getRegionSpecificLink } from '../../../../../lib/utils/utils';
import { groupProgramBreakdownAsOther } from '../../../../../lib/utils/programs';
import { InfoOutlineIcon } from '../../../../../assets/icons';
import Link from '../../../../../components/_shared/Link';
import { LinkUrls } from '../../../../../lib/constants/react-router';
import { ProgramCardBadgeAlignment } from '../../../../../components/_shared/ProgramCard/ProgramCard.types';
import { ProgramCardWithPill } from '../../../../../components/_shared/ProgramCard';
import RetreatSection from '../../../../BayerRewardsLanding/components/RetreatSection';
import styles from './Enrolled.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface Props {
  year: number;
}

const Enrolled: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const [groupedBreakdown, setGroupedBreakdown] = useState<ProgramBreakdownWithColorIcon[]>([]);
  const { data: breakdownData } = useQuery(GET_PROGRAM_REWARDS_BREAKDOWN_QUERY, {
    variables: { input: { year } },
  });
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE);

  useEffect(() => {
    if (breakdownData?.enrolledProgramsInfo) {
      setGroupedBreakdown(
        groupProgramBreakdownAsOther(
          [
            breakdownData.enrolledProgramsInfo.bayerValueProgramInfo,
            ...breakdownData.enrolledProgramsInfo.otherPrograms,
          ].filter((breakdown) => breakdown) as ProgramBreakdown[],
          t
        )
      );
    } else {
      setGroupedBreakdown([]);
    }
  }, [breakdownData, t]);

  return (
    <div className={cx(styles['enrolled'])}>
      <div className={cx(styles['enrolled__body'])}>
        <div className={cx(styles['enrolled__card-wrapper'])}>
          {groupedBreakdown.map((breakdownItem) => (
            <div key={breakdownItem?.program.id} className={cx(styles['enrolled__card'])}>
              <ProgramCardWithPill
                program={breakdownItem}
                badgeAlignment={ProgramCardBadgeAlignment.CENTER}
              />
            </div>
          ))}
        </div>
        <div className={cx(styles['enrolled__retreat-section'])}>
          {process.env.REACT_APP_SHOW_RETREAT && (
            <RetreatSection year={year} theme={DisplayTheme.WIDGET} />
          )}
        </div>
      </div>

      <div className={cx(styles['enrolled__footer'])}>
        <InfoOutlineIcon className={cx(styles['enrolled__icon'])} aria-hidden />
        <p className={cx(styles['enrolled__footer-text'])}>
          {t('bayer-rewards-widget.footer-text')}{' '}
          <Link
            href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
              east: LinkUrls.BAYER_VALUE_CONDITIONS_EAST,
              west: LinkUrls.BAYER_VALUE_CONDITIONS_WEST,
            })}
            target="_blank"
            className={cx(styles['enrolled__footer-link'])}
          >
            {t('general.learn-more')}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Enrolled;
