import { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

const useDeepCompare = <T>(value: T): T => {
  const [state, setState] = useState<T>(value);

  useEffect(() => {
    if (!isEqual(state, value)) {
      setState(value);
    }
  }, [value]);

  return state;
};

export default useDeepCompare;
