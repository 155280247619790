import { CartProduct, ShoppingCart } from '../../../../../__generated__/graphql';

import { CheckmarkIcon } from '../../../../../assets/icons';
import cx from 'classnames';
import { FC } from 'react';
import OrderContainer from '../../components/OrderContainer';
import { OrderProductTheme } from '../../../../../lib/constants/pre-orders';
import styles from '../PreOrderModal.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  shoppingCart: ShoppingCart;
  onEdit: (cartItem: CartProduct) => void;
}

const PreOrderOverview: FC<Props> = ({ shoppingCart, onEdit }) => {
  const { t } = useTranslation();

  return (
    <>
      <p
        className={cx(
          styles['pre-order-details-modal__subheading'],
          styles['pre-order-details-modal__subheading--overview']
        )}
      >
        <CheckmarkIcon className={cx([styles['pre-order-details-modal__checkmark']])} />
        {t('order-placement.pre-order-modal.added-to-shopping-cart')}
      </p>
      <div className={cx(styles['pre-order-details-modal__order-container-wrapper'])}>
        <OrderContainer
          shoppingCart={shoppingCart}
          onEdit={onEdit}
          showSubmitButton={false}
          orderSubmitted={false}
          theme={OrderProductTheme.OVERVIEW}
        />
      </div>
    </>
  );
};

PreOrderOverview.displayName = 'PreOrderOverview';

export default PreOrderOverview;
