import { useEffect, useState } from 'react';
import { libraries } from '../constants/google-maps';
import { useJsApiLoader } from '@react-google-maps/api';

export const usePlacesApi = (): boolean => {
  const [loadError, setLoadError] = useState<boolean>(false);

  const { isLoaded, loadError: apiLoaderError } = useJsApiLoader({
    libraries,
    googleMapsApiKey: `${process.env.REACT_APP_MAPS_API_KEY}`,
    nonce: '',
  });

  // Checks if the environment variable exists.
  useEffect(() => {
    if (!process.env.REACT_APP_MAPS_API_KEY) {
      setLoadError(true);
    }
  }, []);

  // Checks if there is an API's error
  useEffect(() => {
    if (apiLoaderError) {
      setLoadError(true);
    }
  }, [apiLoaderError]);

  // Initializes a new service to check if it is available and if the API Key is correct.
  useEffect(() => {
    if (isLoaded) {
      try {
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: '' }, (_, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setLoadError(false);
          } else {
            setLoadError(true);
          }
        });
      } catch (error) {
        setLoadError(true);
      }
    }
  }, [isLoaded]);

  return isLoaded && !loadError;
};
