import { Children, forwardRef, ReactNode, TouchEvent, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import styles from './Carousel.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  activeSlideIndex?: number;
  children: ReactNode;
  onSlideChange?(slideIndex: number): void;
  onSlideTransitionStart?(slideIndex: number): void;
}

const Carousel = forwardRef<HTMLDivElement, Props>(
  ({ activeSlideIndex = 0, children, onSlideChange, onSlideTransitionStart }, ref) => {
    const [activeSlide, setActiveSlide] = useState<number>(activeSlideIndex);
    // We use refs for the touch values to avoid re-renderings when updating them
    const touchStart = useRef<number>(0);
    const touchEnd = useRef<number>(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const numberOfSlides = Children.toArray(children).length;

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
      touchStart.current = e.targetTouches[0].clientX;
    };

    const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
      touchEnd.current = e.targetTouches[0].clientX;
    };

    const handleTouchEnd = () => {
      if (touchStart.current - touchEnd.current > 100 && activeSlide !== numberOfSlides - 1) {
        setActiveSlide(activeSlide + 1);
      }

      if (touchStart.current - touchEnd.current < -100 && activeSlide !== 0) {
        setActiveSlide(activeSlide - 1);
      }
    };

    useEffect(() => {
      let slidesArray: Element[] = [];

      const transitionstartEvent = (event: Event) => {
        const eventSlideIndex = parseInt(
          (event.target as HTMLElement).dataset.slideIndex || '',
          10
        );
        onSlideTransitionStart?.(eventSlideIndex);
      };

      if (carouselRef.current) {
        slidesArray = Array.from(
          carouselRef.current.getElementsByClassName(styles['carousel__slide'])
        );

        slidesArray.forEach((element) => {
          element.addEventListener('transitionstart', transitionstartEvent);
        });
      }

      return () => {
        slidesArray.forEach((element) => {
          element.removeEventListener('transitionstart', transitionstartEvent);
        });
      };
    }, []);

    useEffect(() => {
      setActiveSlide(activeSlideIndex);

      if (carouselRef.current) {
        carouselRef.current.tabIndex = 0;
        carouselRef.current.focus();
        carouselRef.current.tabIndex = -1;
      }
    }, [activeSlideIndex]);

    useEffect(() => {
      onSlideChange?.(activeSlide);
    }, [activeSlide]);

    return (
      <div ref={carouselRef} className={cx(styles['carousel'])}>
        <div className={cx(styles['carousel__outer-wrapper'])}>
          <div
            ref={ref}
            className={cx(styles['carousel__wrapper'])}
            style={{ transform: `translateX(calc(-${activeSlide} * 100%))` }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {Children.map(children, (child, index) => (
              <div
                className={cx(styles['carousel__slide'], {
                  [styles['carousel__slide--active']]: index === activeSlideIndex,
                })}
                data-slide-index={index}
                aria-hidden={index !== activeSlideIndex}
              >
                {child}
              </div>
            ))}
          </div>
        </div>
        <ul className={cx(styles['carousel__pagination-wrapper'])}>
          {Array.from(Array(numberOfSlides).keys()).map((element) => (
            <li key={element}>
              <button
                type="button"
                className={cx(styles['carousel__bullet-wrapper'])}
                onClick={() => {
                  setActiveSlide(element);
                }}
              >
                <span
                  className={cx(styles['carousel__bullet'], {
                    [styles['carousel__bullet--active']]: activeSlide === element,
                  })}
                ></span>
                <span className={cx('sr-only')}>
                  {t('carousel.go-to-slide', { index: element })}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

Carousel.displayName = 'Carousel';

export default Carousel;
