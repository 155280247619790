export enum ButtonThemes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT_LINK = 'text-link',
  TEXT_LINK_SECONDARY = 'text-link-secondary',
  TEXT_LINK_DARK = 'text-link-dark',
}

export enum LinkThemes {
  TEXT_LINK = 'text-link',
  TEXT_LINK_DARK = 'text-link-dark',
}

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center', // When there is just an Icon and no text in button
}

export enum InitialsTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum AccentStrokeColors {
  ANCILIARY_100 = 'anciliary-100',
}

export enum ValidationStateColors {
  VALID = 'validation-green',
  NOT_VALID = 'primary-color-400',
}

export enum SpinnerSizes {
  MD = 'md',
  LG = 'lg',
}

export const MODAL_FOCUSABLE_ELEMENTS_SELECTOR =
  'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ProfilePhotoSize {
  MD = 'md',
  LG = 'lg',
}

export enum EmptyStateTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum DisplayTheme {
  BANNER = 'Banner',
  WIDGET = 'Widget',
}