import { FAQ } from '../models/FAQ';
import { LinkUrls } from './react-router';

const AccountCreationFAQ: FAQ[] = [
  {
    title: 'faq.account-creation.1.question',
    answer: 'faq.account-creation.1.answer',
  },
  {
    title: 'faq.account-creation.2.question',
    answer: 'faq.account-creation.2.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.account-creation.3.question',
    answer: 'faq.account-creation.3.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.account-creation.4.question',
    answer: 'faq.account-creation.4.answer',
  },
];

const LogInFAQ: FAQ[] = [
  {
    title: 'faq.log-in.1.question',
    answer: 'faq.log-in.1.answer',
  },
  {
    title: 'faq.log-in.2.question',
    answer: 'faq.log-in.2.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.log-in.3.question',
    answer: 'faq.log-in.3.answer',
  },
];

const OnboardingFAQ: FAQ[] = [
  {
    title: 'faq.onboarding.1.question',
    answer: 'faq.onboarding.1.answer',
  },
  {
    title: 'faq.onboarding.2.question',
    answer: 'faq.onboarding.2.answer',
  },
  {
    title: 'faq.onboarding.3.question',
    answer: 'faq.onboarding.3.answer',
  },
  {
    title: 'faq.onboarding.4.question',
    answer: 'faq.onboarding.4.answer',
  },
];

const DashboardFAQ: FAQ[] = [
  {
    title: 'faq.dashboard.1.question',
    answer: 'faq.dashboard.1.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
];

const RewardsFAQ: FAQ[] = [
  {
    title: 'faq.rewards.1.question',
    answer: 'faq.rewards.1.answer',
    links: [
      {
        url: { east: LinkUrls.BAYER_VALUE_RULES_EAST, west: LinkUrls.BAYER_VALUE_RULES_WEST },
        target: '_blank',
      },
    ],
  },
  {
    title: 'faq.rewards.2.question',
    answer: 'faq.rewards.2.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.rewards.3.question',
    answer: 'faq.rewards.3.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.rewards.4.question',
    answer: 'faq.rewards.4.answer',
  },
  {
    title: 'faq.rewards.5.question',
    answer: 'faq.rewards.5.answer',
    links: [
      {
        url: {
          east: LinkUrls.BAYER_VALUE_CALCULATOR_EAST,
          west: LinkUrls.BAYER_VALUE_CALCULATOR_WEST,
        },
        target: '_blank',
      },
    ],
  },
  {
    title: 'faq.rewards.6.question',
    answer: 'faq.rewards.6.answer',
  },
  {
    title: 'faq.rewards.7.question',
    answer: 'faq.rewards.7.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
];

const PurchaseFAQ: FAQ[] = [
  {
    title: 'faq.pre-order-history.1.question',
    answer: 'faq.pre-order-history.1.answer',
  },
  {
    title: 'faq.pre-order-history.2.question',
    answer: 'faq.pre-order-history.2.answer',
  },
  {
    title: 'faq.pre-order-history.3.question',
    answer: 'faq.pre-order-history.3.answer',
  },
];

const AccountSettigsFAQ: FAQ[] = [
  {
    title: 'faq.account-settings.1.question',
    answer: 'faq.account-settings.1.answer',
  },
  {
    title: 'faq.account-settings.2.question',
    answer: 'faq.account-settings.2.answer',
  },
  {
    title: 'faq.account-settings.3.question',
    answer: 'faq.account-settings.3.answer',
  },
  {
    title: 'faq.account-settings.4.question',
    answer: 'faq.account-settings.4.answer',
  },
  {
    title: 'faq.account-settings.5.question',
    answer: 'faq.account-settings.5.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.account-settings.6.question',
    answer: 'faq.account-settings.6.answer',
  },
  {
    title: 'faq.account-settings.7.question',
    answer: 'faq.account-settings.7.answer',
  },
  {
    title: 'faq.account-settings.8.question',
    answer: 'faq.account-settings.8.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
  {
    title: 'faq.account-settings.9.question',
    answer: 'faq.account-settings.9.answer',
    links: [
      { url: LinkUrls.PHONE_REBATE_FULFILLMENT_TEL },
      { url: LinkUrls.MAIL_REBATE_FULFILLMENT },
    ],
  },
];

export const SectionsList = [
  { title: 'faq.account-creation.heading', questions: AccountCreationFAQ },
  { title: 'faq.log-in.heading', questions: LogInFAQ },
  { title: 'faq.onboarding.heading', questions: OnboardingFAQ },
  { title: 'faq.dashboard.heading', questions: DashboardFAQ },
  { title: 'faq.rewards.heading', questions: RewardsFAQ },
  { title: 'faq.pre-order-history.heading', questions: PurchaseFAQ },
  { title: 'faq.account-settings.heading', questions: AccountSettigsFAQ },
];
