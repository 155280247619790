import 'react-day-picker/dist/style.css';
import '../day-picker.scss';

import {
  AllFiltersForm,
  DateRangeFiltersForm,
  DateRangeOptions,
  YEAR_END,
  YEAR_START,
} from '../../../../../lib/constants/forms';
import { CalendarTodayIcon, ChevronLeftIcon } from '../../../../../assets/icons';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { enUS, frCA } from 'date-fns/locale';

import Button from '../../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../../lib/constants/components';
import { Controller } from 'react-hook-form';
import { CustomCaption } from '../CustomCaption/CustomCaption';
import cx from 'classnames';
import { DateFilterView } from '../../FilterSection.const';
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
import { fullWeekday } from '../../../../../lib/constants/date-formats';
import styles from './CustomDateRangeFilter.module.scss';
import useFilterContext from '../../../../../lib/contexts/accessibility/useFilterContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  formControl: Control<DateRangeFiltersForm, object>;
  setView: Dispatch<SetStateAction<DateFilterView>>;
  setValue: UseFormSetValue<AllFiltersForm>;
  watch: UseFormWatch<DateRangeFiltersForm>;
  initialValues: DateRangeFiltersForm;
}

const CustomDateRangeFilter: FC<Props> = ({
  formControl,
  setView,
  setValue,
  initialValues,
  watch,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const calendarLanguage = language === 'fr' ? frCA : enUS;
  const formStartDate = watch('startDate');
  const formEndDate = watch('endDate');

  const [, setFilterId] = useFilterContext();
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialValues.startDate ? new Date(initialValues.startDate) : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    initialValues.endDate ? new Date(initialValues.endDate) : undefined
  );
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const resetDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const onStartDateChange = (selectedStartDate: Date | undefined) => {
    setStartDate(selectedStartDate);

    if (endDate && dayjs(selectedStartDate).isAfter(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const onEndDateChange = (selectedEndDate: Date | undefined) => {
    setEndDate(selectedEndDate);

    if (startDate && dayjs(selectedEndDate).isBefore(startDate)) {
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const onSubmit = () => {
    if (startDate && endDate) {
      setValue('dateRange', DateRangeOptions.CUSTOM);
    } else {
      setValue('dateRange', DateRangeOptions.ALL);
    }
    setFilterId(null);
  };

  return (
    <div className={cx(styles['custom-date-range-filter'])}>
      <div className={cx(styles['custom-date-range-filter__header-row'])}>
        <button
          onClick={() => {
            setView(DateFilterView.OPTIONS);
          }}
          type="button"
          className={cx(styles['custom-date-range-filter__back'])}
          aria-label={t('general.back')}
        >
          <ChevronLeftIcon
            className={cx(styles['custom-date-range-filter__back-button'])}
            aria-hidden="true"
          />
        </button>
        <p className={cx(styles['custom-date-range-filter__heading'])}>
          {t('filter.date-range-options.custom')}
        </p>
      </div>
      <p className={cx(styles['custom-date-range-filter__subtitle'])}>
        {t('filter.calendar.subtitle')}
      </p>
      <div className={cx(styles['custom-date-range-filter__calendar-section-wrapper'])}>
        <div className={cx(styles['custom-date-range-filter__calendar-wrapper'])}>
          <div
            className={cx(styles['custom-date-range-filter__input-wrapper'], {
              [styles['custom-date-range-filter__input-wrapper--error']]: startDateError,
            })}
          >
            <CalendarTodayIcon
              className={cx(styles['custom-date-range-filter__calendar-icon'], {
                [styles['custom-date-range-filter__calendar-icon--error']]: startDateError,
              })}
              aria-hidden="true"
            />
            <input
              readOnly
              value={
                dayjs(startDate).locale(language).format(fullWeekday) ||
                t('filter.calendar.end-date-placeholder')
              }
              className={cx(styles['custom-date-range-filter__date'], {
                [styles['custom-date-range-filter__date--error']]: startDateError,
              })}
            />
          </div>
          <Controller
            control={formControl}
            name="startDate"
            render={({ field: { onChange } }) => (
              <DayPicker
                mode="single"
                selected={startDate}
                onSelect={(e: Date | undefined) => {
                  onStartDateChange(e);
                  onChange(e?.toISOString());
                }}
                fromYear={YEAR_START}
                toYear={YEAR_END}
                locale={calendarLanguage}
                components={{
                  Caption: CustomCaption,
                }}
              />
            )}
          />
        </div>
        <div className={cx(styles['custom-date-range-filter__dash'])}>-</div>
        <div className={cx(styles['custom-date-range-filter__calendar-wrapper'])}>
          <div
            className={cx(styles['custom-date-range-filter__input-wrapper'], {
              [styles['custom-date-range-filter__input-wrapper--error']]: endDateError,
            })}
          >
            <CalendarTodayIcon
              className={cx(styles['custom-date-range-filter__calendar-icon'], {
                [styles['custom-date-range-filter__calendar-icon--error']]: endDateError,
              })}
              aria-hidden="true"
            />
            <input
              readOnly
              value={
                dayjs(endDate).locale(language).format(fullWeekday) ||
                t('filter.calendar.end-date-placeholder')
              }
              className={cx(styles['custom-date-range-filter__date'], {
                [styles['custom-date-range-filter__date--error']]: endDateError,
              })}
            />
          </div>
          <Controller
            control={formControl}
            name="endDate"
            render={({ field: { onChange } }) => (
              <DayPicker
                mode="single"
                selected={endDate}
                onSelect={(e: Date | undefined) => {
                  onEndDateChange(e);
                  onChange(e?.toISOString());
                }}
                fromYear={YEAR_START}
                toYear={YEAR_END}
                locale={calendarLanguage}
                components={{
                  Caption: CustomCaption,
                }}
              />
            )}
          />
        </div>
      </div>
      {(startDateError || endDateError) && (
        <p className={cx(styles['custom-date-range-filter__error'])}>
          {t('filter.calendar.error-message')}
        </p>
      )}
      <hr className={cx(styles['custom-date-range-filter__divider'])} />
      <div className={cx(styles['custom-date-range-filter__cta-wrapper'])}>
        <Button type="button" onClick={() => resetDates()} theme={ButtonThemes.TEXT_LINK}>
          {t('general.clear')}
        </Button>
        <Button
          type="button"
          disabled={
            startDateError ||
            !formStartDate ||
            endDateError ||
            !formEndDate ||
            (startDate === undefined && endDate !== undefined) ||
            (!endDate === undefined && startDate !== undefined)
          }
          onClick={onSubmit}
        >
          {t('general.apply')}
        </Button>
      </div>
    </div>
  );
};

CustomDateRangeFilter.displayName = 'CustomDateRangeFilter';

export default CustomDateRangeFilter;
