import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons';
import { FC, useRef, useState } from 'react';
import {
  GET_USER_ONBOARDING_COMPLETE,
  UPDATE_USER_ACCOUNT_INFO,
} from '../../../lib/graphql/UserInformation.gql';
import Button from '../../../components/_shared/Button';
import cx from 'classnames';
import GetStartedCarousel from '../../../components/GetStartedCarousel';
import { GetStartedCarouselHandle } from '../../../components/GetStartedCarousel/GetStartedCarousel';
import { PageRoutes } from '../../../lib/constants/react-router';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './GettingStarted.module.scss';
import { UpdateUserInput } from '../../../__generated__/graphql';
import { useBreakpoint } from '../../../lib/hooks';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const GettingStarted: FC = () => {
  const { t } = useTranslation();
  const [setUserViewedInstructionCarousel] = useMutation(UPDATE_USER_ACCOUNT_INFO, {
    refetchQueries: [{ query: GET_USER_ONBOARDING_COMPLETE }],
  });
  const { isXl } = useBreakpoint();
  const [, dispatchsSnackBar] = useSnackbarContext();
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const carouselRef = useRef<GetStartedCarouselHandle>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    if (currentSlideIndex !== 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const markStepComplete = () => {
    const input: UpdateUserInput = {
      instructionsCarouselViewed: true,
    };
    setUserViewedInstructionCarousel({ variables: { input }, awaitRefetchQueries: true })
      .then(() => {
        navigate(`/${PageRoutes.ONBOARDING}/${PageRoutes.SETUP}`);
      })
      .catch(() => {
        dispatchsSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  const onNextButtonClick = () => {
    if (currentSlideIndex !== 2) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else if (isXl) {
      carouselRef.current?.playExitDesktop?.(
        markStepComplete,
        wrapperRef.current,
        buttonWrapperRef.current
      );
    } else {
      carouselRef.current?.playExitMobile?.(markStepComplete, wrapperRef.current);
    }
  };

  const onSkipIntroClick = () => {
    carouselRef.current?.playExitMobile?.(markStepComplete, wrapperRef.current);
  };

  return (
    <div ref={wrapperRef} className={cx(styles['gs'])}>
      <div className={cx(styles['gs__carousel'])}>
        <GetStartedCarousel
          ref={carouselRef}
          activeSlideIndex={currentSlideIndex}
          onSlideChange={(index: number) => {
            setCurrentSlideIndex(index);
          }}
        />
      </div>
      <div ref={buttonWrapperRef} className={cx(styles['gs__buttons-wrapper'])}>
        <div className={cx(styles['gs__buttons-inner-wrapper'])}>
          <Button
            className={cx(styles['gs__button'], styles['gs__button--next'])}
            type="button"
            onClick={onNextButtonClick}
            icon={ChevronRightIcon}
          >
            {t(
              currentSlideIndex === 2
                ? 'onboarding.get-started-carousel.begin-onboarding'
                : 'general.next'
            )}
          </Button>
          {currentSlideIndex !== 0 && (
            <Button
              className={cx(styles['gs__button'], styles['gs__button--back'])}
              type="button"
              onClick={onBackButtonClick}
              theme={ButtonThemes.SECONDARY}
              icon={ChevronLeftIcon}
              iconPosition={IconPosition.LEFT}
            >
              {t('general.back')}
            </Button>
          )}
        </div>
        <Button
          className={cx(styles['gs__button'], styles['gs__button--skip'], {
            [styles['gs__button--skip-hidden']]: currentSlideIndex === 2,
          })}
          type="button"
          onClick={onSkipIntroClick}
          theme={ButtonThemes.TEXT_LINK}
          tabIndex={currentSlideIndex === 2 ? -1 : 0}
          aria-hidden={currentSlideIndex === 2}
        >
          {t('onboarding.get-started-carousel.skip-intro')}
        </Button>
      </div>
    </div>
  );
};

export default GettingStarted;
