import { ButtonThemes, IconPosition } from '../../../lib/constants/components';

import cx from 'classnames';
import { ElementType } from 'react';
import { getButtonAriaLabel } from '../../../lib/utils/a11y';
import { Props } from './LinkButton.types';
import styles from './LinkButton.module.scss';

const DEFAULT_ELEMENT = 'a';

const LinkButton = <C extends ElementType = typeof DEFAULT_ELEMENT>({
  altText,
  as,
  children,
  className,
  icon: Icon = null,
  iconClassName,
  iconPosition = IconPosition.RIGHT,
  theme = ButtonThemes.TEXT_LINK,
  ...props
}: Props<C>) => {
  const Tag = as || DEFAULT_ELEMENT;

  return (
    <Tag
      {...props}
      aria-label={getButtonAriaLabel(children, altText)}
      className={cx(className, styles['link-button'], styles[`link-button--${theme}`], {
        [styles[`link-button--i-${iconPosition}`]]: !!Icon,
      })}
    >
      {children}
      {Icon && (
        <Icon
          className={cx(
            iconClassName,
            styles['link-button__icon'],
            styles[`link-button__icon--${iconPosition}`]
          )}
          aria-hidden="true"
        />
      )}
    </Tag>
  );
};

LinkButton.displayName = 'LinkButton';

export default LinkButton;
