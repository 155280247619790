export enum ProductCategory {
  CORN = 'Corn',
  CANOLA_HYBRID = 'Canola',
  SOYBEAN = 'Soybean',
}

export const ProductCategories = [
  ProductCategory.CANOLA_HYBRID,
  ProductCategory.CORN,
  ProductCategory.SOYBEAN
];
