import dayjs from '../utils/dates';
import { Province } from '../../__generated__/graphql';

export enum PasswordState {
  SHOW = 'text',
  HIDE = 'password',
}

export enum PasswordValidationErrorState {
  CHAR_SENSITIVE = 0,
  CONTAINS_NUMBER = 1,
  CONTAINS_SPECIAL_CHAR = 2,
  CHAR_LENGTH = 3,
  GIGYA_REPEATED_CHARACTERS = 4,
  GIGYA_REGEX_VALIDATION = 5,
}

export const BayerValueWestProvinces = [
  Province.Ab,
  Province.Sk,
  Province.Mb,
  Province.Yt,
  Province.Nt,
];
export const BayerValueEastProvinces = [
  Province.Bc,
  Province.Nb,
  Province.Nl,
  Province.Ns,
  Province.Nu,
  Province.On,
  Province.Pe,
  Province.Qc,
];

export type CropInformationForm = {
  selectAcres?: string;
  customAcres: number;
  farmCrops: string[];
};

export enum AcresWest {
  OPTION_1 = '<1500',
  OPTION_2 = '1500-3000',
  OPTION_3 = '3001-6000',
  OPTION_4 = '6001-10000',
  OPTION_5 = '>10000',
  OPTION_6 = 'Custom',
}

export enum AcresEast {
  OPTION_1 = '<300',
  OPTION_2 = '300-600',
  OPTION_3 = '601-1200',
  OPTION_4 = '1201-2400',
  OPTION_5 = '>2400',
  OPTION_6 = 'Custom',
}

export const AcresToNumMap = {
  // West Farms
  [AcresWest.OPTION_1]: 751,
  [AcresWest.OPTION_2]: 2251,
  [AcresWest.OPTION_3]: 4501,
  [AcresWest.OPTION_4]: 8001,
  [AcresWest.OPTION_5]: 11001,
  // East Farms
  [AcresEast.OPTION_1]: 151,
  [AcresEast.OPTION_2]: 451,
  [AcresEast.OPTION_3]: 901,
  [AcresEast.OPTION_4]: 1801,
  [AcresEast.OPTION_5]: 3401,
};

export enum Programs {
  BAYER_VALUE_PROGRAM = 0,
  OTHER_PROGRAMS = 1,
}

export enum DateRangeOptions {
  LAST_THIRTY_DAYS = 'last-thirty-days',
  LAST_SIX_MONTHS = 'last-six-months',
  CURRENT_YEAR = 'current-year',
  PREVIOUS_YEAR = 'previous-year',
  CUSTOM = 'custom',
  ALL = 'all',
}

export enum QueryFilterCategory {
  QUERY = 'query',
}

export enum AllFilterCategory {
  PRODUCT = 'product',
  RETAILER = 'retailer',
  PROGRAM = 'program',
  DATE_RANGE = 'dateRange',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum AllFilterCategoryMobile {
  PRODUCT = 'product',
  RETAILER = 'retailer',
  PROGRAM = 'program',
  PURCHASER = 'purchaser',
  DATE_RANGE = 'dateRange'
}

export enum DateRangeFilterCategory {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum FilterCategory {
  CUSTOM_DATE_RANGE_START = 'customDateRangeStart',
  CUSTOM_DATE_RANGE_END = 'customDateRangeEnd',
}

export type Period = {
  startDate?: string;
  endDate?: string;
};

export type QueryForm = {
  query: string;
};

export type AllFiltersForm = {
  purchaser: string[];
  product: string[];
  program: string[];
  retailer: string[];
  dateRange: DateRangeOptions;
};

export type DateRangeFiltersForm = {
  startDate: string | undefined;
  endDate: string | undefined;
};

export type FilterForm = {
  purchaser: string[];
  product: string[];
  program: string[];
  retailer: string[];
  dateRange: DateRangeOptions;
  query: string;
  customDateRangeStart: string | undefined;
  customDateRangeEnd: string | undefined;
};

export type SecurityForm = {
  newPassword: string;
  confirmNewPassword: string;
};

export type ForgotPasswordForm = {
  email: string;
};

export const DEBOUNCE_DELAY = 750;
export const YEAR_START = 1990;
export const YEAR_END = dayjs().year();
