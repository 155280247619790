import { gql } from '../../__generated__';

export const GET_PARTNERS_PERMISSIONS = gql(`
    query GetPartnersPermissions {
        farm {
            farmInfo {
                id
            }
            partners {
                partner {
                    farmInfo {
                        id
                        name
                        farmLogoUrl
                        address {
                            address
                            address2
                            province
                            city
                            postalCode
                        }
                    }
                    adminUser {
                        accountInfo {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                }
                hasAccess
                isParent
            }
        }
    }
`);

export const UPDATE_PARTNER_PERMISSION = gql(`
    mutation UpdatePartnerPermission($input: FarmPartnerUpdateInput!) {
        farmPartnerUpdate(input: $input) {
            farmPartner {
                partner {
                    farmInfo {
                        id
                    }
                }
                hasAccess
            }
        }
    }
`);

export const GET_FARM_PARTNERS = gql(`
    query GetFarmPartners {
        farm {
            farmInfo {
                id
            }
            partners {
                hasAccess
                isParent
                partner {
                    farmInfo {
                        id
                    }
                }
            }
        }
    }
`);

export const GET_PARTNERS_NAME = gql(`
    query Farm {
        farm {
            farmInfo {
                id
                name
            }
            partners {
                isParent
                partner {
                    farmInfo {
                        id
                        name
                    }
                }
            }
        }
    }
`);