import { CrossIcon, SuccessCircleIcon, WarningIcon } from '../../../assets/icons';
import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from './Snackbar.types';
import styles from './Snackbar.module.scss';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  id: string;
  label: string;
  state: SnackbarStates;
  className?: string;
}

const DELAY = 5000;

const Snackbar: FC<Props> = ({ id, label, state, className }) => {
  const { t } = useTranslation();
  const [, dispatch] = useSnackbarContext();
  const snackbarId = useRef<string>(uuid());

  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      dispatch({ type: SnackbarContextActionTypes.RemoveFromQueue, id });
    }, DELAY);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      dispatch({ type: SnackbarContextActionTypes.RemoveFromQueue, id });
    }, DELAY);
  };

  const renderIcon = () => {
    switch (state) {
      case SnackbarStates.WARNING:
        return <WarningIcon className={cx(styles['snackbar__icon'])} aria-hidden="true" />;
      case SnackbarStates.SUCCESS:
        return <SuccessCircleIcon className={cx(styles['snackbar__icon'])} aria-hidden="true" />;
      default:
        return undefined;
    }
  };

  return (
    <div
      id={snackbarId.current}
      className={cx(styles['snackbar'], className, {
        [styles['snackbar--error']]: state === SnackbarStates.WARNING,
        [styles['snackbar--success']]: state === SnackbarStates.SUCCESS,
      })}
      role="status"
      aria-live="polite"
      key={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      {renderIcon()}
      <span className={cx(styles['snackbar__text'])}>{label}</span>
      <button
        type="button"
        className={cx(styles['snackbar__button'])}
        aria-label={t('snackbars.close')}
        onClick={() => dispatch({ type: SnackbarContextActionTypes.RemoveFromQueue, id })}
      >
        <CrossIcon className={cx(styles['snackbar__icon'])} aria-hidden="true" />
      </button>
    </div>
  );
};

Snackbar.displayName = 'Snackbar';

export default Snackbar;
