import DelkabImage from '../../../../assets/images/products/TFLL_Canola.png';
import { DisplayTheme } from '../../../../lib/constants/components';
import { FC } from 'react';
import { PageRoutes } from '../../../../lib/constants/react-router';
import PromotionBanner from '../../../../components/PromotionBanner';
import styles from "./DelkabPromotionWidget.module.scss";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DelkabPromotionWidget: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PromotionBanner
      image={DelkabImage}
      imageClass={styles['delkab-promotion__image']}
      badge={t('product-offer.dekalb.badge')}
      title={t('product-offer.dekalb.title')}
      description={t('product-offer.dekalb.description')}
      theme={DisplayTheme.WIDGET}
      cta={{
        label: t('product-offer.dekalb.button'),
        action: () => {
          navigate(`${PageRoutes.PRE_ORDERS}?product=DK401TL`);
        },
      }}
    />
  );
};

DelkabPromotionWidget.displayName = 'DelkabPromotionWidget';

export default DelkabPromotionWidget;
