import {
  RetailerContextAction,
  RetailerContextActionTypes,
  RetailerContextState,
} from './RetailerContext.types';

const OrderHistoryContextReducer = (
  state: RetailerContextState,
  action: RetailerContextAction
): RetailerContextState => {
  switch (action.type) {
    case RetailerContextActionTypes.UpdateFilters:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default OrderHistoryContextReducer;
