import { BayerValueEastProvinces } from '../constants/forms';
import { Province } from '../../__generated__/graphql';
import { Region } from '../constants/pre-orders';
import { RegionLink } from '../models/FAQ';

/**
 * Converts a string into kebab-case
 * @param string
 * @returns
 */
export const toKebabCase = (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

/**
 * Formats crop name to use it as a key
 * @param string
 * @returns
 */
export const toNormalizeTranslationKey = (string: string) =>
  string.toUpperCase().replace(/[,/\s-]/g, '');

/**
 * Gets province's region
 * @param province
 * @returns region
 */
export const getRegion = (province: Province) =>
  BayerValueEastProvinces.includes(province) ? Region.EAST : Region.WEST;

/**
 * Gets a link based on the farm's province
 * @param province
 * @param links
 * @returns
 */
export const getRegionSpecificLink = (
  province: Province | undefined,
  links: RegionLink
): string => {
  if (province) {
    return BayerValueEastProvinces.includes(province) ? links.east : links.west;
  }

  return links.east;
};

/**
 * Formats numbers to one decimal only if they have decimals
 * @param value 
 * @returns 
 */
export const formatNumberOneDecimal = (value: number): string | number =>
  value % 1 === 0 ? value : value.toFixed(1);
