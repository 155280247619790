import cx from 'classnames';
import DashboardDesktop from './DashboardDesktop';
import DashboardMobile from './DashboardMobile';
import DashboardTablet from './DashboardTablet';
import { FC } from 'react';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../lib/graphql/UserInformation.gql';
import styles from './Dashboard.module.scss';
import { useBreakpoint } from '../../lib/hooks';
import { useDesktopData } from '../../lib/hooks/useDesktopData';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const Dashboard: FC = () => {
  usePageTitle();
  const {
    i18n: { language },
  } = useTranslation();
  useDesktopData(language);
  const { data: userData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const { isXs, isLg } = useBreakpoint();

  const renderDashboardView = () => {
    if (isXs) {
      return <DashboardMobile hasAccess={userData?.user.hasAccess} />;
    } else if (isLg) {
      return <DashboardTablet hasAccess={userData?.user.hasAccess} />;
    }
    return <DashboardDesktop hasAccess={userData?.user.hasAccess} />;
  };

  return <div className={cx(styles['dashboard'])}>{renderDashboardView()}</div>;
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
