import { CheckmarkIcon } from '../../../assets/icons';
import cx from 'classnames';
import { Fragment } from 'react';
import { Step } from '../../../lib/constants/stepper';
import styles from '../Stepper.module.scss';
import { useTranslation } from 'react-i18next';

export interface SubStepsProps {
  step: Step;
  level?: number; // for tracking nested steps
  onSubStepChange: (subStep: Step) => void;
  activeStepsIds: string[];
}

const SubSteps = ({ step, level = 0, onSubStepChange, activeStepsIds }: SubStepsProps) => {
  const { t } = useTranslation();

  return (
    <ol
      className={cx(styles['sub-steps-container'], {
        [styles[`sub-steps-container--level-${level}`]]: level,
        [styles['sub-steps-container--opened']]: activeStepsIds.includes(step.id) || level,
      })}
    >
      {(step.children || []).map((subStep) => {
        const isActive = activeStepsIds.includes(subStep.id);
        return (
          <Fragment key={subStep.id}>
            <li
              className={cx(styles['sub-step'], {
                [styles['sub-step--active']]: isActive,
                [styles['sub-step--upcoming']]: !subStep.isCompleted && !subStep.isInProgress,
                [styles['sub-step--with-children']]: subStep.children?.length,
                [styles['sub-step--parent']]: !step.Component,
              })}
            >
              <button
                type="button"
                className={cx(styles['sub-step__label'], {
                  [styles['sub-step__label--completed']]: subStep.isCompleted,
                })}
                onClick={() => {
                  onSubStepChange(subStep);
                }}
              >
                {subStep.isCompleted && (
                  <span className="sr-only">{`${t('stepper.completed')}:`} </span>
                )}
                {isActive && <span className="sr-only">{`${t('stepper.current')}:`} </span>}
                {t(subStep.labelKey || '')}
                {subStep.isCompleted && (
                  <div className={cx(styles['sub-step__icon'])}>
                    <CheckmarkIcon aria-hidden="true" />
                  </div>
                )}
              </button>
            </li>
            <li>
              {!!subStep.children?.length && (
                <SubSteps
                  step={subStep}
                  level={level + 1}
                  onSubStepChange={onSubStepChange}
                  activeStepsIds={activeStepsIds}
                />
              )}
            </li>
          </Fragment>
        );
      })}
    </ol>
  );
};

export default SubSteps;
