import {
  GET_PREFERRED_RETAILERS,
  REMOVE_PREFERRED_RETAILER,
} from '../../../lib/graphql/PreferredRetailers.gql';
import { RemovePreferredRetailersInput, Retailer } from '../../../__generated__/graphql';
import AddRetailerCard from '../AddRetailerCard';
import { FC } from 'react';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../SnackbarContainer/Snackbar/Snackbar.types';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

interface Props {
  retailer: Retailer;
}

const PreferredRetailerWrapper: FC<Props> = ({ retailer }) => {
  const { t } = useTranslation();

  const [removeRetailer, { loading }] = useMutation(REMOVE_PREFERRED_RETAILER, {
    refetchQueries: [{ query: GET_PREFERRED_RETAILERS }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();

  const removePreferredRetailer = (ret: Retailer) => {
    const input: RemovePreferredRetailersInput = {
      retailerIds: [ret.id],
    };
    removeRetailer({ variables: { input } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.removed-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  return (
    <AddRetailerCard
      retailer={retailer}
      isPreferred
      onButtonClick={removePreferredRetailer}
      isLoading={loading}
    />
  );
};

export default PreferredRetailerWrapper;
