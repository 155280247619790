import FilterContext from './FilterContext';
import { FilterContextType } from './FilterContext.types';
import { useContext } from 'react';

const useFilterContext = (): FilterContextType => {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }

  return context;
};

export default useFilterContext;
