import { createContext, FC, useReducer } from 'react';
import {
  SnackbarContextProps,
  SnackbarContextState,
  SnackbarContextType,
} from './SnackbarContext.types';
import snackbarContextReducer from './reducer';

const initialState: SnackbarContextState = [];

const SnackbarContext = createContext<SnackbarContextType>([initialState, () => {}]);

export const SnackbarProvider: FC<SnackbarContextProps> = ({ children }) => {
  const value = useReducer(snackbarContextReducer, initialState);

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

export default SnackbarContext;
