import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { GET_USER_ONBOARDING_COMPLETE } from '../../lib/graphql/UserInformation.gql';
import { PageRoutes } from '../../lib/constants/react-router';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import { useQuery } from '@apollo/client';

const Onboarding: FC = () => {
  usePageTitle('onboarding');
  const { data: user } = useQuery(GET_USER_ONBOARDING_COMPLETE);
  const [hasRedirected, setHasRedirected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !hasRedirected) {
      if (!user.user.accountInfo.instructionsCarouselViewed) {
        navigate(`/${PageRoutes.ONBOARDING}/${PageRoutes.GETTING_STARTED}`, { replace: true });
      } else if (!user.user.accountInfo.onboardingComplete) {
        navigate(`/${PageRoutes.ONBOARDING}/${PageRoutes.SETUP}`, { replace: true });
      } else {
        navigate(PageRoutes.HOME, { replace: true });
      }
      setHasRedirected(true);
    }
  }, [hasRedirected, navigate, user]);

  return hasRedirected ? <Outlet /> : null;
};

export default Onboarding;
