import { gql } from '../../../../../__generated__';

export const GET_SUMMARY_DETAILS_QUERY = gql(`
query GetSummaryInfo($skipFarm: Boolean!) {
  user {
    accountInfo {
      id
      isAdmin
      firstName
      lastName
      email
      businessPhone
      mobilePhone
      address {
        address
        address2
        city
        province
        postalCode
      }
    }
    retailers {
      id
      name
      alias
      phone
      mobilePhone
      address {
        address
        address2
        city
        province
        postalCode
      }
      contacts {
        id
        firstName
        lastName
        isPrimary
      }
    }
  }
  farm @skip(if: $skipFarm) {
    farmInfo {
      id
      name
      address {
        address
        address2
        city
        postalCode
        province
      }
      cropInformation {
        acrage
        crops {
          id
          name
        }
      }
      paymentPreference {
        preferredOption
        chequePayeeName
        directDepositEmail
        mailingAddress {
          address
          address2
          city
          province
          postalCode
        }
      }
    }
    users {
      accountInfo {
        id
        firstName
        lastName
        email
        crmEmail
        status
        accessLevel
      }
    }
    partners {
      partner {
        farmInfo {
          id
          name
        }
        adminUser {
          accountInfo {
            id
            email
          }
        }
      }
      hasAccess
      isParent
    }
  }
}
`);
