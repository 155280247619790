import { gql } from '../../../../__generated__';

export const VERIFY_ADMIN_STATUS = gql(`
    query VerificationStatus($input: VerificationStatusInput!) {
        verificationStatus(input: $input) {
            registered
            verificationRequired
            bayerValueConsent
            gigyaAccount {
              emailExists
            }
        }
    }
`);
