import 'react-day-picker/dist/style.css';
import '../day-picker.scss';

import {
  CaptionDropdowns,
  CaptionLabel,
  CaptionProps,
  MonthChangeEventHandler,
  useDayPicker,
  useNavigation,
} from 'react-day-picker';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../../assets/icons';
import cx from 'classnames';
import style from './CustomCaption.module.scss';
import { useBreakpoint } from '../../../../../lib/hooks';
import { useTranslation } from 'react-i18next';

export const CustomCaption = ({ id, displayMonth }: CaptionProps) => {
  const { classNames, styles, components } = useDayPicker();
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation();
  const { isMd } = useBreakpoint();
  const { t } = useTranslation();

  const handleMonthChange: MonthChangeEventHandler = (newMonth) => {
    goToMonth(newMonth);
  };

  const CaptionLabelComponent = components?.CaptionLabel ?? CaptionLabel;

  const captionLabel = <CaptionLabelComponent id={id} displayMonth={displayMonth} />;

  return (
    <div className={classNames.caption_dropdowns} style={styles.caption_dropdowns}>
      <button
        type="button"
        disabled={!previousMonth}
        onClick={() => previousMonth && handleMonthChange(previousMonth)}
        aria-label={t('filter.calendar.previous-month')}
        className={cx(style['custom-caption__button'], style['custom-caption__button--left'])}
      >
        {!isMd && <ChevronLeftIcon className={cx(style['arrow-icon'])} aria-hidden="true" />}
      </button>
      <div className={classNames.vhidden}>{captionLabel}</div>
      {CaptionDropdowns({ id: undefined, displayMonth: currentMonth })}
      <button
        type="button"
        disabled={!nextMonth}
        onClick={() => nextMonth && handleMonthChange(nextMonth)}
        aria-label={t('filter.calendar.next-month')}
        className={cx(style['custom-caption__button'], style['custom-caption__button--right'])}
      >
        {!isMd && <ChevronRightIcon className={cx(style['arrow-icon'])} aria-hidden="true" />}
      </button>
    </div>
  );
};
