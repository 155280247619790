import axios, { AxiosResponse } from 'axios';
import { ExportType, FileFormat } from '../constants/exports';
import { OrderDirection, PurchasePreOrderSortField } from '../../__generated__/graphql';

import { buildUrlParams } from '../utils/api';
import { dateTimeUtc } from '../constants/date-formats';
import dayjs from 'dayjs';
import { Endpoints } from '../constants/endpoints';
import { PurchaseHistoryFilters } from '../models/Purchase.model';
import { v4 as uuid } from 'uuid';

class ExportClient {
  downloadCsv(
    query: string | undefined,
    filters: PurchaseHistoryFilters,
    sortBy: PurchasePreOrderSortField,
    sortOrder: OrderDirection,
    lang: string,
    type: ExportType,
    format: FileFormat
  ): Promise<AxiosResponse<any>> {
    const params =
      type === ExportType.Purchase
        ? {
            orderDir: sortOrder,
            orderField: sortBy,
            query,
            startDate:
              filters.dateRange?.startDate &&
              dayjs(filters.dateRange?.startDate).format(dateTimeUtc),
            endDate:
              filters.dateRange?.endDate &&
              dayjs(filters.dateRange?.endDate).format(dateTimeUtc),
            productSkus: filters.productSkus,
            programIds: filters.programIds,
            retailerIds: filters.retailerIds,
            farmIds: filters.farmIds,
          }
        : {
            orderDir: sortOrder,
            orderField: sortBy,
            query,
            startDate:
              filters.dateRange?.startDate &&
              dayjs(filters.dateRange.startDate).format(dateTimeUtc),
            endDate:
              filters.dateRange?.endDate &&
              dayjs(filters.dateRange.endDate).format(dateTimeUtc),
            productSkus: filters.productSkus,
            retailerIds: filters.retailerIds,
          };

    return axios.get(
      `${Endpoints.DOWNLOAD_EXPORT_LIST}${buildUrlParams(
        {
          ...params,
          lang,
          type,
          format,
        },
        false
      )}`,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: {
          'x-request-id': uuid(),
        },
      }
    );
  }
}

export default new ExportClient();
