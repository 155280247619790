import {
  BayerIcon,
  BayerIconDark,
  LogoutIcon,
  MyBayerIconDark,
  MyBayerIconLight,
} from '../../assets/icons';
import { FarmInfo, UserAccountInfo } from '../../__generated__/graphql';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Avatar from '../_shared/Avatar';
import cx from 'classnames';
import { getRegion } from '../../lib/utils/utils';
import LanguageDropdown from './subcomponents/LanguageDropdown/LanguageDropdown';
import { Link } from 'react-router-dom';
import { LogoutUser } from '../../lib/clients/gigya-client';
import { PageRoutes } from '../../lib/constants/react-router';
import { Region } from '../../lib/constants/pre-orders';
import styles from './Header.module.scss';
import { v4 as uuid } from 'uuid';

export interface Props {
  className?: string;
  user: Partial<UserAccountInfo> | undefined;
  farm: Partial<FarmInfo> | undefined;
  displayLogo: boolean;
}

const HeaderMobile: FC<Props> = ({ className, user, farm, displayLogo }) => {
  const buttonId = useRef<string>(uuid());
  const menuId = useRef<string>(uuid());
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflow = expanded ? 'hidden' : '';

    return () => {
      if (expanded) {
        document.body.style.overflow = '';
      }
    };
  }, [expanded]);

  const displayPreOrder = useMemo(
    () =>
      process.env.REACT_APP_SHOW_PRE_ORDERS &&
      farm?.address?.province &&
      getRegion(farm?.address?.province) === Region.WEST,
    [farm]
  );

  const renderBayerLogo = () => {
    if (expanded && displayLogo) {
      return <BayerIcon className={cx(styles['h-mobile__bayer-logo'])} aria-hidden />;
    } else if (!expanded && displayLogo) {
      return <BayerIconDark className={cx(styles['h-mobile__bayer-logo'])} aria-hidden />;
    }

    return null;
  };

  return (
    <div className={cx(className, styles['h-mobile'], { open: expanded })}>
      <div className={cx(styles['h-mobile__branding'])}>
        <a
          href="#main"
          className={cx('sr-only', 'sr-only-focus-visible', styles['header__skip-to-main'])}
        >
          {t('header.skip-to-main')}
        </a>
        <Link to="/" className={cx(styles['h-mobile__logo-wrapper'])}>
          {expanded ? (
            <MyBayerIconLight
              className={cx(styles['h-mobile__my-bayer-logo'])}
              aria-label={t('general.bayer-logo')}
            />
          ) : (
            <MyBayerIconDark
              className={cx(styles['h-mobile__my-bayer-logo'])}
              aria-label={t('general.bayer-logo')}
            />
          )}
        </Link>
        {renderBayerLogo()}
      </div>
      <div>
        <button
          type="button"
          className={cx(styles['h-mobile__hamburger-button'])}
          onClick={() => {
            setExpanded(!expanded);
          }}
          aria-controls={menuId.current}
          aria-expanded={expanded}
          id={buttonId.current}
        >
          <span className={cx(styles['h-mobile__hamburger'])}></span>
          <span className={cx('sr-only')}>
            {expanded ? t('header.close-menu') : t('header.open-menu')}
          </span>
        </button>
      </div>
      <div className={cx(styles['h-mobile__overlay'])}></div>
      <section
        className={cx(styles['h-mobile__menu'])}
        aria-hidden={!expanded}
        aria-labelledby={buttonId.current}
        id={menuId.current}
      >
        {user && (
          <div className={cx(styles['h-mobile__account-info'])}>
            <div className={cx(styles['h-mobile__avatar-wrapper'])}>
              <Avatar
                profilePictureUrl={user?.profilePictureUrl}
                firstName={user?.firstName || ''}
                lastName={user?.lastName || ''}
              />
            </div>
            <div className={cx(styles['h-mobile__account-text'])}>
              <p className={cx(styles['h-mobile__account-code'])}>
                {t('header.account-code', { id: farm?.id })}
              </p>
              <p
                className={cx(styles['h-mobile__account-name'])}
              >{`${user?.firstName} ${user?.lastName}`}</p>
            </div>
          </div>
        )}
        {user?.onboardingComplete && (
          <nav className={cx(styles['h-mobile__navigation'])} aria-label={t('header.nav-alt')}>
            <ul className={cx(styles['h-mobile__navigation-list'])}>
              <li className={cx(styles['h-mobile__navigation-item'])}>
                <Link
                  to={PageRoutes.HOME}
                  className={cx(styles['h-mobile__navigation-link'])}
                  tabIndex={expanded ? 0 : -1}
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  {t('header.links.dashboard')}
                </Link>
              </li>
              {displayPreOrder && (
                <li className={cx(styles['h-mobile__navigation-item'])}>
                  <Link
                    to={PageRoutes.PRE_ORDERS}
                    className={cx(styles['h-mobile__navigation-link'])}
                    tabIndex={expanded ? 0 : -1}
                    onClick={() => {
                      setExpanded(false);
                    }}
                  >
                    {t('header.links.pre-orders')}
                  </Link>
                </li>
              )}
              <li className={cx(styles['h-mobile__navigation-item'])}>
                <Link
                  to={PageRoutes.ORDER_PURCHASE_HISTORY}
                  className={cx(styles['h-mobile__navigation-link'])}
                  tabIndex={expanded ? 0 : -1}
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  {t('header.links.order-purchase-history')}
                </Link>
              </li>
              <li className={cx(styles['h-mobile__navigation-item'])}>
                <Link
                  to={PageRoutes.REWARDS}
                  className={cx(styles['h-mobile__navigation-link'])}
                  tabIndex={expanded ? 0 : -1}
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  <Trans i18nKey="header.links.bayer-value-rewards" components={[<sup />]} />
                </Link>
              </li>
            </ul>
          </nav>
        )}
        <LanguageDropdown tabIndex={expanded ? 0 : -1} />
        <hr className={cx(styles['h-mobile__hr'])} />

        <ul>
          {user?.onboardingComplete && (
            <li>
              <Link
                className={cx(styles['h-mobile__navigation-link'])}
                to={PageRoutes.ACCOUNT_SETTINGS}
                tabIndex={expanded ? 0 : -1}
                onClick={() => {
                  setExpanded(false);
                }}
              >
                {t('header.links.settings')}
              </Link>
            </li>
          )}
          {user && (
            <li>
              <button
                className={cx([
                  styles['h-mobile__navigation-link'],
                  styles['h-mobile__logout-button'],
                ])}
                onClick={() => {
                  LogoutUser();
                }}
                type="button"
                tabIndex={expanded ? 0 : -1}
              >
                <span className={cx(styles['h-mobile__bold'])}>{t('header.links.logout')}</span>
                <LogoutIcon className={cx(styles['h-mobile__logout-icon'])} aria-hidden="true" />
              </button>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

HeaderMobile.displayName = 'HeaderMobile';

export default HeaderMobile;
