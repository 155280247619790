import CalculatorContext from './CalculatorContext';
import { CalculatorContextType } from './CalculatorContext.types';
import { useContext } from 'react';

const useCalculatorContext = (): CalculatorContextType => {
  const context = useContext(CalculatorContext);

  if (context === undefined) {
    throw new Error('useCalculatorContext must be used within a CalculatorProvider');
  }

  return context;
};

export default useCalculatorContext;
