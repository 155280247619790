import { gql } from '../../__generated__';

export const GET_USER_NAME = gql(`
  query UserName {
    user {
      accountInfo {
        id
        firstName 
      }
    }
  }
`);
