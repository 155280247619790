import { AcresWest, CropInformationForm } from '../../../../../lib/constants/forms';
import { Controller, useForm } from 'react-hook-form';
import { Crop, Province } from '../../../../../__generated__/graphql';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  GET_CROP_INFORMATION_QUERY,
  UPDATE_CROP_INFORMATION_MUTATION,
} from './CropInformation.gql';
import {
  getAcreOptions,
  getFarmAcresFromOption,
  getFarmAcresOption,
  sortCropsByName,
  sortCropsByType,
} from '../../../../../lib/utils/onboarding';
import { useMutation, useQuery } from '@apollo/client';

import Accordion from '../../../../../components/_shared/Accordion';
import { ArrowDropDownIcon } from '../../../../../assets/icons';
import Checkbox from '../../../../../components/_shared/Checkbox';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../../lib/constants/react-select';
import { GET_CROPS_QUERY } from '../../../../../lib/graphql/Crops.gql';
import { GET_FARM_INFORMATION_WITHOUT_PAYMENT } from '../../../../../lib/graphql/FarmInformation.gql';
import { isNaturalNumber } from '../../../../../lib/utils/form-validations';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../../lib/constants/stepper';
import StepsWrapper from '../../../StepsWrapper';
import styles from './CropInformation.module.scss';
import TextField from '../../../../../components/_shared/TextField';
import { toNormalizeTranslationKey } from '../../../../../lib/utils/utils';
import { useFormErrors } from '../../../../../lib/hooks/useFormErrors';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const CropInformation: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [acreOptions, setAcreOptions] = useState<DropdownOption[]>([]);
  const { data: cropOptions, loading: cropOptionsLoading } = useQuery(GET_CROPS_QUERY);
  const {
    data: cropData,
    error: cropError,
    loading: cropLoading,
  } = useQuery(GET_CROP_INFORMATION_QUERY);
  const { data: farmData } = useQuery(GET_FARM_INFORMATION_WITHOUT_PAYMENT);
  const [updateCropInformation, { loading: isSaving }] = useMutation(
    UPDATE_CROP_INFORMATION_MUTATION,
    {
      refetchQueries: [{ query: GET_CROP_INFORMATION_QUERY }],
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    reset,
    setValue,
    trigger,
  } = useForm<CropInformationForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      selectAcres: getFarmAcresOption(
        cropData?.farm?.farmInfo?.cropInformation?.acrage,
        farmData?.farm.farmInfo.address?.province as Province
      ),
      customAcres: cropData?.farm?.farmInfo?.cropInformation?.acrage || 0,
      farmCrops: cropData?.farm?.farmInfo?.cropInformation?.crops?.map((crop) => crop.id) || [],
    },
  });
  useFormErrors(trigger, dirtyFields);

  const watchCustomOption = watch('selectAcres');
  const customAcrageId = useRef(uuid());
  const formId = useRef(uuid());

  useLayoutEffect(() => {
    if (watchCustomOption) {
      const custom = document.getElementById(customAcrageId.current);
      custom?.focus();
    }
  }, [watchCustomOption]);

  useEffect(() => {
    setAcreOptions(getAcreOptions(t, farmData?.farm.farmInfo.address?.province as Province));
  }, [farmData, t]);

  useEffect(() => {
    if (acreOptions.length) {
      const selected = acreOptions.find(
        (option) =>
          option.value ===
          getFarmAcresOption(
            cropData?.farm?.farmInfo?.cropInformation?.acrage,
            farmData?.farm.farmInfo.address?.province as Province
          )
      );
      setValue('selectAcres', selected?.value.toString());
    }
  }, [
    acreOptions,
    cropData?.farm?.farmInfo?.cropInformation?.acrage,
    farmData?.farm.farmInfo.address?.province,
    setValue,
  ]);

  useEffect(() => {
    if (cropData) {
      reset({
        selectAcres: getFarmAcresOption(
          cropData?.farm?.farmInfo?.cropInformation?.acrage,
          farmData?.farm.farmInfo.address?.province as Province
        ),
        customAcres: cropData?.farm?.farmInfo?.cropInformation?.acrage || 0,
        farmCrops: cropData?.farm?.farmInfo?.cropInformation?.crops?.map((crop) => crop.id) || [],
      });
    }
  }, [cropData, farmData?.farm.farmInfo.address?.province, reset]);

  useEffect(() => {
    if (cropError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, cropError, t]);

  const { crops, fruits, vegetables } = useMemo(
    () =>
      cropOptions?.crops
        ? sortCropsByType(cropOptions?.crops)
        : { crops: [], fruits: [], vegetables: [] },
    [cropOptions]
  );

  const onBack = () => {
    back?.();
  };

  const onSubmit = (formData: CropInformationForm) => {
    if (isDirty && cropData?.farm?.farmInfo?.id && formData.selectAcres) {
      let acrage =
        formData.selectAcres === AcresWest.OPTION_6
          ? formData.customAcres
          : getFarmAcresFromOption(formData.selectAcres);

      if (typeof acrage === 'string') {
        acrage = parseInt(acrage, 10);
      }

      updateCropInformation({
        variables: {
          input: {
            cropIds: formData.farmCrops,
            acrage,
          },
        },
      })
        .then(() => {
          next?.();
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    } else {
      next?.();
    }
  };

  const renderCheckboxes = (cropList: Partial<Crop>[]) =>
    sortCropsByName(t, cropList).map((crop) => {
      const { id, name } = crop;

      return (
        <li key={id}>
          <Checkbox
            {...register('farmCrops', { required: true })}
            label={t(`crops.${toNormalizeTranslationKey(name || '')}`)}
            value={id}
            hasError={!!errors.farmCrops}
          />
        </li>
      );
    });

  return (
    <StepsWrapper
      title={t('onboarding.crop-information.heading')}
      subTitle={t('onboarding.crop-information.description')}
      submit={{
        text: t('general.next'),
        buttonType: 'submit',
        buttonFormId: formId.current,
        disabled: cropLoading || isSaving,
        isSaving,
      }}
      back={{ text: t('general.back'), onClick: onBack }}
      loadingQuery={cropLoading || cropOptionsLoading}
    >
      <div className={cx(styles['crop-information'])}>
        <h1 className={cx(styles['crop-information__heading'])}>
          {t('onboarding.crop-information.total-farm-acres')}
        </h1>
        <p className={cx(styles['crop-information__description'])}>
          {t('onboarding.crop-information.total-farm-acres-description')}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} id={formId.current} noValidate>
          <div className={cx(styles['crop-information__field-wrapper'])}>
            <Controller
              control={control}
              name="selectAcres"
              rules={{ required: t('form.errors.required') }}
              render={({ field: { onChange, value, name } }) => (
                <Dropdown
                  name={name}
                  value={acreOptions.find((option) => option.value === value)}
                  label={t('form.labels.select-acres')}
                  className={cx(styles['crop-information__field'], {
                    [styles['crop-information__field--multiple']]:
                      watchCustomOption === AcresWest.OPTION_6,
                  })}
                  options={acreOptions}
                  DropdownIndicatorIcon={ArrowDropDownIcon}
                  searchable={false}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  hasError={!!errors.selectAcres}
                  helperText={errors.selectAcres?.message}
                />
              )}
            />
            {watchCustomOption === AcresWest.OPTION_6 && (
              <TextField
                id={customAcrageId.current}
                className={cx(
                  styles['crop-information__field'],
                  styles['crop-information__field--custom']
                )}
                inputType="text"
                label={t('form.labels.acres-amount')}
                {...register('customAcres', {
                  required: t('form.errors.whole-number'),
                  validate: (val) => isNaturalNumber(val),
                  onChange: ({ currentTarget }) => {
                    const { value } = currentTarget;
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.value = value.replace(/\D+/g, '');
                  },
                })}
                hasError={!!errors.customAcres}
                helperText={errors.customAcres ? t('form.errors.whole-number') : undefined}
                inputMode="numeric"
              />
            )}
          </div>
          <hr className={cx(styles['crop-information__line'])}></hr>
          <h2 className={cx(styles['crop-information__heading'])}>
            {t('onboarding.crop-information.crop-details')}
          </h2>
          <p className={cx(styles['crop-information__description'])}>
            {t('onboarding.crop-information.crop-details-description')}
          </p>
          <ul
            className={cx(
              styles['crop-information__crops-wrapper'],
              styles['crop-information__crops-wrapper--grains']
            )}
          >
            {renderCheckboxes(crops)}
          </ul>
          <div>
            {fruits.length > 0 && (
              <Accordion
                title={t('crops.OTHERTREEFRUIT')}
                classNames={{
                  container: cx(styles['crop-information__accordion']),
                }}
              >
                <ul className={cx(styles['crop-information__crops-wrapper'])}>
                  {renderCheckboxes(fruits)}
                </ul>
              </Accordion>
            )}
            {vegetables.length > 0 && (
              <Accordion title={t('crops.OTHERVEGETABLES')}>
                <ul className={cx(styles['crop-information__crops-wrapper'])}>
                  {renderCheckboxes(vegetables)}
                </ul>
              </Accordion>
            )}
          </div>
        </form>
      </div>
    </StepsWrapper>
  );
};

CropInformation.displayName = 'CropInformation';

export default CropInformation;
