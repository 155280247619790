import { createContext, FC, useState } from 'react';
import { FilterContextProps, FilterContextType } from './FilterContext.types';
import { useEventListener } from '../../hooks';
import { VALUE_ESCAPE } from 'keycode-js';

const FilterContext = createContext<FilterContextType>([null, () => {}]);

export const FilterProvider: FC<FilterContextProps> = ({ children }) => {
  const value = useState<string | null | undefined>(null);

  useEventListener('keydown', (e: any) => {
    const [currentFilterId, setCurrentFilterId] = value;
    if (currentFilterId !== undefined) {
      const { key } = e;

      if (key === VALUE_ESCAPE) {
        setCurrentFilterId(undefined);
      }
    }
    return undefined;
  });
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterContext;
