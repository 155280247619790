import { gql } from '../../__generated__';

export const GET_RETAILERS = gql(`
  query GetRetailers($filterBy: GetRetailersInput!) {
    getRetailers(filterBy: $filterBy) {
      retailers {
        id
        name
        alias
        address {
          address
          address2
          city
          province
          postalCode
        }
        contacts {
          id
          firstName
          lastName
          isPrimary
          profilePictureUrl
        }
      }
      totalCount
    }
  }
`);

export const GET_PREFERRED_RETAILERS = gql(`
  query GetPreferredRetailers {
    user {
      accountInfo {
        id
      }
      retailers {
        id
        name
        alias
        phone
        mobilePhone
        address {
          address
          address2
          city
          province
          postalCode
        }
        contacts {
          id
          firstName
          lastName
          isPrimary
          email
          profilePictureUrl
        }
      }
    }
  }
`);

export const REMOVE_PREFERRED_RETAILER = gql(`
  mutation RemovePreferredRetailers($input: RemovePreferredRetailersInput!) {
    removePreferredRetailers(input: $input) {
      retailers {
        id
      }
    }
  }
`);

export const ADD_PREFERRED_RETAILER = gql(`
  mutation AddPreferredRetailers($input: AddPreferredRetailersInput!) {
    addPreferredRetailers(input: $input) {
      retailers {
        id
      }
    }
  }
`);

export const GET_ALL_RETAILERS_WITH_PREFERRED = gql(`
query RetailersWithPreferred($filterBy: GetRetailersInput!) {
  user {
    retailers {
      id
    }
    accountInfo {
      id
    }
  }
  getRetailers(filterBy: $filterBy) {
    retailers {
      id
        name
        alias
        address {
          address
          address2
          city
          province
          postalCode
        }
        contacts {
          id
          firstName
          lastName
          isPrimary
          profilePictureUrl
        }
    }
    totalCount
  }
}`);
