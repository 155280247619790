import OrderChartContext from './OrderChartContext';
import { OrderChartContextType } from './OrderCharContext.types';
import { useContext } from 'react';

const useOrderChartContext = (): OrderChartContextType => {
  const context = useContext(OrderChartContext);

  if (context === undefined) {
    throw new Error('useOrderChartContext must be used within a OrderChartProvider');
  }

  return context;
};

export default useOrderChartContext;
