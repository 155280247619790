import { Color } from '../../lib/constants/charts';

export const MOCK_PRODUCTS_SELECTED = [
  { value: '1', label: 'SmartStax® PRO RIB Complete® Corn' },
  { value: '2', label: 'Roundup Ready® Corn' },
  { value: '3', label: 'Roundup Ready® Corn 2' },
];

export const MOCK_PRODUCTS = [
  {
    label: MOCK_PRODUCTS_SELECTED[0].label,
    amount: 1000,
  },
  {
    label: MOCK_PRODUCTS_SELECTED[1].label,
    amount: 1000,
  },
  {
    label: MOCK_PRODUCTS_SELECTED[2].label,
    amount: 1000,
  },
];

export const MOCK_CHART_DATA = [
  { color: Color.DARK_BLUE, amount: 2100, programName: 'Seed Treatment' },
  { color: Color.GREEN, amount: 4200, programName: 'Herbicide' },
  { color: Color.PINK, amount: 700, programName: 'Fungicide' },
];
