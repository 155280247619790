import { CalculatorCategory, ProductMainGroup } from '../types/calculator';

import barleyImage from '../../assets/images/crops/barley.jpeg';
import canolaImage from '../../assets/images/crops/canola.jpeg';
import cerealImage from '../../assets/images/crops/cereal.jpeg';
import cornImage from '../../assets/images/crops/corn.jpeg';
import fruitImage from '../../assets/images/crops/fruits.jpeg';
import oilseedImage from '../../assets/images/crops/oilseed.jpeg';
import pulseImage from '../../assets/images/crops/pulse.jpeg';
import soybeanImage from '../../assets/images/crops/soybean.jpeg';
import { TFunction } from 'i18next';
import wheatImage from '../../assets/images/crops/wheat.jpeg';

export const BASE_PRODUCT_CATEGORIES_ARRAY = [
  {
    key: ProductMainGroup.SEED_TREATMENT,
    title: 'bayer-rewards-calculator.select-products.categories.seed-treatment.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.seed-treatment.subtitle',
    products: [],
  },
  {
    key: ProductMainGroup.HERBICIDES,
    title: 'bayer-rewards-calculator.select-products.categories.herbicide.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.herbicide.subtitle',
    products: [],
  },
  {
    key: ProductMainGroup.FUNGICIDES,
    title: 'bayer-rewards-calculator.select-products.categories.fungicide.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.fungicide.subtitle',
    products: [],
  },
];

export const FULL_PRODUCT_CATEGORIES_ARRAY: CalculatorCategory[] = [
  ...BASE_PRODUCT_CATEGORIES_ARRAY,
  {
    key: ProductMainGroup.TRAIT,
    title: 'bayer-rewards-calculator.select-products.categories.traits.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.traits.subtitle',
    products: [],
  },
];

export const cropList = (t: TFunction) => [
  {
    label: t('crops.BARLEY'),
    value: 'Barley',
    image: barleyImage,
  },
  {
    label: t('crops.CANOLA'),
    value: 'Canola',
    image: canolaImage,
  },
  {
    label: t('crops.CORN'),
    value: 'Corn',
    image: cornImage,
  },
  {
    label: t('crops.OTHERBEAN'),
    value: 'Pulses',
    image: pulseImage,
  },
  {
    label: t('crops.SOYBEANS'),
    value: 'Soybeans',
    image: soybeanImage,
  },
  {
    label: t('crops.WHEAT'),
    value: 'Wheat',
    image: wheatImage,
  },
  {
    label: t('crops.CEREALS'),
    value: 'Cereals',
    image: cerealImage,
  },
  {
    label: t('crops.FRUITSANDVEG'),
    value: 'Fruit & Vegetable',
    image: fruitImage,
  },
  {
    label: t('crops.OILSEED'),
    value: 'Oilseeds',
    image: oilseedImage,
  },
];
