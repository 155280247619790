import { ButtonThemes, IconPosition } from '../../../../../lib/constants/components';
import { CartProduct, ShoppingCart } from '../../../../../__generated__/graphql';

import Avatar from '../../../../../components/_shared/Avatar/Avatar';
import Button from '../../../../../components/_shared/Button/Button';
import cx from 'classnames';
import { FC } from 'react';
import { formatRetailerName } from '../../../../../lib/utils/retailers';
import OrderContainerSkeleton from './Skeleton';
import OrderProduct from '../OrderProduct';
import { OrderProductTheme } from '../../../../../lib/constants/pre-orders';
import { SpinnerIcon } from '../../../../../assets/icons';
import styles from './OrderContainer.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  shoppingCart: ShoppingCart;
  showSubmitButton?: boolean;
  orderSubmitted?: boolean;
  onEdit?: (cartItem: CartProduct) => void;
  onSubmit?: (cartIds: string[]) => void;
  isLoading?: boolean;
  submittingIds?: string[];
  theme?: OrderProductTheme;
}

const OrderContainer: FC<Props> = ({
  shoppingCart,
  orderSubmitted = false,
  onEdit,
  onSubmit,
  isLoading,
  showSubmitButton = false,
  submittingIds = [],
  theme = OrderProductTheme.CARD,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['order-container'])}>
      {isLoading || shoppingCart.cartProducts.length < 1 ? (
        <OrderContainerSkeleton />
      ) : (
        <>
          <div className={cx(styles['order-container__header'])}>
            <div className={cx(styles['order-container__retailer'])}>
              <Avatar
                profilePictureUrl=""
                firstName={formatRetailerName(t, shoppingCart.retailer)}
                lastName=""
                className={cx(styles['order-container__retailer-avatar'])}
              />
              <div className={cx(styles['order-container__retailer-info'])}>
                <span className={cx(styles['order-container__retailer-name'])}>
                  {formatRetailerName(t, shoppingCart.retailer)}
                </span>
                <span className={cx(styles['order-container__retailer-location'])}>
                  {shoppingCart.retailer.address?.city}, {shoppingCart.retailer.address?.province}
                </span>
              </div>
            </div>
            {!orderSubmitted && showSubmitButton && (
              <Button
                type="button"
                theme={ButtonThemes.SECONDARY}
                className={cx(styles['order-container__submit-order'])}
                onClick={() => {
                  onSubmit?.([shoppingCart.id]);
                }}
                disabled={submittingIds.length > 0}
                icon={submittingIds.includes(shoppingCart.id) ? SpinnerIcon : undefined}
                iconPosition={IconPosition.LEFT}
                iconClassName={cx({
                  [styles['order-container__submit-order--loading']]: submittingIds.includes(
                    shoppingCart.id
                  ),
                })}
              >
                {t('order-placement.pre-order.submit-pre-order')}
              </Button>
            )}
          </div>
          <div className={cx(styles['order-container__pre-orders'])}>
            {shoppingCart.cartProducts.map((cartProduct) => (
              <OrderProduct
                cartProduct={cartProduct}
                onEdit={onEdit}
                orderSubmitted={orderSubmitted}
                key={cartProduct.id}
                submittingIds={submittingIds}
                theme={theme}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

OrderContainer.displayName = 'OrderContainer';

export default OrderContainer;
