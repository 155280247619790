import {
  ChevronDownIcon,
  ChevronRightIcon,
  FarmIcon,
  FilterIcon,
  IdentityIcon,
  LockOutlineIcon,
  StoreIcon,
} from '../../../../assets/icons';
import { FC, ReactNode, SVGProps, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import cx from 'classnames';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { PageRoutes } from '../../../../lib/constants/react-router';
import styles from './SettingsNavigation.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import { useQuery } from '@apollo/client';

type NavigationItem = {
  path: string;
  translationKey: string;
  icon: FC<SVGProps<SVGSVGElement>>;
};

const NavigationItems: NavigationItem[] = [
  {
    path: `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_PROFILE}`,
    translationKey: 'account-settings.nav.profile',
    icon: IdentityIcon,
  },
  {
    path: `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_OPERATION}`,
    translationKey: 'account-settings.nav.my-operation',
    icon: FarmIcon,
  },
  {
    path: `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_PREFERRED_RETAILER}`,
    translationKey: 'account-settings.nav.my-preferred-retailer',
    icon: StoreIcon,
  },
  {
    path: `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_USER_PERMISSION}`,
    translationKey: 'account-settings.nav.user-permissions',
    icon: FilterIcon,
  },
  {
    path: `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_SECURITY}`,
    translationKey: 'account-settings.nav.security',
    icon: LockOutlineIcon,
  },
];

const getCurrentNavigationItem = (t: TFunction, pathname: string): ReactNode => {
  const currentItem = NavigationItems.find((item) => item.path === pathname);

  if (!currentItem) {
    return null;
  }

  return (
    <>
      <currentItem.icon className={cx(styles['settings-navigation__icon'])} aria-hidden="true" />
      {t(currentItem.translationKey)}
    </>
  );
};

export interface Props {}

const SettingsNavigation: FC<Props> = () => {
  const { data: user } = useQuery(GET_USER_IS_ADMIN_QUERY);
  const { isMd } = useBreakpoint();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen]);

  return (
    <div className={cx(styles['settings-navigation'])}>
      <div>
        <button
          type="button"
          onClick={() => {
            setMenuOpen((prev) => !prev);
          }}
          className={cx(styles['settings-navigation__toggle'])}
        >
          <span className={cx(styles['settings-navigation__icon-text'])}>
            {getCurrentNavigationItem(t, pathname)}
          </span>
          <ChevronDownIcon
            className={cx(
              styles['settings-navigation__chevron'],
              styles['settings-navigation__chevron--active'],
              {
                [styles['settings-navigation__chevron--open']]: menuOpen,
              }
            )}
            aria-hidden="true"
          />
        </button>
      </div>
      <div
        className={cx(styles['settings-navigation__nav'], {
          [styles['settings-navigation__nav--hidden']]: isMd && !menuOpen,
        })}
      >
        <nav aria-label={t('account-settings.nav.alt')}>
          <ul>
            {NavigationItems.filter(
              (item) =>
                user?.user.accountInfo.isAdmin ||
                (!user?.user.accountInfo.isAdmin &&
                  item.path !==
                    `/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_USER_PERMISSION}`)
            ).map((item) => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    cx(styles['settings-navigation__link'], {
                      [styles['settings-navigation__link--active']]: isActive,
                    })
                  }
                  onClick={() => {
                    if (menuOpen) {
                      setMenuOpen(false);
                    }
                  }}
                >
                  <span className={cx(styles['settings-navigation__icon-text'])}>
                    <item.icon className={cx(styles['settings-navigation__icon'])} />
                    {t(item.translationKey)}
                  </span>
                  <ChevronRightIcon
                    className={cx(styles['settings-navigation__chevron'])}
                    aria-hidden="true"
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

SettingsNavigation.displayName = 'SettingsNavigation';

export default SettingsNavigation;
