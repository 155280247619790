export const firstLoginHandler = (e: any) => {
  const application = location.hostname;
  let apps = e.data.bc_accessApplications;
  const timestamp = new Date().toISOString();
  const metaData = { hostname: application, firstLogin: timestamp, lastLogin: timestamp };

  if (!apps) {
    // if application list empty, create new json object
    apps = [JSON.stringify(metaData)];
  } else {
    let isNewApp = true;
    // is app new or already in apps list
    for (let i = 0; i < apps.length; i += 1) {
      const host = JSON.parse(apps[i]).hostname;
      if (host === application) {
        isNewApp = false;
        const data = JSON.parse(apps[i]);
        data.lastLogin = timestamp;
        apps[i] = JSON.stringify(data);
        break;
      }
    }

    if (isNewApp) {
      // app is new -> new entry in array
      apps.push(JSON.stringify(metaData));
    }
  }
  window.gigya.accounts.setAccountInfo({ data: { bc_accessApplications: apps } });
};

export const loadGigyaScreenSet = () => {
  const newNonce = Date.now();
  const authorizeEndpointLink = `https://fidm.eu1.gigya.com/oidc/op/v1.0/${process.env.REACT_APP_GIGYA_API_KEY}/authorize?client_id=${process.env.REACT_APP_GIGYA_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_GIGYA_LOGIN_URL}&scope=openid%20email&nonce=${newNonce}`;
  window.location = authorizeEndpointLink as unknown as Location;
};
