import { Address, PaymentPreferenceOptions } from '../../../../../__generated__/graphql';
import { BellIcon, InfoOutlineIcon } from '../../../../../assets/icons';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  maskPostalCode,
  unmaskPostalCode,
  Validations,
} from '../../../../../lib/utils/form-validations';
import { Trans, useTranslation } from 'react-i18next';
import AutocompleteWrapper from '../../../../../components/AutocompleteWrapper';
import { CharacterLimit } from '../../../../../lib/constants/character-limit';
import Checkbox from '../../../../../components/_shared/Checkbox';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../../lib/constants/react-select';
import { FarmInfoExtended } from '../../../../../lib/models/Farm.model';
import { GET_FARM_PAYMENT_PREFERENCES } from '../../../../../lib/graphql/FarmInformation.gql';
import { getProvinceDropdownOptions } from '../../../../../lib/utils/onboarding';
import { handleEnterKey } from '../../../../../lib/constants/google-maps';
import InfoBanner from '../../../../../components/_shared/InfoBanner';
import Link from '../../../../../components/_shared/Link';
import { LinkUrls } from '../../../../../lib/constants/react-router';
import Radio from '../../../../../components/_shared/Radio';
import styles from './FarmInformation.module.scss';
import TextField from '../../../../../components/_shared/TextField';
import { useQuery } from '@apollo/client';

interface Props {
  control: Control<FarmInfoExtended>;
  register: UseFormRegister<FarmInfoExtended>;
  unregister: UseFormUnregister<FarmInfoExtended>;
  errors: FieldErrors<FarmInfoExtended>;
  watch: UseFormWatch<FarmInfoExtended>;
  setValue: UseFormSetValue<FarmInfoExtended>;
  placesApiLoaded: boolean;
  isParentFarm: boolean;
  isColony: boolean;
}

const PaymentPreferences: FC<Props> = ({
  register,
  unregister,
  control,
  errors,
  watch,
  setValue,
  isParentFarm,
  isColony,
  placesApiLoaded,
}) => {
  const { t } = useTranslation();
  const { data: paymentPreferenceData } = useQuery(GET_FARM_PAYMENT_PREFERENCES);
  const [provinces, setProvinces] = useState<DropdownOption[]>(getProvinceDropdownOptions(t));
  const paymentPreferencePreferred = watch('paymentPreference.preferredOption');
  const paymentPreferenceDataInitialState = { ...paymentPreferenceData };
  const requirePaymentPreference = isParentFarm && !isColony;

  useEffect(() => {
    setProvinces(getProvinceDropdownOptions(t));
  }, [t]);

  const validateMail = useMemo(
    () => paymentPreferencePreferred === PaymentPreferenceOptions.Mail && requirePaymentPreference,
    [paymentPreferencePreferred, requirePaymentPreference]
  );

  const validateEft = useMemo(
    () => paymentPreferencePreferred === PaymentPreferenceOptions.Eft && requirePaymentPreference,
    [paymentPreferencePreferred, requirePaymentPreference]
  );

  useEffect(() => {
    switch (paymentPreferencePreferred) {
      case PaymentPreferenceOptions.Mail:
        unregister([
          'paymentPreference.directDepositEmail',
          'paymentPreference.emailAccepted',
          'paymentPreference.exchangeAccepted',
        ]);
        break;
      case PaymentPreferenceOptions.Eft:
        unregister([
          'paymentPreference.chequePayeeName',
          'paymentPreference.mailingAddress',
          'paymentPreference.exchangeAccepted',
        ]);
        break;
      case PaymentPreferenceOptions.Exchange:
        unregister([
          'paymentPreference.directDepositEmail',
          'paymentPreference.emailAccepted',
          'paymentPreference.chequePayeeName',
          'paymentPreference.mailingAddress',
        ]);
        break;
      default:
        break;
    }
  }, [unregister, paymentPreferencePreferred]);

  const handleAutocompleteFields = (address: Address) => {
    setValue('paymentPreference.mailingAddress', address, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const renderAddressField = (): ReactNode => (
    <TextField
      className={cx(styles['form-input'], {
        [styles['form-input--error']]: !!errors.paymentPreference?.mailingAddress?.address,
      })}
      disabled={!requirePaymentPreference}
      label={t('form.labels.address')}
      {...register('paymentPreference.mailingAddress.address', {
        ...(validateMail && { required: t('form.errors.required') }),
        ...(validateMail && Validations.characterLimit(t, CharacterLimit.ADDRESS)),
      })}
      hasError={!!errors?.paymentPreference?.mailingAddress?.address}
      helperText={errors?.paymentPreference?.mailingAddress?.address?.message}
      onKeyDown={handleEnterKey}
    />
  );

  const getRadioButtonDescription = (option: PaymentPreferenceOptions) => {
    switch (option) {
      case PaymentPreferenceOptions.Eft:
        return (
          paymentPreferenceData?.farm.farmInfo.paymentPreference.directDepositEmail ||
          t('general.pending-details')
        );
      case PaymentPreferenceOptions.Exchange:
        if (
          paymentPreferenceDataInitialState?.farm?.farmInfo.paymentPreference.preferredOption !==
          PaymentPreferenceOptions.Exchange
        ) {
          return t('general.pending-details');
        } else if (paymentPreferenceData?.farm.farmInfo.paymentPreference.paymentExchangeApproved) {
          return t('general.approved');
        }
        return t('general.awaiting-approval');
      case PaymentPreferenceOptions.Mail:
        if (paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress)
          return t('format.full-address', {
            address:
              paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.address,
            city: paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.city,
            province:
              paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.province,
            postalCode: maskPostalCode(
              paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress
                ?.postalCode || ''
            ),
          });
        return t('general.pending-details');
      default:
        return t('general.pending-details');
    }
  };

  return (
    <div>
      {!requirePaymentPreference && (
        <InfoBanner
          title={
            isColony
              ? t('onboarding.payment-preferences.colony-banner')
              : t('onboarding.payment-preferences.child-farm-banner')
          }
          className={cx(styles['farm-information__banner'])}
          closeButton={false}
          icon={InfoOutlineIcon}
        />
      )}
      <div className={cx(styles['farm-information__radio-group'])}>
        <Controller
          control={control}
          name="paymentPreference.preferredOption"
          rules={requirePaymentPreference ? { required: t('form.errors.required') } : {}}
          render={({ field: { onChange, value, name } }) => (
            <Radio
              className={cx(styles['farm-information__radio'])}
              label={t('form.labels.mailed-cheque-default')}
              value={PaymentPreferenceOptions.Mail}
              name={name}
              onChange={onChange}
              checked={value === PaymentPreferenceOptions.Mail}
              disabled={!requirePaymentPreference}
              description={getRadioButtonDescription(PaymentPreferenceOptions.Mail)}
            />
          )}
        />
        <div
          className={cx(styles['farm-information__preference'], {
            [styles['farm-information__preference--hidden']]:
              paymentPreferencePreferred !== PaymentPreferenceOptions.Mail,
          })}
        >
          <div className={cx(styles['form-row'])}>
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.paymentPreference?.chequePayeeName,
              })}
              disabled={!requirePaymentPreference}
              label={t('form.labels.cheque-payee-name')}
              {...register('paymentPreference.chequePayeeName', {
                ...(validateMail && { required: t('form.errors.required') }),
                ...(validateMail && Validations.characterLimit(t, CharacterLimit.NAME)),
              })}
              hasError={!!errors?.paymentPreference?.chequePayeeName}
              helperText={errors?.paymentPreference?.chequePayeeName?.message}
              icon={InfoOutlineIcon}
              iconClassName={cx(styles['info-icon'])}
              tooltip={t('onboarding.profile-details.cheque-payee-tooltip')}
            />
          </div>
          <div className={cx(styles['form-row'])}>
            {placesApiLoaded ? (
              <AutocompleteWrapper handleAutocompleteFields={handleAutocompleteFields}>
                {renderAddressField()}
              </AutocompleteWrapper>
            ) : (
              renderAddressField()
            )}
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.paymentPreference?.mailingAddress?.address2,
              })}
              disabled={!requirePaymentPreference}
              label={t('form.labels.address2')}
              {...register('paymentPreference.mailingAddress.address2', {
                ...(validateMail && Validations.characterLimit(t, CharacterLimit.ADDRESS)),
              })}
              hasError={!!errors?.paymentPreference?.mailingAddress?.address2}
              helperText={errors?.paymentPreference?.mailingAddress?.address2?.message}
            />
          </div>
          <div className={cx(styles['form-row'])}>
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.paymentPreference?.mailingAddress?.city,
              })}
              disabled={!requirePaymentPreference}
              label={t('form.labels.city')}
              {...register('paymentPreference.mailingAddress.city', {
                ...(validateMail && { required: t('form.errors.required') }),
                ...(validateMail && Validations.characterLimit(t, CharacterLimit.CITY)),
              })}
              hasError={!!errors?.paymentPreference?.mailingAddress?.city}
              helperText={errors?.paymentPreference?.mailingAddress?.city?.message}
            />
            <div className={cx(styles['form-nested-row'])}>
              <Controller
                control={control}
                name="paymentPreference.mailingAddress.province"
                rules={{
                  ...(validateMail && { required: t('form.errors.required') }),
                }}
                render={({ field: { onChange, value, name } }) => (
                  <Dropdown
                    className={cx(styles['form-input'], {
                      [styles['form-input--dropdown']]:
                        !!errors.paymentPreference?.mailingAddress?.province,
                    })}
                    isDisabled={!requirePaymentPreference}
                    name={name}
                    value={provinces.find((p) => p.value === value)}
                    onChange={onChange}
                    label={t('form.labels.province')}
                    options={provinces}
                    hasError={!!errors?.paymentPreference?.mailingAddress?.province}
                    helperText={errors?.paymentPreference?.mailingAddress?.province?.message}
                  />
                )}
              />
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]:
                    !!errors.paymentPreference?.mailingAddress?.postalCode,
                })}
                disabled={!requirePaymentPreference}
                label={t('form.labels.postal-code')}
                {...register('paymentPreference.mailingAddress.postalCode', {
                  ...(validateMail && { required: t('form.errors.required') }),
                  ...(validateMail && Validations.postalCode(t('form.labels.postal-code'), t)),
                  onChange: ({ currentTarget }) => {
                    const { value } = currentTarget;
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.value = maskPostalCode(value);
                  },
                  setValueAs: (v) => unmaskPostalCode(maskPostalCode(v)),
                })}
                hasError={!!errors?.paymentPreference?.mailingAddress?.postalCode}
                helperText={errors?.paymentPreference?.mailingAddress?.postalCode?.message}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles['farm-information__radio-group'])}>
        <Controller
          control={control}
          name="paymentPreference.preferredOption"
          rules={requirePaymentPreference ? { required: t('form.errors.required') } : {}}
          render={({ field: { onChange, value, name } }) => (
            <Radio
              className={cx(styles['farm-information__radio'])}
              disabled={!requirePaymentPreference}
              label={t('form.labels.direct-deposit')}
              value={PaymentPreferenceOptions.Eft}
              name={name}
              onChange={onChange}
              checked={value === PaymentPreferenceOptions.Eft}
              description={getRadioButtonDescription(PaymentPreferenceOptions.Eft)}
            />
          )}
        />
        <div
          className={cx(styles['farm-information__preference'], {
            [styles['farm-information__preference--hidden']]:
              paymentPreferencePreferred !== PaymentPreferenceOptions.Eft,
          })}
        >
          <InfoBanner
            title={t('account-settings.my-operation.payment-preferences.eft.description')}
            closeButton={false}
            className={cx(styles['farm-information__banner'])}
            icon={BellIcon}
          />
          <div className={cx(styles['form-row'])}>
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.paymentPreference?.directDepositEmail,
              })}
              disabled={!requirePaymentPreference}
              label={t('form.labels.direct-deposit-email')}
              {...register('paymentPreference.directDepositEmail', {
                ...(validateEft && Validations.email(t('form.labels.email'), t)),
                ...(validateEft && { required: t('form.errors.required') }),
                ...(validateEft && Validations.characterLimit(t, CharacterLimit.EMAIL)),
              })}
              hasError={!!errors?.paymentPreference?.directDepositEmail}
              helperText={errors?.paymentPreference?.directDepositEmail?.message}
              icon={InfoOutlineIcon}
              tooltip={t('account-settings.my-operation.payment-preferences.eft.tooltip')}
            />
          </div>
          <Checkbox
            {...register('paymentPreference.emailAccepted', {
              ...(validateEft && Validations.emailAccepted(t, paymentPreferencePreferred)),
            })}
            disabled={!requirePaymentPreference}
            value="paymentPreference.emailAccepted"
            label={t('account-settings.my-operation.payment-preferences.eft.checkbox')}
            hasError={!!errors.paymentPreference?.emailAccepted}
          />
        </div>
      </div>
      <div className={cx(styles['farm-information__radio-group'])}>
        <Controller
          control={control}
          name="paymentPreference.preferredOption"
          rules={requirePaymentPreference ? { required: t('form.errors.required') } : {}}
          render={({ field: { onChange, value, name } }) => (
            <Radio
              className={cx(styles['farm-information__radio'])}
              disabled={!requirePaymentPreference}
              label={t('form.labels.payment-exchange')}
              value={PaymentPreferenceOptions.Exchange}
              name={name}
              onChange={onChange}
              checked={value === PaymentPreferenceOptions.Exchange}
              description={getRadioButtonDescription(PaymentPreferenceOptions.Exchange)}
            />
          )}
        />
        <div
          className={cx(styles['farm-information__preference'], {
            [styles['farm-information__preference--hidden']]:
              paymentPreferencePreferred !== PaymentPreferenceOptions.Exchange,
          })}
        >
          <p className={cx(styles['farm-information__body'])}>
            <Trans
              i18nKey="account-settings.my-operation.payment-preferences.pe.description"
              components={[
                <Link href={LinkUrls.PAYMENT_EXCHANGE} target="_blank" rel="noopener noreferrer" />,
              ]}
            />
          </p>
          <Checkbox
            {...register('paymentPreference.exchangeAccepted', {
              ...(requirePaymentPreference &&
                Validations.exchangeAccepted(t, paymentPreferencePreferred)),
            })}
            disabled={!requirePaymentPreference}
            value="paymentPreference.exchangeAccepted"
            label={t('account-settings.my-operation.payment-preferences.pe.checkbox')}
            hasError={!!errors.paymentPreference?.exchangeAccepted}
          />
          <InfoBanner
            title={t('account-settings.my-operation.payment-preferences.pe.disclaimer')}
            closeButton={false}
            className={cx(styles['farm-information__banner--last'])}
            icon={BellIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPreferences;
