import { forwardRef, ReactElement, useImperativeHandle, useRef } from 'react';
import cx from 'classnames';
import styles from './GetStartedCarousel.module.scss';

export interface Props {
  introVideoSrc: string;
  outroVideoSrc: string;
  heading: string | ReactElement;
  description: string | ReactElement;
  insideModal?: boolean;
}

export interface GetStartedSlideHandle {
  introVideo: HTMLVideoElement | null;
  outroVideo: HTMLVideoElement | null;
}

const GetStartedSlide = forwardRef<GetStartedSlideHandle, Props>(
  ({ introVideoSrc, outroVideoSrc, heading, description, insideModal = false }, ref) => {
    const introVideoRef = useRef<HTMLVideoElement>(null);
    const outroVideoRef = useRef<HTMLVideoElement>(null);

    useImperativeHandle(ref, () => ({
      get introVideo() {
        return introVideoRef.current;
      },
      get outroVideo() {
        return outroVideoRef.current;
      },
    }));

    return (
      <div className={cx(styles['gss'])}>
        <div className={cx(styles['gss__inner-wrapper'])}>
          <div
            className={cx(styles['gss__video-wrapper'], {
              [styles['gss__video-wrapper--modal']]: insideModal,
            })}
          >
            <video
              ref={introVideoRef}
              className={cx(styles['gss__video'])}
              src={introVideoSrc}
              muted
              playsInline
            ></video>
            <video
              ref={outroVideoRef}
              className={cx(styles['gss__video'])}
              src={outroVideoSrc}
              muted
              playsInline
            ></video>
          </div>
        </div>
        <h1 className={cx(styles['gss__heading'])}>{heading}</h1>
        <p className={cx(styles['gss__description'])}>{description}</p>
      </div>
    );
  }
);

GetStartedSlide.displayName = 'GetStartedCarousel';

export default GetStartedSlide;
