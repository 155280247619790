import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const usePageTitle = (titleKey?: string) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (titleKey) {
      document.title = `${t('page-title.app-name')} ${t('page-title.separator')} ${t(
        `page-title.pages.${titleKey}`
      )}`;
    } else {
      document.title = `${t('page-title.app-name')}`;
    }
  }, [titleKey, t]);
};
