import { EmailOutlineIcon, PhoneIcon, WorldWideWebIcon } from '../../../assets/icons';
import { FC, useEffect, useMemo } from 'react';
import { LinkUrls, PageRoutes, TelephoneLink } from '../../../lib/constants/react-router';

import ContactUsSkeleton from './Skeleton/ContactUsSkeleton';
import cx from 'classnames';
import { formatContacts } from '../../../lib/utils/contacts';
import { GET_SUPPORT_CONTACTS } from '../../../lib/graphql/SupportContact.gql';
import { IconPosition } from '../../../lib/constants/components';
import Link from '../../_shared/Link';
import { LocalTeamMember } from '../../../lib/models/LocalTeam.model';
import { maskPhone } from '../../../lib/utils/form-validations';
import { Link as RouterLink } from 'react-router-dom';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../SnackbarContainer/Snackbar/Snackbar.types';
import styles from './ContactUs.module.scss';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {}

export const MAX_NUM_CONTACTS = 4;

const ContactUs: FC<Props> = () => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();

  const {
    loading: isLoading,
    data: supportContacts,
    error: supportContactError,
  } = useQuery(GET_SUPPORT_CONTACTS);

  useEffect(() => {
    if (supportContactError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, supportContactError, t]);

  const supportUsers = useMemo(
    () => formatContacts(supportContacts, t, MAX_NUM_CONTACTS),
    [supportContacts, t]
  );

  const showMoreButton = useMemo(
    () =>
      (supportContacts?.farm.supportContacts.length ?? 0) +
        (supportContacts?.user?.retailers.length ?? 0) >=
      MAX_NUM_CONTACTS,
    [supportContacts]
  );

  const supportUserCards = !isLoading ? (
    supportUsers?.map((contact: LocalTeamMember) => (
      <div key={contact.id} className={cx(styles['contact-us__user-wrapper'])}>
        <div className={cx(styles['contact-us__user'])}>
          <div className={cx(styles['contact-us__user-titles'])}>
            <p className={cx(styles['contact-us__user-subtitle'])}>{contact.role}</p>
            {contact.retailerName && (
              <p
                className={cx(
                  styles['contact-us__user-subtitle'],
                  styles['contact-us__user-subtitle--lowercase']
                )}
              >
                {contact.retailerName}
              </p>
            )}
            <p className={cx(styles['contact-us__user-title'])}>{contact.contactFullName}</p>
          </div>
        </div>
        <hr className={cx(styles['contact-us__support-line'])}></hr>
        <div className={cx(styles['contact-us__user-details'])}>
          {contact.email && (
            <Link
              href={`mailto:${contact.email}`}
              className={cx(styles['contact-us__user-detail'])}
            >
              <EmailOutlineIcon
                className={cx(styles['contact-us__icon'])}
                aria-label={t('contact-us.email')}
              />
              <span>{contact.email}</span>
            </Link>
          )}
          {contact.phone && (
            <Link
              href={TelephoneLink(contact.phone)}
              className={cx(styles['contact-us__user-detail'])}
            >
              <PhoneIcon
                className={cx(styles['contact-us__icon'])}
                aria-label={t('contact-us.phone')}
              />
              <span>{maskPhone(contact.phone)}</span>
            </Link>
          )}
          {contact.websiteUrl && (
            <Link href={contact.websiteUrl} className={cx(styles['contact-us__user-detail'])}>
              <WorldWideWebIcon
                className={cx(styles['contact-us__icon'])}
                aria-label={t('contact-us.website')}
              />
              <span>{contact.websiteUrl}</span>
            </Link>
          )}
        </div>
      </div>
    ))
  ) : (
    <ContactUsSkeleton />
  );

  return (
    <section className={cx(styles['contact-us'])}>
      <div className={cx(styles['contact-us__inner'])}>
        <div className={cx(styles['contact-us__support-description'])}>
          <div className={cx(styles['contact-us__header'])}>
            <h2 className={cx(styles['contact-us__title'])}>{t('contact-us.description')}</h2>
            <p className={cx(styles['contact-us__subtitle'])}>{t('contact-us.subtitle')}</p>
          </div>
          <ul className={cx(styles['contact-us__phone-information'])}>
            <li className={cx(styles['contact-us__phone'])}>
              <p className={cx(styles['contact-us__phone-title'])}>
                {t('contact-us.bayer-support.name')}
              </p>
              <Link
                href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL}
                className={cx(styles['contact-us__phone-number'])}
                icon={PhoneIcon}
                iconPosition={IconPosition.LEFT}
              >
                {t('contact-us.bayer-support.phone')}
              </Link>
            </li>
            <li className={cx(styles['contact-us__phone'])}>
              <p className={cx(styles['contact-us__phone-title'])}>
                {t('contact-us.climate-view-support.name')}
              </p>
              <Link
                href={LinkUrls.PHONE_FIELD_VIEW}
                className={cx(styles['contact-us__phone-number'])}
                icon={PhoneIcon}
                iconPosition={IconPosition.LEFT}
              >
                {t('contact-us.climate-view-support.phone')}
              </Link>
            </li>
          </ul>
        </div>
        <hr className={cx(styles['contact-us__line'])}></hr>
        <div className={cx(styles['contact-us__support'])}>
          {supportUserCards}
          {showMoreButton && (
            <div className={cx(styles['contact-us__link-container'])}>
              <Link
                as={RouterLink}
                altText={t('contact-us.edit-my-preferred-retailers')}
                to={`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_PREFERRED_RETAILER}`}
                className={cx()}
              >
                {t('contact-us.edit-my-preferred-retailers')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

ContactUs.displayName = 'ContactUs';

export default ContactUs;