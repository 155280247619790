import cx from 'classnames';
import { FC } from 'react';
import QuestionsTable from './QuestionsTable/QuestionsTable';
import { SectionsList } from '../../lib/constants/faq';
import styles from './FAQ.module.scss';
import { useTranslation } from 'react-i18next';

const FAQ: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['faq'])}>
      <h1 className={cx(styles['faq__title'])}>{t('faq.heading')}</h1>
      {SectionsList.map((section) => (
        <QuestionsTable questions={section.questions} title={section.title} />
      ))}
    </div>
  );
};

FAQ.displayName = 'FAQ';

export default FAQ;
