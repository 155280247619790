import { ButtonThemes, IconPosition, SpinnerSizes } from '../../../lib/constants/components';
import { FC, ReactNode, useMemo } from 'react';
import { getBayerValueProgram, getCalculatorUrl } from '../../../lib/utils/programs';
import {
  OrderDirection,
  PurchasePreOrderSortField,
  PurchaseProduct,
} from '../../../__generated__/graphql';

import { ChevronRightIcon } from '../../../assets/icons';
import cx from 'classnames';
import { GET_BAYER_VALUE_PROGRAM_QUERY } from '../../../lib/graphql/ProgramRewards.gql';
import LinkButton from '../../_shared/LinkButton';
import PurchaseTableLegend from '../../_shared/PurchaseTableLegend/PurchaseTableLegend';
import RecentPurchasesTable from '../RecentPurchasesTable';
import { Link as RouterLink } from 'react-router-dom';
import Spinner from '../../_shared/Spinner';
import styles from './RecentPurchases.module.scss';
import { useBreakpoint } from '../../../lib/hooks';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export interface Props {
  description: string | ReactNode;
  heading: string;
  isLoading: boolean;
  purchaseOrderField: PurchasePreOrderSortField;
  setPurchaseOrderField(sort: PurchasePreOrderSortField): void;
  purchaseOrderDirection: OrderDirection;
  recentPurchases: PurchaseProduct[];
  setPurchaseOrderDirection(order: OrderDirection): void;
  viewAllUrl: string;
  year: number;
}

const RecentPurchases: FC<Props> = ({
  description,
  heading,
  isLoading,
  purchaseOrderField,
  setPurchaseOrderField,
  purchaseOrderDirection,
  recentPurchases,
  setPurchaseOrderDirection,
  viewAllUrl,
  year,
}) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();
  const { data: bayerValueProgramData } = useQuery(GET_BAYER_VALUE_PROGRAM_QUERY, {
    variables: { input: { year } },
  });

  const calculatorUrl = useMemo(
    () => getCalculatorUrl(getBayerValueProgram(bayerValueProgramData)?.name, year),
    [year, bayerValueProgramData]
  );

  return (
    <section className={cx(styles['recent-purchases-section'])}>
      <div className={cx(styles['recent-purchases-section__wrapper'])}>
        {calculatorUrl && (
          <LinkButton
            className={cx(styles['section-header__button'])}
            theme={isXs ? ButtonThemes.PRIMARY : ButtonThemes.TEXT_LINK}
            as={RouterLink}
            to={calculatorUrl}
            icon={ChevronRightIcon}
            iconPosition={IconPosition.RIGHT}
          >
            {t('bayer-value.recent-purchases.calculator', { year })}
          </LinkButton>
        )}

        <div className={cx(styles['section-header'])}>
          <div>
            <span className={cx(styles['section-header__title'])}>{heading}</span>
            <span className={cx(styles['section-header__sub-title'])}>{description}</span>
          </div>
          <div>
            <LinkButton
              className={cx(styles['section-header__button'])}
              theme={ButtonThemes.SECONDARY}
              as={RouterLink}
              to={viewAllUrl}
              icon={ChevronRightIcon}
              iconPosition={IconPosition.RIGHT}
            >
              {t('general.view-all')}
            </LinkButton>
          </div>
        </div>
        <div className={cx(styles['legend-wrapper'])}>
          <PurchaseTableLegend />
        </div>
        {isLoading ? (
          <div className={cx(styles['spinner-wrapper'])}>
            <Spinner size={SpinnerSizes.LG} />
          </div>
        ) : (
          <RecentPurchasesTable
            recentPurchases={recentPurchases}
            setOrderDirection={setPurchaseOrderDirection}
            orderDirection={purchaseOrderDirection}
            setOrderField={setPurchaseOrderField}
            orderField={purchaseOrderField}
          />
        )}
      </div>
    </section>
  );
};

RecentPurchases.displayName = 'RecentPurchases';

export default RecentPurchases;
