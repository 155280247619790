import { ALLOWED_MIME_TYPES, MAX_BYTE_IMAGE_SIZE } from '../../../../../lib/constants/images';
import { ButtonThemes, ProfilePhotoSize } from '../../../../../lib/constants/components';
import Avatar from '../../../../../components/_shared/Avatar';
import { Avatar as AvatarIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/_shared/Button';
import cx from 'classnames';
import { Ref } from 'react';
import styles from './UploadPhoto.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  photoUrl: string;
  handlePhotoUpload: () => void;
  handlePhotoDelete: () => void;
  onPhotoChange: (e: any) => void;
  inputPhoto: Ref<HTMLInputElement> | null;
  firstName?: string;
  lastName?: string;
  photoSize?: ProfilePhotoSize;
}

const UploadPhoto = ({
  photoUrl,
  handlePhotoUpload,
  handlePhotoDelete,
  onPhotoChange,
  inputPhoto = null,
  firstName = '',
  lastName = '',
  photoSize = ProfilePhotoSize.MD,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['upload-photo'], styles[`upload-photo--${photoSize}`])}>
      {!photoUrl ? (
        <AvatarIcon
          className={cx(styles['upload-photo__photo'], styles[`upload-photo__photo--${photoSize}`])}
          aria-hidden="true"
        />
      ) : (
        <Avatar
          className={cx(styles['upload-photo__photo'], styles[`upload-photo__photo--${photoSize}`])}
          profilePictureUrl={photoUrl}
          firstName={firstName}
          lastName={lastName}
        />
      )}
      <div className={cx(styles['upload-photo__text-wrapper'])}>
        <p className={cx(styles['upload-photo__name'])}>{`${firstName} ${lastName}`}</p>
        <div className={cx(styles['upload-photo__button-wrapper'])}>
          <Button type="button" theme={ButtonThemes.TEXT_LINK} onClick={handlePhotoUpload}>
            {!photoUrl
              ? t(`form.labels.upload-profile-photo`)
              : t(`form.labels.change-profile-photo`)}
          </Button>
          {photoUrl && (
            <>
              <div className={cx(styles['upload-photo__separator-wrapper'])}>
                <div className={cx(styles['upload-photo__separator'])} />
              </div>
              <Button type="button" theme={ButtonThemes.TEXT_LINK} onClick={handlePhotoDelete}>
                {t(`form.labels.delete-profile-photo`)}
              </Button>
            </>
          )}
        </div>
      </div>
      <input
        type="file"
        accept={ALLOWED_MIME_TYPES}
        size={MAX_BYTE_IMAGE_SIZE}
        onChange={onPhotoChange}
        className={cx(styles['upload-photo__input'])}
        ref={inputPhoto}
      />
    </div>
  );
};

export default UploadPhoto;
