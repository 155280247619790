import cx from 'classnames';
import { FC } from 'react';
import styles from './HeroSkeleton.module.scss';

const HeroSkeleton: FC = () => (
  <div className={cx(styles['hero-skeleton'])}>
    <div className={cx(styles['hero-skeleton__wrapper'])}>
      <div className={cx(styles['hero-skeleton__welcome-wrapper'])}>
        <div
          className={cx(
            styles['hero-skeleton__loading'],
            styles['hero-skeleton__loading--heading']
          )}
        />
        <div
          className={cx(
            styles['hero-skeleton__loading'],
            styles['hero-skeleton__loading--subtitle-right']
          )}
        />
      </div>
      <hr className={cx(styles['hero-skeleton__line'])}></hr>
      <div className={cx(styles['hero-skeleton__rewards-wrapper'])}>
        <div
          className={cx(
            styles['hero-skeleton__loading'],
            styles['hero-skeleton__loading--heading']
          )}
        />
        <div
          className={cx(
            styles['hero-skeleton__loading'],
            styles['hero-skeleton__loading--subtitle-right']
          )}
        />
      </div>
    </div>
  </div>
);
HeroSkeleton.displayName = 'HeroSkeleton';

export default HeroSkeleton;
