import cx from 'classnames';
import { forwardRef } from 'react';
import { SearchIcon } from '../../../assets/icons';
import styles from './SearchBar.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
  onChange?(e?: any): void;
  onBlur?(e?: any): void;
  name: string;
}

const SearchBar = forwardRef<HTMLInputElement, Props>(
  ({ className, onBlur, onChange, name }, ref) => {
    const { t } = useTranslation();

    return (
      <div className={cx(styles['search-bar'], className)}>
        <input
          className={cx(styles['search-bar__input'])}
          type="search"
          placeholder={t('general.search')}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
        />
        <SearchIcon className={cx(styles['search-bar__icon'])} aria-hidden="true" />
      </div>
    );
  }
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
