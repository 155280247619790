import { CartEmptyIcon, SpinnerIcon } from '../../../assets/icons';
import { CartProduct, ShoppingCart } from '../../../__generated__/graphql';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  GET_SHOPPING_CARTS_QUERY,
  SUBMIT_CART_MUTATION,
} from '../../../lib/graphql/ShoppingCart.gql';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import Button from '../../../components/_shared/Button/Button';
import cx from 'classnames';
import { IconPosition } from '../../../lib/constants/components';
import Link from '../../../components/_shared/Link';
import OrderContainer from './components/OrderContainer';
import OrderContainerSkeleton from './components/OrderContainer/Skeleton/Skeleton';
import OrderProductSkeleton from './components/OrderProduct/Skeleton/Skeleton';
import { PageRoutes } from '../../../lib/constants/react-router';
import { PaginationProvider } from '../../../lib/contexts/pagination/PaginationContext';
import PreOrderHeader from './components/PreOrderHeader';
import PreOrderModal from './PreOrderModal/PreOrderModal';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './PreOrder.module.scss';
import TableEmptyState from '../../../components/TableEmptyState';
import { toGQLLanguage } from '../../../lib/utils/i18n';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';

export interface Props {}

const PreOrder: FC<Props> = () => {
  usePageTitle('pre-orders');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [productName, setProductName] = useState<string | null>();
  const [editCartItem, setEditCartItem] = useState<CartProduct>();
  const [submittedCarts, setSubmittedCarts] = useState<ShoppingCart[]>([]);

  const {
    data: shoppingCartData,
    loading: loadingShoppingCart,
    error: shoppingCartError,
  } = useQuery(GET_SHOPPING_CARTS_QUERY);
  const [submitPreOrder, { loading: submittingPreOrder }] = useMutation(SUBMIT_CART_MUTATION, {
    refetchQueries: [{ query: GET_SHOPPING_CARTS_QUERY }],
  });

  const onEdit = (cartItem: CartProduct) => {
    if (cartItem) {
      setEditCartItem(cartItem);
      setProductName(cartItem.product.name);
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setProductName(undefined);
    setEditCartItem(undefined);
    setIsModalOpen(false);
  };

  const onSubmit = (idsToSubmit: string[]) => {
    if (shoppingCartData && !submittingPreOrder) {
      setSubmittedCarts(
        shoppingCartData.shoppingCarts.shoppingCarts.filter((cart) =>
          idsToSubmit.includes(cart.id)
        ) as ShoppingCart[]
      );
      submitPreOrder({
        variables: {
          input: { shoppingCartIds: idsToSubmit, language: toGQLLanguage(language) },
        },
      })
        .then(() => {
          setSubmitted(true);
          scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(() => {
          setSubmittedCarts([]);
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  // Gets the productId parameter from URL and opens pre-order modal
  useEffect(() => {
    const urlProductId = new URLSearchParams(window.location.search).get('product');

    if (urlProductId) {
      setProductName(urlProductId);
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (shoppingCartError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, shoppingCartError, t]);

  const renderView = (): ReactNode => {
    if (loadingShoppingCart) {
      return (
        <OrderContainerSkeleton>
          <OrderProductSkeleton />
        </OrderContainerSkeleton>
      );
    } else if (submitted) {
      return (
        <div className={cx(styles['pre-order__groups'])}>
          {submittedCarts.map((shoppingCart) => (
            <OrderContainer
              shoppingCart={shoppingCart as ShoppingCart}
              orderSubmitted
              key={shoppingCart.retailer.id}
            />
          ))}
        </div>
      );
    } else if (
      shoppingCartData?.shoppingCarts.shoppingCarts &&
      !!shoppingCartData?.shoppingCarts.shoppingCarts.length
    ) {
      return (
        <>
          <div className={cx(styles['pre-order__groups'])}>
            {shoppingCartData.shoppingCarts.shoppingCarts.map((shoppingCart) => (
              <OrderContainer
                shoppingCart={shoppingCart as ShoppingCart}
                showSubmitButton
                onSubmit={onSubmit}
                onEdit={onEdit}
                key={shoppingCart.retailer.id}
                submittingIds={submittedCarts.map((cart) => cart.id)}
              />
            ))}
          </div>
          <Button
            type="button"
            className={cx(styles['pre-order__submit-all'])}
            disabled={loadingShoppingCart || submittingPreOrder}
            onClick={() => {
              if (shoppingCartData) {
                onSubmit(shoppingCartData.shoppingCarts.shoppingCarts.map((cart) => cart.id));
              }
            }}
            icon={submittingPreOrder ? SpinnerIcon : undefined}
            iconClassName={cx({ [styles['pre-order__submit-all--loading']]: submittingPreOrder })}
            iconPosition={IconPosition.LEFT}
          >
            {t('order-placement.pre-order.submit-all')}
          </Button>
        </>
      );
    }

    return (
      <div className={cx(styles['pre-order__empty-state'])}>
        <TableEmptyState
          title={t('order-placement.pre-order.empty-state.title')}
          Icon={CartEmptyIcon}
          description={
            <Trans
              i18nKey="order-placement.pre-order.empty-state.subtitle"
              components={[
                <Link href={`/${PageRoutes.ORDER_PURCHASE_HISTORY}#tab-panel-orders`} />,
              ]}
            />
          }
        />
      </div>
    );
  };

  return (
    <>
      {productName && (
        <PaginationProvider>
          <PreOrderModal
            isVisible={isModalOpen}
            hide={onCloseModal}
            productName={productName}
            editCartItem={editCartItem}
          />
        </PaginationProvider>
      )}

      <div className={cx(styles['pre-order'])}>
        <div className={cx(styles['pre-order__container'])}>
          <PreOrderHeader submitted={submitted} />
          {renderView()}
        </div>
      </div>
    </>
  );
};

PreOrder.displayName = 'PreOrder';

export default PreOrder;
