import { useEffect } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useFormErrors = (trigger: UseFormTrigger<any>, dirtyFields: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (dirtyFields) {
      const paths = Object.keys(dirtyFields);
      trigger(paths);
    }
  }, [t]);
};
