import cx from 'classnames';
import { FC } from 'react';
import { LinearChartTooltipPayload } from '../../LinearChart.types';
import styles from './LinearChartTooltip.module.scss';
import { valueFormatter } from '../../LinearChart.config';

export interface Props {
  active?: boolean;
  label?: string;
  payload?: LinearChartTooltipPayload[];
  language: string;
}

const LinearChartTooltip: FC<Props> = ({ active, payload, label, language }) =>
  active && payload && label ? (
    <div className={cx(styles['linear-chart-tooltip'])}>
      <p className={cx(styles['linear-chart-tooltip__label'])}>{label}</p>
      <ul className={cx(styles['linear-chart-tooltip__list'])}>
        {payload.map((item) => (
          <li className={cx(styles['linear-chart-tooltip__wrapper'])} key={item.name}>
            <div className={cx(styles['linear-chart-tooltip__inner-wrapper'])}>
              <svg className={cx(styles['linear-chart-tooltip__icon-wrapper'])}>
                <rect
                  className={cx(styles['linear-chart-tooltip__icon'])}
                  style={{ fill: item.color }}
                />
              </svg>
              <p className={cx(styles['linear-chart-tooltip__key'])}> {item.name}</p>
            </div>

            <p className={cx(styles['linear-chart-tooltip__value'])}>
              {valueFormatter(item.value, language)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

LinearChartTooltip.displayName = 'LinearChartTooltip';

export default LinearChartTooltip;
