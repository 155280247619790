import { FC, useEffect, useMemo } from 'react';
import { LinkUrls, PageRoutes } from '../../../lib/constants/react-router';
import { defaultContext } from '../../../lib/contexts/launch-darkly';
import { FlagKeys } from '../../../lib/constants/launch-darkly';
import { GET_USER_IDENTITY } from '../../../lib/graphql/UserInformation.gql';
import { LDIdentityType } from './LaunchDarklyWrapper.types';
import { useFlag } from '../../../lib/hooks';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

export interface Props {
  flagKey: FlagKeys;
  redirectToPage?: PageRoutes | undefined;
  identityType: LDIdentityType;
  children: JSX.Element;
}
const LaunchDarklyWrapper: FC<Props> = ({ flagKey, redirectToPage, identityType, children }) => {
  const { flag, setContext } = useFlag({ flagKey, defaultValue: false });
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_USER_IDENTITY, {
    fetchPolicy: 'cache-first',
    partialRefetch: true,
    returnPartialData: true,
  });

  const userKey = useMemo(() => {
    switch (identityType) {
      case LDIdentityType.FARM:
        return data?.farm.farmInfo.id;
      case LDIdentityType.EMAIL:
        return data?.user.accountInfo.email;
      case LDIdentityType.ID:
      default:
        return data?.user.accountInfo.id;
    }
  }, [data, identityType]);

  useEffect(() => {
    if (!loading) {
      const userContext = userKey
        ? {
            kind: 'user',
            anonymous: false,
            key: userKey,
          }
        : defaultContext;

      setContext(userContext);
    }
  }, [loading, userKey]);

  if (flag) {
    return children;
  } else if (redirectToPage) {
    navigate(`${LinkUrls.PREPEND_SLASH}${redirectToPage}`);
  }
  return null;
};

LaunchDarklyWrapper.displayName = 'LaunchDarklyWrapper';

export default LaunchDarklyWrapper;
