import { gql } from '../../__generated__';

export const GET_TRANSFER_OWNERSHIP = gql(`
  query GetTransferOwnership {
    activeTransferOwnershipRequest {
      transferOwnershipRequest {
        newAdminUser {
          accountInfo {
            id
            firstName
            lastName
          }
        }
        transferOwnershipInformation {
          id
          createdTimestamp
          updatedTimestamp
          status
        }
      }
    }
  }
`);

export const START_TRANSFER_OWNERSHIP = gql(`
  mutation StartTransferOwnership($input: FarmTransferOwnershipInput!){
    startTransferOwnershipRequest(input: $input) {
      transferOwnershipRequest {
        newAdminUser{
          accountInfo{
            id
          }
        }
        transferOwnershipInformation {
          id
          status
        }
      }
    }
  }
`);

export const UPDATE_TRANSFER_OWNERSHIP = gql(`
mutation UpdateTransferOwnershipRequest($input: UpdateFarmTransferOwnershipInput!) {
  updateTransferOwnershipRequest(input: $input) {
    transferOwnershipRequest {
      transferOwnershipInformation {
        status
        id
      }
    }
  }
}
`);
