import {
  clickTab,
  getActiveTabByHash,
  getFirstTab,
  getLastTab,
  getNextTab,
  getPanelId,
  getPreviousTab,
} from './Tabs.utils';
import { FC, KeyboardEvent, useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { VALUE_END, VALUE_HOME, VALUE_LEFT, VALUE_RIGHT } from 'keycode-js';

import cx from 'classnames';
import { Props } from './Tabs.types';
import styles from './Tabs.module.scss';

const Tabs: FC<Props> = ({ defaultActiveTabId, tabs, onTabChange }) => {
  const { hash } = useLocation();
  const [activeTabId, setActiveTabId] = useState<string | undefined | null>(
    defaultActiveTabId || getFirstTab(tabs)?.id
  );

  useLayoutEffect(() => {
    const hashedTab = getActiveTabByHash(tabs, hash);

    if (hashedTab) {
      setActiveTabId(hashedTab.id);
    }
  }, []);

  const handleTabKeydown = (e: KeyboardEvent) => {
    const { key } = e;

    switch (key) {
      case VALUE_END:
        clickTab(getLastTab(tabs));
        break;
      case VALUE_HOME:
        clickTab(getFirstTab(tabs));
        break;
      case VALUE_RIGHT:
        clickTab(getNextTab(tabs, activeTabId));
        break;
      case VALUE_LEFT:
        clickTab(getPreviousTab(tabs, activeTabId));
        break;
      default:
        break;
    }
  };

  return (
    <div className={cx(styles['tabs'])}>
      <ul className={cx(styles['tabs__list'])} role="tablist">
        {tabs.map((tab) => (
          <li key={tab.id} className={cx(styles['tabs__list-item'])} role="presentation">
            <Link
              className={cx(styles['tabs__link'], {
                [styles['tabs__link--active']]: tab.id === activeTabId,
                [styles['tabs__link--disabled']]: tab.disabled,
              })}
              to={`#${getPanelId(tab.id)}`}
              role="tab"
              id={tab.id}
              aria-controls={getPanelId(tab.id)}
              aria-selected={activeTabId === tab.id}
              tabIndex={tab.id !== activeTabId ? -1 : undefined}
              onClick={() => {
                setActiveTabId(tab.id);
                onTabChange?.(tab.id);
              }}
              onKeyDown={handleTabKeydown}
            >
              {tab.name}
            </Link>
          </li>
        ))}
      </ul>
      {tabs.map((tab) => (
        <section
          key={tab.id}
          className={cx(styles['tabs__panel'], {
            [styles['tabs__panel--hidden']]: activeTabId !== tab.id,
          })}
          id={getPanelId(tab.id)}
          role="tabpanel"
          aria-labelledby={tab.id}
          aria-hidden={activeTabId !== tab.id}
        >
          {!tab.disabled && tab.component}
        </section>
      ))}
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
