import { Retailer, RetailerContact } from '../../__generated__/graphql';
import { maskPhone } from './form-validations';
import { TFunction } from 'react-i18next';

/**
 * Gets primary contact of retailer
 * @param retailer
 * @returns
 */
export const getPrimaryContact = (retailer: Retailer): RetailerContact | undefined => {
  const { contacts } = retailer;
  const primaryContact = contacts.find((contact) => contact.isPrimary);
  if (!primaryContact && contacts.length) {
    return contacts[0];
  }
  return primaryContact;
};

/**
 * Formats retailer display name
 * @param retailer
 * @returns string
 */
export const formatRetailerName = (t: TFunction, retailer: Retailer): string =>
  retailer.alias
    ? t('format.retailer-alias', { name: retailer.name, alias: retailer.alias })
    : `${retailer.name}`;

/**
 * Formats retailer's phone numebers
 * @param t 
 * @param phone 
 * @param mobilePhone 
 * @returns string
 */
export const formatRetailerPhoneNumbers = (
  t: TFunction,
  phone: string | undefined | null,
  mobilePhone: string | undefined | null
): string => {
  if (phone && mobilePhone) {
    return t('format.retailer-phone', {
      phone: maskPhone(phone),
      mobilePhone: maskPhone(mobilePhone),
    });
  }

  return phone ? maskPhone(phone) : maskPhone(mobilePhone || '');
};
