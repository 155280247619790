import { FC, useEffect, useState } from 'react';
import Button from '../../../components/_shared/Button';
import { ButtonThemes } from '../../../lib/constants/components';
import carouselCtaVideo from '../../../assets/videos/onboarding/details.mp4';
import cx from 'classnames';
import SetupCarouselModal from '../SetupCarouselModal/SetupCarouselModal';
import { Step } from '../../../lib/constants/stepper';
import Stepper from '../../../components/Stepper';
import styles from './SetupSidebar.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  currentStepId?: string | null;
  onStepChange: (newStepId: string, nextStep?: Step, backStep?: Step) => void;
  steps: Step[];
}

const SetupSidebar: FC<Props> = ({ currentStepId, onStepChange, steps }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [currentStepId]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cx(styles['setup-sidebar'])}>
      <div className={cx(styles['carousel-cta'])}>
        <video
          src={carouselCtaVideo}
          className={cx(styles['carousel-cta__image'])}
          muted
          autoPlay
        />
        <div className={cx(styles['carousel-cta__content'])}>
          <p className={cx(styles['carousel-cta__text'])}>{t('onboarding.carousel-cta.text')}</p>
          <Button
            onClick={handleModalOpen}
            type="button"
            theme={ButtonThemes.TEXT_LINK}
            className={cx(styles['carousel-cta__link'])}
          >
            {t('onboarding.carousel-cta.link-text')}
          </Button>
          <SetupCarouselModal open={isModalOpen} onClose={handleModalClose} />
        </div>
      </div>
      <Stepper
        steps={steps}
        onChange={onStepChange}
        currentStepId={currentStepId}
        className={cx(styles['setup-sidebar__stepper'])}
      />
    </div>
  );
};

SetupSidebar.displayName = 'SetupSidebar';

export default SetupSidebar;
