import {
  AccessLevel,
  FarmPartner,
  FarmPartnerUpdateInput,
} from '../../../../../../__generated__/graphql';
import { ButtonThemes, IconPosition } from '../../../../../../lib/constants/components';
import { CheckmarkIcon, SpinnerIcon } from '../../../../../../assets/icons';
import { FC, useState } from 'react';
import {
  GET_PARTNERS_PERMISSIONS,
  UPDATE_PARTNER_PERMISSION,
} from '../../../../../../lib/graphql/PartnerPermissions.gql';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../../../components/_shared/Button';
import cx from 'classnames';
import Modal from '../../../../../../components/_shared/Modal';
import { SnackbarContextActionTypes } from '../../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './Partner.module.scss';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../../../../lib/contexts/snackbar/useSnackbarContext';

export interface Props {
  partner: FarmPartner;
}

const Partner: FC<Props> = ({ partner }) => {
  const { t } = useTranslation();
  const [updatePartnerPermission, { loading }] = useMutation(UPDATE_PARTNER_PERMISSION, {
    refetchQueries: [{ query: GET_PARTNERS_PERMISSIONS }],
  });
  const [, dispatchSnackBar] = useSnackbarContext();
  const [showUndoModal, setShowUndoModal] = useState<boolean>(false);
  const { adminUser, farmInfo } = partner.partner;

  const updateAccessLevel = (accessLevel: AccessLevel) => {
    if (accessLevel === AccessLevel.NoAccess) {
      setShowUndoModal(false);
    }
    const input: FarmPartnerUpdateInput = {
      hasAccess: accessLevel,
      partnerFarmId: partner.partner.farmInfo.id,
    };
    updatePartnerPermission({ variables: { input } })
      .then(() => {
        dispatchSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.updated-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  const shareButton = (
    <Button
      type="button"
      className={cx(styles['partner__button'], {
        [styles['partner__button--disabled']]: loading,
      })}
      onClick={
        loading
          ? undefined
          : () => {
              updateAccessLevel(AccessLevel.FullAccess);
            }
      }
      icon={loading ? SpinnerIcon : undefined}
      iconClassName={cx({ [styles['loading-icon']]: loading })}
      iconPosition={loading ? IconPosition.LEFT : undefined}
    >
      {loading
        ? t('onboarding.partner-permissions.button-sharing-access')
        : t('onboarding.partner-permissions.button-share-access')}
    </Button>
  );

  return (
    <div className={cx(styles['partner'])}>
      <div className={cx(styles['partner__mobile-wrapper'])}>
        <div className={cx(styles['partner__details-wrapper'])}>
          <p className={cx(styles['partner__details-wrapper--name'])}>{farmInfo.name}</p>
          {adminUser && (
            <p className={cx(styles['partner__details-wrapper--email'])}>
              {adminUser.accountInfo.email}
            </p>
          )}
        </div>
        {partner.hasAccess === AccessLevel.FullAccess && (
          <CheckmarkIcon
            className={cx(styles['partner__mobile-wrapper--checkmark'])}
            aria-hidden="true"
          />
        )}
      </div>
      {partner.hasAccess === AccessLevel.FullAccess ? (
        <div className={cx(styles['access-granted'])}>
          <div className={cx(styles['access-granted__desktop-wrapper'])}>
            <span className={cx(styles['access-granted__text'])}>
              {t('onboarding.partner-permissions.access-granted')}
            </span>
            <CheckmarkIcon className={cx(styles['access-granted__checkmark'])} aria-hidden="true" />
          </div>
          <Button
            className={cx(styles['partner__button'], {
              [styles['partner__button--disabled']]: loading,
            })}
            type="button"
            theme={ButtonThemes.SECONDARY}
            onClick={() => setShowUndoModal(true)}
            icon={loading ? SpinnerIcon : undefined}
            iconClassName={cx({ [styles['loading-icon']]: loading })}
            iconPosition={loading ? IconPosition.LEFT : undefined}
          >
            {t('general.undo')}
          </Button>
          <Modal
            title={t('onboarding.partner-permissions.remove-farm-access-header')}
            isVisible={showUndoModal}
            hide={() => {
              setShowUndoModal(false);
            }}
            primaryCta={{
              label: t('onboarding.partner-permissions.remove-access'),
              disabled: loading,
              action: () => {
                updateAccessLevel(AccessLevel.NoAccess);
              },
              buttonType: 'button',
            }}
            secondaryCta={{
              label: t('general.cancel'),
              disabled: loading,
              action: () => {
                setShowUndoModal(false);
              },
            }}
          >
            <p className={cx(styles['modal__subtitle'])}>
              {t('onboarding.partner-permissions.remove-farm-access-subtitle')}
            </p>
            <p className={cx(styles['modal__text'])}>
              <Trans
                i18nKey="onboarding.partner-permissions.remove-farm-access-content"
                components={[<sup />]}
              />
            </p>
          </Modal>
        </div>
      ) : (
        shareButton
      )}
    </div>
  );
};

Partner.displayName = 'Partner';

export default Partner;
