import {
  AllFilterCategory,
  AllFilterCategoryMobile,
  AllFiltersForm,
  DateRangeFiltersForm,
  DateRangeOptions,
  FilterForm,
  QueryForm,
} from '../../../lib/constants/forms';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, UseFormReset } from 'react-hook-form';

import { AllFiltersOptions } from './FilterSection.const';
import Button from '../../../components/_shared/Button/Button';
import { ButtonThemes } from '../../../lib/constants/components';
import Checkbox from '../../../components/_shared/Checkbox/Checkbox';
import cx from 'classnames';
import DateRangeFilterDesktop from './DateRange/Desktop/DateRangeFilterDesktop';
import Filter from '../../../components/_shared/Filter/Filter';
import { FilterDropdownOption } from '../../../lib/utils/order-purchases';
import styles from './FilterSection.module.scss';

interface Props {
  dropdownOptions?: AllFiltersOptions;
  formState: FilterForm;
  resetParentForm: UseFormReset<QueryForm>;
  setParentFormState: Dispatch<SetStateAction<FilterForm>>;
}

const FilterSectionDesktop: FC<Props> = ({
  dropdownOptions,
  formState,
  resetParentForm,
  setParentFormState,
}) => {
  const { t } = useTranslation();
  const {
    register: allFiltersRegister,
    reset: allFiltersReset,
    handleSubmit: allFiltersHandleSubmit,
    watch: allFiltersWatch,
    setValue: allFiltersSetValue,
  } = useForm<AllFiltersForm>({
    defaultValues: {
      product: formState.product,
      retailer: formState.retailer,
      program: formState.program,
      purchaser: formState.purchaser,
      dateRange: formState.dateRange,
    },
  });
  const {
    reset: dateRangeFiltersReset,
    control: dateRangeFiltersControl,
    watch: dateRangeFiltersWatch,
  } = useForm<DateRangeFiltersForm>({
    defaultValues: {
      startDate: formState.customDateRangeStart,
      endDate: formState.customDateRangeEnd,
    },
  });
  const watchAll = allFiltersWatch();

  const renderCheckboxes = (filter: FilterDropdownOption[], name: AllFilterCategoryMobile) => {
    if (filter) {
      return filter.map((option) => {
        const { label, value } = option;
        return (
          <Checkbox
            key={value}
            className={{
              wrapper: cx(styles['checkbox']),
              label: cx(styles['checkbox__label']),
            }}
            {...allFiltersRegister(name)}
            label={label}
            value={value}
          />
        );
      });
    }
    return undefined;
  };

  useEffect(() => {
    const subscription = allFiltersWatch(() =>
      allFiltersHandleSubmit((data: AllFiltersForm) => {
        const { startDate, endDate } = dateRangeFiltersWatch();

        setParentFormState((currentState) => ({
          ...currentState,
          product: data.product,
          program: data.program,
          purchaser: data.purchaser,
          retailer: data.retailer,
          dateRange: data.dateRange,
          customDateRangeStart: startDate,
          customDateRangeEnd: endDate,
        }));
      })()
    );
    return () => subscription.unsubscribe();
  }, [allFiltersWatch, setParentFormState, allFiltersHandleSubmit, dateRangeFiltersWatch]);

  return (
    <form
      className={cx(styles['filter-section__row-wrapper'])}
      onSubmit={allFiltersHandleSubmit(() => {})}
    >
      <div className={cx(styles['filter-section__filters-wrapper'])}>
        <span className={cx(styles['filter-section__subtitle'])}>{t('filter.filter-by')}</span>

        {!!dropdownOptions?.products.length && (
          <Filter
            className={cx(styles['filter-section__item'])}
            label={t('filter.product')}
            numDropdownOptionsSelected={watchAll?.product?.length || 0}
          >
            <div className={cx(styles['filter-section__dropdown'])}>
              {renderCheckboxes(
                dropdownOptions?.products as FilterDropdownOption[],
                AllFilterCategoryMobile.PRODUCT
              )}
            </div>
          </Filter>
        )}
        {!!dropdownOptions?.retailers.length && (
          <Filter
            className={cx(styles['filter-section__item'])}
            label={t('filter.retailer')}
            numDropdownOptionsSelected={watchAll?.retailer?.length || 0}
          >
            <div className={cx(styles['filter-section__dropdown'])}>
              {renderCheckboxes(
                dropdownOptions?.retailers as FilterDropdownOption[],
                AllFilterCategoryMobile.RETAILER
              )}
            </div>
          </Filter>
        )}
        {dropdownOptions?.purchaser && dropdownOptions?.purchaser.length > 1 && (
          <Filter
            className={cx(styles['filter-section__item'])}
            label={t('filter.purchaser')}
            numDropdownOptionsSelected={watchAll?.purchaser?.length || 0}
          >
            <div className={cx(styles['filter-section__dropdown'])}>
              {renderCheckboxes(
                dropdownOptions?.purchaser as FilterDropdownOption[],
                AllFilterCategoryMobile.PURCHASER
              )}
            </div>
          </Filter>
        )}
        <Filter
          className={cx(styles['filter-section__item'])}
          label={
            formState?.dateRange === DateRangeOptions.CUSTOM
              ? t('filter.date-range-options.custom')
              : t('filter.date')
          }
          numDropdownOptionsSelected={
            watchAll.dateRange !== DateRangeOptions.CUSTOM &&
            watchAll.dateRange !== DateRangeOptions.ALL
              ? 1
              : 0
          }
          autoHeight
        >
          <div
            className={cx(
              styles['filter-section__dropdown'],
              styles['filter-section__dropdown--date-range']
            )}
          >
            <DateRangeFilterDesktop
              formControl={dateRangeFiltersControl}
              registerRadioButtons={allFiltersRegister(AllFilterCategory.DATE_RANGE)}
              setValue={allFiltersSetValue}
              watch={dateRangeFiltersWatch}
              selectedDateRange={watchAll.dateRange}
            />
          </div>
        </Filter>
        {!!dropdownOptions?.programs.length && (
          <Filter
            className={cx(styles['filter-section__item'])}
            label={<Trans i18nKey="filter.program" components={[<sup />]} />}
            numDropdownOptionsSelected={watchAll?.program?.length || 0}
          >
            <div className={cx(styles['filter-section__dropdown'])}>
              {renderCheckboxes(
                dropdownOptions?.programs as FilterDropdownOption[],
                AllFilterCategoryMobile.PROGRAM
              )}
            </div>
          </Filter>
        )}
      </div>
      <div className={cx(styles['filter-section__all-filters'])}>
        <Button
          type="button"
          theme={ButtonThemes.TEXT_LINK}
          onClick={() => {
            allFiltersReset({
              product: [],
              program: [],
              purchaser: [],
              retailer: [],
              dateRange: DateRangeOptions.ALL,
            });
            dateRangeFiltersReset({
              startDate: undefined,
              endDate: undefined,
            });
            resetParentForm({
              query: '',
            });
          }}
          className={cx(styles['filter-section__reset-button'])}
        >
          {t('filter.reset-all-filters')}
        </Button>
      </div>
    </form>
  );
};

export default FilterSectionDesktop;
