import { BellIcon, SpinnerIcon } from '../../../../../assets/icons';
import { FC, useState } from 'react';
import {
  GET_TRANSFER_OWNERSHIP,
  UPDATE_TRANSFER_OWNERSHIP,
} from '../../../../../lib/graphql/TransfersOwnership.gql';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import Button from '../../../../../components/_shared/Button';
import cx from 'classnames';
import { GET_FARM_ADMIN_QUERY } from '../../../../../lib/graphql/FarmInformation.gql';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../../lib/graphql/UserInformation.gql';
import { IconPosition } from '../../../../../lib/constants/components';
import Modal from '../../../../../components/_shared/Modal';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from '../MyOperation.module.scss';
import { TransferOwnershipRequestStatus } from '../../../../../__generated__/graphql';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';

const ChildUserTransferOwnership: FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [, dispatchSnackbar] = useSnackbarContext();
  const { data: farmData } = useQuery(GET_FARM_ADMIN_QUERY);
  const { data: transferOwnershipData } = useQuery(GET_TRANSFER_OWNERSHIP);
  const [updateTransferOwnershipRequest, { loading: updatingTransferOwnershipRequest }] =
    useMutation(UPDATE_TRANSFER_OWNERSHIP, {
      refetchQueries: [{ query: GET_TRANSFER_OWNERSHIP }, { query: GET_USER_IS_ADMIN_QUERY }],
    });
  const { t } = useTranslation();

  const completeTransferOwnershipRequest = (status: TransferOwnershipRequestStatus) => {
    if (
      transferOwnershipData?.activeTransferOwnershipRequest?.transferOwnershipRequest
        ?.transferOwnershipInformation?.id
    ) {
      updateTransferOwnershipRequest({
        variables: {
          input: {
            id: transferOwnershipData.activeTransferOwnershipRequest.transferOwnershipRequest
              .transferOwnershipInformation.id,
            status,
          },
        },
      })
        .then(({ data }) => {
          setModalOpen(false);
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label:
                data?.updateTransferOwnershipRequest.transferOwnershipRequest
                  .transferOwnershipInformation.status === TransferOwnershipRequestStatus.Accepted
                  ? t('account-settings.my-operation.transfer-ownership.success-accept')
                  : t('account-settings.my-operation.transfer-ownership.success-reject'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  return transferOwnershipData?.activeTransferOwnershipRequest?.transferOwnershipRequest ? (
    <>
      <div className={cx(styles['tor-notification'])}>
        <div className={cx(styles['tor-notification__text-container'])}>
          <BellIcon className={cx(styles['tor-notification__icon'])} />
          <p>{t('account-settings.my-operation.transfer-ownership.notification')}</p>
        </div>
        <Button
          className={cx(styles['tor-notification__button'])}
          type="button"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {t('account-settings.my-operation.transfer-ownership.cta')}
        </Button>
      </div>
      <Modal
        isVisible={modalOpen}
        hide={() => {
          setModalOpen(false);
        }}
        title={t('account-settings.my-operation.transfer-ownership.modal-title')}
        primaryCta={{
          label: t('account-settings.my-operation.transfer-ownership.accept'),
          action: () => {
            completeTransferOwnershipRequest(TransferOwnershipRequestStatus.Accepted);
          },
          disabled: updatingTransferOwnershipRequest,
          buttonIcon: updatingTransferOwnershipRequest ? SpinnerIcon : undefined,
          buttonIconPosition: IconPosition.LEFT,
          buttonIconClassName: cx(styles['tor-modal__loading']),
        }}
        secondaryCta={{
          label: t('account-settings.my-operation.transfer-ownership.decline'),
          action: () => {
            completeTransferOwnershipRequest(TransferOwnershipRequestStatus.Rejected);
          },
          disabled: updatingTransferOwnershipRequest,
        }}
      >
        <p className={cx(styles['tor-modal__title'])}>
          {t('account-settings.my-operation.transfer-ownership.modal-subtitle')}
        </p>
        <p className={cx(styles['tor-modal__text'])}>
          <Trans
            i18nKey="account-settings.my-operation.transfer-ownership.description"
            values={{
              farmName: farmData?.farm.farmInfo.name,
              firstName: farmData?.farm.adminUser?.accountInfo.firstName,
              lastName: farmData?.farm.adminUser?.accountInfo.lastName,
            }}
            components={[<strong />, <strong />]}
          />
        </p>
      </Modal>
    </>
  ) : null;
};

export default ChildUserTransferOwnership;
