import Avatar from '../Avatar';
import cx from 'classnames';
import { FarmInfo } from '../../../__generated__/graphql';
import { FC } from 'react';
import { maskPostalCode } from '../../../lib/utils/form-validations';
import styles from './FarmCard.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  farm: Partial<FarmInfo>;
  hideEyebrow?: boolean;
}

const FarmCard: FC<Props> = ({ farm, hideEyebrow = false }) => {
  const { t } = useTranslation();
  const { name, address, farmLogoUrl } = farm;

  return (
    <div className={cx(styles['farm-card'])}>
      <div className={cx(styles['farm-card__avatar-wrapper'])}>
        <Avatar firstName="" lastName="" profilePictureUrl={farmLogoUrl} />
      </div>
      <div className={cx(styles['farm-card__text-wrapper'])}>
        {!hideEyebrow && <p className={cx(styles['farm-card__role'])}>{t('farm-card.name')}</p>}
        <p className={cx(styles['farm-card__name'])}>{name}</p>
        <p className={cx(styles['farm-card__subtitle'])}>
          {t('format.full-address', {
            address: address?.address,
            city: address?.city,
            province: address?.province,
            postalCode: maskPostalCode(address?.postalCode || ''),
          })}
        </p>
      </div>
    </div>
  );
};
FarmCard.displayName = 'FarmCard';

export default FarmCard;
