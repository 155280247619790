import { FC, memo, useMemo } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { PreOrderProduct, PurchaseProduct } from '../../../../__generated__/graphql';

import cx from 'classnames';
import dayjs from '../../../../lib/utils/dates';
import { ForwardCallIcon } from '../../../../assets/icons';
import Link from '../../../_shared/Link';
import { LinkUrls } from '../../../../lib/constants/react-router';
import styles from './TableRow.module.scss';
import Tooltip from '../../../_shared/Tooltip';
import { TooltipPosition } from '../../../_shared/Tooltip/Tooltip.types';
import { useBreakpoint } from '../../../../lib/hooks';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useTranslation } from 'react-i18next';

interface Props {
  date: string;
  itemId: string;
  itemType: 'purchase' | 'pre-order';
  products: PurchaseProduct[] | PreOrderProduct[];
  purchaser?: string;
  hasPartners?: boolean;
}

const InvoiceSection: FC<Props> = ({
  date,
  itemId,
  itemType,
  products,
  purchaser,
  hasPartners,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const { isMd } = useBreakpoint();
  const showPurchaser = useMemo(() => purchaser && hasPartners, [hasPartners, purchaser]);

  return (
    <div className={cx(styles['order-details'])}>
      <div className={cx(styles['order-details__header'])}>
        <div>
          <span className={cx(styles['order-details__title'])}>
            {t(`purchases.details.${itemType}.order-details`, { id: itemId })}
          </span>
          <span className={cx(styles['order-details__sub-title'])}>
            {t(`purchases.details.${itemType}.order-placed-on`, {
              date: dayjs(date).locale(language).format(dateFormat),
            })}
          </span>
        </div>
        <div>
          <div className={cx(styles['order-details__order-help'])}>
            <Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL}>
              {t(`purchases.details.${itemType}.get-order-help`)}
            </Link>
            <Tooltip
              text={t(`purchases.details.${itemType}.get-order-help-tooltip`)}
              position={isMd ? TooltipPosition.TOP : TooltipPosition.LEFT}
              className={cx(styles['order-details__tool-tip'])}
            >
              <ForwardCallIcon
                className={cx(styles['order-details__tooltip-icon'])}
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <hr className={cx(styles['order-details__divider'])} />
      {showPurchaser && (
        <div className={cx(styles['order-details__purchaser'])}>
          <span className={cx(styles['product-list__heading'])}>
            {t('purchases.details.purchase.purchaser')}
          </span>
          <p className={cx(styles['product-list__item-text'])}>{purchaser}</p>
        </div>
      )}
      <div className={cx(styles['product-list'])}>
        <div className={cx(styles['product-list__header'])}>
          <span className={cx(styles['product-list__heading'])}>
            {t('purchases.details.product')}
          </span>
          <span className={cx(styles['product-list__heading'])}>
            {t('purchases.details.quantity')}
          </span>
        </div>
        <ul className={cx(styles['product-list__list'])}>
          {products.map((item) => {
            const { product } = item;

            return (
              <li className={cx(styles['product-list__item'])} key={product?.sku}>
                <span>
                  {product?.preAuthUrl ? (
                    <Link
                      className={cx(styles['product-list__item-text'])}
                      href={product.preAuthUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {product?.name}
                    </Link>
                  ) : (
                    <p className={cx(styles['product-list__item-text'])}>{product?.name}</p>
                  )}
                </span>
                <span
                  className={cx(
                    styles['product-list__item-text'],
                    styles['product-list__item-text--w-color']
                  )}
                >
                  {item.quantity}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default memo(InvoiceSection);
