import cx from 'classnames';
import FarmCard from '../../../../../components/_shared/FarmCard';
import { FC } from 'react';
import { GET_FARM_INFORMATION_WITHOUT_PAYMENT } from '../../../../../lib/graphql/FarmInformation.gql';
import styles from '../MyOperation.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const ChildUserFarmInformation: FC = () => {
  const { data: farmData } = useQuery(GET_FARM_INFORMATION_WITHOUT_PAYMENT);
  const { t } = useTranslation();

  return (
    <div>
      <div className={cx(styles['my-operation__text-wrapper'])}>
        <h2 className={cx(styles['my-operation__heading'])}>
          {t('account-settings.my-operation.farm-information.child-user.heading')}
        </h2>
        <p className={cx(styles['my-operation__subtitle'])}>
          {t('account-settings.my-operation.farm-information.child-user.subtitle-1')}
        </p>
        <p className={cx(styles['my-operation__subtitle'])}>
          {t('account-settings.my-operation.farm-information.child-user.subtitle-2')}
        </p>
      </div>
      <div className={cx(styles['my-operation__farm-card'])}>
        {farmData?.farm?.farmInfo && <FarmCard farm={farmData?.farm?.farmInfo} hideEyebrow />}
      </div>
    </div>
  );
};

export default ChildUserFarmInformation;
