import { PreOrderProduct, PurchaseProduct } from '../../../../__generated__/graphql';

import { FC } from 'react';
import PreOrderRow from './PreOrderRow';
import PurchaseRow from './PurchaseRow';

export interface Props {
  item: PurchaseProduct | PreOrderProduct;
  className?: string;
  showBreakdownCta?: boolean;
  hasPartners?: boolean;
  parentFarmName?: string;
}

const PURCHASE_FIELD = 'purchase';
const PRE_ORDER_FIELD = 'preOrder';

const TableRow: FC<Props> = ({
  item,
  className,
  showBreakdownCta = false,
  hasPartners = false,
  parentFarmName
}) => {
  const renderRow = () => {
    if (PURCHASE_FIELD in item) {
      return (
        <PurchaseRow
          item={item}
          className={className}
          showBreakdownCta={showBreakdownCta}
          hasPartners={hasPartners}
          parentFarmName={parentFarmName}
        />
      );
    } else if (PRE_ORDER_FIELD in item) {
      return <PreOrderRow item={item} className={className} showBreakdownCta={showBreakdownCta} />;
    }

    return null;
  };

  return renderRow();
};

export default TableRow;
