import { MultiValueProps } from 'react-select';

const MultiValue = ({ children, ...props }: MultiValueProps) => {
  const { selectProps, data } = props;
  const value = [selectProps.value][0] as Array<any>;
  const currentIndex = value.indexOf(data);
  const addTrailingComma = currentIndex < value.length - 1;
  return (
    <>
      {children}
      {addTrailingComma && ', '}
    </>
  );
};
export default MultiValue;
