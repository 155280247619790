import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';

import Checkbox from '../../../../components/_shared/Checkbox';
import cx from 'classnames';
import Modal from '../../../../components/_shared/Modal';
import { OrderChartContextActionTypes } from '../../../../lib/contexts/order-chart/OrderCharContext.types';
import { PurchaseChartForm } from '../../../../lib/types/charts';
import { PurchaseChartType } from '../../../../lib/constants/purchases';
import Radio from '../../../../components/_shared/Radio';
import styles from './PurchaseFiltersModal.module.scss';
import useOrderChartContext from '../../../../lib/contexts/order-chart/useOrderChartContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  isOpen: boolean;
  hide(): void;
}

const PurchaseFiltersModal: FC<Props> = ({ isOpen, hide }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { dirtyFields },
    register,
    reset,
    watch,
  } = useForm<PurchaseChartForm>();
  const [{ options, filters, type }, dispatch] = useOrderChartContext();

  useEffect(() => {
    if (filters && type) {
      reset({ filters, type });
    }
  }, [filters, type, reset]);

  const closeModal = () => {
    hide();
    reset({ filters, type });
  };

  const applyFilters = () => {
    if (dirtyFields.type) {
      const selectedType = watch('type');
      dispatch({
        type: OrderChartContextActionTypes.UpdateType,
        payload: selectedType,
      });
    }
    if (dirtyFields.filters) {
      const selectedFilters = watch('filters');
      dispatch({
        type: OrderChartContextActionTypes.UpdateFilters,
        payload: selectedFilters,
      });
    }
    hide();
  };

  const renderCheckboxes = () =>
    options.map((option) => (
      <li key={option.key} className={cx(styles['filter-modal__checkbox'])}>
        <Checkbox {...register('filters')} label={t(option.label)} value={option.key} />
      </li>
    ));

  return (
    <Modal
      classNames={{
        header: cx(styles['filter-modal__header']),
        footer: cx(styles['filter-modal__footer']),
      }}
      isVisible={isOpen}
      hide={closeModal}
      title={t('data-visualization.shared.filters')}
      primaryCta={{
        label: t('general.apply'),
        action: () => {
          applyFilters();
        },
      }}
      secondaryCta={{
        label: t('general.cancel'),
        action: () => {
          closeModal();
        },
      }}
    >
      <form>
        <div className={cx(styles['filter-modal__section'])}>
          <h1 className={cx(styles['filter-modal__label'])}>
            {t('data-visualization.filter-label.type')}
          </h1>
          <Controller
            control={control}
            name="type"
            rules={{ required: t('form.errors.required') }}
            render={({ field: { onChange, name, value } }) => (
              <div>
                <Radio
                  className={cx(styles['filter-modal__radio'])}
                  label={t('data-visualization.type.radio.acre')}
                  value={PurchaseChartType.ACRE}
                  checked={value === PurchaseChartType.ACRE}
                  name={name}
                  onChange={onChange}
                />
                <Radio
                  className={cx(styles['filter-modal__radio'])}
                  label={t('data-visualization.type.radio.msrp')}
                  value={PurchaseChartType.MSRP}
                  checked={value === PurchaseChartType.MSRP}
                  name={name}
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
        <div className={cx(styles['filter-modal__section'])}>
          <h1 className={cx(styles['filter-modal__label'])}>
            {t('data-visualization.filter-label.crops')}
          </h1>
          <ul className={cx(styles['filter-modal__checkbox-container'])}>{renderCheckboxes()}</ul>
        </div>
      </form>
    </Modal>
  );
};

PurchaseFiltersModal.displayName = 'PurchaseFiltersModal';

export default PurchaseFiltersModal;
