import SnackbarContext from './SnackbarContext';
import { SnackbarContextType } from './SnackbarContext.types';
import { useContext } from 'react';

const useSnackbarContext = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('useSnackbarContext must be used within a SnackBarProvider');
  }

  return context;
};

export default useSnackbarContext;
