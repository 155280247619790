import { gql } from '../../__generated__';

export const GET_USER_INFO_NAV = gql(`
  query GetUserInfoNavigation {
    user {
      accountInfo {
        id
        firstName
        lastName
        onboardingComplete
        instructionsCarouselViewed
        profilePictureUrl
      }
    }
  }
`);

export const GET_FARM_INFO_NAV = gql(`
  query GetFarmInfoNavigation {
    farm {
      farmInfo {
        id
        address {
          address
          city
          postalCode
          province
        }
      }
    }
  }
`);
