import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { CookieConfig, Cookies, ErrorCodes } from '../constants/gigya';
import axios from 'axios';
import cookies from 'js-cookie';
import dayjs from 'dayjs';
import { Endpoints } from '../constants/endpoints';
import { LogoutUser } from './gigya-client';
import { onError } from 'apollo-link-error';
import { PageRoutes } from '../constants/react-router';
import utc from 'dayjs/plugin/utc';
import { v4 as uuid } from 'uuid';

dayjs.extend(utc);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_URL,
  credentials: 'include',
  headers: {
    'x-request-id': uuid(),
  },
});

// expiration window = access_token_lifetime / 2 (in seconds)
const EXPIRATION_WINDOW = parseInt(process.env.REACT_APP_GIGYA_EXPIRATION_WINDOW as string, 10);

const authMiddleware = new ApolloLink((operation, forward) => {
  const expirationTime = cookies.get(Cookies.CS_GROWERS_TOKEN_EXPIRATION);

  if (expirationTime) {
    const expiration = dayjs(expirationTime).utc().unix();
    const currentTime = dayjs().utc().unix();
    const timeDifference = expiration - currentTime;
    // if the access token is within 50% of it's expiration time request a new one
    if (timeDifference <= EXPIRATION_WINDOW && timeDifference >= 0) {
      axios
        .post(
          Endpoints.TOKEN,
          {},
          {
            withCredentials: true,
            params: {
              grantType: 'refresh_token',
            },
            headers: {
              'x-request-id': uuid(),
            },
          }
        )
        .then((res) => {
          cookies.set(Cookies.CS_GROWERS_TOKEN_EXPIRATION, res.data.cookieExpiration, {
            ...CookieConfig(res.data.cookieExpiration),
          });
        })
        .catch(() => {
          LogoutUser(false, PageRoutes.GIGYA_ERROR);
        });
    }
  }

  return forward(operation);
});

const logoutLink = onError((errors) => {
  const unAuthorizedError = errors?.graphQLErrors?.some(
    (error) => error.extensions.code === ErrorCodes.UNAUTHORIZED_USER
  );

  if (unAuthorizedError && !window.location.pathname.includes(PageRoutes.NOT_FOUND)) {
    LogoutUser();
  }
});

const client = new ApolloClient({
  link: from([logoutLink as unknown as ApolloLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          accountInfo: {
            merge: true,
          },
        },
        keyFields: ['accountInfo', ['id']],
      },
      Farm: {
        fields: {
          farmInfo: {
            merge: true,
          },
        },
        keyFields: ['farmInfo', ['id']],
      },
      FarmInfo: {
        fields: {
          address: {
            merge: true,
          },
        },
        keyFields: ['id'],
      },
      EnrolledProgramsInfo: {
        fields: {
          enrolledProgramsInfo: {
            merge: true,
          },
          bayerValueProgramInfo: {
            merge: true,
          },
        },
        keyFields: ['bayerValueProgramInfo', ['program', ['id']]],
      },
      ProgramRewardMetrics: {
        keyFields: ['segment'],
      },
      Retailer: {
        fields: {
          address: {
            merge: true,
          },
        },
        keyFields: ['id'],
      },
    },
  }),
});

export default client;
