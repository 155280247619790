import { CottonIcon, InvoiceIcon } from '../../../../assets/icons';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';

import CardLink from '../../../../components/_shared/CardLink';
import cx from 'classnames';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './TakeActionWidget.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {}

const TakeActionWidget: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['take-action-widget'])}>
      <div className={cx(styles['take-action-widget__text-wrapper'])}>
        <p className={cx(styles['take-action-widget__title'])}>{t('take-action-widget.title')}</p>
        <p className={cx(styles['take-action-widget__subtitle'])}>
          {t('take-action-widget.subtitle')}
        </p>
      </div>
      <ul className={cx(styles['take-action-widget__list'])}>
        {process.env.REACT_APP_SHOW_PRE_ORDERS && (
          <li>
            <CardLink
              as={RouterLink}
              className={cx(styles['take-action-widget__list-item'])}
              icon={InvoiceIcon}
              to={`/${PageRoutes.ORDER_PURCHASE_HISTORY}#tab-panel-orders`}
            >
              {t('take-action-widget.check-orders')}
            </CardLink>
          </li>
        )}

        <li>
          <CardLink
            className={cx(styles['take-action-widget__list-item'])}
            icon={CottonIcon}
            href={LinkUrls.CATALOG_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('take-action-widget.seed-catalog')}
          </CardLink>
        </li>
      </ul>
    </div>
  );
};

TakeActionWidget.displayName = 'TakeActionWidget';

export default TakeActionWidget;
