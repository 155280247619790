import {
  BAYER_VALUE_PROGRAM_ID,
  OTHER_PROGRAM_ID,
  ProgramBadgeSize,
  ProgramColorMap,
  ProgramType,
} from '../../../../../../lib/constants/programs';
import {
  EMPTY_STATE_COLSPAN_OVERVIEW,
  REBATE_STATEMENTS_PER_PAGE,
} from '../../../../../../lib/constants/cheques';
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import {
  formatNumberOneDecimal,
  toNormalizeTranslationKey,
} from '../../../../../../lib/utils/utils';

import cx from 'classnames';
import { GET_ENTITLEMENTS } from '../../../../../../lib/graphql/Rebates.gql';
import Pagination from '../../../../../../components/_shared/Pagination';
import { Program } from '../../../../../../__generated__/graphql';
import ProgramBadge from '../../../../../../components/_shared/ProgramBadge';
import PurchaseTableLegend from '../../../../../../components/_shared/PurchaseTableLegend/PurchaseTableLegend';
import { ReceiptIcon } from '../../../../../../assets/icons';
import Spinner from '../../../../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../../../../lib/constants/components';
import styles from '../../ChequesSection.module.scss';
import TableEmptyState from '../../../../../../components/TableEmptyState';
import Tooltip from '../../../../../../components/_shared/Tooltip';
import { TooltipPosition } from '../../../../../../components/_shared/Tooltip/Tooltip.types';
import { useBreakpoint } from '../../../../../../lib/hooks';
import usePaginationContext from '../../../../../../lib/contexts/pagination/usePaginationContext';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface Props {
  year: number;
}

const RebateOverview: FC<Props> = ({ year }) => {
  const {
    t,
    i18n: { exists },
  } = useTranslation();
  const { isXs } = useBreakpoint();
  const [pageNumber, dispatchPageNumber] = usePaginationContext();
  const tableRef = useRef<HTMLTableElement>(null);
  const { data: entitlementsData, loading: entitlementsLoading } = useQuery(GET_ENTITLEMENTS, {
    variables: {
      input: {
        year,
        offset: REBATE_STATEMENTS_PER_PAGE * pageNumber,
        limit: REBATE_STATEMENTS_PER_PAGE,
      },
    },
  });

  useEffect(() => {
    dispatchPageNumber(0);
  }, [year]);

  const totalItems = useMemo(
    () => entitlementsData?.rebatesOverview?.rebateEntitlements?.totalCount || 0,
    [entitlementsData]
  );

  const getProductCategory = (category: string) => {
    const labelKey = `product.main-group.${toNormalizeTranslationKey(category)}`;
    if (exists(labelKey) || !labelKey) {
      return t(labelKey);
    }

    return '';
  };

  const renderProgramRewardIcon = (program: Pick<Program, 'id' | 'name' | 'type'>): ReactNode => (
    <Tooltip text={program.name} position={TooltipPosition.TOP} key={program.id}>
      <ProgramBadge
        program={
          program.type === ProgramType.BAYER_VALUE
            ? ProgramColorMap[BAYER_VALUE_PROGRAM_ID]
            : ProgramColorMap[OTHER_PROGRAM_ID]
        }
        size={ProgramBadgeSize.SM}
      />
    </Tooltip>
  );

  const renderLoadingState = (): ReactNode => (
    <div className={cx(styles['spinner-wrapper'])}>
      <Spinner size={SpinnerSizes.LG} />
    </div>
  );

  const renderEmptyState = (): ReactNode => (
    <TableEmptyState
      Icon={ReceiptIcon}
      title={t('bayer-value.rebates.empty-state.overview.heading')}
      description={t('bayer-value.rebates.empty-state.overview.description')}
    />
  );

  const renderDesktopTable = (): ReactNode => (
    <table className={cx(styles['table'])} ref={tableRef}>
      <thead>
        <tr>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.product')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.category')}
          </th>
          <th className={cx(styles['table__header'], styles['table__header--center'])}>
            {t('bayer-value.rebate-statements.table.program')}
          </th>
          <th className={cx(styles['table__header'], styles[`table__header--right`])}>
            {t('bayer-value.rebate-statements.table.savings')}
          </th>
          <th className={cx(styles['table__header'], styles[`table__header--right`])}>
            {t('bayer-value.rebate-statements.table.acres')}
          </th>
        </tr>
      </thead>
      {totalItems === 0 ? (
        <tbody>
          <tr className={cx(styles['table__empty-state'])}>
            <td
              className={cx(styles['table__empty-state-td'])}
              colSpan={EMPTY_STATE_COLSPAN_OVERVIEW}
              aria-label={t('bayer-value.rebate-statements.table.product')}
            >
              {renderEmptyState()}
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody className={cx(styles['table__tbody'])}>
          {entitlementsData?.rebatesOverview?.rebateEntitlements?.entitlements.map(
            (entitlement) => (
              <tr key={entitlement.id} className={cx(styles['table__data-row'])}>
                <td
                  className={cx(styles['table__data-cell'])}
                  data-label={t('bayer-value.rebate-statements.table.product')}
                >
                  {entitlement.product?.name}
                </td>
                <td
                  className={cx(styles['table__data-cell'])}
                  data-label={t('bayer-value.rebate-statements.table.category')}
                >
                  {getProductCategory(entitlement.product?.mainGroup || '')}
                </td>
                <td
                  className={cx(styles['table__data-cell'], styles['table__data-cell--center'])}
                  data-label={t('bayer-value.rebate-statements.table.program')}
                >
                  {entitlement.program && renderProgramRewardIcon(entitlement.program)}
                </td>
                <td
                  className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
                  data-label={t('bayer-value.rebate-statements.table.savings')}
                >
                  {t('format.price', { value: entitlement.entitledAmount })}
                </td>
                <td
                  className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
                  data-label={t('bayer-value.rebate-statements.table.acres')}
                >
                  {entitlement.acres
                    ? formatNumberOneDecimal(entitlement.acres)
                    : entitlement.acres}
                </td>
              </tr>
            )
          )}
        </tbody>
      )}
    </table>
  );

  const renderMobileTable = (): ReactNode => {
    if (totalItems === 0) {
      return renderEmptyState();
    }

    return (
      <div>
        {entitlementsData?.rebatesOverview?.rebateEntitlements?.entitlements.map((entitlement) => (
          <dl
            key={entitlement.id}
            className={cx(styles['mobile-table__row'], styles['mobile-table__row--striped'])}
          >
            <div className={cx(styles['mobile-table__column'])}>
              <dt className={cx(styles['mobile-table__label'], 'sr-only')}>
                {t('punctuation.colon', {
                  value: t('bayer-value.rebate-statements.table.product'),
                })}
              </dt>
              <dd className={cx(styles['mobile-table__header'])}> {entitlement.product?.name}</dd>
            </div>
            <div className={cx(styles['mobile-table__column'])}>
              <dt className={cx(styles['mobile-table__label'])}>
                {t('punctuation.colon', {
                  value: t('bayer-value.rebate-statements.table.savings'),
                })}
              </dt>
              <dd className={cx(styles['mobile-table__value'])}>
                {t('format.price', {
                  value: entitlement.entitledAmount,
                })}
              </dd>
            </div>
            <div className={cx(styles['mobile-table__column'])}>
              <dt className={cx(styles['mobile-table__label'])}>
                {t('punctuation.colon', {
                  value: t('bayer-value.rebate-statements.table.category'),
                })}
              </dt>
              <dd className={cx(styles['mobile-table__value'])}>
                {getProductCategory(entitlement.product?.mainGroup || '')}
              </dd>
            </div>
            <div className={cx(styles['mobile-table__column'])}>
              <dt className={cx(styles['mobile-table__label'])}>
                {t('punctuation.colon', {
                  value: t('bayer-value.rebate-statements.table.acres'),
                })}
              </dt>
              <dd className={cx(styles['mobile-table__value'])}>
                {entitlement.acres ? formatNumberOneDecimal(entitlement.acres) : entitlement.acres}
              </dd>
            </div>
            <div className={cx(styles['mobile-table__column'])}>
              <dt className={cx(styles['mobile-table__label'], 'sr-only')}>
                {t('punctuation.colon', {
                  value: t('bayer-value.rebate-statements.table.program'),
                })}
              </dt>
              <dd className={cx(styles['mobile-table__value'])}>
                {entitlement.program && renderProgramRewardIcon(entitlement.program)}
              </dd>
            </div>
          </dl>
        ))}
      </div>
    );
  };

  const renderTable = (): ReactNode => {
    if (isXs) {
      return renderMobileTable();
    }
    return renderDesktopTable();
  };

  return (
    <div className={cx(styles['rebate-section'])}>
      <PurchaseTableLegend displayPurchaserIcon={false} />
      {entitlementsLoading ? renderLoadingState() : renderTable()}
      {totalItems > 0 && (
        <div className={cx(styles['rebate-section__pagination-wrapper'])}>
          <Pagination
            itemsPerPage={REBATE_STATEMENTS_PER_PAGE}
            onPageChange={(pageNum) => {
              dispatchPageNumber(pageNum);
            }}
            totalNumberItems={totalItems}
          />
        </div>
      )}
    </div>
  );
};

RebateOverview.displayName = 'RebateOverview';

export default RebateOverview;
