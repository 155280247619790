import { CalendarTodayIcon, DollarOutlineIcon, LockOutlineIcon } from '../../../../assets/icons';
import dayjs, {
  getLastThreeRewardYears,
  getRewardEarningPeriodDate,
} from '../../../../lib/utils/dates';
import { FC, useEffect, useMemo, useState } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { Trans, useTranslation } from 'react-i18next';

import { AccessLevel } from '../../../../__generated__/graphql';
import Breakdown from './Breakdown/Breakdown';
import CardLink from '../../../../components/_shared/CardLink';
import cx from 'classnames';
import { DashboardContextActionTypes } from '../../../../lib/contexts/dashboard/DashboardContext.types';
import { EmptyStateTheme } from '../../../../lib/constants/components';
import Enrolled from './Enrolled/Enrolled';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { PageRoutes } from '../../../../lib/constants/react-router';
import RewardsYearPicker from '../../../../components/_shared/RewardsYearPicker';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from './Skeleton/Skeleton';
import styles from './BayerRewardsWidget.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState/TableEmptyState';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useQuery } from '@apollo/client';

const BayerRewardsWidget: FC = () => {
  const { t } = useTranslation();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const [selectedYear, setSelectedYear] = useState<number>(getLastThreeRewardYears()[0]);
  const [hasRewards, setHasRewards] = useState<boolean>(false);
  const [, dispatchDashboard] = useDashboardContext();
  const { data: rewardsAccessData, loading: loadingRewardsData } = useQuery(
    GET_USER_REWARDS_ACCESS_QUERY
  );
  const { data: breakdownData, loading: loadingBreakdown } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year: selectedYear } },
      skip: !rewardsAccessData || rewardsAccessData.user.hasAccess === AccessLevel.NoAccess,
    }
  );

  const rewardsPeriod = useMemo(() => getRewardEarningPeriodDate(selectedYear), [selectedYear]);

  useEffect(() => {
    setHasRewards(!!breakdownData?.enrolledProgramsInfo?.totalRewardAmountInDollars);
  }, [breakdownData]);

  useEffect(() => {
    if (!loadingBreakdown && !breakdownData?.enrolledProgramsInfo) {
      dispatchDashboard({
        type: DashboardContextActionTypes.UpdateDisplayRetreat,
        payload: false,
      });
    }
  }, [breakdownData, loadingBreakdown, dispatchDashboard]);

  const renderView = () => {
    if (loadingBreakdown || loadingRewardsData) {
      return <Skeleton />;
    } else if (rewardsAccessData && rewardsAccessData.user.hasAccess === AccessLevel.NoAccess) {
      return (
        <TableEmptyState
          className={cx(styles['bayer-rewards__empty-state'])}
          Icon={LockOutlineIcon}
          title={t('bayer-rewards-widget.no-access.heading')}
          description={t('bayer-rewards-widget.no-access.description')}
          theme={EmptyStateTheme.SECONDARY}
        />
      );
    } else if (breakdownData && !breakdownData.enrolledProgramsInfo) {
      return (
        <TableEmptyState
          className={cx(styles['bayer-rewards__empty-state'])}
          Icon={CalendarTodayIcon}
          title={t('bayer-value.banner.no-rewards-title', { year: selectedYear })}
          description={t('bayer-value.banner.no-rewards-description', {
            date: dayjs(rewardsPeriod.end).add(1, 'day').format(dateFormat),
          })}
          theme={EmptyStateTheme.SECONDARY}
        />
      );
    } else if (hasRewards) {
      return <Breakdown year={selectedYear} />;
    }

    return <Enrolled year={selectedYear} />;
  };

  return (
    <div className={cx(styles['bayer-rewards'])}>
      <CardLink
        as={RouterLink}
        icon={DollarOutlineIcon}
        isHeaderLink
        to={`/${PageRoutes.REWARDS}?year=${selectedYear}`}
        linkText={t('general.view')}
        className={cx(styles['bayer-rewards__card-link'])}
      >
        <Trans i18nKey="bayer-rewards-widget.card-title" components={[<sup />]} />
      </CardLink>
      {!loadingRewardsData && rewardsAccessData?.user?.hasAccess !== AccessLevel.NoAccess && (
        <div className={cx(styles['bayer-rewards__years'])}>
          <div className={cx(styles['bayer-rewards__years-inner'])}>
            <RewardsYearPicker
              selectedYear={selectedYear}
              onSelect={(year) => {
                setSelectedYear(year);
              }}
            />
          </div>
        </div>
      )}
      {renderView()}
    </div>
  );
};

BayerRewardsWidget.displayName = 'BayerRewardsWidget';

export default BayerRewardsWidget;
