import { LinearChartLine } from '../../../components/LinearChart/LinearChart.types';
import { PurchaseChartType } from '../../constants/purchases';
import { ReactNode } from 'react';

export interface OrderChartContextState {
  filters: string[];
  options: LinearChartLine[];
  type: PurchaseChartType;
}

export interface OrderChartContextProps {
  children: ReactNode;
}

export enum OrderChartContextActionTypes {
  UpdateFilters = 'UpdateFilters',
  UpdateOptions = 'UpdateOptions',
  UpdateType = 'UpdateType',
  UpdateData = 'UpdateData',
}

export type OrderChartContextAction =
  | { type: OrderChartContextActionTypes.UpdateType; payload: PurchaseChartType }
  | { type: OrderChartContextActionTypes.UpdateFilters; payload: string[] }
  | { type: OrderChartContextActionTypes.UpdateOptions; payload: LinearChartLine[] };

export type OrderChartContextDispatch = (action: OrderChartContextAction) => void;

export type OrderChartContextType = [OrderChartContextState, OrderChartContextDispatch];
