import { AddCircleOutline, ChevronDownIcon, TrashCanIcon } from '../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { FC, useState } from 'react';

import Accordion from '../../../components/_shared/Accordion';
import Avatar from '../../../components/_shared/Avatar';
import Button from '../../../components/_shared/Button';
import Dropdown from '../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../lib/constants/react-select';
import { ProductSelected } from '../../../lib/types/calculator';
import styles from './ProductAccordion.module.scss';
import TextField from '../../../components/_shared/TextField';
import { useTranslation } from 'react-i18next';

interface ProductAccordionProps {
  categoryIndex: number;
  title: string;
  subtitle: string;
  products: DropdownOption[];
  selectedProducts?: ProductSelected[];
  onProductsSelectedChange?: (productsSelected: ProductSelected[]) => void;
}

const ProductAccordion: FC<ProductAccordionProps> = ({
  categoryIndex,
  title,
  subtitle,
  products,
  selectedProducts,
  onProductsSelectedChange,
}) => {
  const { t } = useTranslation();
  const [selectedProductList, setSelectedProductList] = useState<ProductSelected[]>(
    selectedProducts ?? []
  );

  const generateKey = (productCategoryIndex: number, productIndex: number) =>
    `product-selector-${productCategoryIndex}-${productIndex}`;

  const addNewProduct = () => {
    setSelectedProductList([...selectedProductList, { product: { ...products[0] }, acres: 0 }]);
  };

  const removeProduct = (index: number) => {
    const newProductList = [...selectedProductList];
    newProductList.splice(index, 1);
    setSelectedProductList(newProductList);
    onProductsSelectedChange?.(newProductList);
  };

  const onProductChange = (index: number, newValue: string) => {
    const newProductList = [...selectedProductList];
    selectedProductList[index].product = products.find((product) => product.value === newValue);
    setSelectedProductList(newProductList);
    onProductsSelectedChange?.(newProductList);
  };

  const handleAcreChange = (index: number, newValue: string) => {
    selectedProductList[index].acres = +newValue;
    const newProductList = [...selectedProductList];
    setSelectedProductList(newProductList);
    onProductsSelectedChange?.(newProductList);
  };

  return (
    <div className={styles['product-accordion']}>
      <Accordion
        title={
          <div className={styles['product-accordion__title-wrapper']}>
            <Avatar firstName={`${categoryIndex}`} lastName="" />
            <div className={styles['product-accordion__text-wrapper']}>
              <span className={styles['product-accordion__title']}>{title}</span>
              <span className={styles['product-accordion__subtitle']}>{subtitle}</span>
            </div>
          </div>
        }
        Icon={ChevronDownIcon}
        defaultOpen
        classNames={{
          container: styles['product-accordion__accordion-container'],
          button: styles['product-accordion__accordion-button'],
          innerContainer: styles['product-accordion__accordion-inner-container'],
        }}
      >
        {selectedProductList.map((selectedProduct, index) => (
          <div
            className={styles['product-accordion__product-wrapper']}
            key={generateKey(categoryIndex, index)}
          >
            <Dropdown
              label={t('bayer-rewards-calculator.product-selector.product-input')}
              name="product-input"
              options={products}
              className={styles['product-accordion__product-input']}
              onChange={(newValue) => onProductChange(index, newValue.target.value)}
              menuPlacement="bottom"
              value={selectedProduct.product}
            />
            <TextField
              inputType="number"
              label={t('bayer-rewards-calculator.product-selector.acres-input')}
              name="acre-input"
              className={styles['product-accordion__acre-input']}
              value={selectedProduct.acres}
              onChange={(event) => handleAcreChange(index, event.target.value)}
            />
            <Button
              icon={TrashCanIcon}
              theme={ButtonThemes.SECONDARY}
              aria-label={t('bayer-rewards-calculator.product-selector.remove-product')}
              className={styles['product-accordion__remove-product']}
              onClick={() => removeProduct(index)}
            />
          </div>
        ))}

        <Button
          icon={AddCircleOutline}
          theme={ButtonThemes.SECONDARY}
          iconPosition={IconPosition.LEFT}
          onClick={addNewProduct}
        >
          {t('bayer-rewards-calculator.product-selector.add-product')}
        </Button>
      </Accordion>
    </div>
  );
};

export default ProductAccordion;
