import {
  AccessLevel,
  FarmPartner,
  PaymentPreferenceOptions,
  Retailer,
  UpdateUserInput,
  UserAccountInfo,
  UserStatus,
} from '../../../../../__generated__/graphql';
import { FC, useEffect, useState } from 'react';
import {
  formatRetailerName,
  formatRetailerPhoneNumbers,
  getPrimaryContact,
} from '../../../../../lib/utils/retailers';
import {
  GET_USER_IS_ADMIN_QUERY,
  GET_USER_ONBOARDING_COMPLETE,
  UPDATE_USER_ACCOUNT_INFO,
} from '../../../../../lib/graphql/UserInformation.gql';
import { getAcreageFromProvince, sortCropsByName } from '../../../../../lib/utils/onboarding';
import { maskPhone, maskPostalCode } from '../../../../../lib/utils/form-validations';
import { OnboardingStep, StepComponentProps } from '../../../../../lib/constants/stepper';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { EditIcon } from '../../../../../assets/icons';
import { GET_SUMMARY_DETAILS_QUERY } from './Summary.gql';
import { PageRoutes } from '../../../../../lib/constants/react-router';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import StepsWrapper from '../../../StepsWrapper';
import styles from './Summary.module.scss';
import { useNavigate } from 'react-router-dom';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const PLACEHOLDER_VALUE = '-';

const Summary: FC<StepComponentProps> = ({ back, goToStep }) => {
  const { t } = useTranslation();
  const { data: userAdminData } = useQuery(GET_USER_IS_ADMIN_QUERY, { fetchPolicy: 'cache-only' });
  const { data: summaryData, loading: summaryLoading } = useQuery(GET_SUMMARY_DETAILS_QUERY, {
    variables: { skipFarm: !userAdminData?.user?.accountInfo?.isAdmin },
  });
  const [setOnboardingComplete, { loading: settingOnboardingComplete }] = useMutation(
    UPDATE_USER_ACCOUNT_INFO,
    {
      refetchQueries: [{ query: GET_USER_ONBOARDING_COMPLETE }],
    }
  );
  const navigate = useNavigate();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [invitedUsers, setInvitedUsers] = useState<UserAccountInfo[]>([]);
  const [partners, setPartners] = useState<FarmPartner[]>([]);

  useEffect(() => {
    const invited: UserAccountInfo[] = [];

    (summaryData?.farm?.users || []).forEach((user) => {
      if (
        user.accountInfo &&
        user.accountInfo.id !== summaryData?.user.accountInfo.id &&
        (user.accountInfo.status === UserStatus.ExternalInvitedPendingSignup ||
          user.accountInfo.status === UserStatus.CrmInvitedPendingSignup ||
          user.accountInfo.status === UserStatus.PendingEmailConfirmation ||
          user.accountInfo.status === UserStatus.Active)
      ) {
        invited.push(user.accountInfo as UserAccountInfo);
      }
    });

    if (summaryData?.farm?.partners) {
      setPartners(
        summaryData.farm.partners.filter((partner) => !partner.isParent) as FarmPartner[]
      );
    }

    setInvitedUsers(invited);
  }, [summaryData?.farm?.users, summaryData?.user?.accountInfo?.id, summaryData?.farm?.partners]);

  const onNext = () => {
    const input: UpdateUserInput = {
      onboardingComplete: true,
    };
    setOnboardingComplete({ variables: { input }, awaitRefetchQueries: true })
      .then(() => {
        navigate(PageRoutes.HOME);
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  const onBack = () => {
    back?.();
  };

  return (
    <StepsWrapper
      title={t('onboarding.summary.title')}
      subTitle={t('onboarding.summary.subtitle')}
      submit={{
        text: t('general.finish'),
        onClick: onNext,
        disabled: summaryLoading || settingOnboardingComplete,
      }}
      back={{
        text: t('general.back'),
        onClick: onBack,
      }}
      loadingQuery={summaryLoading}
    >
      <div className={cx(styles['summary'])}>
        <div className={cx(styles['summary-section'])}>
          <span className={cx(styles['summary-section__title'])}>
            {t('onboarding.steps.my-account.name')}
          </span>
          <div className={cx(styles['summary-sub-section'])}>
            <div className={cx(styles['summary-sub-section__header'])}>
              <span className={cx(styles['summary-sub-section__title'])}>
                {t('onboarding.summary.profile-details')}
              </span>
              <button
                className={cx(styles['summary-section-edit'])}
                type="button"
                onClick={() => {
                  goToStep?.(OnboardingStep.PROFILE_DETAILS);
                }}
                aria-label={t('onboarding.summary.edit-profile-details')}
              >
                <EditIcon className={cx(styles['summary-section-edit__icon'])} aria-hidden="true" />
              </button>
            </div>
            <div className={cx(styles['summary-field'])}>
              <span className={cx(styles['summary-field__name'])}>{t('form.labels.name')}</span>
              <span
                className={cx(styles['summary-field__value'])}
              >{`${summaryData?.user?.accountInfo?.firstName} ${summaryData?.user?.accountInfo?.lastName}`}</span>
            </div>
            <div className={cx(styles['summary-field'])}>
              <span className={cx(styles['summary-field__name'])}>{t('form.labels.email')}</span>
              <span className={cx(styles['summary-field__value'])}>
                {summaryData?.user?.accountInfo?.email}
              </span>
            </div>
            <div className={cx(styles['summary-field'])}>
              <span className={cx(styles['summary-field__name'])}>
                {t('form.labels.phone-number')}
              </span>
              <p className={cx(styles['summary-field__value'])}>
                {summaryData?.user?.accountInfo?.businessPhone &&
                  t('onboarding.summary.business', {
                    phone: summaryData?.user?.accountInfo?.businessPhone
                      ? maskPhone(summaryData?.user?.accountInfo?.businessPhone)
                      : PLACEHOLDER_VALUE,
                  })}
              </p>
              <p className={cx(styles['summary-field__value'])}>
                {t('onboarding.summary.mobile', {
                  phone: summaryData?.user?.accountInfo?.mobilePhone
                    ? maskPhone(summaryData?.user?.accountInfo?.mobilePhone)
                    : PLACEHOLDER_VALUE,
                })}
              </p>
            </div>
            <div className={cx(styles['summary-field'])}>
              <span className={cx(styles['summary-field__name'])}>
                {t('onboarding.summary.mailing-address')}
              </span>
              <span className={cx(styles['summary-field__value'])}>
                {t('format.full-address', {
                  address: summaryData?.user?.accountInfo?.address?.address,
                  city: summaryData?.user?.accountInfo?.address?.city,
                  province: summaryData?.user?.accountInfo?.address?.province,
                  postalCode: maskPostalCode(
                    summaryData?.user?.accountInfo?.address?.postalCode || ''
                  ),
                })}
              </span>
            </div>
          </div>
        </div>
        <div className={cx(styles['summary-section'])}>
          {summaryData?.user?.accountInfo?.isAdmin && (
            <span className={cx(styles['summary-section__title'])}>
              {t('onboarding.steps.my-operation.name')}
            </span>
          )}
          {summaryData?.user?.accountInfo?.isAdmin && (
            <div className={cx(styles['summary-sub-section'])}>
              <div className={cx(styles['summary-sub-section__header'])}>
                <span className={cx(styles['summary-sub-section__title'])}>
                  {t('onboarding.steps.farm-information.name')}
                </span>
                <button
                  className={cx(styles['summary-section-edit'])}
                  type="button"
                  onClick={() => {
                    goToStep?.(OnboardingStep.FARM_INFORMATION);
                  }}
                  aria-label={t('onboarding.summary.edit-farm-information')}
                >
                  <EditIcon
                    className={cx(styles['summary-section-edit__icon'])}
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className={cx(styles['summary-field'])}>
                <span className={cx(styles['summary-field__name'])}>{t('form.labels.name')}</span>
                <span className={cx(styles['summary-field__value'])}>
                  {summaryData?.farm?.farmInfo?.name}
                </span>
              </div>
              <div className={cx(styles['summary-field'])}>
                <span className={cx(styles['summary-field__name'])}>
                  {t('onboarding.summary.operation-address')}
                </span>
                <span className={cx(styles['summary-field__value'])}>
                  {t('format.full-address', {
                    address: summaryData?.farm?.farmInfo?.address?.address,
                    city: summaryData?.farm?.farmInfo?.address?.city,
                    province: summaryData?.farm?.farmInfo?.address?.province,
                    postalCode: maskPostalCode(
                      summaryData?.farm?.farmInfo?.address?.postalCode || ''
                    ),
                  })}
                </span>
              </div>
              <div className={cx(styles['summary-field'])}>
                <span className={cx(styles['summary-field__name'])}>
                  {t('onboarding.summary.payment-preference')}
                </span>
                <span className={cx(styles['summary-field__value'])}>
                  {summaryData?.farm?.farmInfo?.paymentPreference?.preferredOption ===
                  PaymentPreferenceOptions.Mail
                    ? t('onboarding.summary.mailed-cheque', {
                        address: t('format.full-address', {
                          address:
                            summaryData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.address,
                          city: summaryData?.farm?.farmInfo?.paymentPreference?.mailingAddress
                            ?.city,
                          province:
                            summaryData?.farm?.farmInfo?.paymentPreference?.mailingAddress
                              ?.province,
                          postalCode: maskPostalCode(
                            summaryData?.farm?.farmInfo?.paymentPreference?.mailingAddress
                              ?.postalCode || ''
                          ),
                        }),
                      })
                    : t('onboarding.summary.direct-deposit', {
                        email: summaryData?.farm?.farmInfo?.paymentPreference?.directDepositEmail,
                      })}
                </span>
              </div>
            </div>
          )}
          {summaryData?.farm?.farmInfo && summaryData?.user?.accountInfo?.isAdmin && (
            <div className={cx(styles['summary-sub-section'])}>
              <div className={cx(styles['summary-sub-section__header'])}>
                <span className={cx(styles['summary-sub-section__title'])}>
                  {t('onboarding.summary.crop-information')}
                </span>
                <button
                  className={cx(styles['summary-section-edit'])}
                  type="button"
                  onClick={() => {
                    goToStep?.(OnboardingStep.CROP_INFORMATION);
                  }}
                  aria-label={t('onboarding.summary.edit-crop-informtion')}
                >
                  <EditIcon
                    className={cx(styles['summary-section-edit__icon'])}
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className={cx(styles['summary-field'])}>
                <span className={cx(styles['summary-field__name'])}>
                  {t('onboarding.summary.total-farm-acres')}
                </span>
                <span className={cx(styles['summary-field__value'])}>
                  {getAcreageFromProvince(
                    summaryData?.farm?.farmInfo?.address?.province,
                    summaryData?.farm?.farmInfo?.cropInformation?.acrage
                  ) || PLACEHOLDER_VALUE}
                </span>
              </div>
              <div className={cx(styles['summary-field'])}>
                <span className={cx(styles['summary-field__name'])}>
                  {t('onboarding.summary.crop-details')}
                </span>
                <span className={cx(styles['summary-field__value'])}>
                  {(
                    sortCropsByName(t, summaryData?.farm?.farmInfo?.cropInformation?.crops)?.map(
                      (crop) => t(`crops.${crop.name}`)
                    ) || []
                  ).join(', ') || PLACEHOLDER_VALUE}
                </span>
              </div>
            </div>
          )}
          {!!summaryData?.user.retailers?.length && (
            <div className={cx(styles['summary-sub-section'])}>
              <div className={cx(styles['summary-sub-section__header'])}>
                <span className={cx(styles['summary-sub-section__title'])}>
                  {t('onboarding.preferred-retailers.step-title')}
                </span>
                <button
                  className={cx(styles['summary-section-edit'])}
                  type="button"
                  onClick={() => {
                    goToStep?.(OnboardingStep.PREFERRED_RETAILERS);
                  }}
                  aria-label={t('onboarding.summary.edit-preferred-retailers')}
                >
                  <EditIcon
                    className={cx(styles['summary-section-edit__icon'])}
                    aria-hidden="true"
                  />
                </button>
              </div>
              {summaryData?.user.retailers?.map((retailer) => {
                const primaryContact = getPrimaryContact(retailer as Retailer);

                return (
                  <div className={cx(styles['summary-field'])} key={retailer.id}>
                    <span className={cx(styles['summary-field__name'])}>
                      {formatRetailerName(t, retailer)}
                    </span>
                    <span className={cx(styles['summary-field__value'])}>
                      {!!primaryContact && (
                        <>
                          {primaryContact?.firstName} {primaryContact?.lastName}
                          <br />
                        </>
                      )}
                      {t('format.full-address', {
                        address: retailer.address?.address,
                        city: retailer.address?.city,
                        province: retailer.address?.province,
                        postalCode: retailer.address?.postalCode,
                      })}
                    </span>
                    <span className={cx(styles['summary-field__value'])}>
                      {formatRetailerPhoneNumbers(t, retailer.phone, retailer.mobilePhone)}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {summaryData?.user?.accountInfo?.isAdmin && !!partners.length && (
            <div className={cx(styles['summary-sub-section'])}>
              <div className={cx(styles['summary-sub-section__header'])}>
                <span className={cx(styles['summary-sub-section__title'])}>
                  {t('onboarding.steps.partner-permissions.name')}
                </span>
                <button
                  className={cx(styles['summary-section-edit'])}
                  type="button"
                  onClick={() => {
                    goToStep?.(OnboardingStep.PARTNER_PERMISSIONS);
                  }}
                  aria-label={t('onboarding.summary.edit-partners')}
                >
                  <EditIcon
                    className={cx(styles['summary-section-edit__icon'])}
                    aria-hidden="true"
                  />
                </button>
              </div>
              {partners.map((partner) => (
                <div className={cx(styles['summary-field'])} key={partner.partner.farmInfo.id}>
                  <span className={cx(styles['summary-field__name'])}>
                    {t('onboarding.summary.partner')}
                  </span>
                  <span className={cx(styles['summary-field__value'])}>
                    {partner.partner.farmInfo.name}
                    <br />
                    {partner.partner.adminUser && partner.partner.adminUser.accountInfo.email}
                    {partner.partner.adminUser && <br />}
                    {partner.hasAccess === AccessLevel.FullAccess
                      ? t('onboarding.user-full-access')
                      : t('onboarding.user-no-access')}
                  </span>
                </div>
              ))}
            </div>
          )}
          {summaryData?.user?.accountInfo?.isAdmin && !!invitedUsers.length && (
            <div className={cx(styles['summary-sub-section'])}>
              <div className={cx(styles['summary-sub-section__header'])}>
                <span className={cx(styles['summary-sub-section__title'])}>
                  {t('onboarding.steps.user-invitations.name')}
                </span>
                <button
                  className={cx(styles['summary-section-edit'])}
                  type="button"
                  onClick={() => {
                    goToStep?.(OnboardingStep.USER_PERMISSIONS);
                  }}
                  aria-label={t('onboarding.summary.edit-user-invitations')}
                >
                  <EditIcon
                    className={cx(styles['summary-section-edit__icon'])}
                    aria-hidden="true"
                  />
                </button>
              </div>
              {invitedUsers.map((user) => (
                <div className={cx(styles['summary-field'])} key={user.id}>
                  <span className={cx(styles['summary-field__name'])}>
                    {t('onboarding.summary.user')}
                  </span>
                  <span className={cx(styles['summary-field__value'])}>
                    {`${user.firstName} ${user.lastName}`}
                    <br />
                    {user.email || user.crmEmail}
                    <br />
                    {user.accessLevel === AccessLevel.FullAccess
                      ? t('onboarding.user-full-access')
                      : t('onboarding.user-no-access')}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </StepsWrapper>
  );
};

Summary.displayName = 'Summary';

export default Summary;
