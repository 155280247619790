import { FC, useEffect, useRef, useState } from 'react';
import { getFirstStep, getStepById, getSteps, updateStepStatus } from '../../../lib/utils/stepper';
import { Step, StepComponentProps } from '../../../lib/constants/stepper';

import cx from 'classnames';
import { GET_USER_ONBOARDING_DATA } from '../../../lib/graphql/UserInformation.gql';
import { ONBOARDING_STEPS } from '../../../lib/constants/onboarding';
import SetupSidebar from '../SetupSidebar';
import styles from './Setup.module.scss';
import { useQuery } from '@apollo/client';

const Setup: FC = () => {
  const { data: onboardingData } = useQuery(GET_USER_ONBOARDING_DATA);
  const [steps, setSteps] = useState<Step[]>([]);
  const [currentStep, setCurrentStep] = useState<Step | null>(getFirstStep(steps));
  const [stepOptions, setStepOptions] = useState<StepComponentProps | undefined>(undefined);
  const prevStepId = useRef<string | undefined>();

  useEffect(() => {
    if (onboardingData) {
      const userBasedSteps = getSteps(ONBOARDING_STEPS, onboardingData);
      setSteps(userBasedSteps);
      setCurrentStep(getFirstStep(userBasedSteps));
    }
  }, [onboardingData]);

  const handleStepChange = (newStepId: string, nextStep?: Step, backStep?: Step) => {
    setCurrentStep(getStepById(steps, newStepId));
    setStepOptions({
      next: nextStep
        ? () => {
            prevStepId.current = newStepId;
            const updatedSteps = updateStepStatus(steps, prevStepId.current, nextStep?.id);
            setSteps(updatedSteps);
            setCurrentStep(nextStep);
          }
        : undefined,
      back: backStep
        ? () => {
            prevStepId.current = newStepId;
            setCurrentStep(backStep);
          }
        : undefined,
    });
  };

  return (
    <div className={cx(styles['setup'])}>
      <SetupSidebar steps={steps} currentStepId={currentStep?.id} onStepChange={handleStepChange} />
      <div className={cx(styles['setup__content'])}>
        {currentStep?.Component && (
          <currentStep.Component
            next={stepOptions?.next}
            back={stepOptions?.back}
            goToStep={(stepId: string) => {
              setCurrentStep(getStepById(steps, stepId));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Setup;
