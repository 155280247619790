import {
  ProgramCardBadgeAlignment,
  ProgramCardLayout,
  ProgramCardSize,
  Props,
} from './ProgramCard.types';

import { ChevronRightIcon } from '../../../assets/icons';
import cx from 'classnames';
import { ElementType } from 'react';
import { PolymorphicComponent } from '../../../lib/models/GenericComponent.model';
import ProgramCard from './ProgramCard';
import styles from './ProgramCard.module.scss';

const DEFAULT_ELEMENT = 'a';

const ProgramCardLink = <C extends ElementType = typeof DEFAULT_ELEMENT>({
  as,
  badgeAlignment = ProgramCardBadgeAlignment.CENTER,
  description,
  layout = ProgramCardLayout.VERTICAL,
  program,
  size = ProgramCardSize.LG,
  ...props
}: PolymorphicComponent<C, Props>) => {
  const TagName = as || DEFAULT_ELEMENT;

  return (
    <TagName {...props} className={cx(styles['program-card-link'])}>
      <div>
        <ProgramCard
          badgeAlignment={badgeAlignment}
          description={description}
          layout={layout}
          program={program}
          size={size}
        />
      </div>
      <div className={cx(styles['program-card-link__icon-wrapper'])}>
        <ChevronRightIcon className={cx(styles['program-card-link__icon'])} />
      </div>
    </TagName>
  );
};

ProgramCardLink.displayName = 'ProgramCardLink';

export default ProgramCardLink;
