import { GetRetailersInput } from '../../../__generated__/graphql';
import { ReactNode } from 'react';

export interface RetailerContextState {
  filters: GetRetailersInput;
}

export interface RetailerContextProps {
  children: ReactNode;
}

export enum RetailerContextActionTypes {
  UpdateFilters = 'UpdateFilters',
  UpdateOffset = 'UpdateOffset',
}

export type RetailerContextAction = {
  type: RetailerContextActionTypes.UpdateFilters;
  payload: GetRetailersInput;
};

export type RetailerContextDispatch = (action: RetailerContextAction) => void;

export type RetailerContextType = [RetailerContextState, RetailerContextDispatch];
