import { CookieConfig, Cookies } from '../constants/gigya';
import axios from 'axios';
import cookies from 'js-cookie';
import { Endpoints } from '../constants/endpoints';
import { PageRoutes } from '../constants/react-router';
import { v4 as uuid } from 'uuid';

export const LogoutUser = (
  skipRedirect: boolean = false,
  redirectUrl: string = PageRoutes.GIGYA_PROXY_PAGE
) => {
  axios
    .post(
      Endpoints.LOGOUT,
      {},
      {
        withCredentials: true,
        headers: {
          'x-request-id': uuid(),
        },
      }
    )
    .then(() => {
      const val = cookies.get(Cookies.CS_GROWERS_TOKEN_EXPIRATION);
      cookies.remove(Cookies.CS_GROWERS_TOKEN_EXPIRATION, {
        ...CookieConfig(val),
      });
      if (!skipRedirect) {
        window.location.href = redirectUrl;
        window.history.forward();
      }
    })
    .catch();
};
