import dayjs, { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import {
  ordinalDayFullMonthYearEn,
  ordinalDayFullMonthYearFr,
} from '../../../../lib/constants/date-formats';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { FC } from 'react';
import { GET_FARM_ADMIN_QUERY } from '../../../../lib/graphql/FarmInformation.gql';
import Link from '../../../_shared/Link';
import { MailToLink } from '../../../../lib/constants/react-router';
import { mockTotalRewardsEarned } from '../../../../lib/constants/bayer-rewards';
import styles from './RewardsSection.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useQuery } from '@apollo/client';

interface Props {
  year: number;
}

const NoRewardsAccessSection: FC<Props> = ({ year }) => {
  const {
    t
  } = useTranslation();
  const dateFormat = useDateFormat(ordinalDayFullMonthYearEn, ordinalDayFullMonthYearFr);
  const rewardsPeriod = getRewardEarningPeriodDate(year);
  const { data: farmAdminData } = useQuery(GET_FARM_ADMIN_QUERY);

  return (
    <div className={cx(styles['rewards-section'])}>
      <p className={cx(styles['rewards-section__text'], styles['rewards-section__text--overline'])}>
        {t('punctuation.colon', {
          value: t('bayer-value.total-rewards-earned-date', {
            date: dayjs(rewardsPeriod.start).format(dateFormat),
          }),
        })}
      </p>
      <p
        className={cx(
          styles['rewards-section__text'],
          styles['rewards-section__text--heading'],
          styles['rewards-section__text--heading-blur']
        )}
      >
        {mockTotalRewardsEarned}
      </p>
      <p
        className={cx(
          styles['rewards-section__text'],
          styles['rewards-section__text--subtitle'],
          styles['rewards-section__no-access']
        )}
      >
        <span className={cx(styles['rewards-section__no-access'])}>
          {t('bayer-value.no-access')}
        </span>
        <span className={cx(styles['rewards-section__no-access'])}>
          <Trans
            i18nKey="bayer-value.contact-for-access"
            components={[
              <Link
                href={MailToLink(
                  farmAdminData?.farm?.adminUser?.accountInfo?.email ||
                    farmAdminData?.farm?.adminUser?.accountInfo?.crmEmail ||
                    ''
                )}
                className={cx(styles['rewards-section__link'])}
              />,
            ]}
          />
        </span>
      </p>
    </div>
  );
};

export default NoRewardsAccessSection;
