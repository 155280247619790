import { gql } from '../../__generated__';

export const GET_FARM_CHILD_USERS = gql(`
    query GetFarmUsers {
        farm {
            farmInfo {
                id
            }
            users {
                accountInfo {
                    id
                    firstName
                    lastName
                    email
                    crmEmail
                    status
                    profilePictureUrl
                    accessLevel
                    role {
                        id
                        key
                    }
                    responsibilities {
                      id
                      key
                    }
                }
            }
        }
    }
`);

export const INVITE_NEW_USER_MUTATION = gql(`
    mutation InviteNewUser($input: InviteExternalInput!) {
        inviteExternal(input: $input) {
            invitedUser {
                accountInfo {
                  id
                }  
            }
        }
    }
`);

export const INVITE_RECOMMENDED_USER_MUTATION = gql(`
    mutation InviteRecommendedUser($input: InviteRecommendedInput!) {
        inviteRecommended(input: $input) {
            invitedUser {
                accountInfo {
                  id
                } 
            }
        }
    }
`);

export const UPDATE_USER_ACCESS_LEVEL = gql(`
    mutation UpdateUserAccessLevel($input: UpdateUserAccessLevelInput!) {
        updateUserAccessLevel(input: $input) {
          user {
            accountInfo {
              id
            }
          }
        }
    }
`);

// TODO : It's not clear if it's going to be implemented.
export const REMOVE_USER_FROM_FARM = gql(`
    mutation RemoveUserFromFarm($userId: String!) {
        removeUserFromFarm(userId: $userId) {
            removedUser {
                accountInfo {
                    id
                }
            }
        }
    }
`);
