import jwt_decode from 'jwt-decode';
import { JwtPayload } from '../types/onboarding';
import { QueryParams } from '../constants/react-router';

/**
 * Function that decodes Jwt token
 * @param token
 */
// eslint-disable-next-line consistent-return
export const decodeToken = (token: string) => {
  try {
    const decoded = jwt_decode(token);
    if (decoded) {
      return decoded;
    }
    return '';
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't get user Customer Account Code from JWT: ", e);
  }
};

/**
 * Function that extracts search param from URL
 * @param param - Query param
 */
export const getJwtFromQuery = (searchParams: URLSearchParams, param: QueryParams) => {
  const tokenParam = searchParams.get(param);

  if (tokenParam) {
    const decode = decodeToken(tokenParam) as JwtPayload;

    if (decode) {
      return decode;
    }
  }
  return undefined;
};
