import {
  CalculatorCategory,
  PreFillOption,
  ProductBreakdown,
  ProductMainGroup,
  ProductSelected,
} from '../../../../lib/types/calculator';
import { FC, useEffect, useState } from 'react';

import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import { CalculatorProducts } from '../../../../__generated__/graphql';
import { FULL_PRODUCT_CATEGORIES_ARRAY } from '../../../../lib/constants/calculator';
import { GET_CALCULATOR_PRODUCTS } from '../../../../lib/graphql/Calculator.gql';
import ProductAccordion from '../../ProductAccordion/ProductAccordion';
import { RestoreIcon } from '../../../../assets/icons';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { type StepComponentProps } from '../../../../lib/constants/stepper';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from './SelectProducts.module.scss';
import TotalAmountBreakdown from '../../TotalAmountBreakdown/TotalAmountBreakdown';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useLazyQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const SelectProducts: FC<StepComponentProps> = ({ back, next }: StepComponentProps) => {
  const { t } = useTranslation();
  const [productOptions, setProductOptions] = useState<CalculatorProducts[]>([]);
  const [productCategories, setProductCategories] = useState<CalculatorCategory[]>(
    FULL_PRODUCT_CATEGORIES_ARRAY
  );
  const [getCalculatorProducts] = useLazyQuery(GET_CALCULATOR_PRODUCTS);
  const [{ preFill, selectedCrops }, dispatchCalculator] = useCalculatorContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [selectedHerbicides, setSelectedHerbicides] = useState<ProductSelected[]>([]);
  const [selectedFungicides, setSelectedFungicides] = useState<ProductSelected[]>([]);
  const [selectedTraits, setSelectedTraits] = useState<ProductSelected[]>([]);
  const [selectedSeedTreatments, setSelectedSeedTreatments] = useState<ProductSelected[]>([]);

  useEffect(() => {
    if (selectedCrops && preFill) {
      getCalculatorProducts({
        variables: {
          input: {
            prefill: preFill === PreFillOption.YES,
            crops: selectedCrops,
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            setProductOptions(data.calculatorProducts);
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  }, [preFill, selectedCrops]);

  const mapProductsToCategory = (
    category: CalculatorCategory,
    options: CalculatorProducts[]
  ): CalculatorCategory => {
    const matchingOption = options.find((option) => option.group === category.key);

    if (matchingOption) {
      return {
        ...category,
        products: matchingOption.products.map((product) => ({
          value: product.sku,
          label: product.name,
        })),
      };
    }

    return category;
  };

  const flatProductList = productOptions.flatMap((productGroup) => productGroup.products);

  const fullProductOptionsList = [
    ...selectedFungicides,
    ...selectedHerbicides,
    ...selectedSeedTreatments,
  ];

  const selectedProductsFullList = fullProductOptionsList.map((product) =>
    flatProductList.find((flatProduct) => product.product?.value === flatProduct.sku)
  );

  const selectedProductsBreakdownList: ProductBreakdown[] = selectedProductsFullList.map(
    (product, index) => ({
      product,
      acres: fullProductOptionsList[index].acres,
    })
  );

  const getSelectedProducts = (productCategory: ProductMainGroup) => {
    switch (productCategory) {
      case ProductMainGroup.FUNGICIDES:
        return selectedFungicides;
      case ProductMainGroup.HERBICIDES:
        return selectedHerbicides;
      case ProductMainGroup.SEED_TREATMENT:
        return selectedSeedTreatments;
      case ProductMainGroup.TRAIT:
      default:
        return selectedTraits;
    }
  };

  const handleProductChange = (
    productCategory: ProductMainGroup,
    selectedProducts: ProductSelected[]
  ) => {
    switch (productCategory) {
      case ProductMainGroup.FUNGICIDES:
        setSelectedFungicides(selectedProducts);
        break;
      case ProductMainGroup.HERBICIDES:
        setSelectedHerbicides(selectedProducts);
        break;
      case ProductMainGroup.SEED_TREATMENT:
        setSelectedSeedTreatments(selectedProducts);
        break;
      case ProductMainGroup.TRAIT:
      default:
        setSelectedTraits(selectedProducts);
        break;
    }
  };

  useEffect(() => {
    if (productOptions.length) {
      setProductCategories((prevCategories) =>
        prevCategories.map((category) => mapProductsToCategory(category, productOptions))
      );
    }
  }, [productOptions]);

  useEffect(() => {
    dispatchCalculator({
      type: CalculatorContextActionTypes.UpdateSelectedProducts,
      payload: {
        selectedProducts: selectedProductsBreakdownList,
      },
    });
  }, [selectedFungicides, selectedHerbicides, selectedTraits, selectedSeedTreatments]);

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.select-products')}
      subTitle={
        <div className={styles['select-products__subtitle-wrapper']}>
          <span>{t('bayer-rewards-calculator.select-products.description')}</span>
          <Button
            theme={ButtonThemes.SECONDARY}
            icon={RestoreIcon}
            onClick={() => {
              setSelectedFungicides([]);
              setSelectedHerbicides([]);
              setSelectedTraits([]);
              setSelectedSeedTreatments([]);
            }}
          >
            {t('bayer-rewards-calculator.select-products.reset-values')}
          </Button>
        </div>
      }
      back={{ text: t('general.back'), onClick: back }}
      submit={{ text: t('general.next'), onClick: next }}
      classNames={{
        content: styles['select-products'],
        wrapper: styles['select-products__steps-wrapper'],
        form: styles['select-products__steps-form'],
      }}
    >
      <div className={styles['select-products__content']}>
        <div className={styles['select-products__accordion-container']}>
          {productCategories
            .filter((category) => category.products.length > 0)
            .map((category, index) => (
              <ProductAccordion
                categoryIndex={index + 1}
                title={t(category.title)}
                subtitle={t(category.subtitle)}
                products={category.products}
                key={uuid()}
                selectedProducts={getSelectedProducts(category.key)}
                onProductsSelectedChange={(newSelectedProducts) =>
                  handleProductChange(category.key, newSelectedProducts)
                }
              />
            ))}
        </div>
        <TotalAmountBreakdown
          estimatedRewards={3000}
          products={selectedProductsFullList.map((product, index) => ({
            label: product?.name ?? '',
            amount: (product?.pricePerAcre ?? 0) * fullProductOptionsList[index].acres,
          }))}
        />
      </div>
    </StepsWrapper>
  );
};

SelectProducts.displayName = 'SelectProducts';

export default SelectProducts;
