import { DropdownDefaultStyles } from '../constants/react-select';
import { StylesConfig } from 'react-select';

export const getReactSelectStyles = (overrides?: any): StylesConfig => ({
  clearIndicator: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.clearIndicator(),
    ...(overrides?.clearIndicator?.(currentState) || {}),
  }),
  container: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.container(),
    ...(overrides?.container?.(currentState) || {}),
  }),
  control: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.control(currentState),
    ...(overrides?.control?.(currentState) || {}),
  }),
  dropdownIndicator: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.dropdownIndicator(currentState),
    ...(overrides?.dropdownIndicator?.(currentState) || {}),
  }),
  indicatorsContainer: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.indicatorsContainer(),
    ...(overrides?.indicatorsContainer?.(currentState) || {}),
  }),
  indicatorSeparator: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.indicatorSeparator(),
    ...(overrides?.indicatorSeparator?.(currentState) || {}),
  }),
  input: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.input(),
    ...(overrides?.input?.(currentState) || {}),
  }),
  menu: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.menu(),
    ...(overrides?.menu?.(currentState) || {}),
  }),
  menuList: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.menuList(),
    ...(overrides?.menuList?.(currentState) || {}),
  }),
  option: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.option(currentState),
    ...(overrides?.option?.(currentState) || {}),
  }),
  placeholder: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.placeholder(currentState),
    ...(overrides?.placeholder?.(currentState) || {}),
  }),
  singleValue: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.singleValue(currentState),
    ...(overrides?.singleValue?.(currentState) || {}),
  }),
  valueContainer: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.valueContainer(),
    ...(overrides?.valueContainer?.(currentState) || {}),
  }),
  multiValueRemove: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.multiValueRemove(),
    ...(overrides?.multiValueRemove?.(currentState) || {}),
  }),
  multiValue: (providedStyles, currentState) => ({
    ...providedStyles,
    ...DropdownDefaultStyles.multiValue(currentState),
    ...(overrides?.multiValue?.(currentState) || {}),
  }),
});
