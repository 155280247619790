import { gql } from '../../__generated__';

export const GET_USER_ACCOUNT_INFO = gql(`
  query GetUserAccountInfo {
    user {
      accountInfo {
        id
        gigyaId
        isAdmin
        firstName
        language
        lastName
        email
        businessPhone
        mobilePhone
        profilePictureId
        profilePictureUrl
        role {
          id
          key
        }
        responsibilities {
          id
          key
        }
        address {
          address
          address2
          city
          province
          postalCode
          placeId
        }
      }
    }
  }
`);

export const GET_USER_IS_ADMIN_QUERY = gql(`
  query UserIsAdmin {
    user {
      accountInfo {
        id
        isAdmin
      }
      hasAccess
    }
  }
`);

export const GET_USER_ONBOARDING_COMPLETE = gql(`
  query GetUserOnboardingComplete {
    user {
      accountInfo {
        id
        firstName
        isAdmin
        accessLevel
        onboardingComplete
        instructionsCarouselViewed
      }
      hasAccess
    }
  }
`);

export const UPDATE_USER_ACCOUNT_INFO = gql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        accountInfo {
          id
        }
      }
    }
  }
`);

export const GET_USER_REWARDS_ACCESS_QUERY = gql(`
  query UserRewardsAccess {
    user {
      accountInfo {
        id
        firstName
        isAdmin
        accessLevel
      }
      hasAccess
    }
  }
`);

export const SIGNUP_USER = gql(`
  mutation SignupUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      registeredUser{
        accountInfo {
          id
          gigyaId
          status
        }
      }
    }
  }
`);

export const RESET_USER_PASSWORD = gql(`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      resetPassword
    }
  }
`);

export const GET_USER_ONBOARDING_DATA = gql(`
  query UserOnboardingData {
    user {
      accountInfo {
        id
        isAdmin
      }
      hasAccess
    }
    farm {
      farmInfo {
        id
      }
      partners {
        partner {
          farmInfo {
            id
          }
        }
        isParent
      }
    }
  }
`);

export const GET_USER_LANGUAGE = gql(`
  query GetUserLanguage {
    user {
      accountInfo {
        id
        language
      }
    }
  }
`);

export const GET_USER_IDENTITY = gql(`
  query GetUserIdentity {
    user {
      accountInfo {
        id
        email
      }
    }
    farm {
      farmInfo {
        id
      }
    }
  }
`);

export const GET_ROLES_RESPONSIBILITIES = gql(`
  query RolesAndResponsibilities {
    responsibilities {
      id
      key
    }
    roles {
      id
      key
    }
  }
`);
