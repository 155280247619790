import {
  AllFiltersForm,
  DateRangeFiltersForm,
  DateRangeOptions,
  FilterForm,
  QueryForm,
} from '../../../lib/constants/forms';
import { AllFiltersOptions, FilterModalView } from './FilterSection.const';
import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { ChevronLeftIcon, FilterIcon } from '../../../assets/icons';
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useForm, UseFormReset } from 'react-hook-form';

import { ActionCta } from '../../../lib/types/button';
import AllFiltersModalView from './FilterModal/AllFiltersModalView';
import Button from '../../../components/_shared/Button/Button';
import cx from 'classnames';
import DateRangeFilterModalView from './FilterModal/DateRangeFilterModalView';
import Filter from '../../../components/_shared/Filter/Filter';
import styles from './FilterSection.module.scss';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

interface Props {
  dropdownOptions?: AllFiltersOptions;
  formState: FilterForm;
  resetParentForm: UseFormReset<QueryForm>;
  setParentFormState: Dispatch<SetStateAction<FilterForm>>;
}

const FilterSectionMobile: FC<Props> = ({
  dropdownOptions,
  formState,
  resetParentForm,
  setParentFormState,
}) => {
  const { t } = useTranslation();
  const allFilterFormId = useRef<string>(uuid());
  const dateRangeFilterFormId = useRef<string>(uuid());
  const [modalView, setModalView] = useState<FilterModalView>(FilterModalView.ALL);
  const [dateError, setDateError] = useState<boolean>(false);

  const {
    register: allFiltersRegister,
    reset: allFiltersReset,
    handleSubmit: allFiltersHandleSubmit,
    watch: allFiltersWatch,
    setValue: allFiltersSetValue,
  } = useForm<AllFiltersForm>({
    defaultValues: {
      product: formState.product,
      retailer: formState.retailer,
      program: formState.program,
      dateRange: formState.dateRange,
      purchaser: formState.purchaser,
    },
  });
  const {
    reset: dateRangeFiltersReset,
    control: dateRangeFiltersControl,
    watch: dateRangeFiltersWatch,
  } = useForm<DateRangeFiltersForm>({
    defaultValues: {
      startDate: formState.customDateRangeStart,
      endDate: formState.customDateRangeEnd,
    },
  });

  const onSubmit = () => {
    const allFiltersData = allFiltersWatch();
    const dateRangeFiltersData = dateRangeFiltersWatch();

    setParentFormState((currentState) => ({
      ...currentState,
      product: allFiltersData.product,
      program: allFiltersData.program,
      purchaser: allFiltersData.purchaser,
      retailer: allFiltersData.retailer,
      dateRange: allFiltersData.dateRange,
      customDateRangeStart: dateRangeFiltersData.startDate,
      customDateRangeEnd: dateRangeFiltersData.endDate,
    }));
  };

  const renderModalView = () => {
    if (modalView === FilterModalView.DATE) {
      return (
        <DateRangeFilterModalView
          formControl={dateRangeFiltersControl}
          formId={dateRangeFilterFormId.current}
          watch={dateRangeFiltersWatch}
          setError={setDateError}
        />
      );
    }

    return (
      <AllFiltersModalView
        dropdownOptions={dropdownOptions}
        register={allFiltersRegister}
        formId={allFilterFormId.current}
        setModalView={setModalView}
        onSubmit={allFiltersHandleSubmit(onSubmit)}
      />
    );
  };

  const resetFilters = (resetParent: boolean = false, submit: boolean = false) => {
    allFiltersReset({
      product: [],
      program: [],
      purchaser: [],
      retailer: [],
      dateRange: DateRangeOptions.ALL,
    });
    dateRangeFiltersReset({
      startDate: undefined,
      endDate: undefined,
    });

    if (resetParent) {
      resetParentForm({
        query: '',
      });
    }

    if (submit) {
      onSubmit();
    }
  };

  const onModalClose = () => {
    setModalView(FilterModalView.ALL);
  };

  const onModalOpen = () => {
    allFiltersReset({
      product: formState.product,
      program: formState.program,
      purchaser: formState.purchaser,
      retailer: formState.retailer,
      dateRange: formState.dateRange,
    });
    dateRangeFiltersReset({
      startDate:
        formState.dateRange === DateRangeOptions.CUSTOM
          ? formState.customDateRangeStart
          : undefined,
      endDate:
        formState.dateRange === DateRangeOptions.CUSTOM ? formState.customDateRangeEnd : undefined,
    });
  };

  const getModalTitle = () => {
    if (modalView === FilterModalView.DATE) {
      return t('filter.date-range-options.custom');
    }

    return t('filter.all-filters');
  };

  const getModalPrimaryCta = (): ActionCta => {
    if (modalView === FilterModalView.DATE) {
      const { startDate, endDate } = dateRangeFiltersWatch();

      return {
        label: t('general.apply'),
        buttonType: 'submit',
        buttonFormId: dateRangeFilterFormId.current,
        disabled: !startDate || !endDate || dateError,
        action: () => {
          if (startDate && endDate) {
            allFiltersSetValue('dateRange', DateRangeOptions.CUSTOM);
          }
          onSubmit();
        },
      };
    }

    return {
      label: t('general.apply'),
      buttonType: 'submit',
      buttonFormId: allFilterFormId.current,
      action: () => {
        onSubmit();
      },
    };
  };

  const getModalSecondaryCta = (): ActionCta => {
    if (modalView === FilterModalView.DATE) {
      return {
        label: t('general.clear'),
        buttonType: 'button',
        action: () => {
          dateRangeFiltersReset({
            startDate: undefined,
            endDate: undefined,
          });
          allFiltersSetValue('dateRange', DateRangeOptions.ALL);
        },
      };
    }

    return {
      label: t('filter.reset-all-filters'),
      buttonType: 'button',
      action: () => {
        resetFilters();
      },
    };
  };

  const getModalHeaderContent = () => {
    if (modalView === FilterModalView.DATE) {
      return (
        <button
          onClick={() => {
            setModalView(FilterModalView.ALL);
          }}
          type="button"
          className={cx(styles['mobile-filters__back-button-wrapper'])}
          aria-label={t('general.back')}
        >
          <ChevronLeftIcon
            className={cx(styles['mobile-filters__back-button'])}
            aria-hidden="true"
          />
        </button>
      );
    }

    return undefined;
  };

  return (
    <div className={cx(styles['filter-section__row-wrapper'])}>
      <div className={cx(styles['filter-section__filters-wrapper'])}>
        <span className={cx(styles['filter-section__subtitle'])}>{t('filter.filter-by')}</span>
      </div>
      <div className={cx(styles['filter-section__all-filters'])}>
        <Filter
          label={t('filter.all-filters')}
          icon={FilterIcon}
          iconPosition={IconPosition.LEFT}
          className={cx(styles['filter-section__item'])}
          modalProps={{
            title: getModalTitle(),
            headerContent: getModalHeaderContent(),
            primaryCta: getModalPrimaryCta(),
            secondaryCta: getModalSecondaryCta(),
          }}
          onModalClose={onModalClose}
          onModalOpen={onModalOpen}
        >
          {renderModalView()}
        </Filter>
        <Button
          theme={ButtonThemes.TEXT_LINK}
          type="button"
          onClick={() => {
            resetFilters(true, true);
          }}
          className={cx(styles['filter-section__reset-button'])}
        >
          {t('filter.reset-all-filters')}
        </Button>
      </div>
    </div>
  );
};

export default FilterSectionMobile;
