import { useEffect, useRef } from 'react';

const useEventListener = (
  eventName: string,
  handler: (e: any) => void,
  element: HTMLElement | typeof global = global,
  options: AddEventListenerOptions = {}
) => {
  const savedHandler = useRef<HTMLElement | ((e: any) => void) | null>(null);
  const { capture, passive, once } = options;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return undefined;
    }

    const eventListener = (event: any) => {
      if (typeof savedHandler.current === 'function') {
        savedHandler.current(event);
      }
    };
    const opts = { capture, passive, once };
    element.addEventListener(eventName, eventListener, opts);
    return () => {
      element.removeEventListener(eventName, eventListener, opts);
    };
  }, [eventName, element, capture, passive, once]);
};

export default useEventListener;
