import { FC, useEffect } from 'react';

import { ButtonThemes } from '../../../lib/constants/components';
import { Cookies } from '../../../lib/constants/gigya';
import cookies from 'js-cookie';
import cx from 'classnames';
import image from '../../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../../components/ImageWithContent';
import LinkButton from '../../../components/_shared/LinkButton';
import { LogoutUser } from '../../../lib/clients/gigya-client';
import { PageRoutes } from '../../../lib/constants/react-router';
import PreAuthContainer from '../../../components/PreAuthContainer';
import { Link as RouterLink } from 'react-router-dom';
import styles from '../Login.module.scss';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

const GigyaError: FC = () => {
  usePageTitle('gigya-error');
  const { t } = useTranslation();

  useEffect(() => {
    const expirationTime = cookies.get(Cookies.CS_GROWERS_TOKEN_EXPIRATION);
    if (expirationTime) {
      LogoutUser(true);
    }
  }, []);

  return (
    <PreAuthContainer>
      <ImageWithContent imageSrc={image} imageAlt={t('images.farmers')}>
        <div className={cx(styles['login-container'])}>
          <div>
            <h1 className={cx(styles['login-container__header'])}>
              {t('login.gigya-error.title')}
            </h1>
            <p className={cx(styles['login-container__subtitle'])}>
              {t('login.gigya-error.description')}
            </p>
            <LinkButton
              className={cx(styles['login-container__item'])}
              theme={ButtonThemes.PRIMARY}
              as={RouterLink}
              to={`/${PageRoutes.LOGIN}`}
            >
              {t('login.login')}
            </LinkButton>
          </div>
        </div>
      </ImageWithContent>
    </PreAuthContainer>
  );
};

GigyaError.displayName = 'GigyaError';

export default GigyaError;
