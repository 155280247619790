import { gql } from '../../__generated__';

export const GET_FARM_PROMOTIONS = gql(`
query FarmPromotions {
    farm {
        farmInfo{
            id
        }
        activePromotions {
            id      
            isRedeemed
            promotion {
                id
                imageUrl
                key
            }      
        }
    }
}`
);

export const UPDATE_PROMOTION = gql(`
mutation UpdateFarmPromotion($input: PromotionToFarmInput!) {
  farmPromotionUpdate(input: $input) {
    id
  }
}`
);


