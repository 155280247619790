import { LDContext, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';
import { useLDClientContext } from '../contexts/launch-darkly';

export type FlagOptions = {
  flagKey: string;
  defaultValue?: LDFlagValue;
};

export const useFlag = ({ flagKey, defaultValue }: FlagOptions) => {
  const client = useLDClientContext();
  const [flag, setFlag] = useState<LDFlagValue>(client?.variation(flagKey, defaultValue));
  const setContext = (context: LDContext) =>
    client?.identify(context, undefined, () => setFlag(client.variation(flagKey, defaultValue)));

  useEffect(() => {
    client?.on(`change:${flagKey}`, setFlag);

    return () => {
      client?.off(`change:${flagKey}`, () => ({}));
    };
  }, [client, flagKey]);

  return { flag, setContext };
};

export const useFlags = (ldFlags: Array<FlagOptions>) => {
  const client = useLDClientContext();

  const initFlags = () =>
    ldFlags.reduce(
      (acc, { flagKey, defaultValue }) => ({
        ...acc,
        [flagKey]: client?.variation(flagKey, defaultValue),
      }),
      {}
    );

  const [flags, setFlags] = useState<Record<string, LDFlagValue>>(initFlags());
  const setContext = (context: LDContext) =>
    client?.identify(context, undefined, () => setFlags(initFlags()));

  useEffect(() => {
    ldFlags.forEach(({ flagKey }) => {
      client?.on(`change:${flagKey}`, (val) =>
        setFlags((newFlags) => ({ ...newFlags, [flagKey]: val }))
      );
    });

    return () => {
      ldFlags.forEach(({ flagKey }) => client?.off(`change:${flagKey}`, () => ({})));
    };
  }, [client, ldFlags]);

  return { flags, setContext };
};
