import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import GetStartedCarousel from '../../../components/GetStartedCarousel';
import Modal from '../../../components/_shared/Modal';
import styles from './SetupCarouselModal.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  onClose(): void;
}

const SetupCarouselModal: FC<Props> = ({ open, onClose }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const { t } = useTranslation();

  const onNextButtonClick = () => {
    if (currentSlideIndex !== 2) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      onClose();
    }
  };

  const onSkipIntroClick = () => {
    onClose();
  };

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, [open]);

  return (
    <Modal
      isVisible={open}
      hide={onClose}
      title={t('onboarding.carousel-cta.title')}
      primaryCta={{ label: t('general.next'), action: onNextButtonClick, buttonType: 'button' }}
      secondaryCta={{ label: t('general.skip'), action: onSkipIntroClick, buttonType: 'button' }}
      classNames={{ modalContent: cx(styles['modal-content']) }}
    >
      {open && (
        <div className={cx(styles['carousel-wrapper'])}>
          <GetStartedCarousel
            activeSlideIndex={currentSlideIndex}
            onSlideChange={(index: number) => {
              setCurrentSlideIndex(index);
            }}
            insideModal
          />
        </div>
      )}
    </Modal>
  );
};

export default SetupCarouselModal;
