/* eslint-disable no-param-reassign */
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import GetStartedSlide, { GetStartedSlideHandle } from './GetStartedSlide';
import { Trans, useTranslation } from 'react-i18next';

import Carousel from '../_shared/Carousel';
import cx from 'classnames';
import { GET_USER_NAME } from './GetStartedCarousel.gql';
import introDashboardEn from '../../assets/videos/get-started-carousel/dashboard-intro-en.mp4';
import introDashboardFr from '../../assets/videos/get-started-carousel/dashboard-intro-fr.mp4';
import introOrdersEn from '../../assets/videos/get-started-carousel/orders-intro-en.mp4';
import introOrdersFr from '../../assets/videos/get-started-carousel/orders-intro-fr.mp4';
import introRewardsEn from '../../assets/videos/get-started-carousel/rewards-intro-en.mp4';
import introRewardsFr from '../../assets/videos/get-started-carousel/rewards-intro-fr.mp4';
import { Language } from '../../lib/constants/i18n';
import outroDashboardEn from '../../assets/videos/get-started-carousel/dashboard-outro-en.mp4';
import outroDashboardFr from '../../assets/videos/get-started-carousel/dashboard-outro-fr.mp4';
import outroOrdersEn from '../../assets/videos/get-started-carousel/orders-outro-en.mp4';
import outroOrdersFr from '../../assets/videos/get-started-carousel/orders-outro-fr.mp4';
import outroRewardsEn from '../../assets/videos/get-started-carousel/rewards-outro-en.mp4';
import outroRewardsFr from '../../assets/videos/get-started-carousel/rewards-outro-fr.mp4';
import styles from './GetStartedCarousel.module.scss';
import { useQuery } from '@apollo/client';

export interface Props {
  activeSlideIndex?: number;
  onSlideChange?(slideIndex: number): void;
  insideModal?: boolean;
}

export interface GetStartedCarouselHandle {
  playExitDesktop(
    callback: () => void,
    wrapperRef: HTMLDivElement | null,
    buttonWrapperRef: HTMLDivElement | null
  ): void;
  playExitMobile(callback: () => void, wrapperRef: HTMLDivElement | null): void;
}

const GetStartedCarousel = forwardRef<GetStartedCarouselHandle, Props>(
  ({ activeSlideIndex, onSlideChange, insideModal }, ref) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    const carouselRef = useRef<HTMLDivElement>(null);
    const slide1Ref = useRef<GetStartedSlideHandle>(null);
    const slide2Ref = useRef<GetStartedSlideHandle>(null);
    const slide3Ref = useRef<GetStartedSlideHandle>(null);
    const prevSlideIndex = useRef<number | null | undefined>(null);
    const activeSlideIndexRef = useRef<number | null | undefined>(activeSlideIndex);
    const { data: user } = useQuery(GET_USER_NAME);

    const getSlideRefFromIndex = (index: number | null | undefined) => {
      if (!index) {
        return slide1Ref;
      } else if (index === 1) {
        return slide2Ref;
      }

      return slide3Ref;
    };

    const prepareIntro = (slideRef: GetStartedSlideHandle | null) => {
      if (slideRef && slideRef.introVideo && slideRef.outroVideo) {
        slideRef.introVideo.style.opacity = '1';
        slideRef.outroVideo.style.opacity = '0';
        slideRef.introVideo.currentTime = 0;
      }
    };

    const playIntro = (slideRef: GetStartedSlideHandle | null) => {
      if (slideRef && slideRef.introVideo && slideRef.outroVideo) {
        prepareIntro(slideRef);
        const playPromise = slideRef.introVideo.play();
        playPromise.catch(() => {
          // Handles the case where browsers don't allow autoplay
          if (slideRef.introVideo) {
            slideRef.introVideo.autoplay = true;
          }
        });
      }
    };

    const playOutro = (slideRef: GetStartedSlideHandle | null) => {
      if (slideRef && slideRef.introVideo && slideRef.outroVideo) {
        slideRef.outroVideo.style.opacity = '1';
        slideRef.introVideo.style.opacity = '0';
        slideRef.outroVideo.currentTime = 0;

        const playPromise = slideRef.outroVideo.play();
        playPromise.catch(() => {
          // Handles the case where browsers don't allow autoplay
          if (slideRef.outroVideo) {
            slideRef.outroVideo.autoplay = true;
          }
        });
      }
    };

    const handleSlideTransitionStart = (eventSlideIndex: number) => {
      if (eventSlideIndex === activeSlideIndexRef.current) {
        const currentSlideRef = getSlideRefFromIndex(eventSlideIndex);
        playIntro(currentSlideRef.current);
      }
    };

    const animateFinalOutroSlide = (slideElement: HTMLElement) =>
      new Promise((resolve) => {
        const handleAnimationEnd = () => {
          resolve(slideElement);
        };
        slideElement.addEventListener('animationend', handleAnimationEnd, { once: true });
        slideElement.classList.add('outro');
      });

    const transitionFinalOutroOpacity = (slideElement: HTMLElement) =>
      new Promise((resolve) => {
        const handleTransitionEnd = () => {
          resolve(slideElement);
        };
        slideElement.addEventListener('transitionend', handleTransitionEnd, { once: true });
        slideElement.style.opacity = '0';
      });

    useEffect(() => {
      activeSlideIndexRef.current = activeSlideIndex;
      const prevSlideRef = getSlideRefFromIndex(prevSlideIndex.current);
      const currentSlideRef = getSlideRefFromIndex(activeSlideIndex);

      if (prevSlideIndex.current === null && currentSlideRef.current?.introVideo) {
        playIntro(prevSlideRef.current);
      } else {
        playOutro(prevSlideRef.current);
        prepareIntro(currentSlideRef.current);
      }

      prevSlideIndex.current = activeSlideIndex;
    }, [activeSlideIndex]);

    useEffect(() => {
      const currentSlideRef = getSlideRefFromIndex(activeSlideIndex);
      playIntro(currentSlideRef.current);
    }, [language]);

    useImperativeHandle(ref, () => ({
      playExitDesktop(callback, wrapperRef, buttonWrapperRef) {
        if (carouselRef.current && buttonWrapperRef) {
          transitionFinalOutroOpacity(buttonWrapperRef);
          carouselRef.current.style.transition = 'none';
          carouselRef.current.style.transform = 'none';
          playOutro(slide3Ref.current);
          const slides = Array.from(carouselRef.current.children);
          carouselRef.current?.parentElement?.classList?.add('outro-wrapper');
          animateFinalOutroSlide(slides?.[2] as HTMLElement)
            .then(() => animateFinalOutroSlide(slides?.[1] as HTMLElement))
            .then(() => animateFinalOutroSlide(slides?.[0] as HTMLElement))
            .then(() => {
              if (wrapperRef) {
                transitionFinalOutroOpacity(wrapperRef).then(() => {
                  callback();
                });
              }
            });
        }
      },
      playExitMobile(callback, wrapperRef) {
        if (wrapperRef) {
          transitionFinalOutroOpacity(wrapperRef).then(() => {
            callback();
          });
        }
      },
    }));

    return (
      <div className={cx(styles['gsc'])}>
        <Carousel
          ref={carouselRef}
          activeSlideIndex={activeSlideIndex}
          onSlideChange={onSlideChange}
          onSlideTransitionStart={handleSlideTransitionStart}
        >
          <GetStartedSlide
            ref={slide1Ref}
            introVideoSrc={language === Language.En ? introRewardsEn : introRewardsFr}
            outroVideoSrc={language === Language.En ? outroRewardsEn : outroRewardsFr}
            heading={t('onboarding.get-started-carousel.slide-1.heading', {
              name: user?.user.accountInfo.firstName,
            })}
            description={t('onboarding.get-started-carousel.slide-1.description')}
            insideModal={insideModal}
          />
          <GetStartedSlide
            ref={slide2Ref}
            introVideoSrc={language === Language.En ? introDashboardEn : introDashboardFr}
            outroVideoSrc={language === Language.En ? outroDashboardEn : outroDashboardFr}
            heading={t('onboarding.get-started-carousel.slide-2.heading')}
            description={
              <Trans
                i18nKey="onboarding.get-started-carousel.slide-2.description"
                components={[<sup />]}
              />
            }
            insideModal={insideModal}
          />
          <GetStartedSlide
            ref={slide3Ref}
            introVideoSrc={language === Language.En ? introOrdersEn : introOrdersFr}
            outroVideoSrc={language === Language.En ? outroOrdersEn : outroOrdersFr}
            heading={t('onboarding.get-started-carousel.slide-3.heading')}
            description={t('onboarding.get-started-carousel.slide-3.description')}
            insideModal={insideModal}
          />
        </Carousel>
      </div>
    );
  }
);

GetStartedCarousel.displayName = 'GetStartedCarousel';

export default GetStartedCarousel;
