import { FC, useEffect, useState } from 'react';
import {
  filterOtherProgramsBreakdown,
  getProgramYearFromUrl,
  mapBreakdownToColorIcon,
  mapProgramBreakdownToChartEntries,
} from '../../../../../lib/utils/programs';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../../lib/graphql/ProgramRewards.gql';
import Link from '../../../../../components/_shared/Link';
import { LinkUrls } from '../../../../../lib/constants/react-router';
import { ProgramBreakdownWithColorIcon } from '../../../../../lib/models/Program.model';
import ProgramCard from '../../../../../components/_shared/ProgramCard';
import ProgramDoughnutChart from '../../../../../components/_shared/ProgramDoughnutChart';
import Spinner from '../../../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../../../lib/constants/components';
import styles from './BreakdownSection.module.scss';
import { useQuery } from '@apollo/client';

interface Props {}

const BreakdownSection: FC<Props> = () => {
  const { t } = useTranslation();
  const { data: breakdownData, loading: loadingBreakdown } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    { variables: { input: { year: getProgramYearFromUrl() } } }
  );
  const [groupedBreakdown, setGroupedBreakdown] = useState<ProgramBreakdownWithColorIcon[]>([]);

  useEffect(() => {
    if (breakdownData?.enrolledProgramsInfo) {
      setGroupedBreakdown(
        mapBreakdownToColorIcon(
          filterOtherProgramsBreakdown(breakdownData.enrolledProgramsInfo.otherPrograms)
        )
      );
    }
  }, [breakdownData, t]);

  return (
    <section className={cx(styles['breakdown-section'])}>
      <h2 className={cx(styles['breakdown-section__heading'])}>
        {t('other-programs.breakdown.heading')}
      </h2>
      <p className={cx(styles['breakdown-section__description'])}>
        <Trans
          i18nKey="other-programs.breakdown.description"
          components={[
            <Link
              href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL}
              className={cx(styles['contact-us__phone-number'])}
            />,
          ]}
        />
      </p>
      {loadingBreakdown ? (
        <div className={cx(styles['breakdown-section__spinner-wrapper'])}>
          <Spinner size={SpinnerSizes.LG} />
        </div>
      ) : (
        <div className={cx(styles['breakdown-section__content-wrapper'])}>
          <div className={cx(styles['breakdown-section__cards-container'])}>
            <h3 className={cx(styles['breakdown-section__overline'])}>
              {t('other-programs.breakdown.overline')}
            </h3>
            <div className={cx(styles['breakdown-section__cards-wrapper'])}>
              {groupedBreakdown.map((breakdownItem) => (
                <div
                  className={cx(styles['breakdown-section__card'])}
                  key={breakdownItem.program.id}
                >
                  <ProgramCard
                    description={
                      <Trans
                        i18nKey="program.earned"
                        values={{
                          value: breakdownItem.rewardAmountInDollars,
                        }}
                        components={[<strong />]}
                      />
                    }
                    program={breakdownItem}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={cx(styles['breakdown-section__chart-container'])}>
            <div className={cx(styles['breakdown-section__chart-wrapper'])}>
              <ProgramDoughnutChart
                data={mapProgramBreakdownToChartEntries(groupedBreakdown)}
                label={t('bayer-value.breakdown.rewards-earned')}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

BreakdownSection.displayName = 'BreakdownSection';

export default BreakdownSection;
