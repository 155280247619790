import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import LinkButton from '../../../../components/_shared/LinkButton';
import { LinkUrls } from '../../../../lib/constants/react-router';
import styles from './SummerSizzlerWidget.module.scss';
import summerSizzlerImage1 from '../../../../assets/images/banners/summer-sizzler-1.png';
import summerSizzlerImage2 from '../../../../assets/images/banners/summer-sizzler-2.png';
import { useTranslation } from 'react-i18next';

export interface Props {
  /* ...proptypes */
}

const SummerSizzlerWidget: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['summer-sizzler-widget'])}>
      <div className={cx(styles['summer-sizzler-widget__container'])}>
        <h1 className={cx(styles['summer-sizzler-widget__title'])}>
          {t('program-offer.offers.summer-sizzler.title')}
        </h1>
        <div className={cx(styles['summer-sizzler-widget__description-wrapper'])}>
          <p className={cx(styles['summer-sizzler-widget__description'])}>
            {t('program-offer.offers.summer-sizzler.description')}
          </p>
          <p className={cx(styles['summer-sizzler-widget__description'])}>
            {t('program-offer.offers.summer-sizzler.contact')}
          </p>
        </div>
      </div>

      <div className={cx(styles['summer-sizzler-widget__image-container'])}>
        <img
          className={cx(styles['summer-sizzler-widget__image'])}
          src={summerSizzlerImage1}
          alt=""
          aria-hidden
        />
        <img
          className={cx(styles['summer-sizzler-widget__image'])}
          src={summerSizzlerImage2}
          alt=""
          aria-hidden
        />
      </div>

      <LinkButton
        altText={t('program-offer.offers.summer-sizzler.button')}
        className={cx(styles['summer-sizzler-widget__button'])}
        theme={ButtonThemes.SECONDARY}
        href={LinkUrls.SUMMER_SIZZLER_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('program-offer.offers.summer-sizzler.button')}
      </LinkButton>
    </div>
  );
};

SummerSizzlerWidget.displayName = 'SummerSizzlerWidget';

export default SummerSizzlerWidget;
