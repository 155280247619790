import cx from 'classnames';
import { FC } from 'react';
import Snackbar from './Snackbar/Snackbar';
import { Snackbar as SnackbarType } from '../../lib/contexts/snackbar/SnackbarContext.types';
import styles from './SnackbarContainer.module.scss';
import useSnackbarContext from '../../lib/contexts/snackbar/useSnackbarContext';

export interface Props {
  className?: string;
}

const SnackbarContainer: FC<Props> = ({ className }) => {
  const [snackbar] = useSnackbarContext();

  return (
    <div className={cx(styles['snackbar-container'], className)}>
      {snackbar.map((item: SnackbarType) => {
        const { id, label, state } = item;

        return (
          <span key={id}>
            <Snackbar label={label} state={state} className={cx(styles['snack'])} id={id} />
          </span>
        );
      })}
    </div>
  );
};

SnackbarContainer.displayName = 'SnackbarContainer';

export default SnackbarContainer;
