import { ReactNode } from 'react';

export interface DashboardContextState {
  displayCalculator: boolean;
  displayDekalbBanner: boolean;
  displayEventPass: boolean;
  displayProgramPromotions: boolean;
  displayRetreat: boolean;
  displaySummerSizzlerBanner: boolean;
}

export interface DashboardContextProps {
  children: ReactNode;
}

export enum DashboardContextActionTypes {
  UpdateDisplayCalculator = 'UpdateDisplayCalculator',
  UpdateDisplayDelkabBanner = 'UpdateDisplayDelkabBanner',
  UpdateDisplayEventPass = 'UpdateDisplayEventPass',
  UpdateDisplayProgramPromotions = 'UpdateDisplayProgramPromotions',
  UpdateDisplayRetreat = 'UpdateDisplayRetreat',
  UpdateDisplaySummerSizzlerBanner = 'UpdateDisplaySummerSizzlerBanner',
}

export type DashboardContextAction =
  | {
      type: DashboardContextActionTypes.UpdateDisplayRetreat;
      payload: boolean;
    }
  | {
      type: DashboardContextActionTypes.UpdateDisplayProgramPromotions;
      payload: boolean;
    }
  | {
      type: DashboardContextActionTypes.UpdateDisplayEventPass;
      payload: boolean;
    }
  | {
      type: DashboardContextActionTypes.UpdateDisplaySummerSizzlerBanner;
      payload: boolean;
    }
  | {
      type: DashboardContextActionTypes.UpdateDisplayDelkabBanner;
      payload: boolean;
    }
  | {
      type: DashboardContextActionTypes.UpdateDisplayCalculator;
      payload: boolean;
    };

export type DashboardContextDispatch = (action: DashboardContextAction) => void;

export type DashboardContextType = [DashboardContextState, DashboardContextDispatch];
