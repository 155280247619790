import { useEffect, useState } from 'react';

/*
Function that loads the gigya web SDK scripts:
-gigya JS
-gigya OIDC
See 'set up & activate your proxy page' https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/4167d5cb70b21014bbc5a10ce4041860.html
*/
const useGigya = () => {
  const [jsLoaded, setJsLoaded] = useState<boolean>(false);
  const [oidcLoaded, setOidcLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!process.env.REACT_APP_USE_MOCKS) {
      const gigyaScript = document.createElement('script');
      const gigyaOidcScript = document.createElement('script');

      if (gigyaScript && gigyaOidcScript) {
        gigyaScript.onload = () => setJsLoaded(true);
        gigyaOidcScript.onload = () => setOidcLoaded(true);
        gigyaScript.src = `${process.env.REACT_APP_GIGYA_LOGIN_SCRIPT as string}?apikey=${process.env.REACT_APP_GIGYA_API_KEY as string
          }`;
        gigyaScript.async = true;

        gigyaOidcScript.src = `${process.env.REACT_APP_GIGYA_OIDC_SCRIPT as string}?apiKey=${process.env.REACT_APP_GIGYA_API_KEY as string
          }`;
        gigyaOidcScript.async = true;

        const urls = {
          loginURL: process.env.REACT_APP_GIGYA_LOGIN_URL,
          errorURL: process.env.REACT_APP_GIGYA_ERROR_URL,
        };

        gigyaOidcScript.text = JSON.stringify(urls);

        document.head.appendChild(gigyaScript);
        document.head.appendChild(gigyaOidcScript);
      }
    }
  }, []);

  return jsLoaded && oidcLoaded;
};

export default useGigya;
