import React, { forwardRef, ReactNode, useRef } from 'react';
import cx from 'classnames';
import styles from './Radio.module.scss';
import { v4 as uuid } from 'uuid';

export interface Props {
  disabled?: boolean;
  label: string;
  description?: string | ReactNode;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
  name: string;
  onChange(e?: any): void;
  id?: string;
  className?: string;
  checked?: React.InputHTMLAttributes<HTMLInputElement>['checked'];
}

const Radio = forwardRef<HTMLInputElement, Props>(
  ({ disabled, label, description, value, name, onChange, id, className, checked }, ref) => {
    const radioId = useRef<string>(id || uuid());

    const onChangeHandler = (event: any) => {
      onChange(event);
    };

    return (
      <label
        className={cx(
          styles['radio'],
          {
            [styles['radio--disabled']]: disabled,
          },
          className
        )}
        htmlFor={radioId.current}
      >
        <div className={cx(styles['radio__circle-wrapper'])}>
          <input
            className={cx(styles['radio__input'])}
            type="radio"
            onChange={onChangeHandler}
            disabled={disabled}
            value={value}
            name={name}
            ref={ref}
            id={radioId.current}
            aria-label={label}
            checked={checked}
          />
          <span
            className={cx(styles['radio__circle'], {
              [styles['radio__circle--disabled']]: disabled,
            })}
          ></span>
        </div>
        <div className={cx(styles['radio__content'])}>
          <span className={cx(styles['radio__label'])}>{label}</span>
          {description && <span className={cx(styles['radio__description'])}>{description}</span>}
        </div>
      </label>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
