import { CalculatorProduct } from '../../__generated__/graphql';
import { DropdownOption } from '../constants/react-select';

export enum ProductMainGroup {
  FUNGICIDES = 'Fungicides',
  HERBICIDES = 'Herbicides',
  SEED_TREATMENT = 'Seed Treatment',
  TRAIT = 'Trait',
}

export enum PreFillOption {
  YES = 'yes',
  NO = 'no',
}

export interface CalculatorPreferencesForm {
  preFillForm: PreFillOption;
  crops: string[];
}

export interface CalculatorProductOption {
  value: string;
  label: string;
}

export interface CalculatorCategory {
  key: ProductMainGroup;
  title: string;
  subtitle: string;
  products: CalculatorProductOption[];
}
export interface ProductSelected {
  product?: DropdownOption;
  acres: number;
}
export interface Product {
  label: string;
  amount: number;
}

export interface ProductBreakdown {
  product: CalculatorProduct | undefined;
  acres: number;
}
