import { CalculatorStep, type StepComponentProps } from '../../../../lib/constants/stepper';
import { FileDownloadIcon, PrintIcon } from '../../../../assets/icons';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import { MOCK_CHART_DATA } from '../../BayerRewardsCalculator.const';
import ProgramDoughnutChart from '../../../../components/_shared/ProgramDoughnutChart';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from './Results.module.scss';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useTranslation } from 'react-i18next';

const Results: FC<StepComponentProps> = ({ back, goToStep }: StepComponentProps) => {
  const { t } = useTranslation();
  const [{ selectedProducts }] = useCalculatorContext();
  console.log(selectedProducts);

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.results')}
      back={{ text: t('bayer-rewards-calculator.results.back-to-calculator'), onClick: back }}
      submit={{
        text: t('bayer-rewards-calculator.results.new-calculation'),
        onClick: () => goToStep?.(CalculatorStep.PREFERENCES),
      }}
      hideFooter
      actionsOnHeader
    >
      <div className={styles['results']}>
        <div className={cx(styles['results__chart'])}>
          <div className={cx(styles['results__chart-legend'])}>
            <div className={cx(styles['results__chart-title'])}>
              <span className={cx(styles['results__chart-title-text'])}>
                {t('bayer-rewards-calculator.results.chart.title')}
              </span>
              <span className={cx(styles['results__chart-title-amount'])}>
                {t('format.price', {
                  value: MOCK_CHART_DATA.map((item) => item.amount).reduce(
                    (acc, currentValue) => acc + currentValue
                  ),
                })}
              </span>
            </div>
            <span className={cx(styles['results__chart-legend-title'])}>
              {t('bayer-rewards-calculator.results.chart.legend-title')}
            </span>
            {MOCK_CHART_DATA.map((item) => (
              <div className={cx(styles['results__chart-legend-item'])}>
                <div
                  aria-hidden
                  className={cx(styles['results__chart-legend-indicator'])}
                  style={{ backgroundColor: item.color }}
                />
                <span className={cx(styles['results__chart-legend-text'])}>{item.programName}</span>
              </div>
            ))}
          </div>
          <div className={cx(styles['results__chart-container'])}>
            <div className={cx(styles['results__chart-wrapper'])}>
              <ProgramDoughnutChart
                data={MOCK_CHART_DATA}
                label={t('bayer-value.breakdown.rewards-earned')}
              />
            </div>
          </div>
        </div>
        <div className={styles['results__table-container']}>
          <div className={styles['results__table-title-wrapper']}>
            <span className={styles['results__table-title']}>
              {t('bayer-rewards-calculator.results.products-included')}
            </span>
            <div className={styles['results__table-actions']}>
              <Button theme={ButtonThemes.TEXT_LINK} icon={PrintIcon}>
                {t('bayer-rewards-calculator.results.print')}
              </Button>
              <Button theme={ButtonThemes.TEXT_LINK} icon={FileDownloadIcon}>
                {t('bayer-rewards-calculator.results.download')}
              </Button>
            </div>
          </div>
          <div className={styles['results__table-wrapper']}>
            <table className={styles['results__table']}>
              <tr className={styles['results__row']}>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.estimated-rewards')}
                </th>
              </tr>
              {selectedProducts.map((selectedProduct) => (
                <tr className={styles['results__row']}>
                  <td className={styles['results__cell']}>{selectedProduct.product?.name}</td>
                  <td className={styles['results__cell']}>{selectedProduct.product?.platform}</td>
                  <td className={styles['results__cell']}>{selectedProduct.product?.mainGroup}</td>
                  <td className={styles['results__cell']}>{selectedProduct.acres}</td>
                  <td className={styles['results__cell']}>
                    {t('format.price', {
                      value: (selectedProduct.product?.pricePerAcre ?? 0) * selectedProduct.acres,
                    })}
                  </td>
                </tr>
              ))}
              <tr className={cx(styles['results__row'], styles['results__row--total-rewards'])}>
                <td className={styles['results__cell']}>
                  {t('bayer-rewards-calculator.results.breakdown-table.total-rewards')}
                </td>
                <td className={styles['results__cell']} aria-hidden="true" />
                <td className={styles['results__cell']} aria-hidden="true" />
                <td className={styles['results__cell']} aria-hidden="true" />
                <td className={styles['results__cell']}>$1,000.00</td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles['results__table-container']}>
          <div className={styles['results__table-title-wrapper']}>
            <span className={styles['results__table-title']}>
              {t('bayer-rewards-calculator.results.products-not-included')}
            </span>
          </div>
          <div className={styles['results__table-wrapper']}>
            <table className={styles['results__table']}>
              <tr className={styles['results__row']}>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                </th>
                <th className={cx(styles['results__cell'], styles['results__cell--header'])}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
                </th>
              </tr>
              <tr className={styles['results__row']}>
                <td className={styles['results__cell']}>Roundup Ready® Corn 2</td>
                <td className={styles['results__cell']}>500</td>
                <td className={styles['results__cell']}>Yes</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </StepsWrapper>
  );
};

Results.displayName = 'Results';

export default Results;
