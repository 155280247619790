import { ReactNode } from 'react';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';

export interface Snackbar {
  id: string;
  state: SnackbarStates;
  label: string;
}

export interface SnackbarContextProps {
  children: ReactNode;
}

export type SnackbarContextState = Snackbar[];

export enum SnackbarContextActionTypes {
  AddToQueue = 'AddToQueue',
  RemoveFromQueue = 'RemoveFromQueue',
}

export type SnackbarContextAction =
  | { type: SnackbarContextActionTypes.AddToQueue; payload: Omit<Snackbar, 'id'> }
  | { type: SnackbarContextActionTypes.RemoveFromQueue; id: string };

export type SnackbarDispatch = (action: SnackbarContextAction) => void;

export type SnackbarContextType = [SnackbarContextState, SnackbarDispatch];
