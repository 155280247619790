import { ButtonThemes } from '../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import { GET_USER_INFO_NAV } from '../../lib/graphql/Navigation.gql';
import image from '../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../components/ImageWithContent';
import LinkButton from '../../components/_shared/LinkButton';
import { PageRoutes } from '../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import styles from './NotFound.module.scss';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
  usePageTitle('not-found');
  const { t } = useTranslation();
  const { data: userData } = useQuery(GET_USER_INFO_NAV);

  return (
    <div className={cx(styles['not-found'])}>
      <ImageWithContent imageSrc={image} imageAlt={t('images.farmers')}>
        <div className={cx(styles['not-found-container'])}>
          <div className={cx(styles['not-found-container__inner'])}>
            <div>
              <h1 className={cx(styles['not-found-container__header'])}>{t('not-found.title')}</h1>
              <p className={cx(styles['not-found-container__subtitle'])}>
                {t('not-found.description')}
              </p>
              <LinkButton
                className={cx(styles['not-found-container__item'])}
                theme={ButtonThemes.PRIMARY}
                as={RouterLink}
                to={userData ? '/' : `/${PageRoutes.LOGIN}`}
              >
                {userData ? t('not-found.cta-auth') : t('not-found.cta-non-auth')}
              </LinkButton>
            </div>
          </div>
        </div>
      </ImageWithContent>
    </div>
  );
};

NotFound.displayName = 'NotFound';

export default NotFound;
