import { AccessLevel } from '../../__generated__/graphql';
import { BannerTheme } from '../../components/BayerValue/ClimateFieldViewBanner/ClimateFieldViewBanner.types';
import BayerRewardsWidget from './components/BayerRewardsWidget';
import CalculatorWidget from './components/CalculatorWidget';
import ClimateFieldViewBanner from '../../components/BayerValue/ClimateFieldViewBanner/ClimateFieldViewBanner';
import cx from 'classnames';
import { DashboardProps } from './Dashboard.types';
import DelkabPromotionWidget from './promotions/DelkabPromotionWidget';
import EventPassWidget from './promotions/EventPassWidget';
import FarmMembersWidget from './components/FarmMembersWidget';
import { FC } from 'react';
import { Language } from '../../lib/constants/i18n';
import LocalTeamWidget from './components/LocalTeamWidget';
import ProgramPromotionSection from '../BayerRewardsLanding/components/ProgramPromotionSection';
import PurchasesWidget from './components/PurchasesWidget';
import styles from './Dashboard.module.scss';
import SummerSizzlerWidget from './promotions/SummerSizzlerWidget';
import TakeActionWidget from './components/TakeActionWidget';
import useDashboardContext from '../../lib/contexts/dashboard/useDashboardContext';
import { useTranslation } from 'react-i18next';
import WelcomeMessage from './components/WelcomeMessage';

const DashboardDesktop: FC<DashboardProps> = ({ hasAccess }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [
    {
      displayCalculator,
      displayDekalbBanner,
      displayEventPass,
      displayProgramPromotions,
      displayRetreat,
      displaySummerSizzlerBanner,
    },
  ] = useDashboardContext();

  return (
    <div className={cx(styles['dd'])}>
      <div className={cx(styles['dd__container'])}>
        <div className={cx(styles['dd__sidebar'])}>
          <div className={cx(styles['dd__welcome-wrapper'])}>
            <WelcomeMessage />
          </div>
          {hasAccess === AccessLevel.FullAccess && (
            <div className={cx(styles['dd__take-action-wrapper'])}>
              <TakeActionWidget />
            </div>
          )}
          <div className={cx(styles['dd__farm-members-wrapper'])}>
            <FarmMembersWidget />
          </div>
          <LocalTeamWidget />
        </div>
        <div className={cx(styles['dd__main'])}>
          <div className={cx(styles['dd__grid'])}>
            <div
              className={cx(styles['dd__rewards-wrapper'], {
                [styles['dd__rewards-wrapper--trip']]: displayRetreat,
              })}
            >
              <BayerRewardsWidget />
            </div>
            <div
              className={cx(styles['dd__purchases-wrapper'], {
                [styles['dd__purchases-wrapper--trip']]: displayRetreat && !displayCalculator,
              })}
            >
              <PurchasesWidget />
            </div>
            {displayRetreat && displayCalculator && (
              <div
                className={cx(styles['dd__calculator-wrapper--trip'], {
                  [styles['dd__calculator-wrapper--no-access']]: hasAccess === AccessLevel.NoAccess,
                })}
              >
                <CalculatorWidget />
              </div>
            )}
          </div>
          <div className={cx(styles['ds__banner-wrapper'])}>
            <div
              className={cx(styles['ds__banner'], {
                [styles['ds__banner--hidden']]: !displayEventPass,
              })}
            >
              <EventPassWidget />
            </div>
            <div
              className={cx(styles['ds__banner'], {
                [styles['ds__banner--hidden']]:
                  hasAccess === AccessLevel.NoAccess ||
                  !displayProgramPromotions ||
                  language === Language.Fr,
              })}
            >
              <ProgramPromotionSection />
            </div>
            {displayDekalbBanner && (
              <div className={cx(styles['ds__banner'])}>
                <DelkabPromotionWidget />
              </div>
            )}
            {displaySummerSizzlerBanner && (
              <div className={cx(styles['ds__banner'])}>
                <SummerSizzlerWidget />
              </div>
            )}
            {!displayRetreat && displayCalculator && (
              <div className={cx(styles['ds__banner'])}>
                <CalculatorWidget />
              </div>
            )}
            <div className={cx(styles['ds__banner'])}>
              <ClimateFieldViewBanner bannerTheme={BannerTheme.DASHBOARD} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardDesktop.displayName = 'DashboardDesktop';

export default DashboardDesktop;
