import cx from 'classnames';
import { FC } from 'react';
import { getArcPercentage } from './ProgramDoughnutChart.utils';
import styles from './ProgramDoughnutChart.module.scss';
import { TooltipModel } from 'chart.js';
import { useTranslation } from 'react-i18next';

interface Props {
  data: TooltipModel<'doughnut'> | null;
  position: { top: number; left: number } | null;
  visibility: boolean;
  total: number;
}

const ChartTooltip: FC<Props> = ({ data, position, visibility, total }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dataPoint = data?.dataPoints?.[0];

  return (
    <div
      className={cx(styles['chart-tooltip'], {
        [styles['chart-tooltip--hidden']]: !visibility || !dataPoint,
      })}
      style={{
        top: position?.top,
        left: position?.left,
      }}
    >
      <p className={cx(styles['chart-tooltip__text'])}>
        {dataPoint &&
          t('chart.tooltip', {
            programName: dataPoint.label,
            amount: dataPoint.raw,
            percentage: getArcPercentage(
              typeof dataPoint.raw === 'number' ? dataPoint.raw : 0,
              total,
              language
            ),
          })}
      </p>
    </div>
  );
};

export default ChartTooltip;
