import {
  BayerValueProgramInfo,
  OrderDirection,
  PurchasePreOrderSortField,
  PurchaseProduct,
} from '../../../__generated__/graphql';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  GET_BAYER_VALUE_PROGRAM_BY_ID,
  GET_TOTAL_REWARDS_EARNED_QUERY,
} from '../../../lib/graphql/ProgramRewards.gql';
import { getDynamicProgramColorMap, getMarketingUrl } from '../../../lib/utils/programs';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';

import { BAYER_VALUE_PROGRAM_ID } from '../../../lib/constants/programs';
import { buildUrlParams } from '../../../lib/utils/api';
import ContactUs from '../../../components/BayerValue/ContactUs';
import cx from 'classnames';
import { GET_PURCHASES_TABLE } from '../../../lib/graphql/Purchases.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../lib/graphql/UserInformation.gql';
import { getLastThreeRewardYears } from '../../../lib/utils/dates';
import { hasRewardsAccess } from '../../../lib/utils/user';
import Link from '../../../components/_shared/Link';
import { PageRoutes } from '../../../lib/constants/react-router';
import ProgramHero from '../../../components/BayerValue/ProgramHero';
import RecentPurchases from '../../../components/BayerValue/RecentPurchases';
import SegmentsSection from './components/SegmentsSection';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './BayerValueProgram.module.scss';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';

const BayerValueProgram: FC = () => {
  usePageTitle('bayer-value-program');
  const { t } = useTranslation();
  const { program } = useParams();
  const navigate = useNavigate();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [rewardYear, setRewardYear] = useState<number>(getLastThreeRewardYears()[0]);
  const [purchases, setPurchases] = useState<PurchaseProduct[]>([]);
  const [programData, setProgramData] = useState<BayerValueProgramInfo>();
  const [purchaseOrderDirection, setPurchaseOrderDirection] = useState<OrderDirection>(
    OrderDirection.Desc
  );
  const [purchaseOrderBy, setPurchaseOrderBy] = useState<PurchasePreOrderSortField>(
    PurchasePreOrderSortField.Date
  );

  const { data: userData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const [fetchProgram, { loading: loadingProgramData }] = useLazyQuery(
    GET_BAYER_VALUE_PROGRAM_BY_ID
  );
  const [fetchPurchases, { loading: loadingPurchases }] = useLazyQuery(GET_PURCHASES_TABLE);
  const {
    data: totalRewardsEarnedData,
    loading: loadingTotalRewardsEarned,
    error: totalRewardsEarnedError,
  } = useQuery(GET_TOTAL_REWARDS_EARNED_QUERY, {
    variables: { input: { year: rewardYear } },
    skip: !programData,
  });

  const displayErrorSnack = useCallback(() => {
    dispatchSnackbar({
      type: SnackbarContextActionTypes.AddToQueue,
      payload: {
        label: t('errors.generic'),
        state: SnackbarStates.WARNING,
      },
    });
  }, [dispatchSnackbar, t]);

  /**
    Fetches BayerValue program information only if the program ID is passed in the URL.
  */
  useEffect(() => {
    if (program) {
      fetchProgram({
        variables: {
          input: {
            program,
          },
        },
      })
        .then(({ data }) => {
          if (data?.bayerValueProgramInfo) {
            setProgramData(data?.bayerValueProgramInfo as BayerValueProgramInfo);
            setRewardYear(data?.bayerValueProgramInfo.program.year);
          } else {
            navigate(`/${PageRoutes.REWARDS}`);
          }
        })
        .catch(() => {
          navigate(`/${PageRoutes.REWARDS}`);
        });
    } else {
      navigate(`/${PageRoutes.REWARDS}`);
    }
  }, [displayErrorSnack, fetchProgram, navigate, program]);

  /**
   * Fetches the purchases only if the program information was fetched successfully
   */
  useEffect(() => {
    if (programData) {
      fetchPurchases({
        variables: {
          orderBy: {
            orderDirection: purchaseOrderDirection,
            orderField: purchaseOrderBy,
          },
          limit: 5,
          filterBy: {
            programIds: [programData.program.id],
            dateRange: {
              startDate: programData.program.effectiveFrom,
              endDate: programData.program.effectiveTo,
            },
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            setPurchases((data.purchaseProducts.purchaseProducts as PurchaseProduct[]) || []);
          }
        })
        .catch(() => {
          displayErrorSnack();
        });
    }
  }, [purchaseOrderDirection, purchaseOrderBy, fetchPurchases, displayErrorSnack, programData]);

  useEffect(() => {
    if (totalRewardsEarnedError) {
      displayErrorSnack();
    }
  }, [totalRewardsEarnedError, displayErrorSnack]);

  const getViewAllPurchasesLink = () =>
    `/${PageRoutes.ORDER_PURCHASE_HISTORY}${buildUrlParams(
      {
        programId: program,
      },
      false
    )}`;

  return (
    <div className={cx(styles['bayer-value'])}>
      <ProgramHero
        programBadgeProps={getDynamicProgramColorMap({ id: BAYER_VALUE_PROGRAM_ID })}
        totalRewardsEarned={
          totalRewardsEarnedData?.enrolledProgramsInfo?.totalRewardAmountInDollars || 0
        }
        programRewardsEarned={programData?.rewardAmountInDollars || 0}
        programYear={rewardYear}
        programName={
          programData?.program.name || (
            <Trans i18nKey="bayer-value.programs.bayer-value" components={[<sup />]} />
          )
        }
        isLoading={loadingProgramData || loadingTotalRewardsEarned}
        linkText={t('bayer-value.programs.learn-more-link')}
        linkTo={getMarketingUrl(programData?.program.name)}
        programPeriod={{
          start: programData?.program.effectiveFrom,
          end: programData?.program.effectiveTo,
        }}
      />

      <SegmentsSection
        loading={loadingProgramData}
        programRewardMetrics={programData?.programRewardMetrics}
      />

      <hr className={cx(styles['bayer-value__divider'])} />

      {hasRewardsAccess(userData) && (
        <RecentPurchases
          description={
            <Trans
              i18nKey="bayer-value.recent-purchases.description"
              components={[
                <Link
                  as={RouterLink}
                  to={`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_PREFERRED_RETAILER} `}
                />,
              ]}
            />
          }
          heading={t('bayer-value.recent-purchases.heading')}
          isLoading={loadingPurchases}
          recentPurchases={purchases}
          viewAllUrl={getViewAllPurchasesLink()}
          purchaseOrderField={purchaseOrderBy}
          setPurchaseOrderField={setPurchaseOrderBy}
          setPurchaseOrderDirection={setPurchaseOrderDirection}
          purchaseOrderDirection={purchaseOrderDirection}
          year={rewardYear}
        />
      )}
      <ContactUs />
    </div>
  );
};

BayerValueProgram.displayName = 'BayerValueProgram';

export default BayerValueProgram;
