import { FC, useEffect, useState } from 'react';
import AddPreferredRetailerModal from '../../../../components/PreferredRetailer/AddPreferredRetailerModal';
import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { GET_PREFERRED_RETAILERS } from '../../../../lib/graphql/PreferredRetailers.gql';
import PreferredRetailerWrapper from '../../../../components/PreferredRetailer/PreferredRetailerWrapper/PreferredRetailerWrapper';
import { RetailerProvider } from '../../../../lib/contexts/retailer/RetailerContext';
import SettingsWrapper from '../../components/SettingsWrapper';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './MyPreferredRetailer.module.scss';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const MyPreferredRetailer: FC = () => {
  const { data, loading, error } = useQuery(GET_PREFERRED_RETAILERS);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, error, t]);

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  return (
    <SettingsWrapper
      title={t('account-settings.nav.my-preferred-retailer')}
      buttonType="button"
      isLoadingData={loading}
      hideSaveButton
    >
      <div className={cx(styles['my-preferred-retailer'])}>
        <div className={cx(styles['top-section'])}>
          <div className={cx(styles['top-section__text-wrapper'])}>
            <h3 className={cx(styles['top-section__heading'])}>
              {t('account-settings.my-preferred-retailer.heading')}
            </h3>
            <p className={cx(styles['top-section__description'])}>
              {t('account-settings.my-preferred-retailer.subtitle')}
            </p>
          </div>
          <div
            className={cx(styles['top-section__button-wrapper'], {
              [styles['top-section__button-wrapper--no-results']]:
                data?.user?.retailers?.length === 0,
            })}
          >
            <Button onClick={openAddModal} type="button" theme={ButtonThemes.TEXT_LINK}>
              {t('account-settings.my-preferred-retailer.add-retailer')}
            </Button>
          </div>
        </div>
        <div>
          {data?.user?.retailers?.length === 0 ? (
            <div className={cx(styles['no-results'])}>
              <h4 className={cx(styles['no-results__heading'])}>
                {t('account-settings.my-preferred-retailer.no-results.heading')}
              </h4>
              <p className={cx(styles['no-results__description'])}>
                {t('account-settings.my-preferred-retailer.no-results.subtitle')}
              </p>
              <Button
                className={cx(styles['no-results__button'])}
                onClick={openAddModal}
                type="button"
                theme={ButtonThemes.PRIMARY}
              >
                {t('account-settings.my-preferred-retailer.no-results.add-retailer')}
              </Button>
            </div>
          ) : (
            <div className={cx(styles['results'])}>
              {(data?.user?.retailers || []).map((retailer) => (
                <div key={retailer.id} className={cx(styles['results__retailer'])}>
                  <PreferredRetailerWrapper retailer={retailer} />
                </div>
              ))}
            </div>
          )}
        </div>
        <RetailerProvider>
          <AddPreferredRetailerModal hide={closeAddModal} open={addModalOpen} />
        </RetailerProvider>
      </div>
    </SettingsWrapper>
  );
};

MyPreferredRetailer.displayName = 'MyPreferredRetailer';

export default MyPreferredRetailer;
