import { FC, useRef } from 'react';
import Pill, { PillColors } from '../Pill';

import cx from 'classnames';
import { getLastThreeRewardYears } from '../../../lib/utils/dates';
import { Props } from './RewardsYearPicker.types';
import styles from './RewardsYearPicker.module.scss';

const RewardsYearPicker: FC<Props> = ({ selectedYear, onSelect }) => {
  const yearOptions = useRef<number[]>(getLastThreeRewardYears());

  return (
    <ul className={cx(styles['rewards-year-picker'])}>
      {yearOptions.current.map((year) => (
        <li key={year} className={cx(styles['rewards-year-picker__item'])}>
          <Pill
            key={year}
            as="button"
            type="button"
            text={year.toString()}
            color={year === selectedYear ? PillColors.BLUE : PillColors.WHITE}
            onClick={() => {
              onSelect(year);
            }}
          />
        </li>
      ))}
    </ul>
  );
};

RewardsYearPicker.displayName = 'RewardsYearPicker';

export default RewardsYearPicker;
