import { Product, ShoppingCart } from '../../__generated__/graphql';
import { DropdownOption } from '../constants/react-select';
import { ProductCategory } from '../models/Product.model';
import { ProductSize } from '../constants/pre-orders';
import { t } from 'i18next';

/**
 * Gets the dropdown options for a product's sizes
 * @param product
 * @returns
 */
export const getProductSizeOptions = (
  products: Product[],
  seedTreatment: string
): DropdownOption[] =>
  products
    .filter((product) => product.seedTreatment?.id === seedTreatment && product.productSize)
    .map((product) => ({
      value: product.sku,
      label:
        product.productSize === ProductSize.BAG
          ? t('product.sizes.bag')
          : t('product.sizes.mini-bulk'),
    }));

/**
 * Gets the dropdown options for a product's seed treatments
 * @param products
 * @returns
 */
export const getSeedTreatmentOptions = (products: Product[]): DropdownOption[] => {
  const seedTreatmentOptions: DropdownOption[] = [];

  products.forEach((product) => {
    if (product.seedTreatment?.id && product.seedTreatment.name) {
      seedTreatmentOptions.push({
        value: product.seedTreatment.id,
        label: product.seedTreatment.name,
      });
    }
  });

  return seedTreatmentOptions.filter(
    (product, index, self) => index === self.findIndex((p) => p.label === product.label)
  );
};

/**
 * Get's the copy for the quantity label based on the crop type
 * @param category
 * @returns
 */
export const getQuantityCopy = (category: string | undefined) =>
  category === ProductCategory.SOYBEAN
    ? t('form.labels.quantity-soybean')
    : t('form.labels.quantity');

/**
 * Get's a shopping cart based on a CartProduct id
 * @param carts
 * @param cartProductId
 * @returns
 */
export const findCartByCartProductId = (
  carts: ShoppingCart[],
  cartProductId: string
): ShoppingCart =>
  carts.find((c) => c.cartProducts.find((item) => item.id === cartProductId)) as ShoppingCart;

/**
 * Creates a shopping cart with a single product based on the `cartProductId`
 * @param carts
 * @param cartProductId
 * @returns
 */
export const createCartWithSingleProduct = (
  carts: ShoppingCart[],
  cartProductId: string
): ShoppingCart | null => {
  const cart: ShoppingCart = findCartByCartProductId(carts, cartProductId);

  if (cart) {
    const cartWithSingleProduct: ShoppingCart = {
      ...cart,
      cartProducts: cart.cartProducts.filter((item) => item.id === cartProductId),
    };

    return cartWithSingleProduct;
  }

  return null;
};
