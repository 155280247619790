import {
  abbrevMonthOrdinalDayYearEn,
  abbrevMonthOrdinalDayYearFr,
  slashSeparatedDate,
} from '../../../../../../lib/constants/date-formats';
import {
  BAYER_VALUE_PROGRAM_ID,
  OTHER_PROGRAM_ID,
  ProgramBadgeSize,
  ProgramColorMap,
  ProgramType,
  ProgramWithIcon,
} from '../../../../../../lib/constants/programs';
import {
  EMPTY_STATE_COLSPAN_DETAILS,
  REBATE_STATEMENTS_PER_PAGE,
} from '../../../../../../lib/constants/cheques';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { InitialsTheme, SpinnerSizes } from '../../../../../../lib/constants/components';
import { Trans, useTranslation } from 'react-i18next';

import Avatar from '../../../../../../components/_shared/Avatar';
import cx from 'classnames';
import dayjs from 'dayjs';
import { GET_DETAILS } from '../../../../../../lib/graphql/Rebates.gql';
import { Language } from '../../../../../../lib/constants/i18n';
import Pagination from '../../../../../../components/_shared/Pagination';
import { Program } from '../../../../../../__generated__/graphql';
import ProgramBadge from '../../../../../../components/_shared/ProgramBadge';
import PurchaseTableLegend from '../../../../../../components/_shared/PurchaseTableLegend/PurchaseTableLegend';
import { ReceiptIcon } from '../../../../../../assets/icons';
import { SnackbarContextActionTypes } from '../../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../../../components/_shared/Spinner';
import styles from '../../ChequesSection.module.scss';
import TableAccordion from '../../../../../../components/_shared/TableAccordion';
import TableEmptyState from '../../../../../../components/TableEmptyState';
import Tooltip from '../../../../../../components/_shared/Tooltip';
import { TooltipPosition } from '../../../../../../components/_shared/Tooltip/Tooltip.types';
import { useBreakpoint } from '../../../../../../lib/hooks';
import usePaginationContext from '../../../../../../lib/contexts/pagination/usePaginationContext';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../../../lib/contexts/snackbar/useSnackbarContext';

interface Props {
  year: number;
}

const RebateDetails: FC<Props> = ({ year }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isXs } = useBreakpoint();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [pageNumber, dispatchPageNumber] = usePaginationContext();

  const {
    data: rebateDetailsData,
    loading: rebateDetailsLoading,
    error: rebateDetailsError,
  } = useQuery(GET_DETAILS, {
    variables: {
      input: {
        year,
        offset: REBATE_STATEMENTS_PER_PAGE * pageNumber,
        limit: REBATE_STATEMENTS_PER_PAGE,
      },
    },
  });

  const totalItems = useMemo(
    () => rebateDetailsData?.rebateDetails?.rebatePurchases?.totalCount || 0,
    [rebateDetailsData]
  );

  useEffect(() => {
    if (rebateDetailsError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [rebateDetailsError, dispatchSnackbar, t]);

  useEffect(() => {
    dispatchPageNumber(0);
  }, [year]);

  const renderProgramRewardIcon = (programs: Program[]): ReactNode => (
    <div className={cx(styles['table__icon-wrapper'])}>
      {programs.map((program) => {
        if (isXs) {
          return (
            <ProgramBadge
              key={program.id}
              program={
                program.type === ProgramType.BAYER_VALUE
                  ? ProgramColorMap[BAYER_VALUE_PROGRAM_ID]
                  : ProgramColorMap[OTHER_PROGRAM_ID]
              }
              size={ProgramBadgeSize.SM}
            />
          );
        }

        return (
          <Tooltip text={program.name} position={TooltipPosition.TOP} key={program.id}>
            <ProgramBadge
              program={
                program.type === ProgramType.BAYER_VALUE
                  ? ProgramColorMap[BAYER_VALUE_PROGRAM_ID]
                  : ProgramColorMap[OTHER_PROGRAM_ID]
              }
              size={ProgramBadgeSize.SM}
            />
          </Tooltip>
        );
      })}
    </div>
  );

  const renderProgramRewardBreakdown = (programs: Program[]): ReactNode => {
    const bayerValuePrograms: ProgramWithIcon[] = [];
    const otherPograms: ProgramWithIcon[] = [];

    programs.forEach((program) => {
      const formattedProgram = {
        icon:
          program.type === ProgramType.BAYER_VALUE
            ? ProgramColorMap[BAYER_VALUE_PROGRAM_ID]
            : ProgramColorMap[OTHER_PROGRAM_ID],
        program,
      };

      if (program.type === ProgramType.BAYER_VALUE) {
        bayerValuePrograms.push(formattedProgram);
      } else {
        otherPograms.push(formattedProgram);
      }
    });

    return (
      <div className={cx(styles['breakdown-section'])}>
        {!!bayerValuePrograms.length && (
          <div>
            <p className={cx(styles['breakdown-section__header'])}>
              <Trans
                i18nKey="bayer-value.rebate-statements.breakdown.bayer-value"
                components={[<sup />]}
              />
            </p>
            <ul className={cx(styles['breakdown-section__program-list'])}>
              {bayerValuePrograms.map((rewardProgram) => (
                <li
                  key={rewardProgram.program.id}
                  className={cx(styles['breakdown-section__program-wrapper'])}
                >
                  <ProgramBadge program={rewardProgram.icon} size={ProgramBadgeSize.SM} />
                  <p className={cx(styles['mobile-table__value'])}>{rewardProgram.program.name}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!!otherPograms.length && (
          <div>
            <p className={cx(styles['breakdown-section__header'])}>
              <Trans
                i18nKey="bayer-value.rebate-statements.breakdown.other"
                components={[<sup />]}
              />
            </p>
            <ul className={cx(styles['breakdown-section__program-list'])}>
              {otherPograms.map((rewardProgram) => (
                <li
                  key={rewardProgram.program.id}
                  className={cx(styles['breakdown-section__program-wrapper'])}
                >
                  <ProgramBadge program={rewardProgram.icon} size={ProgramBadgeSize.SM} />
                  <p className={cx(styles['mobile-table__value'])}>{rewardProgram.program.name}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const renderPurchaserAvatar = (purchaser: string): ReactNode => {
    const purchaserName = purchaser.split(' ');

    return (
      <Tooltip text={purchaser} position={TooltipPosition.TOP}>
        <Avatar
          firstName={purchaserName[0]}
          lastName={purchaserName.length > 1 ? purchaserName[1] : ''}
          initialsTheme={InitialsTheme.PRIMARY}
        />
      </Tooltip>
    );
  };

  const renderLoadingState = (): ReactNode => (
    <div className={cx(styles['spinner-wrapper'])}>
      <Spinner size={SpinnerSizes.LG} />
    </div>
  );

  const renderEmptyState = (): ReactNode => (
    <TableEmptyState
      Icon={ReceiptIcon}
      title={t('bayer-value.rebates.empty-state.details.heading')}
      description={t('bayer-value.rebates.empty-state.details.description')}
    />
  );

  const renderDesktopTable = (): ReactNode => (
    <table className={cx(styles['table'])}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.product')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.acres')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.date')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.invoice')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.retailer')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.program')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.purchaser')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.rebate-statements.table.cheque-number')}
          </th>
        </tr>
      </thead>
      {!rebateDetailsData?.rebateDetails?.rebatePurchases?.purchases.length ? (
        <tbody>
          <tr className={cx(styles['table__empty-state'])}>
            <td
              className={cx(styles['table__empty-state-td'])}
              colSpan={EMPTY_STATE_COLSPAN_DETAILS}
              aria-label={t('bayer-value.rebate-statements.table.product')}
            >
              {renderEmptyState()}
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody className={cx(styles['table__tbody'])}>
          {rebateDetailsData?.rebateDetails?.rebatePurchases?.purchases.map((rebate) => (
            <tr
              key={`${rebate.purchase.id}-${rebate.product.sku}`}
              className={cx(styles['table__data-row'])}
            >
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.rebate-statements.table.product')}
              >
                {rebate.product.name}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.rebate-statements.table.acres')}
              >
                {rebate.acres}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.rebate-statements.table.date')}
              >
                {dayjs(rebate.purchase.invoiceDate).format(slashSeparatedDate)}
              </td>
              <td
                className={cx(styles['table__data-cell'], styles['table__details-row'])}
                data-label={t('bayer-value.rebate-statements.table.invoice')}
              >
                {rebate.purchase.invoiceId}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.rebate-statements.table.retailer')}
              >
                {t(
                  rebate.purchase.retailer.address
                    ? t('bayer-value.rebate-statements.table.retailer-format', {
                        name: rebate.purchase.retailer.name,
                        city: rebate.purchase.retailer.address.city,
                        province: rebate.purchase.retailer.address.province,
                      })
                    : rebate.purchase.retailer.name
                )}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.rebate-statements.table.program')}
              >
                {renderProgramRewardIcon(rebate.programs as Program[])}
              </td>
              <td
                className={cx(
                  styles['table__data-cell'],
                  styles['rebate-statement-row--purchaser']
                )}
                data-label={t('bayer-value.rebate-statements.table.purchaser')}
                aria-label={rebate.purchase.purchaser || ''}
              >
                {rebate.purchase.purchaser && renderPurchaserAvatar(rebate.purchase.purchaser)}
              </td>
              <td
                className={cx(
                  styles['table__data-cell'],
                  styles['rebate-section__cheques-wrapper']
                )}
                data-label={t('bayer-value.rebate-statements.table.cheque-number')}
              >
                <ul>
                  {rebate.cheques.map((cheque) => (
                    <li key={cheque.chequeNumber}>{cheque.chequeNumber}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );

  const renderMobileTable = (): ReactNode => {
    if (!rebateDetailsData?.rebateDetails?.rebatePurchases?.purchases.length) {
      return renderEmptyState();
    }

    return (
      <table className={cx(styles['mobile-table--accordion'])}>
        <tbody className={cx(styles['mobile-table__row--accordion'])}>
          {rebateDetailsData?.rebateDetails?.rebatePurchases?.purchases.map((rebate) => (
            <TableAccordion
              key={`${rebate.purchase.id}_${rebate.product.sku}`}
              cells={[
                {
                  content: rebate.product.name,
                  align: 'left',
                  key: 'name',
                },
              ]}
              mobileCells={[
                {
                  key: `${rebate.purchase.id}_${rebate.product.sku}`,
                  content: (
                    <div className={cx(styles['mobile-table__accordion-header'])}>
                      <p className={cx(styles['mobile-table__header'])}>{rebate.product.name}</p>
                      <p>
                        {t('bayer-value.rebate-statements.table.acreage', {
                          acres: rebate.acres,
                        })}
                        <span className={cx(styles['mobile-table__middle-dot'])}>&#8226;</span>
                        {t('bayer-value.rebate-statements.table.invoice-data', {
                          invoiceId: rebate.purchase.invoiceId,
                        })}
                      </p>
                      {renderProgramRewardIcon(rebate.programs as Program[])}
                    </div>
                  ),
                },
              ]}
            >
              <dl className={cx(styles['mobile-table__row'])}>
                <div className={cx(styles['mobile-table__column'])}>
                  <dt className={cx(styles['mobile-table__label'])}>
                    {t('punctuation.colon', {
                      value: t('bayer-value.rebate-statements.table.date'),
                    })}
                  </dt>
                  <dd className={cx(styles['mobile-table__value'])}>
                    {dayjs(rebate.purchase.invoiceDate).format(
                      language === Language.En
                        ? abbrevMonthOrdinalDayYearEn
                        : abbrevMonthOrdinalDayYearFr
                    )}
                  </dd>
                </div>
                <div className={cx(styles['mobile-table__column'])}>
                  <dt className={cx(styles['mobile-table__label'])}>
                    {t('punctuation.colon', {
                      value: t('bayer-value.rebate-statements.table.retailer'),
                    })}
                  </dt>
                  <dd className={cx(styles['mobile-table__value'])}>
                    {t(
                      rebate.purchase.retailer.address
                        ? t('bayer-value.rebate-statements.table.retailer-format', {
                            name: rebate.purchase.retailer.name,
                            city: rebate.purchase.retailer.address.city || '',
                            province: rebate.purchase.retailer.address.province || '',
                          })
                        : rebate.purchase.retailer.name
                    )}
                  </dd>
                </div>
                <div className={cx(styles['mobile-table__column'])}>
                  <dt className={cx(styles['mobile-table__label'])}>
                    {t('punctuation.colon', {
                      value: t('bayer-value.rebate-statements.table.purchaser'),
                    })}
                  </dt>
                  <dd className={cx(styles['mobile-table__value'])}>{rebate.purchase.purchaser}</dd>
                </div>
                <div className={cx(styles['mobile-table__column'])}>
                  <dt className={cx(styles['mobile-table__label'])}>
                    {t('punctuation.colon', {
                      value: t('bayer-value.rebate-statements.table.cheque-number'),
                    })}
                  </dt>
                  <dd className={cx(styles['mobile-table__value'])}>
                    {rebate.cheques.map((cheque) => cheque.chequeNumber).join(', ')}
                  </dd>
                </div>
                {rebate.programs.length > 0 &&
                  renderProgramRewardBreakdown(rebate.programs as Program[])}
              </dl>
            </TableAccordion>
          ))}
        </tbody>
      </table>
    );
  };

  const renderTable = (): ReactNode => {
    if (isXs) {
      return renderMobileTable();
    }
    return renderDesktopTable();
  };

  return (
    <div className={cx(styles['rebate-section'])}>
      <PurchaseTableLegend displayPurchaserIcon={!isXs} />
      {rebateDetailsLoading ? renderLoadingState() : renderTable()}
      {totalItems > 0 && (
        <div className={cx(styles['rebate-section__pagination-wrapper'])}>
          <Pagination
            itemsPerPage={REBATE_STATEMENTS_PER_PAGE}
            onPageChange={(pageNum) => {
              dispatchPageNumber(pageNum);
            }}
            totalNumberItems={totalItems}
          />
        </div>
      )}
    </div>
  );
};

RebateDetails.displayName = 'RebateDetails';

export default RebateDetails;
