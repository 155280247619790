import { Language, LANGUAGE_KEY } from '../constants/i18n';
import { checkUserIsInQc } from '../utils/google-maps';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useUrlLanguage = () => {
  const { i18n } = useTranslation();
  const { language } = useParams(); // Sign-up and login routes
  const preferredLanguage = localStorage.getItem(LANGUAGE_KEY) as string;
  const paramLanguage = new URLSearchParams(location.search).get('lang') as string;

  const assignLanguage = (languageKey: string) => {
    i18n.changeLanguage(languageKey);
    localStorage.setItem(LANGUAGE_KEY, languageKey);
  };

  const isValidLanguageKey = (languageKey?: string) =>
    Object.values(Language).includes(languageKey as Language);

  const setNewUserLanguage = () => {
    checkUserIsInQc().then((isInQc) => {
      if (isInQc) {
        // Set French for QC users
        assignLanguage(Language.Fr);
      } else {
        // Default language is English outside QC
        assignLanguage(Language.En);
      }
    });
  };

  useEffect(() => {
    if (language && isValidLanguageKey(language)) {
      // Prioritize URL
      assignLanguage(language);
    } else if (isValidLanguageKey(paramLanguage)) {
      // Use params
      assignLanguage(paramLanguage);
    } else if (isValidLanguageKey(preferredLanguage)) {
      // Local storage language
      assignLanguage(preferredLanguage);
    } else {
      // Default state
      setNewUserLanguage();
    }
  }, [i18n, preferredLanguage, paramLanguage, language]);
};
