import OrderHistoryContext from './OrderHistoryContext';
import { OrderHistoryContextType } from './OrderHistoryContext.types';
import { useContext } from 'react';

const useOrderHistoryContext = (): OrderHistoryContextType => {
  const context = useContext(OrderHistoryContext);

  if (context === undefined) {
    throw new Error('useOrderHistoryContext must be used within a OrderHistoryProvider');
  }

  return context;
};

export default useOrderHistoryContext;
