import { CalculatorStep, Step } from './stepper';
import Preferences from '../../pages/BayerRewardsCalculator/steps/Preferences';
import Results from '../../pages/BayerRewardsCalculator/steps/Results';
import SelectProducts from '../../pages/BayerRewardsCalculator/steps/SelectProducts';

export const mockTotalRewardsEarned = 'XXXX.XX';
export const mockCashbackPercentage = 'XX';

export type RewardDataExists = {
  breakdownData: boolean;
  purchasesData: boolean;
};

export const CALCULATOR_STEPS: Step[] = [
  {
    id: CalculatorStep.PREFERENCES,
    labelKey: 'bayer-rewards-calculator.steps.preferences',
    index: 1,
    Component: Preferences,
  },
  {
    id: CalculatorStep.SELECT_PRODUCTS,
    labelKey: 'bayer-rewards-calculator.steps.select-products',
    index: 2,
    Component: SelectProducts,
  },
  {
    id: CalculatorStep.RESULTS,
    labelKey: 'bayer-rewards-calculator.steps.results',
    index: 3,
    Component: Results,
  },
];
