import { ButtonThemes, IconPosition } from '../../../../../lib/constants/components';
import {
  DELETE_FROM_CART_MUTATION,
  GET_SHOPPING_CARTS_QUERY,
} from '../../../../../lib/graphql/ShoppingCart.gql';
import { EditIcon, SpinnerIcon, TrashCanIcon } from '../../../../../assets/icons';
import { FC, useMemo } from 'react';

import Button from '../../../../../components/_shared/Button';
import { CartProduct } from '../../../../../__generated__/graphql';
import cx from 'classnames';
import { OrderProductTheme } from '../../../../../lib/constants/pre-orders';
import Skeleton from './Skeleton';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './OrderProduct.module.scss';
import { toKebabCase } from '../../../../../lib/utils/utils';
import { useBreakpoint } from '../../../../../lib/hooks';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  cartProduct: CartProduct | undefined;
  onEdit?: (cartItem: CartProduct) => void;
  orderSubmitted?: boolean;
  submittingIds?: string[];
  theme?: OrderProductTheme;
}

const OrderProduct: FC<Props> = ({
  cartProduct,
  onEdit,
  orderSubmitted,
  submittingIds = [],
  theme = OrderProductTheme.CARD,
}) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [removeFromCart, { loading: removingItemFromCart }] = useMutation(
    DELETE_FROM_CART_MUTATION,
    {
      refetchQueries: [{ query: GET_SHOPPING_CARTS_QUERY }],
    }
  );

  const productImage = useMemo<string>(() => {
    if (!cartProduct) {
      return '';
    }

    return cartProduct.product.trait.imageUrl;
  }, [cartProduct]);

  const onRemove = () => {
    if (cartProduct) {
      removeFromCart({ variables: { input: { cartProductId: cartProduct.id } } })
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('alert.removed-successfully'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  if (cartProduct === undefined) return <Skeleton />;

  return (
    <div className={cx(styles['order-product'])}>
      <div className={cx(styles['order-product__upper-section'])}>
        <div className={cx(styles['order-product__image-wrapper'])}>
          <img
            alt={cartProduct.product.name}
            className={cx(styles['order-product__product-img'])}
            src={productImage}
          />
        </div>
        <div className={cx(styles['order-product__info-container'])}>
          <p
            className={cx(
              styles['order-product__info-text'],
              styles['order-product__info-text--bold']
            )}
          >
            {cartProduct.product.name}
          </p>
          <p
            className={cx(
              styles['order-product__info-text'],
              styles['order-product__info-text--light']
            )}
          >
            {t(`product.sizes.${toKebabCase(cartProduct.product.productSize || '')}`, {
              count: cartProduct.quantity,
            })}
          </p>
          <p
            className={cx(
              styles['order-product__info-text'],
              styles['order-product__info-text--light']
            )}
          >
            {cartProduct.product.seedTreatment?.name}
          </p>
        </div>
      </div>
      <div className={cx(styles['order-product__lower-section'])}>
        {!orderSubmitted && (
          <div className={cx(styles['order-product__button-container'])}>
            <Button
              type="button"
              onClick={() => {
                onEdit?.(cartProduct);
              }}
              icon={EditIcon}
              theme={ButtonThemes.SECONDARY}
              className={cx(styles['order-product__icon-wrapper'])}
              iconPosition={IconPosition.LEFT}
              iconClassName={cx(styles['order-product__icon-button'])}
              altText={t('general.edit')}
              disabled={submittingIds.length > 0}
            >
              {isXs && theme === OrderProductTheme.CARD && t('general.edit')}
            </Button>
            <Button
              type="button"
              onClick={onRemove}
              icon={removingItemFromCart ? SpinnerIcon : TrashCanIcon}
              theme={ButtonThemes.SECONDARY}
              className={cx(styles['order-product__icon-wrapper'])}
              iconPosition={IconPosition.LEFT}
              iconClassName={
                removingItemFromCart
                  ? cx(styles['order-product__icon-button--loading'])
                  : cx(styles['order-product__icon-button'])
              }
              disabled={submittingIds.length > 0}
              altText={t('general.remove')}
            >
              {isXs && theme === OrderProductTheme.CARD && t('general.remove')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

OrderProduct.displayName = 'OrderProduct';

export default OrderProduct;
