import {
  AccessLevel,
  InviteExternalInput,
  InviteRecommendedInput,
  UserAccountInfo,
} from '../../__generated__/graphql';
import { ArrowDropDownIcon, SpinnerIcon } from '../../assets/icons';
import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect, useRef } from 'react';
import {
  GET_FARM_CHILD_USERS,
  INVITE_NEW_USER_MUTATION,
  INVITE_RECOMMENDED_USER_MUTATION,
} from '../../lib/graphql/UserPermissions.gql';
import cx from 'classnames';
import Dropdown from '../_shared/Dropdown';
import { DropdownOption } from '../../lib/constants/react-select';
import { GraphQLErrorCodes } from '../../lib/constants/errorCodes';
import { IconPosition } from '../../lib/constants/components';
import { InviteUserRequest } from '../../lib/types/onboarding';
import Modal from '../_shared/Modal';
import { SnackbarContextActionTypes } from '../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../SnackbarContainer/Snackbar/Snackbar.types';
import styles from './InviteUserModal.module.scss';
import TextField from '../_shared/TextField';
import { toGQLLanguage } from '../../lib/utils/i18n';
import { useFormErrors } from '../../lib/hooks/useFormErrors';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Validations } from '../../lib/utils/form-validations';

export interface Props {
  isVisible: boolean;
  hide: () => void;
  accessLevels: DropdownOption[];
  recommendedUser?: Partial<UserAccountInfo> | undefined;
}

const InviteUserModal: FC<Props> = ({ isVisible, hide, accessLevels, recommendedUser }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [inviteNewUser, { loading: inviteNewLoading }] = useMutation(INVITE_NEW_USER_MUTATION, {
    refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
  });
  const [inviteRecommendedUser, { loading: inviteRecommendedLoading }] = useMutation(
    INVITE_RECOMMENDED_USER_MUTATION,
    {
      refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
    }
  );
  const [, dispatchSnackbar] = useSnackbarContext();
  const formID = useRef<string>(uuid());
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, dirtyFields },
    trigger,
  } = useForm<InviteUserRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      accessLevel: undefined,
    },
  });
  useFormErrors(trigger, dirtyFields);

  useEffect(() => {
    reset({
      firstName: recommendedUser?.firstName || '',
      lastName: recommendedUser?.lastName || '',
      email: recommendedUser?.email || recommendedUser?.crmEmail || '',
    });
  }, [recommendedUser, reset]);

  const onSubmitNewUser = (formData: InviteUserRequest) => {
    if (isValid && formData.email && formData.accessLevel) {
      const input: InviteExternalInput = {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email,
        accessLevel: formData?.accessLevel,
        language: toGQLLanguage(language),
      };
      inviteNewUser({ variables: { input } })
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('alert.invited-successfully'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch((e) => {
          const label =
            e?.graphQLErrors?.[0]?.extensions?.code === GraphQLErrorCodes.ALREADY_REGISTERED
              ? t('errors.user-already-invited')
              : t('errors.generic');

          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label,
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          reset();
          hide();
        });
    }
  };

  const onSubmitRecommendedUser = (formData: InviteUserRequest) => {
    if (recommendedUser?.id) {
      const input: InviteRecommendedInput = {
        firstName: recommendedUser?.firstName || formData?.firstName,
        lastName: recommendedUser?.lastName || formData.lastName,
        email: recommendedUser?.crmEmail || formData.email,
        userId: recommendedUser?.id,
        accessLevel: formData?.accessLevel as AccessLevel,
        language: toGQLLanguage(language),
      };
      inviteRecommendedUser({ variables: { input } })
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('alert.invited-successfully'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch((e) => {
          const label =
            e?.graphQLErrors?.[0]?.extensions?.code === GraphQLErrorCodes.ALREADY_REGISTERED
              ? t('errors.user-already-invited')
              : t('errors.generic');

          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label,
              state: SnackbarStates.WARNING,
            },
          });
        })
        .finally(() => {
          reset();
          hide();
        });
    }
  };

  const header = (
    <span className={cx(styles['header-content'])}>
      {recommendedUser
        ? t('onboarding.recommended-users-information')
        : t('onboarding.new-users-information')}
    </span>
  );

  return (
    <Modal
      classNames={{
        modalWrapper: cx(styles['invite-user__modal']),
        modalContent: cx(styles['invite-user__modal']),
        modalContentHeader: cx(styles['invite-user__modal-header']),
        footer: cx(styles['invite-user__modal-footer']),
      }}
      title={
        recommendedUser ? t('onboarding.invite-recommended-user') : t('onboarding.invite-new-user')
      }
      headerContent={header}
      isVisible={isVisible}
      hide={() => {
        hide();
        reset();
      }}
      primaryCta={{
        label: t('general.continue'),
        action: () => {},
        buttonType: 'submit',
        buttonFormId: formID.current,
        disabled: inviteNewLoading || inviteRecommendedLoading,
        buttonIcon: inviteNewLoading || inviteRecommendedLoading ? SpinnerIcon : undefined,
        buttonIconClassName: cx({
          [styles['invite-user__spinner']]: inviteNewLoading || inviteRecommendedLoading,
        }),
        buttonIconPosition: IconPosition.LEFT,
      }}
      secondaryCta={{
        label: t('general.cancel'),
        disabled: inviteNewLoading || inviteRecommendedLoading,
        action: () => {
          reset();
          hide();
        },
      }}
    >
      <form
        className={cx(styles['form-wrapper'])}
        id={formID.current}
        onSubmit={
          recommendedUser ? handleSubmit(onSubmitRecommendedUser) : handleSubmit(onSubmitNewUser)
        }
      >
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.firstName,
          })}
          inputType="text"
          label={t('form.labels.first-name')}
          {...register('firstName', {
            required: t('form.errors.required'),
          })}
          hasError={!!errors.firstName}
          helperText={errors.firstName?.message}
          disabled={!!recommendedUser?.firstName}
        />
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.lastName,
          })}
          inputType="text"
          label={t('form.labels.last-name')}
          {...register('lastName', {
            required: t('form.errors.required'),
          })}
          hasError={!!errors.lastName}
          helperText={errors.lastName?.message}
          disabled={!!recommendedUser?.lastName}
        />
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.email,
          })}
          inputType="text"
          label={t('form.labels.email')}
          {...register('email', {
            required: t('form.errors.required'),
            ...Validations.email(t('form.labels.email'), t),
          })}
          hasError={!!errors.email}
          helperText={errors.email?.message}
          disabled={!!recommendedUser?.email || !!recommendedUser?.crmEmail}
        />
        <Controller
          control={control}
          name="accessLevel"
          rules={{ required: t('form.errors.required') }}
          render={({ field: { onChange, value, name } }) => (
            <Dropdown
              name={name}
              value={accessLevels?.find((option) => option.value === value)}
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.accessLevel,
              })}
              label={t('form.labels.choose-access-level')}
              options={accessLevels}
              DropdownIndicatorIcon={ArrowDropDownIcon}
              onChange={onChange}
              searchable={false}
              hasError={!!errors?.accessLevel}
              helperText={errors.accessLevel?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};

InviteUserModal.displayName = 'InviteUserModal';

export default InviteUserModal;
