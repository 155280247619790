import {
  AccessLevel,
  UpdateUserAccessLevelInput,
  UserAccountInfo,
  UserStatus,
} from '../../../../../__generated__/graphql';
import { ArrowDropDownIcon, SpinnerIcon, TrashCanIcon } from '../../../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../../../lib/constants/components';
import { ComponentProps, FC, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DropdownOption, OptionsFooter } from '../../../../../lib/constants/react-select';
import {
  GET_FARM_CHILD_USERS,
  REMOVE_USER_FROM_FARM,
  UPDATE_USER_ACCESS_LEVEL,
} from '../../../../../lib/graphql/UserPermissions.gql';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import Button from '../../../../../components/_shared/Button';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { GET_USER_IS_ADMIN_QUERY } from '../../../../../lib/graphql/UserInformation.gql';
import { getUserAccessLevels } from '../../../../../lib/utils/onboarding';
import InviteUserModal from '../../../../../components/InviteUserModal';
import Modal from '../../../../../components/_shared/Modal';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../../lib/constants/stepper';
import StepsWrapper from '../../../StepsWrapper';
import styles from './UserPermissions.module.scss';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';

const OptionsFooterComponent: FC<ComponentProps<OptionsFooter['element']>> = ({
  className,
  onSelect,
}) => {
  const { t } = useTranslation();
  return (
    <button className={cx(styles['options-footer'])} type="button" onClick={onSelect}>
      <div className={cx(styles['options-footer__separator'])}></div>
      <div className={cx(className, styles['options-footer__content'])}>
        <span className={cx(styles['options-footer__label'])}>
          {t('onboarding.user-permissions.dropdown-options-footer')}
        </span>
        <TrashCanIcon className={cx(styles['options-footer__icon'])} aria-hidden="true" />
      </div>
    </button>
  );
};

const UserPermissions: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const {
    data: childUsers,
    error: childUsersError,
    loading: childUsersLoading,
  } = useQuery(GET_FARM_CHILD_USERS);
  const {
    data: currentUser,
    error: currentUserError,
    loading: currentUsersLoading,
  } = useQuery(GET_USER_IS_ADMIN_QUERY);
  const [removeUserFromFarm, { loading: removingUser }] = useMutation(REMOVE_USER_FROM_FARM, {
    refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
  });
  const [updateUserAccessLevel] = useMutation(UPDATE_USER_ACCESS_LEVEL, {
    refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();
  const [accessLevels, setAccessLevels] = useState<DropdownOption[]>(getUserAccessLevels(t));
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [recommendedUser, setRecommendedUser] = useState<UserAccountInfo>();
  const [removeUser, setRemoveUser] = useState<string | undefined>();
  const [invitedUsers, setInvitedUsers] = useState<UserAccountInfo[]>([]);
  const [recommendedUsers, setRecommendedUsers] = useState<UserAccountInfo[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<string[]>([]);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setAccessLevels(getUserAccessLevels(t));
  }, [t]);

  useEffect(() => {
    const invited: UserAccountInfo[] = [];
    const recommended: UserAccountInfo[] = [];

    childUsers?.farm.users.forEach((user) => {
      if (user.accountInfo && user.accountInfo.id !== currentUser?.user.accountInfo.id) {
        switch (user.accountInfo.status) {
          case UserStatus.ExternalInvitedPendingSignup:
          case UserStatus.CrmInvitedPendingSignup:
          case UserStatus.PendingEmailConfirmation:
          case UserStatus.Active:
            invited.push(user.accountInfo as UserAccountInfo);
            break;
          case UserStatus.CrmInitialized:
            recommended.push(user.accountInfo as UserAccountInfo);
            break;
          default:
            break;
        }
      }
    });

    setInvitedUsers(invited);
    setRecommendedUsers(recommended);
  }, [childUsers, currentUser?.user.accountInfo.id]);

  useEffect(() => {
    if (currentUserError || childUsersError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [childUsersError, currentUserError, dispatchSnackbar, t]);

  const handleRemoveUserFromFarm = (userId: string) => {
    removeUserFromFarm({ variables: { userId } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.removed-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setRemoveUser(undefined);
      });
  };

  const onSubmit = (data: any) => {
    const input: UpdateUserAccessLevelInput = {
      accessLevel: data.target.value as AccessLevel,
      userId: data.id,
    };

    setLoadingUsers((prevUsers) => [...prevUsers, data.id]);
    updateUserAccessLevel({ variables: { input } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.updated-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setLoadingUsers((prevUsers) => prevUsers.filter((id) => id !== data.id));
      });
  };

  const onBack = () => {
    back?.();
  };

  const onSubmitUserPermissions = () => {
    next?.();
  };

  const renderUserWrapper = (user: UserAccountInfo, rightSide: ReactNode) => {
    const userName = `${user.firstName} ${user.lastName}`;

    return (
      <div className={cx(styles['invited-user'])} key={user.id}>
        <div className={cx(styles['invited-user__details-wrapper'])}>
          <span className={cx(styles['invited-user__details-wrapper--name'])}>{userName}</span>
          <span className={cx(styles['invited-user__details-wrapper--email'])}>
            {user.email || user.crmEmail}
          </span>
        </div>
        {rightSide}
      </div>
    );
  };

  const renderRecommendedUsers = () =>
    recommendedUsers?.map((user) => {
      const inviteButton = (
        <Button
          className={cx(styles['invite-button'])}
          type="button"
          onClick={() => {
            setModalVisible(true);
            setRecommendedUser(user);
          }}
        >
          {t('general.invite')}
        </Button>
      );
      return renderUserWrapper(user, inviteButton);
    });

  const renderInvitedUsers = () =>
    invitedUsers?.map((user) => {
      const nameId = `accesslevel-${user.id}`;
      const currentVal = accessLevels.find((option) => option.value === user.accessLevel);
      const optionsFooter: OptionsFooter = {
        element: OptionsFooterComponent,
        onSelect: () => setRemoveUser(user.id),
      };

      const dropdownForm = (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={nameId}
            render={({ field: { onChange, name } }) => (
              <Dropdown
                name={name}
                value={currentVal}
                label={t('form.labels.select-user-permissions')}
                className={cx(styles['form-dropdown'])}
                options={accessLevels}
                searchable={false}
                onChange={(e) => {
                  onChange(e);
                  onSubmit({ ...e, id: user.id });
                }}
                DropdownIndicatorIcon={ArrowDropDownIcon}
                defaultValue={currentVal}
                optionsFooter={optionsFooter}
                isLoading={loadingUsers.includes(user.id)}
              />
            )}
          />
        </form>
      );

      return renderUserWrapper(user, dropdownForm);
    });

  const modalContent = (
    <div className={cx(styles['modal'])}>
      <p className={cx(styles['modal__subtitle'])}>
        {t('onboarding.partner-permissions.remove-farm-access-subtitle')}
      </p>
      <p className={cx(styles['modal__text'])}>
        <Trans
          i18nKey="onboarding.partner-permissions.remove-farm-access-content"
          components={[<sup />]}
        />
      </p>
    </div>
  );

  return (
    <StepsWrapper
      title={t('onboarding.user-permissions.heading')}
      subTitle={t('onboarding.user-permissions.description')}
      submit={{
        text: t('general.next'),
        onClick: onSubmitUserPermissions,
        disabled: childUsersLoading || currentUsersLoading,
      }}
      back={{ text: t('general.back'), onClick: onBack }}
      loadingQuery={childUsersLoading || currentUsersLoading}
    >
      <div className={cx(styles['user-permissions'])}>
        <Button
          className={cx(styles['user-permissions__invite'])}
          type="button"
          theme={ButtonThemes.SECONDARY}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          {t('onboarding.user-permissions.button-invite-new-user')}
        </Button>

        {!!recommendedUsers?.length && (
          <>
            <h2 className={cx(styles['user-permissions__subheading'])}>
              {t('onboarding.user-permissions.recommended-users')}
            </h2>
            <span className={cx(styles['user-permissions__user-description'])}>
              {t('onboarding.user-permissions.recommended-users-description')}
            </span>
            {renderRecommendedUsers()}
          </>
        )}

        {!!invitedUsers?.length && (
          <>
            <h2 className={cx(styles['user-permissions__subheading'])}>
              {t('onboarding.user-permissions.invited-users')}
            </h2>
            <span className={cx(styles['user-permissions__user-description'])}>
              {t('onboarding.user-permissions.invited-users-description')}
            </span>
            {renderInvitedUsers()}
          </>
        )}

        <InviteUserModal
          isVisible={modalVisible}
          hide={() => {
            setModalVisible(false);
            setRecommendedUser(undefined);
          }}
          accessLevels={accessLevels}
          recommendedUser={recommendedUser}
        />

        <Modal
          title={t('onboarding.partner-permissions.remove-farm-access-header')}
          isVisible={removeUser !== undefined}
          hide={() => {
            setRemoveUser(undefined);
          }}
          primaryCta={{
            label: t('onboarding.partner-permissions.remove-access'),
            action: () => {
              handleRemoveUserFromFarm(removeUser as string);
            },
            buttonType: 'button',
            disabled: removingUser,
            buttonIcon: removingUser ? SpinnerIcon : undefined,
            buttonIconPosition: IconPosition.LEFT,
            buttonIconClassName: cx(styles['user-permissions__spinner']),
          }}
          secondaryCta={{
            label: t('general.cancel'),
            disabled: removingUser,
            action: () => {
              setRemoveUser(undefined);
            },
          }}
        >
          {modalContent}
        </Modal>
      </div>
    </StepsWrapper>
  );
};

UserPermissions.displayName = 'UserPermissions';

export default UserPermissions;
