import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { Controller, useForm } from 'react-hook-form';
import { FC, ReactNode } from 'react';
import { PhoneIcon, SpinnerIcon } from '../../../../assets/icons';
import { SignUpStep, StepComponentProps } from '../../../../lib/constants/stepper';
import {
  VerificationMethod as VerificationMethodEnum,
  VerifyFarmAdminInput,
} from '../../../../__generated__/graphql';

import Button from '../../../../components/_shared/Button';
import cx from 'classnames';
import { hidePhone } from '../../../../lib/utils/sign-up';
import Radio from '../../../../components/_shared/Radio';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import StepWrapper from '../../components/StepWrapper';
import styles from './VerificationMethod.module.scss';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { useMutation } from '@apollo/client';
import useSignUpContext from '../../../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { VerificationMethodForm } from '../../../../lib/types/singup';
import { VERIFY_FARM_ADMIN } from './VerificationMethod.gql';

const VerificationMethod: FC<StepComponentProps> = ({ next, goToStep }) => {
  const { t } = useTranslation();
  const [verifyFarmAdmin, { loading: loadingVerifyFarmAdmin }] = useMutation(VERIFY_FARM_ADMIN);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [{ phone, farmId }, setSignUpInformation] = useSignUpContext();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<VerificationMethodForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      method: VerificationMethodEnum.Sms,
    },
  });
  useFormErrors(trigger, dirtyFields);

  const onSubmit = (formData: VerificationMethodForm) => {
    if (farmId && phone) {
      const input: VerifyFarmAdminInput = {
        farmId,
        phone,
        verificationMethod: formData.method,
      };
      verifyFarmAdmin({ variables: { input } })
        .then(({ data }) => {
          if (data?.verifyFarmAdmin.verificationCodeSent) {
            setSignUpInformation((prev) => ({
              ...prev,
              verificationMethod: formData.method,
            }));
            next?.();
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const renderDescription = (): ReactNode => (
    <div>
      <p className={cx(styles['verification-method__description'])}>
        {t('sign-up.shared.description')}
        <span className={cx(styles['verification-method__phone'])}>
          {phone && hidePhone(phone)}
        </span>
      </p>
      <Button
        theme={ButtonThemes.TEXT_LINK}
        onClick={() => goToStep?.(SignUpStep.PRIMARY_OWNER)}
        type="button"
      >
        {t('sign-up.shared.change')}
      </Button>
    </div>
  );

  return (
    <StepWrapper
      Icon={PhoneIcon}
      title={t('sign-up.verification-method.heading')}
      description={renderDescription()}
    >
      <form className={cx(styles['verification-method__form'])} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="method"
          rules={{ required: t('form.errors.required') }}
          render={({ field: { onChange, name, value } }) => (
            <div className={cx(styles['verification-method__radio-group'])}>
              <Radio
                className={cx(styles['verification-method__radio'])}
                label={t('sign-up.verification-method.text-option')}
                value={VerificationMethodEnum.Sms}
                checked={value === VerificationMethodEnum.Sms}
                name={name}
                onChange={onChange}
              />
              <Radio
                className={cx(styles['verification-method__radio'])}
                label={t('sign-up.verification-method.phone-option')}
                value={VerificationMethodEnum.Voice}
                checked={value === VerificationMethodEnum.Voice}
                name={name}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Button
          className={cx(styles['verification-method__button'])}
          type="submit"
          onClick={() => {
            trigger();
          }}
          icon={loadingVerifyFarmAdmin ? SpinnerIcon : undefined}
          iconClassName={cx(styles['verification-method__button--loading'])}
          disabled={loadingVerifyFarmAdmin}
          iconPosition={IconPosition.LEFT}
        >
          {t('general.continue')}
        </Button>
      </form>
    </StepWrapper>
  );
};

VerificationMethod.displayName = 'VerificationMethod';

export default VerificationMethod;
