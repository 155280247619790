import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { FC, ReactNode } from 'react';
import { PhoneIcon, SpinnerIcon } from '../../../../assets/icons';
import { SignUpStep, StepComponentProps } from '../../../../lib/constants/stepper';
import { VerifyCodeInput, VerifyFarmAdminInput } from '../../../../__generated__/graphql';
import Button from '../../../../components/_shared/Button';
import cx from 'classnames';
import { GraphQLErrorCodes } from '../../../../lib/constants/errorCodes';
import { hidePhone } from '../../../../lib/utils/sign-up';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import StepWrapper from '../../components/StepWrapper';
import styles from './PhoneVerification.module.scss';
import TextField from '../../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { useMutation } from '@apollo/client';
import useSignUpContext from '../../../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { VerificationCodeForm } from '../../../../lib/types/singup';
import { VERIFY_CODE } from './PhoneVerification.gql';
import { VERIFY_FARM_ADMIN } from '../VerificationMethod/VerificationMethod.gql';

const PhoneVerification: FC<StepComponentProps> = ({ next, goToStep }) => {
  const { t } = useTranslation();
  const [{ farmId, phone, verificationMethod }, setSignUpInformation] = useSignUpContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [verifyFarmAdmin, { loading: verifyAdminLoading }] = useMutation(VERIFY_FARM_ADMIN);
  const [verifyCode, { loading: verifyCodeLoading }] = useMutation(VERIFY_CODE);

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    register,
    trigger,
    setError,
  } = useForm<VerificationCodeForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  useFormErrors(trigger, dirtyFields);

  const resendCode = () => {
    if (farmId && phone && verificationMethod) {
      const input: VerifyFarmAdminInput = {
        farmId,
        phone,
        verificationMethod,
      };
      verifyFarmAdmin({ variables: { input } })
        .then(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('sign-up.phone-verification.resend-notification'),
              state: SnackbarStates.SUCCESS,
            },
          });
        })
        .catch((resendError) => {
          const rateLimit = resendError?.graphQLErrors?.some(
            (error: any) => error.extensions.code === GraphQLErrorCodes.RATE_LIMIT
          );

          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: rateLimit
                ? t('sign-up.phone-verification.rate-limit-error')
                : t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const onSubmit = (formData: VerificationCodeForm) => {
    if (phone && formData.code) {
      const input: VerifyCodeInput = {
        phone,
        verificationCode: formData.code,
        accountCode: farmId || '',
      };
      verifyCode({ variables: { input } })
        .then(({ data }) => {
          if (data?.verifyCode.valid) {
            setSignUpInformation((prev) => ({
              ...prev,
              verificationId: data.verifyCode.verificationId,
            }));
            next?.();
          } else {
            setError('code', { message: t('sign-up.phone-verification.incorrect-code') });
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const renderDescription = (): ReactNode => (
    <div>
      <p className={cx(styles['phone-verification__description'])}>
        {t('sign-up.shared.description')}
        <span className={cx(styles['phone-verification__phone'])}>{phone && hidePhone(phone)}</span>
      </p>
      <Button
        theme={ButtonThemes.TEXT_LINK}
        onClick={() => goToStep?.(SignUpStep.PRIMARY_OWNER)}
        type="button"
      >
        {t('sign-up.shared.change')}
      </Button>
    </div>
  );

  return (
    <StepWrapper
      Icon={PhoneIcon}
      title={t('sign-up.phone-verification.heading')}
      description={renderDescription()}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cx(styles['phone-verification__form'])}>
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.code,
          })}
          {...register('code', {
            required: t('form.errors.required'),
          })}
          inputType="text"
          label={t('form.labels.verification-code')}
          hasError={!!errors.code}
          helperText={errors.code?.message}
        />
        <ul className={cx(styles['phone-verification__list'])}>
          <li>{t('sign-up.phone-verification.expiration')}</li>
          <li>
            <span>{t('sign-up.phone-verification.not-received')}</span>{' '}
            <Button
              className={cx(styles['phone-verification__resend'])}
              theme={ButtonThemes.TEXT_LINK}
              onClick={resendCode}
              type="button"
              disabled={verifyAdminLoading}
            >
              {t('sign-up.phone-verification.resend')}
            </Button>
          </li>
        </ul>
        <Button
          className={cx(styles['phone-verification__button'])}
          type="submit"
          disabled={!isValid || verifyCodeLoading}
          onClick={() => trigger()}
          icon={verifyCodeLoading ? SpinnerIcon : undefined}
          iconClassName={cx(styles['phone-verification__button--loading'])}
          iconPosition={IconPosition.LEFT}
        >
          {t('general.continue')}
        </Button>
      </form>
    </StepWrapper>
  );
};

PhoneVerification.displayName = 'PhoneVerification';

export default PhoneVerification;
