import cx from 'classnames';
import { FC } from 'react';
import styles from './Skeleton.module.scss';

export interface Props {}

const Skeleton: FC<Props> = () => (
  <div className={cx(styles['skeleton'])}>
    <div className={cx(styles['skeleton__upper-section'])}>
      <div
        className={cx(styles['skeleton__product-img'])}
      ></div>
      <div className={cx(styles['skeleton__info-container'])}>
        <div className={cx(styles['skeleton__text'], styles['skeleton__text--medium'])}></div>
        <div className={cx(styles['skeleton__text'], styles['skeleton__text--small'])}></div>
        <div className={cx(styles['skeleton__text'], styles['skeleton__text--small'])}></div>
      </div>
    </div>

    <div className={cx(styles['skeleton__lower-section'])}>
      <div className={cx(styles['skeleton__text'], styles['skeleton__text--small'], styles['skeleton__text-padding'])}></div>
    </div>
  </div>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
