import { FC, useEffect, useState } from 'react';
import AddPreferredRetailerModal from '../../../../../components/PreferredRetailer/AddPreferredRetailerModal';
import Button from '../../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../../lib/constants/components';
import cx from 'classnames';
import { GET_PREFERRED_RETAILERS } from '../../../../../lib/graphql/PreferredRetailers.gql';
import PreferredRetailerWrapper from '../../../../../components/PreferredRetailer/PreferredRetailerWrapper/PreferredRetailerWrapper';
import { RetailerProvider } from '../../../../../lib/contexts/retailer/RetailerContext';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../../lib/constants/stepper';
import StepsWrapper from '../../../StepsWrapper';
import styles from './PreferredRetailers.module.scss';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const PreferredRetailers: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_PREFERRED_RETAILERS);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, error, t]);

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const onNext = () => {
    next?.();
  };

  const onBack = () => {
    back?.();
  };

  return (
    <StepsWrapper
      title={t('onboarding.preferred-retailers.step-title')}
      subTitle={t('onboarding.preferred-retailers.step-subtitle')}
      submit={{
        text: t('general.next'),
        onClick: onNext,
        disabled: loading,
      }}
      back={{
        text: t('general.back'),
        onClick: onBack,
      }}
      loadingQuery={loading}
    >
      <div className={cx(styles['preferred-retailers'])}>
        {data?.user.retailers && data?.user.retailers?.length > 0 && (
          <div className={cx(styles['preferred-retailers__retailers-wrapper'])}>
            <p className={cx(styles['preferred-retailers__label'])}>
              {t('onboarding.preferred-retailers.current-preferred-retailers')}
            </p>
            {data?.user.retailers.map((retailer) => (
              <div key={retailer.id} className={cx(styles['preferred-retailers__retailer'])}>
                <PreferredRetailerWrapper retailer={retailer} />
              </div>
            ))}
          </div>
        )}
        <Button
          className={cx(styles['preferred-retailers__button'])}
          type="button"
          onClick={openAddModal}
          theme={ButtonThemes.SECONDARY}
        >
          {t('retailers.add-retailer', { count: 1 })}
        </Button>
        <RetailerProvider>
          <AddPreferredRetailerModal hide={closeAddModal} open={addModalOpen} />
        </RetailerProvider>
      </div>
    </StepsWrapper>
  );
};

PreferredRetailers.displayName = 'PreferredRetailers';

export default PreferredRetailers;
