import { DateRangeOptions, Period } from '../../lib/constants/forms';
import {
  DateRangePeriod,
  OrderHistoryResponse,
  PurchaseHistoryFilters,
} from '../../lib/models/Purchase.model';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  GET_ORDER_HISTORY_FILTERS_QUERY,
  GET_PURCHASES_PRE_ORDER_TABLES,
} from './OrderHistory.gql';
import {
  getFilterDateRange,
  getUpdatedOrderHistorySearchParams,
} from '../../lib/utils/order-purchases';
import {
  OrderHistoryQueryVariables,
  PreOrderProduct,
  PurchaseProduct,
} from '../../__generated__/graphql';
import { useLazyQuery, useQuery } from '@apollo/client';

import cx from 'classnames';
import DataVisualization from './DataVisualization';
import { dateTimeUtc } from '../../lib/constants/date-formats';
import dayjs from '../../lib/utils/dates';
import { FilterProvider } from '../../lib/contexts/accessibility/FilterContext';
import FilterSection from './FilterSection';
import { FlagKeys } from '../../lib/constants/launch-darkly';
import LaunchDarklyWrapper from '../../components/_shared/LaunchDarklyWrapper';
import { LDIdentityType } from '../../components/_shared/LaunchDarklyWrapper/LaunchDarklyWrapper.types';
import { OrderChartProvider } from '../../lib/contexts/order-chart/OrderChartContext';
import { OrderHistoryContextActionTypes } from '../../lib/contexts/order-history/OrderHistoryContext.types';
import { SnackbarContextActionTypes } from '../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './OrderHistory.module.scss';
import TabsSection from './TabsSection/TabsSection';
import { useDeepCompare } from '../../lib/hooks';
import useOrderHistoryContext from '../../lib/contexts/order-history/useOrderHistoryContext';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import usePaginationContext from '../../lib/contexts/pagination/usePaginationContext';
import { useSearchParams } from 'react-router-dom';
import useSnackbarContext from '../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const OrderHistory: FC = () => {
  usePageTitle('order-purchase-history');
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [searchPayload, dispatch] = useOrderHistoryContext();
  const [, setPageNumber] = usePaginationContext();
  const deepComparedSearchPayload = useDeepCompare(searchPayload);
  const [, setSearchParams] = useSearchParams();
  const {
    data: filtersData,
    loading: loadingFilters,
    error: errorFilters,
  } = useQuery(GET_ORDER_HISTORY_FILTERS_QUERY);
  const [fetchPurchasesPreOrders, { loading: loadingResults }] = useLazyQuery(
    GET_PURCHASES_PRE_ORDER_TABLES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [results, setResults] = useState<OrderHistoryResponse>({
    purchases: { count: 0, data: [] },
    orders: { count: 0, data: [] },
  });

  useEffect(() => {
    if (errorFilters) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [errorFilters, dispatchSnackbar, t]);

  useEffect(() => {
    const updatesSearchParams = getUpdatedOrderHistorySearchParams(deepComparedSearchPayload);
    setSearchParams(updatesSearchParams, { replace: true });
  }, [deepComparedSearchPayload, setSearchParams]);

  const onFiltersChange = useCallback(
    (
      appliedFilters: PurchaseHistoryFilters,
      query: string | undefined,
      selectedDateRange: DateRangeOptions,
      customDate?: Period
    ) => {
      const dateRange: DateRangePeriod = customDate || getFilterDateRange(selectedDateRange);
      dateRange.option = selectedDateRange;
      setPageNumber(0);
      dispatch({
        type: OrderHistoryContextActionTypes.UpdateFiltersQuery,
        payload: { filters: { ...appliedFilters, dateRange }, query },
      });
    },
    [dispatch, setPageNumber]
  );

  useEffect(() => {
    const queryPayload: OrderHistoryQueryVariables = {
      orderBy: {
        orderDirection: deepComparedSearchPayload.sortOrder,
        orderField: deepComparedSearchPayload.sortBy,
      },
      limit: deepComparedSearchPayload.limit,
      offset: deepComparedSearchPayload.offset,
      filterBy: {
        query: deepComparedSearchPayload.query,
        programIds: deepComparedSearchPayload.filters.programIds,
        productSkus: deepComparedSearchPayload.filters.productSkus,
        farmIds: deepComparedSearchPayload.filters.farmIds,
        retailerIds: deepComparedSearchPayload.filters.retailerIds,
        dateRange:
          deepComparedSearchPayload.filters.dateRange?.endDate &&
          deepComparedSearchPayload.filters.dateRange?.startDate
            ? {
                endDate: dayjs(deepComparedSearchPayload.filters.dateRange?.endDate).format(
                  dateTimeUtc
                ),
                startDate: dayjs(deepComparedSearchPayload.filters.dateRange?.startDate).format(
                  dateTimeUtc
                ),
              }
            : undefined,
      },
    };

    fetchPurchasesPreOrders({
      variables: queryPayload,
    })
      .then(({ data }) => {
        if (data) {
          setResults({
            purchases: {
              count: data.purchaseProducts.totalCount,
              data: data.purchaseProducts.purchaseProducts as PurchaseProduct[],
            },
            orders: {
              count: data.preOrderProducts.totalCount,
              data: data.preOrderProducts.preOrderProducts as PreOrderProduct[],
            },
          });
        }
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  }, [dispatchSnackbar, deepComparedSearchPayload, t, fetchPurchasesPreOrders]);

  return (
    <section className={cx(styles['order-history'])}>
      <div className={cx(styles['order-history__hero'])}>
        <h1 className={cx(styles['order-history__heading'])}>{t('order-history.heading')}</h1>
        <p className={cx(styles['order-history__sub-heading'])}>{t('order-history.description')}</p>
      </div>
      <LaunchDarklyWrapper flagKey={FlagKeys.DATA_VIS} identityType={LDIdentityType.EMAIL}>
        <div>
          <h2 className={cx(styles['order-history__heading-secondary'])}>
            {t('data-visualization.title')}
          </h2>
          <OrderChartProvider>
            <DataVisualization />
          </OrderChartProvider>
          <hr className={cx(styles['order-history__divider'])} />
          <h2 className={cx(styles['order-history__heading-secondary'])}>
            {t('order-history.breakdown')}
          </h2>
        </div>
      </LaunchDarklyWrapper>
      <FilterProvider>
        <FilterSection
          isLoading={loadingFilters}
          filters={filtersData}
          onFiltersChange={onFiltersChange}
        />
      </FilterProvider>
      <TabsSection results={results} isLoading={loadingResults} />
    </section>
  );
};

OrderHistory.displayName = 'OrderHistory';

export default OrderHistory;
