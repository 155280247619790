import {
  Option as CustomOption,
  DropdownIndicator,
  MultiValue,
  SingleValue,
  ValueContainer,
} from './subcomponents';
import { DropdownOption, OptionsFooter } from '../../../lib/constants/react-select';
import { FC, SVGProps, useRef } from 'react';
import Select, { MenuPlacement } from 'react-select';

import cx from 'classnames';
import { getReactSelectStyles } from '../../../lib/utils/react-select';
import styles from './Dropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  className?: string;
  DropdownIndicatorIcon?: FC<SVGProps<SVGSVGElement>>;
  hasError?: boolean;
  id?: string;
  isDisabled?: boolean;
  label?: string;
  name: string;
  onChange?(e?: any): void;
  options: DropdownOption[];
  value?: DropdownOption | DropdownOption[];
  isMulti?: boolean;
  helperText?: string;
  defaultValue?: DropdownOption;
  optionsFooter?: OptionsFooter;
  searchable?: boolean;
  hideLabel?: boolean;
  isLoading?: boolean;
  clearable?: boolean;
  menuPlacement?: MenuPlacement;
}

const Dropdown = ({
  className,
  DropdownIndicatorIcon,
  hasError,
  id,
  isDisabled,
  label,
  name,
  onChange,
  options,
  value,
  isMulti,
  helperText,
  defaultValue,
  optionsFooter,
  clearable,
  searchable = true,
  hideLabel = false,
  isLoading = false,
  menuPlacement = 'auto',
}: Props) => {
  const { t } = useTranslation();
  const selectId = useRef<string>(id || uuid());
  const selectOptions = options.map((option) => ({
    ...option,
    labelToDisplay: option.label,
    label: option.description ? `${option.label}. ${option.description}` : option.label,
  }));

  return (
    <div className={cx(className)}>
      <label className={cx('sr-only')} htmlFor={selectId.current}>
        {label}
      </label>
      <div className={cx(styles['select-wrapper'])}>
        <Select
          aria-label={label}
          components={{
            DropdownIndicator:
              (clearable && value) || (isMulti && Array.isArray(value) && !!value.length)
                ? null
                : DropdownIndicator,
            ValueContainer,
            MultiValue,
            Option: CustomOption,
            SingleValue,
          }}
          closeMenuOnSelect={!isMulti}
          defaultValue={defaultValue}
          // @ts-ignore
          DropdownIndicatorIcon={DropdownIndicatorIcon}
          hasError={hasError}
          hideSelectedOptions={false}
          inputId={selectId.current}
          isClearable={clearable || isMulti}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={searchable}
          menuPlacement={menuPlacement}
          name={name}
          noOptionsMessage={() => t('general.no-options')}
          options={optionsFooter ? [...selectOptions, optionsFooter] : selectOptions}
          onChange={(newValue: unknown) => {
            const selectedOption = newValue as DropdownOption | OptionsFooter;
            if (selectedOption && 'onSelect' in selectedOption) {
              selectedOption.onSelect();
            } else if (!isMulti) {
              onChange?.({
                target: {
                  value: selectedOption?.value,
                  name,
                },
              });
            } else {
              const multiSelectedOption = newValue as DropdownOption[];
              onChange?.({
                target: {
                  value: multiSelectedOption,
                  name,
                },
              });
            }
          }}
          placeholder={hideLabel ? null : label}
          showSpinner={isLoading}
          styles={getReactSelectStyles()}
          value={value || ''}
        />
        {helperText && (
          <span
            className={cx(styles['helper-text'], {
              [styles['helper-text--disabled']]: isDisabled,
              [styles['helper-text--errors']]: hasError,
            })}
          >
            {helperText}
          </span>
        )}
      </div>
    </div>
  );
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
