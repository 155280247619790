import { FC, useEffect } from 'react';

import cx from 'classnames';
import { GET_FARM_ADMIN_QUERY } from '../../../lib/graphql/FarmInformation.gql';
import { GET_TOTAL_REWARDS_EARNED_QUERY } from '../../../lib/graphql/ProgramRewards.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../lib/graphql/UserInformation.gql';
import { hasRewardsAccess } from '../../../lib/utils/user';
import HeroSkeleton from './Skeleton/HeroSkeleton';
import NoRewardsAccessSection from './RewardsSection/NoRewardsAccessSection';
import NoRewardsSection from './RewardsSection/NoRewardsSection';
import RewardsSection from './RewardsSection/RewardsSection';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../SnackbarContainer/Snackbar/Snackbar.types';
import styles from './LandingHero.module.scss';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  year: number;
}

const LandingHero: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const { data: userData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const {
    data: farmAdminData,
    loading: loadingFarmAdmin,
    error: farmAdminError,
  } = useQuery(GET_FARM_ADMIN_QUERY);
  const {
    data: rewardsTotalData,
    loading: loadingRewardsTotal,
    error: rewardsError,
  } = useQuery(GET_TOTAL_REWARDS_EARNED_QUERY, {
    skip: !hasRewardsAccess(userData),
    variables: {
      input: {
        year,
      },
    },
  });

  useEffect(() => {
    if (farmAdminError || rewardsError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [farmAdminError, rewardsError, dispatchSnackbar, t]);

  const renderRightSection = () => {
    if (!rewardsTotalData?.enrolledProgramsInfo?.totalRewardAmountInDollars) {
      return <NoRewardsSection year={year} />;
    } else if (hasRewardsAccess(userData)) {
      return <RewardsSection year={year} />;
    }
    return <NoRewardsAccessSection year={year} />;
  };

  return (
    <div className={cx(styles['landing-hero'])}>
      {loadingFarmAdmin || loadingRewardsTotal ? (
        <HeroSkeleton />
      ) : (
        <div className={cx(styles['landing-hero__wrapper'])}>
          <div className={cx(styles['landing-hero__welcome-wrapper'])}>
            <p className={cx(styles['landing-hero__text'], styles['landing-hero__text--overline'])}>
              {t('punctuation.colon-key-value', {
                key: t('general.account-code'),
                value: farmAdminData?.farm?.farmInfo?.id,
              })}
            </p>
            <h1 className={cx(styles['landing-hero__text'], styles['landing-hero__text--heading'])}>
              {t('punctuation.exclamation-mark', {
                value: t('bayer-value.welcome-back', {
                  firstName: userData?.user?.accountInfo?.firstName,
                }),
              })}
            </h1>
          </div>
          <hr className={cx(styles['landing-hero__line'])}></hr>
          <div className={cx(styles['landing-hero__rewards-wrapper'])}>{renderRightSection()}</div>
        </div>
      )}
    </div>
  );
};

LandingHero.displayName = 'LandingHero';

export default LandingHero;
