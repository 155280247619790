import { AllFilterCategoryMobile, AllFiltersForm } from '../../../../lib/constants/forms';
import { AllFiltersOptions, FilterModalView } from '../FilterSection.const';
import { Dispatch, FC, SetStateAction } from 'react';

import Checkbox from '../../../../components/_shared/Checkbox/Checkbox';
import cx from 'classnames';
import DateRangeFilterOptions from '../DateRange/DateRangeFilterOptions/DateRangeFilterOptions';
import { FilterDropdownOption } from '../../../../lib/utils/order-purchases';
import styles from '../FilterSection.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  register: UseFormRegister<AllFiltersForm>;
  dropdownOptions?: AllFiltersOptions;
  formId: string;
  setModalView: Dispatch<SetStateAction<FilterModalView>>;
  onSubmit(): void;
}

const AllFiltersModalView: FC<Props> = ({
  dropdownOptions,
  register,
  formId,
  setModalView,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const renderCheckboxes = (filter: FilterDropdownOption[], name: AllFilterCategoryMobile) => {
    if (filter) {
      return filter.map((option) => {
        const { label, value } = option;
        return (
          <Checkbox
            key={value}
            className={{
              wrapper: cx(styles['checkbox']),
              label: cx(styles['checkbox__label']),
            }}
            {...register(name)}
            label={label}
            value={value}
          />
        );
      });
    }

    return undefined;
  };

  const renderFilterSection = (filter: FilterDropdownOption[], name: AllFilterCategoryMobile) => (
    <div className={cx(styles['mobile-filters__section'])}>
      <p className={cx(styles['mobile-filters__heading'])}>{t(`filter.${name}`)}</p>
      {renderCheckboxes(filter, name)}
      <hr />
    </div>
  );

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className={cx(styles['mobile-filters'])}>
        {!!dropdownOptions?.products.length &&
          renderFilterSection(
            dropdownOptions?.products as FilterDropdownOption[],
            AllFilterCategoryMobile.PRODUCT
          )}
        {!!dropdownOptions?.retailers.length &&
          renderFilterSection(
            dropdownOptions?.retailers as FilterDropdownOption[],
            AllFilterCategoryMobile.RETAILER
          )}
        {dropdownOptions?.purchaser &&
          dropdownOptions?.purchaser.length > 1 &&
          renderFilterSection(
            dropdownOptions?.purchaser as FilterDropdownOption[],
            AllFilterCategoryMobile.PURCHASER
          )}
        <div className={cx(styles['mobile-filters__section'])}>
          <p className={cx(styles['mobile-filters__heading'])}>{t(`filter.date`)}</p>
          <DateRangeFilterOptions
            onCustomClick={() => {
              setModalView(FilterModalView.DATE);
            }}
            registerRadioButtons={register(AllFilterCategoryMobile.DATE_RANGE)}
          />
          <hr />
        </div>
        {!!dropdownOptions?.programs.length &&
          renderFilterSection(
            dropdownOptions?.programs as FilterDropdownOption[],
            AllFilterCategoryMobile.PROGRAM
          )}
      </div>
    </form>
  );
};

export default AllFiltersModalView;
