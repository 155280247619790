import { CalendarTodayIcon } from '../../../../../assets/icons';
import cx from 'classnames';
import { DateRangeOptions } from '../../../../../lib/constants/forms';
import { FC } from 'react';
import Radio from '../../../../../components/_shared/Radio';
import styles from './DateRangeFilterOptions.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

export interface Props {
  registerRadioButtons: UseFormRegisterReturn;
  onCustomClick(): void;
}

const DateRangeFilterOptions: FC<Props> = ({ registerRadioButtons, onCustomClick }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className={cx(styles['date-range-filter-options'])}>
      <Radio
        label={t('filter.date-range-options.all')}
        {...registerRadioButtons}
        value={DateRangeOptions.ALL}
        className={cx(styles['radio-buttons'])}
      />
      <Radio
        label={t('filter.date-range-options.last-thirty-days')}
        {...registerRadioButtons}
        value={DateRangeOptions.LAST_THIRTY_DAYS}
        className={cx(styles['radio-buttons'])}
      />
      <Radio
        label={t('filter.date-range-options.last-six-months')}
        {...registerRadioButtons}
        value={DateRangeOptions.LAST_SIX_MONTHS}
        className={cx(styles['radio-buttons'])}
      />
      <Radio
        label={t('filter.date-range-options.year', {
          year: currentYear - 1,
        })}
        {...registerRadioButtons}
        value={DateRangeOptions.PREVIOUS_YEAR}
        className={cx(styles['radio-buttons'])}
      />
      <Radio
        label={t('filter.date-range-options.year', {
          year: currentYear,
        })}
        {...registerRadioButtons}
        value={DateRangeOptions.CURRENT_YEAR}
        className={cx(styles['radio-buttons'])}
      />
      <button
        className={cx(styles['date-range-filter-options__custom'])}
        type="button"
        onClick={onCustomClick}
      >
        <p>{t('filter.date-range-options.custom')}</p>
        <CalendarTodayIcon
          className={cx(styles['date-range-filter-options__custom-icon'])}
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

DateRangeFilterOptions.displayName = 'DateRangeOptions';

export default DateRangeFilterOptions;
