import { IconPosition, LinkThemes } from '../../../lib/constants/components';

import cx from 'classnames';
import { ElementType } from 'react';
import { getButtonAriaLabel } from '../../../lib/utils/a11y';
import { Props } from './Link.types';
import styles from './Link.module.scss';

const DEFAULT_ELEMENT = 'a';

const Link = <C extends ElementType = typeof DEFAULT_ELEMENT>({
  as,
  altText,
  children,
  className,
  icon: Icon = null,
  iconClassName,
  iconPosition = IconPosition.RIGHT,
  theme = LinkThemes.TEXT_LINK,
  ...props
}: Props<C>) => {
  const Tag = as || DEFAULT_ELEMENT;

  return (
    <Tag
      {...props}
      aria-label={getButtonAriaLabel(children, altText)}
      className={cx(className, styles['link'], styles[`link--${theme}`], {
        [styles[`link--i-${iconPosition}`]]: !!Icon,
      })}
    >
      <span className={cx(styles['link__content'])}>{children}</span>
      {Icon && (
        <Icon
          className={cx(iconClassName, styles['link__icon'], styles[`link__icon--${iconPosition}`])}
          aria-hidden="true"
        />
      )}
    </Tag>
  );
};

Link.displayName = 'Link';

export default Link;
