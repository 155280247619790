import { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../clients/apollo-client';
import { SnackbarProvider } from './snackbar/SnackbarContext';

export interface Props {
  children: ReactNode;
}

const RootProvider: FC<Props> = ({ children }) => (
  <ApolloProvider client={client}>
    <SnackbarProvider>{children}</SnackbarProvider>
  </ApolloProvider>
);

export default RootProvider;
