import cx from 'classnames';
import { FC } from 'react';
import styles from './Skeleton.module.scss';

export interface Props {
  className?: string;
}

const Skeleton: FC<Props> = ({ className }) => (
  <div className={cx(styles['skeleton'], className)}>
    <div className={cx(styles['skeleton__text-wrapper'])}>
      <div className={cx(styles['skeleton__text'], styles['skeleton__text--small'])}></div>
      <div className={cx(styles['skeleton__text'], styles['skeleton__text--medium'])}></div>
      <div className={cx(styles['skeleton__text'], styles['skeleton__text--large'])}></div>
    </div>
  </div>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
