import { BAYER_VALUE_PROGRAM_ID, ProgramBadgeSize } from '../../../../lib/constants/programs';
import { ButtonThemes, SpinnerSizes } from '../../../../lib/constants/components';
import { FC, useEffect, useState } from 'react';
import { getBayerValueProgram, getDynamicProgramColorMap } from '../../../../lib/utils/programs';
import cx from 'classnames';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import LinkButton from '../../../../components/_shared/LinkButton/LinkButton';
import { LinkUrls } from '../../../../lib/constants/react-router';
import ProgramBadge from '../../../../components/_shared/ProgramBadge';
import { ProgramBreakdown } from '../../../../lib/models/Program.model';
import Spinner from '../../../../components/_shared/Spinner';
import styles from './EnrolledPrograms.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface Props {
  year: number;
}

const EnrolledPrograms: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const { data: breakdownData, loading: loadingBreakdown } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year } },
    }
  );
  const [groupedBreakdown, setGroupedBreakdown] = useState<ProgramBreakdown[]>([]);

  useEffect(() => {
    if (breakdownData?.enrolledProgramsInfo) {
      setGroupedBreakdown(breakdownData.enrolledProgramsInfo.otherPrograms as ProgramBreakdown[]);
    } else {
      setGroupedBreakdown([]);
    }
  }, [breakdownData, t]);

  const renderLoadingSection = () => (
    <div className={cx(styles['enrolled-programs__wrapper'])}>
      <div className={cx(styles['enrolled-programs__spinner-wrapper'])}>
        <Spinner size={SpinnerSizes.LG} />
      </div>
    </div>
  );

  const renderEnrolledPrograms = () => {
    if (groupedBreakdown.length) {
      return (
        <div className={cx(styles['enrolled-programs__wrapper'])}>
          <div>
            <span className={cx(styles['enrolled-programs__title'])}>
              {t('bayer-value.enrolled-programs.heading')}
            </span>
            <span className={cx(styles['enrolled-programs__sub-title'])}>
              {t('bayer-value.enrolled-programs.description')}
            </span>
          </div>
          <div className={cx(styles['enrolled-programs__card'])}>
            <div className={cx(styles['enrolled-programs__card-program'])}>
              <ProgramBadge
                program={getDynamicProgramColorMap({ id: BAYER_VALUE_PROGRAM_ID })}
                size={ProgramBadgeSize.LG}
              />
              <span>{getBayerValueProgram(breakdownData)?.name}</span>
            </div>
            <span className={cx(styles['enrolled-programs__card-description'])}>
              {t(`bayer-value.enrolled-programs.program-description`)}
            </span>
            <LinkButton
              type="button"
              theme={ButtonThemes.PRIMARY}
              className={cx(styles['enrolled-programs__card-button'])}
              href={LinkUrls.BAYER_VALUE_PROGRAMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(`general.learn-more`)}
            </LinkButton>
          </div>
          <div className={cx(styles['enrolled-programs__programs'])}>
            {groupedBreakdown.map((program) => (
              <div className={cx(styles['enrolled-programs__program'])} key={program.program.id}>
                <ProgramBadge
                  className={cx(styles['enrolled-programs__program-icon'])}
                  program={getDynamicProgramColorMap(program.program)}
                  size={ProgramBadgeSize.LG}
                />
                <span className={cx(styles['enrolled-programs__program-text'])}>
                  {program.program.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={cx(styles['enrolled-programs'])}>
      {loadingBreakdown ? renderLoadingSection() : renderEnrolledPrograms()}
    </div>
  );
};

EnrolledPrograms.displayName = 'EnrolledPrograms';

export default EnrolledPrograms;
