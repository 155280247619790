import { gql } from '../../../../__generated__';

export const VERIFY_FARM_ADMIN = gql(`
    mutation VerifyFarmAdmin($input: VerifyFarmAdminInput!) {
        verifyFarmAdmin(input: $input) {
            registered
            verificationCodeSent
        }
    }`
);
