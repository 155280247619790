import cx from 'classnames';
import { FC } from 'react';
import { ProgramRewardMetrics } from '../../../../../__generated__/graphql';
import styles from '../../BayerValueProgram.module.scss';
import useBreakpoint from '../../../../../lib/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

export interface Props {
  programRewardMetrics: ProgramRewardMetrics[] | undefined;
}

const BayerValueHotPotatoesTable: FC<Props> = ({ programRewardMetrics }) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();

  return (
    <table className={cx(styles['table'])}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.segments.hot-potatoes.table-header-products')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.segments.hot-potatoes.table-header-spend')}
          </th>
          <th className={cx(styles['table__header'])}>
            {t('bayer-value.segments.hot-potatoes.table-header-reward-level')}
          </th>
        </tr>
      </thead>
      <tbody className={cx(styles['table__tbody'])}>
        {programRewardMetrics?.map((segment) => (
          <tr key={segment.id} className={cx(styles['table__data-row'])}>
            <td
              className={cx(styles['table__data-cell'])}
              data-label={t('bayer-value.segments.hot-potatoes.table-header-products')}
            >
              {segment.eligibleProducts}
            </td>
            <td
              className={cx(styles['table__data-cell'])}
              data-label={t('bayer-value.segments.hot-potatoes.table-header-spend')}
            >
              {t('format.price', { value: segment.eligiblePurchases })}
            </td>
            <td
              className={cx(styles['table__data-cell'])}
              data-label={t('bayer-value.segments.hot-potatoes.table-header-reward-level')}
            >
              {t('punctuation.percentage', { value: segment.rewardLevel })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

BayerValueHotPotatoesTable.displayName = 'BayerValueHotPotatoesTable';

export default BayerValueHotPotatoesTable;
