import { gql } from '../../__generated__';

export const GET_PURCHASES_TABLE = gql(`
query Purchases($offset: Int, $orderBy: PurchasePreOrderSortOrder, $filterBy: PurchasePreOrderFilters, $limit: Int) {
  purchaseProducts(offset: $offset, orderBy: $orderBy, filterBy: $filterBy, limit: $limit) {
    purchaseProducts {
      id
      quantity
      product {
        sku
        name
      }
      purchase {
        purchaser
        invoiceDate
        invoiceId
        id
        retailer {
          id
          name
          alias
          address {
            city
            province
          }
        }
        purchaseProducts {
          quantity
          product {
            sku
            name
            preAuthUrl
          }
        }
        rewardPrograms {
          id
          name
          year
          type
        }
      }
      rewardPrograms {
        id
        name
        year
        type
      }
    }
  }
}
`);
