import {
  OrderChartContextAction,
  OrderChartContextActionTypes,
  OrderChartContextState,
} from './OrderCharContext.types';

const OrderChartContextReducer = (
  state: OrderChartContextState,
  action: OrderChartContextAction
): OrderChartContextState => {
  switch (action.type) {
    case OrderChartContextActionTypes.UpdateFilters:
      return { ...state, filters: action.payload };
    case OrderChartContextActionTypes.UpdateType:
      return { ...state, type: action.payload };
    case OrderChartContextActionTypes.UpdateOptions:
      return { ...state, options: action.payload };

    default:
      return state;
  }
};

export default OrderChartContextReducer;
