import { FC, memo } from 'react';

import cx from 'classnames';
import styles from './TableRow.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

const CropProtectionSection: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['crop-protection-section'])}>
      <span className={cx(styles['crop-protection-section__title'])}>
        {t('purchases.details.explore-crop-options')}
      </span>
      <p className={cx(styles['crop-protection-section__description'])}>
        {t('purchases.details.explore-crops-description')}
      </p>
    </div>
  );
};

export default memo(CropProtectionSection);
