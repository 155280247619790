import { AccessLevel } from '../../__generated__/graphql';
import { BannerTheme } from '../../components/BayerValue/ClimateFieldViewBanner/ClimateFieldViewBanner.types';
import BayerRewardsWidget from './components/BayerRewardsWidget';
import CalculatorWidget from './components/CalculatorWidget';
import ClimateFieldViewBanner from '../../components/BayerValue/ClimateFieldViewBanner';
import cx from 'classnames';
import { DashboardProps } from './Dashboard.types';
import DelkabPromotionWidget from './promotions/DelkabPromotionWidget';
import EventPassWidget from './promotions/EventPassWidget';
import FarmMembersWidget from './components/FarmMembersWidget';
import { FC } from 'react';
import { Language } from '../../lib/constants/i18n';
import LocalTeamWidget from './components/LocalTeamWidget';
import ProgramPromotionSection from '../BayerRewardsLanding/components/ProgramPromotionSection';
import PurchasesWidget from './components/PurchasesWidget';
import styles from './Dashboard.module.scss';
import SummerSizzlerWidget from './promotions/SummerSizzlerWidget';
import TakeActionWidget from './components/TakeActionWidget';
import useDashboardContext from '../../lib/contexts/dashboard/useDashboardContext';
import { useTranslation } from 'react-i18next';
import WelcomeMessage from './components/WelcomeMessage';

const DashboardMobile: FC<DashboardProps> = ({ hasAccess }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [
    {
      displayCalculator,
      displayDekalbBanner,
      displayEventPass,
      displayProgramPromotions,
      displaySummerSizzlerBanner,
    },
  ] = useDashboardContext();

  return (
    <div className={cx(styles['dm'])}>
      <div className={cx(styles['dm__dark-section'])}>
        <div className={cx(styles['dm__welcome-wrapper'])}>
          <WelcomeMessage />
        </div>
      </div>

      {hasAccess === AccessLevel.FullAccess && (
        <>
          <TakeActionWidget />
          <hr className={cx(styles['dm__hr'])} />
        </>
      )}
      <BayerRewardsWidget />
      <hr className={cx(styles['dm__hr'])} />
      <PurchasesWidget />
      <hr className={cx(styles['dm__hr'])} />
      <div className={!displayEventPass ? cx(styles['ds__banner--hidden']) : ''}>
        <EventPassWidget />
        <hr className={cx(styles['dm__hr'])} />
      </div>
      {displayDekalbBanner && (
        <>
          <DelkabPromotionWidget />
          <hr className={cx(styles['dm__hr'])} />
        </>
      )}
      {displaySummerSizzlerBanner && (
        <>
          <SummerSizzlerWidget />
          <hr className={cx(styles['dm__hr'])} />
        </>
      )}
      {hasAccess === AccessLevel.FullAccess &&
        language === Language.En &&
        displayProgramPromotions && (
          <>
            <ProgramPromotionSection />
            <hr className={cx(styles['dm__hr'])} />
          </>
        )}
      {displayCalculator && (
        <>
          <CalculatorWidget />
          <hr className={cx(styles['dm__hr'])} />
        </>
      )}

      <ClimateFieldViewBanner bannerTheme={BannerTheme.DASHBOARD} />
      <div className={cx(styles['dm__dark-section'])}>
        <div className={cx(styles['dm__members-wrapper'])}>
          <FarmMembersWidget />
        </div>
        <LocalTeamWidget />
      </div>
    </div>
  );
};

DashboardMobile.displayName = 'DashboardMobile';

export default DashboardMobile;
