import dayjs, { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  groupProgramBreakdownAsOther,
  mapProgramBreakdownToChartEntries,
} from '../../../../lib/utils/programs';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';
import {
  ordinalDayFullMonthYearEn,
  ordinalDayFullMonthYearFr,
} from '../../../../lib/constants/date-formats';
import {
  ProgramBreakdown,
  ProgramBreakdownWithColorIcon,
} from '../../../../lib/models/Program.model';
import { ProgramCardLink, ProgramCardWithPill } from '../../../../components/_shared/ProgramCard';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { GET_FARM_PROVINCE } from '../../../../lib/graphql/FarmInformation.gql';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import { getRegionSpecificLink } from '../../../../lib/utils/utils';
import { InfoOutlineIcon } from '../../../../assets/icons';
import Link from '../../../../components/_shared/Link';
import { ProgramCardBadgeAlignment } from '../../../../components/_shared/ProgramCard/ProgramCard.types';
import ProgramDoughnutChart from '../../../../components/_shared/ProgramDoughnutChart';
import { ProgramType } from '../../../../lib/constants/programs';
import { Link as RouterLink } from 'react-router-dom';
import Spinner from '../../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../../lib/constants/components';
import styles from './BreakdownSection.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useQuery } from '@apollo/client';

interface Props {
  year: number;
}

const BreakdownSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const rewardsPeriod = getRewardEarningPeriodDate(year);
  const dateFormat = useDateFormat(ordinalDayFullMonthYearEn, ordinalDayFullMonthYearFr);
  const { data: breakdownData, loading: loadingBreakdown } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year } },
    }
  );
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE);
  const [groupedBreakdown, setGroupedBreakdown] = useState<ProgramBreakdownWithColorIcon[]>([]);

  useEffect(() => {
    if (breakdownData?.enrolledProgramsInfo) {
      setGroupedBreakdown(
        groupProgramBreakdownAsOther(
          [
            breakdownData.enrolledProgramsInfo.bayerValueProgramInfo,
            ...breakdownData.enrolledProgramsInfo.otherPrograms,
          ].filter((breakdown) => breakdown) as ProgramBreakdown[],
          t
        )
      );
    }
  }, [breakdownData, t]);

  const hasRewards: boolean = useMemo(
    () => !!breakdownData?.enrolledProgramsInfo?.totalRewardAmountInDollars,
    [breakdownData]
  );

  const renderRewardsSection = () => (
    <div className={cx(styles['breakdown-section__content-wrapper'])}>
      <div className={cx(styles['breakdown-section__cards-wrapper'])}>
        {groupedBreakdown.map((breakdownItem) => (
          <div className={cx(styles['breakdown-section__card'])} key={breakdownItem.program.id}>
            <ProgramCardLink
              as={RouterLink}
              description={
                <Trans
                  i18nKey="program.earned-as-of"
                  values={{
                    value: breakdownItem.rewardAmountInDollars,
                    date: dayjs(rewardsPeriod.start).format(dateFormat),
                  }}
                  components={[<strong />]}
                />
              }
              program={breakdownItem}
              to={
                breakdownItem.program.type === ProgramType.BAYER_VALUE
                  ? `/${PageRoutes.BAYER_VALUE_PROGRAM}/${breakdownItem.program.id}`
                  : `/${PageRoutes.OTHER_PROGRAMS}?year=${year}`
              }
            />
          </div>
        ))}
      </div>
      <div className={cx(styles['breakdown-section__chart-container'])}>
        <div className={cx(styles['breakdown-section__chart-wrapper'])}>
          <ProgramDoughnutChart
            data={mapProgramBreakdownToChartEntries(groupedBreakdown)}
            label={t('bayer-value.breakdown.rewards-earned')}
          />
        </div>
      </div>
    </div>
  );

  const renderNoRewardsSection = () => (
    <div className={cx(styles['breakdown-section__enrolled'])}>
      <div className={cx(styles['breakdown-section__enrolled-wrapper'])}>
        {groupedBreakdown.map((breakdownItem) => (
          <div
            key={breakdownItem?.program.id}
            className={cx(styles['breakdown-section__enrolled-card'])}
          >
            <ProgramCardWithPill
              badgeAlignment={ProgramCardBadgeAlignment.CENTER}
              program={breakdownItem}
            />
          </div>
        ))}
      </div>
      <div className={cx(styles['breakdown-section__enrolled-footer'])}>
        <InfoOutlineIcon className={cx(styles['breakdown-section__enrolled-icon'])} aria-hidden />
        <p className={cx(styles['breakdown-section__enrolled-footer-text'])}>
          {t('bayer-rewards-widget.footer-text')}{' '}
          <Link
            href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
              east: LinkUrls.BAYER_VALUE_CONDITIONS_EAST,
              west: LinkUrls.BAYER_VALUE_CONDITIONS_WEST,
            })}
            target="_blank"
            rel="noopener noreferrer"
            className={cx(styles['enrolled__footer-link'])}
          >
            {t('general.learn-more')}
          </Link>
        </p>
      </div>
    </div>
  );

  const renderLoadingSection = () => (
    <div className={cx(styles['breakdown-section__spinner-wrapper'])}>
      <Spinner size={SpinnerSizes.LG} />
    </div>
  );

  const renderView = () => {
    if (loadingBreakdown) {
      return renderLoadingSection();
    } else if (hasRewards) {
      return renderRewardsSection();
    }

    return renderNoRewardsSection();
  };

  return (
    <section
      className={cx({
        [styles['breakdown-section']]: !loadingBreakdown && breakdownData?.enrolledProgramsInfo,
      })}
    >
      {loadingBreakdown ? (
        <>
          <div className={cx(styles['breakdown-section__skeleton-heading'])} />
          <div className={cx(styles['breakdown-section__skeleton-description'])}></div>
        </>
      ) : (
        breakdownData?.enrolledProgramsInfo && (
          <>
            <h2 className={cx(styles['breakdown-section__heading'])}>
              {hasRewards
                ? t('bayer-value.breakdown.heading')
                : t('bayer-value.breakdown.heading-no-rewards')}
            </h2>

            <p className={cx(styles['breakdown-section__description'])}>
              {hasRewards ? (
                <Trans
                  i18nKey="bayer-value.breakdown.description"
                  components={[
                    <Link
                      href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL}
                      className={cx(styles['contact-us__phone-number'])}
                    />,
                  ]}
                />
              ) : (
                t('bayer-value.breakdown.description-no-rewards')
              )}
            </p>

            {renderView()}
          </>
        )
      )}
    </section>
  );
};

BreakdownSection.displayName = 'BreakdownSection';

export default BreakdownSection;
