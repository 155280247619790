import cx from 'classnames';
import { FC } from 'react';
import styles from './Skeleton.module.scss';

export interface Props {}

const SKELETON_ROWS = 3;

const Skeleton: FC<Props> = () => (
  <>
    {Array.from(Array(SKELETON_ROWS).keys()).map((el) => (
      <li key={el} className={cx(styles['skeleton'])}>
        <div className={cx(styles['skeleton__avatar'])}></div>
        <div className={cx(styles['skeleton__text-wrapper'])}>
          <div className={cx(styles['skeleton__role'])}></div>
          <div className={cx(styles['skeleton__name'])}></div>
          <div className={cx(styles['skeleton__subtitle'])}></div>
        </div>
      </li>
    ))}
  </>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
