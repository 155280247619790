import {
  FarmSupportContact,
  GetSupportContactsQuery,
  Retailer,
  RetailerContact,
  SupportContact,
} from '../../__generated__/graphql';
import { LocalTeamMember, LocalTeamMemberType } from '../models/LocalTeam.model';
import { SupportContactRole } from '../constants/users';
import { TFunction } from 'i18next';

/**
 * Function to get support contact's role
 * @param role - role coming from the database (TSM - DAS - SG)
 * @returns
 */
export const getSupportContactRole = (role: string, t: TFunction) => {
  switch (role) {
    case SupportContactRole.TSM:
      return t('support-contacts-roles.tsm');
    case SupportContactRole.SG:
      return t('support-contacts-roles.sg');
    case SupportContactRole.DAS:
      return t('support-contacts-roles.das');
    case SupportContactRole.HORT:
      return t('support-contacts-roles.hort');
    default:
      return role;
  }
};

/**
 * A function that formats the local team member object
 * @param t - Retailer
 * @param type - Retailer or Support contact
 * @param retailer - Retailer
 * @param contact - Support contact or retailer's contact
 * @param role - Contact's role
 */
export const formatLocalTeamMember = (
  t: TFunction,
  type: LocalTeamMemberType,
  contact: RetailerContact | SupportContact | undefined,
  role: string,
  retailer?: Retailer
): LocalTeamMember => {
  const formattedTeamMember: LocalTeamMember = {
    id: '',
    role,
    contactFullName: '',
    email: '',
    phone: '',
  };

  if (type === LocalTeamMemberType.RETAILER && retailer) {
    formattedTeamMember.id = retailer.id;
    formattedTeamMember.email = contact?.email ?? retailer.email ?? '';
    formattedTeamMember.phone = retailer.phone ?? retailer.mobilePhone ?? '';
    formattedTeamMember.retailerName = retailer.alias
      ? `${retailer.name} (${retailer.alias})`
      : retailer.name;
  } else if (type === LocalTeamMemberType.SUPPORT && contact) {
    formattedTeamMember.id = contact.id;
    formattedTeamMember.email = contact.email ?? '';
    formattedTeamMember.phone =
      (contact as SupportContact).phone ?? (contact as SupportContact).otherPhone ?? '';
    formattedTeamMember.websiteUrl = (contact as SupportContact).websiteUrl ?? '';
  }

  if (contact) {
    formattedTeamMember.contactFullName =
      t('user-card.full-name', { firstName: contact.firstName, lastName: contact.lastName }) || '';
  }

  return formattedTeamMember;
};

/**
 * A function that formats preferred retailers and support contacts into a single list with their roles.
 * @param supportContactData - Data returned from the support contact query.
 * @param limit - Maximum number of entries in the list.
 */
export const formatContacts = (
  supportContactData: GetSupportContactsQuery | undefined,
  t: TFunction,
  limit?: number
): LocalTeamMember[] => {
  const retailerContactData = supportContactData?.user.retailers;

  const formattedSupportContacts =
    supportContactData?.farm.supportContacts
      .filter(
        (supportContact) =>
          supportContact.role === SupportContactRole.HORT ||
          supportContact.role === SupportContactRole.TSM ||
          supportContact.role === SupportContactRole.DAS
      )
      .map((supportContact: FarmSupportContact) =>
        formatLocalTeamMember(
          t,
          LocalTeamMemberType.SUPPORT,
          supportContact.contact,
          getSupportContactRole(supportContact.role, t)
        )
      ) ?? [];

  const formattedRetailers =
    retailerContactData?.map((retailer: Retailer) => {
      const primaryContact =
        retailer.contacts.find((contact) => contact.isPrimary) ?? retailer.contacts[0];

      return formatLocalTeamMember(
        t,
        LocalTeamMemberType.RETAILER,
        primaryContact,
        t('onboarding.preferred-retailers.preferred-retailer'),
        retailer
      );
    }) ?? [];

  const retailerContactList = [...formattedSupportContacts, ...formattedRetailers];

  if (limit && retailerContactList.length > limit) {
    return retailerContactList.slice(0, limit);
  }

  return retailerContactList;
};
