export enum ListType {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  SUGGESTED = 'Suggested',
}

export enum SupportContactRole {
  TSM = 'TSM',
  SG = 'SG',
  DAS = 'DAS',
  HORT = 'F&V SA',
}
