import { FC, useEffect, useState } from 'react';
import {
  filterOtherProgramsBreakdown,
  getDynamicProgramColorMap,
  getProgramYearFromUrl,
  mapBreakdownToColorIcon,
} from '../../../lib/utils/programs';
import {
  OrderDirection,
  PurchasePreOrderSortField,
  PurchaseProduct,
} from '../../../__generated__/graphql';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import BreakdownSection from './components/BreakdownSection/BreakdownSection';
import { buildUrlParams } from '../../../lib/utils/api';
import ContactUs from '../../../components/BayerValue/ContactUs';
import cx from 'classnames';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../lib/graphql/ProgramRewards.gql';
import { GET_PURCHASES_TABLE } from '../../../lib/graphql/Purchases.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../lib/graphql/UserInformation.gql';
import { getRewardEarningPeriodDate } from '../../../lib/utils/dates';
import { hasRewardsAccess } from '../../../lib/utils/user';
import Link from '../../../components/_shared/Link/Link';
import { OTHER_PROGRAM_ID } from '../../../lib/constants/programs';
import { PageRoutes } from '../../../lib/constants/react-router';
import ProgramHero from '../../../components/BayerValue/ProgramHero';
import RecentPurchases from '../../../components/BayerValue/RecentPurchases';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './OtherPrograms.module.scss';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';

const OtherPrograms: FC = () => {
  usePageTitle('other-programs');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [otherRewardsEarned, setOtherRewardsEarned] = useState<number>(0);
  const [otherProgramIds, setOtherProgramIds] = useState<string[]>([]);
  const [purchaseOrderDirection, setPurchaseOrderDirection] = useState<OrderDirection>(
    OrderDirection.Desc
  );
  const [purchaseOrderBy, setPurchaseOrderBy] = useState<PurchasePreOrderSortField>(
    PurchasePreOrderSortField.Date
  );
  const selectedYear = getProgramYearFromUrl();

  const { data: userData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const {
    data: programsBreakdownData,
    loading: loadingBreakdown,
    error: breakdownError,
  } = useQuery(GET_PROGRAM_REWARDS_BREAKDOWN_QUERY, {
    variables: { input: { year: selectedYear } },
  });
  const {
    data: purchaseData,
    loading: loadingPurchases,
    error: purchasesError,
  } = useQuery(GET_PURCHASES_TABLE, {
    variables: {
      orderBy: {
        orderDirection: purchaseOrderDirection,
        orderField: purchaseOrderBy,
      },
      limit: 5,
      filterBy: {
        programIds: otherProgramIds,
        dateRange: {
          startDate: getRewardEarningPeriodDate(selectedYear).start,
          endDate: getRewardEarningPeriodDate(selectedYear).end,
        },
      },
    },
    skip: loadingBreakdown || otherProgramIds.length === 0,
  });

  useEffect(() => {
    if (programsBreakdownData?.enrolledProgramsInfo) {
      const otherProgramsBreakdown = mapBreakdownToColorIcon(
        filterOtherProgramsBreakdown(programsBreakdownData?.enrolledProgramsInfo?.otherPrograms)
      );
      const totalAggregatedRewards = otherProgramsBreakdown.reduce(
        (accumulator, currentValue) => accumulator + currentValue.rewardAmountInDollars,
        0
      );
      setOtherRewardsEarned(totalAggregatedRewards);
      setOtherProgramIds(otherProgramsBreakdown.map((item) => item.program.id));

      if (!programsBreakdownData?.enrolledProgramsInfo?.totalRewardAmountInDollars) {
        navigate(`/${PageRoutes.REWARDS}`);
      }
    }
  }, [navigate, programsBreakdownData]);

  useEffect(() => {
    if (breakdownError || purchasesError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [breakdownError, purchasesError, dispatchSnackbar, t]);

  const getViewAllPurchasesLink = () =>
    `/${PageRoutes.ORDER_PURCHASE_HISTORY}${buildUrlParams(
      {
        programId: programsBreakdownData?.enrolledProgramsInfo?.otherPrograms
          ?.map((program) => program.program.id)
          .join(','),
      },
      false
    )}`;

  return (
    <div className={cx(styles['other-programs'])}>
      <ProgramHero
        programBadgeProps={getDynamicProgramColorMap({ id: OTHER_PROGRAM_ID })}
        programYear={selectedYear}
        totalRewardsEarned={
          programsBreakdownData?.enrolledProgramsInfo?.totalRewardAmountInDollars || 0
        }
        programRewardsEarned={otherRewardsEarned}
        programName={t('bayer-value.programs.other-programs')}
        isLoading={loadingBreakdown}
        programPeriod={{
          start:
            programsBreakdownData?.enrolledProgramsInfo?.otherPrograms[0]?.program?.effectiveFrom,
          end: programsBreakdownData?.enrolledProgramsInfo?.otherPrograms[0]?.program?.effectiveTo,
        }}
      />
      <BreakdownSection />
      <hr className={cx(styles['other-programs__divider'])} />
      {hasRewardsAccess(userData) && (
        <RecentPurchases
          description={
            <Trans
              i18nKey="bayer-value.recent-purchases.description"
              components={[
                <Link
                  as={RouterLink}
                  to={`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_MY_PREFERRED_RETAILER} `}
                />,
              ]}
            />
          }
          heading={t('bayer-value.recent-purchases.heading')}
          isLoading={loadingPurchases}
          recentPurchases={
            (purchaseData?.purchaseProducts?.purchaseProducts || []) as PurchaseProduct[]
          }
          viewAllUrl={getViewAllPurchasesLink()}
          purchaseOrderField={purchaseOrderBy}
          setPurchaseOrderField={setPurchaseOrderBy}
          setPurchaseOrderDirection={setPurchaseOrderDirection}
          purchaseOrderDirection={purchaseOrderDirection}
          year={getProgramYearFromUrl()}
        />
      )}
      <hr className={cx(styles['other-programs__divider'])} />
      <ContactUs />
    </div>
  );
};

OtherPrograms.displayName = 'OtherPrograms';

export default OtherPrograms;
