import {
  AccessLevel,
  FarmBayerValueProgramQuery,
  Purchase,
} from '../../../../__generated__/graphql';
import { FC, memo, useEffect, useState } from 'react';
import {
  filterBayerValuePrograms,
  filterOtherPrograms,
  getDynamicProgramColorMap,
  mapProgramsToPartialWithColorIcon,
} from '../../../../lib/utils/programs';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { getLastThreeRewardYears } from '../../../../lib/utils/dates';
import Link from '../../../_shared/Link';
import { PageRoutes } from '../../../../lib/constants/react-router';
import { PartialProgramWithColorIcon } from '../../../../lib/models/Program.model';
import ProgramBadge from '../../../_shared/ProgramBadge';
import { ProgramBadgeSize } from '../../../../lib/constants/programs';
import { Link as RouterLink } from 'react-router-dom';
import styles from './TableRow.module.scss';
import { useQuery } from '@apollo/client';

interface Props {
  bayerValueProgramData?: FarmBayerValueProgramQuery;
  item: Purchase;
}

const RewardsSection: FC<Props> = ({ bayerValueProgramData, item }) => {
  const { t } = useTranslation();
  const { data: userAccessLevel } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);
  const [bayerValueProgramsBreakdown, setBayerValueProgramsBreakdown] = useState<
    PartialProgramWithColorIcon[]
  >([]);
  const [otherProgramsBreakdown, setOtherProgramsBreakdown] = useState<
    PartialProgramWithColorIcon[]
  >([]);

  useEffect(() => {
    setBayerValueProgramsBreakdown(
      mapProgramsToPartialWithColorIcon(filterBayerValuePrograms(item.rewardPrograms))
    );
    setOtherProgramsBreakdown(
      mapProgramsToPartialWithColorIcon(filterOtherPrograms(item.rewardPrograms))
    );
  }, [bayerValueProgramData, item, t]);

  return bayerValueProgramsBreakdown.length > 0 || otherProgramsBreakdown.length > 0 ? (
    <>
      <span className={cx(styles['rewards-earned__title'])}>
        {t(`purchases.details.purchase.rewards-earned-for-this-order`)}
      </span>
      {bayerValueProgramsBreakdown.length > 0 && (
        <div className={cx(styles['rewards-program'])}>
          <span className={cx(styles['rewards-program__title'])}>
            <Trans i18nKey="purchases.details.bayer-value-program" components={[<sup />]} />
          </span>
          {bayerValueProgramsBreakdown.map((breakdown) => (
            <div className={cx(styles['rewards-program__info'])} key={breakdown.program.id}>
              <ProgramBadge
                className={cx(styles['rewards-program__icon'])}
                program={getDynamicProgramColorMap(breakdown.program)}
                size={ProgramBadgeSize.SM}
              />
              <span
                className={cx(
                  styles['rewards-program__text'],
                  styles['rewards-program__text--w-color']
                )}
              >
                {userAccessLevel &&
                userAccessLevel.user.accountInfo.accessLevel === AccessLevel.FullAccess &&
                breakdown.program.year &&
                getLastThreeRewardYears().includes(breakdown.program.year) ? (
                  <Link
                    as={RouterLink}
                    to={`/${PageRoutes.BAYER_VALUE_PROGRAM}/${breakdown.program.id}`}
                  >
                    {breakdown.program.name}
                  </Link>
                ) : (
                  breakdown.program.name
                )}
              </span>
            </div>
          ))}
        </div>
      )}
      {otherProgramsBreakdown.length > 0 && (
        <div className={cx(styles['rewards-program'])}>
          <span className={cx(styles['rewards-program__title'])}>
            {t('punctuation.colon', { value: t('purchases.details.other-programs') })}
          </span>
          {otherProgramsBreakdown.map((breakdown) => (
            <div className={cx(styles['rewards-program__info'])} key={breakdown.program.id}>
              <ProgramBadge
                className={cx(styles['rewards-program__icon'])}
                program={getDynamicProgramColorMap(breakdown.program)}
                size={ProgramBadgeSize.SM}
              />
              <span
                className={cx(
                  styles['rewards-program__text'],
                  styles['rewards-program__text--w-color']
                )}
              >
                {breakdown.program.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  ) : null;
};

export default memo(RewardsSection);
