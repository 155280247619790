import { gql } from '../../__generated__';

export const GET_SUPPORT_CONTACTS = gql(`
  query GetSupportContacts {
    farm {
      farmInfo {
        id
      }
      supportContacts {
        role
        contact {
          id
          email
          phone
          otherPhone
          websiteUrl
          firstName
          lastName
        }
      }
    }
    user {
      accountInfo {
        id
      }
      retailers {
        id
        name
        alias
        email
        phone
        mobilePhone
        address {
          address
          address2
          city
          province
          postalCode
        }
        contacts {
          id
          firstName
          lastName
          isPrimary
          email
          profilePictureUrl
        }
      }
    }
  }
`);
