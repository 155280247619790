import { components, DropdownIndicatorProps } from 'react-select';
import { ArrowDropDownIcon } from '../../../../assets/icons';
import cx from 'classnames';
import styles from './LanguageDropdown.module.scss';

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon className={cx(styles['language-dropdown__icon'])} aria-hidden="true" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
