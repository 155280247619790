import { FC, ReactNode, SVGProps } from 'react';
import cx from 'classnames';
import styles from './StepWrapper.module.scss';

export interface Props {
  title: string;
  children: ReactNode;
  description: ReactNode;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

const StepWrapper: FC<Props> = ({ title, Icon, children, description }) => (
  <div className={cx(styles['step-wrapper'])}>
    <div className={cx(styles['step-wrapper__header'])}>
      <div className={cx(styles['step-wrapper__icon-wrapper'])}>
        <Icon className={cx(styles['step-wrapper__icon'])} />
      </div>
      <h1 className={cx(styles['step-wrapper__title'])}>{title}</h1>
      {description}
    </div>
    {children}
  </div>
);

StepWrapper.displayName = 'StepWrapper';

export default StepWrapper;
