import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { FC, useEffect, useState } from 'react';
import {
  isValidEmail,
  isValidPhone,
  maskPhone,
  unMaskPhone,
  Validations,
} from '../../../../lib/utils/form-validations';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';
import { SignUpStep, StepComponentProps } from '../../../../lib/constants/stepper';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../components/_shared/Button';
import cx from 'classnames';
import farmers from '../../../../assets/images/login-landscape.jpg';
import ImageWithContent from '../../../../components/ImageWithContent';
import Link from '../../../../components/_shared/Link';
import Modal from '../../../../components/_shared/Modal/Modal';
import { PrimaryOwnerForm } from '../../../../lib/types/singup';
import RedirectLoginBanner from '../../../../components/RedirectLoginBanner/RedirectLoginBanner';
import { Link as RouterLink } from 'react-router-dom';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SpinnerIcon } from '../../../../assets/icons';
import styles from './PrimaryOwner.module.scss';
import SuccessCTA from '../../../../components/SuccessCTA';
import TextField from '../../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { useLazyQuery } from '@apollo/client';
import useSignUpContext from '../../../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { VERIFY_ADMIN_STATUS } from './PrimaryOwner.gql';

const PrimaryOwner: FC<StepComponentProps> = ({ next, goToStep }) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [showRedirectBanner, setShowRedirectBanner] = useState<boolean>(false);
  const [forgotCodeModalOpen, setForgotCodeModalOpen] = useState<boolean>(false);
  const [verifyAdminStatus, { loading: loadingVerifyStatus }] = useLazyQuery(VERIFY_ADMIN_STATUS);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [signUpContextData, setSignUpInformation] = useSignUpContext();

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    register,
    trigger,
    setValue,
  } = useForm<PrimaryOwnerForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  useFormErrors(trigger, dirtyFields);

  useEffect(() => {
    if (signUpContextData.email || signUpContextData.farmId) {
      setValue('phoneOrEmail', signUpContextData.email || '');
      setValue('accountId', signUpContextData.farmId || '');
      trigger();
    }
  }, [signUpContextData, setValue, trigger]);

  /**
   * Check whether the input value is only numeric characters,
   * and if it represents a phone number it masks the value.
   * @param value
   */
  const handleInputChange = (value: string) => {
    if (Number(value) && value.length === 10) {
      setValue('phoneOrEmail', maskPhone(value));
      trigger();
      return maskPhone(value);
    }
    return value;
  };

  const onSubmit = (formValues: PrimaryOwnerForm) => {
    const email = isValidEmail(formValues?.phoneOrEmail || '') ? formValues.phoneOrEmail : null;
    const phone = isValidPhone(formValues?.phoneOrEmail || '')
      ? unMaskPhone(formValues.phoneOrEmail || '')
      : null;

    const input = {
      farmId: formValues.accountId,
      phone,
      email,
    };

    const renderGenericError = () => {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    };

    verifyAdminStatus({ variables: { input } })
      .then(({ data }) => {
        if (data) {
          setSignUpInformation({
            ...input,
            bayerValueConsent: data.verificationStatus.bayerValueConsent,
            isAdmin: true,
          });
          if (data.verificationStatus.gigyaAccount?.emailExists) {
            setShowRedirectBanner(true);
          } else if (data.verificationStatus.registered) {
            setDisplayError(true);
          } else if (email || !data.verificationStatus.verificationRequired) {
            goToStep?.(SignUpStep.COMPLETE_ACCOUNT);
          } else {
            next?.();
          }
        } else {
          setDisplayError(true);
        }
      })
      .catch(() => {
        renderGenericError();
      });
  };

  return displayError ? (
    <SuccessCTA
      buttonTheme={ButtonThemes.SECONDARY}
      heading={t('sign-up.not-found.heading')}
      subHeading={
        <Trans
          i18nKey="sign-up.not-found.description"
          components={[<Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />]}
        />
      }
      buttonLinkText={t('sign-up.not-found.button-go-home')}
      buttonRedirectLink={PageRoutes.HOME}
    />
  ) : (
    <div className={cx(styles['primary-owner'])}>
      <ImageWithContent imageSrc={farmers} imageAlt={t('images.farmers')}>
        <div className={cx(styles['primary-owner__wrapper'])}>
          <div className={cx(styles['primary-owner__inner'])}>
            <h1 className={cx(styles['primary-owner__heading'])}>
              {t('sign-up.primary-owner.heading')}
            </h1>
            <p className={cx(styles['primary-owner__sub-heading'])}>
              {t('sign-up.primary-owner.sub-heading')}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.accountId,
                })}
                {...register('accountId', {
                  required: t('form.errors.required'),
                  ...Validations.accountCode(t('form.labels.account-number'), t),
                })}
                inputType="text"
                label={t('form.labels.account-number')}
                hasError={!!errors.accountId}
                helperText={errors.accountId?.message}
              />
              <TextField
                className={cx(styles['form-input'], {
                  [styles['form-input--error']]: !!errors.phoneOrEmail,
                })}
                {...register('phoneOrEmail', {
                  required: t('form.errors.required'),
                  ...Validations.emailOrPhone(t('form.labels.email-or-phone'), t),
                  onChange: ({ currentTarget }) => {
                    const { value } = currentTarget;
                    // eslint-disable-next-line no-param-reassign
                    currentTarget.value = handleInputChange(value);
                  },
                })}
                inputType="text"
                label={t('form.labels.email-or-phone')}
                hasError={!!errors.phoneOrEmail}
                helperText={errors.phoneOrEmail?.message}
              />
              {showRedirectBanner && <RedirectLoginBanner />}
              <Button
                className={cx(styles['primary-owner__button'])}
                type="submit"
                disabled={!isValid || loadingVerifyStatus}
                onClick={() => {
                  trigger();
                }}
                icon={loadingVerifyStatus ? SpinnerIcon : undefined}
                iconClassName={cx(styles['primary-owner__button--loading'])}
                iconPosition={IconPosition.LEFT}
              >
                {t('general.continue')}
              </Button>
            </form>
            <Button
              theme={ButtonThemes.TEXT_LINK}
              className={cx(styles['primary-owner__forgot'])}
              onClick={() => {
                setForgotCodeModalOpen(true);
              }}
            >
              {t('sign-up.primary-owner.forgot-account-code')}
            </Button>
            <p className={cx(styles['primary-owner__login'])}>
              {`${t('sign-up.primary-owner.existing-account')} `}
              <Link as={RouterLink} altText={t('login.login')} to={`/${PageRoutes.LOGIN}`}>
                {t('login.login')}
              </Link>
            </p>
          </div>
        </div>
      </ImageWithContent>
      <Modal
        isVisible={forgotCodeModalOpen}
        title={t('sign-up.primary-owner.forgot-account-code')}
        classNames={{ modalTitle: cx(styles['primary-owner__modal-title']) }}
        hide={() => {
          setForgotCodeModalOpen(false);
        }}
        primaryCta={{
          label: t('general.close'),
          action: () => {
            setForgotCodeModalOpen(false);
          },
        }}
      >
        <p className={cx(styles['forgot-code__description'])}>
          {t('sign-up.primary-owner.forgot-account-modal.description')}
        </p>

        <ol className={cx(styles['forgot-code__list'])}>
          <li className={cx(styles['forgot-code__description'])}>
            {t('sign-up.primary-owner.forgot-account-modal.line-1')}
          </li>
          <li className={cx(styles['forgot-code__description'])}>
            {t('sign-up.primary-owner.forgot-account-modal.line-2')}
          </li>
          <li className={cx(styles['forgot-code__description'])}>
            <Trans
              i18nKey="sign-up.primary-owner.forgot-account-modal.line-3"
              components={[
                <Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />,
                <Link href={LinkUrls.MAIL_REBATE_FULFILLMENT} />,
              ]}
            />
          </li>
        </ol>
      </Modal>
    </div>
  );
};

PrimaryOwner.displayName = 'PrimaryOwner';

export default PrimaryOwner;
