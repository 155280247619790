import {
  CheckmarkIcon,
  CrossIcon,
  HidePasswordIcon,
  LockOutlineIcon,
  ShowPasswordIcon,
  SpinnerIcon,
} from '../../../../assets/icons';
import {
  confirmPasswordMatch,
  isValidPassword,
  Validations,
} from '../../../../lib/utils/form-validations';
import { CreateAccount, CreatePasswordErrorState } from '../../../../lib/types/onboarding';
import {
  ExistingGigyaAccountInput,
  RegisterUserInput,
  VerificationMethod,
} from '../../../../__generated__/graphql';
import { FC, ReactNode, useEffect, useState } from 'react';
import { IconPosition, ValidationStateColors } from '../../../../lib/constants/components';
import { PasswordState, PasswordValidationErrorState } from '../../../../lib/constants/forms';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';

import Button from '../../../../components/_shared/Button';
import Checkbox from '../../../../components/_shared/Checkbox';
import cx from 'classnames';
import { EXISTING_GIGYA_ACCOUNT } from '../../../../lib/graphql/Gigya.gql';
import { GraphQLErrorCodes } from '../../../../lib/constants/errorCodes';
import Link from '../../../../components/_shared/Link';
import { LinkUrls } from '../../../../lib/constants/react-router';
import { newEstDate } from '../../../../lib/utils/dates';
import RedirectLoginBanner from '../../../../components/RedirectLoginBanner/RedirectLoginBanner';
import { Link as RouterLink } from 'react-router-dom';
import { SIGNUP_USER } from '../../../../lib/graphql/UserInformation.gql';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../lib/constants/stepper';
import StepWrapper from '../../components/StepWrapper';
import styles from './CompleteAccount.module.scss';
import TextField from '../../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { usePageTitle } from '../../../../lib/hooks/usePageTitle';
import useSignUpContext from '../../../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import ValidationCriteria from '../../../../components/_shared/ValidationCriteria';

const ACCEPTED = 'accepted';

const CompleteAccount: FC<StepComponentProps> = ({ next }) => {
  usePageTitle('sign-up');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [, dispatch] = useSnackbarContext();
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(true);
  const [showPasswordText, setShowPasswordText] = useState(false);
  const [showConfirmPasswordText, setShowConfirmPasswordText] = useState(false);
  const [signupUser, { loading: loadingSignUp }] = useMutation(SIGNUP_USER);
  const [checkEmail, { loading: loadingCheckEmail }] = useLazyQuery(EXISTING_GIGYA_ACCOUNT);
  const [
    { email, emailExists, farmId, isAdmin, phone, verificationId, verificationMethod },
    setSignUpInformation,
  ] = useSignUpContext();
  const [passwordError, setPasswordError] = useState<CreatePasswordErrorState>({
    [PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]: true,
    [PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]: true,
    [PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]: true,
    [PasswordValidationErrorState.CHAR_LENGTH]: true,
  });

  useEffect(() => {
    if (
      verificationMethod === VerificationMethod.Sms ||
      verificationMethod === VerificationMethod.Voice ||
      emailExists
    ) {
      setShowPasswordFields(false);
    }
  }, []);

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
  } = useForm<CreateAccount>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      bayerValueConsent: '',
      marketingEmailConsent: '',
    },
  });
  useFormErrors(trigger, dirtyFields);

  const currentPassword = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);

  useEffect(() => {
    if (currentPassword && confirmPassword) {
      trigger('confirmPassword');
    }
  }, [currentPassword]);

  const handleShowPassword = (e: any) => {
    if (e.target) {
      setShowPasswordText(!showPasswordText);
    }
  };

  const handleConfirmShowPassword = (e: any) => {
    if (e.target) {
      setShowConfirmPasswordText(!showConfirmPasswordText);
    }
  };

  const onSubmit = (formValues: CreateAccount) => {
    if (farmId) {
      const input: RegisterUserInput = {
        phone,
        email: formValues?.email,
        password: formValues?.password,
        lang: language,
        bayerValueConsent: isAdmin && formValues.bayerValueConsent === ACCEPTED ? true : null,
        bayerValueConsentDate:
          isAdmin && formValues.bayerValueConsent === ACCEPTED ? newEstDate() : null,
        marketingEmailConsent: formValues.marketingEmailConsent === ACCEPTED,
        accountCode: farmId,
        verificationId: phone && verificationId ? verificationId : null,
      };

      signupUser({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          const uid = data?.registerUser.registeredUser.accountInfo.gigyaId as string;
          setSignUpInformation((prev) => ({ ...prev, uid }));
          next?.();
        })
        .catch((e) => {
          const label =
            e?.graphQLErrors?.[0]?.extensions?.code === GraphQLErrorCodes.NOT_FOUND
              ? t('sign-up.complete-account.not-found-error')
              : t('errors.generic');

          dispatch({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label,
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const onCheckEmail = (formValues: CreateAccount) => {
    const input: ExistingGigyaAccountInput = {
      email: formValues.email,
    };
    checkEmail({ variables: { input } })
      .then(({ data }) => {
        setSignUpInformation((prev) => ({
          ...prev,
          emailExists: data?.existingGigyaAccount.emailExists,
        }));
        if (data && !data.existingGigyaAccount.emailExists) {
          setShowPasswordFields(true);
          setEmailDisabled(true);
        }
      })
      .catch(() => {
        const label = t('errors.generic');
        dispatch({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label,
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  const renderDescription = (): ReactNode => (
    <span className={cx(styles['complete-account__description'])}>
      {t('sign-up.complete-account.description')}
    </span>
  );

  const renderMarketingEmailCheckbox = (): ReactNode => (
    <Checkbox
      {...register('marketingEmailConsent')}
      className={{
        wrapper: cx(styles['consent-form__checkbox']),
      }}
      value={ACCEPTED}
      label={<Trans i18nKey="onboarding.consent-form.marketing-consent" />}
    />
  );

  return (
    <StepWrapper
      title={t('sign-up.complete-account.heading')}
      description={renderDescription()}
      Icon={LockOutlineIcon}
    >
      <form
        className={cx(styles['complete-account__form'])}
        onSubmit={showPasswordFields ? handleSubmit(onSubmit) : handleSubmit(onCheckEmail)}
      >
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.email,
          })}
          {...register('email', {
            required: t('form.errors.required'),
            ...Validations.email(t('form.labels.email-address'), t),
          })}
          inputType="text"
          label={t('form.labels.email-address')}
          hasError={!!errors.email}
          helperText={errors.email?.message}
          disabled={!!email || emailDisabled}
        />
        {showPasswordFields && (
          <>
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.password,
              })}
              {...register('password', {
                required: t('form.errors.required'),
                validate: {
                  gigyaRegexValidation: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.GIGYA_REGEX_VALIDATION,
                      setPasswordError
                    ),
                  gigyaRepeatedCharacters: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS,
                      setPasswordError
                    ),
                  containsSpecialCharacter: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR,
                      setPasswordError
                    ),
                  charLength: (val) =>
                    isValidPassword(
                      val,
                      PasswordValidationErrorState.CHAR_LENGTH,
                      setPasswordError
                    ),
                },
              })}
              inputType={showPasswordText ? PasswordState.SHOW : PasswordState.HIDE}
              label={t('form.labels.password')}
              icon={showPasswordText ? HidePasswordIcon : ShowPasswordIcon}
              iconAsButton
              iconOnClick={handleShowPassword}
              iconAriaLabel={showPasswordText ? t('icons.hide-password') : t('icons.show-password')}
              hasError={!!errors.password}
              helperText={errors.password?.message}
            />
            {currentPassword && (
              <div className={cx(styles['complete-account__validation-criteria'])}>
                <ValidationCriteria
                  label={t('onboarding.password-criteria-special-char')}
                  icon={
                    passwordError[PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-length')}
                  icon={
                    passwordError[PasswordValidationErrorState.CHAR_LENGTH]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.CHAR_LENGTH]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-gigya-repetition')}
                  icon={
                    passwordError[PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
                <ValidationCriteria
                  label={t('onboarding.password-criteria-gigya-validation')}
                  icon={
                    passwordError[PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]
                      ? CrossIcon
                      : CheckmarkIcon
                  }
                  validationStateColor={
                    passwordError[PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]
                      ? ValidationStateColors.NOT_VALID
                      : ValidationStateColors.VALID
                  }
                />
              </div>
            )}
            <TextField
              className={cx(styles['form-input'], {
                [styles['form-input--error']]: !!errors.confirmPassword,
              })}
              {...register('confirmPassword', {
                validate: (val) =>
                  !confirmPasswordMatch(currentPassword, val)
                    ? t('form.errors.password-match')
                    : true,
              })}
              inputType={showConfirmPasswordText ? PasswordState.SHOW : PasswordState.HIDE}
              label={t('form.labels.confirm-password')}
              hasError={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              icon={showConfirmPasswordText ? HidePasswordIcon : ShowPasswordIcon}
              iconAsButton
              iconOnClick={handleConfirmShowPassword}
              iconAriaLabel={
                showConfirmPasswordText ? t('icons.hide-password') : t('icons.show-password')
              }
            />
            {isAdmin ? (
              <div className={cx(styles['consent-form'], styles['consent-form--lg'])}>
                <span className={cx(styles['consent-form__sub-header'])}>
                  <Trans i18nKey="onboarding.consent-form.heading-rules" components={[<sup />]} />
                </span>
                <ul className={cx(styles['consent-form__list'])}>
                  <li className={cx(styles['consent-form__list--rule'])}>
                    <Trans
                      i18nKey="onboarding.consent-form.rule-1"
                      components={{
                        url: (
                          <Link
                            to={LinkUrls.BAYER_VALUE_RULES_EAST}
                            as={RouterLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        sup: <sup />,
                      }}
                    />
                  </li>
                  <li className={cx(styles['consent-form__list--rule'])}>
                    {t('onboarding.consent-form.rule-2')}
                  </li>
                  <li className={cx(styles['consent-form__list--rule'])}>
                    <Trans
                      i18nKey="onboarding.consent-form.rule-3"
                      components={{
                        url: (
                          <Link
                            to={LinkUrls.BAYER_VALUE_CONDITIONS_EAST}
                            as={RouterLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        sup: <sup />,
                      }}
                    />
                  </li>
                </ul>
                <span className={cx(styles['consent-form__sub-header'])}>
                  <Trans i18nKey="onboarding.consent-form.heading-dates" components={[<sup />]} />
                </span>
                <ul className={cx(styles['consent-form__list'])}>
                  <li className={cx(styles['consent-form__list--rule'])}>
                    {t('onboarding.consent-form.dates-1')}
                  </li>
                </ul>
                <hr className={cx(styles['consent-form__line'])}></hr>
                <Checkbox
                  {...register('bayerValueConsent', {
                    required: t('form.errors.required'),
                  })}
                  className={{
                    wrapper: cx(styles['consent-form__checkbox']),
                  }}
                  value={ACCEPTED}
                  label={
                    <Trans i18nKey="onboarding.consent-form.accept-terms" components={[<sup />]} />
                  }
                />
                {renderMarketingEmailCheckbox()}
              </div>
            ) : (
              <div className={cx(styles['consent-form'], styles['consent-form--md'])}>
                {renderMarketingEmailCheckbox()}
              </div>
            )}
          </>
        )}

        {emailExists && <RedirectLoginBanner />}

        <Button
          className={cx(styles['complete-account__button'])}
          type="submit"
          onClick={() => (showPasswordFields ? trigger() : {})}
          disabled={!isValid || loadingSignUp || loadingCheckEmail}
          icon={loadingSignUp || loadingCheckEmail ? SpinnerIcon : undefined}
          iconClassName={cx(styles['complete-account__button--loading'])}
          iconPosition={IconPosition.LEFT}
        >
          {t('general.continue')}
        </Button>
      </form>
    </StepWrapper>
  );
};

CompleteAccount.displayName = 'CompleteAccount';

export default CompleteAccount;
