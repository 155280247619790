import { AccessLevel, UserRewardsAccessQuery } from '../../__generated__/graphql';

/**
 * Whether or not a user has access to see the Rewards from their farm
 * @param rewardsAccessData
 * @returns
 */
export const hasRewardsAccess = (
  rewardsAccessData: UserRewardsAccessQuery | undefined
): boolean => {
  if (rewardsAccessData) {
    return rewardsAccessData.user.hasAccess === AccessLevel.FullAccess;
  }

  return false;
};
