import { ButtonThemes } from '../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import Link from '../_shared/Link';
import LinkButton from '../_shared/LinkButton/LinkButton';
import { loadGigyaScreenSet } from '../../lib/utils/gigya';
import { PageRoutes } from '../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import styles from './RedirectLoginBanner.module.scss';
import { Trans } from 'react-i18next';

const RedirectLoginBanner: FC = () => (
  <div className={cx(styles['redirect-login-banner'])}>
    <p className={cx(styles['redirect-login-banner__text'])}>
      <Trans
        i18nKey="sign-up.existing-account-banner"
        components={[
          <LinkButton theme={ButtonThemes.TEXT_LINK} onClick={loadGigyaScreenSet} />,
          <Link as={RouterLink} to={`/${PageRoutes.FORGOT_PASSWORD}`} />,
        ]}
      />
    </p>
  </div>
);

RedirectLoginBanner.displayName = 'RedirectLoginBanner';

export default RedirectLoginBanner;
