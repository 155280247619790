import {
  DashboardContextAction,
  DashboardContextActionTypes,
  DashboardContextState,
} from './DashboardContext.types';

const DashboardContextReducer = (
  state: DashboardContextState,
  action: DashboardContextAction
): DashboardContextState => {
  switch (action.type) {
    case DashboardContextActionTypes.UpdateDisplayRetreat:
      return {
        ...state,
        displayRetreat: action.payload,
      };
    case DashboardContextActionTypes.UpdateDisplayProgramPromotions:
      return {
        ...state,
        displayProgramPromotions: action.payload,
      };
    case DashboardContextActionTypes.UpdateDisplayEventPass:
      return {
        ...state,
        displayEventPass: action.payload,
      };
    case DashboardContextActionTypes.UpdateDisplaySummerSizzlerBanner:
      return {
        ...state,
        displaySummerSizzlerBanner: action.payload,
      };
    case DashboardContextActionTypes.UpdateDisplayDelkabBanner:
      return {
        ...state,
        displayDekalbBanner: action.payload,
      };
    case DashboardContextActionTypes.UpdateDisplayCalculator:
      return {
        ...state,
        displayCalculator: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardContextReducer;
