import SignUpContext from './SignUpContext';
import { SignUpContextType } from './SignUpContext.types';
import { useContext } from 'react';

const useSignUpContext = (): SignUpContextType => {
  const context = useContext(SignUpContext);

  if (context === undefined) {
    throw new Error('useSignUpContext must be used within a SignUpContext');
  }

  return context;
};

export default useSignUpContext;
