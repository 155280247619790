import axios, { AxiosResponse } from 'axios';
import { Endpoints } from '../constants/endpoints';
import { SourceImageType } from '../constants/images';
import { v4 as uuid } from 'uuid';

class ImageClient {
  uploadImage(file: File, type: SourceImageType, farmId?: string): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'x-request-id': uuid()
      },
      withCredentials: true,
      params: {
        sourceImageType: type,
        farmId: farmId || null,
      },
    };

    return axios.post<any>(Endpoints.IMAGE, formData, config);
  }

  deleteImage(imageId: string): Promise<AxiosResponse<string>> {
    return axios.delete<string>(`${Endpoints.IMAGE}/${imageId}`, {
      withCredentials: true,
      headers: {
        'x-request-id': uuid()
      }
    });
  }
}

export default new ImageClient();
