import { gql } from '../../__generated__';

export const GET_FARM_INFORMATION_QUERY = gql(`
  query FarmInfo {
    farm {
      farmInfo {
        id
        address {
          address
          address2
          city
          postalCode
          province
          placeId
        }
        farmLogoId
        farmLogoUrl
        name
        isColony
        paymentPreference {
          preferredOption
          mailingAddress {
            address2
            address
            city
            postalCode
            province
            placeId
          }
          eftConsentDate
          directDepositEmail
          chequePayeeName
          paymentExchangeApproved
        }
      }
      partners {
        isParent
        hasAccess
        partner {
          farmInfo {
            id
            paymentPreference {
              preferredOption
              chequePayeeName
              directDepositEmail
              eftConsentDate
              mailingAddress {
                province
                postalCode
                placeId
                city
                address2
                address
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_FARM_INFORMATION_WITHOUT_PAYMENT = gql(`
  query FarmInfoWithoutPayment {
    farm {
      farmInfo {
        id
        address {
          address
          address2
          city
          postalCode
          province
          placeId
        }
        farmLogoId
        farmLogoUrl
        name
      }
    }
  }
`);

export const GET_FARM_PAYMENT_PREFERENCES = gql(`
  query FarmPaymentPreferences {
    farm {
      farmInfo {
        id
        paymentPreference {
          preferredOption
          mailingAddress {
            address2
            address
            city
            postalCode
            province
            placeId
          }
          eftConsentDate
          directDepositEmail
          chequePayeeName
          paymentExchangeApproved
        }
      }
    }
  }
`);

export const UPDATE_FARM_INFORMATION_MUTATION = gql(`
  mutation FarmUpdate($input: FarmUpdateInput!) {
    farmUpdate(input: $input) {
      farmInfo {
        id
      }
    }
  }
`);

export const GET_FARM_ADMIN_QUERY = gql(`
  query FarmAdmin {
    farm {
      adminUser {
        accountInfo {
          id
          email
          crmEmail
          firstName
          lastName
        }
      }
      farmInfo {
        id
        name
      }
    }
  }
`);

export const GET_FARM_MEMBERS_QUERY = gql(`
  query FarmMembers {
    farm {
      farmInfo {
        id
        name
        address {
          address
          city
          postalCode
          province
        }
        farmLogoUrl
      }
      partners {
        partner {
          farmInfo {
            id
          }
          adminUser {
            accountInfo {
              id
              firstName
              email
              crmEmail
              profilePictureUrl
              lastName
            }
          }
        }
        isParent
      }
      users {
        accountInfo {
          id
          firstName
          email
          crmEmail
          lastName
          profilePictureUrl
          isAdmin
          status
        }
      }
    }
    user {
      accountInfo {
        id
        isAdmin
      }
    }
  }
`);

export const GET_USER_AND_FARM_QUERY = gql(`
  query FarmAndUser {
    user {
      accountInfo {
        id
        firstName
        lastName
      }
    }
    farm {
      farmInfo {
        id
        name
      }
    }
  }
`);

export const GET_FARM_CLIMATE_FIELD_VIEW = gql(`
  query FarmClimateFieldView {
    farm {
      farmInfo {
        id
        isEnrolledClimateFieldview
        climateFieldviewRenewalDate
        address {
          province
        }
      }
    }
  }
`);

export const GET_FARM_PROVINCE = gql(`
  query FarmLocation {
    farm {
      farmInfo {
        id
        address {
          province
        }
      }
    }
  }
`);
