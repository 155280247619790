import { LockOutlineIcon, ReceiptIcon } from '../../../../assets/icons';

import { AccessLevel } from '../../../../__generated__/graphql';
import CardLink from '../../../../components/_shared/CardLink';
import cx from 'classnames';
import { EmptyStateTheme } from '../../../../lib/constants/components';
import { FC } from 'react';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { PageRoutes } from '../../../../lib/constants/react-router';
import PurchasesTable from './PurchasesTable';
import { Link as RouterLink } from 'react-router-dom';
import styles from './PurchasesWidget.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState/TableEmptyState';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export interface Props {}

const PurchasesWidget: FC<Props> = () => {
  const { t } = useTranslation();
  const { data: rewardsAccessData } = useQuery(GET_USER_REWARDS_ACCESS_QUERY);

  const renderContent = () => {
    if (rewardsAccessData?.user?.accountInfo?.accessLevel === AccessLevel.NoAccess) {
      return (
        <TableEmptyState
          className={cx(styles['bayer-rewards__no-access'])}
          Icon={LockOutlineIcon}
          title={t('purchases-widget.no-access.heading')}
          description={t('purchases-widget.no-access.description')}
          theme={EmptyStateTheme.SECONDARY}
        />
      );
    }

    return <PurchasesTable />;
  };

  return (
    <div className={cx(styles['purchases-widget'])}>
      <CardLink
        as={RouterLink}
        icon={ReceiptIcon}
        isHeaderLink
        to={`/${PageRoutes.ORDER_PURCHASE_HISTORY}`}
        linkText={t('general.view')}
      >
        {t('purchases-widget.card-title')}
      </CardLink>
      <div className={cx(styles['purchases-widget__table-wrapper'])}>{renderContent()}</div>
    </div>
  );
};

PurchasesWidget.displayName = 'PurchasesWidget';

export default PurchasesWidget;
