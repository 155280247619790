import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import Button from '../../../../components/_shared/Button';
import cx from 'classnames';
import { IconPosition } from '../../../../lib/constants/components';
import SettingsSkeleton from '../../../../components/_shared/SettingsSkeleton';
import { SpinnerIcon } from '../../../../assets/icons';
import styles from './SettingsWrapper.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import { useTranslation } from 'react-i18next';

export interface Props {
  children: ReactNode;
  title: string;
  onClick?(): void;
  buttonType: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  buttonForm?: string;
  isLoadingData?: boolean;
  isUpdating?: boolean;
  hideSaveButton?: boolean;
}

const SettingsWrapper: FC<Props> = ({
  children,
  title,
  onClick,
  buttonType,
  disabled,
  buttonForm,
  isLoadingData = false,
  isUpdating = false,
  hideSaveButton = false,
}) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint();

  const renderSaveButton = () =>
    !hideSaveButton && (
      <Button
        className={cx(styles['settings-wrapper__button'])}
        disabled={disabled || isLoadingData || isUpdating}
        onClick={onClick}
        type={buttonType}
        formID={buttonForm}
        icon={isUpdating ? SpinnerIcon : undefined}
        iconPosition={IconPosition.LEFT}
        iconClassName={cx(styles['settings-wrapper__loading-icon'])}
      >
        {t('general.save')}
      </Button>
    );

  return (
    <div className={cx(styles['settings-wrapper'])}>
      {isLoadingData ? (
        <SettingsSkeleton />
      ) : (
        <>
          {!isMd && (
            <div className={cx(styles['settings-wrapper__title-wrapper'])}>
              <h2 className={cx(styles['settings-wrapper__heading'])}>{title}</h2>
              {renderSaveButton()}
            </div>
          )}
          <div>{children}</div>
          {isMd && buttonForm && (
            <div className={cx(styles['settings-wrapper__footer'])}>{renderSaveButton()}</div>
          )}
        </>
      )}
    </div>
  );
};

SettingsWrapper.displayName = 'SettingsWrapper';

export default SettingsWrapper;
