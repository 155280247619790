import { createContext, FC, useReducer } from 'react';
import { RetailerContextProps, RetailerContextType } from './RetailerContext.types';

import { PaginationProvider } from '../pagination/PaginationContext';
import RetailerContextReducer from './reducer';
import { RETAILERS_PER_PAGE } from '../../constants/retailers';

const initialState = {
  filters: {
    name: '',
    city: '',
    province: undefined,
  },
  offset: 0,
  limit: RETAILERS_PER_PAGE,
};
const RetailerContext = createContext<RetailerContextType>([initialState, () => {}]);

export const RetailerProvider: FC<RetailerContextProps> = ({ children }) => {
  const value = useReducer(RetailerContextReducer, initialState);

  return (
    <RetailerContext.Provider value={value}>
      <PaginationProvider>{children}</PaginationProvider>
    </RetailerContext.Provider>
  );
};

export default RetailerContext;
