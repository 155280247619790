import { LinkUrls, PageRoutes } from '../../../lib/constants/react-router';
import cookies from 'js-cookie';
import { Cookies } from '../../../lib/constants/gigya';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

export interface Props {
  redirectToPage?: PageRoutes;
  children: JSX.Element;
}
const PublicProtectedRoute: FC<Props> = ({ children, redirectToPage }) => {
  const authTokens = cookies.get(Cookies.CS_GROWERS_TOKEN_EXPIRATION);

  if (authTokens) {
    return (
      <Navigate
        to={redirectToPage ? `${LinkUrls.PREPEND_SLASH}${redirectToPage}` : PageRoutes.HOME}
        replace
      />
    );
  }

  return children;
};

PublicProtectedRoute.displayName = 'PublicProtectedRoute';

export default PublicProtectedRoute;
