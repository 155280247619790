import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import {
  GET_FARM_CHILD_USERS,
  REMOVE_USER_FROM_FARM,
} from '../../../../lib/graphql/UserPermissions.gql';
import cx from 'classnames';
import { getUserAccessLevels } from '../../../../lib/utils/onboarding';
import { IconPosition } from '../../../../lib/constants/components';
import InviteUserModal from '../../../../components/InviteUserModal';
import { ListType } from '../../../../lib/constants/users';
import Modal from '../../../../components/_shared/Modal';
import { OptionsFooter } from '../../../../lib/constants/react-select';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { SpinnerIcon } from '../../../../assets/icons';
import styles from './UserPermissions.module.scss';
import { useMutation } from '@apollo/client';
import User from './User';
import { UserAccountInfo } from '../../../../__generated__/graphql';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  users: UserAccountInfo[] | null;
  renderDropdown: (
    onSubmit: (data: any, setLoadingUsers: Dispatch<SetStateAction<string[]>>) => void,
    partner: UserAccountInfo,
    isLoading: boolean,
    optionsFooter: undefined | OptionsFooter,
    setLoadingUsers: Dispatch<SetStateAction<string[]>>
  ) => JSX.Element;
  updateUsers: (data: any, setLoadingUsers: Dispatch<SetStateAction<string[]>>) => void;
  listType: ListType;
}

const UserList: FC<Props> = ({ users, renderDropdown, updateUsers, listType }) => {
  const { t } = useTranslation();
  const [removeUserFromFarm, { loading: removingUser }] = useMutation(REMOVE_USER_FROM_FARM, {
    refetchQueries: [{ query: GET_FARM_CHILD_USERS }],
  });
  const [, dispatchSnackbar] = useSnackbarContext();
  const [removeUser, setRemoveUser] = useState<string | undefined>();
  const [recommendedUser, setRecommendedUser] = useState<UserAccountInfo>();
  const [recommendedUserModalVisible, setRecommendedUserModalVisible] = useState<boolean>(false);
  const accessLevels = useRef(getUserAccessLevels(t));
  const [loadingUsers, setLoadingUsers] = useState<string[]>([]);

  const handleRemoveUserFromFarm = (userId: string) => {
    removeUserFromFarm({ variables: { userId } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.removed-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setRemoveUser(undefined);
      });
  };

  const renderDeletionModal = (
    <div className={cx(styles['user-permissions__text-wrapper'])}>
      <p className={cx(styles['user-permissions__sub-heading'])}>
        {t('account-settings.user-permissions.remove-farm-access-subtitle')}
      </p>
      <p className={cx(styles['user-permissions__subtitle'])}>
        {t('account-settings.user-permissions.remove-farm-access-content')}
      </p>
    </div>
  );

  const renderUsers = () =>
    users?.map((user) => (
      <User
        key={user.id}
        user={user}
        renderDropdown={renderDropdown}
        updateUsers={updateUsers}
        listType={listType}
        loadingUsers={loadingUsers}
        setLoadingUsers={setLoadingUsers}
        setRecommendedUser={setRecommendedUser}
        setRecommendedUserModalVisible={setRecommendedUserModalVisible}
        setRemoveUser={setRemoveUser}
      />
    ));

  return (
    <div className={cx(styles['child-users'])}>
      {renderUsers()}
      {removeUser && (
        <Modal
          title={t('onboarding.partner-permissions.remove-farm-access-header')}
          isVisible={removeUser !== undefined}
          hide={() => {
            setRemoveUser(undefined);
          }}
          primaryCta={{
            label: t('onboarding.partner-permissions.remove-access'),
            action: () => {
              handleRemoveUserFromFarm(removeUser);
            },
            buttonType: 'button',
            disabled: removingUser,
            buttonIcon: removingUser ? SpinnerIcon : undefined,
            buttonIconPosition: IconPosition.LEFT,
            buttonIconClassName: cx(styles['user-permissions__spinner']),
          }}
          secondaryCta={{
            label: t('general.cancel'),
            disabled: removingUser,
            action: () => {
              setRemoveUser(undefined);
            },
          }}
        >
          {renderDeletionModal}
        </Modal>
      )}
      <InviteUserModal
        recommendedUser={recommendedUser}
        isVisible={recommendedUserModalVisible}
        hide={() => {
          setRecommendedUserModalVisible(false);
        }}
        accessLevels={accessLevels.current}
      />
    </div>
  );
};

UserList.displayName = 'UserList';

export default UserList;
