import { FarmPartner, FarmPartnerUpdateInput } from '../../../../__generated__/graphql';
import { FC, useState } from 'react';
import {
  GET_PARTNERS_PERMISSIONS,
  UPDATE_PARTNER_PERMISSION,
} from '../../../../lib/graphql/PartnerPermissions.gql';
import Avatar from '../../../../components/_shared/Avatar';
import cx from 'classnames';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './UserPermissions.module.scss';
import { useMutation } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  partners: FarmPartner[];
  renderDropdown: (
    onSubmit: (data: any) => void,
    partner: FarmPartner,
    isLoading: boolean,
    optionsFooter?: undefined
  ) => JSX.Element;
}

const Partners: FC<Props> = ({ partners, renderDropdown }) => {
  const { t } = useTranslation();
  const [, dispatchSnackBar] = useSnackbarContext();
  const [updatePartnerPermission] = useMutation(UPDATE_PARTNER_PERMISSION, {
    refetchQueries: [{ query: GET_PARTNERS_PERMISSIONS }],
  });
  const [loadingPartners, setLoadingPartners] = useState<string[]>([]);

  const onSubmitFarmPartner = (data: any) => {
    const hasAccess = data.target.value;
    const partnerFarmId = data.id;
    const input: FarmPartnerUpdateInput = {
      hasAccess,
      partnerFarmId,
    };

    setLoadingPartners((prevPartners) => [...prevPartners, partnerFarmId]);
    updatePartnerPermission({ variables: { input } })
      .then(() => {
        dispatchSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.updated-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackBar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setLoadingPartners((prevPartners) => prevPartners.filter((id) => id !== partnerFarmId));
      });
  };

  const renderPartners = () =>
    partners?.map((partner) => {
      const { adminUser, farmInfo } = partner.partner;
      const { name, farmLogoUrl, address, id } = farmInfo;

      return (
        <div className={cx(styles['user'])} key={id}>
          <Avatar firstName="" lastName="" profilePictureUrl={farmLogoUrl} />
          <div className={cx(styles['user__text-wrapper'])}>
            <p className={cx(styles['user__farm'])}>{name}</p>
            {adminUser && (
              <p
                className={cx(styles['user__name'], styles['user__name--farm'])}
              >{`${adminUser.accountInfo.firstName} ${adminUser.accountInfo.lastName}`}</p>
            )} 
            <p className={cx(styles['user__address'])}>{address?.address}</p>
            <p className={cx(styles['user__address'])}>{t('general.canada')}</p>
          </div>
          {!partner.isParent && (
            <div>{renderDropdown(onSubmitFarmPartner, partner, loadingPartners.includes(id))}</div>
          )}
        </div>
      );
    });

  return <div className={cx(styles['partner'])}>{renderPartners()}</div>;
};

Partners.displayName = 'Partners';

export default Partners;
