import cx from 'classnames';
import { FC } from 'react';
import { GET_USER_AND_FARM_QUERY } from '../../../../lib/graphql/FarmInformation.gql';
import styles from './WelcomeMessage.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export interface Props {}

const WelcomeMessage: FC<Props> = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery(GET_USER_AND_FARM_QUERY);

  return (
    <div className={cx(styles['welcome-message'])}>
      <p className={cx(styles['welcome-message__title-overline'])}>
        {t('welcome-message-widget.account-code', {
          accountCode: userData?.farm?.farmInfo?.id,
        })}
      </p>
      <h1 className={cx(styles['welcome-message__subtitle'])}>
        {t('welcome-message-widget.message', {
          firstName: userData?.user?.accountInfo?.firstName,
        })}
      </h1>
    </div>
  );
};

WelcomeMessage.displayName = 'WelcomeMessage';

export default WelcomeMessage;
