import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import { ChevronLeftIcon, ChevronRightIcon, SpinnerIcon } from '../../../assets/icons';
import Button from '../../../components/_shared/Button';
import { ButtonHTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './StepsWrapper.module.scss';

export interface CTA {
  text: string;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  buttonFormId?: string;
  isSaving?: boolean;
}

export interface Props {
  primaryCta?: CTA;
  secondaryCta?: CTA;
}

const StepsWrapperFooter = ({ primaryCta, secondaryCta }: Props) => (
  <div className={cx(styles['steps-wrapper-footer'])}>
    {!!secondaryCta && (
      <Button
        type={secondaryCta?.buttonType || 'button'}
        formID={secondaryCta?.buttonFormId}
        className={cx(styles['steps-wrapper-footer__button'])}
        theme={ButtonThemes.SECONDARY}
        icon={ChevronLeftIcon}
        iconPosition={IconPosition.LEFT}
        disabled={secondaryCta.disabled}
        onClick={secondaryCta.onClick}
      >
        {secondaryCta.text}
      </Button>
    )}
    {!!primaryCta && (
      <Button
        type={primaryCta.buttonType || 'button'}
        formID={primaryCta?.buttonFormId}
        className={cx(styles['steps-wrapper-footer__button'])}
        icon={primaryCta?.isSaving ? SpinnerIcon : ChevronRightIcon}
        disabled={primaryCta.disabled}
        onClick={primaryCta.onClick}
        iconClassName={cx({ [styles['steps-wrapper-footer__loading-icon']]: primaryCta?.isSaving })}
      >
        {primaryCta.text}
      </Button>
    )}
  </div>
);

export default StepsWrapperFooter;
