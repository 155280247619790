import { MAX_BYTE_IMAGE_SIZE } from '../constants/images';

/**
 * Checks whether or not a image is valid depending on its size.
 * @param file
 * @returns
 */
export const checkImageSize = (file: File): boolean => {
  if (file.size < MAX_BYTE_IMAGE_SIZE) {
    return true;
  }
  return false;
};
