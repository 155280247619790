import cx from 'classnames';
import { FC } from 'react';
import { LinearChartLegendPayload } from '../../LinearChart.types';
import styles from './LinearChartLegends.module.scss';

export interface Props {
  payload?: LinearChartLegendPayload[];
}

const LinearChartLegends: FC<Props> = ({ payload }) => (
  <div className={cx(styles['chart-legends'])}>
    {payload &&
      payload.map((legend) => (
        <div className={cx(styles['chart-legends__legend-wrapper'])} key={legend.value}>
          <svg className={cx(styles['chart-legends__legend-icon'])}>
            <rect
              className={cx(styles['chart-legends__legend-icon'])}
              style={{ fill: legend.color }}
            />
          </svg>
          <p className={cx(styles['chart-legends__legend-text'])}> {legend.value}</p>
        </div>
      ))}
  </div>
);

LinearChartLegends.displayName = 'LinearChartLegends';

export default LinearChartLegends;
