import { CheckmarkIcon, ClockIcon, DollarIcon } from '../../../assets/icons';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonThemes } from '../../../lib/constants/components';
import calculatorImg from '../../../assets/images/bayer-value/calculator.png';
import cx from 'classnames';
import { FC } from 'react';
import { GET_FARM_PROVINCE } from '../../../lib/graphql/FarmInformation.gql';
import { getRegionSpecificLink } from '../../../lib/utils/utils';
import LinkButton from '../../_shared/LinkButton';
import { LinkUrls } from '../../../lib/constants/react-router';
import styles from './CalculatorBanner.module.scss';
import { useQuery } from '@apollo/client';

export interface Props {}

const CalculatorBanner: FC<Props> = () => {
  const { t } = useTranslation();
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE);

  return (
    <div className={cx(styles['calculator-banner'])}>
      <div className={cx(styles['calculator-banner__image-wrapper'])}>
        <img
          className={cx(styles['calculator-banner__image'])}
          src={calculatorImg}
          alt={t('bayer-value.calculator.image-alt')}
        />
      </div>
      <div className={cx(styles['calculator-banner__text-wrapper'])}>
        <h3 className={cx(styles['calculator-banner__heading'])}>
          <Trans i18nKey="bayer-value.calculator.heading" components={[<sup />]} />
        </h3>
        <p className={cx(styles['calculator-banner__description'])}>
          <Trans i18nKey="bayer-value.calculator.description" components={[<sup />]} />
        </p>
        <ul className={cx(styles['calculator-banner__list'])}>
          <li className={cx(styles['calculator-banner__list-item'])}>
            <span className={cx(styles['calculator-banner__icon-wrapper'])}>
              <CheckmarkIcon className={cx(styles['calculator-banner__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-banner__icon-text'])}>
              {t('calculator-widget.item-dollar')}
            </p>
          </li>
          <li className={cx(styles['calculator-banner__list-item'])}>
            <span className={cx(styles['calculator-banner__icon-wrapper'])}>
              <ClockIcon className={cx(styles['calculator-banner__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-banner__icon-text'])}>
              {t('calculator-widget.item-airplane')}
            </p>
          </li>
          <li className={cx(styles['calculator-banner__list-item'])}>
            <span className={cx(styles['calculator-banner__icon-wrapper'])}>
              <DollarIcon className={cx(styles['calculator-banner__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-banner__icon-text'])}>
              {t('calculator-widget.item-shopping-bag')}
            </p>
          </li>
        </ul>
        <LinkButton
          href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
            east: LinkUrls.BAYER_VALUE_CALCULATOR_EAST,
            west: LinkUrls.BAYER_VALUE_CALCULATOR_WEST,
          })}
          target="_blank"
          className={cx(styles['calculator-banner__button'])}
          theme={ButtonThemes.PRIMARY}
          altText={t('bayer-value.calculator.cta-alt')}
          rel="noopener noreferrer"
        >
          {t('bayer-value.calculator.cta')}
        </LinkButton>
      </div>
    </div>
  );
};

CalculatorBanner.displayName = 'CalculatorBanner';

export default CalculatorBanner;
