import { gql } from '../../../../../__generated__';

export const DEACTIVATE_ACCOUNT = gql(`
    mutation DeactivateUser($input: DeactivateUserInput!){
        deactivateUser(input: $input) {
            deactivatedUser {
                accountInfo {
                    id
                    status
                }
            }
        }
    }
`);
