import { BayerIcon, FileDownloadIcon } from '../../../../assets/icons';
import { DisplayTheme, IconPosition } from '../../../../lib/constants/components';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { DashboardContextActionTypes } from '../../../../lib/contexts/dashboard/DashboardContext.types';
import dayjs from 'dayjs';
import download from 'downloadjs';
import eventImage from '../../../../assets/images/banners/event-pass.png';
import { EventPassTag } from '../../../../lib/utils/analytics';
import { FlagKeys } from '../../../../lib/constants/launch-darkly';
import { GET_EVENT_PASS_INFORMATION } from '../../../../lib/graphql/EventPass.gql';
import { Language } from '../../../../lib/constants/i18n';
import Modal from '../../../../components/_shared/Modal';
import PromotionBanner from '../../../../components/PromotionBanner';
import { Province } from '../../../../__generated__/graphql';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './EventPassWidget.module.scss';
import { toPng } from 'html-to-image';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import { useFlag } from '../../../../lib/hooks';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const EventPassWidget: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const passRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { data, loading } = useQuery(GET_EVENT_PASS_INFORMATION);
  const { flag, setContext } = useFlag({ flagKey: FlagKeys.EVENT_PASS, defaultValue: false });
  const [, dispatchDashboard] = useDashboardContext();
  const [, dispatchSnackbar] = useSnackbarContext();

  const currentDate = dayjs();
  const endDate = dayjs('09/13/2024');

  useEffect(() => {
    if (!loading && data) {
      const userContext = {
        kind: 'user',
        anonymous: false,
        key: data?.farm.farmInfo.id,
      };

      setContext(userContext);
    }
  }, [data, loading]);

  const shouldRender = useMemo(
    () =>
      (flag || data?.farm.farmInfo.address.province === Province.On) &&
      language === Language.En &&
      currentDate.isBefore(endDate),
    [flag, data, language, currentDate, endDate]
  );

  useEffect(() => {
    dispatchDashboard({
      type: DashboardContextActionTypes.UpdateDisplayEventPass,
      payload: shouldRender,
    });
  }, [shouldRender]);

  const handleDownloadPass = () => {
    if (passRef.current === null) {
      return;
    }

    toPng(passRef.current)
      .then((dataUrl) => {
        download(dataUrl, 'BayerValueOutdoorShow.png');
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('general.download-success'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch()
      .finally(() => {
        setModalVisible(false);
      });
  };

  return (
    <div className={cx(styles['event-pass-widget'])} id={EventPassTag.BANNER}>
      {shouldRender && (
        <>
          <PromotionBanner
            image={eventImage}
            title={t('event-pass.information.title')}
            description={t('event-pass.information.description')}
            badge={t('event-pass.information.badge')}
            theme={DisplayTheme.WIDGET}
            cta={{
              id: EventPassTag.SHOW_BUTTON,
              label: t('event-pass.cta'),
              action: () => {
                setModalVisible(true);
              },
            }}
          />
          <Modal
            isVisible={modalVisible}
            hide={() => setModalVisible(false)}
            title={t('event-pass.labels.header')}
            classNames={{
              header: cx(styles['event-pass-widget__modal-header']),
              footer: cx(styles['event-pass-widget__modal-footer']),
              modalContent: cx(styles['event-pass-widget__modal-content']),
              modalWrapper: cx(styles['event-pass-widget__modal-wrapper']),
            }}
            primaryCta={{
              id: EventPassTag.DOWNLOAD_BUTTON,
              label: t('general.download'),
              buttonIcon: FileDownloadIcon,
              buttonIconPosition: IconPosition.LEFT,
              action: () => {
                handleDownloadPass();
              },
            }}
            secondaryCta={{
              label: t('general.cancel'),
              action: () => {
                setModalVisible(false);
              },
            }}
          >
            <div className={cx(styles['event-pass-widget__content-wrapper'])} ref={passRef}>
              <img
                className={cx(styles['event-pass-widget__image'])}
                src={eventImage}
                aria-hidden
                alt=""
              />
              <div className={cx(styles['event-pass-widget__sections-wrapper'])}>
                <div className={cx(styles['event-pass-widget__event-header'])}>
                  <BayerIcon
                    className={cx(styles['event-pass-widget__logo'])}
                    aria-label={t('general.bayer-logo')}
                  />
                  <h3 className={cx(styles['event-pass-widget__value--lg'])}>
                    {t('event-pass.information.event-name')}
                  </h3>
                </div>
                <div className={cx(styles['event-pass-widget__event-information'])}>
                  <div className={cx(styles['event-pass-widget__information-wrapper'])}>
                    <p className={cx(styles['event-pass-widget__label'])}>
                      {t('event-pass.labels.grower-name')}
                    </p>
                    <p
                      className={cx(styles['event-pass-widget__value'])}
                    >{`${data?.user.accountInfo.firstName} ${data?.user.accountInfo.lastName}`}</p>
                  </div>
                  <div className={cx(styles['event-pass-widget__information-wrapper'])}>
                    <p className={cx(styles['event-pass-widget__label'])}>
                      {t('event-pass.labels.farm-name')}
                    </p>
                    <p className={cx(styles['event-pass-widget__value'])}>
                      {data?.farm.farmInfo.name}
                    </p>
                  </div>
                  <div className={cx(styles['event-pass-widget__information-wrapper'])}>
                    <p className={cx(styles['event-pass-widget__label'])}>
                      {t('event-pass.labels.location')}
                    </p>
                    <p className={cx(styles['event-pass-widget__value'])}>
                      {t('event-pass.information.location')}
                    </p>
                  </div>
                  <div className={cx(styles['event-pass-widget__information-wrapper'])}>
                    <p className={cx(styles['event-pass-widget__label'])}>
                      {t('event-pass.labels.dates')}
                    </p>
                    <p className={cx(styles['event-pass-widget__value'])}>
                      {t('event-pass.information.dates')}
                    </p>
                  </div>
                </div>
                <div className={cx(styles['event-pass-widget__account-wrapper'])}>
                  <div className={cx(styles['event-pass-widget__information-wrapper--account'])}>
                    <p className={cx(styles['event-pass-widget__label'])}>
                      {t('event-pass.labels.account-code')}
                    </p>
                    <p className={cx(styles['event-pass-widget__value--lg'])}>
                      {data?.farm.farmInfo.id}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

EventPassWidget.displayName = 'EventPassWidget';

export default EventPassWidget;
