import { FC, useEffect, useState } from 'react';
import { IconPosition, LinkThemes } from '../../../../lib/constants/components';
import { ChevronRightIcon } from '../../../../assets/icons';
import cx from 'classnames';
import { GET_FARM_MEMBERS_QUERY } from '../../../../lib/graphql/FarmInformation.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { hasRewardsAccess } from '../../../../lib/utils/user';
import Link from '../../../../components/_shared/Link';
import { maskPostalCode } from '../../../../lib/utils/form-validations';
import { PageRoutes } from '../../../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from './Skeleton/Skeleton';
import styles from './FarmMembersWidget.module.scss';
import { useQuery } from '@apollo/client';
import UserCard from '../../../../components/_shared/UserCard';
import { UserCardData } from '../../../../lib/models/User.model';
import { UserStatus } from '../../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

export interface Props {}

const FarmMembersWidget: FC<Props> = () => {
  const { t } = useTranslation();
  const [farmMembers, setFarmMembers] = useState<UserCardData[]>([]);
  const { data: rewardsAccessData, loading: loadingRewardsAccess } = useQuery(
    GET_USER_REWARDS_ACCESS_QUERY
  );
  const { data: farmMembersData, loading: loadingFarmMembersData } =
    useQuery(GET_FARM_MEMBERS_QUERY);

  const getUserTitle = (isAdmin: boolean, status: UserStatus) => {
    if (isAdmin) {
      return t('user-card.role.farm-owner');
    } else if (status === UserStatus.Active) {
      return t('user-card.role.user');
    }
    return t('user-card.role.invited');
  };

  useEffect(() => {
    if (rewardsAccessData && farmMembersData) {
      const newFarmMembers: UserCardData[] = [];

      if (farmMembersData.user.accountInfo.isAdmin || hasRewardsAccess(rewardsAccessData)) {
        farmMembersData.farm.partners
          .filter((partner) => partner.partner.farmInfo.id !== farmMembersData.farm.farmInfo.id)
          .forEach((partner) => {
            if (partner.partner.adminUser) {
              newFarmMembers.push({
                id: partner.partner.adminUser.accountInfo.id,
                title: t('user-card.role.partner'),
                firstName: partner.partner.adminUser.accountInfo.firstName,
                lastName: partner.partner.adminUser.accountInfo.lastName,
                fullName: t('user-card.full-name', {
                  firstName: partner.partner.adminUser.accountInfo.firstName,
                  lastName: partner.partner.adminUser.accountInfo.lastName,
                }),
                description:
                  partner.partner.adminUser.accountInfo.email ||
                  partner.partner.adminUser.accountInfo.crmEmail ||
                  '',
                photoUrl: partner.partner.adminUser.accountInfo.profilePictureUrl || '',
              });
            }
          });
      } else {
        newFarmMembers.push({
          id: farmMembersData.farm.farmInfo.id,
          title: t('user-card.role.farm'),
          firstName: farmMembersData.farm.farmInfo.name,
          fullName: farmMembersData.farm.farmInfo.name,
          description: t('format.full-address', {
            address: farmMembersData.farm.farmInfo.address?.address,
            city: farmMembersData.farm.farmInfo.address?.city,
            province: farmMembersData.farm.farmInfo.address?.province,
            postalCode: maskPostalCode(farmMembersData.farm.farmInfo.address?.postalCode || ''),
          }),
          photoUrl: farmMembersData.farm.farmInfo.farmLogoUrl || '',
        });
      }

      farmMembersData.farm.users
        .filter((user) => user.accountInfo.id !== farmMembersData.user.accountInfo.id)
        .filter(
          (user) =>
            user.accountInfo.status !== UserStatus.CrmInitialized &&
            user.accountInfo.status !== UserStatus.CrmDeactivated &&
            user.accountInfo.status !== UserStatus.Deactivated &&
            user.accountInfo.status !== UserStatus.Removed
        )
        .sort((userA, userB) => {
          if (userA.accountInfo.isAdmin) {
            return -1;
          } else if (userB.accountInfo.isAdmin) {
            return 1;
          } else if (userA.accountInfo.status === UserStatus.Active) {
            return -1;
          } else if (userB.accountInfo.status !== UserStatus.Active) {
            return 1;
          } else if (userA.accountInfo.firstName < userB.accountInfo.firstName) {
            return -1;
          } else if (userA.accountInfo.firstName > userB.accountInfo.firstName) {
            return 1;
          }
          return 0;
        })
        .forEach((user) => {
          newFarmMembers.push({
            id: user.accountInfo.id,
            title: getUserTitle(user.accountInfo.isAdmin, user.accountInfo.status),
            firstName: user.accountInfo.firstName,
            lastName: user.accountInfo.lastName,
            fullName: t('user-card.full-name', {
              firstName: user.accountInfo.firstName,
              lastName: user.accountInfo.lastName,
            }),
            description: user.accountInfo.email || user.accountInfo.crmEmail || '',
            photoUrl: user.accountInfo.profilePictureUrl || '',
          });
        });

      setFarmMembers(newFarmMembers);
    }
  }, [farmMembersData, rewardsAccessData, t]);

  const renderFarmUserItems = () => {
    if (farmMembers.length === 0) {
      return (
        <li className={cx(styles['farm-members-widget__list-item'])}>
          <p
            className={cx(
              styles['farm-members-widget__text-wrapper'],
              styles['farm-members-widget__text']
            )}
          >
            {t('farm-members-widget.no-farm-members')}
          </p>
        </li>
      );
    }

    return farmMembers.map((member) => (
      <li key={member.id} className={cx(styles['farm-members-widget__list-item'])}>
        <UserCard
          firstName={member.firstName}
          lastName={member.lastName}
          fullName={member.fullName}
          photoUrl={member.photoUrl}
          description={member.description}
          title={member.title}
        />
      </li>
    ));
  };

  return (
    <div className={cx(styles['farm-members-widget'])}>
      <div className={cx(styles['farm-members-widget__text-wrapper'])}>
        <p className={cx(styles['farm-members-widget__title'])}>
          {rewardsAccessData
            ? t('farm-members-widget.admin-title')
            : t('farm-members-widget.child-title')}
        </p>
      </div>
      <ul className={cx(styles['farm-members-widget__list'])}>
        {!loadingFarmMembersData && !loadingRewardsAccess ? renderFarmUserItems() : <Skeleton />}
      </ul>
      {rewardsAccessData?.user?.accountInfo?.isAdmin && (
        <div className={cx(styles['farm-members-widget__button-wrapper'])}>
          <Link
            as={RouterLink}
            to={`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_USER_PERMISSION}`}
            className="link"
            theme={LinkThemes.TEXT_LINK}
            icon={ChevronRightIcon}
            iconPosition={IconPosition.RIGHT}
          >
            {farmMembers.length === 0 && !loadingFarmMembersData && !loadingRewardsAccess
              ? t('farm-members-widget.cta-no-farm-members')
              : t('farm-members-widget.cta')}
          </Link>
        </div>
      )}
    </div>
  );
};

FarmMembersWidget.displayName = 'FarmMembersWidget';

export default FarmMembersWidget;
