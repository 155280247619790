import Avatar from '../Avatar';
import cx from 'classnames';
import { FC } from 'react';
import styles from './UserCard.module.scss';

export interface Props {
  title?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  description?: string;
  phone?: string;
  photoUrl?: string;
  hidePhoto?: boolean;
  retailerGroupName?: string;
}

const UserCard: FC<Props> = ({
  retailerGroupName,
  title = '',
  firstName = '',
  lastName = '',
  fullName = '',
  description = '',
  photoUrl = '',
  hidePhoto = false,
  phone,
}) => (
  <div className={cx(styles['user-card'])}>
    {!hidePhoto && (
      <div className={cx(styles['user-card__avatar-wrapper'])}>
        <Avatar firstName={firstName} lastName={lastName} profilePictureUrl={photoUrl} />
      </div>
    )}
    <div className={cx(styles['user-card__text-wrapper'])}>
      <p className={cx(styles['user-card__role'])}>{title}</p>
      {retailerGroupName && (
        <p className={cx(styles['user-card__role'], styles['user-card__role--retailer'])}>
          {retailerGroupName}
        </p>
      )}
      <p className={cx(styles['user-card__name'])}>{fullName}</p>
      <p className={cx(styles['user-card__subtitle'])}>{description.toLowerCase()}</p>
      {phone && <p className={cx(styles['user-card__subtitle'])}>{phone}</p>}
    </div>
  </div>
);

UserCard.displayName = 'UserCard';

export default UserCard;
