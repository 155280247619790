import { components } from 'react-select';
import cx from 'classnames';
import React from 'react';
import styles from '../Dropdown.module.scss';

// Note: ValueContainerProps doesn't contain the isFocused prop, in case the Placeholder needs it
const ValueContainer = ({ children, ...props }: any) => {
  const { isMulti, selectProps } = props;
  return (
    <>
      <components.Placeholder {...props}>{props.selectProps.placeholder}</components.Placeholder>
      <components.ValueContainer {...props}>
        {isMulti ? (
          <label
            className={cx(styles['multi-container'], {
              [styles['multi-container--disabled']]: selectProps.isDisabled,
            })}
            htmlFor={selectProps.inputId}
          >
            {React.Children.map(children, (child: any) =>
              child && child.type !== components.Placeholder ? child : null
            )}
          </label>
        ) : (
          React.Children.map(children, (child: any) =>
            child && child.type !== components.Placeholder ? child : null
          )
        )}
      </components.ValueContainer>
    </>
  );
};

export default ValueContainer;
