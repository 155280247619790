import {
  ADD_PREFERRED_RETAILER,
  GET_PREFERRED_RETAILERS,
  GET_RETAILERS,
} from '../../../lib/graphql/PreferredRetailers.gql';
import {
  AddPreferredRetailersInput,
  GetRetailersInput,
  GetRetailersQueryVariables,
  Province,
  Retailer,
} from '../../../__generated__/graphql';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import AddRetailerCard from '../AddRetailerCard';
import cx from 'classnames';
import { IconPosition } from '../../../lib/constants/components';
import Modal from '../../_shared/Modal';
import ModalHeader from './ModalHeader';
import Pagination from '../../_shared/Pagination/Pagination';
import { RetailerContextActionTypes } from '../../../lib/contexts/retailer/RetailerContext.types';
import { RETAILERS_PER_PAGE } from '../../../lib/constants/retailers';
import Skeleton from '../AddRetailerCard/Skeleton/Skeleton';
import { SnackbarContextActionTypes } from '../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../SnackbarContainer/Snackbar/Snackbar.types';
import { SpinnerIcon } from '../../../assets/icons';
import styles from './AddPreferredRetailerModal.module.scss';
import { useForm } from 'react-hook-form';
import usePaginationContext from '../../../lib/contexts/pagination/usePaginationContext';
import useRetailerContext from '../../../lib/contexts/retailer/useRetailerContext';
import useSnackbarContext from '../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  open: boolean;
  hide(): void;
}

interface FormValues {
  preferredRetailer: string[];
}

const AddPreferredRetailerModal: FC<Props> = ({ open, hide }) => {
  const { t } = useTranslation();
  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([]);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [retailerResults, setRetailerResults] = useState<Retailer[]>([]);
  const [totalRetailers, setTotalRetailers] = useState<number>(0);
  const formId = useRef<string>(uuid());
  const legendId = useRef<string>(uuid());
  const [retailerFilter, dispatchRetailerFilter] = useRetailerContext();
  const [pageNumber, dispatchPageNumber] = usePaginationContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [variables, setVariables] = useState<GetRetailersQueryVariables>();

  const { loading: fetchingRetailers, data: retailerData } = useQuery(GET_RETAILERS, {
    variables,
    skip: !open,
    fetchPolicy: 'network-only',
  });
  const [addPreferredRetailer, { loading: addingRetailers }] = useMutation(ADD_PREFERRED_RETAILER, {
    refetchQueries: [
      { query: GET_PREFERRED_RETAILERS },
      {
        query: GET_RETAILERS,
        variables,
      },
    ],
  });

  const { register, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      preferredRetailer: [],
    },
  });

  const onClose = () => {
    reset();
    hide();
    setSelectedRetailers([]);
    setShowSelected(false);
    dispatchPageNumber(0);
    dispatchRetailerFilter({
      type: RetailerContextActionTypes.UpdateFilters,
      payload: {},
    });
  };

  const onSubmitFilters = (data: GetRetailersInput) => {
    setShowSelected(false);
    dispatchPageNumber(0);
    dispatchRetailerFilter({
      type: RetailerContextActionTypes.UpdateFilters,
      payload: data,
    });
  };

  const addPreferredRetailers = () => {
    const input: AddPreferredRetailersInput = {
      retailerIds: selectedRetailers.map((retailer) => retailer.id),
    };
    addPreferredRetailer({ variables: { input } })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('alert.added-successfully'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const showSelectedRetailers = () => {
    if (!showSelected) {
      setShowSelected(true);
      setRetailerResults(selectedRetailers);
      dispatchRetailerFilter({
        type: RetailerContextActionTypes.UpdateFilters,
        payload: {},
      });
    } else {
      setShowSelected(false);
      dispatchPageNumber(0);
    }
  };

  const handleSelectRetailer = (retailer: Retailer) => {
    if (!selectedRetailers.filter((selected) => selected.id === retailer.id).length) {
      setSelectedRetailers(selectedRetailers.concat(retailer));
    } else {
      setSelectedRetailers(selectedRetailers.filter((r) => r.id !== retailer.id));
    }
  };

  const renderView = (): ReactNode => {
    if (fetchingRetailers) {
      return (
        <div className={cx(styles['aprm-skeleton'])}>
          <Skeleton className={cx(styles['aprm-skeleton__item'])} />
          <Skeleton className={cx(styles['aprm-skeleton__item'])} />
        </div>
      );
    } else if (retailerResults.length === 0) {
      return null;
    }

    return (
      <form
        className={cx(styles['aprm__form'])}
        id={formId.current}
        onSubmit={handleSubmit(addPreferredRetailers)}
      >
        <ul className={cx(styles['aprm__list'])}>
          {retailerResults.map((retailer) => {
            const elementId = `retailer-option-${retailer.id}`;

            return (
              <li className={cx(styles['aprm__list-item'])} key={retailer.id}>
                <label className={cx(styles['aprm__label'])} htmlFor={elementId}>
                  <input
                    {...register('preferredRetailer')}
                    className={cx(styles['aprm__checkbox'], 'sr-only')}
                    type="checkbox"
                    id={elementId}
                    value={retailer.id}
                    onChange={() => {
                      handleSelectRetailer(retailer);
                    }}
                  />
                  <AddRetailerCard
                    className={cx(styles['aprm__retailer-card'])}
                    retailer={retailer}
                    isSelected={selectedRetailers.filter((r) => r.id === retailer.id).length > 0}
                  />
                </label>
              </li>
            );
          })}
        </ul>
      </form>
    );
  };

  useEffect(() => {
    if (!showSelected && !fetchingRetailers) {
      setRetailerResults(retailerData?.getRetailers.retailers || []);
      setTotalRetailers(retailerData?.getRetailers.totalCount || 0);
    }
  }, [fetchingRetailers, retailerData, showSelected]);

  useEffect(() => {
    if (selectedRetailers.length === 0 && showSelected) {
      setShowSelected(false);
      dispatchPageNumber(0);
    }
  }, [dispatchPageNumber, selectedRetailers, showSelected]);

  useEffect(() => {
    setVariables({
      filterBy: {
        name: retailerFilter.filters.name || '',
        city: retailerFilter.filters.city || '',
        province: retailerFilter.filters.province
          ? (retailerFilter.filters.province as Province)
          : undefined,
        includePreferred: false,
        limit: RETAILERS_PER_PAGE,
        offset: RETAILERS_PER_PAGE * pageNumber,
      },
    });
  }, [
    open,
    pageNumber,
    retailerFilter.filters.city,
    retailerFilter.filters.name,
    retailerFilter.filters.province,
  ]);

  return (
    <Modal
      classNames={{ modalWrapper: cx(styles['aprm']) }}
      isVisible={open}
      hide={onClose}
      title={t('onboarding.preferred-retailers.find-retailer')}
      headerContent={
        <ModalHeader
          onSubmit={onSubmitFilters}
          fetching={fetchingRetailers}
          resultsNumber={totalRetailers}
          legendId={legendId.current}
          selectedRetailers={selectedRetailers}
          setShowSelected={showSelectedRetailers}
          showSelected={showSelected}
        />
      }
      primaryCta={{
        label: t('retailers.add-retailer', { count: selectedRetailers.length }),
        action: () => {},
        buttonType: 'submit',
        buttonFormId: formId.current,
        disabled: selectedRetailers.length === 0 || addingRetailers,
        buttonIcon: addingRetailers ? SpinnerIcon : undefined,
        buttonIconPosition: IconPosition.LEFT,
        buttonIconClassName: cx(styles['aprm__loading-icon']),
      }}
      secondaryCta={{
        label: t('general.cancel'),
        disabled: addingRetailers,
        action: onClose,
      }}
      customFooter={
        <div className={cx(styles['aprm__pagination'])}>
          {!showSelected && (
            <Pagination
              itemsPerPage={RETAILERS_PER_PAGE}
              totalNumberItems={totalRetailers}
              onPageChange={(pageNum) => {
                dispatchPageNumber(pageNum);
              }}
            />
          )}
        </div>
      }
    >
      {renderView()}
    </Modal>
  );
};

AddPreferredRetailerModal.displayName = 'AddPreferredRetailerModal';

export default AddPreferredRetailerModal;
