import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { CheckmarkIcon, ChevronRightIcon, ClockIcon, DollarIcon } from '../../../../assets/icons';
import { Trans, useTranslation } from 'react-i18next';
import calculatorImg from '../../../../assets/images/bayer-value/calculator.png';
import cx from 'classnames';
import { FC } from 'react';
import { GET_FARM_PROVINCE } from '../../../../lib/graphql/FarmInformation.gql';
import { getRegionSpecificLink } from '../../../../lib/utils/utils';
import LinkButton from '../../../../components/_shared/LinkButton';
import { LinkUrls } from '../../../../lib/constants/react-router';
import styles from './CalculatorWidget.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import { useQuery } from '@apollo/client';

export interface Props {}

const CalculatorWidget: FC<Props> = () => {
  const { t } = useTranslation();
  const [{ displayRetreat }] = useDashboardContext();
  const { isXs, isMd } = useBreakpoint();
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE);
  const isBasicWidget = displayRetreat && !isMd;

  const renderImageWrapper = () => (
    <div className={cx(styles['calculator-widget__image-wrapper'])}>
      <img
        className={cx(styles['calculator-widget__image'])}
        src={calculatorImg}
        alt={t('calculator-widget.image-alt')}
      />
    </div>
  );

  return (
    <div
      className={cx(styles['calculator-widget'], {
        [styles['calculator-widget--basic']]: isBasicWidget,
      })}
    >
      {isBasicWidget ? (
        <div
          className={cx(styles['calculator-widget__text-wrapper'], {
            [styles['calculator-widget__text-wrapper--basic']]: isBasicWidget,
          })}
        >
          <div>
            <h2 className={cx(styles['calculator-widget__heading'])}>
              <Trans i18nKey="calculator-widget.heading" components={[<sup />]} />
            </h2>
            <p className={cx(styles['calculator-widget__description'])}>
              {t('calculator-widget.description')}
            </p>
          </div>

          <LinkButton
            href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
              east: LinkUrls.BAYER_VALUE_CALCULATOR_EAST,
              west: LinkUrls.BAYER_VALUE_CALCULATOR_WEST,
            })}
            target="_blank"
            className={cx(styles['calculator-widget__button'])}
            theme={ButtonThemes.TEXT_LINK}
            icon={ChevronRightIcon}
            iconPosition={IconPosition.RIGHT}
          >
            {t('calculator-widget.cta')}
          </LinkButton>
        </div>
      ) : (
        <>
          <div className={cx(styles['calculator-widget__text-wrapper'])}>
            <h2 className={cx(styles['calculator-widget__heading'])}>
              <Trans i18nKey="calculator-widget.heading" components={[<sup />]} />
            </h2>
            <p className={cx(styles['calculator-widget__description'])}>
              {t('calculator-widget.description')}
            </p>
            <ul className={cx(styles['calculator-widget__list'])}>
              <li className={cx(styles['calculator-widget__list-item'])}>
                <span className={cx(styles['calculator-widget__icon-wrapper'])}>
                  <CheckmarkIcon
                    className={cx(styles['calculator-widget__icon'])}
                    aria-hidden="true"
                  />
                </span>
                <p className={cx(styles['calculator-widget__icon-text'])}>
                  {t('calculator-widget.item-dollar')}
                </p>
              </li>
              <li className={cx(styles['calculator-widget__list-item'])}>
                <span className={cx(styles['calculator-widget__icon-wrapper'])}>
                  <ClockIcon className={cx(styles['calculator-widget__icon'])} aria-hidden="true" />
                </span>
                <p className={cx(styles['calculator-widget__icon-text'])}>
                  {t('calculator-widget.item-airplane')}
                </p>
              </li>
              <li className={cx(styles['calculator-widget__list-item'])}>
                <span className={cx(styles['calculator-widget__icon-wrapper'])}>
                  <DollarIcon
                    className={cx(styles['calculator-widget__icon'])}
                    aria-hidden="true"
                  />
                </span>
                <p className={cx(styles['calculator-widget__icon-text'])}>
                  {t('calculator-widget.item-shopping-bag')}
                </p>
              </li>
            </ul>
            {isXs && renderImageWrapper()}
            <LinkButton
              href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
                east: LinkUrls.BAYER_VALUE_CALCULATOR_EAST,
                west: LinkUrls.BAYER_VALUE_CALCULATOR_WEST,
              })}
              target="_blank"
              rel="noopener noreferrer"
              className={cx(styles['calculator-widget__button'])}
              theme={ButtonThemes.SECONDARY}
            >
              {t('calculator-widget.cta')}
            </LinkButton>
          </div>
          {!isXs && renderImageWrapper()}
        </>
      )}
    </div>
  );
};

CalculatorWidget.displayName = 'CalculatorWidget';

export default CalculatorWidget;
