import { Address, FarmInfoQuery } from '../../../../../__generated__/graphql';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { maskPostalCode, Validations } from '../../../../../lib/utils/form-validations';
import AutocompleteWrapper from '../../../../../components/AutocompleteWrapper';
import { BellIcon } from '../../../../../assets/icons';
import { CharacterLimit } from '../../../../../lib/constants/character-limit';
import { checkImageSize } from '../../../../../lib/utils/images';
import cx from 'classnames';
import Dropdown from '../../../../../components/_shared/Dropdown';
import { DropdownOption } from '../../../../../lib/constants/react-select';
import { FarmInfoExtended } from '../../../../../lib/models/Farm.model';
import { getProvinceDropdownOptions } from '../../../../../lib/utils/onboarding';
import { handleEnterKey } from '../../../../../lib/constants/google-maps';
import InfoBanner from '../../../../../components/_shared/InfoBanner';
import { SnackbarContextActionTypes } from '../../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from '../MyOperation.module.scss';
import TextField from '../../../../../components/_shared/TextField';
import UploadPhoto from '../../../../Onboarding/Setup/_shared/UploadPhoto/UploadPhoto';
import useSnackbarContext from '../../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

interface Props {
  control: Control<FarmInfoExtended>;
  register: UseFormRegister<FarmInfoExtended>;
  errors: FieldErrors<FarmInfoExtended>;
  farmData: FarmInfoQuery | undefined;
  farmLogoUrl?: string;
  farmPhotoId?: string;
  photo: File | null;
  placesApiLoaded: boolean;
  setPhoto: Dispatch<SetStateAction<File | null>>;
  setDeletePhoto: Dispatch<SetStateAction<string | null>>;
  setValue: UseFormSetValue<FarmInfoExtended>;
  watch: UseFormWatch<FarmInfoExtended>;
}

const FarmInformation: FC<Props> = ({
  register,
  control,
  errors,
  farmData,
  photo,
  placesApiLoaded,
  setPhoto,
  setDeletePhoto,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [provinces, setProvinces] = useState<DropdownOption[]>(getProvinceDropdownOptions(t));
  const [photoUrl, setPhotoUrl] = useState<string>(farmData?.farm.farmInfo.farmLogoUrl || '');
  const inputPhoto = useRef<HTMLInputElement | null>(null);
  const placeId = watch('address.placeId');

  const shouldUpdateAddress = useMemo(
    () =>
      farmData?.farm?.farmInfo?.address &&
      !farmData?.farm?.farmInfo?.address?.placeId &&
      placesApiLoaded &&
      !placeId,
    [farmData?.farm.farmInfo.address, placeId, placesApiLoaded]
  );

  useEffect(() => {
    setProvinces(getProvinceDropdownOptions(t));
  }, [t]);

  useEffect(() => {
    if (farmData?.farm.farmInfo.farmLogoUrl) {
      setPhotoUrl(farmData?.farm.farmInfo.farmLogoUrl);
    }
  }, [farmData?.farm.farmInfo.farmLogoUrl]);

  useEffect(() => {
    if (photo) {
      const imageUrl = URL.createObjectURL(photo);
      setPhotoUrl(imageUrl);
    }
  }, [photo]);

  const onPhotoChange = (e: any) => {
    setDeletePhoto(null);
    const newPhoto = e.target.files[0];
    if (newPhoto) {
      if (checkImageSize(newPhoto)) {
        setPhoto(newPhoto);
      } else {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.size-limit'),
            state: SnackbarStates.WARNING,
          },
        });
      }
    }
  };

  const handlePhotoUpload = () => {
    if (inputPhoto.current) {
      inputPhoto.current?.click();
    }
  };

  const handlePhotoDelete = () => {
    setPhoto(null);
    setPhotoUrl('');
    if (farmData?.farm.farmInfo.farmLogoId) {
      setDeletePhoto(farmData?.farm.farmInfo.farmLogoId);
    }
    if (inputPhoto.current) {
      inputPhoto.current.value = '';
    }
  };

  const handleAutocompleteFields = (address: Address) => {
    setValue('address', address, { shouldDirty: true, shouldValidate: true });
  };

  const renderAddressField = (): ReactNode => (
    <TextField
      className={cx(styles['form-input'], {
        [styles['form-input--error']]: !!errors.address?.address,
      })}
      label={t('form.labels.address')}
      {...register('address.address', {
        required: t('form.errors.required'),
        ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
      })}
      hasError={!!errors.address?.address}
      helperText={errors.address?.address?.message}
      onKeyDown={handleEnterKey}
    />
  );

  return (
    <div>
      <div className={cx(styles['my-operation__text-wrapper'])}>
        <h2 className={cx(styles['my-operation__heading'])}>
          {t('account-settings.my-operation.farm-information.heading')}
        </h2>
        <p className={cx(styles['my-operation__subtitle'])}>
          {t('account-settings.my-operation.farm-information.subtitle')}
        </p>
        <UploadPhoto
          photoUrl={photoUrl || ''}
          handlePhotoUpload={handlePhotoUpload}
          handlePhotoDelete={handlePhotoDelete}
          onPhotoChange={onPhotoChange}
          inputPhoto={inputPhoto}
        />
      </div>
      <hr className={cx(styles['form-divider'])} />
      <div className={cx(styles['form-row'])}>
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.name,
          })}
          label={t('form.labels.farm-name')}
          {...register('name', {
            required: t('form.errors.required'),
            ...Validations.characterLimit(t, CharacterLimit.NAME),
          })}
          hasError={!!errors.name}
          helperText={errors?.name?.message}
        />
      </div>
      {shouldUpdateAddress && (
        <InfoBanner
          title={t('onboarding.address-banner')}
          className={cx(styles['my-operation__address-banner'])}
          closeButton={false}
          icon={BellIcon}
        />
      )}
      <div className={cx(styles['form-row'])}>
        {placesApiLoaded ? (
          <AutocompleteWrapper handleAutocompleteFields={handleAutocompleteFields}>
            {renderAddressField()}
          </AutocompleteWrapper>
        ) : (
          renderAddressField()
        )}
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.address?.address2,
          })}
          label={t('form.labels.address2')}
          {...register('address.address2', {
            ...Validations.characterLimit(t, CharacterLimit.ADDRESS),
          })}
          hasError={!!errors.address?.address2}
          helperText={errors.address?.address2?.message}
        />
      </div>
      <div className={cx(styles['form-row'])}>
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.address?.city,
          })}
          label={t('form.labels.city')}
          {...register('address.city', {
            required: t('form.errors.required'),
            ...Validations.characterLimit(t, CharacterLimit.CITY),
          })}
          hasError={!!errors.address?.city}
          helperText={errors.address?.city?.message}
        />
        <div className={cx(styles['form-nested-row'])}>
          <Controller
            control={control}
            name="address.province"
            rules={{ required: t('form.errors.required') }}
            render={({ field: { onChange, value, name } }) => (
              <Dropdown
                className={cx(styles['form-input'], {
                  [styles['form-input--dropdown']]: !!errors.address?.province,
                })}
                name={name}
                value={provinces.find((p) => p.value === value)}
                onChange={onChange}
                label={t('form.labels.province')}
                options={provinces}
                hasError={!!errors.address?.province}
                helperText={errors.address?.province?.message}
              />
            )}
          />
          <TextField
            className={cx(styles['form-input'], {
              [styles['form-input--error']]: !!errors.address?.postalCode,
            })}
            label={t('form.labels.postal-code')}
            {...register('address.postalCode', {
              required: t('form.errors.required'),
              ...Validations.postalCode(t('form.labels.postal-code'), t),
              onChange: ({ currentTarget }) => {
                const { value } = currentTarget;
                // eslint-disable-next-line no-param-reassign
                currentTarget.value = maskPostalCode(value);
              },
            })}
            hasError={!!errors.address?.postalCode}
            helperText={errors.address?.postalCode?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default FarmInformation;
