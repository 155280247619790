import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { FilterListIcon, ReceiptIcon } from '../../../assets/icons';
import {
  filterPurchaseMetricsData,
  formatPurchaseMetricsData,
} from '../../../lib/utils/purchase-metrics';
import { PurchaseChartAxisKey, PurchaseChartType } from '../../../lib/constants/purchases';

import Accordion from '../../../components/_shared/Accordion';
import cx from 'classnames';
import FilterModal from './PurchaseFiltersModal';
import { GET_PURCHASES_METRICS } from '../../../lib/graphql/PurchaseMetrics.gql';
import LinearChart from '../../../components/LinearChart';
import LinkButton from '../../../components/_shared/LinkButton';
import { OrderChartContextActionTypes } from '../../../lib/contexts/order-chart/OrderCharContext.types';
import Spinner from '../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../lib/constants/components';
import styles from './DataVisualization.module.scss';
import TableEmptyState from '../../../components/TableEmptyState';
import useOrderChartContext from '../../../lib/contexts/order-chart/useOrderChartContext';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const DataVisualization: FC = () => {
  const { t } = useTranslation();
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [chartInitialized, setChartInitialized] = useState<boolean>(false);
  const [{ type, filters }, dispatch] = useOrderChartContext();
  const { data: chartData, loading: loadingChart } = useQuery(GET_PURCHASES_METRICS);

  const renderEmptyState = useMemo(
    () => !loadingChart && chartData?.purchaseMetrics.breakdown.length === 0,
    [chartData, loadingChart]
  );

  const formattedData = useMemo(
    () => (chartData ? formatPurchaseMetricsData(chartData, type) : { dataset: [], lines: [] }),
    [chartData, type]
  );

  const filteredData = useMemo(
    () => filterPurchaseMetricsData(formattedData, filters),
    [formattedData, filters]
  );

  useEffect(() => {
    if (formattedData.dataset.length > 0 && !chartInitialized) {
      dispatch({
        type: OrderChartContextActionTypes.UpdateFilters,
        payload: formattedData.lines.map((line) => line.key),
      });
      dispatch({
        type: OrderChartContextActionTypes.UpdateOptions,
        payload: formattedData.lines,
      });
      setChartInitialized(true);
    }
  }, [formattedData]);

  const onCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const renderChartBody = (): ReactNode => (
    <div className={cx({ [styles['line-chart__graph-empty']]: renderEmptyState })}>
      {renderEmptyState ? (
        <TableEmptyState
          Icon={ReceiptIcon}
          title={t('data-visualization.empty-state.heading')}
          description={t('data-visualization.empty-state.description')}
        />
      ) : (
        <LinearChart
          data={filteredData}
          yAxisLabel={
            type === PurchaseChartType.ACRE
              ? t('data-visualization.type.legend.acre')
              : t('data-visualization.type.legend.msrp')
          }
          xAxisKey={PurchaseChartAxisKey}
        />
      )}
    </div>
  );

  return (
    <div className={cx(styles['data-visualization'])}>
      <div className={cx(styles['data-visualization__container'])}>
        <Accordion
          classNames={{
            innerContainer: cx(styles['data-visualization__inner-accordion']),
          }}
          title={t('data-visualization.accordion')}
        >
          {loadingChart ? (
            <Spinner className={cx(styles['data-visualization__spinner'])} size={SpinnerSizes.MD} />
          ) : (
            <div className={cx(styles['line-chart'])}>
              <div className={cx(styles['line-chart__header'])}>
                <div className={cx(styles['line-chart__text-container'])}>
                  <h1 className={cx(styles['line-chart__title'])}>
                    {t('data-visualization.graph.title')}
                  </h1>
                  <h2 className={cx(styles['line-chart__subtitle'])}>
                    {t('data-visualization.graph.subtitle')}
                  </h2>
                </div>
                {!renderEmptyState && (
                  <LinkButton
                    className={cx(styles['line-chart__filters'])}
                    icon={FilterListIcon}
                    as="button"
                    onClick={() => {
                      setShowFilterModal(true);
                    }}
                  >
                    {t('data-visualization.shared.filters')}
                  </LinkButton>
                )}
              </div>
              {renderChartBody()}
              <FilterModal isOpen={showFilterModal} hide={onCloseFilterModal} />
            </div>
          )}
        </Accordion>
      </div>
    </div>
  );
};

DataVisualization.displayName = 'DataVisualization';

export default DataVisualization;
