/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, Fragment } from 'react';
import Pill, { PillColors, PillSizes } from '../../../../../../components/_shared/Pill';
import cx from 'classnames';
import { ProgramRewardMetrics } from '../../../../../../__generated__/graphql';
import styles from './BayerValueEastTable.module.scss';
import { useBreakpoint } from '../../../../../../lib/hooks';
import { useTranslation } from 'react-i18next';

export interface Props {
  programRewardMetrics: ProgramRewardMetrics[] | undefined;
}

const BayerValueEastTable: FC<Props> = ({ programRewardMetrics }) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();

  return (
    <table className={cx(styles['table'])}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={cx(styles['table__header'])}></th>
          <th className={cx(styles['table__header'])} scope="col">
            {t('bayer-value.segments.east.table-header-acres')}
          </th>
          <th className={cx(styles['table__header'])} scope="col">
            {t('bayer-value.segments.east.table-header-status')}
          </th>
          <th className={cx(styles['table__header'])} scope="col">
            {t('bayer-value.segments.east.table-header-products')}
          </th>
          <th className={cx(styles['table__header'])} scope="col">
            {t('bayer-value.segments.east.table-header-purchase')}
          </th>
        </tr>
      </thead>
      <tbody className={cx(styles['table__tbody'])}>
        {programRewardMetrics?.map((segment) => (
          <Fragment key={segment.id}>
            <tr className={cx(styles['table__data-row'])}>
              <th className={cx(styles['table__header'], styles['table__header--row'])} scope="row">
                {t('bayer-value.segments.east.table-header-trait')}
              </th>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.segments.east.table-header-acres')}
              >
                {segment.acres}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.segments.east.table-header-status')}
              >
                <Pill
                  text={
                    segment.qualified
                      ? t('bayer-value.segments.table-row-qualified')
                      : t('bayer-value.segments.table-row-not-qualified')
                  }
                  color={segment.qualified ? PillColors.GREEN : PillColors.RED}
                  size={PillSizes.SM}
                />
              </td>
              <td
                className={cx(styles['table__data-cell'], styles['table__data-cell--double-line'])}
                data-label={t('bayer-value.segments.east.table-header-products')}
              >
                {t('bayer-value.segments.table-row-empty')}
              </td>
              <td
                className={cx(styles['table__data-cell'], styles['table__data-cell--double-line'])}
                data-label={t('bayer-value.segments.east.table-header-purchase')}
              >
                {t('bayer-value.segments.table-row-empty')}
              </td>
            </tr>
            <tr className={cx(styles['table__data-row'])}>
              <th className={cx(styles['table__header'], styles['table__header--row'])} scope="row">
                {t('bayer-value.segments.east.table-header-cp-acres')}
              </th>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.segments.east.table-header-acres')}
              >
                {segment.qualifyingAcres}
              </td>
              <td
                className={cx(styles['table__data-cell'])}
                data-label={t('bayer-value.segments.east.table-header-status')}
              >
                {t('bayer-value.segments.table-row-empty')}
              </td>
              <td
                className={cx(styles['table__data-cell'], styles['table__data-cell--double-line'])}
                data-label={t('bayer-value.segments.east.table-header-products')}
              >
                {segment.eligibleProducts}
              </td>
              <td
                className={cx(styles['table__data-cell'], styles['table__data-cell--double-line'])}
                data-label={t('bayer-value.segments.east.table-header-purchase')}
              >
                {t('format.price', { value: segment.eligiblePurchases })}
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

BayerValueEastTable.displayName = 'BayerValueEastTable';

export default BayerValueEastTable;
