import { createContext, FC, useReducer } from 'react';
import {
  DashboardContextProps,
  DashboardContextState,
  DashboardContextType,
} from './DashboardContext.types';
import DashboardContextReducer from './reducer';

const initialState: DashboardContextState = {
  displayCalculator: false,
  displayDekalbBanner: false,
  displayEventPass: false,
  displayRetreat: false,
  displayProgramPromotions: false,
  displaySummerSizzlerBanner: false,
};
const DashboardContext = createContext<DashboardContextType>([initialState, () => {}]);

export const DashboardProvider: FC<DashboardContextProps> = ({ children }) => {
  const value = useReducer(DashboardContextReducer, initialState);

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export default DashboardContext;
