import { Navigate, useSearchParams } from 'react-router-dom';
import { FC } from 'react';

export interface Props {
  redirectTo: string;
}

const RedirectRoute: FC<Props> = ({ redirectTo }) => {
  const [searchParams] = useSearchParams();
  const queryParamsArray = Array.from(searchParams.entries());

  const buildParams = (array: [string, string][]) =>
    array.map(([key, value]) => `${key}=${value}`).join('&');

  const redirectUrl =
    queryParamsArray.length > 0
      ? `/${redirectTo}?${buildParams(queryParamsArray)}`
      : `/${redirectTo}`;

  return <Navigate to={redirectUrl} />;
};

RedirectRoute.displayName = 'RedirectRoute';

export default RedirectRoute;
