import { FC, forwardRef, HTMLInputTypeAttribute, SVGProps, useRef } from 'react';
import cx from 'classnames';
import { PolymorphicComponent } from '../../../lib/models/GenericComponent.model';
import styles from './TextField.module.scss';
import Tooltip from '../Tooltip';
import { v4 as uuid } from 'uuid';

export interface Props {
  inputType?: HTMLInputTypeAttribute;
  icon?: FC<SVGProps<SVGSVGElement>>;
  iconAsButton?: boolean;
  iconAriaLabel?: string;
  iconClassName?: string;
  iconOnClick?(e?: any): void;
  onChange?(e?: any): void;
  onBlur?(e?: any): void;
  className?: string;
  label: string;
  helperText?: string;
  helperTextClassName?: string;
  disabled?: boolean;
  id?: string;
  name: string;
  hasError?: boolean;
  tooltip?: string;
  hideLabel?: boolean;
}

const renderTooltip = (
  tooltip: string | undefined,
  Icon: FC<SVGProps<SVGSVGElement>>,
  iconClassName: string | undefined
) => (
  <>
    {tooltip && (
      <Tooltip text={tooltip} className={cx(styles['text-field__tooltip'])}>
        <Icon className={cx(iconClassName, styles['text-field__icon'])} aria-hidden="true" />
      </Tooltip>
    )}
    {!!tooltip || (
      <Icon className={cx(iconClassName, styles['text-field__icon'])} aria-hidden="true" />
    )}
  </>
);

const TextField = forwardRef<HTMLInputElement, PolymorphicComponent<'input', Props>>(
  (
    {
      inputType = 'text',
      icon = null,
      iconAsButton = false,
      iconClassName,
      iconAriaLabel,
      iconOnClick,
      onChange,
      onBlur,
      className,
      label,
      helperText,
      helperTextClassName,
      disabled = false,
      id,
      hasError = false,
      name,
      tooltip,
      hideLabel = false,
      ...props
    },
    ref
  ) => {
    const Icon = icon;
    const textboxId = useRef<string>(id || uuid());
    const helperTextId = useRef<string>(uuid());

    return (
      <div className={cx(styles['text-field'], className)}>
        <input
          {...props}
          type={inputType}
          className={cx(styles['text-field__input'], {
            [styles['text-field__input--error']]: hasError,
          })}
          aria-describedby={helperText ? `${helperTextId.current}` : undefined}
          disabled={disabled}
          id={textboxId.current}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder="&nbsp;"
          ref={ref}
        />
        {label && (
          <label
            className={cx(styles['text-field__placeholder'], {
              [styles['text-field__placeholder--error']]: hasError,
              [styles['text-field__placeholder--disabled']]: disabled,
              [styles['text-field__placeholder--tooltip']]: tooltip,
              'sr-only': hideLabel,
            })}
            htmlFor={textboxId.current}
          >
            {label}
          </label>
        )}
        {helperText && (
          <div
            className={cx(styles['text-field__bottom-row'], {
              [styles['text-field__bottom-row--error']]: hasError,
            })}
          >
            <span
              className={cx(helperTextClassName, styles['text-field__helper-text'])}
              id={helperTextId.current}
            >
              {helperText}
            </span>
          </div>
        )}
        {Icon && iconAsButton && (
          <button
            type="button"
            className={cx(styles['text-field__icon-wrapper'], styles['text-field__icon-button'])}
            onClick={iconOnClick}
            aria-label={iconAriaLabel}
          >
            {renderTooltip(tooltip, Icon, iconClassName)}
          </button>
        )}
        {Icon && !iconAsButton && (
          <span className={cx(styles['text-field__icon-wrapper'])}>
            {renderTooltip(tooltip, Icon, iconClassName)}
          </span>
        )}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
