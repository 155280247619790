import {
  GET_USER_LANGUAGE,
  UPDATE_USER_ACCOUNT_INFO,
} from '../../../../lib/graphql/UserInformation.gql';
import { Language, LANGUAGE_KEY } from '../../../../lib/constants/i18n';
import { useEffect, useRef } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import DropdownIndicator from './DropdownIndicator';
import { getReactSelectStyles } from '../../../../lib/utils/react-select';
import { LanguageDropdownOverrides } from '../../../../lib/constants/react-select';
import Select from 'react-select';
import styles from './LanguageDropdown.module.scss';
import { toGQLLanguage } from '../../../../lib/utils/i18n';
import { useMutation } from '@apollo/client';
import { usePreferredLanguage } from '../../../../lib/hooks/usePreferredLanguage';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  className?: string;
  tabIndex?: number;
}

const LanguageDropdown = ({ className, tabIndex }: Props) => {
  const { t, i18n } = useTranslation();
  const preferredLanguage = usePreferredLanguage();
  const [updateLanguage] = useMutation(UPDATE_USER_ACCOUNT_INFO, {
    refetchQueries: [{ query: GET_USER_LANGUAGE }],
  });
  const selectId = useRef<string>(uuid());

  const options = [
    { value: Language.En, label: t('header.languages.en') },
    { value: Language.Fr, label: t('header.languages.fr') },
  ];

  const onUpdateLanguage = (selectedOption: any) => {
    if (preferredLanguage) {
      updateLanguage({ variables: { input: { language: toGQLLanguage(selectedOption) } } });
    }
    localStorage.setItem(LANGUAGE_KEY, selectedOption);
    i18n.changeLanguage(selectedOption);
  };

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <label className={cx('sr-only')} htmlFor={selectId.current}>
        {t('header.select-language')}
      </label>

      <div className={cx(styles['language-dropdown'])}>
        <Select
          aria-label={t('header.select-language')}
          components={{
            DropdownIndicator,
          }}
          className={cx(className)}
          name="language"
          options={options}
          onChange={(selectedOption: any) => onUpdateLanguage(selectedOption.value)}
          styles={getReactSelectStyles(LanguageDropdownOverrides)}
          inputId={selectId.current}
          hideSelectedOptions
          isClearable={false}
          value={options.find((option) => option.value === i18n.language)}
          isSearchable={false}
          tabIndex={tabIndex}
        />
      </div>
    </>
  );
};

LanguageDropdown.displayName = 'LanguageDropdown';

export default LanguageDropdown;
