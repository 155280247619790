import { CheckmarkIcon } from '../../../../../assets/icons';
import cx from 'classnames';
import { FC } from 'react';
import LinkButton from '../../../../../components/_shared/LinkButton/LinkButton';
import { PageRoutes } from '../../../../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import styles from './PreOrderHeader.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  submitted: boolean;
}

const PreOrderHeader: FC<Props> = ({ submitted }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['pre-order-header'])}>
      <div>
        {submitted && (
          <div className={cx(styles['pre-order-header__submitted-icon'])}>
            <CheckmarkIcon fill="white" />
          </div>
        )}
        <h1 className={cx(styles['pre-order-header__title'])}>
          {submitted
            ? t('order-placement.pre-order.title-submitted')
            : t('order-placement.pre-order.title')}
        </h1>
        {submitted && (
          <p className={cx(styles['pre-order-header__sub-title'])}>
            {t('order-placement.pre-order.subtitle-submitted')}
          </p>
        )}
      </div>
      {submitted && (
        <LinkButton
          as={RouterLink}
          to={`/${PageRoutes.ORDER_PURCHASE_HISTORY}#tab-panel-orders`}
          className={cx(styles['pre-order-header__button'])}
        >
          {t('order-placement.pre-order.view-orders')}
        </LinkButton>
      )}
    </div>
  );
};

PreOrderHeader.displayName = 'PreOrderHeader';

export default PreOrderHeader;
