import { FC, useState } from 'react';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { Endpoints } from '../../../../lib/constants/endpoints';
import Link from '../../../../components/_shared/Link';
import LinkButton from '../../../../components/_shared/LinkButton';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import { StepComponentProps } from '../../../../lib/constants/stepper';
import styles from './SignupCompleted.module.scss';
import SuccessCTA from '../../../../components/SuccessCTA';
import { usePageTitle } from '../../../../lib/hooks/usePageTitle';
import useSignUpContext from '../../../../lib/contexts/sign-up/useSignUpContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { v4 as uuid } from 'uuid';

const SignupCompleted: FC<StepComponentProps> = () => {
  usePageTitle('sign-up-complete');
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [{ uid }] = useSignUpContext();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  const resendEmail = () => {
    setSendingEmail(true);
    axios
      .post(
        Endpoints.RESEND_VERIFICATION_EMAIL,
        {},
        {
          withCredentials: true,
          params: {
            uid,
          },
          headers: {
            'x-request-id': uuid(),
          },
        }
      )
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('sign-up.account-completed.resend-email-success'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      })
      .finally(() => {
        setSendingEmail(false);
      });
  };

  return (
    <div className={cx(styles['signup-complete'])}>
      <SuccessCTA
        buttonTheme={ButtonThemes.SECONDARY}
        heading={t('sign-up.account-completed.heading')}
        subHeading={t('sign-up.account-completed.description')}
        buttonLinkText={t('onboarding.button-go-home')}
        buttonRedirectLink={PageRoutes.HOME}
      >
        <div>
          <p className={cx(styles['signup-complete__sub-details'])}>
            <Trans
              i18nKey="sign-up.account-completed.resend-email"
              components={[
                <LinkButton onClick={resendEmail} as="button" disabled={sendingEmail}>
                  {t('sign-up.account-completed.resend-email-button')}
                </LinkButton>,
              ]}
            />
          </p>
          <p className={cx(styles['signup-complete__sub-details'])}>
            <Trans
              i18nKey="sign-up.account-completed.assistance"
              components={[<Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />]}
            />
          </p>
        </div>
      </SuccessCTA>
    </div>
  );
};

SignupCompleted.displayName = 'SignupCompleted';

export default SignupCompleted;
