import { FC, ReactNode } from 'react';
import cx from 'classnames';
import { GET_FARM_PAYMENT_PREFERENCES } from '../../../../../lib/graphql/FarmInformation.gql';
import { maskPostalCode } from '../../../../../lib/utils/form-validations';
import { PaymentPreferenceOptions } from '../../../../../__generated__/graphql';
import Radio from '../../../../../components/_shared/Radio';
import styles from '../MyOperation.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const ChildUserPaymentPreferences: FC = () => {
  const { data: paymentPreferenceData } = useQuery(GET_FARM_PAYMENT_PREFERENCES);
  const { t } = useTranslation();

  const getPaymentPreferenceLabel = (): string => {
    switch (paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.preferredOption) {
      case PaymentPreferenceOptions.Mail:
        return t('form.labels.mailed-cheque-default');
      case PaymentPreferenceOptions.Eft:
      default:
        return t('form.labels.direct-deposit');
    }
  };

  const getPaymentPreferenceDescription = (): ReactNode => {
    switch (paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.preferredOption) {
      case PaymentPreferenceOptions.Mail:
        return (
          <>
            {t('format.full-address', {
              address:
                paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.address,
              city: paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.city,
              province:
                paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress?.province,
              postalCode: maskPostalCode(
                paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.mailingAddress
                  ?.postalCode || ''
              ),
            })}
            <br />
            {t('account-settings.my-operation.payment-preferences.cheque-payee-name', {
              name: paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.chequePayeeName,
            })}
          </>
        );
      case PaymentPreferenceOptions.Eft:
      default:
        return paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.directDepositEmail;
    }
  };

  return (
    <div>
      <div className={cx(styles['my-operation__text-wrapper'])}>
        <h2 className={cx(styles['my-operation__heading'])}>
          {t('account-settings.my-operation.payment-preferences.child-user.heading')}
        </h2>
        <p className={cx(styles['my-operation__subtitle'])}>
          {t('account-settings.my-operation.payment-preferences.child-user.subtitle')}
        </p>
      </div>
      <div className={cx(styles['my-operation__radio-group'])}>
        <Radio
          className={cx(styles['my-operation__radio'])}
          label={getPaymentPreferenceLabel()}
          value={paymentPreferenceData?.farm?.farmInfo?.paymentPreference?.preferredOption}
          name="payment-preference"
          onChange={() => {}}
          disabled
          checked
          description={getPaymentPreferenceDescription()}
        />
      </div>
    </div>
  );
};

export default ChildUserPaymentPreferences;
