import { ProgramBreakdownWithColorIcon } from '../../../lib/models/Program.model';
import { ReactNode } from 'react';

export enum ProgramCardBadgeAlignment {
  TOP = 'top',
  CENTER = 'center',
}

export enum ProgramCardSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum ProgramCardLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface Props {
  badgeAlignment?: ProgramCardBadgeAlignment;
  description?: string | ReactNode;
  layout?: ProgramCardLayout;
  program: ProgramBreakdownWithColorIcon;
  size?: ProgramCardSize;
}
