import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AccountCreation from './pages/AccountCreation';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import App from './App';
import BayerRewardsCalculator from './pages/BayerRewardsCalculator/BayerRewardsCalculator';
import BayerRewardsLanding from './pages/BayerRewardsLanding/BayerRewardsLanding';
import BayerValueProgram from './pages/BayerRewardsPrograms/BayerValueProgram/BayerValueProgram';
import Dashboard from './pages/Dashboard/Dashboard';
import { DashboardProvider } from './lib/contexts/dashboard/DashboardContext';
import EmailVerified from './pages/EmailVerified/EmailVerified';
import FAQ from './pages/FAQ/FAQ';
import { FC } from 'react';
import ForgotPassword from './pages/Login/ForgotPassword/ForgotPassword';
import GettingStarted from './pages/Onboarding/GettingStarted/GettingStarted';
import GigyaError from './pages/Login/Gigya/GigyaError';
import GigyaLogin from './pages/Login/Gigya/GigyaLogin';
import Login from './pages/Login/Login';
import LoginError from './pages/Login/LoginError';
import NotFound from './pages/NotFound/NotFound';
import Onboarding from './pages/Onboarding/Onboarding';
import OnboardingSetup from './pages/Onboarding/Setup/Setup';
import OrderHistory from './pages/OrderHistory/OrderHistory';
import { OrderHistoryProvider } from './lib/contexts/order-history/OrderHistoryContext';
import OtherPrograms from './pages/BayerRewardsPrograms/OtherPrograms/OtherPrograms';
import { PageRoutes } from './lib/constants/react-router';
import PreOrder from './pages/OrderPlacement/PreOrder';
import ProtectedRoute from './components/_shared/ProtectedRoute';
import PublicProtectedRoute from './components/_shared/PublicProtectedRoute';
import RedirectRoute from './components/_shared/RedirectRoute';
import { Region } from './lib/constants/pre-orders';
import ResetPasswordConfirmation from './pages/AccountSettings/subpages/Security/ResetPasswordConfirmation/ResetPasswordConfirmation';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SettingsOperation from './pages/AccountSettings/subpages/MyOperation/MyOperation';
import SettingsProfile from './pages/AccountSettings/subpages/Profile/Profile';
import SettingsRetailer from './pages/AccountSettings/subpages/MyPreferredRetailer/MyPreferredRetailer';
import SettingsSecurity from './pages/AccountSettings/subpages/Security/Security';
import SettingsUser from './pages/AccountSettings/subpages/UserPermissions/UserPermissions';
import { SignUpProvider } from './lib/contexts/sign-up/SignUpContext';

const AppRoutes: FC = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <DashboardProvider>
                <Dashboard />
              </DashboardProvider>
            </ProtectedRoute>
          }
        />
        <Route path={`${PageRoutes.SIGNUP}/:language?`}>
          <Route
            index
            element={
              <PublicProtectedRoute>
                <SignUpProvider>
                  <AccountCreation />
                </SignUpProvider>
              </PublicProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${PageRoutes.INSCRIPTION}`}
          element={<RedirectRoute redirectTo={`${PageRoutes.SIGNUP_ANALYTICS}`} />}
        />
        <Route path={`${PageRoutes.LOGIN}/:language?`}>
          <Route
            index
            element={
              <PublicProtectedRoute>
                <Login />
              </PublicProtectedRoute>
            }
          />
        </Route>
        <Route
          path={PageRoutes.FORGOT_PASSWORD}
          element={
            <PublicProtectedRoute>
              <ForgotPassword />
            </PublicProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.GIGYA_LOGIN}
          element={
            <PublicProtectedRoute>
              <GigyaLogin />
            </PublicProtectedRoute>
          }
        />
        <Route path={PageRoutes.GIGYA_ERROR} element={<GigyaError />} />
        <Route path={PageRoutes.LOGIN_ERROR} element={<LoginError />} />
        <Route
          path={PageRoutes.ONBOARDING}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <Onboarding />
            </ProtectedRoute>
          }
        >
          <Route path={PageRoutes.GETTING_STARTED} element={<GettingStarted />} />
          <Route path={PageRoutes.SETUP} element={<OnboardingSetup />} />
        </Route>
        <Route
          path={PageRoutes.REWARDS}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <BayerRewardsLanding />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.OTHER_PROGRAMS}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN} checkHasAccess>
              <OtherPrograms />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${PageRoutes.BAYER_VALUE_PROGRAM}/:program?`}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN} checkHasAccess>
              <BayerValueProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.CALCULATOR}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN} checkHasAccess>
              <BayerRewardsCalculator />
            </ProtectedRoute>
          }
        />
        <Route
          path={PageRoutes.ORDER_PURCHASE_HISTORY}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <OrderHistoryProvider>
                <OrderHistory />
              </OrderHistoryProvider>
            </ProtectedRoute>
          }
        />
        {process.env.REACT_APP_SHOW_PRE_ORDERS && (
          <Route
            path={PageRoutes.PRE_ORDERS}
            element={
              <ProtectedRoute redirectToPage={PageRoutes.LOGIN} checkRegion={Region.WEST}>
                <PreOrder />
              </ProtectedRoute>
            }
          />
        )}

        <Route
          path={PageRoutes.ACCOUNT_SETTINGS}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <AccountSettings />
            </ProtectedRoute>
          }
        >
          <Route path={PageRoutes.SETTINGS_PROFILE} element={<SettingsProfile />} />
          <Route path={PageRoutes.SETTINGS_MY_OPERATION} element={<SettingsOperation />} />
          <Route path={PageRoutes.SETTINGS_MY_PREFERRED_RETAILER} element={<SettingsRetailer />} />
          <Route
            path={PageRoutes.SETTINGS_USER_PERMISSION}
            element={
              <ProtectedRoute redirectToPage={PageRoutes.LOGIN} checkIsAdmin>
                <SettingsUser />
              </ProtectedRoute>
            }
          />
          <Route path={PageRoutes.SETTINGS_SECURITY} element={<SettingsSecurity />} />
        </Route>
        <Route
          path={PageRoutes.RESET_PASSWORD_CONFIRMATION}
          element={<ResetPasswordConfirmation />}
        />
        <Route
          path={PageRoutes.FAQ}
          element={
            <ProtectedRoute redirectToPage={PageRoutes.LOGIN}>
              <FAQ />
            </ProtectedRoute>
          }
        />
        <Route path={PageRoutes.EMAIL_VERIFIED} element={<EmailVerified />} />
        <Route path={PageRoutes.NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<Navigate to={PageRoutes.NOT_FOUND} />} />
      </Route>
    </Routes>
  </Router>
);

export default AppRoutes;
