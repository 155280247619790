import { ArrowDropDownIcon, SpinnerIcon } from '../../../../assets/icons';
import { components, DropdownIndicatorProps } from 'react-select';
import cx from 'classnames';
import styles from '../Dropdown.module.scss';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps, isDisabled } = props;
  // @ts-ignore
  const { DropdownIndicatorIcon, showSpinner } = selectProps;
  const icon = () => {
    // needs to return something in order to replace the default react-select icon
    if (isDisabled) return <span></span>;
    if (showSpinner) return <SpinnerIcon className={cx(styles['loading'])} />;
    if (DropdownIndicatorIcon) return <DropdownIndicatorIcon aria-hidden="true" />;
    return <ArrowDropDownIcon aria-hidden="true" />;
  };
  return <components.DropdownIndicator {...props}>{icon()}</components.DropdownIndicator>;
};

export default DropdownIndicator;
