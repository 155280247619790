export enum OrderProductTheme {
  OVERVIEW = 'OVERVIEW',
  CARD = 'CARD',
}

export enum ProductSize {
  BAG = 'Bag',
  MINI_BULK = 'MiniBulk',
}

export enum Region {
  EAST = 'east',
  WEST = 'west',
}
