import { gql } from '../../__generated__';

export const GET_PURCHASES_METRICS = gql(`
    query PurchaseMetrics {
        purchaseMetrics {
            breakdown {
                year
                categoryMetrics {
                    category
                    acrage
                    totalDollarAmount
                }
            }
        }
    }`
);
