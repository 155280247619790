export enum Cookies {
  CS_GROWERS_TOKEN_EXPIRATION = 'cs_growers_token_expiration',
}

export enum ErrorCodes {
  UNAUTHORIZED_USER = 'UNAUTHORIZED_USER',
}

export enum Mode {
  AFTER_LOGIN = 'afterLogin',
  LOGIN = 'login',
}

export const CookieConfig = (expirationDateUTC?: string) => {
  const baseConfig = {
    path: '/',
    domain: `${process.env.REACT_APP_CLIENT_DOMAIN}`,
  };

  return expirationDateUTC ? { ...baseConfig, expires: new Date(expirationDateUTC) } : baseConfig;
};

export const REDIRECT_URL_SS_KEY = 'redirectUrl';
