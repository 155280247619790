/**
 * Builds a new URL search based on the paramsObject
 * @param {object} paramsObject
 * @param {boolean} includeNull Whether or not to include null values
 * @returns String with the new URL search
 */
export const buildUrlParams = (paramsObject: any, includeNull = true) => {
  const params = new URLSearchParams();
  Object.keys(paramsObject).forEach((key) => {
    if (includeNull || paramsObject[key] || paramsObject[key] === 0) {
      const value = paramsObject[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(key, item);
        });
      } else {
        params.set(key, paramsObject[key]);
      }
    }
  });
  return `?${params.toString()}`;
};

/**
 * Triggers a browser download on an item.
 * @param content Content of the item to download
 * @param fileName Name of the file to be downloaded
 * @param contentType Type of the file to be downloaded
 */
export const downloadFile = (content: string, fileName: string, contentType: string) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};
