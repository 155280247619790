import { CreatePasswordErrorState } from '../types/onboarding';
import { PasswordValidationErrorState } from '../constants/forms';
import { PaymentPreferenceOptions } from '../../__generated__/graphql';
import { SetStateAction } from 'react';
import { TFunction } from 'i18next';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const POSTAL_CODE_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i; // A1A 1A1 or A1A1A1, disallows leading Z,W and invalid characters D, F, I, O, Q or U
export const PHONE_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}$/; // (XXX) XXX - XXXX

/**
 * Function that validates that the email is valid
 * @param email
 */
export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

/**
 * Function that validates that the phone number is valid
 * @param phone
 */
export const isValidPhone = (phone: string) => PHONE_REGEX.test(phone);

/**
 * Function that validates if number is natural (0 decimal places and excludes 0)
 * @param num
 */
export const isNaturalNumber = (num: number) => {
  const match = num % 1;
  return match === 0 && num > 0;
};

/**
 * Function that validates that the Bayer accountCode is valid
 * @param accountCode - accountCode (comprised of 5 letters that are not case sensitive)
 */
export const isValidAccountCode = (accountCode: string) => {
  const match = /^[a-zA-Z]{5}$/.test(accountCode);
  return match;
};

/**
 * Function that validates that the field contains AT LEAST 1 uppercase and 1 lowercase letter
 * @param field - string
 */
export const containsCaseSensitiveCharacters = (field: string) => {
  const matchUpper = /^(?=.*[A-Z]).*$/.test(field);
  const matchLower = /^(?=.*[a-z]).*$/.test(field);
  return matchUpper && matchLower;
};

/**
 * Function that validates that the field contains at least 1 number
 * @param field - string
 */
export const containsNumber = (field: string) => {
  const match = /^(?=.*\d).*$/.test(field);
  return match;
};

/**
 * Function that validates that the field contains ${num} number of special characters
 * @param field - string
 */
export const containsSpecialCharacter = (field: string) => {
  // const match = /^\w{5}$/.test(field);
  const match = /^(?=.*\W).*$/.test(field);
  return match;
};

/**
 * Function that validates that the field passes Gigya's custom regex expression that excludes the use of certain combinations of letters/words
 * @param field - string
 */
export const gigyaRegexValidation = (field: string) => {
  const match =
    /^(?!.*(1QAY|1qay|2WSX|2wsr|APR|apr|ASDF|asdf|AUG|aug|DEC|dec|DEZ|dez|FEB|feb|JAN|jan|JUL|jul|JUN|jun|MAI|mai|MAR|mar|MAY|may|BAYER|bayer|MONAT|monat|MONTH|month|NOV|nov|OCT|oct|OKT|okt|PASSWOR|passwor|PRIVAT|privat|QWERT|qwert|SEP|sep|START01|start01|yxcv|YXCV)).*$/g.test(
      field
    );

  return match;
};

/**
 * Function that validates that the field passes Gigya's custom regex expression that excludes the use of repeated characters
 * @param field - string
 */
export const gigyaRepeatedCharacters = (field: string) => {
  const match = /^(?!.*(.)\1{2}).*$/g.test(field);

  return match;
};

/**
 * Function that validates that the field contains AT LEAST ${minLength} number of characters
 * @param field - string
 * @param minLength - the minimum number of characters the field must contain
 */
export const charLengthIsGreaterThan = (field: string) => {
  const match = /^(?=.{8,}$)/.test(field);
  return match;
};

/**
 * Function that checks that the 'confirmPassword' matches the original 'password
 * @param password
 * @param confirmPassword
 */
export const confirmPasswordMatch = (password: string, confirmPassword: string) =>
  confirmPassword === password;

/**
 * Applies a phone mask based on the regex matches
 * @param {*} match
 * @param {*} m1
 * @param {*} m2
 * @param {*} m3
 * @returns
 */
export const phoneReplacer = (match: string, m1: string, m2: string, m3: string) => {
  if (m3) {
    return `(${m1}) ${m2}-${m3}`;
  }
  if (m2) {
    return `(${m1}) ${m2}`;
  }
  if (m1) {
    return `(${m1}`;
  }

  return '';
};

/**
 * Applies a phone mask to a string
 * @param {string} value Phone number to mask
 * @returns String with the masked phone
 */
export const maskPhone = (value: string) => {
  const removeCountryCode = value.replace(/^\+\d+\s*/, '');
  let maskedValue = removeCountryCode.replace(/\D+/g, '') || '';

  if (maskedValue && maskedValue.length > 0) {
    maskedValue = maskedValue.replace(/(\d{1,3})?(\d{1,3})?(\d{1,4})?/, phoneReplacer);
  }

  return maskedValue.substr(0, 14);
};

/**
 * Unmasks a give phone number
 * @param value
 */
export const unMaskPhone = (value: string) => value.replace(/^(\+)|\D/g, '$1');

/**
 * Applies a postal code mask to a string
 * @param {string} value Postal code to mask
 * @returns String with the masked postal code
 */
export const maskPostalCode = (value: string) => {
  const matches = value.replace(/\s/g, '').match(/.{1,3}/g);
  let maskedValue = '';

  if (matches && matches.length > 0) {
    maskedValue = matches.join(' ');
  }

  return maskedValue.substr(0, 7);
};

export const unmaskPostalCode = (value: string) => value.replace(/\s/g, '');

export const Validations = {
  phone: (fieldName: string, t: TFunction) => ({
    validate: (value: string | null | undefined) => {
      if (!value || !value.length) {
        return true;
      }
      const maskedValue = maskPhone(value);

      if (isValidPhone(maskedValue)) {
        return true;
      }
      return t('form.errors.invalid-field', { fieldName });
    },
  }),
  email: (fieldName: string, t: TFunction) => ({
    pattern: {
      value: EMAIL_REGEX,
      message: t('form.errors.invalid-field', { fieldName }),
    },
  }),
  emailOrPhone: (fieldName: string, t: TFunction) => ({
    validate: (value: string | null | undefined) => {
      if (value && (isValidEmail(value) || isValidPhone(value))) {
        return true;
      }
      return t('form.errors.invalid-field', { fieldName });
    },
  }),
  postalCode: (fieldName: string, t: TFunction) => ({
    pattern: {
      value: POSTAL_CODE_REGEX,
      message: t('form.errors.invalid-field', { fieldName }),
    },
  }),
  accountCode: (fieldName: string, t: TFunction) => ({
    validate: (value: string | null) => {
      if (value && isValidAccountCode(value)) {
        return true;
      }

      return t('form.errors.invalid-field', { fieldName });
    },
  }),
  emailAccepted: (t: TFunction, currentPreference: PaymentPreferenceOptions) => ({
    validate: (value: boolean | undefined) => {
      if (currentPreference !== PaymentPreferenceOptions.Eft || value) {
        return true;
      }

      return t('form.errors.required');
    },
  }),
  exchangeAccepted: (t: TFunction, currentPreference: PaymentPreferenceOptions) => ({
    validate: (value: boolean | undefined) => {
      if (currentPreference !== PaymentPreferenceOptions.Exchange || value) {
        return true;
      }

      return t('form.errors.required');
    },
  }),
  characterLimit: (t: TFunction, limit: number) => ({
    validate: (value: string | undefined | null) => {
      if ((value && value.length <= limit) || !value) {
        return true;
      }

      return t('form.errors.character-limit', { limit });
    },
  }),
};

export const isValidPassword = (
  val: string,
  passwordErrorState: PasswordValidationErrorState,
  setPasswordError: (value: SetStateAction<CreatePasswordErrorState>) => void
) => {
  let regexMatch: boolean | undefined;

  switch (passwordErrorState) {
    case PasswordValidationErrorState.GIGYA_REGEX_VALIDATION:
      regexMatch = gigyaRegexValidation(val);
      setPasswordError((error) => ({
        ...error,
        [PasswordValidationErrorState.GIGYA_REGEX_VALIDATION]: !regexMatch,
      }));
      break;
    case PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS:
      regexMatch = gigyaRepeatedCharacters(val);
      setPasswordError((error) => ({
        ...error,
        [PasswordValidationErrorState.GIGYA_REPEATED_CHARACTERS]: !regexMatch,
      }));
      break;
    case PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR:
      regexMatch = containsSpecialCharacter(val);
      setPasswordError((error) => ({
        ...error,
        [PasswordValidationErrorState.CONTAINS_SPECIAL_CHAR]: !regexMatch,
      }));
      break;
    case PasswordValidationErrorState.CHAR_LENGTH:
      regexMatch = charLengthIsGreaterThan(val);
      setPasswordError((error) => ({
        ...error,
        [PasswordValidationErrorState.CHAR_LENGTH]: !regexMatch,
      }));
      break;
    default:
  }
  return regexMatch;
};
