/* eslint-disable no-param-reassign */

const getElementDimensions = (element: HTMLElement): { width: number; height: number } => {
  if (element.style.display === 'none') {
    element.style.display = '';
    const result = {
      height: element.clientHeight,
      width: element.clientWidth,
    };
    element.style.display = 'none';

    return result;
  }

  return {
    height: element.clientHeight,
    width: element.clientWidth,
  };
};

/**
 * Animates an element from its current height to 0
 */
export const collapseElement = (element: HTMLElement, callback?: (event?: Event) => any) => {
  const height = element.offsetHeight;
  element.style.display = ``;
  element.style.height = `${height}px`;
  element.style.transition = `height 300ms cubic-bezier(0.4, 0, 0.2, 1)`;
  element.style.overflow = `hidden`;

  const onTransitionEnd = (event: Event) => {
    element.style.display = `none`;
    element.style.height = ``;
    element.style.transition = ``;
    element.style.overflow = ``;
    callback?.(event);
  };

  element.addEventListener('transitionend', onTransitionEnd, { once: true });

  setTimeout(() => {
    element.style.height = `0px`;
  });
};

/**
 * Animates an element from no height to auto
 * @param element
 * @param callback
 */
export const expandElement = (element: HTMLElement, callback?: (event?: Event) => any) => {
  const { height } = getElementDimensions(element);
  element.style.height = `0px`;
  element.style.display = ``;
  element.style.transition = `height 300ms cubic-bezier(0.4, 0, 0.2, 1)`;
  element.style.overflow = `hidden`;

  const onTransitionEnd = (event: Event) => {
    element.style.display = ``;
    element.style.height = ``;
    element.style.transition = ``;
    element.style.overflow = ``;
    callback?.(event);
  };

  element.addEventListener('transitionend', onTransitionEnd, { once: true });

  setTimeout(() => {
    element.style.height = `${height}px`;
  });
};
