import {
  ADD_TO_CART_MUTATION,
  GET_SHOPPING_CARTS_QUERY,
  UPDATE_CART_ITEM_MUTATION,
} from '../../../../lib/graphql/ShoppingCart.gql';
import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { CartIcon, SpinnerIcon } from '../../../../assets/icons';
import { CartProduct, Product, Retailer, ShoppingCart } from '../../../../__generated__/graphql';
import {
  createCartWithSingleProduct,
  findCartByCartProductId,
} from '../../../../lib/utils/pre-order';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { PreOrderDetailsProduct, PreOrderDetailsRequest } from '../../../../lib/types/pre-order';
import { useMutation, useQuery } from '@apollo/client';

import cx from 'classnames';
import { GET_ALL_RETAILERS_WITH_PREFERRED } from '../../../../lib/graphql/PreferredRetailers.gql';
import { GET_PRODUCTS_QUERY } from '../../../../lib/graphql/Products.gql';
import Modal from '../../../../components/_shared/Modal';
import PreOrderDetailsForm from './PreOrderDetailsForm/PreOrderDetailsForm';
import PreOrderOverview from './PreOrderOverview/PreOrderOverview';
import { RETAILERS_PER_PAGE_ORDERS } from '../../../../lib/constants/retailers';
import Skeleton from './Skeleton';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './PreOrderModal.module.scss';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import usePaginationContext from '../../../../lib/contexts/pagination/usePaginationContext';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  isVisible: boolean;
  hide: () => void;
  productName: string;
  editCartItem?: CartProduct;
}

enum Step {
  DETAILS = 'Details',
  OVERVIEW = 'Overview',
}

const PreOrderModal: FC<Props> = ({ isVisible, hide, productName, editCartItem }) => {
  const { t } = useTranslation();
  const formId = useRef<string>(uuid());
  const currentCartItemId = useRef<string>();

  const [step, setStep] = useState<Step>(Step.DETAILS);
  const [itemToEdit, setItemToEdit] = useState<CartProduct>();
  const [shoppingCart, setShoppingCart] = useState<ShoppingCart>();
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer>();

  const [, dispatchSnackbar] = useSnackbarContext();
  const [pageNumber, dispatchPageNumber] = usePaginationContext();

  const { data: shoppingCartData, refetch } = useQuery(GET_SHOPPING_CARTS_QUERY);
  const {
    data: productsData,
    loading: loadingProducts,
    error: productsError,
  } = useQuery(GET_PRODUCTS_QUERY, {
    variables: { input: { name: productName } },
  });
  const {
    data: retailersData,
    loading: loadingRetailers,
    error: retailersError,
  } = useQuery(GET_ALL_RETAILERS_WITH_PREFERRED, {
    variables: {
      filterBy: {
        includePreferred: true,
        preorderEligible: true,
        limit: RETAILERS_PER_PAGE_ORDERS,
        offset: pageNumber * RETAILERS_PER_PAGE_ORDERS,
      },
    },
  });
  const [addItemToCart, { loading: addingItemToCart }] = useMutation(ADD_TO_CART_MUTATION, {
    refetchQueries: [{ query: GET_SHOPPING_CARTS_QUERY }],
  });
  const [updateCartItem, { loading: updatingItemOnCart }] = useMutation(UPDATE_CART_ITEM_MUTATION, {
    refetchQueries: [{ query: GET_SHOPPING_CARTS_QUERY }],
  });

  const {
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    reset,
    control,
    watch,
    register,
    setValue,
    trigger,
  } = useForm<PreOrderDetailsRequest>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      retailerId: undefined,
      quantity: undefined,
      productSku: undefined,
      seedTreatmentId: undefined,
    },
  });
  useFormErrors(trigger, dirtyFields);

  const onSubmit = (formData: PreOrderDetailsRequest) => {
    if (itemToEdit) {
      updateCartItem({
        variables: {
          input: {
            cartProductId: itemToEdit.id,
            productSku: formData.productSku,
            retailerId: formData.retailerId,
            quantity: parseInt(formData.quantity, 10),
          },
        },
      })
        .then(({ data }) => {
          currentCartItemId.current = data?.updateCartProduct.cartProductId;
          return refetch();
        })
        .then(({ data }) => {
          if (data) {
            const cartToShow = createCartWithSingleProduct(
              data.shoppingCarts.shoppingCarts as ShoppingCart[],
              currentCartItemId.current || ''
            );
            if (cartToShow) {
              setShoppingCart(cartToShow);
              setStep(Step.OVERVIEW);
            }
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    } else {
      addItemToCart({
        variables: {
          input: {
            productSku: formData.productSku,
            retailerId: formData.retailerId,
            quantity: parseInt(formData.quantity, 10),
          },
        },
      })
        .then(({ data }) => {
          currentCartItemId.current = data?.addToCart.cartProductId;
          return refetch();
        })
        .then(({ data }) => {
          if (data) {
            const cartToShow = createCartWithSingleProduct(
              data.shoppingCarts.shoppingCarts as ShoppingCart[],
              currentCartItemId.current || ''
            );

            if (cartToShow) {
              setShoppingCart(cartToShow);
              setStep(Step.OVERVIEW);
            }
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const onEdit = (cartItem: CartProduct) => {
    setItemToEdit(cartItem);
    setStep(Step.DETAILS);
  };

  // Sets 'Details' form step when the modal is visible
  useEffect(() => {
    setStep(Step.DETAILS);
  }, [isVisible]);

  // Sets the cart item to edit when the prop changes
  useEffect(() => {
    if (editCartItem) {
      setItemToEdit(editCartItem);
    }
  }, [editCartItem]);

  // Populate form fields for editing
  useEffect(() => {
    if (itemToEdit && shoppingCartData) {
      const cart: ShoppingCart = findCartByCartProductId(
        shoppingCartData.shoppingCarts.shoppingCarts as ShoppingCart[],
        itemToEdit.id
      );

      setSelectedRetailer(cart?.retailer);

      reset({
        productSku: itemToEdit.product.sku,
        quantity: itemToEdit.quantity.toString(),
        retailerId: cart?.retailer?.id,
        seedTreatmentId: itemToEdit.product.seedTreatment?.id,
      });
    }
  }, [shoppingCartData, itemToEdit, reset]);

  // Updates 'Overview' screen shopping cart when removing items
  useEffect(() => {
    if (shoppingCartData && step === Step.OVERVIEW && shoppingCart) {
      const cartToShow = createCartWithSingleProduct(
        shoppingCartData.shoppingCarts.shoppingCarts as ShoppingCart[],
        currentCartItemId.current ?? ''
      );

      if (cartToShow) {
        setShoppingCart(cartToShow);
      } else {
        hide();
      }
    }
  }, [shoppingCartData, step, hide]);

  useEffect(() => {
    if (retailersError) {
      hide();
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, hide, retailersError, t]);

  const renderView = (): ReactNode => {
    if (loadingRetailers || loadingProducts) {
      return (
        <div className={cx(styles['pre-order-details-modal-skeleton'])}>
          <Skeleton className={cx(styles['pre-order-details-modal-skeleton__item'])} />
        </div>
      );
    } else if (
      (productsData &&
        (!productsData.productForPreOrder.products.length ||
          !productsData.productForPreOrder.details)) ||
      productsError
    ) {
      return (
        <p className={cx(styles['pre-order-details-modal__text'])}>
          {t('errors.product-not-available', { productName })}
        </p>
      );
    } else if (!productsData || !retailersData?.getRetailers.retailers.length) {
      return null;
    }

    if (step === Step.DETAILS) {
      return (
        <PreOrderDetailsForm
          handleSubmit={handleSubmit(onSubmit)}
          formID={formId}
          products={productsData.productForPreOrder.products as Product[]}
          productDetails={productsData.productForPreOrder.details as PreOrderDetailsProduct}
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          selectedRetailer={selectedRetailer}
          setValue={setValue}
          setSelectedRetailer={setSelectedRetailer}
          retailers={retailersData?.getRetailers.retailers || []}
          preferredRetailers={(retailersData?.user.retailers as Retailer[]) || []}
          totalRetailers={retailersData?.getRetailers.totalCount || 0}
        />
      );
    } else if (step === Step.OVERVIEW && shoppingCart) {
      return <PreOrderOverview shoppingCart={shoppingCart} onEdit={onEdit} />;
    }

    return null;
  };

  return (
    <Modal
      classNames={{
        modalWrapper: cx(styles['pre-order-details-modal__modal-wrapper']),
        modalContent: cx(styles['pre-order-details-modal__modal-content']),
      }}
      title={t('order-placement.pre-order-modal.heading')}
      isVisible={isVisible}
      hide={() => {
        hide();
        dispatchPageNumber(0);
      }}
      primaryCta={{
        label:
          step === Step.OVERVIEW
            ? `${t('order-placement.pre-order-modal.button-go-to-pre-orders')}`
            : t('order-placement.pre-order-modal.button-add-pre-order'),
        action: () => {
          if (step === Step.OVERVIEW) {
            reset();
            hide();
          }
        },
        buttonType: 'submit',
        buttonFormId: formId.current,
        buttonIcon: addingItemToCart || updatingItemOnCart ? SpinnerIcon : CartIcon,
        buttonIconPosition: IconPosition.RIGHT,
        buttonIconClassName: cx({
          [styles['pre-order-details-modal__button-icon']]: addingItemToCart || updatingItemOnCart,
        }),
        disabled:
          (step === Step.DETAILS && (!isValid || !selectedRetailer)) ||
          addingItemToCart ||
          updatingItemOnCart,
      }}
      secondaryCta={{
        label: t(
          step === Step.OVERVIEW
            ? 'order-placement.pre-order.product-information'
            : 'general.cancel'
        ),
        action: () => {
          if (step === Step.DETAILS) {
            reset();
            hide();
          }

          if (step === Step.OVERVIEW) {
            window.open(productsData?.productForPreOrder.details?.preAuthUrl, '_blank');
          }
        },
        buttonTheme: step === Step.OVERVIEW ? ButtonThemes.SECONDARY : ButtonThemes.TEXT_LINK,
      }}
    >
      {renderView()}
    </Modal>
  );
};

PreOrderModal.displayName = 'PreOrderModal';

export default PreOrderModal;
