import cx from 'classnames';
import { FC } from 'react';
import styles from './Skeleton.module.scss';

export interface Props {}

const SKELETON_ROWS = 5;

const Skeleton: FC<Props> = () => (
  <>
    {Array.from(Array(SKELETON_ROWS).keys()).map((el) => (
      <tr key={el} className={cx(styles['skeleton__row'])}>
        <td className={cx(styles['skeleton__cell'])} aria-hidden>
          <div className={cx(styles['skeleton'], styles['skeleton--left'])}></div>
        </td>
        <td className={cx(styles['skeleton__cell'])} aria-hidden>
          <div className={cx(styles['skeleton'], styles['skeleton--right'])}></div>
        </td>
      </tr>
    ))}
  </>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
