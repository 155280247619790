export const PURCHASES_PER_PAGE = 16;

export enum OrderHistoryRowType {
  PRE_ORDER = 'pre-order',
  PURCHASE = 'purchase',
}

export enum OrderHistorySearchParams {
  QUERY = 'query',
  OFFSET = 'offset',
  SORT_BY = 'sortBy',
  SORT_ORDER = 'sortOrder',
  PRODUCT_SKU = 'productSku',
  PROGRAM_ID = 'programId',
  PURCHASER = 'purchaser',
  RETAILER_ID = 'retailerId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DATE_RANGE = 'dateRange',
}

export enum PurchaseChartType {
  ACRE = 'Acre',
  MSRP = 'Msrp',
}

export const PurchaseChartAxisKey = 'year';
