import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { PageRoutes } from '../../lib/constants/react-router';
import SettingsNavigation from './components/SettingsNavigation';
import styles from './AccountSettings.module.scss';
import { useBreakpoint } from '../../lib/hooks';
import { usePageTitle } from '../../lib/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

const AccountSettings: FC = () => {
  usePageTitle('account-settings');
  const { isMd } = useBreakpoint();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/${PageRoutes.ACCOUNT_SETTINGS}`) {
      navigate(`/${PageRoutes.ACCOUNT_SETTINGS}/${PageRoutes.SETTINGS_PROFILE}`, { replace: true });
    }
  }, [navigate, pathname]);

  return (
    <div className={cx(styles['account-settings'])}>
      <div className={cx(styles['account-settings__container'])}>
        <h1 className={cx(styles['account-settings__heading'], { 'sr-only': isMd })}>
          {t('account-settings.title')}
        </h1>
        <div className={cx(styles['account-settings__content'])}>
          <div className={cx(styles['account-settings__navigation-wrapper'])}>
            <SettingsNavigation />
          </div>
          <div className={cx(styles['account-settings__outlet-wrapper'])}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

AccountSettings.displayName = 'AccountSettings';

export default AccountSettings;
