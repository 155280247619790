import { gql } from '../../../../__generated__';

export const VERIFY_CODE = gql(`
    mutation VerifyCode($input: VerifyCodeInput!) {
        verifyCode(input: $input) {
            valid
            verificationId
        }
    }`
);
