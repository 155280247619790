import { FC, useEffect, useState } from 'react';
import {
  groupProgramBreakdownAsOther,
  mapProgramBreakdownToChartEntries,
} from '../../../../../lib/utils/programs';
import {
  ProgramBreakdown,
  ProgramBreakdownWithColorIcon,
} from '../../../../../lib/models/Program.model';
import { Trans, useTranslation } from 'react-i18next';

import { ArcWidth } from '../../../../../components/_shared/ProgramDoughnutChart/ProgramDoughnutChart.types';
import cx from 'classnames';
import { DisplayTheme } from '../../../../../lib/constants/components';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../../lib/graphql/ProgramRewards.gql';
import { getTotalPrice } from '../../../../../components/_shared/ProgramDoughnutChart/ProgramDoughnutChart.utils';
import ProgramCard from '../../../../../components/_shared/ProgramCard';
import { ProgramCardBadgeAlignment } from '../../../../../components/_shared/ProgramCard/ProgramCard.types';
import ProgramDoughnutChart from '../../../../../components/_shared/ProgramDoughnutChart';
import RetreatSection from '../../../../BayerRewardsLanding/components/RetreatSection';
import styles from './Breakdown.module.scss';
import { useBreakpoint } from '../../../../../lib/hooks';
import { useQuery } from '@apollo/client';

interface Props {
  year: number;
}

const Breakdown: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();
  const [groupedBreakdown, setGroupedBreakdown] = useState<ProgramBreakdownWithColorIcon[]>([]);
  const { data: breakdownData } = useQuery(GET_PROGRAM_REWARDS_BREAKDOWN_QUERY, {
    variables: { input: { year } },
  });

  useEffect(() => {
    if (breakdownData?.enrolledProgramsInfo) {
      setGroupedBreakdown(
        groupProgramBreakdownAsOther(
          [
            breakdownData.enrolledProgramsInfo.bayerValueProgramInfo,
            ...breakdownData.enrolledProgramsInfo.otherPrograms,
          ].filter((breakdown) => breakdown) as ProgramBreakdown[],
          t
        )
      );
    }
  }, [breakdownData, t]);

  return (
    <div className={cx(styles['breakdown'])}>
      <div className={cx(styles['breakdown__metrics'])}>
        <div className={cx(styles['breakdown__metrics-inner'])}>
          <div className={cx(styles['breakdown__donut-wrapper'])}>
            <ProgramDoughnutChart
              arcWidth={isXs ? ArcWidth.LG : ArcWidth.XL}
              data={mapProgramBreakdownToChartEntries(groupedBreakdown)}
              label={t('bayer-rewards-widget.metrics-title.rewards-earned')}
              showText={isXs}
            />
          </div>
          <div className={cx(styles['breakdown__information'])}>
            <div className={cx(styles['breakdown__metrics-heading'])}>
              <p className={cx(styles['breakdown__metrics-title'])}>
                {t('bayer-rewards-widget.metrics-title.rewards-earned')}
              </p>
              <p className={cx(styles['breakdown__metrics-subtitle'])}>
                {t('format.price', {
                  value: getTotalPrice(mapProgramBreakdownToChartEntries(groupedBreakdown)),
                })}
              </p>
            </div>
            <div className={cx(styles['breakdown__cards-wrapper'])}>
              <p className={cx(styles['breakdown__metrics-title'])}>
                {t('bayer-rewards-widget.metrics-title.program-breakdown')}
              </p>
              {groupedBreakdown.map((breakdownItem) => (
                <div key={breakdownItem?.program.id} className={cx(styles['breakdown__card'])}>
                  <ProgramCard
                    badgeAlignment={ProgramCardBadgeAlignment.CENTER}
                    description={
                      <strong>
                        <Trans
                          i18nKey="program.earned"
                          values={{
                            value: breakdownItem.rewardAmountInDollars,
                          }}
                          components={[<span />]}
                        />
                      </strong>
                    }
                    program={breakdownItem}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {process.env.REACT_APP_SHOW_RETREAT && (
        <RetreatSection year={year} theme={DisplayTheme.WIDGET} />
      )}
    </div>
  );
};

export default Breakdown;
